<template>
  <div id="app">
    <router-view :style="mxIsChromeBrowser() && 'zoom: 85%'" />
    <warning-modal />
    <toast />
  </div>
</template>

<script>
import Toast from "@/components/Toast";
import WarningModal from "@/components/WarningModal.vue";
import { mapActions } from "vuex";

export default {
  beforeMount() {
    window.addEventListener("beforeunload", () => this.$destroy());

    let sessionStoragePermissions;

    if (window.sessionStorage.getItem("p-keys")) {
      sessionStoragePermissions = JSON.parse(
        Buffer.from(window.sessionStorage.getItem("p-keys"), "base64").toString(
          "utf8"
        )
      );
    }

    const sessionStorageToken = window.sessionStorage.getItem(
      process.env.VUE_APP_TOKEN_KEY
    );

    sessionStorageToken && this.ActionSetToken(sessionStorageToken);

    sessionStoragePermissions &&
      this.ActionSetPermissoes(sessionStoragePermissions);
  },
  methods: {
    ...mapActions(["ActionSetToken", "ActionSetPermissoes"]),
  },
  components: {
    Toast,
    WarningModal,
  },
};
</script>
