import store from "@/store";

export default {
  methods: {
    mxToggleErrorMessageAlert(message, showButton = true) {
      console.log(message);
      store.dispatch("ActionResetPendencies");
      store.dispatch("ActionToggleDangerAlert", { message, showButton });
    },
  },
};
