<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <card-base>
          <template #header>Condições do Contrato</template>
          <template #body>
            <div class="card-body">
              <div class="_card-contrato-item card-condicoes-contrato">
                <div class="card-label">Contrato</div>
                <div class="card-value">{{ contrato.codigo }}</div>
              </div>
              <div class="_card-contrato-item card-condicoes-contrato">
                <div class="card-label">Apelido</div>
                <div class="card-value">{{ contrato.empresa.apelido }}</div>
              </div>
              <div class="_card-contrato-item card-condicoes-contrato">
                <div class="card-label">Ponto de Medição</div>
                <div class="card-value">
                  <div v-if="contrato.rateio">--</div>
                  <div v-else>{{ contrato.pontoMedicao }}</div>
                </div>
              </div>
              <div class="_card-contrato-item card-condicoes-contrato">
                <div class="card-label">PROINFA de {{ COMPETENCIA }}</div>
                <div v-if="contrato.rateio">--</div>
                <div v-else class="card-value">{{ GET_CONTRATO_PROINFA }}</div>
              </div>
              <div class="_card-contrato-item card-condicoes-contrato">
                <div class="card-label">% de Perdas</div>
                <div v-if="contrato.rateio">--</div>
                <div v-else class="card-value">
                  {{ contrato.percentualPerda }}%
                </div>
              </div>
              <div class="_card-contrato-item card-condicoes-contrato">
                <div class="card-label">% Fator Atend. Carga</div>
                <div v-if="contrato.rateio">--</div>
                <div v-else class="card-value">
                  {{ contrato.percentualFatorAtendimento }}%
                </div>
              </div>
            </div>
          </template>
        </card-base>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4 mb-3">
        <card-base>
          <template #header>Flexibilidade (MWh)</template>
          <template #body>
            <div class="card-body">
              <div class="_card-contrato-item card-flexibilidade-recompra">
                <div class="card-label">Qtd. Contratada</div>
                <div class="card-value">
                  {{ contrato.flexibilidade.quantidadeContratada | VOLUME }}
                </div>
              </div>
              <div class="_card-contrato-item card-flexibilidade-recompra">
                <div class="card-label">Limite Min</div>
                <div class="card-value">
                  {{ contrato.flexibilidade.limiteMinimo | VOLUME }}
                </div>
              </div>
              <div class="_card-contrato-item card-flexibilidade-recompra">
                <div class="card-label">Limite Máx</div>
                <div class="card-value">
                  {{ contrato.flexibilidade.limiteMaximo | VOLUME }}
                </div>
              </div>
            </div>
          </template>
        </card-base>
      </div>
      <div class="col-md-8 mb-3">
        <card-base>
          <template #header>Resultado do Cálculo de Medição (MWh)</template>
          <template #body>
            <div class="card-body">
              <div class="_card-contrato-item card-calculo">
                <div class="card-label">Mont SCDE</div>
                <div class="card-value">
                  {{ contrato.calculoResultado.montanteSCDE | VOLUME }}
                </div>
              </div>
              <div class="_card-contrato-item card-calculo">
                <div class="card-label">Solicitado Bruto</div>
                <div class="card-value">
                  {{ contrato.calculoResultado.solicitadoBruto | VOLUME }}
                </div>
              </div>
              <div class="_card-contrato-item card-calculo">
                <div class="card-label">Solicitado Líquido</div>
                <div class="card-value">
                  {{ contrato.calculoResultado.solicitadoLiquido | VOLUME }}
                </div>
              </div>
              <div class="_card-contrato-item card-calculo">
                <div class="card-label">Diferença</div>
                <div class="card-value">
                  <b> {{ DIFERENCA | VOLUME }}</b>
                </div>
              </div>
            </div>
          </template>
        </card-base>
      </div>
    </div>
  </div>
</template>
<script>
import bigDecimal from "js-big-decimal";
import CardBase from "../cards/cardBase.vue";
import { VOLUME } from "@/filters/formatters";
import { getFaturamentoCompetencia } from "@/utils/functionsUtils";

export default {
  components: {
    CardBase,
  },
  props: {
    contrato: Object,
  },
  computed: {
    DIFERENCA() {
      const { solicitadoLiquido, solicitadoBruto } =
        this.contrato.calculoResultado;
      return bigDecimal.subtract(solicitadoLiquido, solicitadoBruto);
    },
    COMPETENCIA() {
      const { month, year } = getFaturamentoCompetencia();
      return `${month}/${year}`;
    },
    GET_CONTRATO_PROINFA() {
      return this.contrato.rateio
        ? "--"
        : this.contrato.proinfa === 0
        ? "0,000"
        : VOLUME(this.contrato.proinfa);
    },
  },
  filters: {
    VOLUME,
  },
};
</script>
<style lang="scss" scoped>
.row {
  display: flex;
}
.row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}
.card-body {
  padding: 0;
  display: flex;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  flex-wrap: wrap;
  flex: 1;
}
._card-contrato-item {
  display: flex;
  padding-top: 15px;
  flex-direction: column;
  justify-content: space-between;
}
.card-condicoes-contrato {
  width: 16.66%;
  @include mq-value(890px) {
    width: 33.33%;
    margin-bottom: 15px;
  }
  @include mq-value(600px) {
    width: 100%;
  }
  @include mq-value(600px) {
    width: 50%;
  }
  @include mq-value(400px) {
    width: 100%;
  }
}
.card-flexibilidade-recompra {
  width: 33.33%;
  @include mq-value(400px) {
    width: 100%;
    margin-bottom: 15px;
  }
}
.card-calculo {
  width: 25%;
  @include mq-value(890px) {
    width: 50%;
    margin-bottom: 15px;
  }
  @include mq-value(400px) {
    width: 100%;
  }
}
.card-label {
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 24px;
  color: #544943;
}
.card-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2a2a2a;
}
</style>