<template>
  <div>
    <span :class="['animated-dot-0', size]">.</span>
    <span :class="['animated-dot-1', size]">.</span>
    <span :class="['animated-dot-2', size]">.</span>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      required: false,
      default: "md",
    },
  },
};
</script>

<style scoped>
.sm {
  font-size: 0.9em;
}

.md {
  font-size: 1em;
}

.lg {
  font-size: 1.5em;
}

.xlg {
  font-size: 2em;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.6;
  }
}

.animated-dot-0 {
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.animated-dot-1 {
  animation-name: loading;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.animated-dot-2 {
  animation-name: loading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
</style>