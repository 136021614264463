<template>
  <div class="">
    <!--Modal recompra preço único-->
    <modal
      max-width="95%"
      max-height="90vh"
      @closeModal="closeModal"
      v-if="showModais && typePriceSelected == 'unico'"
      data-cy="modal-fechar_modal-recompra"
    >
      <template #header>
        Efetuar Recompra - Preço Único -
        <label v-if="rateio">com Rateio</label>
        <label v-if="!rateio">sem rateio</label>
      </template>
      <template #body>
        <recompra-preco-unico
          ref="precoUnico"
          :contrato="contrato"
          @closeModal="closeModal"
          @finishLoading="finishLoading"
          data-cy="modal-fechar_modal_recompra_preco_unico-recompra"
        />
      </template>
      <template #footer>
        <div class="wrapper-buttons right">
          <div class="btn-recompra">
            <button
              @click="efetuarRecompra"
              :disabled="overloadLoading || efetuarRecompraLoading"
              class="btn btn-action-secondary"
              data-cy="button-efetuar_recompra-recompra"
            >
              Efetuar Recompra
            </button>
          </div>
        </div>
      </template>
    </modal>

    <!--Modal recompra preços diferentes-->
    <modal
      max-width="90%"
      max-height="90vh"
      v-if="showModais && typePriceSelected === 'diferentes' && rateio"
      @closeModal="closeModal"
    >
      <template #header> Efetuar Recompra - Preços Diferentes </template>
      <template #body>
        <recompra-precos-diferentes
          :contrato="contrato"
          ref="precosDiferentes"
          @finishLoading="finishLoading"
        />
      </template>
      <template #footer>
        <div class="wrapper-buttons right">
          <div class="btn-recompra">
            <button
              @click="efetuarRecompra"
              :disabled="overloadLoading"
              class="btn btn-action-secondary"
              data-cy="button-efetuar_recompra_diferente-recompra"
            >
              Efetuar recompra
            </button>
          </div>
        </div>
      </template>
    </modal>

    <!-- modal seleção tipo preço em caso de rateio-->
    <modal
      height="300px"
      max-width="fit-content"
      @closeModal="closeModal"
      data-cy="modal-tipo_preco_rateio-recompra"
      v-if="showModais && !typePriceSelected && rateio"
    >
      <template #header> Efetuar Recompra </template>
      <template #body>
        <div class="model-content-prices">
          Escolha a modalidade de recompra:
        </div>
      </template>
      <template #footer>
        <div class="wrapper-buttons center">
          <button
            @click="selectTypePrice('unico')"
            data-cy="button-preco_unico-recompra"
            class="btn btn-primary-outlined btn-common"
          >
            Preço único
          </button>
          <button
            @click="selectTypePrice('diferentes')"
            data-cy="button-precos_diferentes-recompra"
            class="btn btn-primary-outlined btn-common"
          >
            Preços diferentes
          </button>
        </div>
      </template>
    </modal>

    <modal-efetuar-recompra-spread-expirado
      v-if="showModalPldSpreadExpirado"
      @closeModal="showModalPldSpreadExpirado = false"
    />
    <button
      @click.self="openModais"
      :disabled="data.desabilitado"
      class="btn btn-action-secondary-outlined"
      data-cy="button-efetuar_recompra_modais-recompra"
    >
      Efetuar Recompra
    </button>
  </div>
</template>

<script>
import ModalEfetuarRecompraSpreadExpirado from "@/components/contratos/modais/ModalEfetuarRecompraSpreadExpirado.vue";
import Modal from "@/components/modals/ModalBase.vue";
import RecompraPrecoUnico from "@/components/recompra/recompra-preco-unico/RecompraPrecoUnico.vue";
import RecompraPrecosDiferentes from "@/components/recompra/recompra-precos-diferentes/RecompraPrecosDiferentes.vue";
import PLDSpreadService from "@/services/PLDSpreadService";

export default {
  props: {
    rateio: {
      type: Boolean,
      default: false,
    },
    data: Object,
    contrato: Object,
  },
  components: {
    Modal,
    RecompraPrecoUnico,
    RecompraPrecosDiferentes,
    ModalEfetuarRecompraSpreadExpirado,
  },
  data() {
    return {
      overloadLoading: false,
      showModais: false,
      statusPldSpread: null,
      typePriceSelected: null,
      showModalPldSpreadExpirado: false,
      efetuarRecompraLoading: false,
    };
  },
  methods: {
    finishLoading(value) {
      this.overloadLoading = value;
    },
    async efetuarRecompra() {
      this.efetuarRecompraLoading = true;
      await this.getStatusPldSpread();

      if (this.statusPldSpread !== "Aprovado") {
        (this.overloadLoading = false),
          this.mxToggleErrorMessageAlert(
            "Não é possível efetuar a Recompra pois o Spread está expirado."
          );
        this.efetuarRecompraLoading = false;
        return;
      }
      if (!this.rateio || (this.rateio && this.typePriceSelected === "unico")) {
        this.$refs.precoUnico.efetuarRecompraPrecoUnico();
      } else {
        this.$refs.precosDiferentes.efetuarRecompraPrecosDiferentes();
      }
      this.efetuarRecompraLoading = false;
    },
    async openModais() {
      await this.getStatusPldSpread();

      if (!this.rateio) {
        this.typePriceSelected = "unico";
      }
      if (this.statusPldSpread !== "Aprovado") {
        this.showModalPldSpreadExpirado = true;
      } else {
        this.showModais = true;
      }
    },
    selectTypePrice(type) {
      this.showModais = false;
      this.typePriceSelected = type;
      this.showModais = true;
    },
    closeModal() {
      this.showModais = false;
      this.typePriceSelected = null;
    },
    async getStatusPldSpread() {
      const id = Number(this.contrato.id);

      try {
        const { data } = await PLDSpreadService.getStatusPLDSpread({ id });

        this.statusPldSpread = data;
      } catch (error) {
        this.closeModal();

        this.mxHandleRequestError(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
._upload-manual {
  &__body {
    width: 100%;
    padding: 20px 30px;
    font-size: 1rem;
    line-height: 29px;
    color: #2a2a2a;
  }
}
.wrapper-buttons {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0 30px;
}
.btn-common {
  padding: 8px 16px;
  font-weight: 700;
  border-radius: 9px;
  outline: none;
}
.btn-recompra {
  width: 300px;
}

.center {
  justify-content: center;
  padding: 10px;
}
.right {
  justify-content: flex-end;
}
.model-content-prices {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  font-size: 1.3rem;
  color: #666;
}
</style>
