<template>
  <div :style="`width:${width};height:${height}`" class="skeleton">
    <div>{{ name }}</div>
  </div>
</template>
<script>
export default {
  props: ["width", "height", "name"],
};
</script>
<style lang="scss" scoped>
.skeleton {
  background: #ddd;
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 10%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>