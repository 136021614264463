import Vue from "vue";

/**
 * @description - Chama uma função ao detectar texto com mais caracteres permitidos
 * @tag - <div v-tooltip=""></div>
 * @return - (evento,elemento)
 */

Vue.directive("textOverflow", {
  inserted: function (el, binding) {
    if (el.innerText.length >= 15) {
      el.style.width = binding.value.width + "%";
      el.setAttribute("class", "text-overflow");
    }
  },
});
