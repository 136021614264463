export default class DemonstrativoModel {
    constructor(
      {
        contratoPrincipal,
        curtoPrazo,
        demonstrativo,
        historicoConsumo,
      } = new Object()
    ) {
      this._contratoPrincipal = contratoPrincipal;
      this._curtoPrazo = curtoPrazo;
      this._demonstrativo = demonstrativo;
      this._historicoConsumo = historicoConsumo;
    }
  
    get contratoPrincipal() {
      return this._contratoPrincipal ?? {
        id: null,
        codigo: "",
        tipo: "", //FLEX
        tipoClasse: "",
      empresa: {
        id: 615,
        codigo: "",
        cnpj: "",
        tipo: "",
        razaoSocial: "",
        nomeFantasia: "",
        apelido: ""
      },
      dataReferencia: "",
      submercado: {
        id: null,
        codigo: "",
        descricao: "",
        sigla: "",
        pld: null
      },
      tipoEnergia: {
        id: 3,
        codigo: "",
        descricao: "",
        sigla: ""
      },
      percentualPerda: 0,
      pontoMedicao: {
        id: 0,
        codigo: "",
        proinfa: {
        dataReferencia: "",
          valor: 0
        },
        codigoRetaguarda: null
      },
      flexibilidade: {
        limiteMinimo: 0,
        limiteMaximo: 0,
        quantidadeContratada: 0,
        take: ""
      },
      parametrizacao: {
        recebeCurtoPrazo: true,
        recebeReducaoDeMontantes: false,
        envioAutomatico: true
      },
      percentualFatorAtendimento: 0,
      calculoSumarizado: {
        volumeBruto: 0,
        volumeLiquido: 0,
        volumeAFaturar: 0,
        montanteScde: 0,
        price:10
      },
      contratosFilhos: null
      };
    }
  
    set contratoPrincipal(contratoPrincipal = null) {
      this._contratoPrincipal = contratoPrincipal;
    }
  
    get demonstrativo() {
      return this._demonstrativo ?? {
        id: 1,
        url: "",
        dataCriacao: "",
        dataLimite: "",
        ativo: true,
        dataInativacao: null,
        situacaoDemonstrativo: {
            id: null,
            nome: null,
            email: null,
            justificativa: null,
            dataCriacao: null,
            situacao: ""
        }
    };
    }
  
    set demonstrativo(demonstrativo = null) {
      this._demonstrativo = demonstrativo;
    }
  
    get historicoConsumo() {
      return this._historicoConsumo ?? [
        
        {
            dataReferencia: "",
            consumoApurado: 0,
            dataReferenciaFormatada: ""
          },
          {
            dataReferencia: "",
            consumoApurado: 0,
            dataReferenciaFormatada: ""
          },
          {
            dataReferencia: "",
            consumoApurado: 190.44,
            dataReferenciaFormatada: ""
          }

        ];
    }
  
    set curtoPrazo(curtoPrazo = null) {
      this._curtoPrazo = curtoPrazo;
    }

    get curtoPrazo() {
      return this._curtoPrazo ?? null;
    }
  
    set historicoConsumo(historicoConsumo = null) {
      this._historicoConsumo = historicoConsumo;
    }
  

    getData() {
      const { contratoPrincipal,demonstrativo, historicoConsumo,curtoPrazo} = this;
      return {
        contratoPrincipal,demonstrativo, historicoConsumo,curtoPrazo
      };
    }
  }







 