<template>
    <div>
        <data-table
            :stripes="true"
            :headerStick="true"
            :maxItemsToScroll="6"
            :data="data.filter(g=>g.condicaoDePrecoDto.spreadAjustado)"
            :fields="fields"
            :card="true"
            :lineClassIndex="{
                index:contratoMaiorSpread.index,
                class: `maior-spread`
            }"
          ></data-table>
    </div>
</template>
<script>
import DataTable from "@/components/tables/DataTable.vue";
import { moedaFormatter, volumeFormatter } from "@/filters/formatters";
export default {
    components:{
        DataTable
    },
    props:{
        data:Array,
        contratoMaiorSpread:Object
    },
    data(){
        return{
            dataTableFields:[],
            fields:[
                {
                    name: "codigo",
                    title: "Contrato",
                    formatter:function(value,index){
                        return value  
                    }
                },
                {
                    name: "condicaoDePrecoDto",
                    title: "Subm.",
                    formatter:function(value){
                        return value.submercado
                    }
                },
                {
                    name: "condicaoDePrecoDto",
                    title: "Tipo de Energia",
                    formatter:function(value){
                        return value.tipoEnergia
                    }
                },
                {
                    name: "condicaoDePrecoDto",
                    title: "PLD",
                    formatter:function(value){
                        return "R$" + moedaFormatter(value.pld) 
                    }
                },
                {
                    name: "condicaoDePrecoDto",
                    title: "Spread de Compra",
                    formatter:function(value){
                        return "R$" +  moedaFormatter(value.spreadDeCompra) 
                    }
                },
                {
                    name: "condicaoDePrecoDto",
                    title: "Preço de Compra",
                    formatter:function(value){
                        return "R$" +  moedaFormatter(value.precoDeCompra) 
                    }
                },
                {
                    name: "condicaoDePrecoDto",
                    title: "Spread Ajustado",
                    formatter:function(value){
                        return "R$" +  moedaFormatter(value.spreadAjustado) 
                    }
                },
                {
                    name: "condicaoDePrecoDto",
                    title: "Preço Ajustado",
                    formatter:function(value){
                        return "R$" + moedaFormatter(value.precoDeCompraAjustado)
                    }
                },
                {
                    name: "novoVolume",
                    title: "Novo Volume",
                    formatter:function(value){
                        return volumeFormatter(value) 
                    }
                },
            ]
        }
    }
}
</script>
<style lang="scss">
    .maior-spread{
        color:$color--primary;
        font-weight: 500;
    }
</style>