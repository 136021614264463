export default class TusdModel {
  constructor({ id, dataCriacao, descricao, tipoEnergia } = new Object()) {
    this._id = id;
    this._dataCriacao = dataCriacao;
    this._descricao = descricao;
    this._tipoEnergia = tipoEnergia;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id = null) {
    this._id = id;
  }

  get dataCriacao() {
    return this._dataCriacao ?? null;
  }

  set dataCriacao(dataCriacao = null) {
    this._dataCriacao = dataCriacao;
  }

  get descricao() {
    return this._descricao ?? null;
  }

  set descricao(descricao = null) {
    this._descricao = descricao;
  }

  get tipoEnergia() {
    return this._tipoEnergia ?? new Array();
  }

  set tipoEnergia(tipoEnergia = null) {
    this._tipoEnergia = tipoEnergia;
  }

  getData() {
    const { id, dataCriacao, descricao, tipoEnergia } = this;
    return {
      id,
      dataCriacao,
      descricao,
      tipoEnergia,
    };
  }
}
