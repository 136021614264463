<template>
  <div class="d-flex flex-column container-fields">
    <label>{{ label }}</label>
    <vue-numeric
      :max="100"
      :value="value"
      ref="refInput"
      v-model="valueField"
      :precision="precision"
      :disabled="isDisabled"
      class="form-control ipt"
      :decimal-separator="','"
      :thousand-separator="'.'"
      data-cy="input-ponto_medicao-ponto-medicao-components-fields"
    />
  </div>
</template>
<script>
import VueNumeric from "vue-numeric";
export default {
  components: {
    VueNumeric,
  },
  props: {
    label: {
      type: String,
    },
    precision: {
      type: Number,
      default: 2,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      valueField: this.value,
    };
  },
  watch: {
    valueField: {
      handler() {
        this.$emit("changeFieldPoint", this.valueField);
      },
    },
  },
  methods: {
    getValue() {
      const valueInput = this.$refs.refInput.value;
      return valueInput;
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  color: $color--primary-font;
  font-weight: 300;
}

.ipt {
  border-radius: 12px;
  width: 158px;
  height: 40px;
  margin-top: 8.36px;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }

  &:disabled {
    background: #f2edeb;
    border-color: #d6d2d0;
  }
}

.container-fields {
  margin-left: 50px;

  @media screen and (max-width: 1300px) {
    margin-left: 0px;
  }
}
</style>
