import { HTTP_RESPONSE } from "@/constants/strings";
import { loginRedirect, useRefreshToken } from "@/utils/authentication";

export default {
  methods: {
    mxHandleRequestError(request, entity = "") {
      if (!request || !request.response)
        return this.mxToggleErrorMessageAlert(
          "Ocorreu um erro ao processar sua solicitação!"
        );
      if (!request.response.status)
        return this.mxToggleErrorMessageAlert(
          "Ocorreu um erro ao processar sua solicitação: serviço indisponível"
        );

      if (request.response.status === 401)
        return useRefreshToken()
          .then(() => {
            this.$router.push({
              path: "/faturamento",
            });
          })
          .catch(() => {
            this.mxToggleWarningModal({
              message: HTTP_RESPONSE.GENERIC[403],
              btnTrue: "Prosseguir",
              showBtnFalse: false,
            })
              .then()
              .catch()
              .finally(() => {
                loginRedirect();
              });
          });

      return this.mxToggleErrorMessageAlert(
        HTTP_RESPONSE.GENERIC[request.response.status]
      );
    },
    isWarningModalAlreadyShown() {
      const warningModalClasses =
        document.getElementById("warningModal").classList.value;
      return warningModalClasses.includes("show");
    },
  },
};
