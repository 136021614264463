<template>
  <div>
    <span class="timer"
    ><img
        style="margin-top: -3px"
        :src="mountImg('/images/svg/clock-regular.svg')"
    /></span>
    <b
    ><span class="timer"> 00:{{ minutos }}:{{ segundos }}</span></b
    >
  </div>
</template>
<script>
export default {
  props: ["periodo"],
  data() {
    return {
      horas: "00",
      minutos: "00",
      segundos: "00",
      timer: 0,
    };
  },
  mounted() {
    this.timer = this.periodo;
    this.start();
  },
  methods: {
    start() {
      setInterval(() => {
        this.minutos = parseInt(this.timer / 60, 10);
        this.segundos = parseInt(this.timer % 60, 10);
        this.minutos = this.minutos < 10 ? "0" + this.minutos : this.minutos;
        this.segundos =
            this.segundos < 10 ? "0" + this.segundos : this.segundos;
        if (--this.timer < 0) {
          this.timer = this.timer - 60;
          this.timer = 0;
          this.$emit("finished");
        } else {
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.timer {
  color: #f86511;
}
</style>
