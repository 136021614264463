<template>
  <div class="page-faturamento">
    <div>
      <page-painel
        :key="'table-painel'"
        v-elementAsyncLoader="tableLoading"
        style="min-height: 400px"
        class="page-faturamento__panel"
      >
        <template #header> Contratos Não Importados </template>
        <template #body>
          <div class="page-faturamento__body">
            <field-search
              class="fieldSearch"
              :placeholder="'Busque por Apelido ou Contrato'"
              @getSearchValue="getSearchValue"
            />
            <data-table
              :fields="tableContratosNaoImportados.getTableFields()"
              :data="tableDataContratos"
              :stripes="true"
              :pagination="true"
              :paginationData="paginationData"
              @setPage="setPage"
              @setPerPage="setPerPage"
            >
              <div slot="ultimoDetalhe" slot-scope="{ row }">
                <table-details :row="row" />
              </div>
            </data-table>
          </div>
        </template>
      </page-painel>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/tables/DataTable.vue";
import ContratosService from "@/services/ContratosService";
import PagePainel from "@/components/panels/PagePanel.vue";
import FieldSearch from "@/components/fields/FieldSearch.vue";
import TableDetails from "./components/tables/TableDetails.vue";
import TableContratosNaoImportadosModel from "../../models/faturamento/TableContratosNaoImportadosModel";
export default {
  components: {
    DataTable,
    PagePainel,
    FieldSearch,
    TableDetails,
  },
  data() {
    return {
      loading: false,
      modelLine: new Object(),
      tableLoading: undefined,
      paginationData: undefined,
      tableDataContratos: new Array(),
      filters: {
        page: 0,
        size: 10,
        desc: true,
        filtro: null,
      },
      tableContratosNaoImportados: new TableContratosNaoImportadosModel(),
    };
  },
  mounted() {
    this.getContratosNaoImportados();
  },
  methods: {
    setPage(page) {
      this.filters.page = page;
      this.getContratosNaoImportados();
    },
    setPerPage(size) {
      this.filters.size = size;
      this.getContratosNaoImportados();
    },
    getContratosNaoImportados(resetPage = false) {
      if (resetPage) {
        this.filters.page = 0;
      }
      this.tableLoading = ContratosService.getContratosNaoImportados({
        ...this.filters,
      })
        .then((response) => {
          if (response.data) {
            response.data.content = response.data.content.map(
              (item) => new TableContratosNaoImportadosModel(item)
            );
            this.tableDataContratos = response.data.content;
            this.paginationData = response.data;
          } else {
            this.tableDataContratos = new Array();
            this.paginationData = undefined;
          }
        })
        .catch((error) => this.mxHandleRequestError(error, "NAO_IMPORTADOS"));
    },
    getSearchValue(value) {
      this.filters.filtro = value;
      this.getContratosNaoImportados(true);
    },
  },
};
</script>
<style lang="scss" scoped>
.page-faturamento {
  padding-top: 16px;
  &__panel {
    margin-top: 32px;
  }
  &__body {
    padding: 15px 40px;
  }
}
.btn-common {
  width: 194px;
  padding: 8px 16px;
  font-weight: 700;
  border-radius: 9px;
}
.btn-margin {
  margin-right: 20px;
}
.btn-faturamento {
  margin-right: 15rem;
}

.fieldSearch {
  margin-top: 0px;
  margin-bottom: 20px;
}
</style>
