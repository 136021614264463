<template>
  <div
    class="_search"
    :style="[
      customStyle,
      {
        ...(disabled && {
          backgroundColor: '#e6e6e6',
        }),
      },
    ]"
  >
    <input
      v-model.trim="content"
      @input="handleSearchValue"
      @keyup.enter="input"
      @keydown.tab="input"
      :placeholder="placeholder"
      :style="{ width: `${widthInput}%` }"
      data-cy="input-field_search-fields"
      :disabled="disabled"
    />
    <img
      @click="input"
      alt="Icon Search"
      :style="
        customImgSize && {
          width: customImgSize.size,
          margin: customImgSize.margin,
        }
      "
      data-testid="img-buscar-field-search"
      src="@/assets/icons/svg/lupa.svg"
      data-cy="img-procurar_field_search-fields"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: "",
    };
  },
  props: {
    placeholder: {
      type: String,
    },
    widthInput: {
      type: Number,
      default: 95,
    },
    isOnlyNumber: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    customImgSize: {
      type: Object,
      default: undefined,
    },
    value: {
      type: String,
      default: "",
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSearchValue() {
      if (this.isOnlyNumber) {
        const valueFormatter = this.content.replace(/\D/g, "");
        this.content = valueFormatter;
      }
      this.$emit("input", this.content);
    },
    input() {
      this.$emit("getSearchValue", this.content);
    },
  },
  watch: {
    value: {
      handler() {
        this.content = this.value;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/colors";
._search {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;

  background: #fffaf8;

  margin-top: 20px;

  border-radius: 10px;
  border: 1px solid #b4b7ba;

  input {
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 1px;
    color: $color--primary-font;
    padding: 10px;
    border: none;
    outline: none;
    background: none;
    @include ml-hd {
      width: responsive(20px);
      font-size: responsive(18px);
      padding: responsive(10px);
    }
  }

  img {
    width: 20px;
    margin-right: 7px;
    @include ml-hd {
      width: responsive(20px);
    }
    cursor: pointer;
  }
}
</style>
