import { Buffer } from "buffer";

const auth = {
  state: {
    token: null,
    usuario: new Object(),
    permissoes: null,
    permissoesPowerBI: null,
  },
  getters: {
    getUsuario(state) {
      if (Object.keys(state.usuario).length) {
        const decodedUser = JSON.parse(
          Buffer.from(state.usuario, "base64").toString("utf8")
        );
        return decodedUser;
      }
      return state.usuario;
    },
    getPermissoes(state) {
      if (state.permissoes) {
        const decodedPermissions = JSON.parse(
          Buffer.from(state.permissoes, "base64").toString("utf8")
        );

        return decodedPermissions;
      }
      return state.permissoes;
    },
    getPermissoesPowerBI(state) {
      if (state.permissoesPowerBI) {
        const decodedPowerBIPermissions = JSON.parse(
          Buffer.from(state.permissoesPowerBI, "base64").toString("utf8")
        );

        return decodedPowerBIPermissions;
      }
      return state.permissoesPowerBI;
    },
  },
  mutations: {
    setToken(state, data) {
      state.token = data;
      window.localStorage.setItem("b-token", data);
    },
    setUsuario(state, data) {
      if (state.token && data) {
        const base64User = Buffer.from(JSON.stringify(data)).toString("base64");
        state.usuario = base64User;
        window.localStorage.setItem("u-keys", base64User);
      } else {
        state.usuario = null;
        window.localStorage.removeItem("u-keys");
      }
    },
    setPermissoes(state, data) {
      if (state.token && data) {
        const base64Permissions = Buffer.from(JSON.stringify(data)).toString(
          "base64"
        );
        state.permissoes = base64Permissions;
        window.localStorage.setItem("p-keys", base64Permissions);
      } else {
        state.permissoes = null;
        window.localStorage.removeItem("p-keys");
      }
    },
    setPermissoesPowerBI(state, data) {
      if (state.token && data) {
        const base64PowerBIPermissions = Buffer.from(
          JSON.stringify(data)
        ).toString("base64");
        state.permissoesPowerBI = base64PowerBIPermissions;
        window.localStorage.setItem("bi-keys", base64PowerBIPermissions);
      } else {
        state.permissoesPowerBI = null;
        window.localStorage.removeItem("bi-keys");
      }
    },
  },
  actions: {
    ActionSetToken(context, value) {
      context.commit("setToken", value);
      if (!value) {
        window.localStorage.removeItem("b-token");
      }
    },
    ActionSetUsuario(context, value) {
      context.commit("setUsuario", value);
      if (!context.state.token || !value) {
        window.localStorage.removeItem("u-keys");
      }
    },
    ActionSetPermissoes(context, value) {
      context.commit("setPermissoes", value);
      if (!context.state.token || !value) {
        window.localStorage.removeItem("p-keys");
      }
    },
    ActionSetPermissoesPowerBI(context, value) {
      context.commit("setPermissoesPowerBI", value);
      if (!context.state.token || !value) {
        window.localStorage.removeItem("p-keys");
      }
    },
  },
};

export default auth;
