import FaturamentoEmpresaModel from "@/models/faturamento/FaturamentoEmpresaModel";
import TableFaturamentoContratosResumoModel from "@/models/faturamento/TableFaturamentoContratosResumoModel";
import Services from "@/services/Services";

class FaturamentoService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  async getContratosResumo(request) {
    this._url = "/contratos/resumo";

    const { data } = await this.get(request, true);
    data.content = data.content.map(
      (item) =>
        new TableFaturamentoContratosResumoModel({
          ...item,
          categoria: item.statusAtual?.statusGrupo?.descricao,
          parametrizacao: item.empresa.parametrizacao,
        })
    );
    return data;
  }

  getQuantidade(request) {
    this._url = "/contratos/resumo/quantidade";
    return this.get(request, true);
  }

  getQuantidadeWBC(request) {
    this._url = "/contratos/resumo/quantidade/wbc";
    return this.get(request, true);
  }

  async getFaturamentoEmpresaById(request) {
    this._url = `/empresas/${request.id}/contratos`;

    const { data } = await this.get(request);
    const response = data.map((item) =>
      new FaturamentoEmpresaModel(item).getData()
    );
    return response;
  }

  getBotoesAcoes(idContrato) {
    this._url = `/contratos/${idContrato}/acoes/botoes`;
    return this.get();
  }

  getContratosFilhos(idContrato) {
    this._url = `/contratos/${idContrato}/filhos`;
    return this.get();
  }

  getResponsaveis(request) {
    this._url = "/contratos/resumo/responsaveis";
    return this.get(request);
  }
  extrator(request, completo = false) {
    this._url = "contratos/extrator/enviar-email";
    return this.post(request, false);
  }
}

export default new FaturamentoService("/faturamento");
