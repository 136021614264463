<template>
  <div>
    <card-base class="margin-card">
      <template #header>
        <span>
          Contrato: <b>{{ ponto.contrato.codigo }}</b> - Referência:
          <b>{{ PONTO_RATEIO }}</b>
        </span>
      </template>
      <template #body>
        <overlay-loader v-if="loading" />
        <table-empresa
          :razaoSocial="ponto.razaoSocial"
          :apelido="ponto.apelido"
          :cnpj="ponto.cnpj | CNPJ"
        />
        <div class="d-flex container-fields" v-if="CAN_SHOW_ACTIONS">
          <field-habilitar-ponto
            :disabled="!$can('ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR_EDITAR')"
            :enabled="ponto.pontoHabilitado"
            @enableScore="enableScore"
          />
          <fields-ponto-medicao
            :precision="3"
            :isDisabled="
              !ponto.pontoHabilitado ||
              !$can('ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR_EDITAR')
            "
            :value="ponto.percentualFatorAtendimento"
            @changeFieldPoint="changeFieldPoint"
            :label="'Fator Atend. Carga (%)'"
            ref="fatorAtendimentoRef"
          />
          <fields-ponto-medicao
            :isDisabled="
              !ponto.pontoHabilitado ||
              !$can('ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR_EDITAR')
            "
            :value="ponto.percentualPerda"
            @changeFieldPoint="changeFieldPoint"
            :label="'% de Perda'"
            ref="porcentagemPerdaRef"
          />
          <field-ponto-medicao
            :isDisabled="
              !ponto.pontoHabilitado ||
              !$can('ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR_EDITAR')
            "
            :pontoMedicao="ponto"
            @changeFieldPoint="changeFieldPoint"
            ref="pontoMedicaoRef"
          />
          <button
            :disabled="
              selectedPoint ||
              !$can('ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR_EDITAR')
            "
            @click="savePoint"
            class="btn btn-primary-full-filled btn-save"
            data-cy="button-associar_ponto_medicao-ponto-medicao-components-fields"
          >
            Salvar
          </button>
        </div>
      </template>
    </card-base>
  </div>
</template>

<script>
import OverlayLoader from "@/components/OverlayLoader.vue";
import cardBase from "@/components/cards/cardBase.vue";
import { CONFIRMATION } from "@/constants/strings";
import { CNPJ } from "@/filters/formatters";
import ContratosService from "@/services/ContratosService";
import PontosMedicaoService from "@/services/PontosMedicaoService";
import FieldHabilitarPonto from "../components/fields/FieldHabilitarPonto.vue";
import FieldPontoMedicao from "../components/fields/FieldPontoMedicao.vue";
import FieldsPontoMedicao from "../components/fields/FieldsPontoMedicao.vue";
import TableEmpresa from "../components/tables/TableEmpresa.vue";

export default {
  props: {
    ponto: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      saved: false,
      selectedPoint: true,
    };
  },
  methods: {
    getPontosMedicao() {
      this.$emit("getAllPontosMedicao");
    },
    enableScore(checked) {
      this.ponto.pontoHabilitado = checked;

      if (checked) {
        this.changeFieldPoint();
        return;
      }

      const { id } = this.ponto.contrato;
      ContratosService.desassociarPonto({ id })
        .then(() => {
          this.mxToggleToast({
            message: `Contrato: ${this.ponto.contrato.codigo}. Ponto de Medição desabilitado`,
          });
          this.getPontosMedicao();
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    changeFieldPoint(value) {
      this.selectedPoint = value === "";
    },
    savePoint() {
      const request = {
        idPonto: this.ponto.id,
        body: {
          percentualFatorAtendimento: this.$refs.fatorAtendimentoRef.getValue(),
          percentualPerda: this.$refs.porcentagemPerdaRef.getValue(),
          pontoMedicao: {
            id: this.$refs.pontoMedicaoRef.selectOption?.id || null,
          },
        },
      };
      if (!request.body.percentualFatorAtendimento) {
        return this.mxToggleErrorMessageAlert(
          "Campo Fator de Atendimento Carga deve ter valor maior ou igual à 0.1"
        );
      }
      if (!request.body.pontoMedicao.id) {
        return this.mxToggleErrorMessageAlert(
          "Selecione um ponto de Medição válido"
        );
      }

      this.loading = true;
      const message = `Esse faturamento se encontra no status - ${this.ponto.status.descricao} - e será recalculado, deseja continuar ?`;
      this.mxToggleWarningModal({
        message,
        btnTrue: "Confirmar",
        btnFalse: "Cancelar",
      })
        .then(() => {
          PontosMedicaoService.salvarPontoMedicao(request)
            .then(() => {
              this.saved = true;
              this.mxToggleToast({
                message: CONFIRMATION.PONTOS_MEDICAO.UPDATE,
              });
            })
            .catch((error) => this.mxHandleRequestError(error))
            .finally(() => (this.loading = false));
        })
        .catch(() => (this.loading = false));
    },
  },
  computed: {
    disabled() {
      if (this.ponto.pontos.length === 0) {
        return true;
      }
      if (this.ponto.status.descricao === "Cadastro Pendente") {
        return true;
      }
      if (!this.saved) {
        return true;
      }
      return false;
    },
    PONTO_RATEIO() {
      return this.ponto && this.ponto.rateio ? this.ponto.referencia : "--";
    },
    CAN_SHOW_ACTIONS() {
      return this.ponto.tipoClasse !== "PAI";
    },
  },
  filters: {
    CNPJ,
  },
  components: {
    cardBase,
    TableEmpresa,
    OverlayLoader,
    FieldPontoMedicao,
    FieldsPontoMedicao,
    FieldHabilitarPonto,
  },
};
</script>

<style lang="scss" scoped>
.container-fields {
  @media screen and (max-width: 1300px) {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
}

.margin-card {
  margin-top: 40px;
  position: relative;
}

.btn-save {
  width: 163px;
  height: 40px;
  border-radius: 12px;
  padding: 16px auto 16px auto;
  margin-top: 30px;
  margin-left: 50px;

  @media screen and (max-width: 1300px) {
    width: 100%;

    margin-left: 0px;
  }
}

span {
  font-weight: 300;
  b {
    font-weight: bold;
  }
}
</style>
