<template>
  <div class="container-condicao-pagamento-field">
    <textarea
      cols="45"
      rows="10"
      class="form-control"
      :disabled="disabled"
      v-model="model.content"
      id="condicaoPagamentoField"
      ref="condicaoPagamentoField"
      :class="{ 'is-disabled': disabled }"
      data-cy="text-area-condicao_pagamento_curto-prazo"
    ></textarea>
  </div>
</template>
<script>
export default {
  props: {
    disabled: { type: Boolean },
    modelProp: { type: Object },
  },
  data() {
    return {
      model: {
        name: "condicaoPagamento",
        content: "",
      },
    };
  },
  watch: {
    modelProp: {
      deep: true,
      immediate: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
textarea::placeholder {
  font-size: 12px;
}

textarea {
  resize: none;
  border-radius: 12px;
}

.is-disabled {
  color: #9c9ea1 !important;
  background: #f2edeb !important;
}
</style>
