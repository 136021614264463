import PropostaCurtoPrazoModel from "../PropostaCurtoPrazoModel";
import { EMPTY ,VOLUME_MWH } from "@/filters/formatters";


export default class TableCurtoPrazoModel extends PropostaCurtoPrazoModel {
  getTableFields() {
    return new Array(
      {
        name: "apelido",
        title: "Apelido",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "codigo",
        title: "Contrato Ref.",
        formatter(value) {
          return EMPTY(value);
        },
      },

      {
        name: "volumeCurtoPrazo",
        title: "Volume",
        formatter(value) {
          return  VOLUME_MWH(value);
        },
      },
      {
        name: "boletagemWbc",
        title: "Boletagem WBC",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "status",
        title: "Status Proposta",
        formatter(value) {
          return EMPTY(value);
        },
      }
    );
  }
}
