import Services from "@/services/Services";

class DemonstrativoDGService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  async getDemonstrativoDG(id) {
    this._url = `/${id}`;
    const response = await this.get();
    return response.data ?? response;
  }
}

export default new DemonstrativoDGService("/demonstrativo/desconto-garantido");
