<template>
  <div class="filter-container">
    <div class="wrapper-btn-active">
      <button
        type="button"
        @click="isVisibleFilter = !isVisibleFilter"
        :class="[{ 'actived-buttton': isVisibleFilter }, 'filter-button']"
        data-cy="div-processing_count-faturamento-components-filter"
      >
        <span class="icon-filter"></span>
        <span>Filtros</span>
      </button>
    </div>
    <div v-show="isVisibleFilter" class="box-options-filter">
      <field-filter
        ref="responsavelRef"
        labelText="Responsável"
        :modelProp="modelProp.responsavel"
        :optionsFilter="responsavelOptions"
      />
      <field-filter
        ref="takesRef"
        labelText="Takes"
        :modelProp="modelProp.take"
        :optionsFilter="takesOptions"
      />
      <field-filter
        ref="envioRef"
        labelText="Envio"
        :modelProp="modelProp.envio"
        :optionsFilter="envioOptions"
      />
      <field-filter
        ref="statusRef"
        labelText="Status"
        :modelProp="modelProp.status"
        :optionsFilter="statusOptions"
      />
      <field-filter
        ref="detalhesRef"
        labelText="Detalhes"
        :modelProp="modelProp.detalhes"
        :optionsFilter="detalhesOptions"
      />
      <check-box-filter
        :modelProp="modelProp.rateio"
        @getCheckedStatus="getCheckedStatus"
        type="COM_RATEIO"
        ref="comRateioRef"
        textLabel="Com Rateio"
      />
      <check-box-filter
        :modelProp="modelProp.rateio == false"
        @getCheckedStatus="getCheckedStatus"
        type="SEM_RATEIO"
        ref="semRateioRef"
        textLabel="Sem Rateio"
      />
      <button
        type="button"
        @click="clearFilters"
        :disabled="parentIsLoading"
        class="btn btn-primary-outlined button-filter"
        data-cy="button-filter_bar_limpar-faturamento-components-filter"
      >
        Limpar
      </button>
      <button
        type="button"
        @click="applyFilters"
        :disabled="parentIsLoading"
        class="btn btn-primary-full-filled button-filter apply-filter-button"
        data-cy="button-filter_bar_aplicar-faturamento-components-filter"
      >
        Aplicar
      </button>
    </div>
  </div>
</template>

<script>
import DetalhesContratoENUM from "@/enums/DetalhesContratoENUM";
import EnvioFiltroENUM from "@/enums/EnvioFiltroENUM";
import TakesFiltroENUM from "@/enums/TakesFiltroENUM";
import CheckBoxFilter from "@/pages/faturamento/components/filter/fields/CheckBoxFilter.vue";
import FieldFilter from "@/pages/faturamento/components/filter/fields/FieldFilter.vue";
import ContratosService from "@/services/ContratosService";
import FaturamentoService from "@/services/FaturamentoService";
import { Buffer } from "buffer";

export default {
  props: {
    filtersBundle: Object,
    parentIsLoading: Boolean,
  },
  data() {
    return {
      isVisibleFilter: false,
      modelProp: {
        responsavel: null,
        take: null,
        envio: null,
        status: null,
        rateio: undefined,
        detalhe: null,
      },
      statusOptions: new Array(),
      responsavelOptions: new Array(),
      takesOptions: TakesFiltroENUM.TAKES_OPTIONS,
      envioOptions: EnvioFiltroENUM.ENVIO_OPTIONS,
      detalhesOptions: DetalhesContratoENUM,
    };
  },
  watch: {
    filtersBundle: {
      deep: true,
      handler() {
        if (!!this.filtersBundle) {
          this.isVisibleFilter = true;
          this.mountInitialFilters(this.filtersBundle);
        }
      },
    },
  },
  mounted() {
    this.getStatusFields();
    this.getResponsavel();
  },
  methods: {
    getCheckedStatus(payload) {
      const { comRateioRef, semRateioRef } = this.$refs;
      const { type, value } = payload;
      if (value) {
        if (type === "COM_RATEIO") {
          semRateioRef.content = false;
        }
        if (type === "SEM_RATEIO") {
          comRateioRef.content = false;
        }
      }
    },
    mountInitialFilters(filters = {}) {
      Object.keys(filters).forEach((key) => {
        this.modelProp[key] = filters[key];
      });
    },
    getRateioFilter() {
      const { comRateioRef, semRateioRef } = this.$refs;
      if (comRateioRef.getValue()) {
        return true;
      } else if (semRateioRef.getValue()) {
        return false;
      } else {
        return null;
      }
    },
    clearFilters() {
      const {
        responsavelRef,
        takesRef,
        envioRef,
        statusRef,
        comRateioRef,
        semRateioRef,
        detalhesRef,
      } = this.$refs;

      responsavelRef.clear();
      takesRef.clear();
      envioRef.clear();
      statusRef.clear();
      comRateioRef.clear();
      semRateioRef.clear();
      detalhesRef.clear();

      window.sessionStorage.removeItem("f-keys");
      this.$emit("filterClear");
    },
    applyFilters() {
      const { responsavelRef, takesRef, envioRef, statusRef, detalhesRef } =
        this.$refs;
      const responsavel = responsavelRef?.getValue();
      const take = takesRef.getValue()?.toUpperCase();
      const envio = envioRef.getValue()?.toUpperCase();
      const status = statusRef.getValue();
      const detalhe = detalhesRef.getValue();

      let values = {
        responsavel,
        take,
        envio,
        status,
        detalhe,
        rateio: this.getRateioFilter(),
      };
      Object.keys(values).map((key) => {
        if (
          values[key] == null ||
          values[key] == undefined ||
          (!values[key] && typeof values[key] !== "boolean")
        )
          delete values[key];
      });
      this.$emit("applyFilter", values);

      window.sessionStorage.setItem(
        "f-keys",
        Buffer.from(JSON.stringify(values)).toString("base64")
      );
    },
    getStatusFields() {
      ContratosService.getStatusFaturamento()
        .then(({ data }) => {
          this.statusOptions = data.data.map((item) => item);
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    getResponsavel() {
      FaturamentoService.getResponsaveis()
        .then(({ data }) => {
          this.responsavelOptions.push({
            id: "SEM_RESPONSAVEL",
            descricao: "Sem Responsável",
          });
          data.forEach((item) => {
            this.responsavelOptions.push({
              id: item.toLowerCase(),
              descricao: item,
            });
          });
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
  },
  components: {
    FieldFilter,
    CheckBoxFilter,
  },
};
</script>

<style lang="scss" scoped>
.filter-container {
  width: 100%;
}

.wrapper-btn-active {
  position: relative;
  padding: 0 !important;
  height: 42px;
  @include ml-hd {
    height: responsive(42px);
  }
  button {
    position: absolute;
    right: 0px;
  }
}

.filter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 137px;
  height: 40px;
  margin: 0 30px 30px 0;
  border-radius: 20px;
  border: 2px solid #2a2a2a;
  @include ml-hd {
    height: responsive(40px);
    width: responsive(137px);
  }
  span {
    margin-right: 10px;
    color: black;
    font-weight: 700;
    font-size: 16px;
    @include ml-hd {
      font-size: responsive(16px);
    }
  }
}

.actived-buttton {
  background: #0f214a;
  border: none;
  span {
    color: white;
  }
}

.box-options-filter {
  display: flex;
  align-items: center;
  width: 100%;
  height: 126px;
  padding: 0px 42px 15px 28px;
  border-radius: 40px;
  background: #fffaf8;
  margin-top: 14px;
  @include ml-hd {
    height: responsive(126px);
    border-radius: responsive(40px);
    padding: 0px 33.6px 18px 22.4px;
  }
}

.button-filter {
  width: 10%;
  padding: 8px;
  margin-top: 25px;
  margin-left: 19px;
  background: #fffaf8;
  border: 1px solid #ec6623;
  border-radius: 9px;
  color: #ec6623;
  font-weight: bold;
  @include ml-hd {
    padding: responsive(8px);
    border-radius: responsive(9px);
  }
}

.apply-filter-button {
  color: white;
  background: #ec6623;
}
</style>
