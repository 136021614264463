<template>
  <div class="table-card subtitled-only" v-if="config">
    <div class="table-title">Fatura no Mercado CATIVO</div>
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>Dado</th>
          <th>Unidade</th>
          <th>Medição Simulada</th>
          <th>Medição Apurada</th>
          <th>Tarifa s/ Impostos - Sim.</th>
          <th>Tarifa s/ Impostos - Apurada</th>
          <th>Ajuste</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Consumo Ponta</td>
          <td>MWh</td>
          <td>{{ config.consumoPontaMl | truncateRound(4, 3) }}</td>
          <td>{{ config.cep | truncateRound(4, 3) }}</td>
          <td>{{ config.tarSmScdeEp | truncateRound(3, 2) | R$ }}</td>
          <td>{{ config.tarEfScdeEp | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.ajScdeEp | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Consumo Fora Ponta</td>
          <td>MWh</td>
          <td>{{ config.consumoForaPontaMl | truncateRound(4, 3) }}</td>
          <td>{{ config.consumoForaPontaAp | truncateRound(4, 3) }}</td>
          <td>{{ config.tarConsumoForaPontaSm | truncateRound(3, 2) | R$ }}</td>
          <td>{{ config.tarConsumoForaPontaAp | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.ajConsumoForaPonta | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Demanda - Ponta</td>
          <td>kW</td>
          <td>{{ config.demandaPontaSm | truncateRound(4, 3) }}</td>
          <td>{{ config.demandaPontaAp | truncateRound(4, 3) }}</td>
          <td>{{ config.tarDemandaPontaSm | truncateRound(3, 2) | R$ }}</td>
          <td>{{ config.tarDemandaPontaAp | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.ajDemandaPonta | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Demanda - Fora Ponta</td>
          <td>kW</td>
          <td>{{ config.demandaForaPontaSm | truncateRound(4, 3) }}</td>
          <td>{{ config.demandaForaPontaAp | truncateRound(4, 3) }}</td>
          <td>{{ config.tarDemandaForaPontaSm | truncateRound(3, 2) | R$ }}</td>
          <td>{{ config.tarDemandaForaPontaAp | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.ajDemandaForaPonta | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Demanda não utilizada - Ponta</td>
          <td>kW</td>
          <td>--</td>
          <td>{{ config.demandaNUtilPontaAp | truncateRound(4, 3) }}</td>
          <td>
            {{ config.tarDemandaNUtilPontaSm | truncateRound(3, 2) | R$ }}
          </td>
          <td>
            {{ config.tarDemandaNUtilPontaAp | truncateRound(3, 2) | R$ }}
          </td>
          <td class="table-bold">
            {{ config.ajDemandaNUtilPonta | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Demanda não utilizada - Fora Ponta</td>
          <td>kW</td>
          <td>--</td>
          <td>{{ config.demandaNUtilForaPontaAp | truncateRound(4, 3) }}</td>
          <td>
            {{ config.tarDemandaNUtilForaPontaSm | truncateRound(3, 2) | R$ }}
          </td>
          <td>
            {{ config.tarDemandaNUtilForaPontaAp | truncateRound(3, 2) | R$ }}
          </td>
          <td class="table-bold">
            {{ config.ajDemandaNUtilForaPonta | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Adicional Bandeira Tarifária</td>
          <td>R$</td>
          <td>{{ config.bandeiraTarifariaSm | truncateRound(4, 3) }}</td>
          <td>{{ config.bandeiraTarifariaAp | truncateRound(4, 3) }}</td>
          <td>
            {{ config.tarBandeiraTarifariaSm | truncateRound(3, 2) | R$ }}
          </td>
          <td>
            {{ config.tarBandeiraTarifariaAp | truncateRound(3, 2) | R$ }}
          </td>
          <td class="table-bold">
            {{ config.ajBandeiraTarifaria | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Impostos</td>
          <td>R$</td>
          <td>{{ config.impostosSm | moeda }}</td>
          <td>{{ config.impostosAp | truncateRound(4, 3) }}</td>
          <td>--</td>
          <td>--</td>
          <td class="table-bold">
            {{ config.ajImpostos | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr class="table-bold white-row">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ config.concatDisco }}</td>
          <td class="table-total">
            {{ config.ajTotAcr | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["config"],
};
</script>
<style lang="scss" scoped>
.table-card {
  margin-top: 25px;
  border-radius: 0.3em;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  box-shadow: 2px 2px 5px 0 #2a2a2a22;
}
.table-title {
  flex: 1;
  display: flex;
  padding: 16px 24px;
  font-weight: 700;
  color: #ffffff;
  font-size: 1.5rem;
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 0.3em 0.3em 0 0;
  border-bottom: 1px solid #f86511;
}
table {
  border-collapse: collapse;
  margin: 0;
  font-size: 0.9rem;
  border-radius: 0 0 0.3em 0.3em;

  thead {
    background-color: #e8e8e8;
  }

  tbody {
    tr {
      width: 100%;
      &:nth-child(even) {
        background-color: #f2edeb;
      }
      &:last-child {
        border-radius: 0 0 0.3em 0.3em;
        :first-child {
          border-radius: 0 0 0 0.3em;
        }
      }
    }
  }

  .white-row {
    background-color: #ffffff !important;
  }

  .table-bold {
    font-weight: 700;
  }

  .table-total {
    background-color: #fff1ec;
    border-top: solid 1px #f86511;
    border-radius: 0 0 0.3em 0;
  }
}
</style>
