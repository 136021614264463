<template>
  <div class="app-container" :style="mxIsChromeBrowser() && 'height: 117.6vh'">
    <div
      class="app-container__sidebar"
      :class="{ 'app-container--sidebar-open': getNavigationShow }"
    >
      <slot name="sidebar" />
    </div>
    <alert-message
      v-for="(pendency, i) in getPendencies"
      :key="`pendencie_${i}`"
      :index="i"
      :type="pendency.type"
      v-bind="pendency.props"
    />
    <files-error-modal
      v-if="getFilesErrorModal.on"
      v-bind="getFilesErrorModal.props"
    />
    <transition name="fade">
      <div
        @click.once="toggle"
        v-if="getNavigationShow"
        class="app-container__modal-block"
        data-cy="div-app_container-app-container"
      />
    </transition>
    <div class="app-container__body">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
import AlertMessage from "@/components/AlertMessage.vue";
import FilesErrorModal from "@/components/modals/FilesErrorModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AppContainer",
  computed: {
    ...mapGetters(["getPendencies", "getNavigationShow", "getFilesErrorModal"]),
  },
  methods: {
    toggle() {
      this.$store.dispatch("toggleNavigation");
    },
    ...mapActions(["ActionResetPendencies"]),
  },
  watch: {
    "$route.path": {
      handler() {
        this.ActionResetPendencies(null);
      },
    },
  },
  components: {
    AlertMessage,
    FilesErrorModal,
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;
  background: #eee;
  padding-left: 114px;
  transition: padding-left 0.5s;
  @include ml-hd {
    padding-left: responsive(114px);
  }
  &__sidebar {
    top: 0;
    left: 0;
    height: 100%;
    z-index: 999;
    width: 114px;
    position: absolute;
    background: #fff;
    transition: width 0.5s !important;
    box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
    -webkit-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
    @include ml-hd {
      width: responsive(114px);
    }
  }
  &--sidebar-open {
    width: 290px;
    @include ml-hd {
      width: responsive(290px);
    }
  }
  &__body {
    width: 100%;
    overflow: hidden;
  }
  &__modal-block {
    width: 100%;
    height: 117.6vh;
    position: fixed;
    z-index: 998 !important;
    background: rgba($color: #000000, $alpha: 0.5);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
