import { CURRENCY, VOLUME_MWH } from "@/filters/formatters";

export default class TableAprovacaoRecompraModel {
  static getTableFields() {
    return new Array(
      {
        name: "contrato",
        title: "Contrato",
      },
      {
        name: "submercado",
        title: "Subm.",
      },
      {
        name: "tipoEnergia",
        title: "Tipo de Energia",
      },
      {
        name: "pld",
        title: "PLD",
        formatter(value) {
          return CURRENCY(value);
        },
      },
      {
        name: "spread",
        title: "Spread de Compra",
        formatter(value) {
          return CURRENCY(value);
        },
      },
      {
        name: "precoDeCompra",
        title: "Preço de Compra",
        formatter(value) {
          return CURRENCY(value);
        },
      },
      {
        name: "spreadCompraAjustado",
        title: "Spread Ajustado",
        formatter(value) {
          return CURRENCY(value);
        },
      },
      {
        name: "precoFinal",
        title: "Preço Ajustado",
        formatter(value) {
          return CURRENCY(value);
        },
      },
      {
        name: "volumeFinal",
        title: "Novo Volume",
        formatter(value) {
          return VOLUME_MWH(value);
        },
      }
    );
  }
}
