import { moedaFormatter, volumeFormatter } from "@/filters/formatters";

export default class TableRecompraPrecosDiferentes {
  static getTableFields() {
    return new Array(
      {
        name: "codigo",
        title: "Contrato",
      },
      {
        name: "apelido",
        title: "Apelido",
      },
      {
        name: "takeMinimo",
        title: "Distrib. Take Min.",
      },
      {
        name: "volumeReduzir",
        title: "Vol. a Reduzir (MWh)",
      },
      {
        name: "novoVolume",
        title: "Novo Vol. (MWh)",
        formatter: (value) => {
          if (value) {
            return volumeFormatter(value);
          }
          return "--";
        },
      },
      {
        name: "novoPreco",
        title: "Novo Preço",
        formatter: (value) => {
          if (value) {
            return "R$ " + moedaFormatter(value);
          }
          return "R$ --";
        },
      },
      {
        name: "novoNotional",
        title: "Novo Notional",
        formatter: (value) => {
          if (value) {
            return "R$ " + moedaFormatter(value);
          }
          return "R$ --";
        },
      }
    );
  }
}
