<template>
  <div class="_contract-tags">
    <Tag
      v-for="tag in tags"
      v-if="tag.show"
      :key="tag.name"
      :class="tag.class"
      v-html="tag.value"
    />
  </div>
</template>

<script>
import Tag from "@/components/contratos/Tag.vue";

export default {
  props: {
    contrato: Object,
  },
  computed: {
    tags() {
      return [
        {
          name: "Portfólio",
          class: "blue-tag",
          show: !!this.contrato.descricaoPortifolio,
          value: `Portfólio ${this.contrato.descricaoPortifolio}`,
        },
        {
          name: "Tipo",
          class: "blue-tag",
          show: !!this.contrato.tipo,
          value: `Contrato ${this.contrato.tipo}`,
        },
        {
          name: "Tipo de Energia",
          class: "blue-tag",
          show: !!this.contrato.descricaoEnergia,
          value: this.contrato.descricaoEnergia,
        },
        {
          name: "Perfil Agente Contratante",
          class: "blue-tag",
          show:
            !!this.contrato.perfilContratante &&
            !!this.contrato.siglaCceeContratante,
          value: `${this.contrato.siglaCceeContratante.descricao} - ${this.contrato.perfilContratante.descricao}`,
        },
        {
          name: "Demonstrativo Manual",
          class: "blue-tag",
          show: true,
          value:
            this.contrato.empresa?.parametrizacao &&
            !this.contrato.empresa.parametrizacao.envioAutomatico
              ? "Demonstrativo Manual"
              : "Envio Automático",
        },
        {
          name: "DG Aprovação Automática",
          class: "blue-tag",
          show:
            this.contrato.tipo?.toLowerCase().indexOf("desconto garantido") >=
              0 && this.contrato.warnings.length === 0,
          value: "Aprovação Automática",
        },
        {
          name: "Take",
          class: "",
          show:
            this.contrato.take && this.contrato.tipo.toUpperCase() === "FLEX",
          value: `Flex: <b>${this.contrato.take} do take</b>`,
        },
        {
          name: "Descrição",
          class: "",
          show: !!this.contrato.statusAtual?.descricao,
          value: this.contrato.statusAtual.descricao,
        },
      ];
    },
  },
  components: {
    Tag,
  },
};
</script>
<style scoped lang="scss">
._contract-tags {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
}
</style>
