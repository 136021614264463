import axios from "axios";
import HistoricoAcoesModel from "@/models/tablesModels/HistoricoAcoesModel";
class HistoricoAcoesComponentService {
  constructor() {}

  getHistoricoAcoes(link) {
    return new Promise(async (resolve, reject) => {
      try {
        const historico = axios.get(link, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("b-token")}`,
          },
        });

        const response = await historico;
        if (!response.data || !response.data.length) {
          response.data = new Array();
        } else {
          response.data = response.data.map(
            (item) => new HistoricoAcoesModel(item)
          );
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new HistoricoAcoesComponentService();
