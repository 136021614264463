export default class PropostaCurtoPrazoModel {
  constructor(
    {
      id,
      apelido,
      contratoId,
      boletagemWbc,
      codigo,
      responsavel,
      status,
      volumeCurtoPrazo,
    } = new Object()
  ) {
    this._id = id;
    this._apelido = apelido;
    this._contratoId = contratoId;
    this._boletagemWbc = boletagemWbc;
    this._codigo = codigo;
    this._responsavel = responsavel;
    this._status = status;
    this._volumeCurtoPrazo = volumeCurtoPrazo;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id = null) {
    this._id = id;
  }

  get apelido() {
    return this._apelido ?? null;
  }

  set apelido(apelido = null) {
    this._apelido = apelido;
  }

  get contratoId() {
    return this._contratoId ?? null;
  }

  set contratoId(contratoId = null) {
    this._contratoId = contratoId;
  }

  get boletagemWbc() {
    return this._boletagemWbc ?? null;
  }

  set boletagemWbc(boletagemWbc = null) {
    this._boletagemWbc = boletagemWbc;
  }

  get codigo() {
    return this._codigo ?? null;
  }

  set codigo(codigo = null) {
    this._codigo = codigo;
  }

  get responsavel() {
    return this._responsavel ?? null;
  }

  set responsavel(responsavel = null) {
    this._responsavel = responsavel;
  }

  get status() {
    return this._status ?? null;
  }

  set status(status = null) {
    this._status = status;
  }

  get volumeCurtoPrazo() {
    return this._volumeCurtoPrazo ?? null;
  }

  set volumeCurtoPrazo(volumeCurtoPrazo = null) {
    this._volumeCurtoPrazo = volumeCurtoPrazo;
  }

  getData() {
    const {
      id,
      apelido,
      contratoId,
      boletagemWbc,
      codigo,
      responsavel,
      status,
      volumeCurtoPrazo,
    } = this;
    return {
      id,
      apelido,
      contratoId,
      boletagemWbc,
      codigo,
      responsavel,
      status,
      volumeCurtoPrazo,
    };
  }
}
