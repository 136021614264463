<template>
  <div class="_contract-child">
    <div
      @click="show = !show"
      class="_contract-child__header"
      data-cy="div-mostrar_contratos_filhos-modais"
    >
      <div class="_contract-child__header__title">
        <i
          class="icon icon-arrow-down me-3"
          :class="show ? 'turn-down' : 'turn-up'"
        />
        <span> Contratos Filhos: {{ contratosFilhos.length }} </span>
      </div>
      <div class="info-contract-child" v-if="DIFERENCA != 0">
        <label style="font-weight: 900">
          {{
            IS_BIGGER_SOLICITADO_LIQUIDO
              ? "Há um Volume excedente de: "
              : "Falta distribuir: "
          }}
        </label>
        <b :style="[{ color: IS_BIGGER_SOLICITADO_LIQUIDO ? 'red' : 'white' }]">
          {{ DIFERENCA | VOLUME }}
        </b>
        <img
          src="../../assets/icons/svg/warn-icon.svg"
          class="image-danger"
          alt="Danger Image"
        />
      </div>
    </div>
    <div v-if="show" class="_contract-child__body">
      <div class="c_apportionment">
        <data-table
          :stripes="true"
          :footer="true"
          :headerStick="true"
          :maxItemsToScroll="10"
          :data="contratosFilhos"
          :fields="TableContratosFilhosModel.getTableFields()"
        >
          <div
            slot="solicitadoLiquido"
            slot-scope="{ row }"
            v-if="IS_REDISTRIBUIR"
          >
            <vue-numeric
              :minus="true"
              :precision="3"
              placeholder="0,000"
              class="input-field-card"
              :decimal-separator="','"
              :thousand-separator="'.'"
              v-model="row.solicitadoLiquido"
            />
          </div>
          <div slot="solicitadoLiquido" slot-scope="{ row }" v-else>
            <span v-if="row.solicitadoLiquido">
              {{ row.solicitadoLiquido | VOLUME }}</span
            >
            <span v-else>--</span>
          </div>
          <div slot="pontoMedicao" slot-scope="{ row }" v-if="row.pontoMedicao">
            <span
              v-if="row.pontoMedicao.pontoNegadoCcee"
              class="wrapper-ponto-negado"
            >
              {{ row.pontoMedicao.codigo }}
              <img src="@/assets/icons/svg/padlock.svg" class="ic-padlock" />
              <div v-if="row.pontoMedicao.pontoNegadoCcee" class="btn-tooltip">
                <div class="btn-tooltip__arrow"></div>
                <div class="btn-tooltip__body">
                  <span class="text-bold">Ponto sem acesso</span> na
                  <span class="text-bold">CCEE</span>.
                </div>
              </div>
            </span>
            <span v-else>{{ row.pontoMedicao.codigo }}</span>
          </div>
          <div slot="pontoMedicao" v-else><i>Desabilitado</i></div>
          <div slot="proinfa" slot-scope="{ row }" v-if="row.pontoMedicao">
            {{ row.pontoMedicao.proinfa | VOLUME }}
          </div>
          <div slot="proinfa" v-else>--</div>
          <div slot="footer-codigo">
            <b>Total</b>
          </div>
          <div slot="footer-proinfa">
            <b>{{ TOTAL_PROINFA | VOLUME }}</b>
          </div>
          <div slot="footer-calculoFaturamento.apuracao">
            <b>{{ TOTAL_MONTANTE_SCDE | VOLUME }}</b>
          </div>
          <div slot="footer-calculoFaturamento.volumeBruto">
            <b>{{ TOTAL_SOLICITADO_BRUTO | VOLUME }}</b>
          </div>
          <div slot="footer-solicitadoLiquido">
            <b>{{ TOTAL_SOLICITADO_LIQUIDO | VOLUME }}</b>
          </div>
        </data-table>
      </div>
    </div>
  </div>
</template>
<script>
import { VOLUME } from "@/filters/formatters";
import TableContratosFilhosModel from "@/models/tablesModels/TableContratosFilhosModel";
import FaturamentoService from "@/services/FaturamentoService";
import bigDecimal from "js-big-decimal";
import VueNumeric from "vue-numeric";
import DataTable from "../tables/DataTable.vue";

export default {
  props: {
    contrato: {
      type: Object,
      required: true,
    },
    hasAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      show: true,
      data: new Array(),
      contratosFilhos: new Array(),
    };
  },
  mounted() {
    FaturamentoService.getContratosFilhos(this.contrato.id)
      .then(({ data }) => {
        this.contratosFilhos = data.map((contrato) => {
          const { calculoFaturamento } = contrato;
          if (calculoFaturamento) {
            const { volumeLiquido } = calculoFaturamento;
            if (volumeLiquido) {
              contrato.solicitadoLiquido = volumeLiquido;
            }
          }
          return contrato;
        });
      })
      .catch((error) => this.mxHandleRequestError(error));
  },
  methods: {
    isPontoNegadoCcee(row) {
      return row.pontoMedicao.pontoNegadoCcee !== null;
    },
  },
  computed: {
    TableContratosFilhosModel: () => TableContratosFilhosModel,
    TOTAL_PROINFA() {
      if (this.contrato.proinfa) {
        return this.contrato.proinfa;
      }

      return 0;
    },
    TOTAL_SOLICITADO_LIQUIDO() {
      if (this.contratosFilhos.length > 0) {
        const solicitadoLiquidoList = this.contratosFilhos
          .filter((contrato) => contrato.solicitadoLiquido)
          .map(({ solicitadoLiquido }) => solicitadoLiquido);
        return solicitadoLiquidoList.reduce(
          (total, numero) => bigDecimal.add(total, numero),
          0
        );
      }
      return 0;
    },
    TOTAL_SOLICITADO_LIQUIDO_CONTRATO() {
      if (
        this.contrato.calculoResultado &&
        this.contrato.calculoResultado.solicitadoLiquido
      ) {
        return this.contrato.calculoResultado.solicitadoLiquido;
      }

      return 0;
    },
    TOTAL_SOLICITADO_BRUTO() {
      if (
        this.contrato.calculoResultado &&
        this.contrato.calculoResultado.solicitadoBruto
      ) {
        return this.contrato.calculoResultado.solicitadoBruto;
      }

      return 0;
    },
    TOTAL_MONTANTE_SCDE() {
      if (
        this.contrato.calculoResultado &&
        this.contrato.calculoResultado.montanteSCDE
      ) {
        return this.contrato.calculoResultado.montanteSCDE;
      }

      return 0;
    },
    DIFERENCA() {
      if (this.contrato.tipo === "Flat") {
        if (
          this.contrato.flexibilidade &&
          this.contrato.flexibilidade.quantidadeContratada
        ) {
          const difference = bigDecimal.subtract(
            this.contrato.flexibilidade.quantidadeContratada,
            this.TOTAL_SOLICITADO_LIQUIDO
          );

          return Math.abs(difference);
        }
      } else {
        if (this.TOTAL_SOLICITADO_LIQUIDO_CONTRATO === 0) {
          return this.contrato.calculoResultado.solicitadoLiquido;
        }
        if (
          this.contrato.calculoResultado.solicitadoLiquido &&
          this.TOTAL_SOLICITADO_LIQUIDO_CONTRATO
        ) {
          const difference = bigDecimal.subtract(
            this.TOTAL_SOLICITADO_LIQUIDO_CONTRATO,
            this.TOTAL_SOLICITADO_LIQUIDO
          );

          return Math.abs(difference);
        }
      }
    },
    IS_BIGGER_SOLICITADO_LIQUIDO() {
      if (this.contrato && this.contrato.tipo === "Flat") {
        return (
          this.TOTAL_SOLICITADO_LIQUIDO >
          this.contrato.flexibilidade.quantidadeContratada
        );
      } else {
        return (
          this.TOTAL_SOLICITADO_LIQUIDO >
          this.contrato.calculoResultado.solicitadoLiquido
        );
      }
    },
    IS_REDISTRIBUIR() {
      return (
        this.contrato.statusAtual.descricao === "Em Análise pelo Pós-Venda" &&
        this.hasAction("concluir_calculo")
      );
    },
  },
  watch: {
    contratosFilhos: {
      deep: true,
      handler() {
        const contratos = new Array();
        this.contratosFilhos.forEach((contrato) => {
          if (contrato.calculoFaturamento && contrato.calculoFaturamento.id) {
            contratos.push({
              idCalculo: contrato.calculoFaturamento.id,
              volumeLiquido: contrato.solicitadoLiquido ?? 0,
            });
          }
        });
        this.$emit("getContratosFilhosRedis", {
          contratos,
          dif: this.DIFERENCA,
        });
      },
    },
  },
  filters: {
    VOLUME,
  },
  components: {
    DataTable,
    VueNumeric,
  },
};
</script>
<style lang="scss" scoped>
._contract-child {
  &__header {
    width: 100%;
    display: flex;
    cursor: pointer;
    font-size: 1rem;
    padding: 6px 17px;
    border-radius: 24px;
    align-items: center;
    color: $color--primary-white;
    justify-content: space-between;
    background: $color--blue-super-dark;

    &__title {
      display: flex;
      align-items: center;
    }
  }
  &__body {
    padding-top: 10px;
  }
}

.c_apportionment {
  padding: 15px;
  border-radius: 24px;
  background: #fffaf8;
  box-sizing: border-box;
  border: 1px solid #c4c4c4;
}

.info-contract-child {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.image-danger {
  width: 18px;
}

.turn-down {
  transform: rotate(-180deg);
  transition: transform 0.2s linear;
}
.turn-up {
  transform: rotate(0deg);
  transition: transform 0.2s linear;
}

.icon {
  font-size: 1rem;
}

.ic-padlock {
  cursor: pointer;
  margin-left: 10px;
}

.wrapper-ponto-negado {
  position: relative;

  &:hover > .btn-tooltip {
    display: flex;
  }
}

.btn-tooltip {
  top: 28px;
  width: 100%;
  left: 65px;
  z-index: 2000;
  display: none;
  position: absolute;
  align-items: center;
  flex-direction: column;
}

.btn-tooltip__arrow {
  z-index: 10;
  width: 18px;
  height: 18px;
  position: relative;
  background: #bdbdbd;
  transform: rotate(45deg);
}

.btn-tooltip__body {
  z-index: 11;
  width: 200%;
  padding: 10px;
  font-size: 14px;
  color: #2a2a2a;
  margin-top: -10px;
  position: relative;
  border-radius: 4px;
  background: #bdbdbd;
  opacity: 1 !important;

  .text-bold {
    font-weight: 700;
  }
}
</style>
