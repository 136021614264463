<template>
  <div class="page-pronto-medicao">
    <page-painel v-elementAsyncLoader="tableLoading">
      <template #header> Associar Ponto de Medição a Contratos </template>
      <template #body>
        <div class="page-pronto-medicao__body">
          <div class="section-filters">
            <field-search
              style="max-width: 30%"
              :isOnlyNumber="true"
              @getSearchValue="getSearchValue"
              :widthInput="90"
              placeholder="Contrato"
            />
            <check-box-filter
              class="checkbox-filter"
              type="pontoHabilitado"
              :modelProp="modelProp.pontoHabilitado"
              :alignText="5"
              @getCheckedStatus="getCheckedStatus"
              ref="refPontoDesabilitado"
              textLabel="Ponto Desabilitado"
            />
            <check-box-filter
              class="checkbox-filter"
              type="fatorAtendimento"
              :alignText="5"
              :modelProp="modelProp.fatorAtendimento"
              @getCheckedStatus="getCheckedStatus"
              ref="refFatorAtendimento"
              textLabel="Sem Fator Atend Carga"
            />
            <check-box-filter
              class="checkbox-filter"
              type="porcentagemPerda"
              :alignText="5"
              :modelProp="modelProp.porcentagemPerda"
              @getCheckedStatus="getCheckedStatus"
              ref="refPorcentagemPerda"
              textLabel="Sem % de Perdas"
            />
            <check-box-filter
              class="checkbox-filter"
              type="pontoMedicao"
              :alignText="5"
              :modelProp="modelProp.pontoMedicao"
              @getCheckedStatus="getCheckedStatus"
              ref="refpontoMedicao"
              textLabel="Sem Ponto de Medição"
            />
          </div>
          <upload-csv-card
            @sendFormData="
              sendFormDataResult = proinfaService.uploadEmLotes($event)
            "
            :sendFormDataResult="sendFormDataResult"
            label="Ou faça um upload de arquivo"
          />
          <div v-if="empty" class="no-contract">
            Nenhum contrato encontrado.
          </div>
          <div>
            <card-associar-ponto-medicao
              v-for="(item, index) in tableData"
              :ponto="item"
              :key="item.id + 'item' + index"
              @getAllPontosMedicao="getAllPontosMedicao"
            />
            <div class="box-pagination mt-3 mb-4">
              <pagination
                v-if="paginationData && tableData.length > 0"
                @perPage="setPerPage"
                @page="setPage"
                :info="paginationData"
              />
            </div>
          </div>
        </div>
      </template>
    </page-painel>
  </div>
</template>
<script>
import Skeleton from "@/components/Skeleton.vue";
import FieldSearch from "@/components/fields/FieldSearch.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import PagePainel from "@/components/panels/PagePanel.vue";
import UploadCsvCard from "@/components/upload/UploadCsvCard.vue";
import ProinfaService from "@/services/ProinfaService";
import CheckBoxFilter from "../faturamento/components/filter/fields/CheckBoxFilter.vue";
import CardAssociarPontoMedicao from "./components/CardAssociarPontoMedicao.vue";
import PontosMedicaoService from "@/services/PontosMedicaoService";

export default {
  data() {
    return {
      tableData: [],
      empty: false,
      filterParam: null,
      valueSearch: undefined,
      tableLoading: undefined,
      paginationData: undefined,
      proinfaService: ProinfaService,
      sendFormDataResult: undefined,
      paginationFilters: {
        filtro: "",
        page: 0,
        size: 10,
      },
      modelProp: {
        pontoHabilitado: false,
        fatorAtendimento: false,
        porcentagemPerda: false,
        pontoMedicao: false,
      },
    };
  },
  mounted() {
    this.getSearchValue();
  },
  methods: {
    getAllPontosMedicao() {
      this.tableLoading = PontosMedicaoService.getPontosMedicao(
        this.paginationFilters
      )
        .then(({ data, pagination, empty }) => {
          this.tableData = data;
          this.paginationData = pagination;
          this.empty = empty;
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "PONTOS_MEDICAO");
        });
    },
    getSearchValue(value) {
      if (value) {
        this.valueSearch = value
          .trim()
          .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
        this.paginationFilters = {
          ...this.paginationFilters,
          page: 0,
          filtro: this.valueSearch,
        };
        this.getAllPontosMedicao();
      }
    },
    setPerPage(value) {
      this.paginationFilters = {
        ...this.paginationFilters,
        page: 0,
        size: value,
      };
      this.getAllPontosMedicao();
    },
    setPage(page) {
      this.paginationFilters = {
        ...this.paginationFilters,
        page,
      };
      this.getAllPontosMedicao();
    },
    getCheckedStatus(payload) {
      if (this.paginationFilters.filtro === "") {
        return;
      }

      const { type, value } = payload;

      if (value) {
        this.paginationFilters = {
          ...this.paginationFilters,
          [type]: this.modelProp[type],
        };
      } else {
        delete this.paginationFilters[type];
      }

      this.getAllPontosMedicao();
    },
  },
  mounted() {
    if (this.$route.params) {
      this.filterParam = this.$route.params._codigo;
      this.getSearchValue(this.filterParam);
    }
  },
  components: {
    Skeleton,
    PagePainel,
    Pagination,
    FieldSearch,
    UploadCsvCard,
    CheckBoxFilter,
    CardAssociarPontoMedicao,
  },
};
</script>
<style lang="scss" scoped>
.page-pronto-medicao {
  padding-top: 24px;
  &__body {
    padding: 30px;
  }
}
.no-contract {
  margin-top: 10px;
}

.section-filters {
  display: flex;
  align-items: center;
  gap: 20px;

  width: 100%;
}

.checkbox-filter {
  display: flex;
  align-items: flex-start;

  width: fit-content;
  height: 55px;

  font-size: 12px;
}
</style>
