<template>
  <div class="row box-recompra-preco-unico">
    <div class="col-md-12" :class="{ 'without-padding': notPadding }">
      <div class="title-folding">Análise de Recompra - Preço Único</div>
    </div>

    <div class="col-md-4" :class="{ 'without-padding': notPadding }">
      <card-base :colorHeader="'#1F4496'">
        <template #header>Faturamento Sem Recompra</template>
        <template #body>
          <div class="card-body card-body-recompra">
            <div class="_card-contrato-item card-faturamento">
              <div class="card-label">Solic. Líq. (MWh)</div>
              <div class="card-value">
                {{ formatterVolume(dataPrecoUnico.solicitadoLiquido) }}
              </div>
            </div>
            <div class="_card-contrato-item card-faturamento">
              <div class="card-label">Preço</div>
              <div class="card-value">
                {{ formatterCurrency(dataPrecoUnico.preco) }}
              </div>
            </div>
            <div class="_card-contrato-item card-faturamento">
              <div class="card-label">Notional</div>
              <div class="card-value">
                {{ formatterCurrency(dataPrecoUnico.notional) }}
              </div>
            </div>
          </div>
        </template>
      </card-base>
    </div>
    <div class="col-md-4">
      <card-base>
        <template #header>Recompra</template>
        <template #body>
          <div class="card-body card-body-recompra">
            <div class="_card-contrato-item card-faturamento">
              <div class="card-label">Volume (MWh)</div>
              <div class="card-value">
                {{ formatterVolume(dataPrecoUnico.volumeRecompra) }}
              </div>
            </div>
            <div class="_card-contrato-item card-faturamento">
              <div class="card-label">Preço</div>
              <div class="card-value">
                {{ formatterCurrency(dataPrecoUnico.precoRecompra) }}
              </div>
            </div>
            <div class="_card-contrato-item card-faturamento">
              <div class="card-label">Notional</div>
              <div class="card-value">
                {{ formatterCurrency(dataPrecoUnico.notionalRecompra) }}
              </div>
            </div>
          </div>
        </template>
      </card-base>
    </div>
    <div class="col-md-4" :class="{ 'without-padding': notPadding }">
      <card-base :colorHeader="'#EC6623'">
        <template #header>Faturamento Com Recompra</template>
        <template #body>
          <div class="card-body card-body-recompra">
            <div class="_card-contrato-item card-faturamento">
              <div class="card-label">Volume (MWh)</div>
              <div class="card-value">
                {{
                  formatterVolume(dataPrecoUnico.volumeFaturamentoComRecompra)
                }}
              </div>
            </div>
            <div class="_card-contrato-item card-faturamento">
              <div class="card-label">Preço</div>
              <div class="card-value">
                {{
                  formatterCurrency(dataPrecoUnico.precoFaturamentoComRecompra)
                }}
              </div>
            </div>
            <div class="_card-contrato-item card-faturamento">
              <div class="card-label">Notional</div>
              <div class="card-value">
                {{
                  formatterCurrency(
                    dataPrecoUnico.notionalFaturamentoComRecompra
                  )
                }}
              </div>
            </div>
          </div>
        </template>
      </card-base>
    </div>
  </div>
</template>
<script>
import CardBase from "@/components/cards/cardBase.vue";
import { VOLUME, CURRENCY } from "@/filters/formatters";

export default {
  props: {
    notPadding: {
      type: Boolean,
      default: false,
    },
    dataPrecoUnico: {
      type: Object,
      default() {
        return {
          solicitadoLiquido: "",
          volumeRecompra: "",
          volumeFaturamentoComRecompra: "",
          preco: "",
          precoRecompra: "",
          precoFaturamentoComRecompra: "",
          notionalRecompra: "",
          notionalFaturamentoComRecompra: "",
          notional: "",
        };
      },
    },
  },
  methods: {
    formatterVolume(value) {
      if (!value) value = 0;
      return VOLUME(value);
    },
    formatterCurrency(value) {
      if (!value) value = 0;
      return CURRENCY(value);
    },
  },
  components: {
    CardBase,
  },
};
</script>
<style lang="scss" scoped>
.box-recompra-preco-unico {
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
}
.row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

.without-padding {
  padding: 0px;
}

.card-body {
  padding: 0;
  display: flex;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
}
._card-contrato-item {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-faturamento {
  margin-bottom: 15px;
}

.card-label {
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 24px;
  color: #544943;
}
.card-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2a2a2a;
}

.title-folding {
  background: $color--blue-super-dark;
  width: 100%;
  color: $color--white;
  margin-top: 40px;
  padding: 5px 30px;
  border-radius: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
</style>
