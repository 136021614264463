<template>
  <div class="page">
    <overlay-loader style="position: fixed" v-if="loadingPage" />
    <div v-if="spinner" class="mtx-spinner" />
    <div class="breadcrumbs">
      <span class="mr-2">Proposta de Redução de Montantes</span> >
      <span class="mr-2 ml-2">Preços diferentes</span> >
      <span class="mr-2 ml-2" :class="{ 'color-crump': !isSummary }">
        Ajustar Volume
      </span>
    </div>
    <div class="box-reduction-amount">
      <div class="box-head">PROPOSTA DE REDUÇÃO DE MONTANTES</div>
      <div class="box-content">
        <div v-if="!priceIsValidChild(contratosFilhos)" class="alert-msg">
          <div class="alert-icon">
            <img :src="mountImg('/images/icon-alert-triangulo.svg')" />
          </div>
          <div class="alert-text">
            <b>ATENÇÃO: </b>Devido às condições de mercado, favor entrar em
            contato com seu Executivo de Relacionamento para o fechamento da
            operação.
          </div>
        </div>
        <div class="box-infos">
          <div class="info-row info-top mt-4">
            <div class="info-item item-razaoSocial">
              <div class="info-item-title">Submercado:</div>
              <div class="info-item-value">
                <b>{{ SUBMERCADO }} </b>
              </div>
            </div>
            <div class="info-item" />
            <div class="info-item" />
            <div class="info-item">
              <div class="info-item-title">Tipo de Energia:</div>
              <div class="info-item-value">
                <b>{{ TIPO_ENERGIA }} </b>
              </div>
            </div>
          </div>
          <hr />
          <div v-if="!isSummary" class="info-row">
            <div class="info-item">
              <div class="info-item-title">Take Mínimo:</div>
              <div class="info-item-value">
                <b>{{ TAKE_MINIMO_PAI | volume }}</b>
              </div>
            </div>
            <div class="info-item">
              <div class="info-item-title">Consumo Total Apurado:</div>
              <div class="info-item-value">
                <b>{{ CONSUMO_APURADO_PAI | volume }} MWh</b>
              </div>
            </div>
            <div class="info-item">
              <div class="info-item-title">Preço (PLD + Spread):</div>
              <div class="info-item-value">
                <b
                  ><span>
                    (R$
                    {{ PLD_PAI | moeda }}
                    +
                    {{ SPREAD_PAI | moeda }})
                  </span></b
                >
                <br />
                <b
                  >R$
                  {{ PRECO_PAI | moeda }}
                </b>
              </div>
            </div>
            <div class="info-item">
              <div class="info-item-title">Preço válido por:</div>
              <div class="info-item-value">
                <div class="">
                  <timer @finished="timerfinished" :periodo="3599" />
                </div>
                <div class="text-timer">
                  Se fechar ou atualizar a tela, o preço pode sofrer alterações
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-distribution-counter">
          <div class="counter-item"><b>Distribuição por Unidades</b></div>
          <div v-if="!montantIsValid" class="counter-item">
            <span class="color-red">
              Verifique os valores inseridos em "Montante a reduzir" antes de
              prosseguir
            </span>
          </div>
          <div v-if="!isSummary" class="counter-item">
            <span v-if="this.DIFERENCA > 0" class="mr-2">
              Falta distribuir:
            </span>
            <span v-else-if="this.DIFERENCA < 0" class="mr-2">
              Há volume excedente de:
            </span>
            <span v-if="this.DIFERENCA !== 0" style="color: #f7685b">
              {{ this.DIFERENCA | volume }} MWh
            </span>
          </div>
        </div>
        <div style="overflow: auto">
          <div class="box-grid">
            <div class="grid-header">
              <div style="padding-left: 30px" class="grid-cell">CNPJ</div>
              <div class="grid-cell">Razão Social</div>
              <div style="text-align: center" class="grid-cell">
                Consumo Apurado
              </div>
              <div style="text-align: center" class="grid-cell">
                Distribuição do Take Min.
              </div>
              <div style="text-align: center" class="grid-cell">
                Montante a reduzir
              </div>
              <div style="text-align: center" class="grid-cell">
                Novo Montante
              </div>
              <div style="text-align: center" class="grid-cell">
                Preço Original
              </div>
              <div style="text-align: center" class="grid-cell">Novo Preço</div>
            </div>
            <div
              v-for="(contrato, indexContrato) in contratosFilhos"
              :key="`indexContrato${indexContrato}`"
              class="grid-body"
            >
              <div style="padding-left: 30px" class="grid-cell">
                {{ contrato.cnpj | cnpj }}
              </div>
              <div class="grid-cell">{{ contrato.razaoSocial }}</div>
              <div style="text-align: center" class="grid-cell">
                {{ contrato.condicaoDePrecoDto.volumeBruto | volume }}
              </div>
              <div style="text-align: center" class="grid-cell">
                <span v-if="isSummary">{{ contrato.volumeDistribuido }}</span>
                <vue-numeric
                  v-if="!isSummary"
                  v-debounce:50ms="calculate"
                  class="numeric-field-grid"
                  :class="{
                    'border-red': !priceIsValid(contrato.precoNovo),
                  }"
                  currency=""
                  :minus="true"
                  :precision="3"
                  separator="."
                  v-model="contrato.volumeDistribuido"
                />
              </div>
              <div style="text-align: center" class="grid-cell">
                <span v-if="isSummary">{{ contrato.montanteReduzir }}</span>
                <vue-numeric
                  v-if="!isSummary"
                  v-debounce:50ms="calculate"
                  class="numeric-field-grid"
                  :class="{
                    'border-red':
                      contrato.montanteNovo < 0 ||
                      !priceIsValid(contrato.precoNovo),
                  }"
                  currency=""
                  :minus="true"
                  :precision="3"
                  separator="."
                  v-model="contrato.montanteReduzir"
                />
              </div>
              <div
                style="text-align: center"
                :class="{ 'color-red': contrato.montanteNovo < 0 }"
                class="grid-cell"
              >
                {{ formatVolume(contrato.montanteNovo) }}
              </div>
              <div style="text-align: center" class="grid-cell">
                {{ contrato.condicaoDePrecoDto.precoOriginal | moeda }}
              </div>
              <div style="text-align: center" class="grid-cell">
                <span
                  :class="{
                    'color-red': !priceIsValid(contrato.precoNovo),
                  }"
                >
                  R$ {{ formatPrice(contrato.precoNovo) }}
                </span>
              </div>
            </div>
            <div class="grid-total">
              <div style="padding-left: 30px" class="grid-cell">TOTAL</div>
              <div class="grid-cell" />
              <div class="grid-cell" />
              <div style="text-align: center" class="grid-cell">
                {{ formatVolume(TOTAL_TAKE_MIN) }}
              </div>
              <div style="text-align: center" class="grid-cell">
                {{ formatVolume(TOTAL_MONTANTE_REDUZIR) }}
              </div>
              <div style="text-align: center" class="grid-cell">
                {{ formatVolume(TOTAL_NOVO_MONTANTE) }}
              </div>
              <div class="grid-cell" />
              <div class="grid-cell" />
            </div>
          </div>
        </div>
        <div class="box-buttons">
          <button
            @click="deleteStatusTipoRecompra"
            class="btn-action-primary-outlined"
            style="max-width: 300px; margin-right: 30px"
          >
            VOLTAR
          </button>
          <button
            :disabled="
              (this.DIFERENCA != 0 && !isSummary) ||
              (!montantIsValid && !isSummary) ||
              !priceIsValidChild(contratosFilhos)
            "
            @click="next"
            style="max-width: 300px; padding: 5px"
            class="btn-action-primary"
          >
            AVANÇAR
          </button>
        </div>
      </div>
    </div>
    <modal-base
      maxWidth="700px"
      @closeModal="closeModal"
      v-if="showModalFormUser"
    >
      <template #header> APROVAR PROPOSTA DE REDUÇÃO DE MONTANTES </template>
      <template #body>
        <div style="position: relative; padding: 30px">
          <overlay-loader v-if="loading" />
          <div style="text-align: center">
            Para seguir com a confirmação da Proposta de Redução de Montantes,
            por favor insira seu nome e seu e-mail abaixo:
          </div>
          <form-user
            ref="formUserRef"
            @next="efetuarRecompra"
            @getInfosUser="getInfosUser"
          />
        </div>
      </template>
      <template #footer>
        <div class="wrapper-button-user">
          <button
            @click="efetuarRecompra"
            class="btn-action-primary"
            style="max-width: 300px; padding: 8px"
            :disabled="loading"
          >
            Aprovar
          </button>
        </div>
      </template>
    </modal-base>
  </div>
</template>
<script>
import ModalBase from "@/components/modals/ModalBase.vue";
import FormUser from "@/pages/hot-site/components/FormUser.vue";
import Timer from "@/pages/hot-site/components/Timer.vue";
import DemonstrativoService from "@/services/DemonstrativoService";
import {
  divideValues,
  multiplyValues,
  subtractValues,
} from "@/utils/functionsUtils";
import bigDecimal from "js-big-decimal";
import VueNumeric from "vue-numeric";

export default {
  components: {
    Timer,
    VueNumeric,
    ModalBase,
    FormUser,
  },
  props: {
    contratoPrincipal: Object,
  },
  computed: {
    TAKE_MINIMO_PAI() {
      return this.contratoPrincipal?.flexibilidade?.limiteMinimo || 0;
    },
    CONSUMO_APURADO_PAI() {
      return this.contratoPrincipal?.calculoSumarizado?.volumeBruto || 0;
    },
    SUBMERCADO() {
      return this.contratoPrincipal?.submercado?.descricao || "";
    },
    TIPO_ENERGIA() {
      return this.contratoPrincipal?.tipoEnergia?.descricao || "";
    },
    PLD_PAI() {
      return this.contratoPrincipal?.submercado?.pld?.preco || 0;
    },
    SPREAD_PAI() {
      return (
        this.contratoPrincipal?.tipoEnergia?.spreadTipoEnergia?.compra || "--"
      );
    },
    PRECO_PAI() {
      return this.PLD_PAI + this.SPREAD_PAI;
    },
    TOTAL_TAKE_MIN() {
      if (this.contratosFilhos.length === 0) return 0;
      const volumeDistribuidoList = this.contratosFilhos
        .filter((contrato) => contrato.volumeDistribuido)
        .map(({ volumeDistribuido }) => volumeDistribuido);
      return volumeDistribuidoList.reduce(
        (total, numero) => bigDecimal.add(total, numero),
        0
      );
    },
    TOTAL_MONTANTE_REDUZIR() {
      if (this.contratosFilhos.length === 0) return 0;
      const montanteReduzirList = this.contratosFilhos
        .filter((contrato) => contrato.montanteReduzir)
        .map(({ montanteReduzir }) => montanteReduzir);
      return montanteReduzirList.reduce(
        (total, numero) => bigDecimal.add(total, numero, 3),
        0
      );
    },
    TOTAL_NOVO_MONTANTE() {
      if (this.contratosFilhos.length === 0) return 0;
      const montanteNovoList = this.contratosFilhos
        .filter((contrato) => contrato.montanteNovo)
        .map(({ montanteNovo }) => montanteNovo);
      return montanteNovoList.reduce(
        (total, numero) => bigDecimal.add(total, numero, 3),
        0
      );
    },
    TOTAL_VOLUME_LIQUIDO() {
      if (this.contratosFilhos.length === 0) return 0;
      const montanteNovoList = this.contratosFilhos
        .filter((contrato) => contrato.montanteNovo)
        .map(({ montanteNovo }) => montanteNovo);
      return montanteNovoList.reduce(
        (total, numero) => bigDecimal.add(total, numero),
        0
      );
    },
    DIFERENCA() {
      return this.TAKE_MINIMO_PAI - this.TOTAL_TAKE_MIN;
    },
  },
  data() {
    return {
      showModalFormUser: false,
      loading: false,
      loadingPage: false,
      contratosFilhos: [],
      payload: {
        contratosRecompra: [
          {
            contratoRecompra: {
              codigo: "",
              volume: 0,
              preco: 0,
              volumeFinal: 0,
              precoFinal: 0,
            },
            spread: 0,
            pld: 0,
          },
        ],
        nome: "",
        email: "",
      },
      modalPrposalConfirmShow: false,
      modalGetDataUserShow: false,
      spinner: false,
      totalDistribute: 0,
      totalAjuste: 0,
      totalResultante: 0,
      volumeRemains: 0,
      maximumPrice: 650,
      minimumPrice: 49.77,
      recompraMannual: false,
      posvenda: "",
      montantIsValid: true,
      data: [
        {
          isAgrupador: true,
          consumoApuradoTotal: 0,
          contrato: 0,
          precoOriginal: 0,
          takeMinimo: 0,
          cnpj: "",
          razaoSocial: "",
          submercado: "",
          tipoEnergia: "",
          spread: 0,
          pld: 0,
        },
      ],
      isValid: true,
      isPositive: true,
      isSummary: false,
    };
  },
  mounted() {
    this.getRecompraAnalise();
  },
  watch: {
    data: {
      handler() {
        this.calculate();
      },
      deep: true,
    },
  },
  methods: {
    closeModal() {
      this.showModalFormUser = false;
    },
    getRecompraAnalise() {
      this.loadingPage = true;
      DemonstrativoService.getHotsiteRecompraPrecosDiferentesAnalise({
        contratoId: this.contratoPrincipal.id,
      })
        .then((response) => {
          this.loadingPage = false;
          this.contratosFilhos = response.data.map((g) => {
            g.precoAjuste = g.condicaoDePrecoDto.precoDeCompra;
            g.notionalFinal = 0;
            g.notionalOriginal = 0;
            g.notionalRecompra = 0;
            g.precoNovo = 0;
            g.montanteReduzir = "";
            g.volumeDistribuido = 0;
            g.montanteNovo = 0;
            g.volumeDistribuido = g.condicaoDePrecoDto.volumeLiquido;
            g.montanteReduzir =
              g.volumeDistribuido - g.condicaoDePrecoDto.volumeBruto;
            g.montanteNovo = bigDecimal.subtract(
              this.TAKE_MINIMO_PAI,
              g.montanteReduzir
            );

            return g;
          });
          this.calculoInicial();
        })
        .catch(() => {
          this.loadingPage = false;
        });
    },
    calcularContratosFilhos() {
      this.contratosFilhos.forEach((contrato) => {
        contrato.notionalOriginal = multiplyValues(
          contrato.condicaoDePrecoDto.precoOriginal,
          contrato.volumeDistribuido
        );
        contrato.notionalRecompra = multiplyValues(
          contrato.montanteReduzir,
          contrato.precoAjuste
        );
        contrato.notionalFinal = subtractValues(
          contrato.notionalOriginal,
          contrato.notionalRecompra
        );
        contrato.precoNovo = divideValues(
          contrato.notionalFinal,
          subtractValues(contrato.volumeDistribuido, contrato.montanteReduzir)
        );
        contrato.takeMinimo = contrato.volumeDistribuido;
        contrato.montanteNovo = subtractValues(
          contrato.takeMinimo,
          contrato.montanteReduzir
        );
        if (!this.priceIsValid(contrato.precoNovo)) {
          this.isValid = false;
        }
        if (contrato.precoNovo < 0) {
          this.isPositive = false;
        }
        if (contrato.montanteNovo < 0.1) {
          this.newAmountIsValid = false;
        }
      });
    },
    calculoInicial() {
      this.isPositive = true;
      this.isValid = true;
      this.newAmountIsValid = true;

      this.calcularContratosFilhos();

      let distributeChildTemp = 0;
      this.contratosFilhos.forEach((contrato) => {
        distributeChildTemp =
          distributeChildTemp +
          parseFloat(contrato.volumeDistribuido.toFixed(3));
      });

      if (distributeChildTemp !== this.TAKE_MINIMO_PAI) {
        this.contratosFilhos[0].volumeDistribuido =
          this.contratosFilhos[0].volumeDistribuido +
          (this.TAKE_MINIMO_PAI - distributeChildTemp);
      }

      this.montanteIsValidChild();
    },
    calculate() {
      this.isPositive = true;
      this.isValid = true;
      this.newAmountIsValid = true;

      this.calcularContratosFilhos();

      this.montanteIsValidChild();
    },
    getInfosUser(value) {
      this.payload.nome = value.nome;
      this.payload.email = value.email;
    },
    async efetuarRecompra() {
      this.loading = true;
      this.payload.contratosRecompra = this.contratosFilhos.map((contrato) => ({
        contratoRecompra: {
          codigo: contrato.codigo,
          volume: contrato.volumeDistribuido.toFixed(3),
          preco: contrato.condicaoDePrecoDto.precoOriginal.toFixed(2),
          volumeFinal: parseFloat(contrato.montanteNovo).toFixed(3),
          precoFinal: contrato.precoNovo.toFixed(2),
        },
        spread: contrato.condicaoDePrecoDto.spreadDeCompra,
        pld: contrato.condicaoDePrecoDto.pld,
      }));
      try {
        await DemonstrativoService.postHotsiteEfetuarRecompraPrecosDiferentes(
          this.contratoPrincipal.id,
          this.payload
        );
        this.loading = false;
        document.location.reload();
      } catch (error) {
        this.loading = false;
        this.showModalFormUser = false;
        this.mxToggleWarningModal({
          message: error.response.data.message,
          btnTrue: "OK",
          btnFalse: "Fechar",
          title: "Atenção",
          showBtnFalse: false,
        });
      }
    },
    async deleteStatusTipoRecompra() {
      this.loadingPage = true;
      try {
        await DemonstrativoService.deleteStatusTipoRecompra(
          this.contratoPrincipal.id
        );
        this.loadingPage = false;
        document.location.reload();
      } catch {
        this.loadingPage = false;
      }
    },
    async saveStatus(type) {
      try {
        await RepurchaseStatusService.updateStatus({
          idProcess: parseInt(this.id),
          status: type,
        });
        if (type == "APROVADO") {
          this.modalGetDataUserShow = false;
        }
        document.location.reload();
      } catch {
        this.spinner = false;
      }
    },
    formatPrice(value) {
      let val = value.toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    timerfinished() {
      document.location.reload();
    },
    async listPosVenda() {
      this.posvenda = await HostsiteRepurchaseService.getPosVenda({
        processId: this.id,
        contrato: this.contrato.wbcContract,
      });
    },
    priceIsValid(value) {
      return value > this.minimumPrice && value < this.maximumPrice;
    },
    priceIsValidChild(value) {
      return value.every(
        ({ precoNovo }) =>
          precoNovo > this.minimumPrice && precoNovo < this.maximumPrice
      );
    },
    montanteIsValidChild() {
      this.montantIsValid = this.contratosFilhos.every(
        (contrato) => contrato.montanteNovo >= 0
      );
    },
    getUserResult(value) {
      this.modalGetDataUserShow = false;
      value.typeRecompra = "PRECOS_DIFERENTES";
      this.$emit("saveLogRecompra", value);
    },
    closeGetDataUser() {
      this.modalGetDataUserShow = false;
    },
    async getData() {
      this.spinner = true;
      try {
        const response = await HostsiteRepurchaseService.getData({
          processId: this.id,
        });
        const requestPost = response.map((g) => {
          g.precoAjuste = g.pld + g.spread;
          g.isPrecoUnico = true;
          g.notionalFinal = 0;
          g.notionalOriginal = 0;
          g.notionalRecompra = 0;
          g.precoNovo = 0;
          g.montanteReduzir = "";
          g.volumeDistribuido = 0;
          g.montanteNovo = 0;

          const [{ consumoApurado, takeMinimo }] = response.filter(
            (g) => g.isAgrupador
          );
          if (g.isAgrupador == false) {
            g.volumeDistribuido = multiplyValues(
              divideValues(g.consumoApurado, consumoApurado),
              takeMinimo
            );

            g.montanteReduzir = subtractValues(
              g.volumeDistribuido,
              g.consumoApurado
            );
            g.montanteNovo = subtractValues(takeMinimo, g.montanteReduzir);
          }

          return g;
        });
        this.data = requestPost.reverse();
        this.calculoInicial();
      } finally {
        this.spinner = false;
      }
    },
    insertSumOfChildren() {
      const montanteNovo = this.data.reduce(
        (acc, curr) => acc + parseFloat(curr.montanteNovo),
        0
      );
      const montanteReduzir = this.data.reduce(
        (acc, curr) => acc + parseFloat(curr.montanteReduzir),
        0
      );
      this.data.filter((g) => g.isAgrupador)[0].montanteNovo = montanteNovo;
      this.data.filter((g) => g.isAgrupador)[0].montanteReduzir =
        montanteReduzir;
    },
    next() {
      this.spinner = true;
      this.insertSumOfChildren();
      if (!this.isPositive) {
        this.mxShowModalAlert({
          title: "Atenção",
          message: "Novo preço não pode ser negativo negativo",
          type: "confirm",
        });
      } else {
        this.showModalFormUser = true;
      }
    },
    async contestForValue(value) {
      this.modalGetDataUserShow = false;
      this.spinner = true;
      try {
        await HotsiteService.updateStatus({
          id: 0,
          idProc: this.id,
          justify: "",
          status: "RECOMPRA_MANUAL",
        });
        await RepurchaseStatusService.updateStatus({
          idProcess: this.id,
          status: "RECOMPRA_MANUAL",
        });
        await HotsiteService.logAproval({
          createdAt: null,
          email: value.infos.email,
          id: 0,
          name: value.infos.name,
          processId: this.id,
          type: "RC",
        });
        this.$emit("completeTask");
      } finally {
        this.spinner = false;
      }
    },
    async post() {
      this.spinner = true;
      try {
        await HostsiteRepurchaseService.saveCalculation(this.data, this.id);
        this.spinner = false;
        this.$emit("finalizarRecompra", "PRECOS_DIFERENTES");
      } catch {
        this.spinner = false;
      }
    },
    modalaproved() {
      this.modalPrposalConfirmShow = true;
    },
    nextConfirmModal() {
      this.spinner = false;
      this.saveStatus("APROVADO");
    },
    formatVolume(value) {
      if (!value || isNaN(value) || !isFinite(value)) {
        return "0,000";
      }
      var m = parseFloat(value).toFixed(3);
      m = m.toString().replace(".", ",");
      var parts = m.split(",");
      if (parts[0].toString().length > 3) {
        parts[0] = [parts[0].slice(0, -3), ".", parts[0].slice(-3)].join("");
      }
      let result = parts[0] + "," + parts[1];
      return result.replace("-.", "--");
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(117.6vh - 86px);
}
.breadcrumbs {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #505050;
  background: #ffffff;
  border-radius: 5px;
  width: 80%;
  display: flex;
  justify-items: center;
  padding: 5px 0 5px 20px;
  margin-top: 30px;
  margin-bottom: 39px;
  flex-wrap: wrap;
}
.box-reduction-amount {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 80%;
}
.alert-msg {
  width: 100%;
  background: #f8f8f8;
  border-radius: 5px;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #505050;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.alert-icon {
  padding: 0 10px 0 10px;
  img {
    width: 20px;
    margin-top: -2px;
  }
}
.box-head {
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: #929292;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 0 8px 20px;
  display: flex;
  align-items: center;
}
.box-content {
  padding: 20px;
}

.box-infos {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 20px 40px 20px 0px;
}
.info-row {
  display: flex;
  justify-content: flex-start;
  @include tablet {
    flex-direction: column;
  }
}
.info-item {
  width: 25%;
  padding-left: 70px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #505050;
  @include tablet {
    width: 100%;
    text-align: center;
    padding-left: 0px;
    margin-bottom: 10px;
  }
}
.info-item-title {
  margin-bottom: 2px;
}
.info-item-value {
  margin-top: 2px;
}
.numeric-field-grid {
  background: #ebebeb;
  border-radius: 3px;
  border: none;
  width: 100px;
  text-align: center;
  &:disabled {
    background: #c4c4c4;
    color: #c4c4c4;
  }
}
.box-distribution-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #505050;
  margin-top: 41px;
  margin-bottom: 25px;
  @include tablet {
    flex-direction: column;
  }
}
.counter-item {
  @include tablet {
    margin-bottom: 10px;
  }
}
.grid-header {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #000000;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #929292;
  padding: 7px 0 7px 0;
}
.grid-body {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #505050;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 7px 0 7px 0;
}
.grid-total {
  display: flex;
  justify-content: space-between;
  background: #e8e8e8;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #505050;
  padding: 7px 0 7px 0;
}
.grid-cell {
  width: 14.7% !important;
  padding-right: 30px;
}
.box-buttons {
  margin-top: 31px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @include tablet {
    justify-content: center;
  }
}
.btn-orange {
  width: 174px;
  height: 42px;
  background: #f86511;
  border-radius: 4px;
  color: #fff;
  &:hover {
    background: rgba($color: #f86511, $alpha: 0.8);
    color: #fff;
  }
  &:disabled {
    background: rgba($color: #ccc, $alpha: 0.8);
    cursor: not-allowed;
  }
}
.btn-border {
  width: 174px;
  height: 42px;
  border: 1px solid #505050;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
}
.color-red {
  color: red;
  font-weight: 600;
}
.color-crump {
  color: #f86511;
}
.border-red {
  border: red 1px solid;
}
.info-timer {
  text-align: right;
  width: 50%;
  @include tablet {
    text-align: center;
    width: 100%;
  }
}
.text-timer {
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: #505050;
  padding: 7px 0 7px 0;
}
.info-top {
  justify-content: space-between;
  @include tablet {
    justify-content: center;
  }
}
.wrapper-button-user {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-grid {
  min-width: 1000px;
}
</style>
