import {
  CAPITALIZE,
  CURRENCY,
  EMPTY,
  VOLUME,
  volumeFormatter,
} from "@/filters/formatters";
import ExportadorModel from "@/models/ExportadorModel";
import { padLeft } from "@/utils/functionsUtils";
import { DateTime } from "luxon";

export default class TableExportadorFlatModel extends ExportadorModel {
  constructor(args) {
    super({ ...args, tipo: "flat" });
  }

  static endpoint = "/flat";

  static fileName = "montantes_wbc_flat";

  static getTableFields() {
    return [
      {
        name: "ano",
        title: "Ano",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "mes",
        title: "Mês",
        formatter(value) {
          return EMPTY(padLeft(value));
        },
      },
      {
        name: "apelido",
        title: "Apelido",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "contrato",
        title: "Contrato",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "pontoMedicao",
        title: "Ponto de Medição",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "rateio",
        title: "Rateio",
        formatter(value) {
          return value ? "Sim" : "Não";
        },
      },
      {
        name: "responsavel",
        title: "Responsável",
        formatter(value) {
          return EMPTY(value);
        },
        align: "center",
      },
      {
        name: "take",
        title: "Take",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "montantesWbc",
        title: "Montante WBC",
        formatter(value) {
          return VOLUME(value);
        },
      },
      {
        name: "valorContratado",
        title: "Preço",
        formatter(value) {
          return CURRENCY(value);
        },
      },
      {
        name: "valorContratadoWbc",
        title: "Preço Wbc",
        formatter(value) {
          return CURRENCY(value);
        },
      },
      {
        name: "aprovacao",
        title: "Aprovação",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "statusIntegracao",
        title: "Situação",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "exportado",
        title: "Exportado",
        formatter(value) {
          return value ? "Sim" : "Não";
        },
      },
      {
        name: "statusOrdemFinanceira",
        title: "Ordem Financeira",
        formatter(value) {
          return EMPTY(CAPITALIZE(value));
        },
      },
      {
        name: "volumeRecompra",
        title: "Vol. Recompra",
        getValue(row) {
          return volumeFormatter(row._montanteBilling?.recompra?.volume);
        },
      },
      {
        name: "precoRecompra",
        title: "Preço Recompra",
        getValue(row) {
          return CURRENCY(row._montanteBilling?.recompra?.preco);
        },
      },
      {
        name: "notionalRecompra",
        title: "Notional Recompra",
        getValue(row) {
          return CURRENCY(row._montanteBilling?.recompra?.notional);
        },
      },
      {
        name: "novoFaturamento",
        title: "Novo Faturamento",
        getValue(row) {
          return volumeFormatter(row._montanteBilling?.recompra?.volumeFinal);
        },
      },
      {
        name: "novoPrecoBilling",
        title: "Novo Preço Billing",
        getValue(row) {
          return CURRENCY(row._montanteBilling?.recompra?.precoFinal);
        },
      },
      {
        name: "novoNotional",
        title: "Novo Notional",
        getValue(row) {
          return CURRENCY(row._montanteBilling?.recompra?.notionalFinal);
        },
      },
      {
        name: "montanteBilling",
        title: "Montante Billing",
        getValue(row) {
          return volumeFormatter(row._montanteBilling?.volumeAFaturar);
        },
      },
      {
        name: "dataUltimaPublicacao",
        title: "Últ. Publicação",
        formatter(value) {
          if (!value) return "--";
          return DateTime.fromISO(value).toFormat("dd/MM/yyyy - HH:mm") + "hs";
        },
      },
      {
        name: "selecionar",
        title: "Selecionar",
        align: "center",
      },
    ];
  }
}
