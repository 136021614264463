<template>
  <div class="container-field">
    <div class="mb-1">Data:</div>
    <date-picker
      :lang="LANG"
      v-model="content"
      :disabled="disabled"
      :format="'DD/MM/YYYY'"
      :ref="'datePickerField'"
      :placeholder="'dd/mm/aaaa'"
      data-cy="input-data_field-pld-spread-components-fields"
    >
      <span slot="icon-calendar">
        <img :src="calendarIcon" />
      </span>
    </date-picker>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import DatePicker from "vue2-datepicker";
import { lang } from "@/plugins/config-lang-vuedatepicker";
import calendarIcon from "@/assets/icons/svg/calendar.svg";

export default {
  name: "FieldData",
  props: {
    modelProp: Date,
    disabled: Boolean,
  },
  watch: {
    modelProp() {
      this.content = this.modelProp;
    },
  },
  data() {
    return {
      content: null,
    };
  },
  computed: {
    LANG: () => lang,
    calendarIcon: () => calendarIcon,
  },
  components: {
    DatePicker,
  },
};
</script>
<style lang="scss">
.mx-input {
  border: none !important;
  box-shadow: none !important;
}

.mx-input-wrapper {
  width: 135px !important;
}
</style>
