<template>
  <div class="container-card">
    <div class="title-folding mb-3">
      <div>Contratos de Rateio</div>
      <div class="text-icon" v-if="calculoFaltante != 0">
        <label>
          {{ GET_EXCEDENTE_TEXT }}
        </label>
        <b :style="[{ color: calculoFaltante < 0 ? 'red' : 'white' }]">
          {{ calculoFaltante | round(3) }} MWh
        </b>
        <img
          v-if="volumePaiFormatado - sumTotalVolume != 0"
          src="@/assets/icons/svg/exclamation.svg"
          alt=""
        />
      </div>
    </div>
    <data-table
      :card="true"
      :footer="true"
      :stripes="true"
      :headerStick="true"
      style="width: 100%"
      maxHeight="400px"
      :data="analiseContratosFilhos"
      :fields="ContratoRateioModel.getTableFields()"
    >
      <div slot="header-volumeFinal">
        <span style="color: #ec6623">Volume (MWh)</span>
      </div>
      <div slot="header-preço">
        <span style="color: #ec6623">Preço</span>
      </div>
      <div slot="header-novoNotional">
        <span style="color: #ec6623">Notional</span>
      </div>
      <div slot="volumeFinal" slot-scope="{ row }">
        <vue-numeric
          :minus="false"
          :precision="3"
          placeholder="0,000"
          :decimal-separator="','"
          :thousand-separator="'.'"
          class="input-field-card"
          v-model="row.volumeFinal"
          @input="calculateNotional(row)"
        />
      </div>
      <div slot="footer-codigo">Totais</div>
      <div slot="footer-volumeFinal">{{ sumTotalVolume | round(3) }} MWh</div>
      <div slot="footer-novoNotional">
        {{ sumTotalNotional | round(2) | R$ }}
      </div>
    </data-table>
  </div>
</template>
<script>
import TableContratosRateio from "@/components/recompra/TableContratosRateio.vue";
import DataTable from "@/components/tables/DataTable.vue";
import ContratoRateioModel from "@/models/tablesModels/ContratoRateioModel";
import {
  multiplyValues,
  subtractValues,
  sumValues,
} from "@/utils/functionsUtils";
import VueNumeric from "vue-numeric";

export default {
  props: {
    contrato: Object,
    volumeComRecompraPai: Number,
    analiseContratosFilhos: Array,
  },
  data() {
    return {
      sumTotalVolume: 0,
      faltaDistribuir: 0,
      sumTotalNotional: 0,
      volumePaiFormatado: 0,
    };
  },
  computed: {
    ContratoRateioModel: () => ContratoRateioModel,
    GET_EXCEDENTE_TEXT() {
      return this.calculoFaltante < 0
        ? "Há um Volume excedente de: "
        : "Falta distribuir: ";
    },
    calculoFaltante() {
      return subtractValues(this.volumePaiFormatado, this.sumTotalVolume);
    },
  },
  methods: {
    calculateNotional(row) {
      this.analiseContratosFilhos.forEach((contrato) => {
        if (contrato.codigo == row.codigo) {
          contrato.novoNotional = multiplyValues(
            row.volumeFinal,
            contrato.precoFinal
          );
        }
      });
    },
  },
  watch: {
    analiseContratosFilhos: {
      deep: true,
      handler() {
        this.sumTotalVolume = this.analiseContratosFilhos
          .map(({ volumeFinal }) => volumeFinal)
          .reduce((total, numero) => sumValues(total, numero), 0);

        this.sumTotalNotional = this.analiseContratosFilhos
          .map(({ novoNotional }) => novoNotional)
          .reduce((total, numero) => sumValues(total, numero), 0);

        this.$emit("getContratosFilhosAjustados", this.analiseContratosFilhos);
      },
    },
    volumeComRecompraPai: {
      handler() {
        this.volumePaiFormatado = this.volumeComRecompraPai;
      },
    },
  },
  components: {
    DataTable,
    VueNumeric,
    TableContratosRateio,
  },
};
</script>
<style lang="scss" scoped>
.title-folding {
  width: 100%;
  display: flex;
  font-weight: 700;
  margin-top: 40px;
  padding: 10px 30px;
  border-radius: 24px;
  color: $color--white;
  justify-content: space-between;
  background: $color--blue-super-dark;
}
.container-card {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  flex-direction: column;
  justify-content: center;
}
.text-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-left: 10px;
  }
}
.red {
  color: red;
}
</style>
