import {
  mountImg as mountImgUtil,
  mountUrl as mountUrlUtil,
} from "../utils/functionsUtils";

export default {
  methods: {
    mountUrl(uri) {
      return mountUrlUtil(uri);
    },
    mountImg(uri) {
      return mountImgUtil(uri);
    },
  },
};
