<template>
  <div>
    <table class="table table-faturamento-contrato-wbc">
      <tbody>
        <tr class="_border-bottom">
          <td><b>Vendedor</b></td>
          <td>MATRIX COMERCIALIZADORA DE ENERGIA ELÉTRICA S.A.</td>
        </tr>
        <tr class="_border-bottom">
          <td><b>Comprador</b></td>
          <td>
            {{ CONTRATO_SELECIONADO_CONTRATO_EMPRESA_RAZAO_SOCIAL | EMPTY }}
          </td>
        </tr>
        <tr class="_border-bottom">
          <td><b>CNPJ do Comprador</b></td>
          <td>
            {{ CONTRATO_SELECIONADO_CONTRATO_EMPRESA_CNPJ | EMPTY | cnpj }}
          </td>
        </tr>
        <tr class="_border-bottom">
          <td><b>Fonte</b></td>
          <td>{{ CONTRATO_SELECIONADO_TIPO_ENERGIA | EMPTY }}</td>
        </tr>
        <tr class="_border-bottom">
          <td><b>Submercado</b></td>
          <td>{{ CONTRATO_SELECIONADO_CONTRATO_SUBMERCADO | EMPTY }}</td>
        </tr>
        <tr class="_border-bottom">
          <td><b>Período de Fornecimento</b></td>
          <td>{{ PERIODO_DE_FORNECIMENTO | EMPTY }}</td>
        </tr>
        <tr class="_border-bottom">
          <td><b>Volume Total</b></td>
          <td>
            <b>{{ CONTRATO_SELECIONADO_VOLUME_TOTAL | truncateRound(4, 3) }}</b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { EMPTY } from "@/filters/formatters";

export default {
  props: {
    contratoSelecionado: Object,
  },
  data() {
    return {
      novaData: new Date(),
    };
  },
  filters: { EMPTY },
  computed: {
    CONTRATO_SELECIONADO_CONTRATO_EMPRESA_RAZAO_SOCIAL() {
      return this.contratoSelecionado?.contrato?.empresa?.razaoSocial;
    },
    CONTRATO_SELECIONADO_CONTRATO_EMPRESA_CNPJ() {
      return this.contratoSelecionado?.contrato?.empresa?.cnpj;
    },
    CONTRATO_SELECIONADO_TIPO_ENERGIA() {
      return this.contratoSelecionado?.contrato?.tipoEnergia;
    },
    CONTRATO_SELECIONADO_CONTRATO_SUBMERCADO() {
      return this.contratoSelecionado?.contrato?.submercado;
    },
    CONTRATO_SELECIONADO_VOLUME_TOTAL() {
      return this.contratoSelecionado?.volumeTotal;
    },
    PERIODO_DE_FORNECIMENTO() {
      return this.contratoSelecionado?.contrato?.dataReferencia;
    },
  },
};
</script>
<style lang="scss" scoped>
.table-faturamento-contrato-wbc {
  width: 90%;
  margin: 44px 0px 49px 36px;
}

tr {
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee !important;
}
</style>
