<template>
  <div class="page-rotina-importacao">
    <div class="body-rotina-importacao">
      <rotina-importacao-card
        label="Preço Fixo"
        :service="RotinaImportacaoService"
      />
      <rotina-importacao-card
        label="Desconto Garantido"
        :service="JobService"
      />
    </div>
  </div>
</template>

<script>
import RotinaImportacaoCard from "@/pages/parametrizacao/components/layout/RotinaImportacaoCard.vue";
import JobService from "@/services/JobService";
import RotinaImportacaoService from "@/services/RotinaImportacaoService";

export default {
  computed: {
    RotinaImportacaoService: () => RotinaImportacaoService,
    JobService: () => JobService,
  },
  components: {
    RotinaImportacaoCard,
  },
};
</script>

<style lang="scss" scoped>
.page-rotina-importacao {
  .body-rotina-importacao {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
}
</style>
