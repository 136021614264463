<template>
  <div class="page-empresa-template">
    <page-painel v-element-async-loader="loading">
      <template #header>
        <div class="d-flex">
          <span style="font-weight: 500">Faturamento de</span>
          <label class="ms-2" v-if="contratos && contratos.length > 0">
            <b>
              {{ contratos[0].empresa.apelido }} - ({{
                contratos[0].empresa.razaoSocial
              }})
            </b>
          </label>
        </div>
        <span class="competencia">Competência: {{ FATURAMENTO_ATUAL }}</span>
      </template>
      <template #body>
        <div class="page-empresa-template__body">
          <div v-for="(contrato, i) in contratos" :key="`${i}contratos`">
            <contrato :contrato="contrato" />
          </div>
        </div>
      </template>
    </page-painel>
  </div>
</template>

<script>
import Contrato from "@/components/contratos/Contrato.vue";
import HorasFaltantes from "@/components/horas-faltantes/HorasFaltantes.vue";
import PagePainel from "@/components/panels/PagePanel.vue";
import FaturamentoService from "@/services/FaturamentoService";
import FaturamentosFechadosService from "@/services/FaturamentosFechadosService";
import { Buffer } from "buffer";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      contratos: null,
      loading: undefined,
      dataFaturamento: null,
    };
  },
  mounted() {
    this.faturamentoAtual();
    this.getContratoPaiId();
    this.ActionSetIsPontoSemAcesso(this.$route.query.pontoSemAcesso === "true");

    this.loading = FaturamentoService.getFaturamentoEmpresaById({
      id: this.$route.params.empresaId,
    }).then((response) => {
      this.contratos = response;
    });
  },
  computed: {
    FATURAMENTO_ATUAL() {
      if (this.dataFaturamento) {
        return this.dataFaturamento.competencia;
      } else {
        return "--";
      }
    },
  },
  methods: {
    ...mapActions(["ActionSetContratoPaiId", "ActionSetIsPontoSemAcesso"]),
    faturamentoAtual() {
      this.loading = FaturamentosFechadosService.getCurrentFaturamento()
        .then(({ data }) => {
          this.dataFaturamento = data;
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    getContratoPaiId() {
      let contratoPaiId;
      if (!window.sessionStorage.getItem("c-key")) {
        contratoPaiId = this.$route.params.contratoId;
        window.sessionStorage.setItem(
          "c-key",
          Buffer.from(contratoPaiId.toString()).toString("base64")
        );
      } else {
        contratoPaiId = Buffer.from(
          window.sessionStorage.getItem("c-key"),
          "base64"
        ).toString("utf8");
      }
      this.ActionSetContratoPaiId(Number(contratoPaiId));
    },
  },
  components: {
    Contrato,
    PagePainel,
    HorasFaltantes,
  },
};
</script>

<style lang="scss" scoped>
.page-empresa-template {
  padding-top: 24px;
  &__body {
    padding: 2px 40px;
  }
  .competencia {
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
