<template>
  <div>
    <modal-base
      :width="'70%'"
      @closeModal="$emit('closeModal')"
      data-cy="modal-base-fechar_modal_editar_pld-pld-spread-components-modais"
    >
      <template #header> Editar PLD </template>
      <template #body>
        <div class="content-pld" v-element-async-loader="loading">
          <div class="subtitle-pld">
            Submercado:
            <span
              ><b>{{ SUBMERCADO_SIGLA }}</b></span
            >
            - Tipo de Energia: <span><b>Todos</b></span>
          </div>
          <p>
            Atenção: Trata-se de uma alteração de PLD cuja fonte é a CCEE. Tem
            certeza dessa alteração?
          </p>
          <div class="box-content-pld">
            <field-estou-ciente
              ref="fieldEstouCiente"
              @input="(value) => (estouCienteCheck = value)"
            />
            <field-pld-novo
              :isDisabled="estouCienteCheck"
              @input="(value) => (content = value)"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="footer-botoes">
          <button
            @click="$emit('closeModal')"
            class="mx-2 my-2 btn btn-secondary-outlined"
            data-cy="button-cancelar_modal_editar_pld-pld-spread-components-modais"
          >
            Cancelar
          </button>
          <button
            @click="updatePLD"
            id="update-pld-button"
            :disabled="!estouCienteCheck"
            class="mx-3 my-2 btn btn-primary-full-filled"
            data-cy="button-salvar_modal_editar_pld-pld-spread-components-modais"
          >
            Salvar
          </button>
        </div>
      </template>
    </modal-base>
  </div>
</template>

<script>
import PldService from "@/services/PldService";
import { CONFIRMATION } from "@/constants/strings";
import FieldPldNovo from "../fields/FieldPldNovo.vue";
import FieldPldAtual from "../fields/FieldPldAtual.vue";
import ModalBase from "@/components/modals/ModalBase.vue";
import { createErrorObject } from "@/utils/functionsUtils";
import FieldEstouCiente from "../fields/FieldEstouCiente.vue";

export default {
  name: "ModalEditarPLD",
  props: {
    submercado: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data() {
    return {
      content: 0,
      loading: undefined,
      estouCienteCheck: false,
    };
  },
  computed: {
    SUBMERCADO_SIGLA() {
      return this.submercado.sigla || "--";
    },
    PLD_EDITADO_OR_PRECO() {
      const { ultimoPld } = this.submercado;
      if (!ultimoPld) return "";
      return ultimoPld.precoEditado || ultimoPld.preco;
    },
  },
  methods: {
    updatePLD() {
      this.loading = new Promise((resolve, reject) => {
        if (this.content <= 0) {
          this.mxHandleRequestError(createErrorObject(400), "PLD_SPREAD");
          resolve();
          return;
        }
        const { id: submercadoId } = this.submercado;

        let id = null;
        if (this.submercado.ultimoPld) {
          id = this.submercado.ultimoPld.id;
        }
        const preco = Number(this.PLD_EDITADO_OR_PRECO);
        const precoEditado = this.content;

        PldService.createPLD({
          id,
          submercado: { id: submercadoId },
          preco,
          precoEditado,
        })
          .then(() => {
            this.mxToggleToast({
              message: CONFIRMATION.PLD_SPREAD.UPDATE,
            });
            this.$emit("reloadSubmercados");
            resolve();
          })
          .catch((error) => {
            this.mxHandleRequestError(error);
            reject(error);
          });
      });
    },
  },
  components: {
    ModalBase,
    FieldPldNovo,
    FieldPldAtual,
    FieldEstouCiente,
  },
};
</script>

<style lang="scss" scoped>
.content-pld {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subtitle-pld {
  width: 45%;
  height: 44px;
  padding-top: 7px;
  margin: 32px auto;
  text-align: center;
  border-radius: 24px;
  justify-items: center;
  border: 2px $color--secondary--gray solid;
}

p {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
}

.box-content-pld {
  width: 100%;
  display: flex;
  justify-content: center;
}

.check-box-pld {
  display: flex;
  justify-content: center;
}

.footer-botoes {
  width: 100%;
  display: flex;
  margin: 16px 30px;
  flex-direction: row;
  justify-content: flex-end;
}

.btn {
  height: 114;
  width: 136px;
  font-size: 18px;
  border-radius: 9px;
}
</style>
