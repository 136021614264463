<template>
  <div class="__card">
    <div class="__card__title">Processamento de Medições:</div>
    <div class="__card__bar" v-for="(progressBar, i) in bars" :key="i">
      <span>{{ progressBar.label }}</span>
      <div class="__progess-bar">
        <progress-bar :splits="progressBar.splits" />
        <div class="caption">
          <label class="pe-2">
            <template v-if="progressBar.isDg">
              Iniciado às
              {{ formatHours(progressBar.horaInicio) }}
              - {{ formatDate(progressBar.dataProcessamento) }}
            </template>
            <template v-else>
              Iniciado às
              {{ formatHours(progressBar.horaInicio) }}
            </template>
          </label>
          <label class="slash-left ps-2" v-if="progressBar.proximaExecucao">
            Próximo processamento automático às
            {{ formatHours(progressBar.proximaExecucao) }}
          </label>
        </div>
      </div>
      <div>
        <button
          @click="doImportingAction(startImportacao({ dg: progressBar.isDg }))"
          class="btn btn-action-secondary"
          :disabled="!$can('BUSCAR_APURACOES_CCEE')"
        >
          {{ progressBar.importacaoButton }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ProgressBar from "@/components/progress-bar/ProgressBar.vue";
import { CONFIRMATION } from "@/constants/strings";
import { StatusBarraENUM, getBarStatus } from "@/enums/StatusBarraENUM";
import CceeService from "@/services/CceeService";
import JobService from "@/services/JobService";
import RotinaImportacaoService from "@/services/RotinaImportacaoService";
import { formatData } from "@/utils/functionsUtils";
import { Stomp } from "@stomp/stompjs";
import * as SockJS from "sockjs-client";

export default {
  data() {
    return {
      stomp: null,
      retryStompConnection: true,
      loading: false,
      bars: [
        {
          label: "Preço Fixo",
          horaInicio: null,
          dataProcessamento: null,
          proximaExecucao: null,
          isDg: false,
          importacaoButton: "Iniciar Importação CCEE",
          splits: [
            {
              label: "Importação CCEE",
              loadingLabel: "Importando...",
              color: "#78b82a",
              width: "60%",
              statusTitle: "FixoImportacao",
              status: "idle",
              extraInfo: "",
              ws: "/topic/progresso-importacao",
              value: 0,
            },
            {
              label: "Cálculo",
              loadingLabel: "Calculando...",
              color: "#ff7043",
              width: "40%",
              statusTitle: "FixoCalculo",
              status: "idle",
              extraInfo: "",
              ws: "/topic/progresso-calculo",
              value: 0,
            },
          ],
        },
        {
          label: "Desconto Garantido",
          horaInicio: null,
          dataProcessamento: null,
          proximaExecucao: null,
          isDg: true,
          importacaoButton: "Iniciar Importação DG",
          splits: [
            {
              label: "Cálculo",
              loadingLabel: "Calculando...",
              color: "#ff3d00",
              width: "100%",
              statusTitle: "DgCalculo",
              status: "idle",
              extraInfo: "",
              ws: "/topic/progresso-calculo-dg",
              value: 0,
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.connectWebsocket();
  },
  beforeDestroy() {
    this.retryStompConnection = false;
  },
  methods: {
    doImportingAction(action) {
      if (this.loading) return;
      this.loading = true;
      action.finally(() => {
        this.loading = false;
      });
    },
    connectWebsocket() {
      this.getStatuses();
      if (this.stomp == null || !this.stomp.connected) {
        const url = `${process.env.VUE_APP_ROOT_BILLING}/websocket`;
        const sockJS = new SockJS(url);
        this.stomp = Stomp.over(sockJS);
        this.stomp.debug = (f) => f;
        this.stomp.connect(
          {},
          this.connectCallback,
          this.errorCallback,
          this.closedCallback
        );
      }
    },
    connectCallback() {
      for (let i = 0; i < this.bars.length; i++) {
        for (let j = 0; j < this.bars[i].splits.length; j++) {
          const split = this.bars[i].splits[j];

          this.stomp.subscribe(split.ws, (response) => {
            this.updateWsData(
              JSON.parse(response.body),
              i,
              j,
              split.statusTitle
            );
          });
        }
      }
    },
    errorCallback() {
      if (this.retryStompConnection) {
        this.connectWebsocket();
      }
    },
    closedCallback() {
      if (this.retryStompConnection) {
        this.stomp = null;
        this.connectWebsocket();
      }
    },
    updateWsData(response, barIndex, splitIndex, statusTitle) {
      if (response.porcentagem === 100) {
        this.getStatuses();
        return;
      }
      this.bars[barIndex].splits[splitIndex].value = response.porcentagem;

      const enumVal = StatusBarraENUM[statusTitle];
      this.bars[barIndex].splits[splitIndex].status = getBarStatus(
        {
          [enumVal.error]: response[enumVal.error],
          [enumVal.finished]: response.finalizado,
          [enumVal.percentage]: response.porcentagem,
        },
        statusTitle,
        enumVal.isDg
      );
    },
    getStatus({ dg }) {
      (dg ? JobService : RotinaImportacaoService)
        .getStatusRotinaImportacao()
        .then(({ data, status }) => {
          if (status === 204) return;

          if (dg) {
            this.bars[1].splits[0].value = data.progressoPorcentagemSucesso;
            this.bars[1].splits[0].status = getBarStatus(
              data,
              "DgCalculo",
              true
            );
            this.bars[1].splits[0].extraInfo = `${data.totalRegistrosProcessadosComSucesso}/${data.totalRegistros} Processados com Sucesso`;

            this.bars[1].horaInicio = data.horaInicio || null;
            this.bars[1].dataProcessamento = data.dataProcessamento || null;
            this.bars[1].proximaExecucao = data.proximaExecucao || null;
            return;
          }

          this.bars[0].splits[0].value = data.importacaoPorcentagem;
          this.bars[0].splits[0].status = getBarStatus(
            data,
            "FixoImportacao",
            false
          );
          this.bars[0].splits[1].value = data.calculoPorcentagem;
          this.bars[0].splits[1].status = getBarStatus(
            data,
            "FixoCalculo",
            false
          );

          this.bars[0].horaInicio = data.horaInicio || null;
          this.bars[0].dataProcessamento = data.dataProcessamento || null;
          this.bars[0].proximaExecucao = data.proximaExecucao || null;
        })
        .catch((error) => {
          if (dg && error.response?.status === 404) {
            this.bars[1].splits[0].status = "idle";
            return;
          }
          this.mxHandleRequestError(error);
        });
    },
    async getStatusDetalheDg() {
      try {
        const { data } = await JobService.getStatusDetalheRotinaImportacao();
        console.log("Get status detalhe dg sucesso: ", data);
      } catch (error) {
        console.error("Get status detalhe dg erro: ", error);
      }
    },
    async startImportacao({ dg }) {
      try {
        const { data } = await (dg
          ? JobService
          : RotinaImportacaoService
        ).getStatusRotinaImportacao();

        if (!data.finalizado) {
          this.mxToggleErrorMessageAlert(
            "O processo de importação já está em andamento"
          );
          return;
        }
      } catch {
        this.getStatuses();
        return;
      }

      try {
        await (dg ? JobService : CceeService).startJobImportacao();

        this.mxToggleToast({
          message: CONFIRMATION.IMPORTACAO.CREATED,
        });
        this.getStatuses();
      } catch (error) {
        await this.getStatuses();
        if (error?.response?.status === 422) {
          this.mxToggleErrorMessageAlert(
            "Não é possível iniciar a rotina em período de pré-faturamento"
          );
        } else {
          this.mxHandleRequestError(error, "IMPORTACAO");
        }
      }
    },
    getStatuses() {
      this.getStatus({ dg: false });
      this.getStatus({ dg: true });
      this.getStatusDetalheDg();
    },
    formatHours: (data) => formatData(data, "HH:mm") || "--:--",
    formatDate: (data) => formatData(data, "dd/MM/yy") || "dd/mm/yy",
  },
  components: {
    ProgressBar,
  },
};
</script>
<style lang="scss" scoped>
.slash-left {
  border-left: 1px solid black;
}
.__card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: responsive(14px);
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 1px;
  border-radius: 20px;
  color: $color--primary-font;
  padding: 18px;
  justify-content: space-between;
  background: $color--primary-white;
  &__title {
    font-weight: 500;
    font-size: 12px;
  }
  &__bar {
    display: flex;
    align-items: center;
    gap: 12px;
    > span {
      width: fit-content;
      min-width: 125px;
    }
    .__progess-bar {
      width: 100%;
      .caption {
        font-weight: 500;
        font-size: 10px !important;
      }
    }
    .btn-action-secondary {
      font-size: 1em;
      font-weight: bold;
      width: 180px !important;
      border-radius: 9px !important;
    }
  }
}
</style>
