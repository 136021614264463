<template>
  <div class="fields-box">
    <div class="fields-section">
      <field-aprovacao
        titleField="Contrato"
        :valueInfo="dataInfo.codigo"
        colorTitleField="#544943"
        colorValueField="#2A2A2A"
        :widthField="50"
      />
      <field-aprovacao
        titleField="Spread de Compra Ajustado"
        :valueInfo="`${formatterValue(dataInfo.spreadCompraAjustado)}`"
        colorTitleField="#544943"
        colorValueField="#EC6623"
        :widthField="50"
      />
    </div>
    <div class="fields-section">
      <field-aprovacao
        titleField="Spread de Compra Original"
        :valueInfo="`${formatterValue(dataInfo.spread)}`"
        colorTitleField="#544943"
        colorValueField="#2A2A2A"
        :widthField="50"
      />
      <field-aprovacao
        titleField="Alçada Competente"
        :valueInfo="dataInfo.descricao"
        colorTitleField="#544943"
        colorValueField="#2A2A2A"
        :widthField="50"
      />
    </div>
  </div>
</template>

<script>
import FieldAprovacao from "../modais/fields/FieldAprovacao.vue";

import { CURRENCY } from "@/filters/formatters";

export default {
  props: {
    dataInfo: {
      type: Object,
      default() {
        return {
          descricao: "",
          spread: 0,
          spreadCompraAjustado: 0,
          codigo: "",
        };
      },
    },
  },
  methods: {
    formatterValue(value) {
      return CURRENCY(value);
    },
  },
  components: {
    FieldAprovacao,
  },
};
</script>

<style lang="scss" scoped>
.fields-box {
  display: flex;
  flex-direction: column;

  width: 40%;
}

.fields-section {
  display: flex;

  height: 50%;
}
</style>
