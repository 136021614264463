var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-base',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"no-bold",domProps:{"innerHTML":_vm._s(_vm.SUBMERCADO_SIGLA)}})]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"container-submercado-card"},[(_vm.loading)?_c('overlay-loader'):_vm._e(),_c('div',{staticClass:"row mb-2 align-items-center"},[_c('div',{staticClass:"col col-auto"},[_c('b',{staticClass:"pld-value",class:_vm.HAS_PLD_EDITADO ? 'hide-pld' : null},[_vm._v(" PLD: "),_c('span',{staticClass:"pld-span"},[_vm._v(_vm._s(_vm.submercado.getPrecoPLD()))])])]),_c('div',{staticClass:"col col-auto pld-editado",class:_vm.HAS_PLD_EDITADO ? 'pld-editado-on' : 'pld-editado-off'},[_vm._v(" PLD Editado: "),_c('span',{staticClass:"pld-span"},[_vm._v(_vm._s(_vm.submercado.getPLDEditado())+" ")])]),_c('div',{staticClass:"col col-auto"},[(
              _vm.$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR') ||
              _vm.$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR')
            )?_c('button',{staticClass:"btn btn-primary-text fw-bold",attrs:{"id":"editar-pld-button","data-cy":"button-editar_pld-pld-spread-components"},on:{"click":function($event){return _vm.$emit('editarPld', _vm.submercado)}}},[_vm._v(" (Editar PLD) ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('data-table',{attrs:{"data":_vm.tableData,"lineClickable":_vm.$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR') ||
            _vm.$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR'),"lineClass":"spread-item","fields":_vm.SubmercadoModel.getTableFields()},on:{"lineClicked":_vm.lineClicked},scopedSlots:_vm._u([{key:"descricao",fn:function(ref){
            var row = ref.row;
return _c('div',{staticClass:"table-pld"},[_vm._v(" "+_vm._s(row.descricao)+" ")])}},{key:"spreadCompra",fn:function(ref){
            var row = ref.row;
return _c('div',{staticClass:"table-pld"},[_vm._v(" "+_vm._s(_vm.getPropertyValue(row.ultimoSpread, "compra"))+" ")])}},{key:"spreadVenda",fn:function(ref){
            var row = ref.row;
return _c('div',{staticClass:"table-pld"},[_vm._v(" "+_vm._s(_vm.getPropertyValue(row.ultimoSpread, "venda"))+" ")])}},{key:"precoCompra",fn:function(ref){
            var row = ref.row;
return _c('div',{staticClass:"table-pld"},[_vm._v(" "+_vm._s(_vm.getPropertyValue(row.ultimoSpread, "precoCompra"))+" ")])}},{key:"precoVenda",fn:function(ref){
            var row = ref.row;
return _c('div',{staticClass:"table-pld"},[_vm._v(" "+_vm._s(_vm.getPropertyValue(row.ultimoSpread, "precoVenda"))+" ")])}},{key:"status",fn:function(ref){
            var row = ref.row;
return _c('div',{staticClass:"status-slot"},[_c('div',{class:['STATUS-DOT', _vm.STATUS_DOT_CLASS(row)]}),_c('span',{staticClass:"STATUS-DOT-TEXT"},[_vm._v(_vm._s(row.status))])])}},{key:"detalhes",fn:function(ref){
            var row = ref.row;
return _c('div',{staticClass:"d-flex ms-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.selected),expression:"row.selected"}],attrs:{"disabled":!_vm.$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR') ||
                row.status !== 'Pendente',"type":"checkbox","data-cy":"input-pld_submercado-pld-spread-components"},domProps:{"value":row.selected,"checked":Array.isArray(row.selected)?_vm._i(row.selected,row.selected)>-1:(row.selected)},on:{"click":function($event){$event.stopPropagation();return _vm.selectSpreadRow(row)},"change":function($event){var $$a=row.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=row.selected,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(row, "selected", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(row, "selected", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(row, "selected", $$c)}}}})])}}])},[_c('div',{staticClass:"all-checked",attrs:{"slot":"header-detalhes"},slot:"header-detalhes"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.allSubmercadoChecked),expression:"allSubmercadoChecked"}],attrs:{"type":"checkbox","disabled":!_vm.SHOW_SELECT_ALL_SPREADS ||
                !_vm.$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR'),"data-cy":"input-pld_todos_submercado-pld-spread-components"},domProps:{"checked":Array.isArray(_vm.allSubmercadoChecked)?_vm._i(_vm.allSubmercadoChecked,null)>-1:(_vm.allSubmercadoChecked)},on:{"click":_vm.toggleAllSubmercado,"change":function($event){var $$a=_vm.allSubmercadoChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.allSubmercadoChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.allSubmercadoChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.allSubmercadoChecked=$$c}}}})]),(_vm.HAS_PERMISSAO_EDITAR_APROVAR)?_c('div',{staticClass:"all-checked",attrs:{"slot":"header-tooltip"},slot:"header-tooltip"},[_c('div',[_c('tooltip',{attrs:{"width":"200px","arrowAlign":"up","alignVertical":"30px","alignHorizontal":"-180px"}},[_c('img',{staticClass:"icon-duvida",attrs:{"slot":"header","alt":"Informação","src":require("@/assets/icons/svg/duvida.svg")},slot:"header"}),_c('p',{staticClass:"span-pendentes",attrs:{"slot":"body"},slot:"body"},[_vm._v(" Selecione os itens "),_c('span',[_vm._v("Pendentes")]),_vm._v(" que deseja "),_c('span',[_vm._v("aprovar.")])])])],1)]):_c('div',{staticClass:"all-checked",attrs:{"slot":"header-tooltip"},slot:"header-tooltip"},[_c('div',[_c('tooltip',{attrs:{"width":"200px","arrowAlign":"up","alignVertical":"30px","alignHorizontal":"-180px"}},[_c('img',{staticClass:"icon-duvida",attrs:{"slot":"header","alt":"Informação","src":require("@/assets/icons/svg/duvida.svg")},slot:"header"}),_c('p',{staticClass:"span-pendentes",attrs:{"slot":"body"},slot:"body"},[_vm._v(" Apenas usuários com perfil "),_c('span',[_vm._v("Aprovador")]),_vm._v(" podem aprovar. ")])])],1)])])],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }