<template>
  <div class="sl-alcada-container">
    <div class="sl-title">Alçada Correspondente</div>
    <p>{{ descricao }}</p>
  </div>
</template>
<script>
export default {
  props: {
    descricao: String,
  },
};
</script>

<style scoped>
.sl-alcada-container {
  width: 271px;
}

.sl-title {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 16px;
}

p {
  font-size: 16px;
  font-weight: 400;
}
</style>
