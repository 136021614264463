import {
  CNPJ,
  moedaFormatter,
  realSymbolFormatter,
  replaceZeroWith,
  roundFormatter,
  truncateRoundFormatter,
} from "@/filters/formatters";

export default {
  filters: {
    // Máscara de CNPJ
    cnpj: (value) => CNPJ(value),
    // Arredondar em X
    round: (value, a, b) => roundFormatter(value, a, b),
    // Truncar em X e Arredondar em Y
    truncateRound: (value, a, b, c) => truncateRoundFormatter(value, a, b, c),
    // Replace zero with
    repZero: (value, a) => replaceZeroWith(value, a),
    // Coloca R$ na frente caso seja um número
    R$: (value) => realSymbolFormatter(value),

    // ## Filtros depreciados, porém ainda utilizados pelo projeto ##
    moeda: (value, a) => moedaFormatter(value, a),
    volume: (value) => {
      if (!value) value = 0;
      return Number(value).toLocaleString("pt-BR", {
        type: "currency",
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
    },
  },
};
