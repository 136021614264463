import { render, staticRenderFns } from "./FieldsPontoMedicao.vue?vue&type=template&id=239b4a36&scoped=true&"
import script from "./FieldsPontoMedicao.vue?vue&type=script&lang=js&"
export * from "./FieldsPontoMedicao.vue?vue&type=script&lang=js&"
import style0 from "./FieldsPontoMedicao.vue?vue&type=style&index=0&id=239b4a36&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "239b4a36",
  null
  
)

export default component.exports