<template>
  <div class="wrapper-approvar">
    <div class="user-form-title">
      {{ title }}
    </div>
    <div class="user-form-message">
      {{ message }}
    </div>
    <div class="user-form-field"></div>
    <div class="formGroup">
      <div class="label">Nome:</div>
      <input @input="change" v-model="$v.infos.nome.$model" type="text" />
      <span v-if="$v.infos.nome.$error" class="text-danger">
        Nome é requerido
      </span>
    </div>
    <div class="formGroup">
      <div class="label">E-mail:</div>
      <input @input="change" v-model="$v.infos.email.$model" type="text" />
      <span v-if="$v.infos.email.$error" class="text-danger">
        E-mail inválido
      </span>
    </div>
  </div>
</template>
<script>
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  props: {
    title: String,
    message: String,
  },

  data() {
    return {
      infos: {
        nome: "",
        email: "",
      },
    };
  },
  methods: {
    change() {
      this.$emit("getInfosUser", this.infos);
    },
    send() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("next");
      }
    },
  },
  validations: {
    infos: {
      nome: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.user-form-title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}
.user-form-message {
  margin-top: 10px;
  text-align: center;
}
.user-form-field {
  width: 100%;
  margin-top: 30px;
  background: #ff0;
  input {
    width: 100%;
  }
}

.formGroup {
  margin-bottom: 15px;
  input {
    width: 100%;
    padding: 5px 15px;
    border: none;
    border: solid 1px #ccc;
    border-radius: 5px;
  }
  .label {
    font-weight: bold;
  }
}
</style>
