<template>
  <div>
    <overlay-loader v-if="loading || loadingBilling" />
    <alert-message
      :config="pendency"
      :pendencyIndex="pendencyIndex"
      style="margin-top: 0 !important"
      :key="`pendencie_${pendencyIndex}`"
      v-for="(pendency, pendencyIndex) in getPendencies"
    />
    <div class="page-header">
      <img src="@/assets/logo/orange.svg" alt="" width="40" />
      <span>DEMONSTRATIVO DE FATURAMENTO DE DESCONTO GARANTIDO</span>
    </div>
    <div v-if="messageError && valido" class="error-message">
      <div style="max-width: 70%">
        {{ messageError }}
      </div>
    </div>
    <div v-if="!valido" class="error-message" style="text-align: center">
      <div style="max-width: 70%">
        O demonstrativo está expirado. Favor entrar em contato com seu officer
        pós-vendas
      </div>
    </div>
    <div
      v-if="
        !!demonstrativo &&
        !loading &&
        !loadingBilling &&
        valido &&
        !messageError
      "
      class="page-container"
    >
      <!--MENSAGENS APROVADO | CONTESTADO | PENDENTE -->
      <div
        class="mt-3 alert _alert-aprovado"
        v-if="situacaoDemonstrativo === 'APROVADO'"
      >
        O Demonstrativo de Faturamento foi
        <b>APROVADO</b> por
        <b>{{ demonstrativoBilling.situacaoDemonstrativo.nome }}</b>
        (e-mail: <b>{{ demonstrativoBilling.situacaoDemonstrativo.email }}</b
        >).
      </div>
      <div
        class="mt-3 alert _alert-contestado"
        v-if="situacaoDemonstrativo === 'REPROVADO'"
      >
        O Demonstrativo de Faturamento foi
        <b>CONTESTADO</b> por
        <b>{{ demonstrativoBilling.situacaoDemonstrativo.nome }}</b>
        (e-mail: <b>{{ demonstrativoBilling.situacaoDemonstrativo.email }}</b
        >).
      </div>
      <div
        class="mt-3 alert _alert-pendente"
        v-if="
          situacaoDemonstrativo !== 'APROVADO' &&
          situacaoDemonstrativo !== 'REPROVADO'
        "
      >
        DOCUMENTO PRELIMINAR PARA VALIDAÇÃO
      </div>
      <div>
        <div class="demonstrativo-info">
          <div class="demonstrativo-card">
            <span>Cliente: </span>
            <b>{{ demonstrativo.cliente }}</b>
            <b v-if="demonstrativo.codigoWbc">
              ({{ demonstrativo.codigoWbc }})
            </b>
          </div>
          <div class="demonstrativo-card">
            <span>Período de Consumo Faturado: </span>
            <b>{{ demonstrativo.periodoConsumoFaturado }}</b>
          </div>
          <div class="demonstrativo-card">
            <span>Gestora: </span>
            <b>{{ demonstrativo.gestora }}</b>
          </div>
          <div class="demonstrativo-card">
            <span>Data Limite para Aprovação: </span>
            <b>{{ demonstrativo.dataLimiteAprovacao }}</b>
          </div>
        </div>
        <div class="your-economy">
          <span class="your-economy-title">Sua Economia com a Matrix: </span>
          <div
            v-if="!!DG_ECONOMY_CONFIG && !!YOUR_ECONOMY_CONFIG"
            class="your-economy-cards"
          >
            <your-economy-card :config="DG_ECONOMY_CONFIG" />
            <your-economy-card :config="YOUR_ECONOMY_CONFIG" />
          </div>
        </div>
        <div class="your-accounts">
          <info-table-card
            width="32%"
            title="Sua Conta no Mercado CATIVO"
            :config="CONTA_MERCADO_CATIVO_CONFIG"
          />
          <info-table-card
            width="32%"
            titleColor="#f86511"
            title="Sua Conta no Mercado LIVRE"
            :config="CONTA_MERCADO_LIVRE_CONFIG"
          />
          <info-table-card
            width="32%"
            titleColor="#12b75d"
            title="Energia Renovável Matrix"
            :config="ENERGIA_RENOVAVEL_CONFIG"
          />
        </div>
        <div
          v-if="situacaoDemonstrativo === 'EM_PREENCHIMENTO'"
          class="rounded-container"
        >
          <div class="select-option">Selecione a opção desejada:</div>
          <div class="box-buttons-approval">
            <button
              @click="alterarSituacao('APROVADO')"
              class="_btn _btn--green"
            >
              CONFIRMAR
            </button>
            <button
              @click="alterarSituacao('REPROVADO')"
              class="_btn _btn--red ml-3"
            >
              CONTESTAR
            </button>
          </div>
        </div>
        <div>
          <consumption-history
            titleStyle="dark-title"
            :historicoConsumo="HISTORICO_CONSUMO"
          />
        </div>
        <div class="info-matrix-explains">
          <info-table-card
            width="39%"
            :config="INFORMACOES_PONTO"
            title="Informações do Ponto"
          />
          <description-table-card
            stripes
            fs="0.8rem"
            width="59%"
            :config="MATRIX_EXPLICA"
            title="Matrix Explica"
          />
        </div>
      </div>
      <div>
        <fatura-mercado-cativo-faturamento
          :distribuidora="DISTRIBUIDORA"
          :config="demonstrativo.faturaMercadoCativoFaturamento"
        />
        <fatura-mercado-livre-sistema-distribuidora
          :distribuidora="DISTRIBUIDORA"
          :config="
            demonstrativo.faturaMercadoLivreFaturamento
              .contratoDGCustosUsoSistemaDistribuidora
          "
        />
        <fatura-mercado-livre-comercializacao-energia
          :distribuidora="DISTRIBUIDORA"
          :config="
            demonstrativo.faturaMercadoLivreFaturamento
              .contratoDGCustosRelacionadosCcee
          "
        />
        <fatura-mercado-livre-mes-anterior
          :config="
            demonstrativo.faturaMercadoLivreFaturamento
              .contratoDGAjustesRelacionadosSimulacaoMesAnterior
          "
        />
        <fatura-mercado-livre-energia-renovavel
          :config="
            demonstrativo.faturaMercadoLivreFaturamento
              .contratoDGEnergiaRenovavelMatrixFaturamento
          "
        />
        <div>
          <div class="info-matrix-explains">
            <description-table-card
              width="100%"
              title="Observações"
              :config="OBSERVACOES"
            />
          </div>
        </div>
        <ajuste-mercado-cativo-faturamento
          :config="demonstrativo.faturaMercadoCativoAjuste"
        />
        <ajuste-mercado-livre-sistema-distribuidora
          :distribuidora="DISTRIBUIDORA"
          :config="
            demonstrativo.faturaMercadoLivreAjuste
              .contratoDGCustosUsoSistemaDistribuidoraAjuste
          "
        />
        <ajuste-mercado-livre-comercializacao-energia
          :config="
            demonstrativo.faturaMercadoLivreAjuste
              .contratoDGCustosRelacionadosCceeAjuste
          "
        />
      </div>
      <div>
        <div class="info-matrix-explains">
          <description-table-card
            width="100%"
            title="Observações"
            :config="OBSERVACOES_FOOTER"
          />
        </div>
      </div>
      <modal-base
        :maxWidth="'900px'"
        :footerHide="true"
        v-if="showModalAlterarSituacao"
        @closeModal="closeModals"
      >
        <template #header>
          <span v-if="situacaoModal == 'APROVADO'">
            APROVAR DEMONSTRATIVO DE FATURAMENTO
          </span>
          <span v-if="situacaoModal == 'REPROVADO'">
            CONTESTAR DEMONSTRATIVO DE FATURAMENTO
          </span>
        </template>
        <template #body>
          <div style="position: relative">
            <overlay-loader v-if="loadingModalSituacao" />
            <AprovarContestar
              @situacaoAtualizada="situacaoAtualizada"
              @finalizarDemonstrativo="finalizarDemonstrativo"
              :contrato="contratoBilling"
              :situacao="situacaoModal"
            />
          </div>
        </template>
      </modal-base>
    </div>
  </div>
</template>
<script>
import AlertMessage from "@/components/AlertMessage";
import ModalBase from "@/components/modals/ModalBase.vue";
import {
  ALIQUOTAS_PERCENTUAL,
  CURRENCY,
  CURRENCY_VALUE_ONLY,
  VOLUME,
} from "@/filters/formatters";
import ConsumptionHistory from "@/pages/hot-site/ConsumptionHistory";
import AjusteMercadoCativoFaturamento from "@/pages/hot-site/components/AjusteMercadoCativoFaturamento.vue";
import AjusteMercadoLivreComercializacaoEnergia from "@/pages/hot-site/components/AjusteMercadoLivreComercializacaoEnergia.vue";
import AjusteMercadoLivreSistemaDistribuidora from "@/pages/hot-site/components/AjusteMercadoLivreSistemaDistribuidora.vue";
import AprovarContestar from "@/pages/hot-site/components/AprovarContestar";
import DescriptionTableCard from "@/pages/hot-site/components/DescriptionTableCard.vue";
import FaturaMercadoCativoFaturamento from "@/pages/hot-site/components/FaturaMercadoCativoFaturamento.vue";
import FaturaMercadoLivreComercializacaoEnergia from "@/pages/hot-site/components/FaturaMercadoLivreComercializacaoEnergia.vue";
import FaturaMercadoLivreEnergiaRenovavel from "@/pages/hot-site/components/FaturaMercadoLivreEnergiaRenovavel.vue";
import FaturaMercadoLivreMesAnterior from "@/pages/hot-site/components/FaturaMercadoLivreMesAnterior.vue";
import FaturaMercadoLivreSistemaDistribuidora from "@/pages/hot-site/components/FaturaMercadoLivreSistemaDistribuidora.vue";
import InfoTableCard from "@/pages/hot-site/components/InfoTableCard.vue";
import YourEconomyCard from "@/pages/hot-site/components/YourEconomyCard.vue";
import DemonstrativoDGService from "@/services/DemonstrativoDGService";
import DemonstrativoService from "@/services/DemonstrativoService";
import { mapGetters } from "vuex";

export default {
  mounted() {
    const { contratoId } = JSON.parse(window.atob(this.$route.query.code));
    this.loading = true;
    DemonstrativoDGService.getDemonstrativoDG(contratoId)
      .then((response) => {
        this.loading = false;
        this.demonstrativo = response;
        this.getDemonstrativoBilling(contratoId);
      })
      .catch((error) => {
        this.loading = false;
        if (error.response.status === 404) {
          this.messageError =
            "Demonstrativo Desconto Garantido não encontrado ";
        } else {
          this.messageError =
            "Ocorreu um erro ao processar sua solicitação! Tente novamente mais tarde ou entre em contato com seu officer pós-vendas.";
          this.getDemonstrativoBilling(contratoId);
        }
      });
  },
  data() {
    return {
      contratoBilling: {},
      demonstrativoBilling: {},
      situacaoDemonstrativo: "",
      situacaoModal: null,
      payloadSituacao: null,
      loadingModalSituacao: false,
      showModalAlterarSituacao: false,
      loading: undefined,
      loadingBilling: undefined,
      demonstrativo: undefined,
      messageError: "",
      expirado: false,
      ativo: true,
      valido: true,
    };
  },
  methods: {
    formatterCurrency(value) {
      return CURRENCY(value);
    },
    getDemonstrativoBilling(contratoId) {
      this.loadingBilling = true;
      DemonstrativoService.getDemonstrativo(contratoId)
        .then((response) => {
          this.loadingBilling = false;
          this.expirado = response.demonstrativo.expirado;
          this.ativo = response.demonstrativo.ativo;
          if (this.expirado || !this.ativo) this.valido = false;
          this.demonstrativoBilling = response?.demonstrativo;
          this.situacaoDemonstrativo =
            response?.demonstrativo?.situacaoDemonstrativo?.situacao;
          this.contratoBilling = response?.contratoPrincipal;
        })
        .catch(() => {
          this.loadingBilling = false;
          this.messageError =
            "Ocorreu um erro ao processar sua solicitação! Tente novamente mais tarde ou entre em contato com seu officer pós-vendas.";
        });
    },
    finalizarDemonstrativo(payload) {
      this.payloadSituacao = payload;
      this.salvarSitucaoDemonstrativo();
    },
    salvarSitucaoDemonstrativo() {
      this.loadingModalSituacao = true;
      DemonstrativoService.patchSituacaoDG(
        this.contratoBilling.id,
        this.payloadSituacao
      )
        .then(() => {
          this.loadingModalSituacao = false;
          this.showModalAlterarSituacao = false;
          this.situacaoAtualizada(this.situacaoModal);
        })
        .catch((error) => {
          this.loadingModalSituacao = false;
          this.showModalAlterarSituacao = false;
          this.mxToggleWarningModal({
            message: error.response.data.message,
            btnTrue: "OK",
            btnFalse: "Fechar",
            title: "Atenção",
            showBtnFalse: false,
          })
            .then(() => {
              document.location.reload(true);
            })
            .catch(() => {});
        });
    },
    alterarSituacao(value) {
      this.situacaoModal = value;
      this.showModalAlterarSituacao = true;
    },
    closeModals() {
      this.showModalAlterarSituacao = false;
    },
    situacaoAtualizada(situacao) {
      const labels = {
        APROVADO: {
          title: "CONFIRMAÇÃO ENVIADA!",
          message: "Suas Notas Fiscais serão processadas e enviadas em breve.",
        },
        REPROVADO: {
          title: "CONTESTAÇÃO ENVIADA!",
          message: "Estamos avaliando sua solicitação e retornaremos em breve.",
        },
      };
      const title = labels[situacao].title;
      const message = labels[situacao].message;
      this.closeModals();
      this.mxToggleWarningModal({
        message,
        btnTrue: "Concluir",
        btnFalse: "Fechar",
        title,
        showBtnFalse: false,
      })
        .then(() => document.location.reload(true))
        .catch(() => {
          document.location.reload(true);
        });
    },
  },
  computed: {
    ...mapGetters(["getPendencies"]),

    DISTRIBUIDORA() {
      return this.demonstrativo?.informacoesPonto?.distribuidora;
    },
    HISTORICO_CONSUMO() {
      return this.demonstrativo.historicoConsumo;
    },
    DG_ECONOMY_CONFIG() {
      return {
        value:
          this.demonstrativo && this.demonstrativo.descontoGarantido
            ? this.demonstrativo.descontoGarantido
            : 0,
        type: "PERCENTUAL",
        title: "Desconto Garantido",
      };
    },
    YOUR_ECONOMY_CONFIG() {
      return {
        value:
          this.demonstrativo && this.demonstrativo.economiasMes
            ? this.demonstrativo.economiasMes
            : 0,
        type: "CURRENCY",
        title: "Suas Economias do Mês",
      };
    },
    CONTA_MERCADO_CATIVO_CONFIG() {
      return [
        {
          desc: "Simulação Conta Mercado CATIVO",
          value: CURRENCY_VALUE_ONLY(
            this.demonstrativo.contaMercadoCativo.simulacaoContaMercadoCativo
          ),
        },
        {
          desc: "% Desconto Garantido",
          value: this.$options.filters.PERCENTUAL(
            this.demonstrativo.contaMercadoCativo.percentualDescontoGarantido
          ),
        },
        {
          desc: "Sua Conta no Mercado LIVRE",
          value: CURRENCY_VALUE_ONLY(
            this.demonstrativo.contaMercadoCativo.contaMercadoLivre
          ),
          color: "#f86511",
        },
        {
          desc: "Economia Total do Mês",
          value: CURRENCY_VALUE_ONLY(
            this.demonstrativo.contaMercadoCativo.economiaTotalMes
          ),
        },
      ];
    },
    CONTA_MERCADO_LIVRE_CONFIG() {
      return [
        {
          desc: "Custos Distribuição Física",
          value: CURRENCY_VALUE_ONLY(
            this.demonstrativo.contaMercadoLivre.custoDistribuicaoFisica
          ),
        },
        {
          desc: "Ajustes Mês Anterior",
          value: CURRENCY_VALUE_ONLY(
            this.demonstrativo.contaMercadoLivre.adjustmentValue
          ),
        },
        {
          desc: "Custo com Câmara CCEE",
          value: CURRENCY_VALUE_ONLY(
            this.demonstrativo.contaMercadoLivre.custoCamaraCcee
          ),
        },
        {
          desc: "Energia Renovável Matrix",
          value: CURRENCY_VALUE_ONLY(
            this.demonstrativo.contaMercadoLivre.energiaRenovavelMatrix
          ),
          color: "#12b75d",
        },
        {
          desc: "Sua Conta no Mercado Livre",
          value: CURRENCY_VALUE_ONLY(
            this.demonstrativo.contaMercadoLivre.contaMercadoLivre
          ),
        },
      ];
    },
    ENERGIA_RENOVAVEL_CONFIG() {
      return [
        {
          desc: "Volume Apurado (MWh)",
          value: VOLUME(
            this.demonstrativo.energiaRenovavelMatrix.volumeApurado
          ),
        },
        {
          desc: "PROINFA (MWh)",
          value: VOLUME(this.demonstrativo.energiaRenovavelMatrix.proinfa),
        },
        {
          desc: "Preço com ICMS (R$/MWh)",
          value: CURRENCY_VALUE_ONLY(
            this.demonstrativo.energiaRenovavelMatrix.precoComIcms
          ),
          type: "CURRENCY",
        },
        {
          desc: "Preço sem ICMS (R$/MWh)",
          value: CURRENCY_VALUE_ONLY(
            this.demonstrativo.energiaRenovavelMatrix.precoSemIcms
          ),
        },
        {
          desc: "Energia Renovável Matrix",
          value: CURRENCY_VALUE_ONLY(
            this.demonstrativo.energiaRenovavelMatrix.energiaRenovavelMatrix
          ),
        },
      ];
    },
    INFORMACOES_PONTO() {
      return [
        {
          desc: "Unidade Consumidora",
          value: this.demonstrativo.informacoesPonto.unidadeConsumidora,
        },
        {
          desc: "Ponto de Medição",
          value: this.demonstrativo.informacoesPonto.pontoMedicao,
        },
        { desc: "CNPJ", value: this.demonstrativo.informacoesPonto.cnpj },
        {
          desc: "Distribuidora",
          value: this.demonstrativo.informacoesPonto.distribuidora,
        },
        {
          desc: "Subgrupo Tarifário",
          value: this.demonstrativo.informacoesPonto.subgrupoTarifario,
        },
        {
          desc: "Modalidade Tarifária",
          value: this.demonstrativo.informacoesPonto.modalidadeTarifaria,
        },
        {
          desc: "Demanda Contratada Ponta kW",
          value: this.demonstrativo.informacoesPonto.demandaContratadaPonta,
        },
        {
          desc: "Demanda Contratada Fora Ponta kW",
          value: this.demonstrativo.informacoesPonto.demandaContratadaForaPonta,
        },
        {
          desc: "Bandeira Vigente",
          value: this.demonstrativo.informacoesPonto.bandeiraVigente,
        },
        {
          desc: "Alíquota ICMS",
          value: ALIQUOTAS_PERCENTUAL(
            this.demonstrativo.informacoesPonto.aliquotaIcms
          ),
        },
        {
          desc: "[2] Alíquota PIS",
          value: ALIQUOTAS_PERCENTUAL(
            this.demonstrativo.informacoesPonto.aliquotaPis
          ),
        },
        {
          desc: "[2] Alíquota COFINS",
          value: ALIQUOTAS_PERCENTUAL(
            this.demonstrativo.informacoesPonto.aliquotaCofins
          ),
        },
      ];
    },
    MATRIX_EXPLICA() {
      return [
        {
          info: "[1] Volume Apurado: ",
          desc: "((Montante SCDE*(1+Perdas da Distribuidora))-PROINFA)",
        },
        {
          info: "Montante SCDE: ",
          desc: "Montante de energia elétrica total consumo do no mês suprido.",
        },
        {
          info: "Perdas da Distribuidora: ",
          desc: "Perdas energéticas das distribuidoras em relação à energia injetada na rede.",
        },
        {
          info: "PROINFA: ",
          desc: "Programa de Incentivo às Fontes Alternativas de Energia Elétrica.",
        },
        {
          info: "[2] PIS / COFINS: ",
          desc: "As alíquotas consideradas para fins de simulação do faturamento são aquelas capturadas no site da distribuidora quando disponível. Caso contrário utilizamos um média móvel dos últimos três meses destes impostos, conforme os valores apresentados na fatura da distribuidora.",
        },
        {
          info: "Resolução Homologatória: ",
          desc: "DADOS A SEREM INSERIDOS",
        },
      ];
    },
    OBSERVACOES() {
      return [
        {
          info: "[3] Tarifa sem Impostos: ",
          desc: `Tarifas da distribuidora, conforme resolução ${this.demonstrativo?.resHomologatoria}`,
        },
        {
          info: "[4] Custos CCEE: ",
          desc: "indicados estão sendo estimados. Quando os valores reais forem divulgados pela CCEE, se necessário, serão feitos ajustes na conta. Os ajustes serão contabilizados no preço de faturamento do mês seguinte para os cálculos.",
        },
        {
          info: "[5] Ajustes: ",
          desc: "os valores considerados nas colunas “Valor Simulado” e “Valor Real” desta tabela consideram os ajustes retroativos se aplicável, conforme a página 3.",
        },
      ];
    },
    OBSERVACOES_FOOTER() {
      return [
        {
          info: "[3] Tarifa com Impostos: ",
          desc: "Dado variável conforme o tipo de energia contratada para o mês de referência. No mês seguinte esse percentual será ajustado conforme o desconto disponibilizado pela CCEE. Esse ajuste será contabilizado para os cálculos do preço do mês seguinte.",
        },
        {
          info: "[4] Custos CCEE: ",
          desc: "indicados estão sendo estimados. Quando os valores reais forem divulgados pela CCEE, se necessário, serão feitos ajustes na conta. Os ajustes serão contabilizados no preço de faturamento do mês seguinte para os cálculos.",
        },
        {
          desc: "Considerando a Resolução Normativa nº 1.080/23, a qual alterou a aplicação das cotas de Proinfa a partir de janeiro de 2024, e o CO 1.012/23 da Câmara de Comercialização de Energia Elétrica, para o faturamento do suprimento a partir do mês de fevereiro de 2024, será considerada a cota de Proinfa divulgada pela CCEE referente ao mês anterior.",
        },
      ];
    },
  },
  filters: {
    PERCENTUAL: (value) => {
      if (typeof value != "number") {
        return "--";
      }
      return `${value.toFixed(2).replaceAll(".", ",")}`;
    },
  },
  components: {
    AlertMessage,
    InfoTableCard,
    YourEconomyCard,
    ConsumptionHistory,
    DescriptionTableCard,
    FaturaMercadoLivreMesAnterior,
    FaturaMercadoCativoFaturamento,
    AjusteMercadoCativoFaturamento,
    FaturaMercadoLivreEnergiaRenovavel,
    AjusteMercadoLivreSistemaDistribuidora,
    FaturaMercadoLivreSistemaDistribuidora,
    AjusteMercadoLivreComercializacaoEnergia,
    FaturaMercadoLivreComercializacaoEnergia,
    AprovarContestar,
    ModalBase,
  },
};
</script>
<style lang="scss" scoped>
.page-header {
  color: white;
  padding: 0.7% 7.5%;
  background-color: #2a2a2a;
  display: flex;
  align-items: center;

  & span {
    margin-left: 2.5%;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.page-container {
  display: flex;
  overflow: auto;
  color: #2a2a2a;
  padding: 48px 7%;
  flex-direction: column;
  background-color: #e5e5e5;
}

.demonstrativo-info {
  display: flex;
  padding: 1% 2%;
  flex-wrap: wrap;
  border-radius: 0.3em;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: 2px 2px 4px 0 #2a2a2a22;
}

.demonstrativo-card {
  width: 50%;
  display: flex;
  padding: 1% 0;

  & b {
    margin-left: 1%;
  }
}

.your-economy {
  padding-top: 3%;
}

.your-economy-title {
  font-size: 3rem;
  color: #f86511;
  font-weight: 800;
}

.your-economy-cards {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
}

.your-accounts {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
}

.info-matrix-explains {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
}

.table-title {
  padding: 2% 1%;
  font-weight: 700;
  color: #ffffff;
  font-size: 1.5rem;
  background-color: #2a2a2a;
  border-radius: 0.3em 0.3em 0 0;
  border-bottom: 1px solid #f86511;
}

.rounded-container {
  border-radius: 0.3em;
}

.select-option {
  padding: 10px;
  color: #fff;
  font-size: 16px;
  margin-top: 25px;
  font-weight: bold;
  text-align: center;
  background: #2a2a2a;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 0.3em 0.3em 0 0;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.box-buttons-approval {
  display: flex;
  min-height: 140px;
  background: #fff;
  align-items: center;
  margin: 0px 0 30px 0;
  justify-content: center;
  border-radius: 0 0 0.3em 0.3em;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

._btn {
  color: White;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  width: 250px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
}

._btn--green {
  background: #12b75d;
  margin-right: 15px;

  &:hover {
    background: rgba($color: #12b75d, $alpha: 0.8);
  }
}

._btn--red {
  background: #f24242;
  margin-left: 15px;

  &:hover {
    background: rgba($color: #f24242, $alpha: 0.8);
  }
}

._alert-contestado {
  background: #fce7e7;
  border: 2px solid #ff7575;
  color: #ff7575;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}

._alert-aprovado {
  background: #fff;
  border: 1px solid #2ed47a;
  color: #2ed47a;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}

._alert-pendente {
  background: #f1f1f1;
  border: 2px solid #929292;
  color: #525252;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}

.error-message {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f86511;
  font-size: 1.5rem;
  font-weight: 600;
  height: 80vh;
  flex-direction: column;
}
</style>
