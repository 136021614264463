<template>
  <div>
    <generic-modal
      :width="'90%'"
      :maxWidth="'800px'"
      :minWidth="'300px'"
      :footerHide="true"
      :height="'500px'"
      :iconClose="true"
      @close="close"
    >
      <template v-slot:header>{{ title }}</template>

      <div class="wrapper">
        <div class="atention">ATENÇÃO</div>
        <div class="messages">
          <span>
            {{ text }}
          </span>
        </div>
        <div class="form">
          <div class="group">
            <div class="form-label">Nome:</div>
            <input
              maxlength="50"
              v-model.trim="$v.userInfo.name.$model"
              class="input"
              type="text"
              :class="{
                error: $v.userInfo.name.$error,
              }"
            />
            <div class="form-label">E-mail</div>
            <input
              v-model.trim="$v.userInfo.email.$model"
              class="input"
              type="email"
              :class="{
                error: $v.userInfo.email.$error,
              }"
            />
          </div>
        </div>
        <div class="box-button">
          <button @click="next" class="btn">AVANÇAR</button>
        </div>
      </div>
    </generic-modal>
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  props: ["title", "text", "recompraMannual", "offToRange"],
  data() {
    return {
      loading: undefined,
      userInfo: {
        name: "",
        email: "",
      },
    };
  },
  methods: {
    next() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let result = {
          infos: this.userInfo,
          type: "APPROVAL",
        };
        if (this.recompraMannual) {
          this.$emit("userResultForManual", result);
        } else if (this.offToRange) {
          this.$emit("userResultOffToRange", result);
        } else {
          this.$emit("userResult", result);
        }
      }
    },
    close() {
      this.$emit("closeGetDataUser");
    },
  },
  validations: {
    userInfo: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 15px;
  position: relative;
}
.atention {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #707683;
  background: #e8e8e8;
  border-radius: 5px;
  padding: 6px;
  text-align: center;
}
.messages {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #000000;
  margin: 20px 0px 33px 0px;
}
.form {
  text-align: center;
  width: 80%;
  margin: auto;
}

.form-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #000000;
  text-align: left;
  margin-bottom: 3px;
}

.input {
  background: #f1f1f1;
  border-radius: 5px;
  border: none;
  height: 25px;
  border: solid 1px #f1f1f1;
  width: 100%;
  margin-bottom: 30px;
  padding-left: 10px;
  font-size: 12px;
}
.box-button {
  width: 100%;
  text-align: center;
}
.btn {
  width: 174px;
  height: 42px;
  left: calc(50% - 174px / 2);
  top: 573px;
  background: #f86511;
  border-radius: 4px;
  color: #fff;
  &:hover {
    background: rgba($color: #f86511, $alpha: 0.8);
    color: #fff;
  }
}
.error {
  border: 1px solid red;
}
</style>