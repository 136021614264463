<template>
  <div v-elementAsyncLoader="loading">
    <div
      class="page"
      :style="mxIsChromeBrowser() && 'height: 117.6vh; width: 117.6vw'"
    >
      <alert-message
        v-for="(pendency, pendencyIndex) in getPendencies"
        :key="`pendencie_${pendencyIndex}`"
        :config="pendency"
        :pendencyIndex="pendencyIndex"
      />
      <div class="left-container">
        <div class="copyright-footer mb-3">
          <img
            src="@/assets/logo/orange.svg"
            class="copyright-logo mx-4"
            alt="Logotipo Matrix Energia"
          />
          <span class="copyright-text">
            Matrix Energia - Todos os direitos reservados.
          </span>
        </div>
      </div>
      <div class="right-container">
        <div class="container row justify-content-center">
          <div class="box-content">
            <div class="mt-3 row justify-content-center">
              <div
                class="d-flex px-5 flex-column col align-items-center justify-content-between"
              >
                <div class="mt-3 fw-200 fs-3">
                  Billing <span class="fw-bold highlight-text">Matrix</span>
                </div>
                <div class="d-flex justify-content-center w-100 px-3">
                  <hr class="mt-2 mb-4 w-100" />
                </div>
                <div
                  style="font-size: 0.85rem"
                  class="d-flex flex-column justify-content-center align-items-center w-100 px-3"
                  v-if="this.$route.path.indexOf('/signout') >= 0"
                >
                  <span class="text-center">
                    Você foi desconectado com sucesso.
                  </span>
                  <span class="text-center">
                    É recomendado
                    <strong>fechar as abas do navegador</strong> antes de
                    continuar.
                  </span>
                </div>
                <div
                  style="font-size: 0.85rem"
                  class="d-flex flex-column justify-content-center align-items-center w-100 px-3"
                  v-else
                >
                  <span class="text-center">
                    Realize o <b>login</b> para acessar o <b>Billing</b>.
                  </span>
                </div>
              </div>
            </div>
            <form class="needs-validation mt-3 px-5" novalidate>
              <button
                @click.prevent="tryLogin"
                class="btn btn-primary w-100 mb-4"
                data-cy="button-tentar_login-pages-login"
              >
                Entrar com a Microsoft
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertMessage from "@/components/AlertMessage";
import PermissoesService from "@/services/PermissoesService";
import { loginRedirect } from "@/utils/authentication";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: undefined,
    };
  },
  computed: {
    ...mapGetters(["getPendencies"]),
  },
  async beforeMount() {
    if (window.localStorage.getItem("u-keys")) {
      this.checkCurrentLogin();
    }
  },
  methods: {
    tryLogin() {
      loginRedirect();
    },
    checkCurrentLogin() {
      this.loading = PermissoesService.getPermissoes()
        .then(() => {
          this.$router.push({ path: "/faturamento" });
        })
        .catch(() => {
          this.tryLogin();
        });
    },
  },
  components: {
    AlertMessage,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/_colors.scss";
@import "@/assets/style/mixins.scss";
// OVERRIDE PRIMARY
.btn-primary {
  background-color: #081630;
  border: none;
}
.btn-primary:hover {
  background-color: #182d53;
  border: none;
}

.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #f2edea;
}

.right-container {
  @include flex-centered;
  width: 50%;
  height: 100%;
}

.left-container {
  display: flex;
  align-items: end;
  width: 50%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../assets/images/matrix_background-min.png");
}

.copyright-footer {
  display: flex;
  align-items: center;
}

.copyright-logo {
  width: 20px;
}

.copyright-text {
  font-size: 10px;
  color: white;
}

.box-content {
  border-radius: 24px;
  width: 90% !important;
  margin: 0px !important;
  padding: 0px !important;
  background-color: $color--white;
  box-shadow: 0px 0px 5px 5px #22222211;
}

.card-login-footer {
  margin: 0 0 30px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 24px 24px;
}

.card-login-footer-text {
  cursor: pointer;
  color: #3c76f1;
  text-decoration: underline;
}
.card-login-footer-text:hover {
  color: #1f4496;
}

.highlight-text {
  color: #ec6723;
}

form {
  padding: 10px;
}

.is-invalid {
  border: 1px solid red;
}
</style>
