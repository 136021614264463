var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"__bar"},_vm._l((_vm.splitsData),function(ref,i){
      var label = ref.label;
      var loadingLabel = ref.loadingLabel;
      var width = ref.width;
      var value = ref.value;
      var status = ref.status;
      var extraInfo = ref.extraInfo;
      var color = ref.color;
      var transition = ref.transition;
return _c('div',{key:i,staticClass:"__bar__split",style:({ width: width })},[_c('div',{staticClass:"__bar__split__description"},[(status === 'error')?_c('span',{staticClass:"__bar__split__status-error"},[_vm._v(" "+_vm._s(label)+" "),_c('i',{staticClass:"icon icon-close"}),_vm._v(" (Finalizado com Erro) ")]):(status === 'finished')?_c('span',{staticClass:"__bar__split__status-finalized"},[_vm._v(" "+_vm._s(label)+" ✓ (Finalizado com Sucesso) ")]):(status === 'running')?_c('span',[_vm._v(" "+_vm._s(loadingLabel)+" ")]):_c('span',[_vm._v(_vm._s(label))])]),_c('div',{staticClass:"__bar__split__rail",style:({ borderRadius: _vm.getSplitBorderRadius(i) })},[_c('div',{staticClass:"__bar__split__status",style:({
          width: (value + "%"),
          background: color,
          borderRadius: _vm.getSplitBorderRadius(i),
          transition: ("width " + (transition.duration) + "ms " + (transition.easing) + " " + (transition.delay) + "ms"),
        })}),_c('label',{staticClass:"__bar__split__percentage",style:({ color: value > 0 ? 'white' : 'black' })},[_vm._v(" "+_vm._s(value)+"% "),(extraInfo)?[_vm._v("- "+_vm._s(extraInfo))]:_vm._e()],2)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }