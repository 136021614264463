import { EMPTY } from "@/filters/formatters";
import RotinaImportacaoEmLoteModel from "../RotinaImportacaoEmLoteModel";

export default class TableArquivoModel extends RotinaImportacaoEmLoteModel {
  static getTableFields() {
    return new Array(
      {
        name: "codigoContratoReferencia",
        title: "Contrato Ref.",
        width: "15%",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "codigoContrato",
        title: "Contrato",
        width: "15%",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "razaoSocial",
        title: "Razão Social",
        width: "35%",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "codigoPonto",
        title: "Ponto de Medição",
        width: "15%",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "icons",
        title: "",
        width: "5%",
      }
    );
  }
}
