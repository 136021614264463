<template>
  <div class="page-curto-prazo">
    <modal-faturamento-contrato-wbc
      @closeModal="closeModal"
      :rowSelected="rowSelected"
      @listaCurtoPrazo="listaCurtoPrazo"
      v-if="showModalFaturamentoContratoWbc"
      data-cy="modal-abrir_modal_contrato_wbc-pages-curto-prazo"
    />
    <field-search
      @getSearchValue="getSearchValue"
      style="margin-bottom: 30px; width: 100%"
      data-cy="field-search_search_curto_prazo-pages-curto-prazo"
      :placeholder="'Busque por Apelido, Contrato ou Responsável Matrix'"
    />
    <div v-elementAsyncLoader="tableLoading">
      <page-painel>
        <template #header> Proposta de Curto Prazo </template>
        <template #body>
          <div class="page-curto-prazo__body">
            <data-table
              :stripes="false"
              :data="tableData"
              @setPage="setPage"
              :pagination="true"
              :lineClickable="true"
              @setPerPage="setPerPage"
              @lineClicked="lineClicked"
              :paginationData="paginationData"
              :fields="tableModel.getTableFields()"
            >
              <div
                slot="status"
                slot-scope="{ row }"
                v-if="row.status == 'Boletada'"
              >
                <span class="font-color"
                  ><b>{{ row.status }}</b></span
                >
                <i class="icon icon-new-window ms-2"></i>
              </div>
              <div v-else-if="row.status == 'Aprovada'">
                <span class="font-color"
                  ><b>{{ row.status }}</b></span
                >
                <i class="icon icon-new-window ms-2"></i>
              </div>
              <div v-else>
                <span class="font-color"
                  ><b>{{ row.status }}</b></span
                >
              </div>
            </data-table>
          </div>
        </template>
      </page-painel>
    </div>
  </div>
</template>
<script>
import { isCNPJ } from "@/filters/assertions";
import DataTable from "@/components/tables/DataTable.vue";
import PagePainel from "@/components/panels/PagePanel.vue";
import FieldSearch from "@/components/fields/FieldSearch.vue";
import PropostaCurtoPrazoService from "@/services/PropostaCurtoPrazoService";
import TableCurtoPrazoModel from "@/models/tablesModels/TableCurtoPrazoModel";
import ModalFaturamentoContratoWbc from "./components/modals/ModalFaturamentoContratoWbc.vue";

export default {
  data() {
    return {
      searchFilter: {},
      tableData: new Array(),
      tableLoading: undefined,
      rowSelected: new Object(),
      paginationData: undefined,
      showModalFaturamentoContratoWbc: false,
      tableModel: new TableCurtoPrazoModel(),
      paginationFilters: {
        page: 0,
        size: 10,
        desc: true,
      },
    };
  },
  mounted() {
    this.listaCurtoPrazo();
  },
  methods: {
    listaCurtoPrazo() {
      this.tableLoading = PropostaCurtoPrazoService.getPropostaCurtoPrazo({
        ...this.searchFilter,
        ...this.paginationFilters,
      })
        .then(({ data, pagination }) => {
          this.tableData = data || new Array();
          this.paginationData = pagination;
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    setPage(page) {
      this.paginationFilters.page = page;
      this.listaCurtoPrazo();
    },
    setPerPage(value) {
      this.paginationFilters.size = value;
      this.listaCurtoPrazo();
    },
    getSearchValue(value) {
      value ? (this.searchFilter.filtro = value) : (this.searchFilter = {});
      this.listaCurtoPrazo();
    },
    searchingByCNPJ(value) {
      if (!value) return false;
      return isCNPJ(value);
    },
    lineClicked(row) {
      if (["Aprovada", "Boletada"].includes(row.status)) {
        this.rowSelected = row;
        this.showModalFaturamentoContratoWbc = true;
      }
    },
    closeModal() {
      this.showModalFaturamentoContratoWbc = false;
    },
    openModal() {},
  },
  components: {
    DataTable,
    PagePainel,
    FieldSearch,
    ModalFaturamentoContratoWbc,
  },
};
</script>
<style lang="scss" scoped>
.page-curto-prazo {
  padding-top: 24px;
  &__body {
    padding: 30px;
  }
}

.icon-new-window:before {
  color: #2a2a2a !important;
}

.font-color {
  font-weight: 400;
}
</style>
