<template>
  <div class="pre-input-group">
    <div
      class="input-group"
      :class="{
        'is-invalid': (!VALIDATED || !externallyValidated) && dirty,
        'file-attached': IS_FILE_ATTACHED,
        'is-dragging': isDragging,
        disabled,
      }"
    >
      <span :class="{ 'file-attachment-icons': IS_FILE_ATTACHED }">
        <i class="icon-file" v-for="_ in model.content.slice(0, 3)" />
        <div class="extra-file" v-if="model.content.length > 3">
          <i class="icon-file" />
          <span>···</span>
        </div>
        <template v-if="!IS_FILE_ATTACHED">
          <i class="icon-exportador" />
          Arraste e solte arquivos aqui ou clique para enviar.<br />
          <template v-if="fileType && fileType.length">
            Apenas em {{ fileType.length > 1 ? "formatos" : "formato" }}
            {{ fileType.join(", ") }}
          </template>
        </template>
      </span>
      <input
        type="file"
        :disabled="disabled"
        @change="changeFile"
        ref="inputUploadField"
        @dragenter="isDragging = true"
        @dragleave="isDragging = false"
        @dragover.prevent
        @drop.prevent="drop"
        multiple
        :accept="fileType ? fileType.join(',') : ''"
      />
    </div>
    <button
      @click="downloadLayoutFile"
      v-if="getLayoutFile !== null"
      :disabled="loadingLayout"
      :class="{ disabled: loadingLayout }"
    >
      <div>
        <overlay-loader
          v-if="loadingLayout"
          :styles="{
            spinnerWrapper: {
              width: '30px',
              height: '30px',
            },
          }"
        />
        <i class="icon-descarregar small" />
        <span>Baixar template de Excel</span>
      </div>
    </button>
  </div>
</template>

<script>
import { ERROR, MAX_FILE_SIZE } from "@/constants/strings";
import { downloadFileFromResponseObject } from "@/utils/functionsUtils";

export default {
  props: {
    disabled: Boolean,
    modelProp: Object,
    externallyValidated: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    fileType: {
      type: Array,
      required: false,
    },
    getLayoutFile: {
      type: Function,
      required: false,
      default: null,
    },
    fileSizeMatters: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      model: {
        name: "arquivos",
        content: [],
      },
      isDragging: false,
      loadingLayout: false,
      validations: {
        hasRightExtension: false,
        inMaxNameSize: false,
        inMaxContentSize: false,
      },
      dirty: false,
    };
  },
  computed: {
    IS_FILE_ATTACHED() {
      return !!this.model.content.length;
    },
    VALIDATED() {
      for (const key in this.validations) {
        if (!this.validations[key]) return false;
      }
      return true;
    },
  },
  watch: {
    modelProp: {
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
        if (this.model.content.length && this.$refs.inputUploadField.files) {
          this.$refs.inputUploadField.files = new DataTransfer().files;
        }
        this.validate();
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
        this.validate();
      },
    },
  },
  methods: {
    changeFile(event) {
      this.addFiles([...event.target.files]);
    },
    drop(event) {
      this.isDragging = false;
      this.addFiles([...event.dataTransfer.files]);
    },
    addFiles(files) {
      if (this.disabled) return;
      const filteredFiles = files.filter((file) => {
        return this.hasRightExtension(file);
      });
      if (filteredFiles.length !== files.length) {
        this.mxToggleErrorMessageAlert(ERROR.WRONG_FILE_EXTENSION);
      }

      const fileNames = {};
      const newFilteredFiles = this.model.content
        .concat(filteredFiles)
        .reverse()
        .filter((file) => {
          if (fileNames[file.name]) {
            return false;
          } else {
            fileNames[file.name] = true;
            return true;
          }
        });

      this.model.content = newFilteredFiles.reverse();
    },
    async downloadLayoutFile() {
      if (this.loadingLayout) return;

      this.loadingLayout = true;

      try {
        const data = await this.getLayoutFile();
        downloadFileFromResponseObject(data, "Template de Arquivo.xlsx");
      } catch (error) {
        this.mxHandleRequestError(error);
      } finally {
        this.loadingLayout = false;
      }
    },
    hasRightExtension(file) {
      const filename = file.name;
      for (let j = 0; j < this.fileType.length; j++) {
        const ext = this.fileType[j];
        if (
          filename.indexOf(ext) &&
          filename.length - ext.length === filename.indexOf(ext)
        ) {
          return true;
        }
      }
      return false;
    },
    validate() {
      if (!this.dirty) this.dirty = true;
      const value = this.model.content;

      if (!value || !value.length) {
        this.validated = {
          hasRightExtension: false,
          inMaxNameSize: false,
          inMaxContentSize: false,
        };
        this.$emit("validationTriggered", false);
        return;
      }

      const validated = {
        hasRightExtension: true,
        inMaxNameSize: true,
        inMaxContentSize: true,
      };

      if (this.fileType) {
        for (let i = 0; i < value.length; i++) {
          if (!this.hasRightExtension(value[i])) {
            validated.hasRightExtension = false;
          }
        }
      }
      if (!validated.hasRightExtension) {
        this.mxToggleErrorMessageAlert(ERROR.WRONG_FILE_EXTENSION);
      }

      for (let i = 0; i < value.length; i++) {
        if (value[i].name.length >= 100) {
          validated.inMaxNameSize = false;
        }
      }
      if (!validated.inMaxNameSize) {
        this.mxToggleErrorMessageAlert(ERROR.MAX_FILE_SIZE_NAME);
      }

      if (this.fileSizeMatters) {
        for (let i = 0; i < value.length; i++) {
          if (value[i].size >= MAX_FILE_SIZE) {
            validated.inMaxContentSize = false;
          }
        }
      }
      if (!validated.inMaxContentSize) {
        this.mxToggleErrorMessageAlert(ERROR.MAX_FILE_SIZE_MB);
      }

      this.validations = validated;
      this.$emit("validationTriggered", this.VALIDATED);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.pre-input-group {
  position: relative;
  width: 100%;
  height: 100%;
  > button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: $color--primary-white;
    border: 1px solid #b1b1b1;
    border-radius: 5px;
    padding: 5px 10px;
    &:not(.disabled) {
      transition: background-color 200ms ease-in-out;
      cursor: pointer;
      border: 1px solid #505050;
      &:hover {
        background-color: #f3f2f2;
      }
    }
    > div {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      > i {
        font-size: 1em;
        &::before {
          color: #505050;
        }
      }
    }
  }
}

.input-group {
  height: 100%;
  min-height: 200px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px dashed $color--primary-black;
  transition: opacity 200ms ease-in-out;
  &.disabled {
    > span {
      opacity: 0.5;
    }
  }
  &:not(.disabled) {
    &:hover {
      opacity: 0.5;
    }
    &.is-dragging {
      border: 1px solid #ffb946;
    }
    &:not(.is-dragging) {
      &.is-invalid {
        border: 1px solid #dc3545;
      }
      &:not(.is-invalid) {
        &.file-attached {
          border: 2px solid #2ed47a;
        }
      }
    }
    > input {
      cursor: pointer;
    }
  }
  > span {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 16px;
    i {
      font-size: 3em;
      &::before {
        color: #505050;
      }
    }
    &.file-attachment-icons {
      flex-direction: row;
      gap: 24px;
    }
    > .extra-file {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      > i {
        opacity: 0.5;
      }
      > span {
        position: absolute;
        font-size: 32px;
        font-weight: 700;
      }
    }
  }
  > input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }
}
</style>
