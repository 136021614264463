<template>
  <div class="table-card subtitled-only" v-if="config">
    <div class="table-subtitle">
      B. Custos relacionados à Câmara de Comercialização de Energia Elétrica
      (CCEE)
    </div>
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>Dado</th>
          <th>Unidade</th>
          <th>Medição Simulada</th>
          <th>Medição Apurada</th>
          <th>Tarifa s/ Impostos - Sim.</th>
          <th>Tarifa s/ Impostos - Apurada</th>
          <th>Ajuste</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Liquidação [4]</td>
          <td>R$</td>
          <td>{{ config.montanteDemandaCceeAj | truncateRound(4, 3) }}</td>
          <td>{{ config.montanteFaturaCceeAj | truncateRound(4, 3) }}</td>
          <td>
            {{ config.tarifaLiquidacaoSimplesAj | truncateRound(3, 2) | R$ }}
          </td>
          <td>
            {{ config.tarifaLiquidacaoApuradaAj | truncateRound(3, 2) | R$ }}
          </td>
          <td class="table-bold">
            {{ config.ajusteLiquidacaoAj | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>EER [4]</td>
          <td>R$</td>
          <td>{{ config.montanteDemandaCceeAj | truncateRound(4, 3) }}</td>
          <td>{{ config.montanteFaturaCceeAj | truncateRound(4, 3) }}</td>
          <td>{{ config.tarifaEerSimplesAj | truncateRound(3, 2) | R$ }}</td>
          <td>{{ config.tarifaEerApuradaAj | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.ajusteEerAj | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Contribuição Associativa [4]</td>
          <td>R$</td>
          <td>{{ config.montanteDemandaCceeAj | truncateRound(4, 3) }}</td>
          <td>{{ config.montanteFaturaCceeAj | truncateRound(4, 3) }}</td>
          <td>
            {{
              config.tarifaContribuicaoAssociativaSimplesAj
                | truncateRound(3, 2)
                | R$
            }}
          </td>
          <td>
            {{
              config.tarifaContribuicaoAssociativaApuradaAj
                | truncateRound(3, 2)
                | R$
            }}
          </td>
          <td class="table-bold">
            {{
              config.ajusteContribuicaoAssociativaAj | truncateRound(3, 2) | R$
            }}
          </td>
        </tr>
        <tr class="table-bold white-row">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>Total</td>
          <td class="table-total">
            {{ config.totalAjCcee | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["config"],
};
</script>
<style lang="scss" scoped>
.table-card {
  margin-top: 25px;
  border-radius: 0 0 0.3em 0.3em;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  box-shadow: 2px 2px 5px 0 #2a2a2a22;
}
.table-subtitle {
  padding: 0.4em;
  background-color: #cecece;
  font-size: 0.9rem;
  font-weight: 700;
  border-top: solid 1px #f86511;
}
table {
  border-collapse: collapse;
  margin: 0;
  font-size: 0.9rem;
  border-radius: 0 0 0.3em 0.3em;

  thead {
    background-color: #e8e8e8;
  }

  tbody {
    tr {
      width: 100%;
      &:nth-child(even) {
        background-color: #f2edeb;
      }
      &:last-child {
        border-radius: 0 0 0.3em 0.3em;
        :first-child {
          border-radius: 0 0 0 0.3em;
        }
      }
    }
  }

  .white-row {
    background-color: #ffffff !important;
  }

  .table-bold {
    font-weight: 700;
  }

  .table-total {
    background-color: #fff1ec;
    border-top: solid 1px #f86511;
    border-radius: 0 0 0.3em 0;
  }
}
</style>
