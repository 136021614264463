<template>
  <div>
    <modal-base width="680px" @closeModal="close">
      <template #header> ATENÇÃO </template>
      <template #body>
        <div class="container-body">
          <p>
            Ao prosseguir, o Faturamento Atual será encerrado e NÃO PODERÁ SER
            REABERTO.
          </p>
        </div>
      </template>
      <template #footer>
        <div class="container-footer">
          <button class="btn btn-primary-outlined cancel-btn" @click="close">
            Cancelar
          </button>
          <button
            class="btn btn-primary-full-filled proceed-btn"
            @click="proceed"
          >
            REALMENTE QUERO PROSSEGUIR COM O FECHAMENTO
          </button>
        </div>
      </template>
    </modal-base>
  </div>
</template>

<script>
import ModalBase from "@/components/modals/ModalBase.vue";
export default {
  methods: {
    close() {
      this.$emit("close");
    },
    proceed() {
      this.$emit("proceedAtionCloseFaturamento");
    },
  },
  components: {
    ModalBase,
  },
};
</script>

<style lang="scss" scoped>
.container-body {
  font-weight: 700;
  margin: 22px 16px;
  color: #d40d00;
  text-align: center;
}

.cancel-btn {
  margin-right: 22px;
  border-radius: 10px !important;
}

.proceed-btn {
  background: #d40d00 !important;
  border: 1px solid #d40d00 !important;
  font-weight: 600;

  &:hover {
    color: #d40d00 !important;
    background: #fff !important;
  }
}

.container-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 22px;
}
</style>