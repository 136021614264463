<template>
  <div>
    <modal-base
      :width="'70%'"
      @closeModal="$emit('closeModal')"
      data-cy="modal-base-fechar_modal_editar_spread-pld-spread-components-modais"
    >
      <template #header> Editar Spread - {{ COMPETENCIA }} </template>
      <template #body>
        <div class="body-editar-spread" v-element-async-loader="loading">
          <div class="title-body-modal">
            Submercado: <b>{{ SPREAD_DETAILS.SIGLA || "--" }}</b> - Tipo de
            Energia:
            <b>{{ SPREAD_DETAILS.DESCRICAO || "--" }}</b>
          </div>
          <div class="body-fields-editar-proposta">
            <div class="column">
              <field-spread-compra
                @input="updateSpreadCompraValue"
                :modelProp="compra || 0"
              />
              <field-replicar-spread
                :idKey="0"
                ref="fieldReplicarSpreadCompra"
                @input="(value) => (replicarCompra = value)"
              />
              <field-preco-compra-pld-spread :modelProp="precoCompra" />
            </div>
            <div class="column">
              <field-spread-venda
                @input="updateSpreadVenda"
                :modelProp="venda || 0"
              />
              <field-replicar-spread
                :idKey="1"
                ref="fieldReplicarSpreadVenda"
                @input="(value) => (replicarVenda = value)"
              />
              <field-preco-venda-pld-spread :modelProp="precoVenda" />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="container-footer">
          <button
            @click="$emit('closeModal')"
            class="btn btn-secondary-outlined btn-cancel"
            data-cy="button-cancelar_modal_editar_spread-pld-spread-components-modais"
          >
            Cancelar
          </button>
          <button
            style="position: relative"
            :disabled="overlayLoading"
            @click="updateSpread"
            class="btn btn-primary-full-filled approve-btn update-spread-button"
            data-cy="button-salvar_aprovar_modal_editar_spread-pld-spread-components-modais"
          >
            <OverlayLoader v-if="overlayLoading" />
            <span v-if="$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR')">
              Salvar e Aprovar
            </span>
            <span v-else> Salvar </span>
          </button>
        </div>
      </template>
    </modal-base>
  </div>
</template>
<script>
import ModalBase from "@/components/modals/ModalBase.vue";
import OverlayLoader from "@/components/OverlayLoader.vue";
import { CONFIRMATION } from "@/constants/strings";
import FieldPrecoCompraPldSpread from "@/pages/pld-spread/components/fields/FieldPrecoCompraPldSpread.vue";
import FieldPrecoVendaPldSpread from "@/pages/pld-spread/components/fields/FieldPrecoVendaPldSpread.vue";
import FieldReplicarSpread from "@/pages/pld-spread/components/fields/FieldReplicarSpread.vue";
import FieldSpreadCompra from "@/pages/pld-spread/components/fields/FieldSpreadCompra.vue";
import FieldSpreadVenda from "@/pages/pld-spread/components/fields/FieldSpreadVenda.vue";
import PLDSpreadService from "@/services/PLDSpreadService";
import { getFaturamentoCompetencia } from "@/utils/functionsUtils";

export default {
  name: "ModalEditarSpread",
  props: {
    spread: Object,
    submercados: Array,
    submercado: Object,
  },
  data() {
    return {
      venda: 0,
      compra: 0,
      precoVenda: 0,
      precoCompra: 0,
      ultimoPld: null,
      loading: undefined,
      overlayLoading: false,
      replicarVenda: false,
      replicarCompra: false,
    };
  },
  mounted() {
    const { ultimoPld } = this.submercado;
    if (ultimoPld) {
      const precoAtual = ultimoPld.precoAtual || 0;
      this.ultimoPld = precoAtual;
      // Preço de compra e Venda já são sendo configurados aqui, assim se não houver um preço de compra/venda no último spread, ele já fica com um valor padrão
      this.precoCompra = precoAtual;
      this.precoVenda = precoAtual;
    }

    const { tipoEnergia } = this.spread;
    const { ultimoSpread } = tipoEnergia;
    if (!ultimoSpread) return;
    if (ultimoSpread.precoCompra) {
      this.precoCompra = ultimoSpread.precoCompra;
    }
    if (ultimoSpread.compra) {
      this.compra = ultimoSpread.compra || 0;
    }
    if (ultimoSpread.precoVenda) {
      this.precoVenda = ultimoSpread.precoVenda;
    }
    if (ultimoSpread.venda) {
      this.venda = ultimoSpread.venda || 0;
    }
  },
  computed: {
    COMPETENCIA() {
      const { month, year } = getFaturamentoCompetencia();
      return `${month}/${year}`;
    },
    SPREAD_DETAILS() {
      const { submercado, tipoEnergia } = this.spread;
      return {
        SIGLA: submercado.sigla,
        DESCRICAO: tipoEnergia.descricao,
      };
    },
  },
  methods: {
    updateSpreadCompraValue(value) {
      this.compra = value;
      this.precoCompra = value + this.ultimoPld;
    },
    updateSpreadVenda(value) {
      this.venda = value;
      this.precoVenda = value + this.ultimoPld;
    },
    mountUpdateSpreadRequest(
      spread,
      compra,
      venda,
      replicarVenda,
      replicarCompra,
      submercados
    ) {
      const { ultimoSpread } = spread.tipoEnergia;
      if (!compra && compra !== 0) {
        compra = !!ultimoSpread ? ultimoSpread.compra : 0;
      }
      if (!venda && venda !== 0) {
        venda = !!ultimoSpread ? ultimoSpread.venda : 0;
      }

      if (!replicarVenda && !replicarCompra) {
        const submercado = {
          id: spread.submercado.id,
        };
        const tipoEnergia = {
          id: spread.tipoEnergia.id,
        };
        return new Array({
          compra,
          venda,
          submercado,
          tipoEnergia,
        });
      }
      const arr = new Array();
      submercados.map((submercado) => {
        submercado.tiposEnergia.map((tipoEnergia) => {
          if (spread.tipoEnergia.id !== tipoEnergia.id) return;
          const { ultimoSpread } = tipoEnergia;
          arr.push({
            compra:
              replicarCompra && tipoEnergia.id === spread.tipoEnergia.id
                ? compra
                : !!ultimoSpread
                ? ultimoSpread.compra
                : 0,
            venda:
              replicarVenda && tipoEnergia.id === spread.tipoEnergia.id
                ? venda
                : !!ultimoSpread
                ? ultimoSpread.venda
                : 0,
            submercado: {
              id: submercado.id,
            },
            tipoEnergia: {
              id: tipoEnergia.id,
            },
          });
        });
      });
      return arr;
    },
    updateSpread() {
      this.overlayLoading = true;
      const request = this.mountUpdateSpreadRequest(
        this.spread,
        this.compra,
        this.venda,
        this.replicarVenda,
        this.replicarCompra,
        this.submercados
      );
      this.loading = PLDSpreadService.updateSpread(request)
        .then(() => {
          this.overlayLoading = false;
          this.mxToggleToast({
            message: CONFIRMATION.APROVAR_SPREAD.UPDATE,
          });
          this.$emit("reloadSubmercados");
        })
        .catch((error) => {
          this.overlayLoading = false;
          this.mxHandleRequestError(error);
        });
    },
  },
  components: {
    ModalBase,
    FieldSpreadVenda,
    FieldSpreadCompra,
    FieldReplicarSpread,
    FieldPrecoVendaPldSpread,
    FieldPrecoCompraPldSpread,
    OverlayLoader,
  },
};
</script>
<style lang="scss" scoped>
.body-editar-spread {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.title-body-modal {
  margin: 15px;
  font-size: 16px;
  padding: 7px 125px;
  width: fit-content;
  border-radius: 100px;
  border: 1px solid #c4c4c4;
}
.body-fields-editar-proposta {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.container-footer {
  width: 100%;
  display: flex;
  margin: 16px 30px;
  flex-direction: row;
  justify-content: flex-end;
}
.btn-cancel {
  font-weight: 700;
  padding: 8px 36px;
  border-radius: 9px;
  margin-right: 30px;
}
.approve-btn {
  font-weight: 700;
  padding: 8px 46px;
  border-radius: 9px;
}
</style>
