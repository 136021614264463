<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <td>Razão Social</td>
          <td>Apelido</td>
          <td>CNPJ</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ razaoSocial }}</td>
          <td>{{ apelido }}</td>
          <td>{{ cnpj }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    razaoSocial: {
      type: String,
    },
    apelido: {
      type: String,
    },
    cnpj: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
thead td {
  color: #544943;
  font-weight: 300;
  border-bottom: none;
  padding-bottom: 0;
}

table tr td {
  border-bottom: none;
}

table {
  width: 80%;
}
</style>
