<template>
  <div class="page-pld-spread">
    <modal-editar-spread
      :spread="spreadEditing"
      @closeModal="closeModal"
      :submercados="submercados"
      :submercado="modalEditarSpread"
      v-if="modalEditarSpread !== null"
      @reloadSubmercados="reloadSubmercados"
    />
    <modal-editar-pld
      @closeModal="closeModal"
      id="modal-editar-pld"
      v-if="showModalEditarPld"
      :submercado="submercadoEditing"
      @reloadSubmercados="reloadSubmercados"
    />
    <modal-aprovar-spread
      v-if="showModalAprovarSpread"
      @reloadSubmercados="reloadSubmercados"
      @closeModal="toggleModalAprovarSpread()"
      :submercados="SUBMERCADOS_SPREAD_SELECTED"
    />
    <page-panel>
      <template #header> PLD e Spread </template>
      <template #body>
        <div class="label-date">
          {{ COMPETENCIA || "Nenhuma competência adicionada" }}
        </div>
        <div class="container-body-head">
          <prazo-final-vigencia-spread
            @reload="reloadSubmercados"
            class="prazo-vigencia"
          />
          <div class="d-flex flex-column justify-content-between">
            <button
              class="btn btn-secondary-full-filled"
              @click="toggleModalAprovarSpread()"
              :disabled="
                !$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR') ||
                !SUBMERCADOS_SPREAD_SELECTED.length ||
                !SHOW_APROVAR_SPREADS_SELECIONADOS
              "
              data-cy="button-aprovar_spreads_selecionados-pld-spread-components"
            >
              Aprovar Spreads Selecionados
            </button>
            <label
              id="select-all-spreads-button"
              :class="{
                disabled:
                  !SHOW_APROVAR_SPREADS_SELECIONADOS ||
                  !$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR'),
              }"
              for="selectAllSpreads"
              class="btn select-all-spreads-btn"
            >
              Selecionar todos os Spreads
              <input
                type="checkbox"
                :disabled="
                  !$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR')
                "
                style="width: 30px"
                id="selectAllSpreads"
                v-model="allSpreadSelected"
                data-cy="input-selecionar_todos_os_spreads-pld-spread-components"
              />
            </label>
          </div>
        </div>
        <div class="page-pld-spread__body">
          <card-PLD-submercado
            :loading="loading"
            @editarPld="editarPld"
            :submercado="submercado"
            @lineClicked="lineClicked"
            :key="`submercado-card-${index}`"
            class="mt-4 card-pld-submercado-item"
            v-for="(submercado, index) in submercados"
          />
        </div>
      </template>
    </page-panel>
  </div>
</template>
<script>
import PagePanel from "@/components/panels/PagePanel.vue";
import Skeleton from "@/components/Skeleton.vue";
import STATUS_SPREAD from "@/enums/SpreadStatusEnum";
import CardPLDSubmercado from "@/pages/pld-spread/components/CardPLDSubmercado.vue";
import ModalAprovarSpread from "@/pages/pld-spread/components/modais/ModalAprovarSpread.vue";
import ModalEditarPld from "@/pages/pld-spread/components/modais/ModalEditarPld.vue";
import ModalEditarSpread from "@/pages/pld-spread/components/modais/ModalEditarSpread.vue";
import PrazoFinalVigenciaSpread from "@/pages/pld-spread/components/PrazoFinalVigenciaSpread.vue";
import PLDSpreadService from "@/services/PLDSpreadService";
import { getFaturamentoCompetencia } from "@/utils/functionsUtils";

export default {
  data() {
    return {
      loading: false,
      spreadEditing: null,
      PENDENTE: "Pendente",
      submercadoEditing: null,
      submercados: new Array(),
      allSpreadSelected: false,
      showModalEditarPld: false,
      modalEditarSpread: null,
      showModalAprovarSpread: false,
    };
  },
  mounted() {
    this.listSubmercados();
  },
  computed: {
    COMPETENCIA() {
      const { month, year } = getFaturamentoCompetencia();
      return `Competência ${month}/${year}`;
    },
    SHOW_APROVAR_SPREADS_SELECIONADOS() {
      return this.submercados.some((submercado) => {
        return submercado.tiposEnergia.some(
          (tipoEnergia) => tipoEnergia.status === this.PENDENTE
        );
      });
    },
    SUBMERCADOS_SPREAD_SELECTED() {
      return this.submercados.filter((submercado) => {
        return submercado.tiposEnergia.some(
          (tipoEnergia) =>
            tipoEnergia.selected &&
            tipoEnergia.status === STATUS_SPREAD.PENDENTE
        );
      });
    },
  },
  watch: {
    allSpreadSelected: {
      handler() {
        this.submercados.map((submercado) => {
          submercado.tiposEnergia.map((tipoEnergia) => {
            tipoEnergia.selected = this.allSpreadSelected;
          });
        });
      },
    },
  },
  methods: {
    listSubmercados() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        PLDSpreadService.listSubmercados()
          .then(({ data }) => {
            this.submercados = data;
            this.loading = false;
            resolve();
          })
          .catch((error) => {
            this.mxHandleRequestError(error);
            reject(error);
          });
      });
    },
    reloadSubmercados() {
      this.closeModal();
      this.listSubmercados();
      this.allSpreadSelected = false;
    },
    editarPld(submercado) {
      this.showModalEditarPld = true;
      this.submercadoEditing = submercado;
    },
    lineClicked(submercado, tipoEnergia) {
      this.spreadEditing = {
        submercado,
        tipoEnergia,
      };
      this.modalEditarSpread = submercado;
    },
    closeModal() {
      this.submercadoEditing = null;
      this.showModalEditarPld = false;
      this.modalEditarSpread = null;
      this.showModalAprovarSpread = false;
    },
    toggleModalAprovarSpread() {
      this.showModalAprovarSpread = !this.showModalAprovarSpread;
    },
  },
  components: {
    Skeleton,
    PagePanel,
    ModalEditarPld,
    CardPLDSubmercado,
    ModalEditarSpread,
    ModalAprovarSpread,
    PrazoFinalVigenciaSpread,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/_colors.scss";

.page-pld-spread {
  padding-top: 24px;
  &__body {
    padding: 30px;
  }
}

.label-date {
  width: 100%;
  font-size: 12px;
  color: #2a2a2a;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 1px;
  padding: 12px 0 0px 40px;
}

.container-body-head {
  display: flex;
  margin-right: 30px;
  justify-content: space-between;
}

.btn-approve {
  font-weight: 700;
  border-radius: 9px;
  padding-left: 23px;
  padding-right: 23px;
}

.prazo-vigencia {
  margin-top: 30px;
}

.select-all-spreads-btn {
  border-radius: 30px;
  font-weight: 700;
  color: #0a1633 !important;
  background: #fffaf8 !important;
  border: 2px solid $color--primary-font;
}

.btn-secondary-outlined {
  font-weight: 700;
  color: #0a1633 !important;
  background: #fffaf8 !important;
  border: 2px solid #0a1633 !important;
}
</style>
