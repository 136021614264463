import Vue from "vue";
import imageSpinner from "@/assets/icons/svg/spinner.svg";

/**
 * @description - show loader  element pendent promise
 * @tag - <div v-elementAsyncLoader="loading"></div>
 * @return - (evento,elemento)
 */
Vue.directive("elementAsyncLoader", {
  inserted(el, binding) {},
  update(el, binding) {
    if (binding.value === binding.oldValue) return;
    if (document.querySelector(".modalSpinner")) return;
    const modalSpinner = document.createElement("div");
    modalSpinner.setAttribute("class", "modalSpinner");
    const spinner = document.createElement("div");
    spinner.setAttribute("class", "spinner");
    const image = document.createElement("img");
    image.src = imageSpinner;
    spinner.appendChild(image);
    modalSpinner.appendChild(spinner);
    el.classList.add("_relative");
    el.appendChild(modalSpinner);
    const modal = document.querySelector(".modalSpinner");
    if (modal) {
      binding.value.catch(() => modal.remove());
      binding.value.then(() => modal.remove());
    }
  },
});
