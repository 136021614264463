<template>
  <div>
    <div @mouseleave="close" class="time-picker">
      <input
        id="time"
        :value="value"
        @blur="update"
        @click="options"
        v-mask="'##:##'"
        autocomplete="off"
        placeholder="00:00"
        :style="pickerStyle"
        :disabled="disabled"
        class="time-picker__input form-control"
        data-cy="input-time_picker-time-picker"
      />
      <transition name="slide">
        <div
          id="options"
          @click.alt="close"
          v-show="showOptions"
          class="time-picker__options"
          data-cy="div-mostrar_datas_option-time-picker"
        >
          <div
            @click="selectHour(hour)"
            class="time-picker__option"
            :key="`${indexHour}indexHour`"
            v-for="(hour, indexHour) in HOURS"
            :class="{ active: hour == value }"
            data-cy="div-selecionar_hora-time-picker"
          >
            {{ hour }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import AwesomeMask from "awesome-mask";
import { padLeft } from "@/utils/functionsUtils";
export default {
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pickerStyle: {
      type: Object,
    },
    startScore: {
      type: [Number, String],
      default: 0,
    },
    AuxiliaryIndexInMinutes: {
      type: [Number, String],
      default: 0,
    },
    intervalInMinutes: {
      type: [Number, String],
      default: 0,
    },
  },
  directives: {
    mask: [AwesomeMask],
  },
  computed: {
    HOURS() {
      const hoursList = new Array();
      let startScoreProp = this.startScore;
      let AuxiliaryIndexInMinutesProps = AuxiliaryIndexInMinutesProps;

      for (
        startScoreProp, AuxiliaryIndexInMinutesProps;
        startScoreProp < 24;

      ) {
        hoursList.push(
          `${padLeft(startScoreProp)}:${padLeft(AuxiliaryIndexInMinutesProps)}`
        );
        if (AuxiliaryIndexInMinutesProps == this.intervalInMinutes)
          startScoreProp++;
        AuxiliaryIndexInMinutesProps =
          AuxiliaryIndexInMinutesProps == this.intervalInMinutes
            ? 0
            : this.intervalInMinutes;
      }
      return hoursList;
    },
  },
  data() {
    return {
      showOptions: false,
    };
  },
  methods: {
    selectHour(hour) {
      this.$emit("input", hour);
      this.close();
    },
    options() {
      this.showOptions = !this.showOptions;
    },
    close() {
      this.showOptions = false;
    },
    update(event) {
      let time = event.target.value;
      if (!this.HOURS.includes(time)) {
        time = "00:00";
      }
      this.$emit("input", time);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.time-picker {
  width: 100%;
  font-size: 12px;
  position: relative;
  color: $color--primary-font;
  border-radius: 10px !important;

  input {
    outline: 0;
    width: 100%;
    border: none;
    border-radius: 5px;
    background-size: 15px;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: 95% center;
    border-radius: 10px !important;
    border: 1px solid #b4b7ba !important;
    background-image: url("../../assets/icons/svg/clock-grey.svg");
  }

  .time-picker__options {
    top: 25px;
    left: 0px;
    width: 50%;
    z-index: 100;
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    border: 1px solid #eee;
    background-color: #f8f8f8;
  }

  .time-picker__option {
    width: 90%;
    text-align: center;
    margin: 6px;
    cursor: pointer;
    &:hover {
      color: $color--primary;
    }
  }
}

.active {
  color: $color--primary;
}

.slide-enter-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.time-picker__input {
  &:disabled {
    cursor: not-allowed;
  }
}
</style>

<!-- 
<style lang="scss" scoped>
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.time-picker {
  width: 100px;
  font-size: 12px;
  position: relative;
  padding-left: 10px;
  color: $color--primary-font;

  input {
    outline: 0;
    width: 100%;
    border: none;
    height: 25px;
    border-radius: 5px;
    background-size: 15px;
    background-color: #f8f8f8;
    background-repeat: no-repeat;
    background-position: 95% center;
    background-image: url("../../assets/icons/svg/clock-grey.svg");
  }

  .time-picker__options {
    top: 25px;
    left: 0px;
    width: 99px;
    z-index: 100;
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    border: 1px solid #eee;
    background-color: #f8f8f8;
  }

  .time-picker__option {
    width: 90%;
    text-align: center;
    margin: 6px;
    cursor: pointer;
    &:hover {
      color: $color--primary;
    }
  }
}

.active {
  color: $color--primary;
}

.slide-enter-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.time-picker__input {
  &:disabled {
    cursor: not-allowed;
  }
}
</style> -->