<template>
  <div
    class="_uploader"
    @click="openFile"
    @dragover.prevent
    @drop.prevent="dropFile"
    data-cy="div-abrir_arquivo_upload-modais"
  >
    <div class="up-icon">
      <span class="icon-exportador" />
    </div>
    <div class="container-uploader">
      <span>
        Envie arquivos arrastando e soltando ou selecionando<br />Apenas
        arquivos .csv
      </span>
      <input
        type="file"
        id="fileInput"
        multiple="multiple"
        @change="handleFile"
        style="opacity: 0; height: 0; width: 0"
        data-cy="input-receber_arquivo_upload-modais"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openFile() {
      document.getElementById("fileInput").click();
    },
    dropFile(e) {
      const files = e.dataTransfer.files;

      this.$emit("changeFiles", files);
    },
    handleFile(e) {
      const files = e.target.files;

      this.$emit("changeFiles", files);

      e.target.value = "";
    },
  },
};
</script>

<style lang="scss" scoped>
._uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  border: dashed 2px #2a2a2a;
  color: #2a2a2a;
  border-radius: 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
}
.up-icon {
  font-size: 3rem;
  margin-bottom: 2rem;
}
.container-uploader {
  text-align: center;
}
</style>
