import Services from "@/services/Services";

class PldService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  createPLD(request) {
    return this.post(request);
  }
}

export default new PldService("/pld");
