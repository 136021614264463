import Vue from 'vue';

/** 
* @description - Chama uma função ao detectar resize da janela
* @tag - <div v-resize=""></div>
* @return - (evento,elemento)
*/ 
Vue.directive('resize', {
    inserted: function (el, binding) {
      let f = function (evt) {
        if (binding.value(evt, el)) {
          window.removeEventListener('resize', f)
        }
      }
      window.addEventListener('resize', f)
    }
  }) 