<template>
  <div class="table-card subtitled-only" v-if="config">
    <div class="table-title">Fatura no Mercado Livre</div>
    <div class="table-subtitle">
      A. Custos com uso do Sistema da Distribuidora ({{ distribuidora }})
      <!--unidade consumidora-->
    </div>
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>Dado</th>
          <th>Unidade</th>
          <th>Medição Simulada</th>
          <th>Medição Apurada</th>
          <th>Tarifa s/ Impostos - Sim.</th>
          <th>Tarifa s/ Impostos - Apurada</th>
          <th>Ajuste</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Consumo Ponta</td>
          <td>MWh</td>
          <td>{{ config.consumoPontaMl | truncateRound(4, 3) }}</td>
          <td>{{ config.cep | truncateRound(4, 3) }}</td>
          <td>{{ config.tarConsumoPontaSmml | truncateRound(3, 2) | R$ }}</td>
          <td>{{ config.tarConsumoPontaApml | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.ajConsumoPontaMl | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Consumo Fora Ponta</td>
          <td>MWh</td>
          <td>{{ config.consumoForaPontaMl | truncateRound(4, 3) }}</td>
          <td>{{ config.consumoForaPontaAp | truncateRound(4, 3) }}</td>
          <td>
            {{ config.tarConsumoForaPontaSmml | truncateRound(3, 2) | R$ }}
          </td>
          <td>
            {{ config.tarConsumoForaPontaApml | truncateRound(3, 2) | R$ }}
          </td>
          <td class="table-bold">
            {{ config.ajConsumoForaPontaMl | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Demanda - Ponta</td>
          <td>kW</td>
          <td>{{ config.demandaPontaSm | truncateRound(4, 3) }}</td>
          <td>{{ config.demandaPontaAp | truncateRound(4, 3) }}</td>
          <td>{{ config.tarDemandaPontaSmml | truncateRound(3, 2) | R$ }}</td>
          <td>{{ config.tarDemandaPontaApml | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.ajDemandaPontaMl | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Demanda - Fora Ponta</td>
          <td>kW</td>
          <td>{{ config.demandaForaPontaSm | truncateRound(4, 3) }}</td>
          <td>{{ config.demandaForaPontaAp | truncateRound(4, 3) }}</td>
          <td>
            {{ config.tarDemandaForaPontaSmml | truncateRound(3, 2) | R$ }}
          </td>
          <td>
            {{ config.tarDemandaForaPontaApml | truncateRound(3, 2) | R$ }}
          </td>
          <td class="table-bold">
            {{ config.ajDemandaForaPontaMl | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Demanda não utilizada - Ponta</td>
          <td>kW</td>
          <td>0,000</td>
          <td>{{ config.demandaNUtilPontaAp | truncateRound(4, 3) }}</td>
          <td>
            {{ config.tarDemandaNUtilPontaSmml | truncateRound(3, 2) | R$ }}
          </td>
          <td>
            {{ config.tarDemandaNUtilPontaApml | truncateRound(3, 2) | R$ }}
          </td>
          <td class="table-bold">
            {{ config.ajDemandaNUtilPontaMl | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Demanda não utilizada - Fora Ponta</td>
          <td>kW</td>
          <td>0,000</td>
          <td>{{ config.demandaNUtilForaPontaAp | truncateRound(4, 3) }}</td>
          <td>
            {{ config.tarDemandaNUtilForaPontaSmml | truncateRound(3, 2) | R$ }}
          </td>
          <td>
            {{ config.tarDemandaNUtilForaPontaApml | truncateRound(3, 2) | R$ }}
          </td>
          <td class="table-bold">
            {{ config.ajDemandaNUtilForaPontaMl | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Subvenção Tarifária</td>
          <td>R$</td>
          <td>{{ config.chargeGrantSimulationValue | truncateRound(4, 3) }}</td>
          <td>{{ config.subvencaoTarifariaAp | truncateRound(4, 3) }}</td>
          <td>--</td>
          <td>--</td>
          <td class="table-bold">
            {{ config.ajSubvencaoTarifa | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>CDE - COVID</td>
          <td>R$</td>
          <td>{{ config.cdeCovidSm | truncateRound(4, 3) }}</td>
          <td>{{ config.cdeCovidAp | truncateRound(4, 3) }}</td>
          <td>{{ config.tarCdeCovidSm | truncateRound(3, 2) | R$ }}</td>
          <td>{{ config.tarCdeCovidAp | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.ajCdeCovid | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Ajuste TUSD</td>
          <td>R$</td>
          <td>--</td>
          <td>{{ config.ajTusdsap | truncateRound(4, 3) }}</td>
          <td>--</td>
          <td>--</td>
          <td class="table-bold">
            {{ config.ajTusd | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Impostos</td>
          <td>R$</td>
          <td>{{ config.impostosAclSm | truncateRound(4, 3) }}</td>
          <td>{{ config.impostosAclAp | truncateRound(4, 3) }}</td>
          <td>--</td>
          <td>--</td>
          <td class="table-bold">
            {{ config.ajImpostosMl | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr class="table-bold white-row">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>Total</td>
          <td class="table-total">
            {{ config.ajTotAcl | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["config", "distribuidora"],
};
</script>
<style lang="scss" scoped>
.table-card {
  margin-top: 25px;
  border-radius: 0.3em;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  box-shadow: 2px 2px 5px 0 #2a2a2a22;
}
.table-title {
  flex: 1;
  display: flex;
  padding: 16px 24px;
  font-weight: 700;
  color: #ffffff;
  font-size: 1.5rem;
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 0.3em 0.3em 0 0;
}
.table-subtitle {
  padding: 0.4em;
  background-color: #cecece;
  font-size: 0.9rem;
  font-weight: 700;
  border-top: solid 1px #f86511;
}
table {
  border-collapse: collapse;
  margin: 0;
  font-size: 0.9rem;
  border-radius: 0 0 0.3em 0.3em;

  thead {
    background-color: #e8e8e8;
  }

  tbody {
    tr {
      width: 100%;
      &:nth-child(even) {
        background-color: #f2edeb;
      }
      &:last-child {
        border-radius: 0 0 0.3em 0.3em;
        :first-child {
          border-radius: 0 0 0 0.3em;
        }
      }
    }
  }

  .white-row {
    background-color: #ffffff !important;
  }

  .table-bold {
    font-weight: 700;
  }

  .table-total {
    background-color: #fff1ec;
    border-top: solid 1px #f86511;
    border-radius: 0 0 0.3em 0;
  }
}
</style>
