import alcadaService from "@/services/AlcadasAprovacaoService";

const alcadaSpread = {
  state: {
    perfilAprovador: {
      nome: null,
      simbolo: null,
    },
    alcadas: null,
  },
  getters: {
    getAlcadasSpreadRecompra: (state) => state.alcadas,
    getPerfilAprovadorSpreadRecompra: (state) => state.perfilAprovador,
  },
  mutations: {
    setAlcadas(state, value) {
      state.alcadas = value;
    },
    setPerfilAprovador(state, value) {
      state.perfilAprovador = value;
    },
  },
  actions: {
    ActionLoadAcadasSpreadRecompra(context) {
      alcadaService
        .getAlcadasAprovacaoSpread()
        .then((response) => {
          context.commit("setAlcadas", response.data);
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    ActionCheckAprovadorSpreadRecompra(context, value) {
      if (value === 0 || null || undefined) {
        context.commit("setPerfilAprovador", { nome: null, simbolo: null });
      } else {
        const alcadas = context.state["alcadas"];
        let find = false;
        var simbolos = {
          POS_VENDA: "PV",
          APROVADOR_NIVEL_1: "N1",
          APROVADOR_NIVEL_2: "N2",
          APROVADOR_NIVEL_3: "N3",
        };

        alcadas.forEach((alcada) => {
          if (value > alcada.valorFaixaMin && value <= alcada.valorFaixaMax) {
            context.commit("setPerfilAprovador", {
              nome: alcada.descricaoPerfilAprovador,
              simbolo: simbolos[alcada.descricaoPerfilAprovador],
            });
            find = true;
          }
          if (
            alcada.descricaoPerfilAprovador === "APROVADOR_NIVEL_3" &&
            value > alcada.valorFaixaMax
          ) {
            context.commit("setPerfilAprovador", {
              nome: alcada.descricaoPerfilAprovador,
              simbolo: simbolos[alcada.descricaoPerfilAprovador],
            });
            find = true;
          }
        });
        if (!find) {
          context.commit("setPerfilAprovador", { nome: null, simbolo: null });
        }
      }
    },
  },
};

export default alcadaSpread;
