import Vue from 'vue'

/** 
* @description - Chama uma função ao detectar scroll
* @tag - <div v-scroll=""></div>
* @return - (evento,elemento)
*/ 
Vue.directive('scroll', {
    inserted: function (el, binding) {
      let f = function (evt) {
        if (binding.value(evt, el)) {
          window.removeEventListener('scroll', f)
        }
      }
      window.addEventListener('scroll', f)
    }
  })