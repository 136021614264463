import AlcadasAprovacaoModel from "@/models/AlcadasAprovacaoModel";
import Services from "./Services";

class AlcadasAprovacaoService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  getAlcadasAprovacao(request) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request);
        if (response.status !== 204) {
          response.data = response.data.map(
            (alcada) => new AlcadasAprovacaoModel(alcada)
          );
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  updateAlcadasAprovacao(request) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.patch(request);
        let { data } = response;
        resolve(data);
      } catch {
        reject(error);
      }
    });
  }

  getAlcadasAprovacaoSpread() {
    this._url = "/solicitado-spread-recompra";
    return this.get();
  }
}

export default new AlcadasAprovacaoService("/alcadas-aprovacao");
