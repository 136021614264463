<template>
  <div class="container-field">
    <label for="ipt-pld-novo">Novo PLD</label>
    <vue-numeric
      :min="0"
      :precision="2"
      id="ipt-pld-novo"
      :currency="'R$'"
      v-model="content"
      placeholder="R$ 00,00"
      :disabled="!isDisabled"
      :decimal-separator="','"
      :thousand-separator="'.'"
      class="form-control ipt-pld-novo"
      data-cy="input-pld_novo_field-pld-spread-components-fields"
    />
  </div>
</template>
<script>
import VueNumeric from "vue-numeric";
export default {
  name: "FieldPldNovo",
  props: {
    isDisabled: Boolean,
  },
  watch: {
    content: {
      handler() {
        this.$emit("input", this.content);
      },
    },
  },
  data() {
    return {
      content: 0,
    };
  },
  components: {
    VueNumeric,
  },
};
</script>
<style lang="scss" scoped>
label {
  font-size: 16px;
  font-weight: 300;
  color: $color--primary-font;
}

.container-field {
  margin: 20px;
}

.ipt-pld-novo {
  width: 200px;
  height: 57.64px;
  font-size: 21px;
  font-weight: 300;
  margin-top: 8.36px;
  border-radius: 12px;
  color: $color--primary-font;
  padding: 15px 8px 15px 16px;
}

.ipt-pld-novo:disabled {
  color: rgba($color: $color--primary-font, $alpha: 0.5);
}
</style>