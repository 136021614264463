<template>
  <div>
    <modal-base
      width="75%"
      maxHeight="90VH"
      @closeModal="closeModal()"
      data-cy="modal-fechar_modal_faturamento_contrato_wbc-pages-curto-prazo-components-modals"
    >
      <template #header> Faturamento do Contrato no WBC </template>
      <template #body>
        <div v-elementAsyncLoader="loading">
          <p v-if="rowSelected.status === 'Boletada'" class="title-body">
            Proposta Boletada por:
            <b>{{ `${contratoSelecionado.nomeAprovador} (${contratoSelecionado.emailAprovador}) ` | EMPTY }}</b>
          </p>
          <table-informacoes-proposta-curto-prazo
            :contratoSelecionado="contratoSelecionado"
          />
          <table-empresa :contratoSelecionado="contratoSelecionado" />
          <table-informacoes-proposta-faturada-manualmente-wbc
            :contratoSelecionado="contratoSelecionado"
          />
          <div v-if="rowSelected.status === 'Aprovada'" class="container-field">
            <input
              type="checkbox"
              v-model="faturadoManualmenteWbc"
              data-cy="input-proposta_faturada_manualemnte_wbc-pages-curto-prazo-components-fields"
            />
            <label
              >Esta proposta já foi faturada manualmente no <b>WBC.</b></label
            >
          </div>
        </div>
      </template>
      <template #footer>
        <div
          v-if="rowSelected.status === 'Aprovada'"
          v-elementAsyncLoader="loading"
          class="container-btn"
        >
          <button
            @click="closeModal"
            class="btn btn-secondary-outlined me-2 buttons"
            data-cy="button-cancelar_faturado_wbc-pages-curto-prazo-components-modals"
          >
            Cancelar
          </button>
          <button
            v-if="$can('ACOMPANHAR_CURTO_PRAZO_VISUALIZAR_SINALIZAR')"
            @click="boletarContrato"
            :disabled="!faturadoManualmenteWbc || overlayLoading"
            class="btn btn-primary-full-filled buttons"
            data-cy="button-salvar_faturado_wbc-pages-curto-prazo-components-modals"
          >
            Salvar
          </button>
        </div>
      </template>
    </modal-base>
  </div>
</template>
<script>
import { EMPTY } from "@/filters/formatters";
import { CONFIRMATION } from "@/constants/strings";
import TableEmpresa from "../tables/TableEmpresa.vue";
import DataTable from "@/components/tables/DataTable.vue";
import ModalBase from "@/components/modals/ModalBase.vue";
import PropostaCurtoPrazoService from "@/services/PropostaCurtoPrazoService";
import TableCurtoPrazoModel from "@/models/tablesModels/TableCurtoPrazoModel";
import TableInformacoesPropostaCurtoPrazo from "../tables/TableInformacoesPropostaCurtoPrazo.vue";
import TableInformacoesPropostaFaturadaManualmenteWbc from "../tables/TableInformacoesPropostaFaturadaManualmenteWbc.vue";
export default {
  props: {
    rowSelected: Object,
  },
  data() {
    return {
      loading: undefined,
      overlayLoading:false,
      faturadoManualmenteWbc: false,
      contratoSelecionado: new Object(),
      tableModel: new TableCurtoPrazoModel(),
    };
  },
  mounted() {
    this.listContratoSelecionado();
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    listContratoSelecionado() {
      this.loading = PropostaCurtoPrazoService.buscarPropostaCurtoPrazo({
        idContrato: this.rowSelected.id,
      })
        .then(({ data }) => {
          this.contratoSelecionado = data;
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "MODAL_FATURADO_MANUALMENTE_WBC");
        });
    },
    boletarContrato() {
      this.overlayLoading = true
      this.loading = PropostaCurtoPrazoService.fecharFaturamentoContratoWBC({
        curtoPrazoId: this.rowSelected.id,
      })
        .then(() => {
           this.overlayLoading = false
          this.mxToggleToast({
            message: CONFIRMATION.MODAL_FATURADO_MANUALMENTE_WBC.UPDATE,
          });
          this.$emit("closeModal");
          this.$emit("listaCurtoPrazo");
        })
        .catch((error) => {
          this.overlayLoading = false
          this.mxHandleRequestError(error, "MODAL_FATURADO_MANUALMENTE_WBC");
        });
    },
  },
  filters: {
    EMPTY,
  },
  components: {
    DataTable,
    ModalBase,
    TableEmpresa,
    TableInformacoesPropostaCurtoPrazo,
    TableInformacoesPropostaFaturadaManualmenteWbc,
  },
};
</script>
<style lang="scss" scoped>
.title-body {
  padding: 30px 0px 0px 36px;
}
.container-btn {
  width: 100%;
  display: flex;
  margin: 16px 30px;
  flex-direction: row;
  justify-content: flex-end;

  button {
    border-radius: 9px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.buttons {
  width: 10%;
  height: 34px;
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 1px;
  font-family: "Montserrat";
}

.container-field {
  display: flex;
  align-items: center;
  margin-bottom: 33px;
  justify-content: center;
}

label {
  margin-left: 16px;
}

input {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 4px;
}
</style>