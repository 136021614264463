<template>
  <div>
    <modal-base
      width="688px"
      @closeModal="closeModal()"
      data-cy="modal-fechar_modal_cadastro_pendente_sem_ponto-faturamento-components-modais"
    >
      <template #header> Ponto de Medição Desabilitado </template>
      <template #body>
        <div class="body-container">
          <p>
            Não há pontos de medição habilitados para o contrato
            <b>{{ getContrato }}</b>. Deseja habilitar?</p>
        </div>
      </template>
      <template #footer>
        <div class="container-footer">
          <button
            @click="closeModal()"
            class="btn btn-primary-outlined btn-common btn-margin"
            data-cy="button-cancelar_pendente_sem_ponto-faturamento-components-modais"
          >
            Cancelar
          </button>
          <button
            @click="associar()"
            class="btn btn-primary-full-filled btn-common"
            data-cy="button-associar_contrato_pendente_sem_ponto-faturamento-components-modais"
          >
            Habilitar Ponto de Medição
          </button>
        </div>
      </template>
    </modal-base>
  </div>
</template>
<script>
import ModalBase from "@/components/modals/ModalBase.vue";
export default {
  props: {
    modelProp: Object,
  },
  computed: {
    getContrato() {
      return this.modelProp.codigo;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    associar() {
      this.$emit("associarPonto");
    },
  },
  components: {
    ModalBase,
  },
};
</script>
<style lang="scss" scoped>
.body-container {
  text-align: center;
  margin: 34px 37px;
}

.body-container p {
  color: #2a2a2a;
  font-size: 16px;
}

.container-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin: 16px 30px;
}

.btn-common {
  padding: 8px 16px;
  font-weight: 700;
  border-radius: 9px;
}

.btn-margin {
  margin-right: 20px;
}

.modal-width {
  width: 688px;
}
</style>
