<template>
  <div>
    <div :style="mxIsChromeBrowser() ? 'height: 117.6vh' : 'height: 100vh'">
      <div class="__header">
        <div class="header__box-logo">
          <img src="@/assets/logo/orange.svg" />
        </div>
        <div class="text__header">Portal Matrix</div>
      </div>

      <div
        class="box-expirado"
        :style="mxIsChromeBrowser() && 'height: calc(117.6vh - 60px)'"
      >
        <div
          class="text-expirado"
          v-if="demonstrativo.situacaoDemonstrativo.situacao === 'APROVADO'"
        >
          O demonstrativo de faturamento já foi finalizado e aprovado, para
          realizar o download do PDF de versão final, clique no botão abaixo.
        </div>

        <div class="text-expirado" v-if="demonstrativo.spreadExpirado">
          O demonstrativo está expirado. Favor entrar em contato com seu officer
          pós-vendas
        </div>
        <div
          class="text-expirado"
          v-else-if="
            demonstrativo.situacaoDemonstrativo.situacao !== 'APROVADO' &&
            demonstrativo.situacaoDemonstrativo.situacao !== 'REPROVADO'
          "
        >
          Favor entrar em contato com seu officer pós-vendas
        </div>

        <div class="box-pdf">
          <div
            v-if="
              demonstrativo.ativo &&
              demonstrativo.situacaoDemonstrativo.situacao === 'APROVADO'
            "
            style="font-size: 16px"
            @click="downloadPdf"
            class="btn-pdf"
          >
            <span class="icon-pdf">
              <img :src="mountImg('/images/pdf.svg')" />
            </span>
            Visualizar Demonstrativo de faturamento em PDF
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    demonstrativo: Object,
    contrato: Object,
  },
  methods: {
    downloadPdf() {
      let url =
        process.env.VUE_APP_ROOT_BILLING +
        `/demonstrativo/${this.contrato.id}/download`;
      window.open(url, "_system");
    },
  },
  computed: {
    
  }
};
</script>

<style lang="scss">
.text-expirado {
  max-width: 800px;
  padding: 0 30px;
  font-size: 1.5rem;
}
.__header {
  height: 60px;
  width: 100%;
  display: flex;
  background: #505050;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #ffffff;

  img {
    width: 45px;
  }
}

.header__box-logo {
  padding: 0 15px 0 15px;
}
.box-expirado {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #f86511;
  margin-bottom: 20px;
}
.box-pdf {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-pdf {
  background: #f86511;
  color: #fff;
  border-radius: 4px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px 70px 5px 70px;
  margin-bottom: 60px;
  cursor: pointer;
}

.icon-pdf {
  margin: -5px 15px 0 0px;
}
</style>
