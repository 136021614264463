<template>
  <div class="page-pos-venda">
    <modal-associar-pos-venda
      :tableData="rowSelected"
      @closeModal="closeModal"
      v-if="showModalAssociar"
      @getEmpresasFull="getEmpresasFull"
    />
    <page-painel>
      <template #header> Associar Pós-Venda </template>
      <template #body>
        <div class="page-pos-venda__body">
          <div class="labelTitle">Selecione a empresa</div>
          <field-search
            class="fieldSearch"
            @getSearchValue="getSearchValue"
            :placeholder="'Busque por razão social, apelido, CNPJ, contrato ou pós venda'"
          />
          <div v-elementAsyncLoader="tableLoading">
            <data-table
              :stripes="true"
              :data="tableData"
              @setPage="setPage"
              :pagination="true"
              :lineClickable="true"
              @setPerPage="setPerPage"
              @lineClicked="lineClicked"
              :paginationData="paginationData"
              :fields="tableModel.getTableFields()"
            ></data-table>
          </div>
        </div>
      </template>
    </page-painel>
  </div>
</template>
<script>
import { isCNPJ } from "@/filters/assertions";
import EmpresaService from "@/services/EmpresaService";
import DataTable from "@/components/tables/DataTable.vue";
import PagePainel from "@/components/panels/PagePanel.vue";
import FieldSearch from "@/components/fields/FieldSearch.vue";
import ModalAssociarPosVenda from "./modais/ModalAssociarPosVenda.vue";
import TablePosVendaModel from "@/models/tablesModels/TablePosVendaModel";
export default {
  data() {
    return {
      tableData: new Array(),
      tableLoading: undefined,
      showModalAssociar: false,
      paginationData: undefined,
      rowSelected: new Object(),
      tableModel: new TablePosVendaModel(),
      filters: {
        page: 0,
        size: 10,
        desc: true,
        filtro: null,
      },
    };
  },
  mounted() {
    this.getEmpresasFull();
  },
  methods: {
    setPage(page) {
      this.filters.page = page;
      this.getEmpresasFull();
    },
    setPerPage(size) {
      this.filters.size = size;
      this.getEmpresasFull();
    },
    getEmpresasFull(resetPage = false) {
      if (resetPage) {
        this.filters.page = 0;
      }
      this.tableLoading = EmpresaService.getEmpresas({
        ...this.filters,
      })
        .then(({ data, pagination }) => {
          this.tableData = data || new Array();
          this.paginationData = pagination;
        })
        .catch((error) => this.mxHandleRequestError(error, "EMPRESA"));
    },
    searchingByCNPJ(value) {
      if (!value) return false;
      return isCNPJ(value);
    },
    getSearchValue(value) {
      let valueFormatted = value;
      if (this.searchingByCNPJ(value)) {
        valueFormatted = value.match(/\d/g).join("");
      }
      this.filters.filtro = valueFormatted;
      this.getEmpresasFull(true);
      return valueFormatted;
    },
    lineClicked(row) {
      row.selecionar = false;
      this.rowSelected = row;
      this.showModalAssociar = true;
    },
    closeModal() {
      this.showModalAssociar = false;
    },
  },
  components: {
    DataTable,
    PagePainel,
    FieldSearch,
    ModalAssociarPosVenda,
  },
};
</script>
<style lang="scss" scoped>
.page-pos-venda {
  padding-top: 24px;
  &__body {
    padding: 15px 40px;
  }
  .labelTitle {
    font-size: 16px;
    font-weight: 600;
  }
}

.fieldSearch {
  margin-top: 30px;
  margin-bottom: 20px;
}
</style>
