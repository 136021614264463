<template>
  <div
    class="page-template-default"
    :style="mxIsChromeBrowser() && 'height: 117.6vh'"
  >
    <div class="page-template-default__top-bar"><slot name="topbar" /></div>
    <scroll :ops="scrollConfig">
      <div class="page-template-default__content">
        <slot name="content" />
      </div>
    </scroll>
  </div>
</template>

<script>
import Topbar from "@/components/Topbar/Topbar.vue";

export default {
  components: {
    Topbar,
  },
  data() {
    return {
      scrollConfig: {
        rail: { opacity: 0 },
        bar: { keepShow: false },
        scrollPanel: { scrollingX: false },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.page-template-default {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  &__top-bar {
    width: 100%;
    max-height: 75px;
    min-height: 75px;
    border-bottom: solid 1px #ec6623;
    background: #fffaf8;
    transition: width 0.3s;
    @include ml-hd {
      max-height: responsive(75px);
      min-height: responsive(75px);
    }
  }
  &__content {
    width: 100%;
    flex: 1;
    background: #eee;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 24px;
    margin-bottom: 50px;
  }
}
</style>
