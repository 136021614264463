<template>
  <div v-elementAsyncLoader="loading">
    <div class="row mt-4">
      <div class="col-lg-12">
        <card-base :widthCard="98">
          <template #header>
            <p class="title-card">Reembolso TUSD (TRU)</p>
          </template>
          <template #body>
            <p class="text-card">
              Defina o texto que será apresentado ao cliente no campo acima
              quando receber uma proposta de curto prazo enviada pelo hotsite.
            </p>
            <div
              v-for="(tusd, indexTusd) in listTusdFor"
              :key="`tusd_${indexTusd}`"
            >
              <field-energia-tusd
                :tusd="tusd"
                ref="descricao"
                :tusdIndex="indexTusd"
                :tusdCurtoPrazo="tusd.curtoPrazoTusd"
                :disabled="!$can('CURTO_PRAZO_VISUALIZAR_EDITAR')"
              />
            </div>
          </template>
        </card-base>
      </div>
    </div>
    <div class="button-bar-parametrizacao">
      <div class="p-2">
        <button
          :disabled="!$can('CURTO_PRAZO_VISUALIZAR_EDITAR')"
          @click="getBackToParametrizacaoHome()"
          class="btn btn-secondary-full-filled buttons"
          data-cy="button-cancelar_alteracoes-parametrizacao"
        >
          Cancelar Alterações
        </button>
      </div>
      <div class="p-2">
        <button
          :disabled="!$can('CURTO_PRAZO_VISUALIZAR_EDITAR')"
          @click="editTusd"
          class="btn btn-primary-full-filled buttons"
          data-cy="button-salvar_alteracoes-parametrizacao"
        >
          Salvar Alterações
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TusdModel from "@/models/TusdModel";
import { CONFIRMATION } from "@/constants/strings";
import CardBase from "@/components/cards/cardBase.vue";
import FieldEnergiaIE0 from "./fields/FieldEnergiaIE0.vue";
import CurtoPrazoService from "@/services/CurtoPrazoService";
import FieldEnergiaIE50 from "./fields/FieldEnergiaIE50.vue";
import FieldEnergiaTusd from "./fields/FieldEnergiaTusd.vue";
import FieldEnergiaIE100 from "./fields/FieldEnergiaIE100.vue";
import FieldEnergiaConvencional from "./fields/FieldEnergiaConvencional.vue";
import FieldEnergiaCogeracaoQualificada from "./fields/FieldEnergiaCogeracaoQualificada.vue";
export default {
  data() {
    return {
      loading: undefined,
      model: new TusdModel(),
      listTusdFor: new Array(),
    };
  },
  mounted() {
    this.listTusd();
  },
  methods: {
    getBackToParametrizacaoHome() {
      this.$router.push({ name: "Parâmetros" });
    },
    listTusd() {
      this.loading = CurtoPrazoService.getTusd()
        .then(({ data }) => {
          this.listTusdFor = data;
          this.model = new TusdModel(data);
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "TUSD");
        });
    },
    editTusd() {
      const listMapped = this.listTusdFor.map((mapped) => ({
        descricao: mapped.curtoPrazoTusd.descricao,
        tipoEnergia: {
          id: mapped.id,
        },
      }));
      this.loading = CurtoPrazoService.postTusd(listMapped)
        .then(() => {
          this.mxToggleToast({
            message: CONFIRMATION.TUSD.UPDATE,
          });
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "CURTO_PRAZO");
        });
    },
  },
  watch: {
    listTusdFor: {
      deep: true,
      immediate: true,
      handler() {},
    },
  },
  components: {
    CardBase,
    FieldEnergiaIE0,
    FieldEnergiaIE50,
    FieldEnergiaTusd,
    FieldEnergiaIE100,
    FieldEnergiaConvencional,
    FieldEnergiaCogeracaoQualificada,
  },
};
</script>

<style lang="scss" scoped>
.btn {
  padding: 5px 35px !important;
}

.button-bar-parametrizacao {
  display: flex;
  justify-content: flex-end;
}
</style>