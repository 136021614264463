export default class PontoMedicao {
  constructor(
    {
      id,
      codigo,
      proinfas,
      principal,
      contratos,
      codigoRetaguarda,
      proinfaConfigurado,
    } = new Object()
  ) {
    this._id = id;
    this._codigo = codigo;
    this._proinfas = proinfas;
    this._principal = principal;
    this._contratos = contratos;
    this._retaguarda = codigoRetaguarda;
    this._proinfaConfigurado = proinfaConfigurado;
  }

  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get proinfaConfigurado() {
    return this._proinfaConfigurado;
  }

  set proinfaConfigurado(proinfaConfigurado) {
    this._proinfaConfigurado = proinfaConfigurado;
  }

  get codigo() {
    return this._codigo ?? null;
  }

  set codigo(codigo = null) {
    this._codigo = codigo;
  }

  get principal() {
    return this._principal;
  }

  set principal(principal) {
    this._principal = principal;
  }

  get retaguarda() {
    return this._retaguarda;
  }

  set retaguarda(retaguarda) {
    this._retaguarda = retaguarda;
  }

  get contratos() {
    return this._contratos;
  }

  set contratos(contratos) {
    this._contratos = contratos;
  }

  get proinfas() {
    return this._proinfas || new Array();
  }

  set proinfas(proinfas) {
    this._proinfas = proinfas;
  }

  addProinfas(proinfas) {
    if (Array.isArray(proinfas)) this._proinfas.push(...proinfas);
    else this._proinfas.push(proinfas);
  }

  getData() {
    const {
      id,
      proinfas,
      principal,
      contratos,
      retaguarda,
      proinfaConfigurado,
    } = this;

    const proinfasArrayLike = new Array();
    proinfas.map((proinfa) =>
      Object.keys(proinfa).map(
        (key) => key !== "ano" && proinfasArrayLike.push(proinfa[key])
      )
    );

    return {
      id,
      principal,
      contratos,
      retaguarda,
      proinfaConfigurado,
      proinfas: {
        listProinfas: proinfasArrayLike,
      },
    };
  }

  static getContratoModalTableFields() {
    return new Array(
      { name: "codigo", title: "Contrato" },
      { name: "codigoContratoReferencia", title: "Referência" },
      { name: "cpnj", title: "CNPJ" },
      { name: "apelido", title: "Apelido" },
      { name: "razaoSocial", title: "Razão Social" }
    );
  }

  static getTableFields() {
    return new Array(
      {
        name: "jan",
        title: "JAN",
        month: "01",
      },
      {
        name: "fev",
        title: "FEV",
        month: "02",
      },
      {
        name: "mar",
        title: "MAR",
        month: "03",
      },
      {
        name: "abr",
        title: "ABR",
        month: "04",
      },
      {
        name: "mai",
        title: "MAI",
        month: "05",
      },
      {
        name: "jun",
        title: "JUN",
        month: "06",
      },
      {
        name: "jul",
        title: "JUL",
        month: "07",
      },
      {
        name: "ago",
        title: "AGO",
        month: "08",
      },
      {
        name: "set",
        title: "SET",
        month: "09",
      },
      {
        name: "out",
        title: "OUT",
        month: "10",
      },
      {
        name: "nov",
        title: "NOV",
        month: "11",
      },
      {
        name: "dez",
        title: "DEZ",
        month: "12",
      }
    );
  }
}
