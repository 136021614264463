var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"processing-count"},[(_vm.loading)?_c('overlay-loader'):_vm._e(),_c('div',{staticClass:"wrapper-cards-count"},_vm._l((_vm.quantidades),function(item,index){return _c('div',{key:("processing-count-item-" + index),class:[
        {
          processing_count_item_opacity:
            item.titulo !== 'Divergências de Contrato',
        },
        'processing-count__item' ],style:([
        {
          width:
            item.titulo === 'Divergências de Contrato' ? '290px' : '180px',
        } ]),attrs:{"data-cy":"div-processing_count-faturamento-components-contador"},on:{"click":function($event){item.titulo !== 'Divergências de Contrato' &&
          _vm.filterByContratoStatus(item)}}},[(item.titulo === 'Divergências de Contrato')?_c('divergences-card',{attrs:{"WBCLoading":_vm.WBCLoading,"quantidadeDivergencias":_vm.quantidadeDivergencias,"divergenciasLoading":_vm.divergenciasLoading,"quantidadeWBC":_vm.quantidadeWBC}}):_c('card-processing',{attrs:{"loading":_vm.loading,"itemSelected":_vm.itemSelected === item.grupoId},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(item.titulo))]},proxy:true},{key:"value",fn:function(){return [_vm._v(" "+_vm._s(item.quantidade)+" ")]},proxy:true}],null,true)})],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }