import Services from "./Services";
import TableCurtoPrazoModel from "@/models/tablesModels/TableCurtoPrazoModel";

class PropostaCurtoPrazoService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  /**
   * @summary Get the Curto Prazo
   * @param {Object} request
   * @endpoint /api/curto-prazo
   */
  getPropostaCurtoPrazo(request) {
    this._url = "/listar";
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request, true);
        if (!response.data) {
          resolve(response);
        }
        const pagination = response.data;
        const data = response.data.content.map(
          (curtoPrazo) => new TableCurtoPrazoModel(curtoPrazo)
        );
        const tableData = { data, pagination };
        resolve(tableData);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * @summary Get the Proposta Curto Prazo
   * @param {Object} request
   * @endpoint /api/propostas-curto-prazo/${idContrato}
   */
  buscarPropostaCurtoPrazo(request) {
    this._url = `/${request.idContrato}/proposta`;
    return this.get(request);
  }

  /**
   * @summary Patch Curto Prazo Boletado
   * @param {Object} request
   * @endpoint /api/propostas-curto-prazo/${idContrato}/boletar
   */
  fecharFaturamentoContratoWBC(request) {
    this._url = `/${request.curtoPrazoId}/boletagem`;
    return this.patch(request);
  }

  /**
   * @summary Cria proposta de curto prazo
   * @param {Object} request
   * @endpoint /api//propostas-curto-prazo/{contratoId}
   */
  criarPropostaCurtoPrazo(request) {
    this._url = `/${request.contratoId}`;
    return this.post(request);
  }

  getPropostaCurtoPrazoHotsite(request) {
    this._url = `${request.curtoPrazoid}`;
    return this.get(request);
  }

  distribuirCurtoPrazo(request) {
    this._url = `/${request.curtoPrazoId}/distribuicao`;
    return this.patch(request.payload);
  }

  aprovarPropostaCurtoPrazo(request) {
    this._url = `/${request.curtoPrazoId}/aprovacao`;
    return this.patch(request.payload);
  }
  renovarPropostaCurtoPrazo(request) {
    this._url = `/${request.curtoPrazoId}/renovar`;
    return this.post(request.payload);
  }
}

export default new PropostaCurtoPrazoService(
  "/demonstrativo/propostas-curto-prazo"
);
