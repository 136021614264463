export default {
  methods: {
    mxIsChromeBrowser() {
      // Chrome 1 - 79
      const isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);
      return isChrome;
    },
    mxIsOperaBrowser() {
      // Opera 8.0+
      const isOpera =
        (!!window.opr && !!opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0;
      return isOpera;
    },
    mxIsFirefoxBrowser() {
      // Firefox 1.0+
      const isFirefox = typeof InstallTrigger !== "undefined";
      return isFirefox;
    },
    mxIsSafariBrowser() {
      // Safari 3.0+ "[object HTMLElementConstructor]"
      const isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && window["safari"].pushNotification)
        );
      return isSafari;
    },
    mxIsInternetExplorerBrowser() {
      // Internet Explorer 6-11
      const isIE = /*@cc_on!@*/ false || !!document.documentMode;
      return isIE;
    },
    mxIsEdgeBrowser() {
      // Edge 20+
      const isIE = /*@cc_on!@*/ false || !!document.documentMode;
      const isEdge = !isIE && !!window.StyleMedia;
      return isEdge;
    },
  },
};
