import { CURRENCY, VOLUME } from "@/filters/formatters";

export class TableRecompraPrecoDiferente {
  static getTableFields() {
    return new Array(
      {
        name: "contrato",
        title: "Contrato",
      },
      {
        name: "solicitadoLiquido",
        title: "Vol. (MWh)",
        formatter(value) {
          return VOLUME(value);
        },
      },
      {
        name: "preco",
        title: "Preço",
        formatter(value) {
          return CURRENCY(value, "--");
        },
      },
      {
        name: "notional",
        title: "Notional",
        formatter(value) {
          return CURRENCY(value, "--");
        },
      },
      {
        name: "volumeRecompra",
        title: "Vol. (MWh)",
        formatter(value) {
          return VOLUME(value);
        },
      },
      {
        name: "precoRecompra",
        title: "Preço",
        formatter(value) {
          return CURRENCY(value, "--");
        },
      },
      {
        name: "notionalRecompra",
        title: "Notional",
        formatter(value) {
          return CURRENCY(value, "--");
        },
      },
      {
        name: "volumeFaturamentoComRecompra",
        title: "Vol. (MWh)",
        formatter(value) {
          return VOLUME(value);
        },
      },
      {
        name: "precoFaturamentoComRecompra",
        title: "Preço",
        formatter(value) {
          return CURRENCY(value, "--");
        },
      },
      {
        name: "notionalFaturamentoComRecompra",
        title: "Notional",
        formatter(value) {
          return CURRENCY(value, "--");
        },
      }
    );
  }
}
