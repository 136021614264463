<template>
  <div class="page-container">
    <div class="header-dashboard">
      <div
        @click="goToCardList"
        class="icon-close btn-fc"
        data-cy="div-seguir_para_card_selecionado-pages-dashboards-components"
      ></div>

      <div
        class="btn-fc"
        @click="openFullscreen"
        data-cy="div-dashboard_pbi-pages-dashboards-components"
      >
        <img src="@/assets/icons/svg/fullscreen_white.svg" alt="fullscreen" />
      </div>
    </div>

    <div
      id="chart"
      ref="chart"
      class="reportMobileContainer iframeContainer active"
    ></div>
  </div>
</template>

<script>
import * as pbi from "powerbi-client";
import PowerBiService from "@/services/PowerBiService";
export default {
  name: "PowerBI",
  props: {
    dashboardObject: {
      type: Object,
    },
  },
  // props: ["dashboardObject"],
  data() {
    return {
      token: null,
      report: null,
      powerbi: null,
      dashboard: null,
      timeoutForTokenExpiration: null,
      powerbiData: null,
    };
  },
  async mounted() {
    await this.getDashboard(true);
    this.scheduleTokenExpiration();
  },
  methods: {
    getDashboard(plot = false) {
      return new Promise((resolve, reject) => {
        this.loading = PowerBiService.getPowerBI({
          reportId: this.dashboardObject.reportId,
        })
          .then(async ({ data }) => {
            this.dashboard = data;
            if (plot) {
              await this.plotChart();
            } else {
              this.scheduleTokenExpiration();
            }
            resolve();
          })
          .catch((error) => {
            this.mxHandleRequestError(error);
            reject();
          });
      });
    },
    goToCardList() {
      clearTimeout(this.timeoutForTokenExpiration);
      this.token = null;
      this.report = null;
      this.powerbi = null;
      this.timeoutForTokenExpiration = null;
      this.powerbiData = null;
      this.$emit("goToCardList");
    },
    plotChart() {
      return new Promise((resolve, reject) => {
        try {
          let embedConfiguration = {
            type: "report",
            tokenType: pbi.models.TokenType.Embed,
            accessToken: this.dashboard.token,
            embedUrl: this.dashboard.embedUrl,
            id: this.dashboard.reportId,
            permissions: pbi.models.Permissions.All,
            settings: {
              panes: {
                filters: {
                  visible: false,
                },
                pageNavigation: {
                  visible: true,
                },
              },
            },
          };

          this.powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory,
            pbi.factories.wpmpFactory,
            pbi.factories.routerFactory
          );

          this.report = this.powerbi.embed(
            this.$refs.chart,
            embedConfiguration
          );

          this.$refs.chart.firstChild.style.border = "none";

          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    openFullscreen() {
      var elem = this.$refs.chart;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        //Safari
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        //IE
        elem.msRequestFullscreen();
      }
    },

    scheduleTokenExpiration() {
      const future = new Date(this.dashboard.expiracaoToken).getTime();
      const tokenExpiration = future - new Date().getTime();

      if (this.timeoutForTokenExpiration) {
        clearTimeout(this.timeoutForTokenExpiration);
      }

      this.timeoutForTokenExpiration = setTimeout(async () => {
        await this.getDashboard();
        this.report.setAccessToken(this.dashboard.token);
      }, tokenExpiration);
    },
  },
};
</script>

<style lang="scss" scoped>
#chart {
  height: 100%;
}

iframe {
  border: none;
}

.header-dashboard {
  right: 0;
  bottom: 0;
  width: 140px;
  display: flex;
  position: fixed;
  align-self: center;
  margin-right: 40px;
  margin-bottom: 40px;
  justify-content: space-between;
}

.btn-fc {
  width: 60px;
  height: 60px;
  display: flex;
  color: white;
  cursor: pointer;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
  background-color: rgb(230, 108, 55);
}

.page-container {
  height: 85vh;
  display: flex;
  padding: 15px;
  overflow: auto;
  flex-direction: column;

  &__body {
    padding: 30px;
  }
}

.scroll {
  width: 100%;
  padding: 30px;
  height: calc(117.6vh - 45px);
}

.mtx-scroll-template {
  width: 100%;
  padding: 30px;
  overflow: auto;
  height: 100% !important;
}
.mtx-buttons-bar {
  width: 60%;
  height: 50px;
  margin: auto;
  min-width: 850px;
  margin-top: 10px;
  position: relative;
}
.buttons-bar {
  width: 60%;
  height: 60px;
  margin: auto;
  min-width: 850px;
  margin-top: -60px;
  position: relative;
}
</style>
