<template>
  <div>
    <input
      class="w-100"
      :class="{
        'is-disabled': disabled,
      }"
      :disabled="disabled"
      v-model.trim="model.content"
      data-cy="input-alcada_aprovacao_minima-parametrizacao-components-fields"
    />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    modelProp: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data() {
    return {
      model: {
        name: "valorFaixaMin",
        content: "",
      },
    };
  },
  watch: {
    modelProp: {
      immediate: true,
      deep: true,
      handler() {
        if (this.modelProp.descricaoPerfilAprovador === "POS_VENDA") {
          this.model.content = 0;
        } else {
          this.model.content = this.modelProp[this.model.name];
        }
      },
    },
    "model.content": {
      deep: true,
      handler() {
        if (this.modelProp.descricaoPerfilAprovador === "POS_VENDA") {
          this.modelProp[this.model.name] = 0;
        } else {
          this.modelProp[this.model.name] = this.model.content;
        }
      },
    },
  },
  computed: {
    disabledStylization() {
      if (this.disabled) return "is-disabled";
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  padding: 5px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #b4b7ba;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.is-disabled {
  color: #9c9ea1 !important;
  background: #f2edeb !important;
}
</style>
