import { DateTime } from "luxon";
import { CAPITALIZE } from "@/filters/formatters";


export default class TableHistoricoFaturamentosFechados {
  getTableFields() {
    return new Array(
      {
        name: "competencia",
        title: "Faturamento/Mês",
        width: "100px",
        formatter(value) {
          return CAPITALIZE(value)
        },
      },
      {
        name: "dataFechamento",
        title: "Data Fechamento",
        width: "100px",
        formatter(value) {
          const dataFechamentoFormatada =
            DateTime.fromISO(value).toFormat("dd/MM/yyyy");
          if (dataFechamentoFormatada === "Invalid DateTime") {
            return "--";
          }
          return dataFechamentoFormatada;
        },
      },
      {
        name: "responsavel",
        title: "Responsável",
        width: "100px",
      }
    );
  }
}
