<template>
  <div
    v-if="on"
    class="default-modal"
    :class="{ closingAnim: runClosingAnim }"
    :style="styles.defaultModal || {}"
  >
    <div
      class="default-modal-bg"
      @click="closeable && close()"
      :style="styles.defaultModalBg || {}"
    />
    <div
      class="default-modal-content"
      :style="styles.defaultModalContent || {}"
    >
      <div class="mtx-page-header" :style="styles.mtxPageHeader || {}">
        <div>
          <slot name="header" />
        </div>
      </div>
      <div class="modal__body" :style="styles.modalBody || {}">
        <slot name="body" />
      </div>
      <div class="modal__footer" :style="styles.modalFooter || {}">
        <slot name="footer" />
      </div>
      <button v-if="closeable" @click="close" type="button" class="btn-close" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      runClosingAnim: false,
    };
  },
  props: {
    on: {
      type: Boolean,
      default() {
        return true;
      },
    },
    timeout: {
      type: Number,
      default: 200,
    },
    closeable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    styles: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    close() {
      this.runClosingAnim = true;
      setTimeout(() => {
        this.$emit("close");
        this.runClosingAnim = false;
      }, this.timeout);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";
@import "@/assets/style/breakpoints.scss";

.default-modal {
  width: 100%;
  height: 100vh;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.closingAnim {
    .default-modal-content {
      animation: modalShrink 200ms forwards;
    }
    .default-modal-bg {
      animation: modalBgFadeOutLight 500ms forwards;
    }
  }
  &-bg {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(1.2px);
    animation: modalBgFadeInLight 300ms;
    width: 100%;
    height: 100%;
  }
  &-content {
    position: absolute;
    background-color: white;
    animation: modalBump 300ms;
    border-radius: 10px;
    width: 80%;
    max-width: 800px;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    > .btn-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.custom-table-template {
  overflow-y: scroll;
  height: 100%;
  background-color: #f8f8f8;
  > div.table__row {
    background-color: white;
    display: flex;
    justify-content: center;
    transition: background-color 100ms;
    &:hover {
      background-color: #f8f8f8;
    }
    .coll {
      border-bottom: 1px solid #b6b6b6;
      padding: 10px;
      font-weight: 400;
      color: #2a2a2a;
      &:nth-child(1) {
        flex-grow: 1;
      }
      &:nth-child(3) {
        width: 10%;
        width: 21px;
        padding: 0;
      }
    }
  }
}
.mtx-page-header {
  width: 100%;
  display: flex;
  font-size: 21px;
  min-height: 57px;
  font-weight: 700;
  line-height: 120%;
  position: relative;
  text-align: center;
  align-items: center;
  letter-spacing: 1px;
  justify-content: center;
  border-bottom: 1px solid $color--primary--gray;
}
.modal__body {
  flex: 1;
  width: 100%;
  overflow: auto;
}
.modal__footer {
  display: flex;
  min-height: 66px;
  align-items: center;
  border-top: 1px solid $color--primary--gray;
}
</style>
