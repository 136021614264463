<template>
  <div>
    <generic-modal
    
      :width="'90%'"
      :maxWidth="'800px'"
      :minWidth="'300px'"
      :footerHide="true"
      :height="'500px'"
      :iconClose="false"
      @close="close"
    >
      <template v-slot:header>ENVIO DA PROPOSTA DE CURTO PRAZO</template>

      <div class="wrapper">
        <div class="atention">ATENÇÃO</div>
        <div class="messages">
          <span style="text-align: center">
            A Proposta de Curto Prazo foi enviada para o seu e-mail cadastrado.
          </span>
        </div>

        <div class="box-button">
          <button @click="close" class="btn">FECHAR</button>
        </div>
      </div>
    </generic-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: undefined,
      userInfo: {
        name: "",
        email: "",
      },
    };
  },

  methods: {
    close() {
      this.$emit("closeShortTermSent");
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 15px;
  position: relative;
}
.atention {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #707683;
  background: #e8e8e8;
  border-radius: 5px;
  padding: 6px;
  text-align: center;
}
.messages {
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #000000;
  margin: 20px 0px 33px 0px;
}

.box-button {
  width: 100%;
  text-align: center;
}
.btn {
  width: 174px;
  height: 42px;
  left: calc(50% - 174px / 2);
  top: 573px;
  background: #f86511;
  border-radius: 4px;
  color: #fff;
  &:hover {
    background: rgba($color: #f86511, $alpha: 0.8);
    color: #fff;
  }
}
.error {
  border: 1px solid red;
}
</style>