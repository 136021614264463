import AlcadasAprovacaoModel from "../AlcadasAprovacaoModel";

export default class TableAlcadasAprovacaoModel extends AlcadasAprovacaoModel {
  getTableFields() {
    return new Array(
      {
        name: "descricao",
        title: "Alçada",
        width: "40%"
      },
      {
        name: "valorFaixaMin",
        title: "Mínimo (MWh)",
        width: "30%"
      },
      {
        name: "valorFaixaMax",
        title: "Máximo (MWh)",
        width: "30%"
      }
    );
  }
}
