import Services from "./Services";
import TablePosVendaModel from "@/models/tablesModels/TablePosVendaModel";
import { CNPJ } from "@/filters/formatters";

class EmpresaService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  getEmpresas(request) {
    this._url = "";
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request, true);
        if (!response.data) {
          resolve(response);
        }
        const pagination = response.data;
        const data = response.data.content.map(
          (empresa) => new TablePosVendaModel(empresa)
        );
        const tableData = { data, pagination };
        resolve(tableData);
      } catch (error) {
        reject(error);
      }
    });
  }

  getPosVenda(request) {
    this._url = `pos-venda`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request, true);
        const pagination = response.data;
        const data = response.data.content ? response.data.content : [];
        const dataPosVenda = { data, pagination };
        resolve(dataPosVenda);
      } catch (error) {
        reject(error);
      }
    });
  }

  associarPosVenda(request) {
    this._url = `${request.id}/pos-venda/${request.idPosVenda}`;
    return this.patch(request);
  }

  getQuantidadeInconsistentes(request) {
    this._url = "/inconsistentes/quantidade";

    return this.get(request);
  }

  getInconsistentes(request) {
    this._url = "/inconsistentes";

    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await this.get(request, true);
        const pagination = data;

        if (typeof data === "string") {
          data = {
            content: new Array(),
          };
        }

        if (data.content.length == 0) {
          return resolve({ data: new Array(), pagination });
        }

        const content = data.content.map((item) => ({
          ...item,
          cnpj: CNPJ(item.cnpj),
          selected: false,
        }));

        resolve({ data: content, pagination });
      } catch (error) {
        reject(error);
      }
    });
  }

  sendEmpresasIncosistentes(request) {
    this._url = "/inconsistentes";

    return this.post(request);
  }
}

export default new EmpresaService("/empresas");
