export default class AlcadasAprovacaoModel {
  constructor(
    {
      id,
      tipo,
      descricao,
      valorFaixaMin,
      valorFaixaMax,
      descricaoPerfilAprovador,
    } = new Object()
  ) {
    this._id = id;
    this._tipo = tipo;
    this._descricao = descricao;
    this.valorFaixaMin = valorFaixaMin;
    this._valorFaixaMax = valorFaixaMax;
    this._descricaoPerfilAprovador = descricaoPerfilAprovador;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id = null) {
    this._id = id;
  }

  get tipo() {
    return this._tipo ?? null;
  }

  set tipo(tipo = null) {
    this._tipo = tipo;
  }

  get descricao() {
    return this._descricao ?? null;
  }

  set descricao(descricao = null) {
    this._descricao = descricao;
  }

  get descricaoPerfilAprovador() {
    return this._descricaoPerfilAprovador ?? null;
  }

  set descricaoPerfilAprovador(descricaoPerfilAprovador = null) {
    this._descricaoPerfilAprovador = descricaoPerfilAprovador;
  }

  get valorFaixaMin() {
    return this._valorFaixaMin ?? null;
  }

  set valorFaixaMin(valorFaixaMin = null) {
    this._valorFaixaMin = valorFaixaMin;
  }

  get valorFaixaMax() {
    return this._valorFaixaMax ?? null;
  }

  set valorFaixaMax(valorFaixaMax = null) {
    this._valorFaixaMax = valorFaixaMax;
  }

  getData() {
    const {
      id,
      tipo,
      descricao,
      valorFaixaMin,
      valorFaixaMax,
      descricaoPerfilAprovador,
    } = this;
    return {
      id,
      tipo,
      descricao,
      valorFaixaMin,
      valorFaixaMax,
      descricaoPerfilAprovador,
    };
  }
}
