import Services from "./Services";

class PowerBiService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  /**
   * @summary Gets data from Power BI by id
   * @param {Object} request
   * @param Number id
   * @endpoint /api/power-bi/id
   */
  getPowerBI(request) {
    this._url = "";
    return this.get(request, true);
  }
}

export default new PowerBiService("/power-bi");
