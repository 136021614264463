<template>
  <div class="wrapper-pagination">
    <div class="box-arrow">
      <div
        class="left"
        @click="preview"
        v-if="currentPage > 0"
        data-cy="div-preview_box_arrow-pagination"
      >
        &lsaquo;
      </div>
    </div>
    <div
      :key="`index${index}`"
      class="pagination__item"
      v-for="(item, index) in maxItens"
      @click="setPage(index + first - 1)"
      data-cy="div-number_for_pages-pagination"
      :class="[currentPage == index + first - 1 ? 'item-active' : null]"
    >
      {{ index + first }}
    </div>
    <span v-if="currentPage < lastPage - 4 && lastPage != first + 4"
      ><div class="_dots">&hellip;</div>
    </span>

    <div
      class="pagination__item"
      @click="goPage(lastPage)"
      data-cy="div-number_for_last_page-pagination"
      v-if="currentPage < lastPage - 4 && lastPage != first + 4"
    >
      {{ lastPage }}
    </div>

    <div class="box-arrow">
      <div v-if="currentPage < lastPage - 1" @click="next" class="right">
        &rsaquo;
      </div>
    </div>
    <div class="mr-2">
      <select
        class="perpage"
        v-model="itemPerPage"
        @change="setNumberPerPage(itemPerPage)"
        data-cy="select-number_per_page-pagination"
      >
        <option :value="10">10 itens por página</option>
        <option :value="20">20 itens por página</option>
        <option :value="30">30 itens por página</option>
        <option :value="40">40 itens por página</option>
        <option :value="50">50 itens por página</option>
        <option :value="100">100 itens por página</option>
      </select>
    </div>
    <span class="label"> Ir para a pág.</span>
    <div>
      <input
        id="goPage"
        type="text"
        class="input"
        v-debounce:300ms="goPage"
        data-cy="input-go_to_page-pagination"
      />
    </div>
    <!--<div class="label ml-2 mr-2">Total:</div><span class="label">{{this.info.totalElements}}</span>-->
  </div>
</template>
<script>
export default {
  props: ["info"],
  data() {
    return {
      show: false,
      maxItens: 5,
      maxLeft: undefined,
      currentPage: 0,
      first: undefined,
      itens: [],
      activePage: 0,
      lastPage: 0,
      itemPerPage: 10,
    };
  },
  methods: {
    reset() {
      (this.maxItens = 5), (this.maxLeft = undefined);
      this.currentPage = 0;
      this.first = undefined;
      this.itens = [];
      this.activePage = 0;
      this.lastPage = 0;
      this.itemPerPage = 10;
    },
    generate() {
      this.reset();
      this.maxLeft = 1;
      this.currentPage = this.info.number ? this.info.number : 0;
      this.lastPage = this.info.totalPages;
      if (this.lastPage < this.maxItens) {
        this.maxItens = this.lastPage;
      }
      if (this.currentPage <= this.lastPage - 3) {
        this.first = Math.max(this.currentPage - this.maxLeft, 1);
      } else {
        this.first = this.lastPage - (this.maxItens - 1);
      }
    },
    setPage(number) {
      this.$emit("page", number);
    },
    setNumberPerPage(value) {
      this.$emit("perPage", value);
    },
    goPage(page) {
      if (page <= this.lastPage && page > 0 && page != "") {
        if (page < this.lastPage - 3) {
          this.maxLeft = 1;
          this.setPage(page - 1);
        } else {
          this.maxLeft = 3;
          this.setPage(page - 1);
        }
      }
      document.querySelector("#goPage").value = "";
    },
    next() {
      if (this.currentPage < this.lastPage - 3) {
        this.maxLeft = 3;
      } else {
        this.maxLeft = 1;
      }
      this.$emit("page", this.currentPage + 1);
    },
    preview() {
      this.$emit("page", this.currentPage - 1);
    },
  },
  watch: {
    info: {
      deep: true,
      immediate: true,
      handler() {
        this.generate();
        this.show = true;
        this.info.size
          ? (this.itemPerPage = this.info.size)
          : (this.itemPerPage = 10);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-pagination {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.pagination__item {
  min-width: 34px;
  height: 34px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 21px;
  line-height: 100%;
  text-align: center;
  color: #505050;
  font-weight: 700;
  margin: 0 2px 0 2px;
  &:hover {
    color: #f86511;
    border: 1px solid #f86511;
  }
  @include ml-hd {
    min-width: responsive(34px);
    height: responsive(34px);
    border-radius: responsive(10px);
    font-size: responsive(21px);
  }
}
.item-active {
  color: #f86511;
  border: 1px solid #f86511;
}
.box-arrow,
.left,
.right {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  @include ml-hd {
    width: responsive(24px);
    height: responsive(24px);
  }
}
.left,
.right {
  cursor: pointer;
  overflow: hidden;
  font-size: 35px;
  margin-top: -5px;
  &:hover {
    color: #f86511;
  }
  @include ml-hd {
    font-size: responsive(35px);
  }
}
.perpage {
  height: 34px;
  width: 200px;
  border-radius: 10px;
  border: 1px solid #b4b7ba;
  background: #fffaf8;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #2a2a2a;
  padding-left: 10px;
  margin: 0 15px 0 15px;
  @include ml-hd {
    height: responsive(34px);
    width: responsive(200px);
    border-radius: responsive(10px);
    font-size: responsive(12px);
  }
  &:hover {
    border: 1px solid #f86511;
  }
  &:focus {
    outline: 0;
    border: none;
    border: 1px solid #f86511;
  }
}
.label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #2a2a2a;
  @include ml-hd {
    font-size: responsive(21px);
  }
}
.input {
  height: 34px;
  border-radius: 10px;
  width: 60px;
  margin-left: 10px;
  border: 1px solid #b4b7ba;
  box-sizing: border-box;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #2a2a2a;
  @include ml-hd {
    height: responsive(34px);
    border-radius: responsive(10px);
    width: responsive(60px);
    font-size: responsive(12px);
  }
  &:hover {
    border: 1px solid #f86511;
  }
  &:focus {
    outline: 0;
    border: none;
    border: 1px solid #f86511;
  }
}
._dots {
  font-size: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  @include ml-hd {
    font-size: responsive(30px);
    height: responsive(30px);
  }
}
</style>