import { CURRENCY } from "@/filters/formatters";

export default class Submercado {
  constructor(
    {
      id,
      sigla,
      codigo,
      selected,
      descricao,
      ultimoPld,
      tiposEnergia,
    } = new Object()
  ) {
    this._id = id;
    this._sigla = sigla;
    this._codigo = codigo;
    this._selected = selected;
    this._descricao = descricao;
    this._ultimoPld = ultimoPld;
    this._tiposEnergia = tiposEnergia;
  }

  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get descricao() {
    return this._descricao;
  }

  set descricao(descricao) {
    this._descricao = descricao;
  }

  get sigla() {
    return this._sigla;
  }

  set sigla(sigla) {
    this._sigla = sigla;
  }

  get ultimoPld() {
    return this._ultimoPld;
  }

  set ultimoPld(ultimoPld) {
    this._ultimoPld = ultimoPld;
  }

  get tiposEnergia() {
    return this._tiposEnergia;
  }

  set tiposEnergia(tiposEnergia) {
    this._tiposEnergia = tiposEnergia;
  }

  get selected() {
    return this._selected || false;
  }

  set selected(selected = false) {
    this._selected = selected;
  }

  getPrecoPLD(currency = true) {
    if (!this._ultimoPld) return "--";
    if (currency) return CURRENCY(this._ultimoPld.preco);
    return this._ultimoPld.preco;
  }

  getPLDEditado(currency = true) {
    if (!this._ultimoPld || !this._ultimoPld.precoEditado) return "--";
    if (currency) return CURRENCY(this._ultimoPld.precoEditado);
    return this._ultimoPld.precoEditado;
  }

  static getTableFields() {
    return new Array(
      {
        name: "descricao",
        title: "Tipo de Energia",
      },
      {
        name: "spreadCompra",
        title: "Spread Compra",
        formatter(value) {
          return CURRENCY(value, "--");
        },
      },
      {
        name: "spreadVenda",
        title: "Spread Venda",
        formatter(value) {
          return CURRENCY(value, "--");
        },
      },
      {
        name: "precoCompra",
        title: "Preço Compra",
        formatter(value) {
          return CURRENCY(value, "--");
        },
      },
      {
        name: "precoVenda",
        title: "Preço Venda",
        formatter(value) {
          return CURRENCY(value, "--");
        },
      },
      {
        name: "status",
        title: "Status",
      },
      {
        name: "detalhes",
        title: "Detalhes",
      },
      {
        name: "tooltip",
        title: "Tooltip",
      },
    );
  }

  getData() {
    const { id, sigla, ultimoPld, tiposEnergia, selected } = this;
    return {
      id,
      sigla,
      tiposEnergia,
      selected,
      ultimoPld,
    };
  }
}
