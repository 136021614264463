import PontoMedicaoModel from "@/models/PontoMedicaoModel";
import { createProinfaYears } from "@/utils/functionsUtils";
import { DateTime } from "luxon";
import Services from "./Services";

class ProinfaService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  getPontosMedicaoByContrato(request) {
    this._url = `/proinfas?filtro=${request.contrato}`;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request);
        if (response.data.length > 0) {
          response.data = response.data.map((item) => {
            const pontoMedicao = new PontoMedicaoModel(item);

            let hasProinfas = false;

            let { proinfas } = item;
            if (!proinfas.length) {
              proinfas = createProinfaYears();
            } else {
              hasProinfas = true;
              const newYears = [
                ...new Set(
                  proinfas.map((proinfa) =>
                    DateTime.fromISO(proinfa.dataReferencia).toFormat("y")
                  )
                ),
              ];

              const newProinfas = createProinfaYears();
              newProinfas.forEach((newProinfa) => {
                proinfas.forEach((proinfa) => {
                  const yearProinfa = DateTime.fromISO(
                    proinfa.dataReferencia
                  ).toFormat("y");

                  const monthProinfa = DateTime.fromISO(
                    proinfa.dataReferencia
                  ).toFormat("MM");

                  const yearNewProinfa = DateTime.fromISO(
                    newProinfa.dataReferencia
                  ).toFormat("y");

                  const monthNewProinfa = DateTime.fromISO(
                    newProinfa.dataReferencia
                  ).toFormat("MM");

                  if (
                    yearProinfa == yearNewProinfa &&
                    monthProinfa == monthNewProinfa
                  ) {
                    newProinfa.valor = proinfa.valor;
                  }
                });
              });

              proinfas = newProinfas;
            }

            const years = new Array();
            const withMonthAndYear = proinfas.map((proinfa) => {
              const year = DateTime.fromISO(proinfa.dataReferencia).toFormat(
                "y"
              );

              if (!years.includes(year)) years.push(year);

              const month = DateTime.fromISO(proinfa.dataReferencia).toFormat(
                "MM"
              );

              return { ...proinfa, year, month };
            });

            const proinfasGroupedByYear = new Array();
            const months = [
              "jan",
              "fev",
              "mar",
              "abr",
              "mai",
              "jun",
              "jul",
              "ago",
              "set",
              "out",
              "nov",
              "dez",
            ];

            years.forEach((year) => {
              const proinfaGroupedByYear = {
                ano: year,
              };
              let index = 0;
              withMonthAndYear.forEach((proinfa) => {
                if (proinfa.year == year) {
                  proinfaGroupedByYear[months[index++]] = proinfa;
                }
              });
              proinfasGroupedByYear.push(proinfaGroupedByYear);
            });

            pontoMedicao.proinfas = new Array();
            pontoMedicao.hasProinfas = hasProinfas;
            pontoMedicao.opened = false;
            pontoMedicao.addProinfas(proinfasGroupedByYear);

            return pontoMedicao;
          });
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  getPontosContrato(request) {
    this._url = `/contratos/${request.idContrato}`;

    return new Promise(async (resolve, reject) => {
      try {
        const responsePontos = await this.get(request);
        if (responsePontos.data.length > 0) {
          const data = responsePontos.data.map((ponto) => ({
            contrato: ponto.codigoContrato,
            pontos: ponto.pontoMedicao,
            pontosRetaguarda: ponto.pontoRetaguarda,
            isValid: false,
          }));

          const response = { data };

          resolve(response);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  getPontosByContratoCod(request) {
    this._url = `/proinfas/contrato?codigo=${request.contrato}`;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request);
        if (response.data.length > 0) {
          response.data = response.data.map((item) => {
            const pontoMedicao = new PontoMedicaoModel(item);

            let hasProinfas = false;

            let { proinfas } = item;
            if (!proinfas.length) {
              proinfas = createProinfaYears();
            } else {
              hasProinfas = true;
              const newYears = [
                ...new Set(
                  proinfas.map((proinfa) =>
                    DateTime.fromISO(proinfa.dataReferencia).toFormat("y")
                  )
                ),
              ];

              const newProinfas = createProinfaYears();
              newProinfas.forEach((newProinfa) => {
                proinfas.forEach((proinfa) => {
                  const yearProinfa = DateTime.fromISO(
                    proinfa.dataReferencia
                  ).toFormat("y");

                  const monthProinfa = DateTime.fromISO(
                    proinfa.dataReferencia
                  ).toFormat("MM");

                  const yearNewProinfa = DateTime.fromISO(
                    newProinfa.dataReferencia
                  ).toFormat("y");

                  const monthNewProinfa = DateTime.fromISO(
                    newProinfa.dataReferencia
                  ).toFormat("MM");

                  if (
                    yearProinfa == yearNewProinfa &&
                    monthProinfa == monthNewProinfa
                  ) {
                    newProinfa.valor = proinfa.valor;
                  }
                });
              });

              proinfas = newProinfas;
            }

            const years = new Array();
            const withMonthAndYear = proinfas.map((proinfa) => {
              const year = DateTime.fromISO(proinfa.dataReferencia).toFormat(
                "y"
              );

              if (!years.includes(year)) years.push(year);

              const month = DateTime.fromISO(proinfa.dataReferencia).toFormat(
                "MM"
              );

              return { ...proinfa, year, month };
            });

            const proinfasGroupedByYear = new Array();
            const months = [
              "jan",
              "fev",
              "mar",
              "abr",
              "mai",
              "jun",
              "jul",
              "ago",
              "set",
              "out",
              "nov",
              "dez",
            ];

            years.forEach((year) => {
              const proinfaGroupedByYear = {
                ano: year,
              };
              let index = 0;
              withMonthAndYear.forEach((proinfa) => {
                if (proinfa.year == year) {
                  proinfaGroupedByYear[months[index++]] = proinfa;
                }
              });
              proinfasGroupedByYear.push(proinfaGroupedByYear);
            });

            pontoMedicao.proinfas = new Array();
            pontoMedicao.hasProinfas = hasProinfas;
            pontoMedicao.opened = false;
            pontoMedicao.addProinfas(proinfasGroupedByYear);

            return pontoMedicao;
          });
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  updateProinfa(request) {
    this._url = `/${request.idPonto}/proinfas`;
    return this.patch(request.proinfas);
  }

  uploadProinfaEmLotes(request) {
    this._url = "/proinfas/upload-lote";

    return this.post(request, false, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  uploadEmLotes(request) {
    this._url = "/upload-lote";

    return this.post(request, false, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}

export default new ProinfaService("/pontos");
