<template>
  <div class="processing-count">
    <overlay-loader v-if="loading" />
    <div class="wrapper-cards-count">
      <div
        :class="[
          {
            processing_count_item_opacity:
              item.titulo !== 'Divergências de Contrato',
          },
          'processing-count__item',
        ]"
        v-for="(item, index) in quantidades"
        @click="
          item.titulo !== 'Divergências de Contrato' &&
            filterByContratoStatus(item)
        "
        :key="`processing-count-item-${index}`"
        :style="[
          {
            width:
              item.titulo === 'Divergências de Contrato' ? '290px' : '180px',
          },
        ]"
        data-cy="div-processing_count-faturamento-components-contador"
      >
        <divergences-card
          :WBCLoading="WBCLoading"
          :quantidadeDivergencias="quantidadeDivergencias"
          :divergenciasLoading="divergenciasLoading"
          :quantidadeWBC="quantidadeWBC"
          v-if="item.titulo === 'Divergências de Contrato'"
        />

        <card-processing
          v-else
          :loading="loading"
          :itemSelected="itemSelected === item.grupoId"
        >
          <template #title>{{ item.titulo }}</template>
          <template #value>
            {{ item.quantidade }}
          </template>
        </card-processing>
      </div>
    </div>
  </div>
</template>
<script>
import CardProcessing from "./CardProcessing.vue";
import OverlayLoader from "@/components/OverlayLoader.vue";
import DivergencesCard from "./DivergencesCard.vue";

export default {
  props: {
    quantidades: {
      type: Array,
      default: new Array(),
    },
    loading: {
      default: false,
    },
    WBCLoading: {
      default: false,
    },
    divergenciasLoading: {
      default: false,
    },
    quantidadeWBC: {
      default: 0,
    },
    quantidadeDivergencias: {
      default: 0,
    },
  },
  data() {
    return {
      itemSelected: null,
    };
  },
  methods: {
    filterByContratoStatus(item) {
      if (this.itemSelected === item.grupoId) {
        this.itemSelected = null;
        return this.$emit("clearFilterByContratoStatus");
      }
      this.itemSelected = item.grupoId;
      this.$emit("filterByContratoStatus", item);
    },
  },
  components: {
    OverlayLoader,
    CardProcessing,
    DivergencesCard,
  },
};
</script>
<style lang="scss" scoped>
.processing-count {
  min-height: 170px;
  position: relative;
  @include mq-value(1450px) {
    min-height: responsive(170px);
  }
  &__item {
    margin-top: 20px;
    width: 195px;
  }
}
.wrapper-cards-count {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.processing-count__item {
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.processing_count_item_opacity:hover {
  opacity: 0.8;
}
</style>
