import bigDecimal from "js-big-decimal";
import { DateTime } from "luxon";

/**
 * @description - Make a deep copy of an Object to a new instance with the same values;
 * @param {Object} value - Object to be copied;
 * @returns {Object} - A new instance of the same object;
 */
export const makeDeepCopy = (value = new Object()) => {
  try {
    return JSON.parse(JSON.stringify(value));
  } catch (error) {
    return new Object();
  }
};

/**
 * @description - Receives a Vue Component context to search by all its refs and clean up its models;
 * @param {Object} context - Vue Componente context;
 * @returns {void};
 */
export const cleanUpFields = (context) => {
  Object.keys(context.$refs).forEach((ref) => {
    if (!context.$refs[ref]) return;
    if (ref != "codigoAgenteField" && ref != "dadosProvisoriosField") {
      if (context.$refs[ref].model && context.$refs[ref].model.content) {
        context.$refs[ref].model.content = "";
      } else if (context.$refs[ref].selected) {
        context.$refs[ref].selected = false;
      }
      if (context.$refs[ref].$v) {
        context.$refs[ref].$v.$reset();
      }
    }
  });
};

/**
 * @description - Value Is Present is a function to validate if the value exists;
 * @param {Object} value - Object to be validated;
 * @returns {Boolean} - True or false;
 */
export const valueIsPresent = (value = null) => value && value.id;

/**
 * @description - Value Is Present is a function to validate if the value is greater than the index passed;
 * @param {Object} value - Object to be validated;
 * @returns {Boolean} - True or false;
 */
export const valueIsGreaterThanIndex = (value = null, size = 0) =>
  value && value.length >= size;

/**
 * @description - Receives a Vue Component context to set the model of its refs;
 * @param {Object} context - Vue Component context;
 * @param {Object} data - Data to be setted on the model;
 * @returns {void};
 */
export const setFieldsData = (context, data) => {
  Object.keys(context.$refs).forEach((ref) => {
    if (!context.$refs[ref] || !context.$refs[ref].model) return;
    const modelValue = data[context.$refs[ref].model.name];
    if (
      context.$refs[ref].model &&
      context.$refs[ref].model.name &&
      modelValue
    ) {
      if (Array.isArray(modelValue) || typeof modelValue !== "object") {
        context.$refs[ref].model.content = modelValue;
      } else {
        const optionSetting = modelValue.id;
        const toSet = context.$refs[ref].list.find(
          (item) => item.id == optionSetting
        );
        context.$refs[ref].model.content = toSet;
      }
      if (context.$refs[ref].$v) {
        context.$refs[ref].$v.$reset();
      }
    }
  });
};

/**
 * @description - Function for validation of the process's environment;
 * @return {Boolean} - Returns true if the process is running in development environment;
 */
export const isDevEnvironment = () => {
  return process.env.NODE_ENV === "development";
};

/**
 * @description - Function for validation in cnpj input;
 * @param {String} cnpj - Cnpj value;
 * @param {String} referenceField - Reference field to know when there is an error;
 * @param {String} referenceMessage - Reference message to know is an error;
 * @return {Boolean} - Reference message to see if it's an error;
 */

export const validateCNPJ = (cnpj) => {
  if (isDevEnvironment()) return true;
  if (!cnpj || cnpj.length == 0) return true;
  const _cnpj = cnpj.toString().replace(/[^\d]+/g, "");
  const cnpjGerador = _cnpj.substring(0, _cnpj.length - 2).split("");
  const cnpjVerificador = _cnpj.substring(_cnpj.length - 2).split("");

  let valid = true;
  for (let index = 0; index < cnpjVerificador.length; index++) {
    let numbersValidation = new Array(5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2);
    if (index === 1) {
      numbersValidation = new Array(6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2);
    }

    let total = 0;
    for (let i = 0; i < cnpjGerador.length; i++) {
      total += cnpjGerador[i] * numbersValidation[i];
    }

    const verificador = total % 11 >= 2 ? 11 - (total % 11) : 0;

    if (verificador == cnpjVerificador[index]) {
      cnpjGerador.push(verificador.toString());
    } else {
      valid = false;
    }
  }
  return valid;
};

const getFileApplicationType = (extension) => {
  if (extension.match(new RegExp("[xls|xlsx]", "gmi"))) {
    return {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
  }
  if (extension === "pdf") {
    return {
      type: { type: "application/pdf" },
    };
  }
  return;
};

export const downloadFileFromResponseObject = (
  responseObject = new Object(),
  fileName = "",
  extension = "csv"
) => {
  const link = document.createElement("a");
  const type = getFileApplicationType(extension);
  const url = window.URL.createObjectURL(new Blob([responseObject.data]), type);
  link.href = url;
  link.setAttribute("download", `${fileName}.${extension}`);
  document.body.appendChild(link);
  link.click();
};

export const downloadFileFromResponseObjectPdf = (
  responseObject = new Object(),
  fileName = ""
) => {
  const link = document.createElement("a");
  const url = window.URL.createObjectURL(
    new Blob([responseObject.data], { type: "application/pdf" })
  );
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const mountFormDataForRequest = (entries = new Object()) => {
  const formData = new FormData();
  Object.keys(entries).forEach((key) => {
    if (Array.isArray(entries[key])) {
      formData.append(key, entries[key][0], entries[key][1]);
    } else {
      formData.append(key, entries[key]);
    }
  });
  return formData;
};

/**
 * @description - Transform the Date object in a type date input value;
 * @param {Number} value - Miliseconds value that represents a date;
 * @returns {String} - A type date input value in the pattern YYYY-MM-DD;
 */
export const formatMilliDateToISODate = (value = null) => {
  if (!value) return null;
  if (typeof value != "number") return null;
  return DateTime.fromMillis(value).toISODate();
};

/**
 * @description - Gets the hour of the Date miliseconds;
 * @param {Number} value - Miliseconds value that represents a date;
 * @returns {String} - A hour value in the pattern NN:NN;
 */
export const formatMilliDateToISOHour = (value = null) => {
  if (!value) return null;
  if (typeof value != "number") return null;
  return DateTime.fromMillis(value).toLocaleString(DateTime.TIME_24_SIMPLE);
};

/**
 * @description - Convert a Date value in Miliseconds to be persisted on the database;
 * @param {String} date - Date value;
 * @param {String} hour - The hour value (only on the Proposta creation);
 * @returns {Number} - The Date value in miliseconds;
 */
export const convertDateToTimestamp = (date = undefined, time = "00:00") => {
  if (!date || !time || (date && !date.toString().includes("-"))) {
    return null;
  }

  if (time.includes(":")) {
    time = time.replace(":", "");
  }

  const hour = Number(time[0] + time[1]);
  const minute = Number(time[2] + time[3]);
  return DateTime.fromISO(date).set({ hour, minute }).toMillis();
};

/**
 * @description - Fills the value with zero caracter at the start of it;
 * @param {String|Number} value - Value to be filled;
 * @param {Number} maximumDigits - Maximum digits to be filled;
 * @returns {String} - The value filled with zeros at the start of it;
 */
export const padLeft = (value = "", maximumDigits = 2) => {
  return value.toString().padStart(maximumDigits, "0");
};

/**
 * @description - Mount the "Faturamento Competência" info -
 * it's value is the current year and actual month - 1;
 * @param {DateTime} value - An instance of a DateTime from now;
 * @returns {Object} - A object with month and year of the "Faturamento Competência" info;
 */
export const getFaturamentoCompetencia = (datetime = DateTime.now()) => {
  const MONTH_DAYS_IN_MILLIS = datetime.day * 24 * 60 * 60 * 1000;
  const DT = datetime.minus(MONTH_DAYS_IN_MILLIS);
  return { month: padLeft(DT.month), year: DT.year };
};

/**
 * @description - Use it to get the literal year months description;
 * @param {String} - Locale of the months
 * @returns {Array} - A list with the literal months with the Locale passed to it
 */
export const getLiteralMonthsList = (locale = "pt-BR") => {
  const months = new Array();
  for (let i = 0; i < 12; i++) {
    months.push(
      DateTime.now()
        .set({ month: i })
        .setLocale(locale)
        .monthShort.replaceAll(".", "")
        .toLowerCase()
    );
  }
  return months;
};

export const volumeTrunc = (value) => {
  if (!value || value === Infinity || isNaN(value)) return "--";
  var trunc = Math.trunc(value * 1000) / 1000;
  return trunc;
};

export const moedaTrunc = (value) => {
  if (!value || value === Infinity || isNaN(value)) return "--";
  var trunc = Math.trunc(value * 100) / 100;
  return trunc;
};

export const formatData = (value, formatacao = "HH:mm") => {
  const dtTime = DateTime.fromISO(value);
  if (dtTime.invalid) return null;
  return dtTime.toFormat(formatacao);
};

/**
 * @description - Use it to generate a list with 3 year of Proinfa data
 * @param {Number} - Initial value for year
 * @param {Number} - Final value for year
 * @returns {Array} - A list of Proinfas with 12 objects each year
 */
export const createProinfaYears = (
  min = DateTime.now().get("year") - 1,
  max = DateTime.now().get("year") + 1
) => {
  const newProinfa = new Array();
  for (let i = min; i <= max; i++) {
    Array.from({ length: 12 }, (item, index) => {
      const dataReferencia = DateTime.utc(i, index + 1, 1, 5).toISO();
      newProinfa.push({
        dataReferencia,
        valor: 0,
      });
    });
  }
  return newProinfa;
};

export const generatePaginatedMock = (
  data = new Array(),
  page = 0,
  size = 10,
  resultSet = null
) => {
  return {
    content: resultSet ? resultSet : data,
    pageable: {
      sort: {
        empty: resultSet ? !resultSet.length : !data.length,
        sorted: false,
        unsorted: true,
      },
      offset: 0,
      pageNumber: page,
      pageSize: size,
      paged: true,
      unpaged: false,
    },
    totalPages: Math.ceil(data.length / 10),
    totalElements: data.length,
    last: page === Math.ceil(data.length / 10),
    size,
    number: page,
    sort: {
      empty: resultSet ? !resultSet.length : !data.length,
      sorted: false,
      unsorted: true,
    },
    numberOfElements: resultSet ? resultSet.length : size,
    first: page === 0,
    empty: resultSet ? !resultSet.length : false,
  };
};

export const getDataFromPaginatedMock = (data, page, size) => {
  const resultSet = new Array();
  for (let i = page * size; i < size * (page + 1); i++) {
    if (data[i]) resultSet.push(data[i]);
  }
  return generatePaginatedMock(data, page, size, resultSet);
};

/**
 * @description - Make a deep copy of an Object to a new instance with the same values;
 * @param {Object} value - Object to be copied;
 * @returns {Object} - A new instance of the same object;
 */
export const createErrorObject = (status) => {
  return { response: { status: status } };
};

export const convertDateToGMT = (
  date,
  dateStyle = "short",
  timeStyle = "medium"
) => {
  const dateConverted = new Date(
    new Date(date) - 3600 * 1000 * 3
  ).toLocaleString("pt-BR", { dateStyle, timeStyle });

  return dateConverted;
};

/**
 * @description - Use to generate random numbers and string
 */

export const decTwoHex = (dec) => {
  return ("0" + dec.toString(16)).substring(-2);
};

export const generateRandomString = () => {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, decTwoHex).join("");
};

function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a) {
  var str = "";
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }

  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

export async function challengeFromVerifier(v) {
  const hashed = await sha256(v);
  const base64encoded = base64urlencode(hashed);
  return base64encoded;
}

export async function defer() {
  await new Promise((resolve) => requestAnimationFrame(resolve));
}

export async function removeByteOrderMarkFromFiles(files) {
  const newFiles = new Array();
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const fileContent = await file.text();
    const newFile = new File([fileContent.replace("\ufeff", "")], file.name, {
      type: file.type,
    });
    newFiles.push(newFile);
  }
  return newFiles;
}

export function mountUrl(uri) {
  return (
    (process.env.VUE_APP_ENVIRONMENT_URL || "") +
    (process.env.VUE_APP_BASE_URL || "") +
    uri
  );
}
export function mountImg(uri) {
  return (process.env.VUE_APP_BASE_URL || "") + uri;
}

export const multiplyValues = (value1, value2) => {
  return Number(bigDecimal.multiply(value1, value2));
};
export const divideValues = (value1, value2) => {
  return Number(bigDecimal.divide(value1, value2));
};
export const sumValues = (value1, value2) => {
  return Number(bigDecimal.add(value1, value2));
};
export const subtractValues = (value1, value2) => {
  return Number(bigDecimal.subtract(value1, value2));
};

/**
 * @description - Redirect only if the query params are different;
 * @param {Object} redirectInfo - Object with the new query params;
 * @returns {void};
 */
export function changeQueryDynamically(redirectInfo, ctx) {
  const { query } = redirectInfo;
  const keys = Object.keys(query);

  const updatedParams = {};

  for (let i = 0; i < keys.length; i++) {
    const currValue = ctx.$route.query[keys[i]];
    const newValue = String(query[keys[i]]);
    if (currValue !== newValue) {
      updatedParams[keys[i]] = newValue;
    }
  }
  if (Object.keys(updatedParams).length > 0) {
    ctx.$router.push(redirectInfo);
  }
}
