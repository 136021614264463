<template>
  <div
    class="_contract-tag _label"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <slot class="_contract-tag__content" />
    <dynamic-tooltip
      class="_contract-tag__dynamic-tooltip"
      :on="hover"
      :content="hint"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: false,
    };
  },
  props: {
    color: {
      type: String,
      default: "#000",
    },
    hint: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
._contract-tag {
  display: flex;
  padding: 5px 10px;
  text-align: center;
  border-radius: 10px;
  font-size: 1.125rem;
  align-items: center;
  word-wrap: break-word;
  box-sizing: border-box;
  justify-content: center;
  border: 1px dashed #2a2a2a;
  background-color: hsla(0, 0%, 16%, 0.05);
  position: relative;
  &__content {
    z-index: 10;
  }
  &__dynamic-tooltip {
    animation: tooltipBump forwards 300ms;
    line-height: 18px;
    font-family: sans-serif;
    z-index: 12;
  }
}

.blue-tag {
  color: #1f4496;
  border: 1px dashed #1f4496;
  background-color: hsla(221, 66%, 35%, 0.1);
}
</style>
