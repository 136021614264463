export default {
  TAKES_OPTIONS: [
    {
      id: "ABAIXO",
      descricao: "Abaixo",
    },
    {
      id: "ACIMA",
      descricao: "Acima",
    },
    {
      id: "DENTRO",
      descricao: "Dentro",
    },
    {
      id: "SEM_TAKE",
      descricao: "Sem Take",
    },
  ],
};
