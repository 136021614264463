<template>
  <div class="_upload-manual">
    <modal-base
      maxWidth="80%"
      v-if="showModalUploadManual"
      data-cy="modal-fechar_modal_upload_manual-modais"
      @closeModal="close"
    >
      <template #header>Realizar Upload Manual</template>
      <template #body>
        <div
          class="_upload-manual__body"
          v-elementAsyncLoader="loading"
          style="position: relative"
        >
          <overlay-loader v-if="loadingLoad" />
          <div>
            Adicionar aqui os arquivos de medição referentes a todos os pontos
            de medição do contrato <b>{{ contrato.codigo }}</b>
          </div>
          <div class="box-upload">
            <div class="item-upload">
              <div style="margin-bottom: 15px" class="mt-3">
                <div><b>Upload de Arquivos</b></div>
                <contrato-upload-manual-field
                  v-elementAsyncLoader="filesLoading"
                  @changeFiles="handleFiles"
                />
              </div>
              <div class="mt-3">
                <div><b>Arquivos Enviados</b></div>
                <template>
                  <data-table
                    v-elementAsyncLoader="fieldsFilesLoading"
                    :fields="fieldsFiles"
                    :data="dataFiles"
                    headerStick
                    :maxItemsToScroll="5"
                  >
                    <div slot="download" slot-scope="{ row }">
                      <img
                        alt="Download icon"
                        style="cursor: pointer"
                        :src="icon.download"
                        data-cy="img-baixar_arquivo_upload-modais"
                        @click="
                          downloadArquivo(row.idHistoricoAquivo, row.nome)
                        "
                      />
                    </div>
                    <div slot="delete" slot-scope="{ row }">
                      <img
                        alt="Delete icon"
                        style="cursor: pointer"
                        :src="icon.trasher"
                        @click="excluirArquivo(row.idHistoricoAquivo)"
                        data-cy="img-excluir_arquivo_upload-modais"
                      />
                    </div>
                  </data-table>
                </template>
              </div>
            </div>
            <div class="item-upload">
              <data-table
                headerStick
                :data="dataContratos"
                :maxItemsToScroll="10"
                :fields="fieldsContratos"
              >
                <div slot="icons" slot-scope="{ row }">
                  <img v-if="row.isValid" :src="icon.check" />
                  <img v-else :src="icon.exclamation" />
                </div>
              </data-table>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="wrapper-buttons">
          <div class="btn-calcular">
            <button
              @click="loadManual"
              :disabled="
                IS_DISABLE_FINISH ||
                overlayLoading ||
                arquivosEnviados.length === 0 ||
                loadingLoad
              "
              class="btn btn-action-primary"
              data-cy="button-realizar_upload_manual-contratos-"
            >
              Calcular Medições
            </button>
          </div>
        </div>
      </template>
    </modal-base>
    <button
      class="btn btn-action-secondary-outlined"
      @click.self="showModalUploadManual = true"
      :disabled="showModalUploadManual"
      data-cy="button-mostar_modal_upload_manual-contratos-"
    >
      Realizar Upload Manual
    </button>
  </div>
</template>

<script>
import CheckIcon from "@/assets/icons/svg/check.svg";
import DownloadIcon from "@/assets/icons/svg/download_icon.png";
import ExclamationIcon from "@/assets/icons/svg/exclamation.svg";
import TrasherIcon from "@/assets/icons/svg/trasher.png";
import ContratoUploadManualField from "@/components/contratos/ContratoUploadManualField.vue";
import ModalBase from "@/components/modals/ModalBase.vue";
import DataTable from "@/components/tables/DataTable.vue";
import { CONFIRMATION } from "@/constants/strings";
import ContratoService from "@/services/ContratoService";
import ProinfaService from "@/services/ProinfaService";
import UploadService from "@/services/UploadService";
import {
  downloadFileFromResponseObject,
  removeByteOrderMarkFromFiles,
} from "@/utils/functionsUtils";

export default {
  components: {
    ModalBase,
    ContratoUploadManualField,
    DataTable,
  },
  props: {
    data: Object,
    contrato: Object,
  },
  data() {
    return {
      arquivosEnviados: [],
      overlayLoading: false,
      loading: undefined,
      loadingLoad: false,
      dataFiles: new Array(),
      filesLoading: undefined,
      dataContratos: new Array(),
      showModalUploadManual: false,
      fieldsFilesLoading: undefined,
      fieldsContratos: [
        { name: "contrato", title: "Contrato" },
        { name: "pontos", title: "Pontos de Medição" },
        { name: "pontosRetaguarda", title: "Pontos de Retaguarda" },
        { name: "icons", title: "" },
      ],
      fieldsFiles: [
        { name: "nome", title: "Nome do Arquivo" },
        { name: "download", title: "" },
        { name: "delete", title: "" },
      ],
    };
  },
  mounted() {
    this.getInicialDataContratos();
  },
  methods: {
    close() {
      this.arquivosEnviados = [];
      this.dataFiles = [];
      this.getInicialDataContratos();
      this.showModalUploadManual = false;
    },
    getInicialDataContratos() {
      this.loading = ProinfaService.getPontosContrato({
        idContrato: this.contrato.id,
      }).then(({ data }) => (this.dataContratos = data));
    },
    calcularMedicoes() {
      this.loadingLoad = true;
      this.loading = ContratoService.calcularMedicoes({
        idContrato: this.contrato.id,
      })
        .then(() => {
          this.loadingLoad = false;
          this.mxToggleToast({
            message: CONFIRMATION.CALCULO_MEDICOES.CREATED,
          });
          setTimeout(() => {
            document.location.reload(true);
          }, 1000);
        })
        .catch((error) => {
          this.loadingLoad = false;
          if (error.response.data.message) {
            this.mxToggleErrorMessageAlert(error.response.data.message);
          } else {
            this.mxHandleRequestError();
          }
        });
    },
    async prevalidateFiles(files) {
      let data;
      try {
        const req = await UploadService.validarArquivosUploadManual({
          files,
          contratoId: this.contrato.id,
        });
        data = req.data;
      } catch (error) {
        this.overlayLoading = false;
        this.mxHandleRequestError(error);
        return;
      }
      const errosArquivos = [];
      for (let i = 0; i < data.length; i++) {
        const arquivo = data[i];
        if (arquivo.validationErrors) {
          const errosArquivo = {
            name: arquivo.nome,
            rows: [],
          };
          for (let j = 0; j < arquivo.validationErrors.length; j++) {
            const erro = arquivo.validationErrors[j];
            errosArquivo.rows.push({ row: erro.linha, errors: erro });
          }
          if (errosArquivo.rows.length) {
            errosArquivos.push(errosArquivo);
          }
        }
      }
      if (errosArquivos.length) {
        this.mxToggleFilesError(errosArquivos);
        throw new Error("Arquivos inválidos");
      }
    },
    async handleFiles(payload = new Array()) {
      this.overlayLoading = true;
      const files = await removeByteOrderMarkFromFiles(new Array(...payload));

      const bodyFormData = new FormData();

      files.forEach((file) => {
        bodyFormData.append("arquivos", file);
      });

      this.filesLoading = this.prevalidateFiles(bodyFormData);
      try {
        await this.filesLoading;
      } catch {
        return;
      }

      this.filesLoading = UploadService.uploadManual({
        idContrato: this.contrato.id,
        arquivos: bodyFormData,
      })
        .then(({ data: { pontosCalculados, arquivosEnviados } }) => {
          this.overlayLoading = false;
          this.arquivosEnviados = [
            ...this.arquivosEnviados,
            ...arquivosEnviados,
          ];
          pontosCalculados.forEach((ponto) => {
            this.dataContratos.forEach((pontoContrato) => {
              if (pontoContrato.pontos == ponto) {
                pontoContrato.isValid = true;
              }
            });
          });
          arquivosEnviados.map(({ nomeArquivo, historicoArquivoId }) => {
            const archiveExists = this.dataFiles.find(
              (file) => file.nome === nomeArquivo
            );

            if (!archiveExists) {
              this.dataFiles.push({
                nome: nomeArquivo,
                idHistoricoAquivo: historicoArquivoId,
              });
            }
          });
        })
        .catch((error) => {
          this.overlayLoading = false;
          const { response } = error;
          if (response) {
            const { status } = response;
            if (status === 422) {
              this.mxToggleErrorMessageAlert(response.data.message);
            }
          } else {
            this.mxHandleRequestError(error);
          }
        });
    },
    loadManual() {
      if (this.arquivosEnviados.length === 0) {
        return this.mxToggleErrorMessageAlert(
          "Nenhum arquivo válido para medição"
        );
      }
      let payload = { arquivos: [] };
      this.arquivosEnviados.forEach((arquivo) => {
        payload.arquivos.forEach((item, index) => {
          if (item.nomeArquivo === arquivo.nomeArquivo) {
            payload.arquivos.splice(index, 1);
          }
        });
        payload.arquivos.push(arquivo);
      });

      payload.arquivos.map((g) => {
        g.nome = g.nomeBlob;
        delete g.historicoArquivoId;
        delete g.nomeArquivo;
        delete g.nomeBlob;
        delete g.pontos;
        return g;
      });

      this.loadingLoad = true;
      UploadService.loadManual(payload)
        .then(() => {
          this.loadingLoad = false;
          this.calcularMedicoes();
        })
        .catch((error) => {
          this.loadingLoad = false;
          if (error.response.data.message) {
            this.mxToggleErrorMessageAlert(error.response.data.message);
          } else {
            this.mxHandleRequestError();
          }
        });
    },
    downloadArquivo(idHistoricoAquivo, fileName) {
      this.fieldsFilesLoading = UploadService.downloadArquivo({
        idHistoricoAquivo,
      })
        .then((response) => {
          downloadFileFromResponseObject(response, fileName);
          this.mxToggleToast({
            message: CONFIRMATION.UPLOAD_MANUAL.DOWNLOAD,
          });
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    excluirArquivo(idHistoricoAquivo) {
      this.fieldsFilesLoading = UploadService.deletarArquivo({
        idHistoricoAquivo,
      })
        .then(({ data }) => {
          const fileIndex = this.dataFiles.findIndex(
            (file) => file.idHistoricoAquivo == idHistoricoAquivo
          );

          if (fileIndex > -1) {
            this.dataContratos.map((contrato, index) => {
              const ponto = data.find(
                (namePonto) => contrato.pontos === namePonto
              );

              if (ponto) {
                this.dataContratos[index].isValid = false;
              }
            });

            this.dataFiles.splice(fileIndex, 1);
          }

          this.mxToggleToast({
            message: CONFIRMATION.UPLOAD_MANUAL.DELETE,
          });
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
  },
  computed: {
    IS_DISABLE_FINISH() {
      const isValidAllPoints = this.dataContratos.every(
        ({ isValid }) => isValid
      );
      return !this.dataFiles.length || !isValidAllPoints;
    },
    icon() {
      return {
        download: DownloadIcon,
        trasher: TrasherIcon,
        check: CheckIcon,
        exclamation: ExclamationIcon,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
._upload-manual {
  &__body {
    width: 100%;
    display: flex;
    font-size: 1rem;
    color: #2a2a2a;
    line-height: 29px;
    padding: 20px 30px;
    flex-direction: column;
  }
}

.box-upload {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.item-upload {
  width: calc(50% - 10px);
  display: flex;
  flex-direction: column;
}

.wrapper-buttons {
  width: 100%;
  display: flex;
  padding: 0 30px;
  text-align: right;
  justify-content: flex-end;
}
.btn-calcular {
  width: 300px !important;
}
</style>
