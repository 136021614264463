<!-- Aviso - Componente temporariamente incompleto, e não utilizado; -->
<template>
  <div>
    <input
      type="text"
      :id="id"
      :ref="id"
      :value="isFocused ? value : hourfyValue(value)"
      @input="value = $event.target.value"
      @focus="isFocused = true"
      @blur="isFocused = false"
      :disabled="disabled"
      :class="disabled ? 'disabled' : ''"
      :style="[{ backgroundImage: `url(${clockGreyIcon})` }, customStyle]"
    />
  </div>
</template>

<script>
import ClockGreyIcon from "@/assets/icons/svg/clock-grey.svg";

export default {
  data() {
    return {
      isFocused: false,
      // Value é usado como "1"
      value: "1",
    };
  },
  props: {
    // ValueProp Content vem como "01:00"
    valueProp: {
      type: String,
    },
    id: {
      type: String,
    },
    from: {
      type: Number,
      default: 1,
    },
    to: {
      type: Number,
      default: 22,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    clockGreyIcon: () => ClockGreyIcon,
  },
  methods: {
    hourfyValue(value) {
      if (!value) return null;
      return value.padStart(2, "0") + ":00";
    },
    unhourfyValue(value) {
      if (!value) return null;
      let newVal = value.replace(/[^0-9]/g, "").substring(0, 2);
      newVal = Number(newVal).toString();
      return newVal;
    },
  },
  watch: {
    valueProp() {
      this.value = this.unhourfyValue(this.valueProp);
    },
    value: {
      deep: true,
      handler() {
        this.$emit("input", this.hourfyValue(this.value));
      },
    },
    isFocused: {
      immediate: true,
      handler() {
        if (this.isFocused) return;

        if (!this.value || Number(this.value) < this.from) {
          this.value = String(this.from);
        } else if (Number(this.value) > this.to) {
          this.value = String(this.to);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  outline: 0;
  background-size: 15px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: 95% center;
  border-radius: 10px;
  border: 1px solid #b4b7ba;
  padding: 6px 12px;
  & .disabled {
    background-color: red;
    color: #b4b7ba;
  }
}
</style>
