<template>
  <div v-if="data.visivel">
    <modal-base
      maxWidth="60%"
      v-if="modalDgError.error"
      @closeModal="closeModal"
    >
      <template #header>Erro ao calcular o desconto garantido</template>
      <template #body>
        <div class="modal-body">
          <span>{{ modalDgError.message }}</span>
        </div>
      </template>
      <template #footer>
        <div class="modal-btn-wrapper">
          <button class="btn-primary-full-filled" @click="closeModal">
            Ok
          </button>
        </div>
      </template>
    </modal-base>
    <button
      class="btn"
      style="position: relative"
      :class="{
        'btn-action-primary': type == 'primary',
        'btn-action-secondary': type == 'secondary',
        'btn-action-secondary-fs': type == 'secondary-fs',
        'btn-action-secondary-outlined': type == 'secondary-outlined',
      }"
      @click="postCalcularDescontoGarantido()"
      :disabled="data.desabilitado"
    >
      <overlay-loader v-if="loading" />
      {{ data.descricao }}
    </button>
  </div>
</template>
<script>
import OverlayLoader from "@/components/OverlayLoader.vue";
import ModalBase from "@/components/modals/ModalBase.vue";
import AcaoBotaoContratoService from "@/services/AcaoBotaoContratoService";

export default {
  data() {
    return {
      loading: false,
      modalDgError: {
        error: false,
        message: "",
      },
    };
  },
  props: {
    data: Object,
    type: {
      type: String,
      default: "primary",
    },
    contrato: Object,
  },
  methods: {
    postCalcularDescontoGarantido() {
      this.loading = true;
      AcaoBotaoContratoService.calcularDescontoGarantido(
        this.data.url,
        this.data.metodo
      )
        .then(() => {
          this.loading = false;
          const { id: contratoId } = this.contrato;
          window.sessionStorage.setItem(
            "c-key",
            Buffer.from(contratoId.toString()).toString("base64")
          );
          document.location.reload(true);
        })
        .catch((error) => {
          this.loading = false;
          this.modalDgError = {
            error: true,
            message: error.response.data.message,
          };
        });
    },
    closeModal() {
      this.modalDgError = {
        error: false,
        message: "",
      };
    },
  },
  components: { OverlayLoader, ModalBase },
};
</script>

<style lang="scss" scoped>
.modal-body {
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-btn-wrapper {
  width: 100%;
  text-align: center;
  > * {
    padding: 0 20px;
  }
}
</style>
