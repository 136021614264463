<template>
  <div class="page-recompra-hotsite">
    <div class="box-choose-peoposal">
      <div class="box-header-proposal">PROPOSTA DE REDUÇÃO DE MONTANTES</div>
      <div class="box-content">
        <div class="content-icon">
          <img :src="mountImg('/images/icon-alert-triangulo.svg')" />
        </div>
        <div class="content-text">
          <div class="mb-4">
            <b>ATENÇÃO</b> Atendemos mais de uma unidade no seu contrato.
          </div>
          <div class="mb-2">
            Caso você queria receber uma Proposta de Redução de Montantes
            <b>com preço único</b> para todas as unidades, prossiga com a opção
            <b>"Preço Único"</b>.
          </div>
          <div class="mb-2">
            Caso você queria receber uma Proposta de Redução de Montantes com
            preços diferentes para cada unidade, prossiga com a opção
            <b>"Preços Diferentes"</b>
          </div>
          <div class="box-button">
            <button
              @click="chooseOption('PRECO_UNICO')"
              class="btn-action-primary mx-3"
            >
              PREÇO ÚNICO
            </button>
            <button
              @click="chooseOption('PRECOS_DIFERENTES')"
              class="btn-action-primary mx-3"
            >
              PREÇOS DIFERENTES
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    chooseOption(option) {
      this.$emit("tipoSelecionado", option);
    },
  },
};
</script>
<style lang="scss" scoped>
.page-recompra-hotsite {
  width: 100%;
  display: flex;
  background: #eee;
  align-items: center;
  flex-direction: column;
  height: calc(117.6vh - 116px);
}
.box-choose-peoposal {
  background: #ffffff;
  width: 70%;
  margin-top: 30px;
  min-height: 400px;
}
.box-header-proposal {
  display: flex;
  align-items: center;
  height: 40px;
  background: #929292;
  border-radius: 4px 4px 0px 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  padding-left: 20px;
}
.box-content {
  display: flex;
  //@include mobile {
  //    flex-direction: column;
  //}
}
.content-icon {
  padding: 36px 20px 20px 20px;
  //@include mobile {
  //    flex-direction: column;
  //     padding: 20px;
  //}
}
.content-text {
  text-align: left;
  padding: 41px 10px 0px 0;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #505050;
  //@include mobile {
  //   padding: 0px 20px 20px 20px;
  //   text-align: justify;
  //}
}
.box-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 52px 0 52px 0;
  margin-left: -30px;
  //@include mobile {
  //  margin-left:0px;
  //  flex-direction: column;
  //}
}
.btn {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  text-align: center;
  letter-spacing: 0.01em;
  background: #f86511;
  border-radius: 4px;
  color: #fff;
  padding: 8px 20px 8px 30px;
  //@include mobile {
  //  width: 200px;
  //  margin: 0px 0px 20px 0px !important;
  //}
  &:hover {
    background: rgba($color: #f86511, $alpha: 0.8);
    color: #fff;
  }
}
</style>
