<template>
  <div v-elementAsyncLoader="curtoPrazoLoading">
    <div class="row mt-4">
      <div class="col-lg-4">
        <card-base :widthCard="32">
          <template #header>
            <p class="title-card">Alçadas de Volume</p>
          </template>
          <template #body>
            <p class="description-card">
              Define os limites para geração de curto prazo automático via
              hotsite.
            </p>
            <div class="mwh-content">
              <p class="min_mwh">Mínimo (MWh)</p>
              <p class="max_mwh">Máximo (MWh)</p>
            </div>
            <hr class="line" style="boder: 2px black solid" />
            <div class="field-volume">
              <field-volume-min
                ref="valorMinimo"
                :modelProp="model"
                :disabled="!$can('CURTO_PRAZO_VISUALIZAR_EDITAR')"
              />
              <field-volume-max
                :modelProp="model"
                :disabled="!$can('CURTO_PRAZO_VISUALIZAR_EDITAR')"
              />
            </div>
            <hr class="line" style="border: 2px #f2edeb solid" />
          </template>
        </card-base>
      </div>
      <div class="col-lg-4">
        <card-base :widthCard="32">
          <template #header>
            <p class="title-card">Prazo de Spread</p>
          </template>
          <template #body>
            <p class="description-card">
              Ajuste quantas horas além do prazo do Spread você quer ter na
              validade de proposta de CP.
            </p>
            <div>
              <p class="cp-content">Tempo somado ao prazo (horas)</p>
            </div>
            <hr class="line" style="boder: 2px black solid" />
            <div class="field-cp-minutos">
              <field-curto-prazo-minutos
                :modelProp="model"
                :disabled="!$can('CURTO_PRAZO_VISUALIZAR_EDITAR')"
              />
            </div>
            <hr class="line" style="border: 2px #f2edeb solid" />
          </template>
        </card-base>
      </div>
      <div class="col-lg-4">
        <card-base :widthCard="32">
          <template #header>
            <p class="title-card">Data Final para gerar CP</p>
          </template>
          <template #body>
            <p class="description-card">
              Informe o último dia para criar proposta de curto prazo pelo
              hotsite enviado ao cliente.
            </p>
            <div>
              <p class="cp-content">Último dia para gerar CP</p>
            </div>
            <hr class="line" style="boder: 2px black solid" />
            <div class="field-cp">
              <field-cp
                :modelProp="model"
                :disabled="!$can('CURTO_PRAZO_VISUALIZAR_EDITAR')"
              />
            </div>
            <hr class="line" style="border: 2px #f2edeb solid" />
          </template>
        </card-base>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-6">
        <card-base>
          <template #header>
            <p class="title-card">Condições de Pagamento</p>
          </template>
          <template #body>
            <p class="description-card">
              Defina o texto que será apresentado ao cliente no campo acima
              quando receber uma proposta de curto prazo enviada pelo hotsite.
            </p>
            <field-condicao-pagamento
              :modelProp="model"
              :disabled="!$can('CURTO_PRAZO_VISUALIZAR_EDITAR')"
            />
          </template>
        </card-base>
      </div>
      <div class="col-lg-6">
        <card-base>
          <template #header>
            <p class="title-card">Multas e Juros</p>
          </template>
          <template #body>
            <p class="description-card">
              Defina o texto que será apresentado ao cliente no campo acima
              quando receber uma proposta de curto prazo enviada pelo hotsite.
            </p>
            <field-multa-juros
              :modelProp="model"
              :disabled="!$can('CURTO_PRAZO_VISUALIZAR_EDITAR')"
            />
          </template>
        </card-base>
      </div>
    </div>
    <div class="button-bar-parametrizacao">
      <div class="pt-2">
        <button
          @click="getBackToParametrizacaoHome()"
          class="btn btn-secondary-full-filled buttons"
          :disabled="!$can('CURTO_PRAZO_VISUALIZAR_EDITAR')"
          data-cy="button-cancelar_alteracoes_curto_prazo-parametrizacao-components"
        >
          Cancelar Alterações
        </button>
      </div>
      <div class="ps-4 pt-2">
        <button
          @click="editCurtoPrazo"
          class="btn btn-primary-full-filled buttons"
          :disabled="!$can('CURTO_PRAZO_VISUALIZAR_EDITAR')"
          data-cy="button-salvar_alteracoes_curto_prazo-parametrizacao-components"
        >
          Salvar Alterações
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import FieldCp from "./fields/FieldCp.vue";
import { CONFIRMATION } from "@/constants/strings";
import CurtoPrazoModel from "@/models/CurtoPrazoModel";
import CardBase from "@/components/cards/cardBase.vue";
import FieldVolumeMin from "./fields/FieldVolumeMin.vue";
import FieldVolumeMax from "./fields/FieldVolumeMax.vue";
import FieldMultaJuros from "./fields/FieldMultaJuros.vue";
import CurtoPrazoService from "@/services/CurtoPrazoService";
import FieldCondicaoPagamento from "./fields/FieldCondicaoPagamento.vue";
import FieldCurtoPrazoMinutos from "./fields/FieldCurtoPrazoMinutos.vue";
import FieldEnergiaConvencional from "./fields/FieldEnergiaConvencional.vue";

export default {
  data() {
    return {
      curtoPrazoLoading: undefined,
      model: new CurtoPrazoModel(),
    };
  },
  mounted() {
    this.listCurtoPrazo();
  },
  methods: {
    getBackToParametrizacaoHome() {
      this.$router.push({ name: "Parâmetros" });
    },
    listCurtoPrazo() {
      this.curtoPrazoLoading = CurtoPrazoService.getCurtoPrazo()
        .then(({ data }) => {
          this.model = new CurtoPrazoModel(data);
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "CURTO_PRAZO");
        });
    },
    editCurtoPrazo() {
      this.curtoPrazoLoading = CurtoPrazoService.postCurtoPrazo(
        this.model.getData()
      )
        .then(() => {
          this.mxToggleToast({
            message: CONFIRMATION.CURTO_PRAZO.UPDATE,
          });
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "CURTO_PRAZO");
        });
    },
  },
  components: {
    FieldCp,
    CardBase,
    FieldVolumeMin,
    FieldVolumeMax,
    FieldMultaJuros,
    FieldCondicaoPagamento,
    FieldCurtoPrazoMinutos,
    FieldEnergiaConvencional,
  },
};
</script>
<style lang="scss" scoped>
.buttons {
  width: 300px;
  height: 57px;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  color: #fffaf8;
  border-radius: 12px;
  letter-spacing: 1px;
  font-family: "Montserrat";
}
.mwh-content {
  display: flex;
}

.title-card {
  margin-bottom: 0px;
}

.description-card {
  font-size: 12px;
  margin-bottom: 10px;
}

.line {
  height: 2px;
  margin-top: 2px;
  margin-bottom: 4px;
}

.min_mwh {
  width: 50%;
}

.min_mwh,
.max_mwh,
.cp-content {
  margin: 0px;
  font-weight: bold;
  font-size: 0.8rem;
}

.field-volume {
  display: flex;
  justify-content: space-between;
}

.cards-container {
  display: flex;
  justify-content: space-around;
}

.behind-container {
  margin-top: 20px;
}

.button-bar-parametrizacao {
  display: flex;
  justify-content: flex-end;
}
</style>