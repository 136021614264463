<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <td>Tipo de Energia</td>
          <td>Spread Compra</td>
          <td>Spread Venda</td>
          <td>Preço compra</td>
          <td>Preço venda</td>
        </tr>
      </thead>
      <tbody>
        <tr
          class="_border-bottom"
          v-for="(tipoEnergia, tipoEnergiaIndex) in tiposEnergia"
          :key="`tipoEnergiaLine_${tipoEnergiaIndex}`"
        >
          <td>{{ tipoEnergia.descricao }}</td>
          <td>{{ tipoEnergia.ultimoSpread.compra | CURRENCY }}</td>
          <td>{{ tipoEnergia.ultimoSpread.venda | CURRENCY }}</td>
          <td>{{ tipoEnergia.ultimoSpread.precoCompra | CURRENCY }}</td>
          <td>{{ tipoEnergia.ultimoSpread.precoVenda | CURRENCY }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { CURRENCY } from "@/filters/formatters";
export default {
  filters: {
    CURRENCY,
  },
  props: {
    tiposEnergia: Array,
  },
};
</script>
<style lang="scss" scoped>
._border-bottom {
  border-bottom: solid 1px #eee;
}
</style>