<template>
  <div class="container-volume-field">
    <vue-numeric
      :disabled="disabled"
      :precision="precision"
      id="valorMinimoField"
      ref="valorminimoField"
      :decimal-separator="','"
      :thousand-separator="'.'"
      v-model.trim="model.content"
      class="w-100 input-pattern-border"
      :class="{ 'is-disabled': disabled }"
      data-cy="input-volume_min_curto-prazo"
    />
  </div>
</template>
<script>
import VueNumeric from "vue-numeric";
import AwesomeMask from "awesome-mask";
export default {
  props: {
    disabled: { type: Boolean },
    modelProp: { type: Object },
  },
  data() {
    return {
      model: {
        name: "valorMinimo",
        content: "",
      },
      precision: 3,
    };
  },
  watch: {
    modelProp: {
      deep: true,
      immediate: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
        this.precision = 3;
      },
    },
  },
  components: {
    VueNumeric,
  },
  directives: {
    mask: AwesomeMask,
  },
};
</script>
<style lang="scss" scoped>
.container-volume-field {
  width: 50%;
}

.is-disabled {
  color: #9c9ea1 !important;
  background: #f2edeb !important;
}
</style>
