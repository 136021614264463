<template>
  <div class="body-preco-unico-com-rateio">
    <div class="section-title">{{ GET_SECTION_TITLE }}</div>
    <data-table
      :card="true"
      :data="dataTable"
      :fields="fields.getTableFields()"
    />
  </div>
</template>

<script>
import DataTable from "@/components/tables/DataTable.vue";
import AprovacaoAnaliseRecompraPrecoUnico from "../AprovacaoAnaliseRecompraPrecoUnico.vue";
import { TableRecompraPrecoUnicoRateio } from "@/models/tablesModels/TableRecompraPrecoUnicoRateio";

export default {
  props: {
    dataTable: Array,
  },
  computed: {
    GET_SECTION_TITLE() {
      return `Contrato de Rateio - ${this.dataTable.length} filhos`;
    },
  },
  data() {
    return {
      fields: new TableRecompraPrecoUnicoRateio(),
    };
  },
  components: {
    DataTable,
    AprovacaoAnaliseRecompraPrecoUnico,
  },
};
</script>

<style lang="scss" scoped>
.body-preco-unico-com-rateio {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.section-title {
  width: 100%;

  padding: 7px 20px;
  margin: 10px 0px;

  background: #08132c;
  color: white;

  font-weight: 700;
  font-size: 16px;

  border-radius: 20px;
}
</style>
