<template>
  <modal-template
    @close="closeModal"
    data-cy="modal-fechar_modal_aprovacao-modais"
    ref="filesErrorModal"
    :styles="{ defaultModal: { zIndex: 6000 } }"
  >
    <template #header>Linhas inválidas</template>
    <template #body>
      <div class="body-container">
        <file-panel
          v-for="(file, i) in files"
          :key="i"
          :fileName="file.name"
          :startingOn="i === 0"
          :closeable="files.length > 1"
        >
          <div class="body-container__grid">
            <template v-for="(row, j) in file.rows">
              <template v-for="cell in [0, 1]">
                <span
                  v-if="cell === 0"
                  :class="`cell-${j % 2 === 0 ? 'even' : 'odd'}`"
                >
                  {{ row.row }}
                </span>
                <span
                  v-else-if="cell === 1"
                  :class="`cell-${j % 2 === 0 ? 'even' : 'odd'}`"
                >
                  {{ row.errors.errors.join(", ") }}
                </span>
              </template>
            </template>
          </div>
        </file-panel>
      </div>
    </template>
    <template #footer>
      <div class="btn-container-footer">
        <button
          class="btn btn-primary-outlined"
          style="border-radius: 12px"
          @click="$refs.filesErrorModal && $refs.filesErrorModal.close()"
        >
          Continuar
        </button>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from "@/components/modals/ModalTemplate.vue";
import FilePanel from "@/components/panels/FilePanel.vue";
import { mapActions } from "vuex";

export default {
  props: {
    files: Array,
  },
  methods: {
    ...mapActions(["ActionToggleFilesError"]),
    closeModal() {
      this.ActionToggleFilesError({ on: false, props: {} });
    },
  },
  components: {
    FilePanel,
    ModalTemplate,
  },
};
</script>

<style lang="scss" scoped>
.body-container {
  margin: 0 28px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__grid {
    display: grid;
    grid-template-columns: max-content 1fr;
    > span {
      padding: 8px;
      border-bottom: 1px solid #e0e0e0;
      &.cell-odd {
        background-color: #f5f5f5;
      }
    }
  }
}
.btn-container-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 16px 30px;
  justify-content: center;
}
</style>
