<template>
  <div class="form-justificativa">
    <div class="justificativa-title">ATENÇÃO !</div>
    <div class="justificativa-message">
      Preencha abaixo o motivo da contestação:
    </div>
    <div class="form-group">
      <textarea class="input" @input="change" v-model="$v.justificativa.$model">
      </textarea>
      <span v-if="$v.justificativa.$error" class="text-danger">
        O motivo da contestação é obrigatório para avançar
      </span>
    </div>
  </div>
</template>
<script>
import { email, required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      justificativa: "",
    };
  },
  methods: {
    change() {
      this.$emit("getJustificativa", this.justificativa);
    },

    send() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("finalizar");
      }
    },
  },
  validations: {
    justificativa: {
      required,
    },
  },
};
</script>
<style lang="scss" scoped>
.justificativa-title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}
.justificativa-message {
  margin-top: 10px;
  text-align: center;
}
.form-group {
  margin-bottom: 15px;
  textarea {
    width: 100%;
    padding: 5px 15px;
    border: none;
    border: solid 1px #ccc;
    border-radius: 5px;
    min-height: 150px;
  }
  .label {
    font-weight: bold;
  }
}
</style>
