<template>
  <div class="page">
    <div class="box-reduction-amount">
      <div  class="pt-4 pb-4">
        <div class="box-message-approve">
          <div class="box-message-icon">&#10004;</div>
          <div class="">
            PROPOSTA DE REDUÇÃO DE MONTANTES <b>APROVADA</b> POR
            <b>{{ APROVADOR }}</b>
            .
          </div>
        </div>
      </div>

      <div class="box-head">PROPOSTA DE REDUÇÃO DE MONTANTES</div>
      <div class="box-content">
        <div class="box-infos">
          <div style="width: 33.335" class="info-row">
            <div class="info-item">
              <div class="info-item-title">Submercado:</div>
              <div class="info-item-value">
                <b>{{
                  SUBMERCADO
                }}</b>
              </div>
            </div>
            <div class="info-item">
              <div class="info-item-title">Tipo de Energia:</div>
              <div class="info-item-value">
                <b>{{
                  ENERGIA
                }}</b>
              </div>
            </div>
          </div>
        </div>
        <div class="box-distribution-counter">
          <div class="counter-item"><b>Distribuição por Unidades</b></div>
        </div>
        <div class="grid-scroll">
          <div v-if="contratosFilhos.length > 0">
            <div class="box-grid">
              <div class="grid-header">
                <div style="padding-left: 30px" class="grid-cell">CNPJ</div>
                <div class="grid-cell">Razão Social</div>
                <div style="text-align: center" class="grid-cell">Preço</div>
                <div style="text-align: center" class="grid-cell">
                  Vol. Distribuído (MWh)
                </div>
              </div>
              <div
                v-for="(contract, indexContract) in contratosFilhos"
                :key="`indexContract${indexContract}`"
                class="grid-body"
              >
                <div style="padding-left: 30px" class="grid-cell">
                  {{ contract.cnpj | cnpj }}
                </div>
                <div class="grid-cell">{{ contract.razaoSocial }}</div>
                <div style="text-align: center" class="grid-cell">
                  <span>R$ {{ contract.precoOriginal | moeda }} </span>
                </div>
                <div style="text-align: center" class="grid-cell">
                  <span>{{ contract.volumeRecompraFinal | volume }}</span>
                </div>
              </div>
              <div v-if="RATEIO" class="grid-total">
                <div style="padding-left: 30px" class="grid-cell">TOTAL</div>
                <div class="grid-cell"></div>
                <div class="grid-cell"></div>
                <div
                  style="padding-left: 5px; text-align: center"
                  class="grid-cell"
                >
                  {{ parseFloat(TOTAL_VOLUME_DIST)|volume }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DemonstrativoService from '@/services/DemonstrativoService';
import bigDecimal from 'js-big-decimal';
export default {
  
  props: {
    contratoPrincipal:Object,
    demonstrativo:Object
  },
  computed:{
    RATEIO(){
      return this.contratoPrincipal.rateio
    },
    SUBMERCADO(){
      return this.contratoPrincipal &&
      this.contratoPrincipal.submercado ?
      this.contratoPrincipal.submercado.descricao : ''
    },
    ENERGIA(){
      return this.contratoPrincipal &&
      this.contratoPrincipal.tipoEnergia ?
      this.contratoPrincipal.tipoEnergia.descricao : ''
    },
    APROVADOR:function(){
      return  this.demonstrativo &&  
      this.demonstrativo.situacaoDemonstrativo   ? 
      this.demonstrativo.situacaoDemonstrativo.email : ''
    },

    TIPO:function(){
      return  this.demonstrativo &&  
      this.demonstrativo.recompraDemonstrativo   ? 
      this.demonstrativo.recompraDemonstrativo.tipo : ''
    },
    TOTAL_VOLUME_DIST() {
      if (this.contratosFilhos.length > 0) {
        const listVolumeRecompraFinal = this.contratosFilhos
          .filter((contrato) => contrato.volumeRecompraFinal)
          .map(({ volumeRecompraFinal }) => volumeRecompraFinal);
        return listVolumeRecompraFinal.reduce(
          (total, numero) => bigDecimal.add(total, numero),
          0
        );
      }
      return 0;
    },
  },

  
  data() {
    return {
      total: 0,
      contratosFilhos:[],
      data: [
        {
          isAgrupador: true,
          email: "",
        },
      ],
    };
  },
  mounted() {
    


    this.getDataAproved();
  },
  methods: {
    
    getDataAproved() {
      DemonstrativoService.getHotsiteRecompraBuscarRateio({contratoId:this.contratoPrincipal.id}).then(response=>{
        this.contratosFilhos = response.data
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(117.6vh - 86px);
}
.breadcrumbs {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #505050;
  background: #ffffff;
  border-radius: 5px;
  width: 80%;
  display: flex;
  justify-items: center;
  padding: 5px 0 5px 20px;
  margin-top: 30px;
  margin-bottom: 39px;
  flex-wrap: wrap;
}
.box-reduction-amount {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 80%;
}
.box-head {
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: #929292;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 0 8px 20px;
  display: flex;
  align-items: center;
}
.box-content {
  padding: 20px;
}

.box-infos {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 27px 0px 27px 0px;
}
.info-row {
  display: flex;
  justify-content: flex-start;
  @include tablet {
    flex-direction: column;
  }
}
.info-item {
  width: 33.33%;
  padding-left: 70px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #505050;
  @include tablet {
    width: 100%;
    text-align: center;
    padding-left: 0px;
    margin-bottom: 20px;
  }
}
.info-item-title {
  margin-bottom: 2px;
}
.info-item-value {
  margin-top: 2px;
}
.numeric-field-grid {
  background: #ebebeb;
  border-radius: 3px;
  border: none;
  width: 100px;
  text-align: center;
  &:disabled {
    background: #c4c4c4;
    color: #c4c4c4;
  }
}
.box-distribution-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #505050;
  margin-top: 41px;
  margin-bottom: 25px;
  @include tablet {
    flex-direction: column;
  }
}
.counter-item {
  @include tablet {
    margin-bottom: 10px;
  }
}
.grid-scroll {
  overflow: auto;
}
.box-grid {
  min-width: 800px;
  max-height: 400px;
}
.grid-header {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #000000;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #929292;
  padding: 7px 0 7px 0;
}
.grid-body {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #505050;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 7px 0 7px 0;
}
.grid-total {
  display: flex;
  justify-content: space-between;
  background: #e8e8e8;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #505050;
  padding: 7px 0 7px 0;
}
.grid-cell {
  width: 25%;
  padding-right: 30px;
}
.box-buttons {
  margin-top: 31px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.btn-orange {
  width: 174px;
  height: 42px;
  background: #f86511;
  border-radius: 4px;
  color: #fff;
  &:hover {
    background: rgba($color: #f86511, $alpha: 0.8);
    color: #fff;
  }
  &:disabled {
    background: rgba($color: #ccc, $alpha: 0.8);
    cursor: not-allowed;
  }
}
.btn-border {
  width: 174px;
  height: 42px;
  border: 1px solid #505050;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
}
.color-red {
  color: red;
  font-weight: bold;
}

.contract-pai {
  background: #eee;
}
.color-crump {
  color: #f86511;
}

.box-message-approve {
  padding: 10px 0 10px 0px;
  background: #ffffff;
  border: 1px solid #2ed47a;
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.box-message-icon {
  color: #2ed47a;
  font-size: 20px;
  min-width: 60px;
  text-align: center;
}
</style>