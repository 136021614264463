<template>
  <div class="filepanel-container">
    <h2
      @click="closeable && (on = !on)"
      class="filepanel-container__header"
      :style="{
        borderRadius: on ? '12px 12px 0 0' : '12px',
      }"
    >
      <span class="filepanel-container__header__title">
        <span class="icon-file" />
        {{ fileName }}
      </span>
      <span
        class="icon-arrow-down"
        v-if="closeable"
        :style="{
          transform: on ? 'rotate(180deg)' : 'rotate(0deg)',
        }"
      />
    </h2>
    <div v-if="on">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      on: this.startingOn,
    };
  },
  props: {
    startingOn: {
      type: Boolean,
      default: false,
    },
    fileName: {
      type: String,
      default: "",
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.filepanel-container {
  &__header {
    background-color: $color--primary;
    color: white;
    padding: 12px;
    display: flex;
    margin: 0;
    justify-content: space-between;
    &__title {
      font-size: 16px;
      font-weight: 600;
      display: flex;
      gap: 8px;
    }
    & .icon-arrow-down {
      font-size: 16px;
    }
  }
}
</style>
