const pages = {
  state: {
    pendencies: new Array(),
  },
  getters: {
    getPendencies(state) {
      return state.pendencies;
    },
  },
  mutations: {
    toggleDangerAlert(state, { message, showButton }) {
      if (!state.pendencies.some((pendency) => pendency.message === message)) {
        state.pendencies.push({
          type: "alert-danger",
          props: { message, showButton },
        });
      }
    },
    resetPendencyByIndex(state, pendencyIndex) {
      state.pendencies.splice(pendencyIndex, 1);
    },
    resetPendencies(state, type) {
      if (!type) {
        state.pendencies = new Array();
        return;
      }
      state.pendencies = state.pendencies.filter(
        (pendency) => pendency.type !== type
      );
    },
  },
  actions: {
    ActionToggleDangerAlert(context, value) {
      context.commit("toggleDangerAlert", value);
    },
    ActionResetPendencyByIndex(context, value) {
      context.commit("resetPendencyByIndex", value);
    },
    ActionResetPendencies(context, value) {
      context.commit("resetPendencies", value);
    },
  },
};

export default pages;
