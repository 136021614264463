export default class PontosMedicaoModel {
  constructor(
    {
      id,
      cnpj,
      status,
      rateio,
      pontos,
      apelido,
      contrato,
      referencia,
      tipoClasse,
      razaoSocial,
      pontoMedicao,
      pontoHabilitado,
      percentualPerda,
      percentualFatorAtendimento,
    } = new Object()
  ) {
    this._id = id;
    this._cnpj = cnpj;
    this._status = status;
    this._rateio = rateio;
    this._pontos = pontos;
    this._apelido = apelido;
    this._contrato = contrato;
    this._tipoClasse = tipoClasse;
    this._referencia = referencia;
    this._razaoSocial = razaoSocial;
    this._pontoMedicao = pontoMedicao;
    this._pontoHabilitado = pontoHabilitado;
    this._percentualPerda = percentualPerda;
    this._percentualFatorAtendimento = percentualFatorAtendimento;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id = null) {
    this._id = id;
  }

  get tipoClasse() {
    return this._tipoClasse;
  }

  set tipoClasse(tipoClasse) {
    this._tipoClasse = tipoClasse ?? null;
  }

  get pontoMedicao() {
    return this._pontoMedicao;
  }

  set pontoMedicao(pontoMedicao) {
    this._pontoMedicao = pontoMedicao;
  }

  get razaoSocial() {
    return this._razaoSocial ?? null;
  }

  set razaoSocial(razaoSocial = null) {
    this._razaoSocial = razaoSocial;
  }

  get apelido() {
    return this._apelido ?? null;
  }

  set apelido(apelido = null) {
    this._apelido = apelido;
  }

  get cnpj() {
    return this._cnpj ?? null;
  }

  set cnpj(cnpj = null) {
    this._cnpj = cnpj;
  }

  get contrato() {
    return this._contrato ?? null;
  }

  set contrato(contrato = null) {
    this._contrato = contrato;
  }

  get referencia() {
    return this._referencia ?? null;
  }

  set referencia(referencia = null) {
    this._referencia = referencia;
  }

  get pontos() {
    return this._pontos ?? null;
  }

  set pontos(pontos = null) {
    this._pontos = pontos;
  }

  get pontoHabilitado() {
    return this._pontoHabilitado ?? null;
  }

  set pontoHabilitado(pontoHabilitado = null) {
    this._pontoHabilitado = pontoHabilitado;
  }

  get percentualFatorAtendimento() {
    return this._percentualFatorAtendimento ?? null;
  }

  set percentualFatorAtendimento(percentualFatorAtendimento = null) {
    this._percentualFatorAtendimento = percentualFatorAtendimento;
  }

  get percentualPerda() {
    return this._percentualPerda ?? null;
  }

  set percentualPerda(percentualPerda = null) {
    this._percentualPerda = percentualPerda;
  }

  get status() {
    return this._status ?? null;
  }

  set status(status = null) {
    this._status = status;
  }

  get rateio() {
    return this._rateio ?? null;
  }

  set rateio(rateio = null) {
    this._rateio = rateio;
  }

  getData() {
    const {
      id,
      cnpj,
      pontos,
      status,
      rateio,
      apelido,
      contrato,
      referencia,
      tipoClasse,
      razaoSocial,
      pontoMedicao,
    } = this;

    return {
      id,
      cnpj,
      pontos,
      status,
      rateio,
      apelido,
      contrato,
      referencia,
      tipoClasse,
      razaoSocial,
      pontoMedicao,
    };
  }
}
