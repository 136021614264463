<template>
  <div class="mt-1">
    <div
      class="alert alert-dismissible fade show alert-danger"
      :style="{ 'margin-top': `${index * 3.5}rem` }"
    >
      <div v-html="message" />
      <button
        type="button"
        class="btn-close"
        v-if="showButton"
        data-cy="button-alert_message-alert-message"
        @click="ActionResetPendencyByIndex(index)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    message: String,
    showButton: Boolean,
    index: Number,
  },
  methods: {
    ...mapActions(["ActionResetPendencyByIndex"]),
  },
};
</script>

<style lang="scss" scoped>
.alert {
  left: 10%;
  width: 80%;
  z-index: 9999;
  position: absolute;
  margin-bottom: 0 !important;
}
</style>
