<template>
  <div class="page-dashboards">
    <page-painel>
      <template v-if="PAGE_TITLE" #header>
        <div>{{ PAGE_TITLE }}</div>
      </template>
      <template #body>
        <DashboardPBI
          v-if="showDashboard"
          :dashboardObject="dashboardObject"
          @goToCardList="goToCardList"
        />
        <div class="page-dashboards__body" v-else>
          <div class="wrapper">
            <card-base-dashboards
              :key="`card_${index}`"
              @click.native="selectDashboard(dashboard)"
              :title="dashboard.reportName | formatName"
              v-for="(dashboard, index) in getPermissoesPowerBI"
              data-cy="card-base-dashboarda-pages-dashboards"
            />
          </div>
        </div>
      </template>
    </page-painel>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PagePainel from "@/components/panels/PagePanel.vue";
import DashboardPBI from "@/pages/dashboards/components/DashboardPBI.vue";
import CardBaseDashboards from "@/components/cards/cardBaseDashboards.vue";

export default {
  data() {
    return {
      showDashboard: false,
      dashboardObject: null,
    };
  },
  filters: {
    formatName(value) {
      if (value) {
        return (
          {
            PORTFÓLIO: "Portfólio",
            "POS VENDAS": "Pós-Vendas",
          }[value.toUpperCase()] || value
        );
      }
      return value;
    },
  },
  components: {
    PagePainel,
    CardBaseDashboards,
    DashboardPBI,
  },
  computed: {
    ...mapGetters(["getPermissoesPowerBI"]),
    PAGE_TITLE() {
      if (this.dashboardObject && this.dashboardObject.reportName) return;
      return "Dashboards";
    },
  },
  methods: {
    selectDashboard(dashboardObject) {
      this.showDashboard = true;
      this.dashboardObject = dashboardObject;
    },
    goToCardList() {
      this.showDashboard = false;
      this.dashboardObject = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-dashboards {
  padding-top: 24px;
  &__body {
    padding: 30px;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  grid-column-gap: 40px;
  grid-row-gap: 25px;
  height: 100%;
}

.panel-parametrizacao {
  min-height: 85vh;
}
</style>
