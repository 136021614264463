<template>
  <div
    v-if="row.ultimoDetalhe && row.ultimoDetalhe.descricao"
    class="table-details"
  >
    <div
      :style="`background:${row.ultimoDetalhe.hex};
                    color:${
                      details.filter(
                        (g) => g.name === row.ultimoDetalhe.descricao
                      )[0].color
                    }
            
            `"
      class="table-details__tag"
    >
      {{ row.ultimoDetalhe.descricao }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      details: [
        {
          name: "ccee indisponível",
          bg: "#ECD823",
          color: "#00000",
        },
        {
          name: "envio via hotsite",
          bg: "#1F4496",
          color: "#FFFFFF",
        },
        {
          name: "envio manual",
          bg: "#1F4496",
          color: "#FFFFFF",
        },
        {
          name: "ponto sem acesso",
          bg: "#EC2F23",
          color: "#ffffff",
        },
        {
          name: "contrato s/ ponto",
          bg: "#EC2F23",
          color: "#ffffff",
        },
        {
          name: "ponto s/ proinfa",
          bg: "#EC2F23",
          color: "#ffffff",
        },

        {
          name: "s/ ponto e proinfa",
          bg: "#EC2F23",
          color: "#ffffff",
        },
        {
          name: "recompra aprov.",
          bg: "#78B82A",
          color: "#00000",
        },
        {
          name: "take mín. aprov.",
          bg: "#78B82A",
          color: "#00000",
        },
        {
          name: "sem recompra",
          bg: "#78B82A",
          color: "#00000",
        },
        {
          name: "com recompra",
          bg: "#78B82A",
          color: "#00000",
        },
        {
          name: "volume negativo",
          bg: "#EC2F23",
          color: "#ffffff",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.table-details {
  &__tag {
    padding: 3px 5px;
    background: #eee;
    border-radius: 5px;
    font-weight: 700;
    font-size: 10px;
    text-align: center;
    line-height: 150%;
    //letter-spacing: 1px;
    color: #000000;
  }
  &__tag--yellow {
    background: #ecd823;
    color: #000;
  }
}
</style>