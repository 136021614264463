<template>
  <div class="main-content access-denied-children-scroll">
    <div class="container-about">
      <div class="row">
        <div class="mt-5">
          <h2>Você não tem permissão para acessar o Billing.</h2>
          <h2>Entre em contato com algum administrador.</h2>
          <button class="mt-5 btn btn-primary-full-filled" @click="logout">
            Sair
          </button>
        </div>
      </div>
      <div class="container-image">
        <img
          src="@/assets/icons/svg/heliceFundo.svg"
          alt="Imagem de um parque"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { logoutFromSSO } from "@/utils/authentication";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions([
      "ActionSetToken",
      "ActionSetUsuario",
      "ActionSetPermissoes",
      "ActionResetPendencies",
      "ActionSetPermissoesPowerBI",
    ]),
    logout() {
      this.ActionSetToken(null);
      this.ActionSetUsuario(null);
      this.ActionSetPermissoes(null);
      this.ActionResetPendencies(null);
      this.ActionSetPermissoesPowerBI(null);
      logoutFromSSO();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/mixins.scss";
@import "@/assets/style/breakpoints.scss";

.access-denied-children-scroll {
  text-align: center;
  padding: 0 !important;
  height: calc(100vh - 63px - 1.5rem);
}

h1 {
  font-weight: 400;
}

.main-content {
  height: 100vh;
  background-color: #ffffff;
}

.container-about {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  justify-content: space-between;

  .container-image {
    width: 100%;
    display: flex !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
