<template>
  <div class="wrapper-button-action" v-if="data.visivel">
    <button
      class="btn"
      :class="[
        {
          'btn-action-primary': type == 'primary',
          'btn-action-secondary': type == 'secondary',
          'btn-action-secondary-outlined': type == 'secondary-outlined',
        },
      ]"
      @click="sendFaturamento()"
      :disabled="data.desabilitado || loading"
      data-cy="button-acao_faturamento-modais"
    >
      <overlay-loader :customStyle="{ width: '60px' }" v-if="loading" />
      {{ data.descricao }}
    </button>
    <div
      class="btn-tooltip"
      v-if="contrato.statusAtual.descricao === 'Cadastro Pendente'"
    >
      <div class="btn-tooltip__arrow"></div>
      <div class="btn-tooltip__body">
        Resolva as pendências deste contrato para seguir com o faturamento:<br />
        - Associe ponto de Medição<br />
        - Cadastre PROINFA
      </div>
    </div>
  </div>
</template>

<script>
import { CONFIRMATION } from "@/constants/strings";
import AcaoBotaoContratoService from "@/services/AcaoBotaoContratoService";
import { Buffer } from "buffer";

export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    data: Object,
    type: {
      type: String,
      default: "primary",
    },
    contratosFilhosRedis: Object,
    contrato: Object,
  },
  data() {
    return {
      loading: undefined,
    };
  },
  methods: {
    postAcao() {
      this.loading = true;
      AcaoBotaoContratoService.postAcaoFaturamento(
        this.data.url,
        this.data.metodo
      )
        .then(() => {
          this.loading = false;
          if (this.data.acao == "finalizar_faturamento") {
            this.$router.push({ path: "/faturamento" });
          } else {
            const { id: contratoId } = this.contrato;
            window.sessionStorage.setItem(
              "c-key",
              Buffer.from(contratoId.toString()).toString("base64")
            );
            document.location.reload(true);
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            const { status } = error.response;
            if (status === 406) {
              this.mxToggleWarningModal({
                message: CONFIRMATION.FATURAMENTO.CANT_FOLLOW,
                btnTrue: "Prosseguir",
                showBtnFalse: false,
              }).then(() => document.location.reload(true));
            }
          } else {
            this.mxHandleRequestError(error);
          }
        });
    },
    concluirCalculo() {
      if (this.contratosFilhosRedis.dif === 0 || !this.contrato.rateio) {
        this.loading = true;
        var payload = null;
        if (this.contrato.rateio) {
          payload = this.contratosFilhosRedis.contratos;
        } else {
          payload = [
            {
              idCalculo: this.contrato.calculoFaturamento.id,
              volumeLiquido: this.contrato.calculoResultado.solicitadoLiquido,
            },
          ];
        }
        AcaoBotaoContratoService.concluirCalculo(
          this.data.url,
          this.data.metodo,
          payload
        )
          .then(() => {
            this.loading = false;
            const { id: contratoId } = this.contrato;
            window.sessionStorage.setItem(
              "c-key",
              Buffer.from(contratoId.toString()).toString("base64")
            );
            document.location.reload(true);
          })
          .catch((error) => {
            this.loading = false;
            if (error.response) {
              const { status } = error.response;
              if (status === 406) {
                this.mxToggleWarningModal({
                  message: CONFIRMATION.FATURAMENTO.CANT_FOLLOW,
                  btnTrue: "Prosseguir",
                  showBtnFalse: false,
                }).then(() => document.location.reload(true));
              } else if (status === 400) {
                this.mxToggleErrorMessageAlert(error.response.data.message);
              }
            } else {
              this.mxHandleRequestError(error);
            }
          });
      } else {
        return this.mxToggleErrorMessageAlert(
          "A soma do solicitado líquido dos contratos filhos de ser igual ao solicitado líquido do contratos pai."
        );
      }
    },
    sendFaturamento() {
      if (this.data.acao == "assumir_faturamento") {
        this.mxToggleWarningModal({
          message: CONFIRMATION.ACAO_FATURAMENTO_BUTTON.CONTINUE_BILLING,
          btnTrue: "Sim, continuar",
          btnFalse: "Cancelar",
        }).then(() => this.postAcao());
      } else if (this.data.acao == "finalizar_faturamento") {
        this.mxToggleWarningModal({
          message: CONFIRMATION.ACAO_FATURAMENTO_BUTTON.FINALIZED_BILLING,
          btnTrue: "Finalizar Faturamento",
          btnFalse: "Cancelar",
        }).then(() => this.postAcao());
      } else if (this.data.acao === "recalcular_faturamento") {
        this.mxToggleWarningModal({
          message: CONFIRMATION.ACAO_FATURAMENTO_BUTTON.STATUS_AFTER_SALES,
          btnTrue: "Sim, continuar",
          btnFalse: "Cancelar",
          title: "Recalcular Faturamento",
        }).then(() => this.postAcao());
      } else if (this.data.acao === "concluir_calculo") {
        this.concluirCalculo();
      } else {
        this.postAcao();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-button-action {
  position: relative;

  &:hover > .btn-tooltip {
    display: flex;
  }
}

.btn-medicoes {
  width: 230px !important;
  margin-left: 20px !important;
}

.btn-tooltip {
  left: 0;
  top: 50px;
  color: #000;
  z-index: 2000;
  display: none;
  background: #ff0;
  position: absolute;
  align-items: center;
  flex-direction: column;
}

.btn-tooltip__arrow {
  z-index: 10;
  width: 50px;
  height: 50px;
  text-align: center;
  position: relative;
  background: #bdbdbd;
  transform: rotate(45deg);
}

.btn-tooltip__body {
  z-index: 11;
  width: 100%;
  padding: 10px;
  padding: 10px;
  font-size: 16px;
  color: #2a2a2a;
  font-weight: 300;
  margin-top: -50px;
  line-height: 150%;
  position: relative;
  border-radius: 4px;
  background: #bdbdbd;
  opacity: 1 !important;
}
</style>
