<template>
  <div class="_collapsible-card" v-elementAsyncLoader="loading">
    <div
      @click="toggle"
      :active="open"
      :class="{ borderClose: !open }"
      class="_collapsible-card__header"
      data-cy="div-open_toggle_collapsible-card"
      :style="[{ backgroundColor: colorHeader, borderColor: colorHeader }]"
    >
      <i
        class="icon icon-arrow-down me-3"
        :class="open ? 'turn-down' : 'turn-up'"
      ></i>
      <slot name="title"></slot>
    </div>
    <div v-show="open" class="_collapsible-card__body">
      <slot name="body"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loading: Promise,
    colorHeader: {
      type: String,
      default: "#0F214A",
    },
    initialOpen: {
      type: Boolean,
      default: false,
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    this.open = this.initialOpen;
  },
  methods: {
    toggle() {
      this.open = !this.open;
      this.$emit("handleIcon", this.itemIndex);
    },
  },
};
</script>
<style lang="scss" scoped>
._collapsible-card {
  //cursor: pointer;

  .turn-down {
    transform: rotate(-180deg);
    transition: transform 0.2s linear;
  }
  .turn-up {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }

  .icon {
    font-size: 1rem;
  }
  &__header {
    cursor: pointer;
    display: flex;
    font-weight: 300;
    padding: 5px 15px;
    font-size: 1.312rem;
    align-items: center;
    border-radius: 24px 24px 0 0;
    color: $color--primary-white;
    border: 1px solid $color--blue-super-dark;
  }
  &__body {
    border: 1px solid #c4c4c4;
    padding: 20px;
    border-radius: 0 0 24px 24px;
  }
  .borderClose {
    border-radius: 24px;
  }
  .borderClose {
    border-radius: 24px;
  }
}
</style>
