<template>
  <div class="body-extrato-resumido">
    <div class="container-extrato-resumido">
      <div class="header">
        <span>Extrato Resumido</span>
      </div>
      <main>
        <div class="info-section">
          <span>Volume a faturar</span>
          <span>{{ extrato.volumeAFaturar | VOLUME }} MWh</span>
        </div>
        <div class="info-section">
          <span>Preço sem ICMS (R$/MWh)</span>
          <span>{{ extrato.precoSemIcms | PRECO_ICMS }}</span>
        </div>
        <div class="info-section">
          <span>Tipo de Energia</span>
          <span>{{ extrato.tipoEnergia }}</span>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { VOLUME, PRECO_ICMS } from "@/filters/formatters";

export default {
  props: {
    extrato: {
      type: Object,
      default() {
        return {
          volumeAFaturar: 0,
          precoSemIcms: 0,
          tipoEnergia: "",
        };
      },
    },
  },
  mounted() {
    const titleModal = "Enviar E-mail - Cálculo Finalizado";

    this.$emit("handleTitleModal", titleModal);
  },
  filters: {
    VOLUME,
    PRECO_ICMS,
  },
};
</script>

<style lang="scss" scoped>
.body-extrato-resumido {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}
.container-extrato-resumido {
  display: flex;
  flex-direction: column;

  width: 80%;
  height: 200px;

  margin-top: 40px;

  border: 1px solid #2a2a2a;
}

.header {
  margin: 5px 10px;

  border-bottom: 1px solid #2a2a2a;

  span {
    font-weight: 700;
    font-size: 16px;
  }
}

main {
  display: flex;
  flex-direction: column;

  margin: 5px 10px;
}

.info-section {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 40px;

  span {
    width: 300px;

    font-size: 16px;
    font-weight: 300;
  }
}
</style>
