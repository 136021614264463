import Services from "./Services";
import HistoricoFaturamentoFechadoModel from "@/models/faturamento/HistoricoFaturamentoFechadoModel";

class FaturamentosFechadosService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  async getHistoricoFaturamentosFechados(request) {
    this._url = "";
    const response = await this.get(request);
    response.data = response.data.map((historicoFaturamentoFechado) => {
      return new HistoricoFaturamentoFechadoModel(historicoFaturamentoFechado);
    });
    return response;
  }

    /**
   * @summary Post the Faturamento atual
   * @param {Object} request
   * @endpoint /api/fechamento
   */
  fecharFaturamentoAtual(request) {
    this._url = "";
    return this.post(request);
  }

  /**
   * @summary Get the Faturamento atual
   * @param {Object} request
   * @endpoint /api/fechamento/atual
   */
   getCurrentFaturamento(request) {
    this._url = "/atual";
    return this.get(request);
  }
}

export default new FaturamentosFechadosService("/fechamento");
