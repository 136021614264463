<template>
  <div>
    <div class="box-cards">
      <div class="cards-line">
        <div class="_card">
          <div class="_card_title">Energia Medida Verificada</div>
          <div class="_card_body">
            <div class="_card_logo">
              <img :src="mountImg('/images/scde.svg')" />
            </div>
            <div class="_card_value">
              {{ contrato.calculoSumarizado.montanteScde | volume }} MWh
            </div>
          </div>
        </div>
        <div class="_card">
          <div class="_card_title">Energia Contratada Mensal</div>
          <div class="_card_body">
            <div class="_card_logo">
              <img :src="mountImg('/images/faturar.svg')" />
            </div>
            <div class="_card_value">
              {{ contrato.calculoSumarizado.volumeBruto | volume }} MWh
            </div>
          </div>
        </div>
      </div>

      <div class="cards-line">
        <div class="_card_3">
          <div class="_card_title">Fatura sem ICMS</div>
          <div class="_card_body">
            <div class="_card_logo">
              <img :src="mountImg('/images/preco.svg')" />
            </div>
            <div class="_card_value">
              {{
                currency(contrato.calculoSumarizado.volumeBruto *
                  contrato.calculoSumarizado.precoSemIcms)
              }}
            </div>
          </div>
        </div>

        <div class="_card_3">
          <div class="_card_title">Preço sem ICMS (R$/MWh)</div>
          <div class="_card_body">
            <div class="_card_logo">
              <img :src="mountImg('/images/preco.svg')" />
              <!--<img :src="mountImg('/images/scde.svg')" />-->
            </div>
            <div class="_card_value">
              {{currency(contrato.calculoSumarizado.precoSemIcms)}}
            </div>
          </div>
        </div>
        <div class="_card_3">
          <div class="_card_title">Tipo de Energia</div>
          <div class="_card_body">
            <div class="_card_logo">
              <img :src="mountImg('/images/energia.svg')" />
            </div>
            <div class="_card_value">
              <span style="text-transform: uppercase">{{
                contrato.tipoEnergia.descricao
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CURRENCY } from '@/filters/formatters';
export default {
  props: {
    contrato: Object,
  },
  methods: {
    truncDecimals(value, digits) {
      var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
        m = value.toString().match(re);
      return m ? parseFloat(m[1]) : value.valueOf();
    },
    currency(value){
      return CURRENCY(value)
    }
  },
  filters: {
    energia: (value) => {
      if (!value) return "";
      return value;
    },
  },
};
</script>
<style lang="scss" scoped>
.box-cards {
  margin-top: 10px;
}
.cards-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  @include tablet {
    flex-direction: column;
    margin-bottom: 0px;
  }
}
._card {
  background: #ffffff;
  height: 150px;
  width: calc(50% - 13px);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  @include tablet {
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
  }
}
._card_3 {
  background: #ffffff;
  height: 150px;
  width: calc(33% - 13px);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  @include tablet {
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
  }
}
.box-take {
  width: 100%;
  height: 140px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background: #ffffff;
}
._card_title {
  border-bottom: solid 1px #ebeff2;
  padding: 10px;
  padding-left: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #192a3e;
}
._card_body {
  display: flex;
  justify-content: space-between;
  height: 107px;
  align-items: center;
}
._card_logo {
  padding: 0 20px 0 20px;
}
._card_value {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #f86511;
  //@include mobile {
  //  font-size: 20px;
  //  text-align: center;
  //}
}
.wrapper-takes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 98px;
  color: #f86511;
  //@include mobile {
  //  flex-direction: column;
  //  min-width: 330px;
  //}
}
.box-take-min {
  width: 50%;
  display: flex;
  border-right: dashed 1px #eee;
  height: 100%;
  align-items: center;
  color: #f86511;
  // @include mobile {
  //  width: 100%;
  //}
}
.box-take-max {
  width: 50%;
  display: flex;
  height: 100%;
  align-items: center;
  //@include mobile {
  //  width: 100%;
  //}
}
.take-description {
  padding-left: 15px;
  width: 50%;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #929292;
  //@include mobile {
  //  font-size: 14px;
  //}
}
.take-value {
  width: 50%;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #f86511;
  //@include mobile {
  //  text-align: center;
  //}
}
</style>