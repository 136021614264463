<template>
  <div
    v-if="row.ultimoDetalhe && row.ultimoDetalhe.descricao"
    class="table-details"
  >
    <span
      :style="{
        background: row.ultimoDetalhe.hex,
        color: getColor(row),
      }"
      class="table-details__tag"
    >
      {{ row.ultimoDetalhe.descricao }}
    </span>
  </div>
</template>

<script>
import DetalhesContratoENUM from "@/enums/DetalhesContratoENUM";

export default {
  props: {
    row: {
      type: Object,
      require: true,
    },
  },
  methods: {
    getColor(row) {
      const detail = DetalhesContratoENUM.find(
        (g) => g.descricao === row.ultimoDetalhe.descricao
      );
      return detail?.color || "#000000";
    },
  },
};
</script>

<style lang="scss" scoped>
.table-details {
  display: flex;
  justify-content: center;
  &__tag {
    padding: 3px 5px;
    min-width: 40px;
    min-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eeeeee;
    border-radius: 5px;
    font-weight: 700;
    font-size: 10px;
    text-align: center;
    line-height: 150%;
    color: #000000;
  }
  &__tag--yellow {
    background: #ecd823;
    color: #000000;
  }
}
</style>
