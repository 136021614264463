<template>
  <div class="toast-container">
    <div
      class="toast"
      id="toast-component"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="section-color" :style="[{ background: color }]"></div>
      <div class="toast-section">
        <div class="toast-header">
          <img :src="icon" />
          <strong class="me-auto">{{ getToast.title }}</strong>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            data-bs-dismiss="toast"
            data-cy="button-fechar_toast-toast"
          ></button>
        </div>
        <div class="toast-body">
          {{ getToast.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import info from "@/assets/icons/svg/info.png";
import danger from "@/assets/icons/svg/danger.png";
import TypesToastENUM from "@/enums/TypesToastENUM";
import success from "@/assets/icons/svg/success.png";
import warning from "@/assets/icons/svg/warning.png";

export default {
  computed: {
    ...mapGetters(["getToast"]),
    color() {
      return {
        success: "#78B82A",
        danger: "#EC2F23",
        warning: "#ECD823",
        info: "#1F4496",
      }[this.getToast.type];
    },
    icon() {
      if (this.getToast.type === TypesToastENUM.DANGER) {
        return danger;
      } else if (this.getToast.type === TypesToastENUM.INFO) {
        return info;
      } else if (this.getToast.type === TypesToastENUM.WARNING) {
        return warning;
      } else {
        return success;
      }
    },
  },
  methods: {
    getActualDateTime() {
      return new Date().toLocaleTimeString("pt-BR");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_colors.scss";
.toast-container {
  position: fixed;
  right: 10px;
  bottom: 15px;
  z-index: 9999;
  border-radius: 9px;
}

.toast {
  display: flex;
  width: 343px;
  height: 100px;
  border-radius: 9px;
}

.section-color {
  width: 13px;
  height: 100%;
  border-radius: 9px 0px 0px 9px;
}

.toast-section {
  width: 100%;
  height: 100%;

  strong {
    font-size: 15px;
  }

  img {
    margin: 0px 10px 0px 3px;
  }
}
</style>
