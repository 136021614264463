<template>
  <div class="d-flex h-100 container-topbar w-100 justify-content-between">
    <div class="h-100 d-flex align-items-center">
      <div v-if="AMBIENTE" class="px-2 py-1" :class="AMBIENTE.class">
        <i class="icon ms-2" :class="AMBIENTE.icon" />
        <span class="ms-1 me-2">{{ AMBIENTE.label }}</span>
      </div>
      <div class="ms-4">
        <dots-loader size="xlg" v-if="loading" />
        <div v-else>
          Competência: {{ getCurrentFaturamento.competencia }} -
          <b :style="{ color: GET_SITUACAO_TEXT_COLOR }">
            {{ getCurrentFaturamento.situacao }}
          </b>
        </div>
      </div>
    </div>
    <div class="h-100 d-flex align-items-center justify-content-end">
      <i class="icon icon-user-profile me-2" />
      <div class="me-2">
        {{ GET_CURRENT_USER }}
      </div>
      |
      <div
        style=""
        @click="logout()"
        class="logout-text ms-2"
        data-cy="div-logout_app-topbar"
      >
        <div>Sair</div>
        <div style="margin-top: 1px; margin-left: 6px">
          <i class="icon-exit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DotsLoader from "@/components/Loaders/DotsLoader.vue";
import FaturamentosFechadosService from "@/services/FaturamentosFechadosService";
import { logoutFromSSO } from "@/utils/authentication";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const { data } = await FaturamentosFechadosService.getCurrentFaturamento();
    this.setCurrentFaturamento(data);
    this.loading = false;
  },
  methods: {
    ...mapActions([
      "ActionSetToken",
      "ActionSetUsuario",
      "ActionSetPermissoes",
      "ActionResetPendencies",
      "ActionSetPermissoesPowerBI",
      "setCurrentFaturamento",
    ]),
    logout() {
      this.ActionSetToken(null);
      this.ActionSetUsuario(null);
      this.ActionSetPermissoes(null);
      this.ActionResetPendencies(null);
      this.ActionSetPermissoesPowerBI(null);
      logoutFromSSO();
    },
  },
  computed: {
    ...mapGetters(["getUsuario", "getCurrentFaturamento"]),
    AMBIENTE() {
      const ambientes = {
        DEV: {
          label: "Ambiente de Desenvolvimento",
          class: "desenvolvimento-environment",
          icon: "icon-tool",
        },
        HMG: {
          label: "Ambiente de Homologação",
          class: "homologacao-environment",
          icon: "icon-stop",
        },
      };
      return ambientes[process.env.VUE_APP_ENVIRONMENT] || null;
    },
    GET_SITUACAO_TEXT_COLOR() {
      return {
        "FATURAMENTO ABERTO": "#78B82A",
        "PRÉ-FATURAMENTO": "#EC6723",
      }[this.getCurrentFaturamento.situacao];
    },
    GET_CURRENT_USER() {
      const { nome, role } = this.getUsuario;
      return `${nome || "N/A"} - ${role || "N/A"}`;
    },
  },
  components: {
    DotsLoader,
  },
};
</script>

<style lang="scss" scoped>
.container-topbar {
  font-size: 13px;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  .icon-exit {
    margin-top: 10px;
  }
}

.local-environment,
.homologacao-environment,
.desenvolvimento-environment {
  height: 50%;
  width: auto;
  display: flex;
  color: #ffffff;
  align-items: center;
  border-radius: 20px;
}

.local-environment {
  background: #ec6623;
}
.homologacao-environment {
  background: #d40d00;
}
.desenvolvimento-environment {
  background: #7700d4;
}

.logout-text {
  display: flex;
  cursor: pointer;
}
</style>
