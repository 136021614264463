<template>
  <div class="container-field">
    <label :class="{ hasValue: content !== '...' && content !== null }">{{
      labelText
    }}</label>
    <select
      ref="selectRef"
      id="selectField"
      v-model="content"
      :style="[{ width: `${widthSelect}%` }]"
      :class="{ hasValue: content !== '...' && content !== null }"
      data-cy="select-field_filter-faturamento_components_filter_fields"
    >
      <option selected>...</option>
      <option
        v-for="(option, key) in optionsFilter"
        v-if="option.visible !== false"
        :value="option.id"
        :key="`filter_key_${key}`"
      >
        {{ option.descricaoFilter || option.descricao }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    modelProp: {
      type: [String, Number, Object],
      required: false,
    },
    labelText: {
      type: String,
      required: false,
      default: "",
    },
    widthSelect: {
      type: Number,
      default: 100,
    },
    optionsFilter: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      content: null,
    };
  },
  watch: {
    optionsFilter: {
      deep: true,
      handler() {
        this.setValue(this.modelProp);
      },
    },
    modelProp: {
      deep: true,
      handler() {
        this.setValue(this.modelProp);
      },
    },
  },
  methods: {
    setValue(value) {
      if (!value) return;
      const filterToApply = this.optionsFilter.find(
        (item) => item.id === value
      );
      if (filterToApply) {
        this.content = filterToApply.id;
      }
    },
    getValue() {
      const value = this.content;
      if (value === "...") {
        return null;
      }
      return value;
    },
    clear() {
      const select = this.$refs.selectRef;
      select.selectedIndex = 0;
      this.content = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-field {
  width: 15%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  @include ml-hd {
    padding: responsive(10px);
  }
}

label {
  font-size: 16px;
  color: #a29da2;
  margin-bottom: 5px;
  @include ml-hd {
    font-size: responsive(16px);
    margin-bottom: responsive(5px);
  }

  &.hasValue {
    color: $color--blue-btn !important;
    font-weight: bold !important;
  }
}

select {
  padding: 5px;
  outline: none;
  color: #a29da2;
  border-radius: 12px;
  background: #f2edeb;
  border: 1px solid #b4b7ba;
  @include ml-hd {
    height: 30px;
    font-size: responsive(16px);
    border-radius: responsive(12px);
  }

  &.hasValue {
    color: $color--blue-btn !important;
    border: 1px solid $color--blue-btn !important;
  }
}
</style>
