<template>
  <div class="wrapper">
    <card-base-parametros
      v-if="
        (
          $can('ALCADAS_APROVACAO_VISUALIZAR') ||
          $can('ALCADAS_APROVACAO_VISUALIZAR_EDITAR')
        )
      "
      :icon="clipboardCheckIcon"
      title="Alçadas de Aprovação"
      @click.native="goToPage('/parametrizacao/alcada-aprovacao')"
      data-cy="card-para_alcadas_aprovacao-parametrizacao-components"
    />
    <card-base-parametros
      v-if="
        (
          $can('CURTO_PRAZO_VISUALIZAR') ||
          $can('CURTO_PRAZO_VISUALIZAR_EDITAR')
        )
      "
      title="TUSD"
      :icon="tusd"
      @click.native="goToPage('/parametrizacao/tusd')"
      data-cy="card-para_tusd-parametrizacao-components"
    />
    <card-base-parametros
      v-if="
        (
          $can('CURTO_PRAZO_VISUALIZAR') ||
          $can('CURTO_PRAZO_VISUALIZAR_EDITAR')
        )
      "
      :icon="rocketIcon"
      title="Proposta de Curto Prazo"
      @click.native="goToPage('/parametrizacao/curto-prazo')"
      data-cy="card-para_proposta_curto_prazo-parametrizacao-components"
    />
    <card-base-parametros
      v-if="
        (
          $can('ROTINA_IMPORTACAO_VISUALIZAR') ||
          $can('ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR') ||
          $can('ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR_EDITAR')
        )
      "
      :icon="downloadIcon"
      title="Rotina de Importação"
      @click.native="goToPage('/parametrizacao/rotina-importacao')"
      data-cy="card-para_rotina_importacao-parametrizacao-components"
    />
    <card-base-parametros
      v-if="$can('FECHAR_FATURAMENTO_HABILITADO')"
      :icon="receipt"
      title="Fechar Faturamento"
      @click.native="goToPage('/parametrizacao/fechar-faturamento')"
      data-cy="card-para_fechar_faturamento-parametrizacao-components"
    />
  </div>
</template>

<script>
import rocket from "@/assets/icons/svg/rocket.svg";
import receipt from "@/assets/icons/svg/receipt.svg";
import download from "@/assets/icons/svg/download.svg";
import clipboardCheck from "@/assets/icons/svg/clipboard-check.svg";
import tusdIcon from "@/assets/icons/svg/tusd.svg";

import CardBaseParametros from "@/components/cards/cardBaseParametros.vue";

export default {
  data() {
    return {
      pageShow: "home",
      rocketIcon: rocket,
      clipboardCheckIcon: clipboardCheck,
      downloadIcon: download,
      receipt: receipt,
      tusd: tusdIcon,
    };
  },
  components: {
    CardBaseParametros,
  },
  methods: {
    goToPage(path) {
      this.$router.push({ path });
    },
  },
};
</script>

<style lang="sass" scoped></style>
