<template>
  <div class="checkbox-estou-ciente">
    <input
      type="checkbox"
      v-model="content"
      id="estou-ciente-input"
      data-cy="input-estou_cliente_field-pld-spread-components-fields"
    />
    <label for="estou-ciente-input"> Estou Ciente </label>
  </div>
</template>
<script>
export default {
  name: "FieldEstouCiente",
  data() {
    return {
      content: false,
    };
  },
  watch: {
    content: {
      handler() {
        this.$emit("input", this.content);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  margin-right: 16.19px;
}

.checkbox-estou-ciente {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-right: 2%;
}

label {
  font-weight: 300;
}
</style>