import CurtoPrazo from "@/pages/curto-prazo/CurtoPrazo";
import DashboardPBI from "@/pages/dashboards/components/DashboardPBI.vue";
import Dashboards from "@/pages/dashboards/Dashboards.vue";
import DivergenciasBillingWBC from "@/pages/divergencias-wbc-billing";
import Exportador from "@/pages/exportador/Exportador.vue";
import Faturamento from "@/pages/faturamento/Faturamento.vue";
import ImportarLote from "@/pages/lote/ImportarLote.vue";
import NaoImportados from "@/pages/nao-importados/NaoImportados.vue";
import AlcadaAprovacao from "@/pages/parametrizacao/components/AlcadaAprovacao.vue";
import CurtoPrazoParametrizacao from "@/pages/parametrizacao/components/CurtoPrazo.vue";
import FecharFaturamento from "@/pages/parametrizacao/components/FecharFaturamento.vue";
import ParametrizacaoHome from "@/pages/parametrizacao/components/ParametrizacaoHome.vue";
import RotinaImportacao from "@/pages/parametrizacao/components/RotinaImportacao.vue";
import Tusd from "@/pages/parametrizacao/components/Tusd.vue";
import Parametrizacao from "@/pages/parametrizacao/Parametrizacao.vue";
import PldSpread from "@/pages/pld-spread/PldSpread.vue";
import PontosMedicao from "@/pages/pontos-de-medicao/PontosMedicao.vue";
import PosVenda from "@/pages/pos-venda/PosVenda.vue";
import Proinfa from "@/pages/proinfa/Proinfa.vue";
import SincronizacaoEmpresas from "@/pages/sincronizacao-empresas-central/SincronizacaoEmpresas.vue";

const routes = [
  {
    path: "/Faturamento",
    name: "Faturamento",
    component: Faturamento,
    meta: {
      permissions: new Array(),
    },
  },
  {
    path: "/pld-spread",
    name: "Pld Spread",
    component: PldSpread,
    meta: {
      permissions: new Array(
        "AJUSTES_PLD_SPREAD_VISUALIZAR",
        "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR",
        "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR"
      ),
    },
  },
  {
    path: "/pontos-medicao",
    name: "Pontos Medicao",
    params: true,
    component: PontosMedicao,
    meta: {
      permissions: new Array(
        "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR",
        "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR_EDITAR"
      ),
    },
  },
  {
    path: "/nao-importados",
    name: "Não Importados",
    params: true,
    component: NaoImportados,
    meta: {
      permissions: new Array("CONTRATOS_NAO_IMPORTADOS_HABILITADO"),
    },
  },
  {
    path: "/proinfa",
    name: "Proinfa",
    params: true,
    component: Proinfa,
    meta: {
      permissions: new Array(
        "CONFIGURAR_PROINFA_VISUALIZAR",
        "CONFIGURAR_PROINFA_VISUALIZAR_EDITAR"
      ),
    },
  },
  {
    path: "/pos-venda",
    name: "Pos Venda",
    component: PosVenda,
    meta: {
      permissions: new Array(
        "ASSOCIAR_POS_VENDA_VISUALIZAR",
        "ASSOCIAR_POS_VENDA_VISUALIZAR_ASSOCIAR"
      ),
    },
  },
  {
    path: "/exportador",
    name: "Exportador",
    component: Exportador,
    meta: {
      permissions: new Array(
        "EXPORTADOR_MONTANTES_VISUALIZAR",
        "EXPORTADOR_MONTANTES_VISUALIZAR_EXPORTAR"
      ),
    },
  },
  {
    path: "/divergencias",
    name: "Divergências Billing x WBC",
    component: DivergenciasBillingWBC,
    meta: {
      permissions: new Array(),
    },
  },
  {
    path: "/sincronizacao",
    name: "Sincronização de Empresas Central",
    component: SincronizacaoEmpresas,
    meta: {
      permissions: new Array(),
    },
  },
  {
    path: "/curto-prazo",
    name: "CurtoPrazo",
    component: CurtoPrazo,
    meta: {
      permissions: new Array(
        "ACOMPANHAR_CURTO_PRAZO_VISUALIZAR",
        "ACOMPANHAR_CURTO_PRAZO_VISUALIZAR_SINALIZAR"
      ),
    },
  },
  {
    path: "/lote",
    name: "Importação em Lote",
    component: ImportarLote,
    meta: {
      permissions: new Array("IMPORTACAO_LOTE_HABILITADO"),
    },
  },
  {
    path: "/dashboards",
    name: "Dashboards",
    component: Dashboards,
    meta: {
      permissions: new Array("DASHBOARD_HABILITADO"),
    },
  },
  {
    path: "/dashboard",
    name: "DashboardPBI",
    component: DashboardPBI,
    meta: {
      permissions: new Array("DASHBOARD_HABILITADO"),
    },
  },
  {
    path: "/parametrizacao-home",
    name: "Parametrizacao",
    component: Parametrizacao,
    redirect: "/parametrizacao",
    children: [
      {
        path: "/parametrizacao",
        name: "Parâmetros",
        component: ParametrizacaoHome,
        meta: {
          permissions: new Array(
            "ALCADAS_APROVACAO_VISUALIZAR",
            "ALCADAS_APROVACAO_VISUALIZAR_EDITAR",
            "CURTO_PRAZO_VISUALIZAR",
            "CURTO_PRAZO_VISUALIZAR_EDITAR",
            "ROTINA_IMPORTACAO_VISUALIZAR",
            "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR",
            "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR_EDITAR",
            "FECHAR_FATURAMENTO_HABILITADO"
          ),
        },
      },
      {
        path: "/parametrizacao/alcada-aprovacao",
        name: "Alçadas de Aprovação",
        component: AlcadaAprovacao,
        meta: {
          permissions: new Array(
            "ALCADAS_APROVACAO_VISUALIZAR",
            "ALCADAS_APROVACAO_VISUALIZAR_EDITAR"
          ),
        },
      },
      {
        path: "/parametrizacao/tusd",
        name: "Tusd",
        component: Tusd,
        meta: {
          permissions: new Array(
            "CURTO_PRAZO_VISUALIZAR",
            "CURTO_PRAZO_VISUALIZAR_EDITAR"
          ),
        },
      },
      {
        path: "/parametrizacao/curto-prazo",
        name: "Proposta de Curto Prazo",
        component: CurtoPrazoParametrizacao,
        meta: {
          permissions: new Array(
            "CURTO_PRAZO_VISUALIZAR",
            "CURTO_PRAZO_VISUALIZAR_EDITAR"
          ),
        },
      },
      {
        path: "/parametrizacao/rotina-importacao",
        name: "Rotina de Importação",
        component: RotinaImportacao,
        meta: {
          permissions: new Array(
            "ROTINA_IMPORTACAO_VISUALIZAR",
            "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR",
            "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR_EDITAR"
          ),
        },
      },
      {
        path: "/parametrizacao/fechar-faturamento",
        name: "Fechar Faturamento",
        component: FecharFaturamento,
        meta: {
          permissions: new Array("FECHAR_FATURAMENTO_HABILITADO"),
        },
      },
    ],
  },
];

export default routes;
