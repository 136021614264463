<template>
  <div v-if="config" class="table-card">
    <div class="table-title">Fatura no Mercado LIVRE</div>
    <div class="table-subtitle">
      A. Custos com uso do Sistema da Distribuidora ({{ distribuidora }})
      <!--unidade consumidora-->
    </div>
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>Dado</th>
          <th>Unidade</th>
          <th>Medição Considerada</th>
          <th>Tarifa Sem Impostos [3]</th>
          <th>Faturamento</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Consumo Ponta</td>
          <td>MWh</td>
          <td>{{ config.scdeEp | truncateRound(4, 3) }}</td>
          <td>{{ config.tarLivScdeEpSi | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.fatLivScdeEpSi | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Consumo Fora Ponta</td>
          <td>MWh</td>
          <td>{{ config.scdeEfp | truncateRound(4, 3) }}</td>
          <td>{{ config.tarLivScdeEfpSi | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.fatLivScdeEfpSi | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Demanda - Ponta</td>
          <td>kW</td>
          <td>{{ config.dcEp | truncateRound(4, 3) }}</td>
          <td>{{ config.tarLivDcEpSi | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.fatLivDcEpSi | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Demanda - Fora Ponta</td>
          <td>kW</td>
          <td>{{ config.dcEfp | truncateRound(4, 3) }}</td>
          <td>{{ config.tarLivDcEfpSi | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.fatLivDcEfpSi | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>CDE - COVID</td>
          <td>R$</td>
          <td>{{ config.scdeLivTot | truncateRound(4, 3) }}</td>
          <td>{{ config.tarCovidSi | truncateRound(3, 2) | R$ }}</td>
          <td class="table-bold">
            {{ config.fatCovidSi | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Subvenção ICMS</td>
          <td>R$</td>
          <td>--</td>
          <td>--</td>
          <td class="table-bold">
            {{ config.fatSubvencaoCi | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Ajuste TUSD</td>
          <td>R$</td>
          <td>--</td>
          <td>--</td>
          <td class="table-bold">
            {{ config.resultAdjustmentTusdValue | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Impostos</td>
          <td>R$</td>
          <td>--</td>
          <td>--</td>
          <td class="table-bold">
            {{ config.impostosAcl | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr class="table-bold white-row">
          <td></td>
          <td></td>
          <td></td>
          <td>Total</td>
          <td class="table-total">
            {{ config.aclDiscoSm | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["config", "distribuidora"],
};
</script>
<style lang="scss" scoped>
.table-card {
  margin-top: 25px;
  border-radius: 0.3em;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  box-shadow: 2px 2px 5px 0 #2a2a2a22;
}
.table-title {
  flex: 1;
  display: flex;
  padding: 16px 24px;
  font-weight: 700;
  color: #ffffff;
  font-size: 1.5rem;
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 0.3em 0.3em 0 0;
  border-bottom: 1px solid #f86511;
}
.table-subtitle {
  padding: 8px 8px;
  background-color: #cecece;
  font-size: 0.9rem;
  font-weight: 700;
}
table {
  border-collapse: collapse;
  margin: 0;
  font-size: 0.9rem;
  border-radius: 0 0 0.3em 0.3em;

  thead {
    background-color: #e8e8e8;
  }

  tbody {
    tr {
      width: 100%;
      &:nth-child(even) {
        background-color: #f2edeb;
      }
      &:last-child {
        border-radius: 0 0 0.3em 0.3em;
        :first-child {
          border-radius: 0 0 0 0.3em;
        }
      }
    }
  }

  .white-row {
    background-color: #ffffff !important;
  }

  .table-bold {
    font-weight: 700;
  }

  .table-total {
    background-color: #fff1ec;
    border-top: solid 1px #f86511;
    border-radius: 0 0 0.3em 0;
  }
}
</style>
