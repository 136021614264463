import {
  EMPTY,
  PERCENTUAL_PERDA,
  roundFormatter,
  VOLUME,
  VOLUME_PERCENTUAL,
} from "@/filters/formatters";

export default class TableContratosFilhosModel {
  static getTableFields() {
    return new Array(
      {
        name: "codigo",
        title: "Contrato",
      },
      {
        name: "empresa",
        title: "Apelido",
        width: "300px",
        formatter: (value) => {
          if (!value) return "--";
          return EMPTY(value.apelido);
        },
      },
      {
        name: "pontoMedicao",
        title: "Pto. de Medição",
        align: "center",
        formatter: (value) => {
          if (!value) return "--";
          return EMPTY(value.codigo);
        },
      },
      {
        name: "calculoFaturamento.apuracao",
        title: "M. SCDE (MWh)",
        align: "center",
        formatter: (value) => {
          return roundFormatter(value?.quantidadeMontanteScde, 3);
        },
      },
      {
        name: "percentualPerda",
        title: "% De Perda",
        align: "center",
        formatter: (value) => {
          if (!value) return "--";
          if (Number(value) === 100) return "100%";
          return PERCENTUAL_PERDA(value, false);
        },
      },
      {
        name: "percentualFatorAtendimento",
        title: "F. Atend. Carga",
        align: "center",
        formatter: (value) => {
          if (!value) return "--";
          if (Number(value) === 100) return "100%";
          return VOLUME_PERCENTUAL(value);
        },
      },
      {
        name: "proinfa",
        title: "PROINFA",
        align: "center",
        formatter: (value) => {
          if (!value) return "--";
          if (value) {
            return VOLUME(value);
          }
        },
      },
      {
        name: "calculoFaturamento.volumeBruto",
        title: "S. Bruto",
        align: "center",
        formatter: (value) => {
          return roundFormatter(value, 3);
        },
      },
      {
        name: "solicitadoLiquido",
        title: "S. Líquido (MWh)",
        align: "center",
      }
    );
  }
}
