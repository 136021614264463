<template>
  <div class="body-recompra">
    <aprovacao-analise-recompra-preco-unico
      v-if="
        tipoRecompra === 'analise-recompra-preco-unico' ||
        tipoRecompra === 'analise-recompra-preco-unico-rateio'
      "
      :dataPrecoUnico="recompraAnalisePrecoUnico"
      :notPadding="true"
    />

    <contrato-preco-unico-com-rateio
      v-if="tipoRecompra === 'analise-recompra-preco-unico-rateio'"
      :dataTable="recompraAnaliseUnicoRateio"
    />
    <contrato-recompra-preco-diferente
      v-if="tipoRecompra === 'analise-recompra-precos-diferentes'"
      :contrato="contrato"
      :dataPrecoDiferente="recompraAnalisePrecoDiferente"
      :isVisibleInfoContract="true"
    />
  </div>
</template>

<script>
import ContratosFilhosRecompra from "../recompra/ContratosFilhosRecompra.vue";
import AprovacaoAnaliseRecompraPrecoUnico from "./AprovacaoAnaliseRecompraPrecoUnico.vue";
import ContratoPrecoUnicoComRateio from "./envio-email-recompra/ContratoPrecoUnicoComRateio.vue";
import ContratoRecompraPrecoDiferente from "@/components/contratos/envio-email-recompra/ContratoRecompraPrecoDiferente.vue";

export default {
  props: {
    contrato: {
      type: Object,
    },
    recompraAnalisePrecoUnico: {
      type: Object,
    },
    recompraAnaliseUnicoRateio: {
      type: Array,
    },
    recompraAnalisePrecoDiferente: {
      type: Array,
    },
    tipoRecompra: {
      type: String,
    },
  },
  components: {
    ContratosFilhosRecompra,
    ContratoPrecoUnicoComRateio,
    ContratoRecompraPrecoDiferente,
    AprovacaoAnaliseRecompraPrecoUnico,
  },
};
</script>

<style lang="scss" scoped>
.body-recompra {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}
</style>
