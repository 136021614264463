<template>
  <div class="container-field">
    <label>Ponto de Medição</label>
    <select
      ref="selectRef"
      :disabled="isDisabled"
      v-model="selectOption"
      @change="handleFieldPoint"
      class="form-select slct-ponto-medicao"
      data-cy="select-ponto_medicao-ponto-medicao-components-fields"
    >
      <template v-if="pontoMedicao.pontos.length == 0">
        <option selected value="">Sem ponto cadastrado</option>
      </template>
      <template v-else>
        <option value="">Selecionar ponto</option>
        <option
          v-for="ponto in pontoMedicao.pontos"
          :key="`ponto_${ponto.id}`"
          :value="ponto"
        >
          {{ ponto.codigo }}
        </option>
      </template>
    </select>
    <button
      @click="toProinfaPage"
      v-if="pontoMedicao.pontos.length >= 1"
      class="btn btn-primary-text btn-pro-infa"
      v-show="!isDisabled && selectOption !== ''"
      data-cy="input-configurar_proinfa-ponto-medicao-components-fields"
    >
      Configurar PROINFA deste ponto
    </button>
  </div>
</template>

<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: true,
    },
    pontoMedicao: {
      type: Object,
      default: new Object(),
    },
  },
  data() {
    return {
      selectOption: "",
    };
  },
  mounted() {
    if (this.pontoMedicao) {
      this.selectOption = this.pontoMedicao.pontoMedicao;
    }
  },
  methods: {
    getLabelSelect() {
      const select = this.$refs.selectRef;
      const labelSelect = select.options[select.selectedIndex].label;
      return labelSelect;
    },
    toProinfaPage() {
      this.$router.push({
        name: "Proinfa",
        query: {
          contrato: this.pontoMedicao.contrato.codigo,
        },
      });
    },
    handleFieldPoint() {
      this.$emit("changeFieldPoint", this.selectOption);
    },
  },
};
</script>

<style lang="scss" scoped>
.slct-ponto-medicao {
  margin-top: 8.36px;
  border-radius: 12px;
  height: 40px;

  font-weight: 700;

  &:disabled {
    background: #f2edeb;
    border-color: #d6d2d0;
  }

  option {
    font-weight: 300;
  }
}

.container-field {
  margin-left: 40px;

  @media screen and (max-width: 1300px) {
    margin-left: 0px;

    width: 100%;
  }
}

label {
  color: $color--primary-font;
  font-weight: 300;
}

.btn-pro-infa {
  text-decoration: underline;
  font-weight: 700;
}
</style>
