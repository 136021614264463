import App from "@/App.vue";
import "@/assets/icomoon/style.css";
import "@/assets/style/animation.scss";
import "@/assets/style/style.scss";
import "@/components/globalizedComponents";
import "@/directives";
import MixinBrowserUtils from "@/mixins/mxBrowserUtils";
import MixinGlobals from "@/mixins/mxGlobals";
import MixinHandleRequestError from "@/mixins/mxHandleRequestError";
import MixinMountUrls from "@/mixins/mxMountUrls";
import MixinToggleMessageAlert from "@/mixins/mxToggleErrorMessageAlert";
import MixinToggleFilesError from "@/mixins/mxToggleFilesError";
import MixinToggleToast from "@/mixins/mxToggleToast";
import MixinToggleWarningModal from "@/mixins/mxToggleWarningModal";
import "@/plugins";
import "@/plugins/casl";
import "@/plugins/vuelidate";
import router from "@/router";
import store from "@/store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "chartjs-plugin-datalabels";
import Vue from "vue";
import vueDebounce from "vue-debounce";
import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);
Vue.use(vueDebounce);
Vue.mixin(MixinToggleToast);
Vue.mixin(MixinBrowserUtils);
Vue.mixin(MixinToggleMessageAlert);
Vue.mixin(MixinToggleFilesError);
Vue.mixin(MixinHandleRequestError);
Vue.mixin(MixinToggleWarningModal);
Vue.mixin(MixinMountUrls);
Vue.mixin(MixinGlobals);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
