<template>
  <div class="container-field">
    <input
      type="checkbox"
      v-model="content"
      :id="`replicar_spread_${idKey}`"
      data-cy="input-replicar_spread_field-pld-spread-components-fields"
    />
    <label :for="`replicar_spread_${idKey}`">Replicar Spread</label>
    <div>
      <tooltip
        width="200px"
        arrowAlign="up"
        alignVertical="30px"
        alignHorizontal="-80px"
      >
        <img
          slot="header"
          alt="Informação"
          class="icon-info"
          src="@/assets/icons/svg/info.svg"
        />
        <p slot="body">
          Replicar este Spread para este tipo de energia em
          <strong>todos os Submercados</strong>
        </p>
      </tooltip>
    </div>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip/Tooltip.vue";

export default {
  props: {
    idKey: Number,
  },
  data() {
    return {
      content: false,
    };
  },
  watch: {
    content: {
      handler() {
        this.$emit("input", this.content);
      },
    },
  },
  components: {
    Tooltip,
  },
};
</script>

<style lang="scss" scoped>
input {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 4px;
}

.container-field {
  display: flex;
  margin-top: 40px;
  margin-left: 30px;
  align-items: center;
  justify-content: center;
}
label {
  font-weight: 300;
  margin-left: 16.19px;
}

.icon-info {
  cursor: pointer;
  margin-left: 9.65px;
}
</style>
