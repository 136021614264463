<template>
  <div class="page-proinfa">
    <page-panel v-elementAsyncLoader="loading">
      <template #header> Configurar PROINFA de Ponto de Medição </template>
      <template #body>
        <div class="page-proinfa__body">
          <p style="margin-bottom: 0; font-weight: 500">
            Procure por um único ponto de medição
          </p>
          <div class="page-proinfa__body__filters">
            <field-search
              style="max-width: 50%"
              class="page-proinfa__body__filters__search"
              :customImgSize="{ size: '15px', margin: '15px' }"
              :placeholder="// contratoTag
              //   ? 'Buscando pelo contrato...'
              //   :
              'Digite aqui o ponto de medição'"
              @input="handleInput"
              :value="pontoSearch"
              @getSearchValue="getSearchValue"
            />
            <!-- :disabled="!!contratoTag" -->
            <!-- <div v-if="!!contratoTag" class="page-proinfa__body__filters__tag">
              Contrato: {{ contratoTag }}
              <i
                class="icon-close"
                @click="
                  contratoTag = '';
                  $router.push({ name: 'Proinfa' });
                "
              />
            </div> -->
          </div>
          <upload-csv-card
            @sendFormData="
              sendFormDataResult = service.uploadProinfaEmLotes($event)
            "
            :sendFormDataResult="sendFormDataResult"
            label="Ou faça um upload de arquivo (MWh)"
            descType="proinfa"
          />
          <contratos-proinfa
            :contrato="contrato"
            :pontosMedicao="pontosMedicao"
            @proinfaReload="refetch"
            v-if="pontosMedicao && pontosMedicao.length > 0"
          />
          <div v-if="isEmpty" class="no-proinfa">
            {{ contratoTag ? "Contrato" : "Ponto de Medição" }} não encontrado
          </div>
        </div>
      </template>
    </page-panel>
  </div>
</template>

<script>
import FieldSearch from "@/components/fields/FieldSearch.vue";
import PagePanel from "@/components/panels/PagePanel.vue";
import UploadCsvCard from "@/components/upload/UploadCsvCard.vue";
import ContratosProinfa from "@/pages/proinfa/components/ContratosProinfa.vue";
import ProinfaService from "@/services/ProinfaService";
import { changeQueryDynamically } from "@/utils/functionsUtils";

export default {
  data() {
    return {
      contrato: "",
      isEmpty: false,
      loading: undefined,
      pontosMedicao: new Array(),
      service: ProinfaService,
      sendFormDataResult: undefined,
      pontoSearch: "",
      contratoTag: "",
    };
  },
  mounted() {
    const { ponto, contrato } = this.QUERY_VALUES;
    if (contrato) {
      this.contratoTag = contrato;
      this.getSearchValue(contrato, true);
    } else if (ponto) {
      this.pontoSearch = ponto;
      this.getSearchValue(ponto);
    }
  },
  methods: {
    async handleInput(value) {
      changeQueryDynamically(
        { name: "Proinfa", query: { ponto: value } },
        this
      );
    },
    getSearchValue(ponto = "", isContrato = false) {
      this.loading = ProinfaService[
        isContrato ? "getPontosByContratoCod" : "getPontosMedicaoByContrato"
      ]({
        contrato: ponto.trim(),
      })
        .then((response) => {
          this.contrato = ponto;
          this.pontosMedicao = response.data;
          this.isEmpty = response.data == 0;
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "PROINFA");
        });
    },
    refetch() {
      const { contrato } = this.QUERY_VALUES;
      this.getSearchValue(this.contrato, !!contrato);
    },
  },
  computed: {
    QUERY_VALUES() {
      const { contrato, ponto } = this.$route.query;
      return { contrato, ponto };
    },
  },
  components: {
    PagePanel,
    FieldSearch,
    ContratosProinfa,
    UploadCsvCard,
  },
};
</script>
<style lang="scss" scoped>
.page-proinfa {
  padding-top: 24px;
  &__body {
    padding: 30px;
    margin-bottom: 10px;
    &__filters {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      &__search {
        height: 40px;
      }
      &__tag {
        margin-top: 20px;
        background-color: $color--blue-dark;
        color: white;
        padding: 8px 12px;
        border-radius: 10px;
        display: flex;
        gap: 6px;
        align-items: center;
        i {
          cursor: pointer;
        }
      }
    }
  }
}

.no-proinfa {
  margin-top: 10px;
}
</style>
