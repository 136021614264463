import { helpers } from "vuelidate/lib/validators";

/**
 * @sumary - Checks whether the value is a valid Brazilian CNPJ number
 * @pattern - XX.XXX.XXX/XXXX-XX
 * @optional - The dots and the hyphen
 */
export const isCNPJ = helpers.regex(
  "isCnpj",
  /[0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2}/
);
