<template>
  <div v-elementAsyncLoader="loading">
    <div class="row">
      <div class="col-lg-6">
        <card-base>
          <template #header>Solicitado Líquido</template>
          <template #body>
            <div class="wrapper-table">
              <data-table
                :data="alcadasSolicitadoLiquido"
                :fields="tableModel.getTableFields()"
              >
                <div slot="valorFaixaMin" slot-scope="{ row }">
                  <field-alcada-aprovacao-min
                    disabled
                    class="w-75"
                    :modelProp="{
                      ...row,
                      valorFaixaMin: '> ' + row.valorFaixaMin,
                    }"
                    placeHolder="> 50"
                    fieldName="valorFaixaMin"
                    style="width: 80% !important"
                    :ref="`field_alcada_solicitado_liquido_min_${generateUUID()}`"
                  />
                </div>
                <div
                  slot="valorFaixaMax"
                  slot-scope="{ row }"
                  v-if="row.descricaoPerfilAprovador != 'APROVADOR_NIVEL_3'"
                >
                  <field-alcada-aprovacao-max
                    class="w-75"
                    placeHolder="30"
                    :modelProp="row"
                    fieldName="valorFaixaMax"
                    style="width: 80% !important"
                    @maxValueChanged="changeMinValue"
                    :disabled="!$can('ALCADAS_APROVACAO_VISUALIZAR_EDITAR')"
                    :ref="`field_alcada_solicitado_liquido_max_${generateUUID()}`"
                  />
                </div>
              </data-table>
            </div>
          </template>
        </card-base>
      </div>

      <div class="col-lg-6">
        <card-base>
          <template #header>Solicitado Spread de Recompra Manual</template>
          <template #body>
            <div class="wrapper-table">
              <data-table
                :data="alcadasSolicitadoSpreadRecompra"
                :fields="tableModel.getTableFields()"
              >
                <div slot="valorFaixaMin" slot-scope="{ row }">
                  <field-alcada-aprovacao-min
                    disabled
                    class="w-75"
                    :modelProp="{
                      ...row,
                      valorFaixaMin: '> ' + row.valorFaixaMin,
                    }"
                    placeHolder="> 50"
                    fieldName="valorFaixaMin"
                    style="width: 80% !important"
                    :ref="`field_alcada_solicitado_spread_recompra_min_${generateUUID()}`"
                  />
                </div>
                <div
                  slot="valorFaixaMax"
                  slot-scope="{ row }"
                  v-if="row.descricaoPerfilAprovador != 'APROVADOR_NIVEL_3'"
                >
                  <field-alcada-aprovacao-max
                    class="w-75"
                    placeHolder="30"
                    :modelProp="row"
                    fieldName="valorFaixaMax"
                    style="width: 80% !important"
                    @maxValueChanged="changeMinValue"
                    :disabled="!$can('ALCADAS_APROVACAO_VISUALIZAR_EDITAR')"
                    :ref="`field_alcada_solicitado_spread_recompra_max_${generateUUID()}`"
                  />
                </div>
              </data-table>
            </div>
          </template>
        </card-base>
      </div>

      <div class="col d-flex justify-content-end mt-3">
        <div class="button-bar-parametrizacao">
          <button
            :disabled="
              !hasChanged || !$can('ALCADAS_APROVACAO_VISUALIZAR_EDITAR')
            "
            class="btn btn-secondary-full-filled"
            @click="getBackToParametrizacaoHome()"
            data-cy="button-cancelar_alteracoes_alcada_aprovacao-parametrizacao-components"
          >
            Cancelar Alterações
          </button>
          <button
            :disabled="
              !hasChanged || !$can('ALCADAS_APROVACAO_VISUALIZAR_EDITAR')
            "
            @click="save"
            class="btn btn-primary-full-filled ms-3"
            data-cy="button-salvar_alteracoes_alcada_aprovacao-parametrizacao-components"
          >
            Salvar Alterações
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formIsValid } from "@/utils/validators";
import TypesToastENUM from "@/enums/TypesToastENUM";
import CardBase from "@/components/cards/cardBase.vue";
import DataTable from "@/components/tables/DataTable.vue";
import { createErrorObject } from "@/utils/functionsUtils";
import AlcadasAprovacaoModel from "@/models/AlcadasAprovacaoModel.js";
import { CONFIRMATION, ERROR, HTTP_RESPONSE } from "@/constants/strings";
import FieldAlcadaAprovacaoMax from "./fields/FieldAlcadaAprovacaoMax.vue";
import FieldAlcadaAprovacaoMin from "./fields/FieldAlcadaAprovacaoMin.vue";
import AlcadasAprovacaoService from "@/services/AlcadasAprovacaoService.js";
import TableAlcadasAprovacaoModel from "@/models/tablesModels/TableAlcadasAprovacaoModel";

export default {
  name: "AlcadaAprovacao",
  components: {
    CardBase,
    DataTable,
    FieldAlcadaAprovacaoMax,
    FieldAlcadaAprovacaoMin,
  },
  data() {
    return {
      loading: undefined,
      tableModel: new TableAlcadasAprovacaoModel(),
      receivedAlcadas: new Array(),
      alcadasSolicitadoLiquido: new Array(),
      alcadasSolicitadoSpreadRecompra: new Array(),
    };
  },
  mounted() {
    this.loading = AlcadasAprovacaoService.getAlcadasAprovacao()
      .then(({ data, status }) => {
        if (status === 204) {
          throw createErrorObject(status);
        } else {
          this.receivedAlcadas = data.map((alcada) => {
            return new AlcadasAprovacaoModel(
              this.makeDeepCopy(alcada.getData())
            );
          });

          if (data.length > 0) {
            this.alcadasSolicitadoLiquido = data.filter(
              (alcada) => alcada.tipo === "SOLICITADO_LIQUIDO"
            );

            this.alcadasSolicitadoSpreadRecompra = data.filter(
              (alcada) => alcada.tipo === "SOLICITADO_SPREAD_RECOMPRA"
            );
          }
        }
      })
      .catch((error) => {
        this.mxHandleRequestError(error, "ALCADA_APROVACAO");
      });
  },
  methods: {
    getBackToParametrizacaoHome() {
      this.$router.push({ name: "Parâmetros" });
    },
    makeDeepCopy(value) {
      return JSON.parse(JSON.stringify(value));
    },
    changeMinValue(id, tipo, value) {
      // existem somente 8 tipos de alçada (pós-venda. aprovador nível 1, 2 e 3)
      if (id > 0 && id < 8) {
        const nextId = id + 1;
        if (tipo === "SOLICITADO_LIQUIDO") {
          let alcada = this.alcadasSolicitadoLiquido.find(
            (alcada) => alcada.id === nextId
          );
          alcada.valorFaixaMin = value;
        } else if (tipo === "SOLICITADO_SPREAD_RECOMPRA") {
          let alcada = this.alcadasSolicitadoSpreadRecompra.find(
            (alcada) => alcada.id === nextId
          );
          alcada.valorFaixaMin = value;
        }
      }
    },
    generateUUID() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    alcadasFormatted() {
      const alcadas = new Array(
        ...this.alcadasSolicitadoLiquido.map((alcada) => alcada.getData()),
        ...this.alcadasSolicitadoSpreadRecompra.map((alcada) =>
          alcada.getData()
        )
      );
      return { alcadas };
    },
    save() {
      if (!formIsValid(this)) {
        this.mxToggleToast({
          title: "Valores Inválidos!",
          message: ERROR.VALUES_INVALID,
          type: TypesToastENUM.DANGER,
        });
      } else {
        this.loading = AlcadasAprovacaoService.updateAlcadasAprovacao(
          this.alcadasFormatted()
        )
          .then(() => {
            this.mxToggleToast({
              message: HTTP_RESPONSE.ALCADA_APROVACAO.UPDATE,
            });
            this.receivedAlcadas = new Array(
              ...this.alcadasSolicitadoLiquido.map(
                (alcada) => new AlcadasAprovacaoModel(alcada.getData())
              ),
              ...this.alcadasSolicitadoSpreadRecompra.map(
                (alcada) => new AlcadasAprovacaoModel(alcada.getData())
              )
            ).map((alcada) => {
              return new AlcadasAprovacaoModel(
                this.makeDeepCopy(alcada.getData())
              );
            });
          })
          .catch((error) => {
            this.mxHandleRequestError(error, "ALCADA_APROVACAO");
          });
      }
    },
  },
  computed: {
    hasChanged() {
      let hasChanged = false;

      this.alcadasSolicitadoLiquido.forEach((alcadaSolicitadoLiquido) => {
        const foundAlcada = this.receivedAlcadas.find(
          (alcada) => alcada.id === alcadaSolicitadoLiquido.id
        );
        if (
          foundAlcada &&
          (foundAlcada.valorFaixaMin != alcadaSolicitadoLiquido.valorFaixaMin ||
            foundAlcada.valorFaixaMax != alcadaSolicitadoLiquido.valorFaixaMax)
        ) {
          hasChanged = true;
        }
      });

      this.alcadasSolicitadoSpreadRecompra.forEach(
        (alcadaSolicitadoSpreadRecompra) => {
          const foundAlcada = this.receivedAlcadas.find(
            (alcada) => alcada.id === alcadaSolicitadoSpreadRecompra.id
          );
          if (
            foundAlcada &&
            (foundAlcada.valorFaixaMin !=
              alcadaSolicitadoSpreadRecompra.valorFaixaMin ||
              foundAlcada.valorFaixaMax !=
                alcadaSolicitadoSpreadRecompra.valorFaixaMax)
          ) {
            hasChanged = true;
          }
        }
      );

      return hasChanged;
    },
  },
  watch: {
    alcadasSolicitadoLiquido: {
      deep: true,
      handler() {
        formIsValid(this);
      },
    },
    alcadasSolicitadoSpreadRecompra: {
      deep: true,
      handler() {
        formIsValid(this);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  width: 300px;
  border-radius: 12px;
  height: 57px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 1px;
  color: #fffaf8;
}

.body-alcada-aprovacao {
  padding: 30px 40px 0 40px;
}

.title-card {
  margin-bottom: 0px !important;
}

.header-container {
  width: 100%;

  border-bottom: 1px solid #949291;
}

.header-container p {
  margin: 7px 0 5px 0;

  font-size: 16px;
  font-weight: 700;
}

.row-body {
  width: 100%;

  padding: 5px 0;

  border-bottom: 1px solid #f2edeb;
}

.row-body p {
  font-weight: 300;
}

.label-td {
  width: 40%;
  padding: 2% 5% 2% 0;
  border-bottom: 1px solid #f2edeb;
}

.data-td {
  width: 30%;
  padding: 2% 5% 2% 0;
  border-bottom: 1px solid #f2edeb;
}
</style>
