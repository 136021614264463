import WarningModal from "@/components/WarningModal.vue";

export default {
  methods: {
    mxToggleWarningModal({
      message = "Deseja prosseguir?",
      btnTrue = "Confirmar",
      btnFalse = "Cancelar",
      title = "Atenção",
      showBtnFalse = true,
      rejectOnCloseModal = false,
      rejectOnCloseIcon = false,
    }) {
      return WarningModal.show({
        message,
        title,
        btnTrue,
        btnFalse,
        showBtnFalse,
        rejectOnCloseModal,
        rejectOnCloseIcon,
      });
    },
  },
};
