<template>
  <div class="container-prazo-final">
    <div v-element-async-loader="loading">
      <p class="title-prazo-final">Prazo Final da Vigência do Spread</p>
      <div class="container-fields">
        <field-data
          ref="fieldData"
          :modelProp="DATE"
          data-testid="fieldData"
          :disabled="
            !$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR') &&
            !$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR')
          "
        />
        <field-hora
          ref="fieldHora"
          :modelProp="HOUR"
          :disabled="
            !$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR') &&
            !$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR')
          "
        />
        <button
          @click="updateSpreadValidity"
          class="btn btn-primary-full-filled btn-save"
          :disabled="
            !$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR') &&
            !$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR')
          "
          data-cy="button-salvar_prazo_final_vigencia_spread-pld-spread-components"
        >
          Salvar Prazo
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { DateTime } from "luxon";
import FieldHora from "./fields/FieldHora.vue";
import FieldData from "./fields/FieldData.vue";
import { padLeft } from "@/utils/functionsUtils";
import { CONFIRMATION, ERROR } from "@/constants/strings";
import PLDSpreadService from "@/services/PLDSpreadService";
export default {
  data() {
    return {
      content: null,
      loading: undefined,
    };
  },
  computed: {
    DATE() {
      if (!this.content) return null;
      const { vigencia } = this.content;
      return new Date(vigencia);
    },
    HOUR() {
      if (!this.content) return null;
      const { vigencia } = this.content;
      const _v = new Date(vigencia);
      return `${padLeft(_v.getHours())}:${padLeft(_v.getMinutes())}`;
    },
  },
  mounted() {
    this.getSpreadValidity();
  },
  methods: {
    getSpreadValidity() {
      return new Promise((resolve, reject) => {
        this.loading = PLDSpreadService.getSpreadValidity()
          .then(({ data }) => {
            this.content = data;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    greaterThanCurrentDate(date = DateTime.now().toMillis()) {
      return DateTime.fromISO(date).toMillis() > DateTime.now().toMillis();
    },
    mountSpreadValidity() {
      if (!this.$refs.fieldData || !this.$refs.fieldHora) return null;
      const DATE = DateTime.fromJSDate(this.$refs.fieldData.content);
      if (!DATE) return null;
      const TIME = this.$refs.fieldHora.content || "";
      if (!TIME) return null;
      const SPREAD_VALIDITY = DateTime.fromISO(DATE)
        .set({
          hour: TIME.substr(0, 2),
          minute: TIME.substr(3, 2),
        })
        .toISO();
      if (!this.greaterThanCurrentDate(SPREAD_VALIDITY)) return null;
      return SPREAD_VALIDITY;
    },
    updateSpreadValidity() {
      const vigencia = this.mountSpreadValidity();
      if (!vigencia) {
        return this.mxToggleErrorMessageAlert(ERROR.VIGENCIA_DATE_INVALID);
      }
      this.loading = PLDSpreadService.updateSpreadValidity({
        vigencia,
      })
        .then(() => {
          this.mxToggleToast({
            message: CONFIRMATION.PRAZO_FINAL_VIGENCIA_SPREAD.UPDATE,
          });

          this.$emit("reload");
        })
        .catch((error) =>
          this.mxHandleRequestError(error, "PLD_SPREAD_VIGENCIA")
        );
    },
  },
  components: {
    FieldHora,
    FieldData,
  },
};
</script>
<style lang="scss" scoped>
.title-prazo-final {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}

.container-prazo-final {
  // margin: 0px 0px 0px 40px;
  margin-left: 40px;
}

.container-fields {
  display: flex;
  margin-top: 24px;
  align-items: flex-end;
  justify-content: flex-start;
}

.btn-save {
  font-weight: 700;
  margin-left: 30px;
  padding-left: 24px;
  border-radius: 9px;
  padding-right: 24px;
}
</style>
