import { CURRENCY, VOLUME, CNPJ } from "@/filters/formatters";

export class TableRecompraPrecoUnicoRateio {
  getTableFields() {
    return new Array(
      {
        name: "codigo",
        title: "Contrato",
      },
      {
        name: "apelido",
        title: "Apelido",
      },
      {
        name: "cnpj",
        title: "CNPJ",
        formatter(value) {
          return CNPJ(value, "--");
        },
      },
      {
        name: "solicitadoLiquido",
        title: "Solic. Bruto (MWh)",
        formatter(value) {
          return VOLUME(value, "--");
        },
      },
      {
        name: "volumeRecompraFinal",
        title: "Volume (MWh)",
        formatter(value) {
          if (!value) return "--";
          return VOLUME(value);
        },
      },
      {
        name: "precoOriginal",
        title: "Preço",
        formatter(value) {
          return CURRENCY(value, "--");
        },
      },
      {
        name: "notional",
        title: "Notional",
        formatter(value) {
          return CURRENCY(value, "--");
        },
      }
    );
  }
}
