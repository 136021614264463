<template>
  <div class="box-container">
    <label
      class="check-box-container"
      :style="{ paddingTop: `${alignText}px` }"
    >
      {{ textLabel }}
      <input
        type="checkbox"
        v-model="content"
        data-cy="input-check_box_filter-components-faturamento_components_filter-fields"
      />
      <span></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelProp: {
      type: [Boolean, Object],
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    textLabel: {
      type: String,
      default: "",
    },
    alignText: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      content: undefined,
    };
  },
  watch: {
    modelProp: {
      handler() {
        this.content = this.modelProp;
      },
    },
    content: {
      handler() {
        this.$emit("getCheckedStatus", {
          type: this.type,
          value: this.content,
        });
      },
    },
  },
  methods: {
    getValue() {
      return this.content;
    },
    clear() {
      this.content = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.box-container {
  display: flex;
  align-items: center;

  width: 150px;
  height: 70px;
}

.check-box-container {
  position: relative;

  padding-left: 35px;
  margin-top: 25px;
  margin-left: 10px;

  input {
    position: absolute;

    width: 0;
    height: 0;

    opacity: 0;

    cursor: pointer;
  }

  span {
    position: absolute;

    top: 0;
    left: 0;

    height: 25px;
    width: 25px;

    border: 1px solid #b4b7ba;
    border-radius: 5px;

    cursor: pointer;
  }
}

.check-box-container input:checked ~ span {
  background-color: white;
}

.check-box-container span::after {
  content: "";

  position: absolute;
  display: none;

  left: 9px;
  top: 5px;

  width: 5px;
  height: 10px;

  border: solid #f86511;
  border-width: 0 3px 3px 0;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.check-box-container input:checked ~ span::after {
  display: block;
}
</style>
