<template>
  <div class="wrapper-recompra">
    <overlay-loader v-if="loading" />
    <contrato-cards-recompra :contrato="contrato" />
    <div class="row mt-3">
      <div class="col-md-12">
        <card-base>
          <template #header>Condições de Preço</template>
          <template #body>
            <div class="card-body">
              <div class="_card-contrato-item card-condicoes-preco">
                <div class="card-label">Submercado</div>
                <div class="card-value">{{ condicaoPreco.submercado }}</div>
              </div>
              <div class="_card-contrato-item card-condicoes-preco__energia">
                <div class="card-label">Tipo de Energia</div>
                <div class="card-value">{{ condicaoPreco.tipoEnergia }}</div>
              </div>
              <div class="_card-contrato-item card-condicoes-preco">
                <div class="card-label">PLD</div>
                <div class="card-value">
                  {{ condicaoPreco.pld | round(2) | R$ }}
                </div>
              </div>
              <div class="_card-contrato-item card-condicoes-preco">
                <div class="card-label">Spread de Compra</div>
                <div class="card-value">
                  {{ condicaoPreco.spreadDeCompra | round(2) | R$ }}
                </div>
              </div>
              <div class="_card-contrato-item card-condicoes-preco">
                <div class="card-label">Preço de Compra</div>
                <div class="card-value">
                  {{ condicaoPreco.precoDeCompra | round(2) | R$ }}
                </div>
              </div>
              <div class="_card-contrato-item card-condicoes-preco">
                <div class="card-label d-flex">
                  Spread Ajustado
                  <div
                    v-if="spreadCompraAjustado === condicaoPreco.spreadDeCompra"
                    class="ms-2"
                  >
                    <tooltip
                      width="300px"
                      arrowAlign="up"
                      alignVertical="25px"
                      alignHorizontal="-50px"
                    >
                      <img
                        slot="header"
                        alt="Informação"
                        class="icon-duvida"
                        src="@/assets/icons/svg/duvida.svg"
                      />
                      <p slot="body">
                        O spread Ajustado deve ser diferente do Spread de
                        Compra.
                      </p>
                    </tooltip>
                  </div>
                </div>
                <div class="card-value">
                  <vue-numeric
                    :minus="true"
                    :precision="2"
                    currency="R$"
                    placeholder="R$ 00,00"
                    :class="
                      spreadCompraAjustado != condicaoPreco.spreadDeCompra
                        ? 'input-field-card'
                        : 'input-field-card-red'
                    "
                    decimal-separator=","
                    thousand-separator="."
                    v-model="spreadCompraAjustado"
                  />
                </div>
              </div>
              <div class="_card-contrato-item card-condicoes-preco">
                <div class="card-label">Preço Ajustado</div>
                <div class="card-value">
                  {{ precoAjustado | round(2) | R$ }}
                </div>
              </div>
            </div>
          </template>
        </card-base>
      </div>
    </div>
    <div>
      <div class="col-md-12">
        <div class="title-folding mb-3">Análise de Recompra</div>
        <div class="container-cards">
          <div class="row">
            <div class="col-md-4">
              <card-base>
                <template #header>Faturamento Sem Recompra</template>
                <template #body>
                  <div class="card-body card-body-recompra">
                    <div class="_card-contrato-item card-faturamento">
                      <div class="card-label">Solic. Líq. (MWh)</div>
                      <div class="card-value">
                        {{ analiseContratoPai.solicitadoLiquido | round(3) }}
                      </div>
                    </div>
                    <div class="_card-contrato-item card-faturamento">
                      <div class="card-label">Preço</div>
                      <div class="card-value">
                        {{ analiseContratoPai.precoOriginal | round(2) | R$ }}
                      </div>
                    </div>
                    <div class="_card-contrato-item card-faturamento">
                      <div class="card-label">Notional</div>
                      <div class="card-value">
                        {{ analiseContratoPai.notional | round(2) | R$ }}
                      </div>
                    </div>
                  </div>
                </template>
              </card-base>
            </div>
            <div class="col-md-4">
              <card-base>
                <template #header>Recompra</template>
                <template #body>
                  <div class="card-body card-body-recompra">
                    <div class="_card-contrato-item card-faturamento">
                      <div class="card-label">Volume (MWh)</div>
                      <div class="card-value">
                        <vue-numeric
                          :minus="false"
                          :precision="3"
                          decimal-separator=","
                          thousand-separator="."
                          :empty-value="0"
                          placeholder="00,000"
                          v-model="recompraVolume"
                          class="input-field-card max-w-120px"
                        />
                      </div>
                    </div>
                    <div class="_card-contrato-item card-faturamento">
                      <div class="card-label">Preço</div>
                      <div class="card-value">
                        {{ precoDeCompraFinal | round(2) | R$ }}
                      </div>
                    </div>
                    <div class="_card-contrato-item card-faturamento">
                      <div class="card-label">Notional</div>
                      <div class="card-value">
                        {{ recompraNotional | round(2) | R$ }}
                      </div>
                    </div>
                  </div>
                </template>
              </card-base>
            </div>
            <div class="col-md-4">
              <card-base colorHeader="#EC6623">
                <template #header>Faturamento Com Recompra</template>
                <template #body>
                  <div class="card-body card-body-recompra">
                    <div class="_card-contrato-item card-faturamento">
                      <div class="card-label">Volume (MWh)</div>
                      <div class="card-value">
                        {{ contratoRecompra.volumeFinal | round(3) }}
                      </div>
                    </div>
                    <div class="_card-contrato-item card-faturamento">
                      <div class="card-label">Preço</div>
                      <div class="card-value">
                        {{ contratoRecompra.precoFinal | round(2) | R$ }}
                      </div>
                    </div>
                    <div class="_card-contrato-item card-faturamento">
                      <div class="card-label">Notional</div>
                      <div class="card-value">
                        {{ contratoRecompra.notionalFinal | round(2) | R$ }}
                      </div>
                    </div>
                  </div>
                </template>
              </card-base>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <contratos-filhos-recompra
          v-if="contrato.rateio"
          :analiseContratosFilhos="analiseContratosFilhos"
          :volumeComRecompraPai="contratoRecompra.volumeFinal"
          @getContratosFilhosAjustados="getContratosFilhosAjustados"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12" v-if="spreadCompraAjustado">
        <card-base>
          <template #header>Solicitação de Ajuste de Spread</template>
          <template #body>
            <div class="card-body">
              <div class="_card-contrato-item card-justificativa">
                <div class="card-label">Alçada Competente</div>
                <div class="card-value">
                  {{ getPerfilAprovadorSpreadRecompra.nome || "--" }}
                </div>
              </div>
              <div class="_card-contrato-item card-justificativa">
                <div class="card-label">Justificativa</div>
                <div class="card-value">
                  <textarea
                    style="width: 100%"
                    class="input-field-card"
                    v-model.trim="recompraFinal.justificativa"
                  />
                </div>
              </div>
            </div>
          </template>
        </card-base>
      </div>
    </div>
  </div>
</template>
<script>
import CardBase from "@/components/cards/cardBase.vue";
import OverlayLoader from "@/components/OverlayLoader.vue";
import ContratoCardsRecompra from "@/components/recompra/ContratoCardsRecompra.vue";
import ContratosFilhosRecompra from "@/components/recompra/ContratosFilhosRecompra.vue";
import Tooltip from "@/components/Tooltip/Tooltip.vue";
import { CONFIRMATION } from "@/constants/strings";
import { VOLUME } from "@/filters/formatters";
import RecompraService from "@/services/RecompraService";
import {
  divideValues,
  multiplyValues,
  subtractValues,
  sumValues,
  volumeTrunc,
} from "@/utils/functionsUtils";
import VueNumeric from "vue-numeric";
import { mapGetters } from "vuex";

export default {
  props: {
    contrato: Object,
  },
  data() {
    return {
      loading: false,
      condicaoPreco: {
        submercado: "--",
        tipoEnergia: "--",
        pld: null,
        spreadDeCompra: 0,
        precoDeCompra: 0,
      },
      spreadCompraAjustado: 0,
      precoAjustado: undefined,
      precoFinal: undefined,
      analiseContratoPai: {
        apelido: "",
        cnpj: "",
        codigo: "",
        isAgrupador: true,
        notional: 0,
        precoOriginal: 0,
        solicitadoLiquido: 0,
      },
      analiseContratosFilhos: [],
      contratosFilhosAjustados: [],
      recompraVolume: 0,
      recompraNotional: 0,
      precoDeCompraFinal: 0,
      contratoRecompra: {
        codigo: "",
        volumeRecompra: 0,
        precoRecompra: 0,
        notionalRecompra: 0,
        volumeFinal: 0,
        precoFinal: 0,
        notionalFinal: 0,
        volume: 0,
        preco: 0,
      },
      recompraFinal: {
        contratosRecompra: [],
        spread: 0,
        spreadCompraAjustado: null,
        spreadFinal: 0,
        pld: 0,
        justificativa: "",
      },
    };
  },
  created() {
    this.$store.dispatch("ActionLoadAcadasSpreadRecompra");
  },
  computed: {
    ...mapGetters(["getPerfilAprovadorSpreadRecompra"]),
    diferenca() {
      const { solicitadoLiquido, solicitadoBruto } =
        this.contrato.calculoResultado;
      const diferenca = subtractValues(solicitadoLiquido, solicitadoBruto);

      if (Math.sign(diferenca) === -1) {
        return 0;
      }

      return parseFloat(VOLUME(diferenca).replace(/"|\,|\./g, "")) / 1000;
    },
  },
  mounted() {
    this.getAnaliseRecompraPrecoUnico();
    this.getCondicoesPrecos();
    this.recompraVolume = this.diferenca;
  },
  watch: {
    spreadCompraAjustado() {
      this.calcularRecompra();
    },
    recompraVolume: function () {
      this.calcularRecompra();
    },
  },
  methods: {
    getContratosFilhosAjustados(value) {
      this.contratosFilhosAjustados = value;
    },
    calcularRecompra() {
      if (
        this.spreadCompraAjustado &&
        this.condicaoPreco &&
        this.condicaoPreco.pld
      ) {
        this.precoAjustado =
          parseFloat(this.condicaoPreco.pld) +
          parseFloat(this.spreadCompraAjustado);
      } else {
        this.precoAjustado = undefined;
      }
      this.precoDeCompraFinal =
        this.precoAjustado ?? this.condicaoPreco.precoDeCompra;
      this.recompraNotional = multiplyValues(
        this.recompraVolume,
        this.precoDeCompraFinal
      );

      this.contratoRecompra.volumeFinal = subtractValues(
        this.analiseContratoPai.solicitadoLiquido,
        this.recompraVolume
      );

      this.contratoRecompra.notionalRecompra = this.recompraNotional;
      this.contratoRecompra.volumeRecompra = this.recompraVolume;
      this.contratoRecompra.volume = this.recompraVolume;

      /*
      Cálculo do Preço Final com base na planilha de Cálculo Notional:
      (
        (Take Mínimo * Preço Original) - (Montante A Reduzir * Preço-PLD-Spread)
      )
        ÷
      (Take Mínimo - Montante A Reduzir)
      */
      this.contratoRecompra.precoFinal = divideValues(
        subtractValues(
          multiplyValues(
            this.analiseContratoPai.solicitadoLiquido,
            this.analiseContratoPai.precoOriginal
          ),
          multiplyValues(this.recompraVolume, this.precoDeCompraFinal)
        ),
        this.contratoRecompra.volumeFinal
      );

      /*
      Pode ser calculado por:
        Volume Final * Preço Final
      Ou
        Notional Original - Notional Recompra
      */
      this.contratoRecompra.notionalFinal = subtractValues(
        this.analiseContratoPai.notional,
        this.contratoRecompra.notionalRecompra
      );
      this.analiseContratosFilhos = this.analiseContratosFilhos.map(
        (contratoFilho) => {
          contratoFilho.volume = contratoFilho.volumeRecompra;
          contratoFilho.preco = this.precoDeCompraFinal;
          contratoFilho.precoFinal = this.contratoRecompra.precoFinal;
          contratoFilho.novoNotional = multiplyValues(
            contratoFilho.volumeFinal,
            this.contratoRecompra.precoFinal
          );
          return contratoFilho;
        }
      );
      this.contratoRecompra.preco = this.precoDeCompraFinal;
      this.contratoRecompra.precoRecompra =
        this.analiseContratoPai.precoOriginal;
      this.contratoRecompra.codigo = this.analiseContratoPai.codigo;
      this.recompraFinal.spread = this.condicaoPreco.spreadDeCompra;
      this.recompraFinal.spreadCompraAjustado = this.spreadCompraAjustado;
      if (this.recompraFinal.spreadCompraAjustado === 0)
        this.recompraFinal.spreadCompraAjustado = null;
      this.recompraFinal.spreadFinal =
        this.recompraFinal.spreadCompraAjustado ?? this.recompraFinal.spread;
      this.recompraFinal.pld = this.condicaoPreco.pld;
      if (!this.spreadCompraAjustado) this.recompraFinal.justificativa = "";
      this.recompraFinal.contratosRecompra = [this.contratoRecompra];

      if (this.recompraFinal.spreadCompraAjustado) {
        let difSpread = subtractValues(
          this.recompraFinal.spreadCompraAjustado,
          this.recompraFinal.spread
        );
        if (Math.sign(difSpread) === -1) {
          difSpread = difSpread * -1;
        }
        this.$store.dispatch("ActionCheckAprovadorSpreadRecompra", difSpread);
      }
    },
    getCondicoesPrecos() {
      RecompraService.getRecompraCondicaoPreco({
        idContrato: this.contrato.id,
      })
        .then(({ data }) => {
          this.condicaoPreco = data;
          this.calcularRecompra();
          this.loading = false;
        })
        .catch(() => {
          this.loadingPreco = false;
          this.$emit("closeModal");
          return this.mxToggleErrorMessageAlert(
            "Condições de preço de análise de recompra não disponíveis"
          );
        });
    },
    getAnaliseRecompraPrecoUnico() {
      this.loading = true;
      RecompraService.getAnaliseRecompraPrecoUnico({
        idContrato: this.contrato.id,
      })
        .then(({ data }) => {
          this.analiseContratoPai = data.find((g) => g.isAgrupador);
          this.analiseContratoPai.solicitadoLiquido =
            this.contrato.calculoResultado.solicitadoLiquido;
          this.analiseContratoPai.notional = multiplyValues(
            this.analiseContratoPai.solicitadoLiquido,
            this.analiseContratoPai.precoOriginal
          );
          this.analiseContratosFilhos = data
            .filter((g) => !g.isAgrupador)
            .map((g) => {
              g.volumeFinal = g.solicitadoLiquido;
              return g;
            });
          this.calcularRecompra();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$emit("closeModal");
          return this.mxToggleErrorMessageAlert(
            "Análise de recompra não disponível"
          );
        });
    },
    efetuarRecompraPrecoUnico() {
      this.$emit("finishLoading", true);
      this.loading = true;
      if (
        !this.contratoRecompra.volumeFinal ||
        !this.contratoRecompra.precoFinal
      ) {
        this.loading = false;
        this.$emit("finishLoading", false);
        return this.mxToggleErrorMessageAlert("Recompra inválida.");
      }
      if (volumeTrunc(this.contratoRecompra.volumeFinal) === 0.0) {
        this.loading = false;
        this.$emit("finishLoading", false);
        return this.mxToggleErrorMessageAlert(
          "Não há volume para faturamento com recompra"
        );
      }
      if (
        (this.recompraFinal.justificativa == "" ||
          this.recompraFinal.justificativa == null) &&
        this.spreadCompraAjustado
      ) {
        this.$emit("finishLoading", false);
        this.loading = false;
        return this.mxToggleErrorMessageAlert(
          "Justificativa não pode ficar sem preenchimento."
        );
      }
      if (
        this.recompraFinal.spread === this.recompraFinal.spreadCompraAjustado
      ) {
        this.$emit("finishLoading", false);
        this.loading = false;
        return this.mxToggleErrorMessageAlert(
          "O spread Ajustado deve ser diferente do Spread de Compra."
        );
      }

      if (this.recompraVolume === 0) {
        this.$emit("finishLoading", false);
        this.loading = false;
        return this.mxToggleErrorMessageAlert(
          "Campo Volume não pode ser zero!"
        );
      }

      if (this.contrato.rateio) {
        const total = this.contratosFilhosAjustados
          .map(({ volumeFinal }) => volumeFinal)
          .reduce((total, numero) => sumValues(total, numero), 0);

        if (subtractValues(this.contratoRecompra.volumeFinal, total) != 0) {
          this.$emit("finishLoading", false);
          this.loading = false;
          return this.mxToggleErrorMessageAlert(
            "Distribuição de volumes nos contratos de rateio inválida."
          );
        }
        this.recompraFinal.contratosRecompra =
          this.contratosFilhosAjustados.map((item) => ({
            codigo: item.codigo,
            preco: item.preco,
            volumeFinal: item.volumeFinal,
            precoFinal: item.precoFinal,
          }));
      }

      RecompraService.efetuarRecompraPrecoUnico(
        this.contrato.id,
        this.recompraFinal
      )
        .then(() => {
          this.mxToggleToast({
            message: CONFIRMATION.RECOMPRA.EXECUTE,
          });
          setTimeout(() => {
            document.location.reload(true);
          }, 2000);
        })
        .catch((error) => {
          this.$emit("finishLoading", false);
          if (error.response.status === 406) {
            this.mxToggleErrorMessageAlert(error.response.data.message);
          } else {
            this.mxToggleErrorMessageAlert(
              "Ocorreu um erro ao tentar efetuar a recompra"
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  filters: {
    VOLUME,
  },
  components: {
    Tooltip,
    CardBase,
    VueNumeric,
    OverlayLoader,
    ContratoCardsRecompra,
    ContratosFilhosRecompra,
  },
};
</script>
<style lang="scss" scoped>
.max-w-120px {
  max-width: 120px !important;
}
.wrapper-recompra {
  padding: 20px;
  position: relative;
}
.row {
  display: flex;
}
.row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}
.card-body {
  flex: 2;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}
._card-contrato-item {
  display: flex;
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 30px;
  flex-direction: column;
  justify-content: space-between;
}
.card-condicoes-preco {
  width: 12.5%;
  @include mq-value(890px) {
    width: 33.33%;
    margin-bottom: 15px;
  }
  @include mq-value(600px) {
    width: 50%;
  }
  @include mq-value(400px) {
    width: 100%;
  }
  &__energia {
    width: 25%;
  }
}
.card-faturamento {
  margin-bottom: 15px;
}
.card-label {
  color: #544943;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 24px;
}
.card-value {
  font-size: 16px;
  color: #2a2a2a;
  font-weight: 500;
  line-height: 24px;
}
.title-folding {
  width: 100%;
  margin-top: 40px;
  font-weight: 700;
  padding: 10px 30px;
  border-radius: 24px;
  color: $color--white;
  background: $color--blue-super-dark;
}
.card-justificativa {
  &:nth-child(1) {
    width: 20%;
  }
  &:nth-child(2) {
    width: 80%;
  }
  @include mq-value(800px) {
    &:nth-child(1) {
      width: 100%;
      margin-bottom: 10px;
    }
    &:nth-child(2) {
      width: 100%;
    }
  }
}

.is-invalid-label {
  color: red;
  font-size: 10px;
  width: auto !important;
  display: inline-block;
}

.input-field-card-red {
  border-radius: 8px;
  border: 1px solid red;
}
</style>
