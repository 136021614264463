<template>
  <div class="prices-different" style="position: relative">
    <OverlayLoader v-if="loading" />
    <contrato-cards-recompra :contrato="contrato" />
    <analise-recompra-precos-diferentes
      :contrato="contrato"
      @getResultadoRecompra="getResultadoRecompra"
    />
  </div>
</template>
<script>
import OverlayLoader from "@/components/OverlayLoader.vue";
import RecompraService from "../../../services/RecompraService";
import ContratoCardsRecompra from "../ContratoCardsRecompra.vue";
import AnaliseRecompraPrecosDiferentes from "./AnaliseRecompraPrecosDiferentes.vue";
export default {
  created() {
    this.$store.dispatch("ActionLoadAcadasSpreadRecompra");
  },
  components: {
    OverlayLoader,
    ContratoCardsRecompra,
    AnaliseRecompraPrecosDiferentes,
  },
  props: {
    contrato: Object,
  },
  data() {
    return {
      recompra: {},
      isValid: true,
      loading: false,
    };
  },
  methods: {
    getResultadoRecompra(value, isValid) {
      this.recompra = value;
      this.isValid = isValid;
    },
    efetuarRecompraPrecosDiferentes() {
       this.$emit('finishLoading',true)
      if (!this.isValid) {
        this.loading = false;
         this.$emit('finishLoading',false)
        return this.mxToggleErrorMessageAlert("Preencha a Justificativa");
      }

      for (let i = 0; i < this.recompra.contratosRecompra.length; i++) {
        const contrato = this.recompra.contratosRecompra[i];
        if (contrato.spread === contrato.spreadCompraAjustado) {
          this.loading = false;
           this.$emit('finishLoading',false)
          return this.mxToggleErrorMessageAlert(
            "O spread Ajustado deve ser diferente do Spread de Compra."
          );
        }
      }
      RecompraService.efetuarRecompraPrecosDiferentes(
        this.contrato.id,
        this.recompra
      )
        .then(() => {
          document.location.reload(true)
        } )
        .catch((error) => {
           this.$emit('finishLoading',false)
          if (error.response.status === 406) {
            this.mxToggleErrorMessageAlert(error.response.data.message);
          } else {
            this.mxToggleErrorMessageAlert(
              "Ocorreu um erro ao tentar efetuar a recompra"
            );
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="scss" scoped>
.prices-different {
  padding: 2rem;
}
</style>