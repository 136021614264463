<template>
  <div class="modal-pos-venda">
    <modal-base
      :maxWidth="'70%'"
      @closeModal="closeModal"
      data-cy="modal-fechar_modal_associar_pos_venda-pos-venda-modais"
    >
      <template #header> Associar Pós-Venda </template>
      <template #body>
        <div class="modal-pos-venda__body">
          <div class="card-pos-venda">
            <div>
              <label class="card-pos-venda__title">Apelido: </label>
              <label class="card-pos-venda__value">{{
                tableDataResponse.apelido
              }}</label>
            </div>
            <div>
              <label class="card-pos-venda__title">CNPJ: </label>
              <label class="card-pos-venda__value">{{
                tableDataResponse.cnpj | CNPJ
              }}</label>
            </div>
            <div>
              <label class="card-pos-venda__title">Razão Social: </label>
              <label class="card-pos-venda__value">{{
                tableDataResponse.razaoSocial
              }}</label>
            </div>
            <div>
              <label class="card-pos-venda__title">Nome Fantasia: </label>
              <label class="card-pos-venda__value">{{
                tableDataResponse.nomeFantasia
              }}</label>
            </div>
          </div>

          <field-search
            style="margin-top: 32px"
            @getSearchValue="getSearchValue"
            :placeholder="'Busque por nome ou e-mail de pós-venda'"
          ></field-search>
          <div v-elementAsyncLoader="modalLoading">
            <data-table
              :card="false"
              :stripes="false"
              :pagination="true"
              @setPage="setPage"
              :lineClickable="true"
              @setPerPage="setPerPage"
              :data="dataTablePosVenda"
              @lineClicked="lineClicked"
              class="mt-4 pos-venda-table"
              :paginationData="paginationData"
              :fields="TablePosVendaVinculoModel.getTableFields()"
            >
              <div slot="selecionar" slot-scope="{ row }">
                <div class="wrapper-radio">
                  <input
                    type="radio"
                    :value="row.id"
                    name="selecionar"
                    v-model="lineSelected"
                    @click="togglePosVenda(row)"
                    data-cy="input-associar_pos_venda_linha_selecionada-pos-venda-modais"
                  />
                </div>
              </div>
            </data-table>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="button-bar-modal">
          <button
            @click="closeModal"
            class="btn btn-secondary-outlined buttons"
            data-cy="button-cancelar_associar_pos_venda-pos-venda-modais"
          >
            Cancelar
          </button>
          <button
            v-if="$can('ASSOCIAR_POS_VENDA_VISUALIZAR_ASSOCIAR')"
            style="position: relative;"
            :disabled="overlayLoading"
            @click="associatePosVenda"
            class="btn btn-associatePosVenda buttons"
            data-cy="button-associar_pos_venda-pos-venda-modais"
          >
            <OverlayLoader v-if="overlayLoading"/>
            Salvar
          </button>
        </div>
      </template>
    </modal-base>
  </div>
</template>
<script>
import { CNPJ } from "@/filters/formatters";
import TypesToastENUM from "@/enums/TypesToastENUM";
import EmpresaService from "@/services/EmpresaService";
import ModalBase from "@/components/modals/ModalBase.vue";
import DataTable from "@/components/tables/DataTable.vue";
import { CONFIRMATION, ERROR } from "@/constants/strings";
import FieldSearch from "@/components/fields/FieldSearch.vue";
import TablePosVendaVinculoModel from "@/models/tablesModels/TablePosVendaVinculoModel";
import OverlayLoader from "@/components/OverlayLoader.vue";
export default {
  props: {
    tableData: Object,
  },
  components: {
    ModalBase,
    DataTable,
    FieldSearch,
    OverlayLoader
  },
  filters: {
    CNPJ,
  },
  data() {
    return {
      overlayLoading:false,
      lineSelected: null,
      idPosVendaRequest: null,
      modalLoading: undefined,
      paginationData: undefined,
      dataTablePosVenda: new Array(),
      tableModel: new TablePosVendaVinculoModel(),
      paginationFilters: {
        page: 0,
        size: 10,
        desc: true,
      },
    };
  },
  mounted() {
    this.getPosVendaFull();
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    setPage(page) {
      this.paginationFilters.page = page;
      this.getPosVendaFull();
    },
    setPerPage(value) {
      this.paginationFilters.size = value;
      this.getPosVendaFull();
    },
    associatePosVenda() {
      this.overlayLoading = true
      if (this.tableData.posVenda) {
        this.overlayLoading = false
        if (this.tableData.posVenda.id === this.idPosVendaRequest) {
          this.overlayLoading = false
          this.mxToggleToast({
            title: "Atenção!",
            message: ERROR.ASSOCIATE_POS_VENDA,
            type: TypesToastENUM.WARNING,
          });
          return;
        }
      }

      this.modalLoading = EmpresaService.associarPosVenda({
        id: this.tableData.id,
        idPosVenda: this.idPosVendaRequest,
      })
        .then(() => {
          this.overlayLoading = false
          this.mxToggleToast({
            message: CONFIRMATION.POS_VENDA.CREATED,
          });
          this.$emit("closeModal");
          this.$emit("getEmpresasFull");
        })
        .catch((error) =>  {
        this.overlayLoading = false
        this.mxHandleRequestError(error)} );
    },
    getPosVendaFull(filtro, resetPage = false) {
      if (resetPage) {
        this.paginationFilters.page = 0;
      }
      this.modalLoading = EmpresaService.getPosVenda({
        filtro,
        ...this.paginationFilters,
      })
        .then(({ data, pagination }) => {
          this.dataTablePosVenda = data;
          this.paginationData = pagination;
          if (this.tableData.posVenda && this.tableData.posVenda.id) {
            this.dataTablePosVenda.forEach(({ id: posVenda }) => {
              if (posVenda == this.tableData.posVenda.id) {
                this.lineSelected = posVenda;
                this.idPosVendaRequest = posVenda;
              }
            });
          }
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    lineClicked({ id }) {
      this.lineSelected = id;
      this.idPosVendaRequest = id;
    },
    togglePosVenda(row) {
      row.selecionar = true;
      this.idPosVendaRequest = row.id;
    },
    getSearchValue(value) {
      this.getPosVendaFull(value, true);
    },
  },
  computed: {
    TablePosVendaVinculoModel: () => TablePosVendaVinculoModel,
    tableDataResponse() {
      return this.tableData;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-radio {
  display: flex;
  justify-content: center;
}
input[type="radio"] {
  width: 20px;
  height: 20px;
}
.button-bar-modal {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  button {
    margin: 10px;
    width: 140px;
    height: 34px;
    border-radius: 9px;
    line-height: 100%;
  }
}
.modal-pos-venda {
  &__body {
    padding: 24px;
  }
}

.pos-venda-table {
  border: 1px solid #b4b7ba;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  padding: 15px;
}
.card-pos-venda {
  border: 1px solid #b4b7ba;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  padding: 20px;
  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #222222;
    margin-right: 10px;
  }
}

.btn-associatePosVenda {
  color: #fffaf8 !important;
  background: #ec6623 !important;
  border: 1px solid #ec6623 !important;
  padding: 0px 75.5px !important;
  width: 20% !important;
  border-radius: 12px !important;
}
</style>
