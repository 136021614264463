<template>
  <div class="page-sincronizacao-empresas-central">
    <modal-sincronizar-divergencias
      v-if="showModalSincronizarDivergencias"
      @close="showModalSincronizarDivergencias = false"
      @sincronizarDivergenciasWbc="submitSyncCompanies"
    />
    <page-panel v-elementAsyncLoader="tableLoading">
      <template #header>Sincronizar Empresas com Central de Cadastros</template>
      <template #body>
        <div
          style="position: relative;"
          class="page-sincronizacao-empresas-central__body"
        >
          <OverlayLoader v-if="loading" />
          <modal-base
            width="688px"
            @closeModal="closeModal"
            data-cy="modal-fechar_modal"
            v-if="showModalNaoSincronizados"
          >
            <template #header>
              Não sincronizados
            </template>
            <template #body>
              <div class="body-container">
                <div class="modal_message">
                  Os contratos abaixo não podem ser sincronizados,
                  pois estão com o status "<b>Finalizado</b>" ou "<b>Em análise pelo cliente-hotsite</b>"
                </div>
                <div class="itens-wrapper">
                  <div
                    
                    v-for="item in tableData"
                    :key="item.id"
                  >
                    <div class="item-sync" v-if="sincronizou(item)">{{item.razaoSocial}}</div>
                  </div>
                </div>
              </div>
            </template>
            <template #footer>
              <div class="btn-container-footer">
                <button
                  @click="closeModal"
                  class="btn btn-action-primary"
                >OK</button>
              </div>
            </template>
          </modal-base>
          <table class="_table">
            <thead>
              <tr>
                <td>CNPJ</td>
                <td>Razão social</td>
                <td>
                  Selecionar
                  <span>(<u
                      style="cursor: pointer"
                      @click="selectedAllCompanies()"
                    >todos da página</u>)</span>
                </td>
              </tr>
            </thead>
            <tbody v-if="IS_EMPTY_DATA_TABLE">
              <tr>
                <td
                  class="text-center"
                  colspan="5"
                  style="text-align: center"
                >
                  Nenhum resultado encontrado
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="(item, index) in tableData"
                :key="item.id"
              >
                <td
                  v-for="(
                    { name }, indexColumn
                  ) in TableSincronizacaoEmpresasModel.getTableFields()"
                  :key="name"
                >
                  <span v-if="name !== 'selecionar'">{{ item[name] }}</span>
                  <input
                    v-else
                    v-model="item.selected"
                    @click.stop="selectedCompanyRow(item)"
                    type="checkbox"
                    data-cy="input-sincronizacao-empresas-page"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="box-pagination mt-3 mb-4">
            <pagination
              v-if="paginationData && tableData.length > 0"
              @perPage="setPerPage"
              @page="setPage"
              :info="paginationData"
            ></pagination>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="page-sincronizacao-empresas-central__footer">
          <button
            :disabled="IS_EMPTY_DATA_TABLE || loading"
            class="btn btn-secondary-full-filled"
            @click=" ()=>{!loading ? showModalSincronizarDivergencias = true : null } "
          >
            Sincronizar com a Central de Cadastros
          </button>
        </div>
      </template>
    </page-panel>
  </div>
</template>

<script>
import { CONFIRMATION } from "@/constants/strings";
import TypesToastENUM from "@/enums/TypesToastENUM";
import ModalBase from "@/components/modals/ModalBase.vue";
import EmpresaService from "@/services/EmpresaService";
import OverlayLoader from "@/components/OverlayLoader.vue";
import PagePanel from "@/components/panels/PagePanel.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import TableSincronizacaoEmpresasModel from "@/models/tablesModels/TableSincronizacaoEmpresasModel";
import ModalSincronizarDivergencias from "../divergencias-wbc-billing/components/ModalSincronizarDivergencias.vue";

export default {
  data() {
    return {
      paginationFilters: {
        page: 0,
        size: 10,
      },
      loading: false,

      tableData: new Array(),
      tableLoading: undefined,
      paginationData: undefined,
      allCompaniesSelected: false,
      showModalSincronizarDivergencias: false,
      showModalNaoSincronizados: false,
      naoSincronizados: [],
    };
  },
  methods: {
    sincronizou(value) {
      if (this.naoSincronizados.includes(value.id)) return true;
      return false;
    },
    closeModal() {
      this.showModalNaoSincronizados = false;
      this.allCompaniesSelected = false;
      this.paginationFilters.page = 0;
      this.getAllEmpresasInconsistentes();
    },
    getAllEmpresasInconsistentes() {
      this.tableLoading = EmpresaService.getInconsistentes(
        this.paginationFilters
      ).then(({ data, pagination }) => {
        this.tableData = data;
        this.paginationData = pagination;
      });
    },
    setPerPage(value) {
      this.paginationFilters = {
        ...this.paginationFilters,
        page: 0,
        size: value,
      };
      this.getAllEmpresasInconsistentes();
    },
    setPage(page) {
      this.paginationFilters = {
        ...this.paginationFilters,
        page,
      };
      this.getAllEmpresasInconsistentes();
    },
    selectedCompanyRow(item) {
      const companyIndex = this.tableData.findIndex(
        (company) => company.id === item.id
      );

      this.tableData[companyIndex].selected =
        !this.tableData[companyIndex].selected;
    },
    selectedAllCompanies() {
      this.allCompaniesSelected = !this.allCompaniesSelected;
      this.tableData = this.tableData.map((item) => {
        return {
          ...item,
          selected: this.allCompaniesSelected,
        };
      });
    },
    submitSyncCompanies() {
      this.showModalSincronizarDivergencias = false;
      const isExistSyncCompany = this.tableData.some((item) => item.selected);
      if (!isExistSyncCompany) {
        this.mxToggleToast({
          title: `Alerta`,
          message: CONFIRMATION.SINCRONIZACAO_EMPRESA.SELECTED_EMPRESA,
          type: TypesToastENUM.DANGER,
        });
        return;
      }
      const empresas = new Array();
      this.tableData.forEach((item) => {
        if (item.selected) {
          empresas.push(item.id);
        }
      });
      this.loading = true;
      EmpresaService.sendEmpresasIncosistentes({ empresas })
        .then((response) => {
          this.loading = false;
          if (response.data.length > 0) {
            this.naoSincronizados = response.data;
            this.showModalNaoSincronizados = true;
          } else {
            this.mxToggleToast({
              message: CONFIRMATION.SINCRONIZACAO_EMPRESA.EMPRESAS_SYNC,
            });
            this.allCompaniesSelected = false;
            this.paginationFilters.page = 0;
            this.getAllEmpresasInconsistentes();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.mxHandleRequestError(error, "SINCRONIZACAO_EMPRESA");
        });
    },
  },
  computed: {
    TableSincronizacaoEmpresasModel: () => TableSincronizacaoEmpresasModel,
    IS_EMPTY_DATA_TABLE() {
      return this.tableData.length === 0;
    },
  },
  mounted() {
    this.getAllEmpresasInconsistentes();
  },
  components: {
    PagePanel,
    Pagination,
    ModalSincronizarDivergencias,
    OverlayLoader,
    ModalBase,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_colors.scss";

.page-sincronizacao-empresas-central {
  padding-top: 24px;

  &__body {
    padding: 30px;
  }

  &__footer {
    display: flex;
    justify-content: end;

    & button {
      font-weight: bold;
    }
  }
}

._table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  thead {
    background: $color--primary-white;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 1px;
    color: #2a2a2a;

    @include ml-hd {
      font-size: responsive(1rem);
    }
    td {
      padding: 15px 15px 3px 10px;
      margin: 5px;
      border-bottom: #2a2a2a 1px solid;
    }
  }
  tbody {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 1px;
    color: #2a2a2a;
    @include ml-hd {
      font-size: responsive(1rem);
    }
    td {
      padding: 6px 10px;
    }
  }
}

.box-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px !important;
}
.modal_message {
  padding: 30px;
  text-align: center;
}
.btn-container-footer {
  width: 100%;
  padding: 0 30px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
}
.item-sync{
  border-top:solid 1px #ccc;
  padding: 5px 20px;
}
</style>
