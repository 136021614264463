<template>
  <div>
    <modal-base
      width="71vw"
      @closeModal="closeModal()"
      data-cy="modal-fechar_modal_editar_solicitado_liquido-modais"
    >
      <template #header> Editar Solicitado Líquido </template>
      <template #body>
        <div class="body-container" v-elementAsyncLoader="loadingModal">
          <field-solicitado-liquido-original
            :value="solicitadoLiquidoOriginal"
          />
          <field-novo-solicitado-liquido ref="refNovoSolicitadoField" />
          <field-alcada :descricao="decricaoAlcadaCorrepondente" />
        </div>
        <div class="justify-section">
          <field-justificativa
            ref="refJustificativaField"
            :widthField="95"
            titleField="Justificativa"
            :heightField="180"
          />
        </div>
        <div class="section-arquivo">
          <div class="container-upload">
            <input-upload-solicitado-liquido @formatFiles="handleFile" />
          </div>
          <div class="ms-4 container-table">
            <span class="labelTitle">Arquivos adicionados:</span>
            <div class="mt-3 mb-2" v-if="arquivos.length <= 0">
              Nenhum arquivo adicionado.
            </div>
            <div class="mt-1" v-else>
              <span class="header-title"> Nome dos arquivos </span>
              <hr class="mt-2" />
            </div>
            <div
              v-for="(arquivo, index) in arquivos"
              :key="`arquivo_adicionado_${index}`"
              class="
                d-flex
                file-description
                align-items-center
                justify-content-between
              "
            >
              <span class="pt-1">
                <i class="icon icon-file me-2"></i> {{ arquivo.name }}
              </span>
              <div>
                <i
                  @click="excluirArquivos(index)"
                  class="icon icon-lixo cursop d-flex"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="container-footer">
          <button
            @click="closeModal()"
            class="mx-2 btn btn-primary-outlined btn-margin"
            data-cy="button-fechar_modal_aprovacao-modais"
          >
            Cancelar
          </button>
          <button
            :disabled="isDisable"
            @click="submitSolicitado"
            class="mx-3 btn btn-primary-full-filled btn-common"
            data-cy="button-salvar_solicitado_liquido-modais"
          >
            Salvar
          </button>
        </div> </template
      >s
    </modal-base>
  </div>
</template>
<script>
import Services from "@/services/Services";
import { CONFIRMATION } from "@/constants/strings";
import FieldAlcada from "./fields/FieldAlcada.vue";
import ContratoService from "@/services/ContratoService";
import ModalBase from "@/components/modals/ModalBase.vue";
import FieldJustificativa from "./fields/FieldJustificativa.vue";
import FieldNovoSolicitadoLiquido from "./fields/FieldNovoSolicitadoLiquido.vue";
import FieldSolicitadoLiquidoOriginal from "./fields/FieldSolicitadoLIquidoOriginal.vue";
import InputUploadSolicitadoLiquido from "@/components/contratos/modais/fields/InputUploadSolicitadoLiquido.vue";
import { removeByteOrderMarkFromFiles } from "@/utils/functionsUtils";

export default {
  props: {
    modelProp: Object,
    contratoId: Number,
    linkSolicitado: String,
    solicitadoLiquidoOriginal: Number,
  },
  data() {
    return {
      alcadas: [],
      isDisable: true,
      arquivos: new Array(),
      loadingModal: undefined,
      decricaoAlcadaCorrepondente: "--",
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    submitSolicitado() {
      const justificativa = this.$refs.refJustificativaField.valueField;
      const novoSolicitadoLiquido =
        this.$refs.refNovoSolicitadoField.valueField;
      const formData = new FormData();

      formData.append("justificativa", justificativa);
      formData.append("novoSolicitadoLiquido", novoSolicitadoLiquido);
      this.arquivos.forEach((arquivo) => {
        formData.append("arquivos", arquivo);
      });

      const axiosInstance = Services.getInstanceAxiosFormData({
        baseURL: this.linkSolicitado,
      });

      this.loadingModal = axiosInstance
        .post("", formData)
        .then(() => {
          this.mxToggleToast({
            message: CONFIRMATION.SOLICITADO_LIQUIDO.CREATED,
          });
          setTimeout(() => {
            document.location.reload(true);
          }, 1000);
        })
        .catch((error) => {
          if (error.response) {
            const { status } = error.response;
            if (status === 406) {
              this.mxToggleWarningModal({
                message: CONFIRMATION.FATURAMENTO.CANT_FOLLOW,
                btnTrue: "Prosseguir",
                showBtnFalse: false,
              }).then(() => document.location.reload(true));
            }
          } else {
            this.mxHandleRequestError(error);
          }
        });
      this.closeModal();
    },
    getCalculoFaturamento() {
      this.loadingModal = ContratoService.buscarCalculoFaturamento()
        .then(({ data }) => {
          this.alcadas = data;
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "CALCULO_FATURAMENTO");
        });
    },
    changeAlcadaCorrespondente(value) {
      // Verificando as alcadas disponiveis
      const alcada = this.alcadas.filter(
        (item) => value >= item.valorMin && value <= item.valorMax
      )[0];

      if (alcada) {
        return alcada.descricao;
      } else {
        // Verificando o valor maximo de alcada
        const lastAlcada = this.alcadas[this.alcadas.length - 1];

        const { descricao, valorMin } = lastAlcada;

        // Caso se o valor maximo de alcada retornar a descrição correspondente
        const descricaoAlcada = value >= valorMin ? descricao : "--";

        return descricaoAlcada;
      }
    },
    async handleFile(payload = new Array()) {
      let files = await removeByteOrderMarkFromFiles(new Array(...payload));
      files.map((file) => {
        if (this.arquivos.findIndex(({ name }) => name == file.name) < 0) {
          this.arquivos.push(file);
        } else {
          this.mxToggleErrorMessageAlert(
            "Arquivo não suportado: os arquivos devem ser CSV"
          );
        }
      });
    },
    excluirArquivos(index) {
      this.arquivos.splice(index, 1);
    },
  },
  mounted() {
    this.getCalculoFaturamento();

    this.$watch(
      () => {
        return {
          novoSolicitadoLiquidoField:
            this.$refs.refNovoSolicitadoField.valueField,
          justificativaSolicitadoLiquido:
            this.$refs.refJustificativaField.valueField,
        };
      },
      ({ novoSolicitadoLiquidoField, justificativaSolicitadoLiquido }) => {
        const valorAlcada = Math.abs(
          novoSolicitadoLiquidoField - this.solicitadoLiquidoOriginal
        ).toFixed(2);

        this.decricaoAlcadaCorrepondente =
          novoSolicitadoLiquidoField === 0
            ? "--"
            : this.changeAlcadaCorrespondente(valorAlcada);

        const justificativaIsRequired = [
          "Aprovador Nível 1",
          "Aprovador Nível 2",
          "Aprovador Nível 3",
        ].includes(this.decricaoAlcadaCorrepondente);

        // Validação se a justificativa é obrigatoria quando for alguns do niveis.
        if (
          novoSolicitadoLiquidoField !== 0 &&
          justificativaIsRequired &&
          justificativaSolicitadoLiquido !== ""
        ) {
          this.isDisable = false;
        } else if (
          novoSolicitadoLiquidoField !== 0 &&
          this.decricaoAlcadaCorrepondente === "Pós-Venda"
        ) {
          this.isDisable = false;
        } else {
          this.isDisable = true;
        }
      }
    );
  },
  components: {
    ModalBase,
    FieldAlcada,
    FieldJustificativa,
    FieldNovoSolicitadoLiquido,
    InputUploadSolicitadoLiquido,
    FieldSolicitadoLiquidoOriginal,
  },
};
</script>
>
<style scoped>
.body-container {
  display: flex;
  justify-content: space-between;
}

.body-container,
.justify-section {
  width: 95%;
}

.body-container,
.justify-section,
.section-arquivo {
  margin: 33px 35px 35px 43px;
}

.justify-section {
  height: 220px;
  margin: 0px 30px;
}

.container-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.btn-common,
.btn-margin {
  width: 190px;
  height: 35px;
}

.btn-margin {
  margin-right: 20px;
}

.btn {
  font-weight: 700;
  padding: 4px 16px;
  border-radius: 9px;
}

.section-arquivo {
  width: 90%;
  display: flex;
}

.container-upload {
  max-width: 40%;
}

.container-table {
  width: 60%;
}

.labelTitle {
  font-size: 16px;
}

.file-description {
  word-break: break-all;
  white-space: pre-wrap;
}

.icon-file {
  font-weight: 600;
  color: #ec6623;
}

.header-title {
  font-weight: 500;
}
</style>
