<template>
  <div class="your-economy-card-item">
    <div class="title">{{ config.title }}</div>
    <div v-if="config.type === 'CURRENCY'" class="value">
      {{ config.value | CURRENCY }}
    </div>
    <div v-else-if="config.type === 'PERCENTUAL'" class="value">
      {{ config.value | PERCENTUAL }}
    </div>
    <div v-else class="value">{{ config.value }}</div>
  </div>
</template>

<script>
import { VOLUME, CURRENCY, CURRENCY_VALUE_ONLY } from "@/filters/formatters";

export default {
  props: {
    config: Object,
  },
  filters: {
    PERCENTUAL: (value) => {
      if (typeof value != "number") {
        return "--";
      }
      return `${value.toFixed(2).replaceAll(".", ",")}%`;
    },
    VOLUME,
    CURRENCY,
    CURRENCY_VALUE_ONLY,
  },
};
</script>

<style lang="scss" scoped>
.your-economy-card-item {
  width: 49%;
  border-radius: 7px;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0 #2a2a2a22;
  & .title {
    padding: 3%;
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #2a2a2a;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom: 1px solid #f86511;
  }
  & .value {
    display: flex;
    padding: 5% 0;
    font-size: 2rem;
    color: #f86511;
    font-weight: bold;
    align-items: center;
    justify-content: center;
  }
}
</style>
