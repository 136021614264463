export default class TableSincronizacaoEmpresasModel {
  static getTableFields() {
    return new Array(
      { name: "cnpj", title: "CNPJ" },
      { name: "razaoSocial", title: "Razão Social" },
      {
        name: "selecionar",
        title: "Selecionar (todos)",
      }
    );
  }
}
