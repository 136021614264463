<template>
  <div class="page-importar-lote">
    <page-painel v-if="runningImportacao" class="page-importacao-running mb-4">
      <template #body>
        <span>
          Importação em Lote em andamento, iniciada pelo usuário:
          <b>{{ nomeImportacao || "--" }}</b>
        </span>
      </template>
    </page-painel>
    <page-painel
      v-if="mensagemErroUpload && !runningImportacao"
      class="page-importacao-running mb-4"
    >
      <template #body>
        <span>
          Ocorreu um erro no último upload de arquivos:
          <b>{{ mensagemErroUpload || "--" }}</b>
        </span>
      </template>
    </page-painel>
    <page-painel>
      <template #header>Importar em Lote</template>
      <template #body>
        <div class="page-importar-lote__body">
          <div>
            <div class="row" style="position: relative">
              <overlay-loader v-if="runningImportacao || loading" />
              <div class="col">
                <input-upload-field
                  :modelProp="arquivosModel"
                  :disabled="runningImportacao || loading"
                  :fileType="['.csv']"
                  :fileSizeMatters="false"
                  @validationTriggered="stackValidation.client = $event"
                  :externallyValidated="stackValidation.server"
                />
              </div>
              <div class="col col-4 d-flex flex-column file-container">
                <span class="labelTitle">Arquivos adicionados:</span>
                <div class="mt-3" v-if="!arquivosModel.arquivos.length">
                  Nenhum arquivo adicionado
                </div>
                <div
                  v-for="(arquivo, index) in arquivosModel.arquivos"
                  :key="`arquivo_adicionado_${index}`"
                  class="mt-2 d-flex file-description align-items-center justify-content-between"
                >
                  <span> {{ arquivo.name }} </span>
                  <div>
                    <i
                      class="icon icon-lixo ms-2 cursop"
                      @click="excluirArquivos(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button
                @click="importarEmLote"
                :disabled="
                  !tableData.length ||
                  runningImportacao ||
                  loading ||
                  !stackValidation.client ||
                  !stackValidation.server
                "
                class="btn btn-secondary-full-filled mt-4"
              >
                Calcular Importação em Lote
              </button>
            </div>
          </div>
          <div class="mt-4">
            <data-table
              :stripes="true"
              :data="tableData"
              @setPage="setPage"
              :pagination="true"
              :lineClickable="false"
              @setPerPage="setPerPage"
              :paginationData="paginationData"
              :fields="TableArquivoModel.getTableFields()"
            >
              <div slot="codigoContratoReferencia" slot-scope="{ row }">
                <span v-if="row.filho">
                  > {{ row.codigoContratoReferencia }}
                </span>
                <span v-else>
                  <b>
                    {{ row.codigoContratoReferencia }}
                  </b>
                </span>
              </div>
              <div slot="icons" slot-scope="{ row }">
                <i
                  v-if="pontosImportadosCorretos.includes(row.codigoPonto)"
                  class="icon icon-check-arrow"
                />
                <img
                  v-else-if="
                    (row.filhos && row.filhos.length == 0) || row.filho
                  "
                  :src="ExclamationIcon"
                />
              </div>
            </data-table>
          </div>
        </div>
      </template>
    </page-painel>
    <modal-template
      :on="!!pontosDgData"
      @close="pontosDgData = null"
      ref="modalArquivosDg"
      :closeable="false"
      :styles="{ defaultModal: { zIndex: 7000 } }"
    >
      <template #header>Contratos DG identificados</template>
      <template #body>
        <div style="padding: 0 20px">
          <data-table
            :fields="[
              { name: 'arquivo', title: 'Arquivo' },
              { name: 'ponto', title: 'Ponto' },
              { name: 'contrato', title: 'Contrato Associado' },
            ]"
            :data="pontosDgData"
          />
        </div>
      </template>
      <template #footer>
        <div
          style="display: flex; width: 100%; justify-content: center; gap: 10px"
        >
          <button class="btn btn-remover-arquivos" @click="removerArquivosDg">
            Remover arquivos listados
          </button>
          <button
            class="btn btn-primary-full-filled"
            @click="$refs.modalArquivosDg && $refs.modalArquivosDg.close()"
            style="font-size: 14px"
          >
            Continuar
          </button>
        </div>
      </template>
    </modal-template>
  </div>
</template>

<script>
import ExclamationIcon from "@/assets/icons/svg/exclamation.svg";
import OverlayLoader from "@/components/OverlayLoader.vue";
import ModalBase from "@/components/modals/ModalBase.vue";
import ModalTemplate from "@/components/modals/ModalTemplate.vue";
import PagePainel from "@/components/panels/PagePanel.vue";
import DataTable from "@/components/tables/DataTable.vue";
import { CONFIRMATION } from "@/constants/strings";
import TableArquivoModel from "@/models/tablesModels/TableArquivoModel";
import InputUploadField from "@/pages/lote/fields/InputUploadField.vue";
import UploadService from "@/services/UploadService";
import { removeByteOrderMarkFromFiles } from "@/utils/functionsUtils";

export default {
  data() {
    return {
      idInterval: null,
      loading: true,
      nomeImportacao: null,
      arquivosModel: { arquivos: [] },
      tableData: [],
      uploadErro: false,
      mensagemErroUpload: undefined,
      runningImportacao: false,
      paginationData: undefined,
      pontosImportadosCorretos: [],
      filters: {
        page: 0,
        size: 10,
      },
      pontosDgData: null,
      stackValidation: {
        client: false,
        server: false,
      },
    };
  },
  mounted() {
    this.handleContratosNaoImportados();
    this.checarUploadArquivos();
  },
  computed: {
    TableArquivoModel: () => TableArquivoModel,
    ExclamationIcon: () => ExclamationIcon,
  },
  methods: {
    setPage(page) {
      this.filters.page = page;
      this.handleContratosNaoImportados();
    },
    setPerPage(size) {
      this.filters.size = size;
      this.handleContratosNaoImportados();
    },
    async handleFile() {
      if (!this.stackValidation.client) {
        this.stackValidation.server = false;
        return;
      }
      if (!this.arquivosModel.arquivos.length) return;

      this.loading = true;

      const files = await removeByteOrderMarkFromFiles(
        structuredClone(this.arquivosModel.arquivos)
      );

      const formData = new FormData();
      files.forEach((file) => {
        if (file.type === "text/csv") {
          formData.append("arquivos", file, file.name);
        }
      });

      try {
        const { data } = await UploadService.getPontosValidos(formData);
        this.loading = false;
        this.pontosImportadosCorretos.push(...data.flatMap((d) => d.pontos));

        const pontosDg = [];
        const errosArquivos = [];
        for (let i = 0; i < data.length; i++) {
          const arquivo = data[i];
          if (arquivo.contratos) {
            for (let j = 0; j < arquivo.contratos.length; j++) {
              const contrato = arquivo.contratos[j];
              if (contrato.classificacao === "DESCONTO GARANTIDO") {
                pontosDg.push({
                  arquivo: arquivo.nome,
                  ponto: contrato.ponto,
                  contrato: contrato.codigo,
                });
              }
            }
            continue;
          }
          if (arquivo.validationErrors) {
            const errosArquivo = {
              name: arquivo.nome,
              rows: [],
            };
            for (let j = 0; j < arquivo.validationErrors.length; j++) {
              const erro = arquivo.validationErrors[j];
              errosArquivo.rows.push({ row: erro.linha, errors: erro });
            }
            if (errosArquivo.rows.length) {
              errosArquivos.push(errosArquivo);
            }
          }
        }
        if (pontosDg.length) {
          this.pontosDgData = pontosDg;
        }
        this.stackValidation.server = !errosArquivos.length;
        if (errosArquivos.length) {
          this.mxToggleFilesError(errosArquivos);
        }
      } catch (error) {
        this.mxHandleRequestError(error);
      }
    },
    async importarEmLote() {
      if (this.runningImportacao) return;
      if (!this.arquivosModel.arquivos.length) {
        this.mxToggleErrorMessageAlert(
          "Adicione pelo menos um arquivo para realizar a importação"
        );
        return;
      }

      const bodyFormData = new FormData();
      this.arquivosModel.arquivos.forEach((arquivo) => {
        bodyFormData.append("arquivos", arquivo, arquivo.name);
      });

      this.loading = true;

      try {
        await UploadService.uploadEmLote(bodyFormData);

        this.mxToggleToast({
          message: CONFIRMATION.UPLOAD_LOTE.UPDATE,
        });

        this.arquivosModel.arquivos = [];

        this.runningImportacao = true;
        this.idInterval = setInterval(this.checarUploadArquivos, 2000);
      } catch (error) {
        this.loading = false;
        this.mxToggleErrorMessageAlert(
          error?.response?.data?.message ||
            "Houve um erro ao realizar a importação em lote"
        );
      }
    },
    async checarUploadArquivos() {
      try {
        const {
          data: { running, responsavel, progress },
        } = await UploadService.arquivosImportados();

        if (running) {
          this.runningImportacao = true;
          this.nomeImportacao = responsavel;
          if (this.idInterval === null) {
            this.idInterval = setInterval(this.checarUploadArquivos, 2000);
          }
        } else {
          this.runningImportacao = false;
          this.loading = false;
          clearInterval(this.idInterval);
          this.idInterval = null;
        }
      } catch (error) {
        this.mxHandleRequestError(error);
      }
    },
    async excluirArquivos(index) {
      this.loading = true;
      this.arquivosModel.arquivos.splice(index, 1);

      const formData = new FormData();
      this.arquivosModel.arquivos.forEach((arquivo) => {
        formData.append("arquivos", arquivo, arquivo.name);
      });

      try {
        const { data } = await UploadService.getPontosValidos(formData);
        this.pontosImportadosCorretos = data.flatMap((d) => d.pontos);
        this.loading = false;
        this.handleContratosNaoImportados();
      } catch (error) {
        this.mxHandleRequestError(error);
      }
    },
    async handleContratosNaoImportados() {
      try {
        const { data } = await UploadService.getContratosNaoImportados({
          ...this.filters,
        });
        const contratos = [];
        for (let i = 0; i < data.content.length; i++) {
          const contrato = data.content[i];
          contratos.push(contrato);
          if (!contrato.filhos?.length) continue;
          for (let j = 0; j < contrato.filhos.length; j++) {
            contratos.push({ ...contrato.filhos[j], filho: true });
          }
        }
        this.paginationData = data;
        this.tableData = contratos;
      } catch (error) {
        this.mxHandleRequestError(error, "UPLOAD_LOTE");
      }
    },
    removerArquivosDg() {
      let newFiles = structuredClone(this.arquivosModel.arquivos);
      newFiles = newFiles.filter((file) => {
        for (let i = 0; i < this.pontosDgData.length; i++) {
          if (this.pontosDgData[i].arquivo === file.name) {
            return false;
          }
        }
        return true;
      });
      this.arquivosModel.arquivos = newFiles;
      this.$refs.modalArquivosDg?.close();
    },
  },
  watch: {
    "arquivosModel.arquivos": {
      handler: "handleFile",
      immediate: true,
    },
  },
  components: {
    DataTable,
    ModalBase,
    PagePainel,
    ModalTemplate,
    OverlayLoader,
    InputUploadField,
  },
};
</script>
<style lang="scss" scoped>
.page-importar-lote {
  padding-top: 24px;
  &__body {
    padding: 15px 40px;
  }
  .labelTitle {
    font-size: 16px;
    font-weight: 600;
  }
}

.page-importacao-running {
  max-height: 8vh;
  padding: 15px 40px;
  border-radius: 40px;
}

.body-importacao {
  margin-bottom: 25%;
}

.btn-secondary-full-filled {
  width: 30%;
  font-size: 16px;
  font-weight: 700;
}

.file-description {
  word-break: break-all;
  white-space: pre-wrap;
}

.file-container {
  overflow: auto;
  max-height: 35vh;
}

.icon-check-arrow {
  font-size: 1.3rem;
}

.btn-remover-arquivos {
  color: $color--blue-btn;
  background: white;
  border: 1px solid $color--blue-btn;
  border-radius: 12px;
  padding-left: 20px !important;
  padding-right: 20px !important;

  &:hover {
    background: $color--blue-btn;
    color: white;
  }
}
</style>
