<template>
  <div>
    <data-table
      :fields="fieldsContratoRateio"
      :data="dataTable"
      :stripes="true"
    >
      <td slot="volumeMwh" slot-scope="{ fieldHeader }" class="red">
        {{ fieldHeader.title }}
      </td>
      <input
        type="text"
        slot="volumeMwh"
        slot-scope="{ row }"
        class="form-control"
        v-model="row.volumeMwh"
        data-cy="input-table_contrato_raterio_mwh-recompra"
      />
    </data-table>
  </div>
</template>
<script>
import DataTable from "@/components/tables/DataTable.vue";
export default {
  data() {
    return {
      fieldsContratoRateio: [
        {
          name: "contrato",
          title: "Contrato",
        },
        {
          name: "apelido",
          title: "Apelido",
        },
        {
          name: "cnpj",
          title: "CNPJ",
        },
        {
          name: "saldoBrutoMwm",
          title: "S. Bruto (MWm)",
        },
        {
          name: "volumeMwh",
          title: "Volume (MWh)",
        },
        {
          name: "preco",
          title: "Preço",
        },
        {
          name: "notional",
          title: "Notional",
        },
      ],
      dataTable: [
        {
          contrato: "81222",
          apelido: "Apelido Empresa 1",
          cnpj: "07.158.073/0004-06",
          saldoBrutoMwm: "66,821",
          volumeMwh: "4.170,996",
          preco: "R$ 231,00",
          notional: "R$ 15.435,65",
        },
        {
          contrato: "81222",
          apelido: "Apelido Empresa 1",
          cnpj: "07.158.073/0004-06",
          saldoBrutoMwm: "66,821",
          volumeMwh: "4.170,996",
          preco: "R$ 231,00",
          notional: "R$ 15.435,65",
        },
        {
          contrato: "81222",
          apelido: "Apelido Empresa 1",
          cnpj: "07.158.073/0004-06",
          saldoBrutoMwm: "66,821",
          volumeMwh: "4.170,996",
          preco: "R$ 231,00",
          notional: "R$ 15.435,65",
        },
        {
          contrato: "81222",
          apelido: "Apelido Empresa 1",
          cnpj: "07.158.073/0004-06",
          saldoBrutoMwm: "66,821",
          volumeMwh: "4.170,996",
          preco: "R$ 231,00",
          notional: "R$ 15.435,65",
        },
      ],
    };
  },
  components: {
    DataTable,
  },
};
</script>
<style lang="scss" scoped>
</style>