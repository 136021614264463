import Services from "./Services";
class ContratoService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  calcularMedicoes(request) {
    this._url = `/calculo-faturamento/${request.idContrato}/calcular-medicoes?uploadArquivo=true`;
    return this.post(request);
  }

  calcularMedicoesFull(request) {
    this._url = `/calculo-faturamento/calcular-medicoes`;
    return this.post(request);
  }

  buscarAprovacao(request) {
    this._url = `/${request.idContrato}/solicitado-liquido/aprovacao`;

    return this.get(request);
  }

  buscarCalculoFaturamento(request) {
    this._url = `/calculo-faturamento/solicitado-liquido/edicao`;

    return this.get(request);
  }

  editarSolicitadoLiquido(request) {
    this._url = `/${request.idContrato}/solicitado-liquido/editar`;

    return this.post({
      justificativa: request.justificativa,
      novoSolicitadoLiquido: request.novoSolicitadoLiquido,
    });
  }

  aprovarSolicitadoLiquido(request) {
    this._url = `/${request.idContrato}/solicitado-liquido/aprovar`;

    return this.put({
      justificativa: request.justificativa,
    });
  }

  reprovarSolicitadoLiquido(request) {
    this._url = `/${request.idContrato}/solicitado-liquido/reprovar`;

    return this.delete({
      justificativa: request.justificativa,
    });
  }
}

export default new ContratoService("/contrato");
