<template>
  <div class="__bar">
    <div
      class="__bar__split"
      v-for="(
        {
          label,
          loadingLabel,
          width,
          value,
          status,
          extraInfo,
          color,
          transition,
        },
        i
      ) in splitsData"
      :style="{ width }"
      :key="i"
    >
      <div class="__bar__split__description">
        <span v-if="status === 'error'" class="__bar__split__status-error">
          {{ label }} <i class="icon icon-close" /> (Finalizado com Erro)
        </span>
        <span
          v-else-if="status === 'finished'"
          class="__bar__split__status-finalized"
        >
          {{ label }} &check; (Finalizado com Sucesso)
        </span>
        <span v-else-if="status === 'running'">
          {{ loadingLabel }}
        </span>
        <span v-else>{{ label }}</span>
      </div>
      <div
        class="__bar__split__rail"
        :style="{ borderRadius: getSplitBorderRadius(i) }"
      >
        <div
          class="__bar__split__status"
          :style="{
            width: `${value}%`,
            background: color,
            borderRadius: getSplitBorderRadius(i),
            transition: `width ${transition.duration}ms ${transition.easing} ${transition.delay}ms`,
          }"
        />
        <label
          class="__bar__split__percentage"
          :style="{ color: value > 0 ? 'white' : 'black' }"
        >
          {{ value }}% <template v-if="extraInfo">- {{ extraInfo }}</template>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    splits: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getSplitBorderRadius(splitIndex) {
      const values = ["0", "0", "0", "0"];
      if (splitIndex === 0) {
        values[0] = "5px";
        values[3] = "5px";
      }
      if (splitIndex === this.splits.length - 1) {
        values[1] = "5px";
        values[2] = "5px";
      }
      return values.join(" ");
    },
  },
  computed: {
    splitsData() {
      return this.splits.map((split, i) => {
        const transition = {
          duration: 1000,
          delay: 0,
          easing: "linear",
        };
        if (i === 0 && this.splits.length === 1) {
          transition.easing = "ease-in-out";
        }
        if (this.splits.length > 1) {
          transition.duration = 1000 / this.splits.length;
        }
        if (i !== 0) {
          transition.delay = (1000 * i) / this.splits.length;
          if (i === this.splits.length - 1) {
            transition.easing = "ease-out";
          }
        }
        return {
          ...split,
          transition,
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.__bar {
  display: flex;
  gap: 4px;
  &__split {
    display: grid;
    grid-template-rows: 1fr 1fr;
    > div {
      width: 100%;
    }
    &__rail {
      height: 16px;
      position: relative;
      background: $color--gray-light;
      @include ml-hd {
        height: responsive(16px);
      }
    }
    &__status {
      height: 100%;
    }
    &__percentage {
      top: 0;
      left: 5px;
      color: white;
      font-size: 0.8em;
      position: absolute;
    }
    &__description {
      display: flex;
      width: 100%;
      top: -16px;
      font-style: italic;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 1px;
      margin-bottom: 3px;
      @include ml-hd {
        top: responsive(-16px);
        font-size: responsive(12px);
      }
    }
    &__status-finalized {
      color: $color--green;
    }
    &__status-error {
      color: $color--red;
    }
    &__calc-finalized {
      color: $color--primary;
    }
  }
}
</style>
