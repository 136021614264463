import PosVendaModel from "../PosVendaModel";

export default class TablePosVendaVinculoModel extends PosVendaModel {
  static getTableFields() {
    return new Array(
      {
        name: "nome",
        title: "Nome",
        width: "400px",
      },
      {
        name: "email",
        title: "E-mail",
      },
      {
        name: "selecionar",
        title: "Selecionar",
        align: "center",
      }
    );
  }
}
