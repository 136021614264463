<template>
  <div class="container-spread-recompra">
    <div class="header-spread-recompra">
      <span>Aprovação de Spread</span>
    </div>
    <card-base>
      <template #header> Solicitação de Ajuste de Spread </template>
      <template #body>
        <data-table
          style="
            border: 1px solid #c4c4c4;
            margin-bottom: 30px;
            border-radius: 24px;
            padding: 10px;
          "
          :fields="fields"
          :data="dataTableAprovacao"
          :stripes="true"
        />
        <div class="container-fields">
          <aprovacao-recompra-rateio
            :dataInfo="aprovacaoInfo"
            v-if="contrato.rateio"
          />

          <aprovacao-recompra-sem-rateio v-else :dataInfo="aprovacaoInfo" />

          <field-justificativa
            ref="refJustificativaSolicitante"
            :fieldDisabled="true"
            titleField="Justificativa do Solicitante:"
          />
          <field-justificativa
            ref="refJustificativa"
            titleField="Inserir Justificativa (Opcional):"
          />
        </div>

        <div class="box-buttons">
          <button
            class="btn reprovar-btn"
            data-cy="button-reprovar_solicitado_liquido-modais"
            @click="reprovarRecompra"
          >
            Reprovar
          </button>
          <button
            class="btn aprovar-btn"
            data-cy="button-aprovar_solicitado_liquido-modais"
            @click="aprovarRecompra"
          >
            Aprovar
          </button>
        </div>
      </template>
    </card-base>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import cardBase from "../cards/cardBase.vue";
import DataTable from "../tables/DataTable.vue";
import FieldAprovacao from "./modais/fields/FieldAprovacao.vue";
import FieldJustificativa from "./modais/fields/FieldJustificativa.vue";
import AprovacaoRecompraRateio from "./aprovacao-recompra/AprovacaoRecompraRateio.vue";
import AprovacaoRecompraSemRateio from "./aprovacao-recompra/AprovacaoRecompraSemRateio.vue";

import RecompraService from "@/services/RecompraService";
import TableAprovacaoRecompraModel from "@/models/tablesModels/TableAprovacaoRecompraModel";

import RecompraStatusENUM from "@/enums/RecompraStatusENUM";

export default {
  components: {
    cardBase,
    DataTable,
    FieldAprovacao,
    FieldJustificativa,
    AprovacaoRecompraRateio,
    AprovacaoRecompraSemRateio,
  },
  props: {
    contrato: {
      type: Object,
    },
  },
  data() {
    return {
      aprovacaoInfo: new Object(),
      dataTableAprovacao: new Array(),
      fields: TableAprovacaoRecompraModel.getTableFields(),
    };
  },
  mounted() {
    this.getAprovacao();
  },
  methods: {
    getAprovacao() {
      const { id: idContrato } = this.contrato;

      RecompraService.getAprovacaoRecompra({ idContrato })
        .then(({ data }) => {
          this.dataTableAprovacao = data.recompras.map((item) => {
            return {
              ...item,
              contrato: item.codigo,
              tipoEnergia: item.tipoEnergia.descricao,
              submercado: item.submercado.sigla,
            };
          });

          // Retornando a maior spread compra ajustada
          const { spread, spreadCompraAjustado, codigo } =
            data.recompras.reduce((prevent, current) =>
              prevent.spreadCompraAjustado > current.spreadCompraAjustado
                ? prevent
                : current
            );

          // Retornando a maior alcada do log de historico
          const { justificativa } = data.log.reduce((prevent, current) => {
            if (!prevent) {
              return {
                justificativa: current.justificativa,
                ...current.alcadaParametrizacao,
              };
            }

            return prevent.id > current.alcadaParametrizacao.id
              ? {
                  justificativa: prevent.justificativa,
                  ...prevent,
                }
              : {
                  justificativa: current.justificativa,
                  ...current.alcadaParametrizacao,
                };
          }, {});

          // Usando todas as informaçoes nos campos para cada field correspondente
          const dataInfo = {
            idParametrizacao: data.alcadaParametrizacao.id,
            descricao: data.alcadaParametrizacao.descricao,
            spread,
            spreadCompraAjustado,
            codigo,
            proximaAprovacaoDescricao:
              RecompraStatusENUM[data.proximoStatusContrato],
            statusAprovacaoDescricao: data.proximaAprovacao.descricao,
          };
          this.aprovacaoInfo = dataInfo;

          this.$refs.refJustificativaSolicitante.valueField = justificativa;
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    finishApproveMessage(message, title) {
      this.mxToggleWarningModal({
        message,
        btnTrue: "Visualizar faturamento",
        title,
        showBtnFalse: false,
      }).then(() => {
        document.location.reload(true);
      });
    },
    aprovarRecompra() {
      const justificativa = this.$refs.refJustificativa.valueField;

      const body = {
        justificativa,
      };

      if (this.IS_APPROVE) {
        this.mxToggleWarningModal({
          message: `Ao confirmar, o novo Spread de Recompra será aprovado e este faturamento terá seu status alterado para <b>Em Análise do Pós-Venda</b>.`,
          title: "Encaminhar Aprovação de Spread de Recompra",
        }).then(() => {
          RecompraService.aprovarRecompra({
            idContrato: this.contrato.id,
            body,
          })
            .then(() => {
              this.finishApproveMessage(
                `Sucesso! O novo Spread de Recompra deste contrato foi aprovado e seu status alterado para <b>Em Análise do Pós-Venda</b>.`,
                "Spread de Recompra Aprovado"
              );
            })
            .catch((error) => {
              this.mxHandleRequestError(error);
            });
        });
      } else {
        this.mxToggleWarningModal({
          message: `Ao confirmar, a aprovação de Spread de Recompra será encaminhada para análise de <b>${this.aprovacaoInfo.statusAprovacaoDescricao}.</b>`,
          title: "Encaminhar Aprovação de Spread de Recompra",
        }).then(() => {
          RecompraService.aprovarRecompra({
            idContrato: this.contrato.id,
            body,
          })
            .then(() => {
              this.finishApproveMessage(
                `Sucesso! A aprovação de Spread de Recompra foi encaminhada para análise de <b>${this.aprovacaoInfo.statusAprovacaoDescricao}.</b>`,
                "Aprovação de Spread de Recompra Encaminhada"
              );
            })
            .catch((error) => {
              this.mxHandleRequestError(error);
            });
        });
      }
    },
    reprovarRecompra() {
      this.mxToggleWarningModal({
        message:
          "Ao confirmar, a aprovação de Spread de Recompra será reprovada e este faturamento terá seu status alterado para <b>Em Análise do Pós-Venda.</b>",
        title: "Reprovar Spread de Recompra",
      }).then(() => {
        RecompraService.reprovarRecompra({
          idContrato: this.contrato.id,
          justificativa: this.$refs.refJustificativa.valueField,
        })
          .then((res) => {
            this.mxToggleWarningModal({
              message:
                "O novo Spread de Recompra deste contrato foi reprovado e seu status foi alterado para <b>Em Análise do Pós-Venda.</b>",
              title: "Spread de Recompra Reprovado",
              showBtnFalse: false,
            }).then(() => {
              document.location.reload(true);
            });
          })
          .catch((error) => {
            this.mxHandleRequestError(error);
          });
      });
    },
  },
  computed: {
    ...mapGetters(["getPermissoes"]),
    IS_APPROVE() {
      return this.getPermissoes.some(
        (item) =>
          (item === "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N1" &&
            this.aprovacaoInfo.idParametrizacao == 6) ||
          (item === "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N2" &&
            this.aprovacaoInfo.idParametrizacao == 7) ||
          (item === "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N3" &&
            this.aprovacaoInfo.idParametrizacao == 8)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container-spread-recompra {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: 10px;

  border: 3px dashed #ec6623;
  border-radius: 20px;
}

.header-spread-recompra {
  width: 100%;
  height: 35px;

  padding: 5px 25px;

  margin-bottom: 10px;

  background: #08132c;

  border-radius: 24px;

  font-size: 16px;
  font-weight: 700;
  color: white;
}

.container-fields {
  display: flex;

  width: 95%;
}

.box-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 25%;

    margin: 5px;
    padding: 8px;

    color: white;

    font-weight: bold;

    border-radius: 10px;
  }
}
.aprovar-btn {
  background: #78b82a;
}

.reprovar-btn {
  background: #d40d00;
}
</style>
