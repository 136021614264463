<template>
  <div class="container-energia-convencional-field">
    <p>Energia - {{ tusd.descricao }}</p>
    <textarea
      cols="45"
      rows="10"
      placeholder="N/A"
      class="form-control"
      id="fieldEnergiaField"
      v-model="model.content"
      ref="fieldEnergiaField"
      data-cy="text-area-energia_tusd-parametrizacao-components-fields"
      :disabled="disabled"
    ></textarea>
  </div>
</template>
<script>
export default {
  props: {
    tusd: {
      type: Object,
    },
    tusdCurtoPrazo: {
      type: Object,
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      model: {
        name: "descricao",
        content: "",
      },
    };
  },
  watch: {
    tusd: {
      deep: true,
      immediate: true,
      handler() {
        this.model.content = this.tusdCurtoPrazo[this.model.name];
      },
    },
    "model.content": {
      handler() {
        this.tusdCurtoPrazo[this.model.name] = this.model.content;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  font-size: 16px;
  font-weight: 700;
  margin: 16px auto;
}
textarea::placeholder {
  font-size: 12px;
}

textarea {
  resize: none;
  border-radius: 12px;
}
</style>