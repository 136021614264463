<template>
  <div class="card-container">
    <div class="card-message">{{ title }}</div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    icon: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";

.card-container {
  width: 250px;
  height: 100px;
  display: flex;
  cursor: pointer;
  border-radius: 27px;
  align-items: center;
  justify-content: center;
  background: $color--blue-dark;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.card-container:hover {
  opacity: 0.9;
  margin-top: -3px;
  transition: 0.2s ease-in-out;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}

.card-message {
  color: $color--primary-white;
  font-weight: 700;
  max-width: 90%;
  line-break: anywhere;
  text-align: center;
}
</style>
