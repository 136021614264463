<template>
  <div class="__card">
    <div class="__card__header">
      <h2>{{ label }}</h2>
      <switch-button
        :value="model.ativo"
        @input="model.ativo = $event"
        :disabled="!hasPermission"
      />
    </div>
    <div class="__card__body">
      <field-fornecimento-rotina-importacao
        :modelProp="model"
        reference="horaInicio"
        label="Início do Fornecimento"
        :disabled="!hasEditingPermission || !model.ativo"
      />
      <field-fornecimento-rotina-importacao
        :modelProp="model"
        reference="horaFim"
        label="Fim do Fornecimento"
        :disabled="!hasEditingPermission || !model.ativo"
      />
      <field-intervalo-execucao
        :modelProp="model"
        :disabled="!hasEditingPermission || !model.ativo"
      />
      <div class="__card__body__buttons">
        <button
          @click="rollbackChanges"
          class="btn btn-secondary-full-filled"
          :disabled="!isModelChanged || !hasPermission"
        >
          Cancelar
        </button>
        <button
          @click="postDadosRotina"
          class="btn btn-primary-full-filled"
          :disabled="!isModelChanged || !hasPermission"
        >
          Salvar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { CONFIRMATION } from "@/constants/strings";
import RotinaImportacaoModel from "@/models/RotinaImportacaoModel";
import FieldFornecimentoRotinaImportacao from "@/pages/parametrizacao/components/fields/FieldFornecimentoRotinaImportacao.vue";
import FieldHourSelector from "@/pages/parametrizacao/components/fields/FieldHourSelector.vue";
import FieldIntervaloExecucao from "@/pages/parametrizacao/components/fields/FieldIntervaloExecucao.vue";
import SwitchButton from "@/pages/parametrizacao/components/fields/SwitchButton.vue";
import { DateTime } from "luxon";

export default {
  data() {
    return {
      model: new RotinaImportacaoModel(),
      originalModel: new RotinaImportacaoModel(),
    };
  },
  props: {
    label: {
      type: String,
    },
    service: {
      type: Object,
    },
  },
  mounted() {
    this.getDadosRotina();
  },
  methods: {
    getDadosRotina() {
      this.loading = this.service
        .getRotinaImportacao()
        .then(({ data }) => {
          data.horaFim = DateTime.fromFormat(
            data.horaFim,
            "HH:mm:ss"
          ).toLocaleString(DateTime.TIME_24_SIMPLE);
          data.horaInicio = DateTime.fromFormat(
            data.horaInicio,
            "HH:mm:ss"
          ).toLocaleString(DateTime.TIME_24_SIMPLE);

          this.model = new RotinaImportacaoModel(data);
          this.originalModel = new RotinaImportacaoModel(data);
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "ROTINA_IMPORTACAO");
        });
    },
    postDadosRotina() {
      this.loading = this.service
        .postRotinaImportacao(this.model.getData())
        .then(() => {
          this.mxToggleToast({
            message: CONFIRMATION.ROTINA_IMPORTACAO.UPDATE,
          });
          this.commitChanges();
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "ROTINA_IMPORTACAO");
        });
    },
    commitChanges() {
      this.originalModel.ativo = this.model.ativo;
      this.originalModel.horaInicio = this.model.horaInicio;
      this.originalModel.horaFim = this.model.horaFim;
      this.originalModel.frequenciaEmHoras = this.model.frequenciaEmHoras;
    },
    rollbackChanges() {
      this.model.ativo = this.originalModel.ativo;
      this.model.horaInicio = this.originalModel.horaInicio;
      this.model.horaFim = this.originalModel.horaFim;
      this.model.frequenciaEmHoras = this.originalModel.frequenciaEmHoras;
    },
  },
  computed: {
    hasPermission() {
      return (
        this.$can("ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR") ||
        this.hasEditingPermission
      );
    },
    hasEditingPermission() {
      return this.$can("ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR_EDITAR");
    },
    isModelChanged() {
      return JSON.stringify(this.model) !== JSON.stringify(this.originalModel);
    },
  },
  components: {
    SwitchButton,
    FieldFornecimentoRotinaImportacao,
    FieldHourSelector,
    FieldIntervaloExecucao,
  },
};
</script>

<style lang="scss" scoped>
.__card {
  width: 525px;
  display: flex;
  flex-direction: column;
  &__header {
    background-color: $color--primary;
    height: 50px;
    border-radius: 20px 20px 0 0;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > h2 {
      font-size: 20px;
      color: $color--primary-white;
      margin: auto 0;
      font-weight: 700;
    }
  }
  &__body {
    width: 100%;
    padding: 20px;
    gap: 12px 0;
    justify-content: space-between;
    border: 1px solid #c4c4c4;
    border-top: 0;
    border-radius: 0 0 20px 20px;
    display: flex;
    flex-wrap: wrap;
    &__buttons {
      width: 100%;
      display: flex;
      gap: 8px;
      justify-content: end;
      margin-top: 6px;
      > button {
        font-weight: 600;
        font-size: 14px;
        width: 100px;
      }
    }
  }
}
</style>
