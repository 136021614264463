import { render, staticRenderFns } from "./FaturaMercadoLivreComercializacaoEnergia.vue?vue&type=template&id=27456240&scoped=true&"
import script from "./FaturaMercadoLivreComercializacaoEnergia.vue?vue&type=script&lang=js&"
export * from "./FaturaMercadoLivreComercializacaoEnergia.vue?vue&type=script&lang=js&"
import style0 from "./FaturaMercadoLivreComercializacaoEnergia.vue?vue&type=style&index=0&id=27456240&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27456240",
  null
  
)

export default component.exports