import Services from "./Services";
class PermissoesService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }
  getPermissoes(request) {
    this._url = "";
    return this.post(request, true);
  }
}
export default new PermissoesService("/permissoes");