<template>
  <div class="sl-justificativa" :style="[{ width: `${widthField}%` }]">
    <label class="sl-title">{{ titleField }}</label>
    <textarea
      rows="6"
      class="form-control"
      v-model="valueField"
      :disabled="fieldDisabled"
      :style="[{ height: `${heightField}px` }]"
      data-cy="text-justificativa_field-contratos-fields"
    >
    </textarea>
  </div>
</template>
<script>
export default {
  data() {
    return {
      valueField: "",
    };
  },
  props: {
    titleField: {
      type: String,
    },
    widthField: {
      type: Number,
      default: 30,
    },
    heightField: {
      type: Number,
      default: 100,
    },
    fieldDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.sl-justificativa {
  margin: auto 10px;
}
.sl-title {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 16px;
}
textarea::placeholder {
  font-size: 12px;
}

textarea {
  border-radius: 12px;
  margin-bottom: 43px;

  border: 1px solid #b4b7ba;
  border-radius: 12px;

  resize: none;

  background: transparent;
}
</style>
