<template>
  <div class="page-horas-faltantes">
    <collapsible-card class="_contract__collapsable" colorHeader="#F5B129">
      <template #title><b>Horas Faltantes</b></template>
      <template #body>
        <div class="container-horas-faltantes">
          <overlay-loader v-if="loading" />
          <data-table
            :data="data"
            :stripes="true"
            :fields="TableHorasFaltantesModel.getTableFields()"
          >
            <div
              v-if="IS_RESPONSAVEL"
              slot="totalProjetadoMwh"
              slot-scope="{ row }"
            >
              <vue-numeric
                separator="."
                :precision="3"
                empty-value="0"
                class="input-total-proj"
                v-model="row.totalProjetadoMwh"
                data-cy="input-total_projetado_mwh-horas-faltantes"
              />
            </div>
            <div slot="diferenca" slot-scope="{ row }">
              {{ (row.totalProjetadoMwh - row.totalScde) | volume }}
            </div>
            <div slot="origem" slot-scope="{ row }">
              {{ row.descricaoOrigem || "--" }}
            </div>
          </data-table>
        </div>
        <div class="container-button">
          <button
            :disabled="loading"
            class="btn btn-action-primary"
            @click="postComplementarHoras"
            v-if="IS_RESPONSAVEL && data.length && $can('REALIZAR_FATURAMENTO')"
            data-cy="button-criar_complemento_horas-horas-faltantes"
          >
            Complementar Horas
          </button>
        </div>
      </template>
    </collapsible-card>
  </div>
</template>
<script>
import collapsibleCard from "@/components/cards/collapsibleCard.vue";
import PagePainel from "@/components/panels/PagePanel.vue";
import DataTable from "@/components/tables/DataTable.vue";
import { CONFIRMATION } from "@/constants/strings";
import { TableHorasFaltantesModel } from "@/models/tablesModels/TableHorasFaltantesModel";
import ContratosService from "@/services/ContratosService";
import { Buffer } from "buffer";
import VueNumeric from "vue-numeric";

export default {
  props: {
    link: {
      type: String,
      required: true,
    },
    contratoId: {
      type: Number,
      required: true,
    },
    hasAction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    TableHorasFaltantesModel: () => TableHorasFaltantesModel,
    IS_RESPONSAVEL() {
      return this.hasAction("complementar_horas");
    },
  },
  data() {
    return {
      loading: false,
      data: [],
    };
  },
  methods: {
    async getComplementarHoras() {
      this.loading = true;
      const { link } = this;
      try {
        const { data } = await ContratosService.getComplementarHoras({ link });
        this.data = data;
      } catch (error) {
        this.mxHandleRequestError(error, "HORAS_FALTANTES");
      } finally {
        this.loading = false;
      }
    },
    async postComplementarHoras() {
      this.loading = true;
      try {
        await ContratosService.postComplementarHoras(
          this.data.map((g) => ({
            idContrato: g.idContrato,
            quantidadeHorasComplementares: g.totalProjetadoMwh,
          }))
        );
        this.getComplementarHoras();
        this.mxToggleToast({ message: CONFIRMATION.PROINFA.UPDATE });
        window.sessionStorage.setItem(
          "c-key",
          Buffer.from(this.contratoId.toString()).toString("base64")
        );
        document.location.reload(true);
      } catch (error) {
        if (error.response) {
          const {
            status,
            data: { message },
          } = error.response;
          if ([400, 404, 406].includes(status)) {
            this.mxToggleErrorMessageAlert(message);
          }
        } else {
          this.mxHandleRequestError(error);
        }
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getComplementarHoras();
  },
  components: {
    PagePainel,
    collapsibleCard,
    DataTable,
    VueNumeric,
  },
};
</script>
<style lang="scss" scoped>
.page-horas-faltantes {
  padding-bottom: 24px;
  &__body {
    padding: 2px 40px;
  }

  .contract {
    margin: 20px 0 20px 0;
  }

  .container-horas-faltantes {
    padding: 0px 10px 0px 10px;
    overflow: hidden;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    position: relative;
  }

  .input-total-proj {
    padding: 0.2rem 0.5rem;
    text-align: right;
    border: 1px solid #b4b7ba;
    border-radius: 0.7rem;
    outline: none;
  }

  .container-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-top: 10px;
    button {
      width: 300px !important;
    }
  }
}

._collapsible-card__header {
  &__header {
    background: #f5b129;
  }
}
</style>
