<template>
  <div class="container-horas-faltantes">
    <modal-visualizar-upload-solicitado-liquido
      @closeModal="closeModal()"
      :rowSelected="rowSelected"
      v-if="showModalVisualizarUploadSolicitadoLiquido"
    />
    <overlay-loader v-if="loading" />
    <card-base>
      <template #header> Histórico de Ações </template>
      <template #body>
        <div class="container-historico-acoes">
          <data-table
            :stripes="true"
            :headerStick="true"
            :maxItemsToScroll="6"
            :data="dataTableFields"
            :fields="HistoricoAcoesModel.getTableFields()"
          >
            <div
              slot="descricao"
              slot-scope="{ row }"
              :title="row.descricao"
              class="table-descricao"
            >
              {{ row.descricao }}
            </div>
            <div
              slot="link"
              slot-scope="{ row }"
              v-if="row.link && row.link.url"
            >
              <span
                class="mx-2 table-link"
                @click="visualizarUploadSolicitadoLiquido(row)"
                style="cursor: pointer"
              >
                - ver arquivos
              </span>
            </div>
          </data-table>
        </div>
      </template>
    </card-base>
  </div>
</template>

<script>
import cardBase from "../cards/cardBase.vue";
import OverlayLoader from "../OverlayLoader.vue";
import DataTable from "@/components/tables/DataTable.vue";
import HistoricoAcoesModel from "@/models/tablesModels/HistoricoAcoesModel.js";
import HistoricoAcoesComponentService from "@/services/HistoricoAcoesComponentService";
import ModalVisualizarUploadSolicitadoLiquido from "@/components/contratos/modais/ModaVisualizarUploadSolicitadoLiquido.vue";
export default {
  components: {
    cardBase,
    DataTable,
    OverlayLoader,
    ModalVisualizarUploadSolicitadoLiquido,
  },
  props: {
    link: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: undefined,
      rowSelected: new Object(),
      dataTableFields: new Array(),
      showModalVisualizarUploadSolicitadoLiquido: false,
    };
  },
  computed: {
    HistoricoAcoesModel: () => HistoricoAcoesModel,
  },
  mounted() {
    this.loading = HistoricoAcoesComponentService.getHistoricoAcoes(this.link)
      .then(({ data }) => (this.dataTableFields = data))
      .catch((error) => this.mxHandleRequestError(error))
      .finally(() => (this.loading = false));
  },
  methods: {
    visualizarUploadSolicitadoLiquido(row) {
      this.rowSelected = row.link;
      this.showModalVisualizarUploadSolicitadoLiquido = true;
    },
    closeModal() {
      this.showModalVisualizarUploadSolicitadoLiquido = false;
    },
    lineClicked(row) {
      this.rowSelected = row;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-horas-faltantes {
  position: relative;
}

.table-descricao {
  max-width: 10vw !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.table-link {
  color: #ec6723;
  text-decoration: underline;
  white-space: nowrap !important;
}
</style>
