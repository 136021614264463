var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pre-input-group"},[_c('div',{staticClass:"input-group",class:{
      'is-invalid': (!_vm.VALIDATED || !_vm.externallyValidated) && _vm.dirty,
      'file-attached': _vm.IS_FILE_ATTACHED,
      'is-dragging': _vm.isDragging,
      disabled: _vm.disabled,
    }},[_c('span',{class:{ 'file-attachment-icons': _vm.IS_FILE_ATTACHED }},[_vm._l((_vm.model.content.slice(0, 3)),function(_){return _c('i',{staticClass:"icon-file"})}),(_vm.model.content.length > 3)?_c('div',{staticClass:"extra-file"},[_c('i',{staticClass:"icon-file"}),_c('span',[_vm._v("···")])]):_vm._e(),(!_vm.IS_FILE_ATTACHED)?[_c('i',{staticClass:"icon-exportador"}),_vm._v(" Arraste e solte arquivos aqui ou clique para enviar."),_c('br'),(_vm.fileType && _vm.fileType.length)?[_vm._v(" Apenas em "+_vm._s(_vm.fileType.length > 1 ? "formatos" : "formato")+" "+_vm._s(_vm.fileType.join(", "))+" ")]:_vm._e()]:_vm._e()],2),_c('input',{ref:"inputUploadField",attrs:{"type":"file","disabled":_vm.disabled,"multiple":"","accept":_vm.fileType ? _vm.fileType.join(',') : ''},on:{"change":_vm.changeFile,"dragenter":function($event){_vm.isDragging = true},"dragleave":function($event){_vm.isDragging = false},"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.drop.apply(null, arguments)}}})]),(_vm.getLayoutFile !== null)?_c('button',{class:{ disabled: _vm.loadingLayout },attrs:{"disabled":_vm.loadingLayout},on:{"click":_vm.downloadLayoutFile}},[_c('div',[(_vm.loadingLayout)?_c('overlay-loader',{attrs:{"styles":{
          spinnerWrapper: {
            width: '30px',
            height: '30px',
          },
        }}}):_vm._e(),_c('i',{staticClass:"icon-descarregar small"}),_c('span',[_vm._v("Baixar template de Excel")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }