<template>
  <div>
    <p class="title-field">Habilitar ponto</p>
    <label class="switch">
      <input
        type="checkbox"
        :checked="enabled"
        :disabled="disabled"
        @change="handleEnableCheckBox($event)"
        data-cy="input-habilitar_ponto-ponto-medicao-components-fields"
      />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    enabled: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    handleEnableCheckBox(e) {
      this.$emit("enableScore", e.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $color--primary;
}

input:checked:disabled + .slider {
  opacity: 0.5;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.title-field {
  color: $color--primary-font;
  font-weight: 300;
}
</style>
