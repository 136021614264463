var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-table"},[_c('div',{class:{ 'card-table': _vm.card }},[_c('scroll',{staticStyle:{"padding-bottom":"18px"},attrs:{"ops":_vm.scrollConfig}},[_c('div',{staticClass:"data-table__wrapper",style:({
          'max-height': _vm.customWidth
            ? _vm.maxItemsToScroll
              ? _vm.GET_MAX_HEIGHT
              : _vm.maxHeight
            : _vm.maxHeight,
        })},[_c('table',{staticClass:"_table"},[_c('thead',{class:{ 'header-sticky': _vm.headerStick }},[_c('tr',_vm._l((_vm.fields),function(fieldHeader,fieldHeaderIndex){return _c('td',{key:(fieldHeaderIndex + "fieldHeaderIndex"),class:{ center: fieldHeader.align == 'center' },staticStyle:{"font-size":"16px"},style:([
                  {
                    width: fieldHeader.width
                      ? fieldHeader.width
                      : ((100 / fieldHeader.length) + "%"),
                  } ])},[(_vm.isFieldSlot('header-' + fieldHeader.name))?_c('div',[_vm._t('header-' + fieldHeader.name)],2):_c('div',[_vm._v(" "+_vm._s(fieldHeader.title)+" ")])])}),0)]),_c('transition-group',{attrs:{"name":"list","mode":"in-out","tag":"tbody"}},[_vm._l((_vm.data),function(row,indexRow){return _c('tr',{key:(indexRow + "indexRow"),class:[
                _vm.stripes ? 'stripes' : '_border-bottom',
                _vm.lineClickable && 'pointer',
                _vm.lineClass && (_vm.lineClass + "-" + indexRow),
                _vm.lineClassIndex &&
                  _vm.lineClassIndex.index === indexRow &&
                  _vm.lineClassIndex.class,
                _vm.GET_BOLD_LINE(row) ],attrs:{"data-cy":"tbody-tr-clicked_row-table-components"},on:{"click":function($event){$event.stopPropagation();return _vm.lineClicked(row, indexRow)}}},_vm._l((_vm.fields),function(fieldBody,indexFieldBody){return _c('td',{key:(indexFieldBody + "indexFieldBody"),class:{ center: fieldBody.align == 'center' },style:(("padding: " + _vm.rowPadding + " 15px " + _vm.rowPadding + " 10px;"))},[(_vm.isFieldSlot(fieldBody.name))?_c('div',{class:{ center: fieldBody.align == 'center' }},[_vm._t(fieldBody.name,null,{"row":row})],2):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.renderValue(_vm.data[indexRow], fieldBody))}})])])}),0)}),_c('tr',{key:"tr-children-two-key"},[(_vm.data.length === 0)?_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.fields.length}},[_vm._v(" Nenhum resultado encontrado ")]):_vm._e()]),(_vm.footer)?_c('tr',{key:"tr-children-three-key",staticClass:"footer-sticky"},_vm._l((_vm.fields),function(fieldHeader,i){return _c('td',{key:i,class:{ center: fieldHeader.align == 'center' },staticStyle:{"font-size":"16px"},style:({
                  width: fieldHeader.width || ((100 / fieldHeader.length) + "%"),
                })},[(_vm.isFieldSlot(("footer-" + (fieldHeader.name))))?_c('div',{staticStyle:{"font-weight":"600"}},[_vm._t(("footer-" + (fieldHeader.name)))],2):_c('div')])}),0):_vm._e()],2)],1)])])],1),(_vm.pagination && !!_vm.paginationData)?_c('div',{staticClass:"box-pagination mt-3 mb-4"},[_c('pagination',{attrs:{"info":_vm.paginationData},on:{"perPage":_vm.setPerPage,"page":_vm.setPage}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }