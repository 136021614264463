import axios from "axios";
import Services from "./Services";

class ContratosService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  getEmailRecompraManual(request) {
    this._url = `${request.idContrato}/emails/recompra-manual`;

    return this.get(request);
  }

  sendEmailRecompraManual(request) {
    const { idContrato, body } = request;

    this._url = `${idContrato}/emails/recompra-manual`;

    return this.post(body);
  }

  getEmailCalculoFinalizado(request) {
    this._url = `${request.idContrato}/emails/calculo-finalizado`;

    return this.get(request);
  }

  sendEmailCalculoFinalizado(request) {
    const { idContrato, body } = request;

    this._url = `${idContrato}/emails/calculo-finalizado`;

    return this.post(body);
  }

  getStatusFaturamento(request) {
    this._url = `status`;

    return new Promise(async (resolve, reject) => {
      try {
        const data = await this.get(request);
        const response = { data };
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  getComplementarHoras(request) {
    if (request.link) {
      return axios.get(request.link, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("b-token")}`,
        },
      });
    } else {
      this._url = `/complementar-horas/${request.idContrato}`;
      return this.get(request);
    }
  }

  postComplementarHoras(request) {
    this._url = `/complementar-horas`;
    return this.patch(request);
  }

  desassociarPonto(request) {
    const { id } = request;
    this._url = `/${id}/pontos`;
    return this.delete(request);
  }

  getContratosNaoImportados(request) {
    this._url = "/nao-importados";
    return this.get(request, true);
  }
}

export default new ContratosService("/contratos");
