var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_search",style:([
    _vm.customStyle,
    Object.assign({}, (_vm.disabled && {
        backgroundColor: '#e6e6e6',
      })) ])},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.content),expression:"content",modifiers:{"trim":true}}],style:({ width: (_vm.widthInput + "%") }),attrs:{"placeholder":_vm.placeholder,"data-cy":"input-field_search-fields","disabled":_vm.disabled},domProps:{"value":(_vm.content)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.content=$event.target.value.trim()},_vm.handleSearchValue],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.input.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.input.apply(null, arguments)},"blur":function($event){return _vm.$forceUpdate()}}}),_c('img',{style:(_vm.customImgSize && {
        width: _vm.customImgSize.size,
        margin: _vm.customImgSize.margin,
      }),attrs:{"alt":"Icon Search","data-testid":"img-buscar-field-search","src":require("@/assets/icons/svg/lupa.svg"),"data-cy":"img-procurar_field_search-fields"},on:{"click":_vm.input}})])}
var staticRenderFns = []

export { render, staticRenderFns }