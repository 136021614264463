<template>
  <div class="page">
    <OverlayLoader v-if="loading" />

    <div v-if="spinner" class="mtx-spinner" />
    <div class="__header">
      <div class="header__box-logo">
        <img src="@/assets/logo/orange.svg" />
      </div>
      <div class="text__header">Portal Matrix</div>
    </div>

    <!-- componentes pages -->

    <div class="box-erros" v-if="erroProposta">
      <div class="box-content">
        <div class="content-icon">
          <img :src="mountImg('/images/icon-alert-triangulo.svg')" />
        </div>
        <div class="content-text pb-5">
          <b>ATENÇÃO: </b> <br />
          <div>{{ erroProposta }}</div>
        </div>
      </div>
    </div>

    <div class="box-erros" v-if="STATUS == 'EXPIRADA' || STATUS == 'RENOVADA'">
      <div class="box-content">
        <div class="content-icon">
          <img :src="mountImg('/images/icon-alert-triangulo.svg')" />
        </div>
        <div style="width: 100%" class="content-text pb-5">
          <b>ATENÇÃO: </b> <br />
          <div v-if="STATUS == 'EXPIRADA' && PODE_RENOVAR" class="mt-3">
            Esta proposta expirou. Clique no botão abaixo para renová-la.
          </div>
          <div v-if="STATUS == 'EXPIRADA' && !PODE_RENOVAR" class="mt-3">
            Esta proposta expirou. Entre em contato com seu Executivo de
            Relacionamento Matrix para mais informações.
          </div>
          <div v-if="STATUS == 'RENOVADA'" class="mt-3">
            Esta proposta foi renovada. Aguarde a chegada por e-mail ou entre em
            contato com seu Executivo de Relacionamento Matrix para mais
            informações.
          </div>
          <button
            v-if="PODE_RENOVAR"
            style="margin-top: 60px; padding: 5px; max-width: 300px"
            @click="renovarProposta"
            class="btn-action-primary"
          >
            Renovar Proposta
          </button>
        </div>
      </div>
    </div>

    <template
      v-if="
        showDistribuicaoPage &&
        !erroProposta &&
        STATUS != 'EXPIRADA' &&
        STATUS != 'APROVADA' &&
        STATUS != 'RENOVADA'
      "
    >
      <div class="box-specification">
        <div class="specification-header">
          ESPECIFICAÇÃO DO VOLUME (MONTANTE)
        </div>

        <div class="specification-content">
          <div v-if="!volumeConsideredIsValid" class="alert-msg">
            <div class="alert-icon">
              <img :src="mountImg('/images/icon-alert-triangulo.svg')" />
            </div>
            <div class="alert-text">
              <b>ATENÇÃO: </b> Ao clicar em "AVANÇAR", devido às condições de
              mercado, esta proposta será encaminhada diretamente ao seu
              Executivo de Relacionamento.
            </div>
          </div>

          <div class="box-specification-infos">
            <div v-if="FATOR_ATENDIMENTO_INTEGRAL" class="infos-item">
              <div class="info-label">Volume Excedido:</div>
              <div class="info-value">{{ VOLUME_EXCEDIDO | volume }}</div>
            </div>
            <div class="infos-item">
              <div class="info-label">Preço:</div>
              <div class="info-value">
                {{ PRECO }}
              </div>
            </div>
            <div class="infos-item">
              <div class="info-label">Preço válido até:</div>
              <div class="info-value">{{ VALIDADE }}</div>
            </div>
          </div>

          <div style="margin: 30px 0 20px 0" class="specification-label">
            Defina o volume que será considerado na proposta:
          </div>
          <div class="box-specificstion-option">
            <div
              v-if="FATOR_ATENDIMENTO_INTEGRAL"
              class="option-item option-left"
            >
              <div class="box-input-radio">
                <input
                  v-model="checkOptions"
                  name="option"
                  value="defined"
                  type="radio"
                />
              </div>
              <div class="info-label">Receber proposta do volume total</div>
            </div>

            <div class="option-item option-right">
              <div class="box-input-radio">
                <input
                  v-model="checkOptions"
                  name="option"
                  value="total"
                  type="radio"
                />
              </div>
              <div class="info-label">
                Definir volume da Proposta de Curto Prazo:
                <vue-numeric
                  :disabled="inputDisabled"
                  @input="changeSetVolume"
                  class="numeric-field-grid"
                  :class="{ 'color-red': !volumeConsideredIsValid }"
                  currency=""
                  :minus="false"
                  :precision="3"
                  separator="."
                  v-model="setVolume"
                ></vue-numeric>
                MWh
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-distribution">
        <div class="box-distribution-infos">
          <div>
            <div class="info-label">Volume Considerado da Proposta:</div>
            <div
              :class="{ 'color-red': !volumeConsideredIsValid }"
              class="info-value"
            >
              {{ volumeConsiderado | volume }}
            </div>
          </div>
        </div>
        <div class="distribution-header">
          <div class="info-label">Distribuição por Unidades</div>
          <div v-if="DISTRIBUICAO_POR_UNIDADES.length > 1" class="">
            <span v-if="missingDistribute > 0">Falta distribuir: </span>
            <span v-if="missingDistribute < 0"
              >Há um volume excedente de:
            </span>
            <span v-if="missingDistribute != 0" class="color-red"
              ><b>{{ missingDistribute | volume }} MWh </b></span
            >
          </div>
        </div>
        <div class="grid-scroll">
          <div class="grid-distribution">
            <div class="grid-header">
              <div style="width: 20%" class="">CNPJ</div>
              <div style="width: 60%" class="">Razão Social</div>
              <div style="width: 20%" class="">Vol. Distribuído</div>
            </div>

            <div
              v-for="(contrato, indexContrato) in proposta.curtoPrazo
                .distribuicaoPorUnidades"
              :key="`indexContraxt${indexContrato}`"
              class="grid-body"
            >
              <div style="width: 20%" class="">{{ contrato.cnpj }}</div>
              <div style="width: 60%" class="">{{ contrato.razaoSocial }}</div>
              <div style="width: 20%" class="">
                <span v-if="DISTRIBUICAO_POR_UNIDADES.length == 1">{{
                  contrato.volumeDistribuido | volume
                }}</span>

                <vue-numeric
                  v-if="DISTRIBUICAO_POR_UNIDADES.length > 1"
                  @input="calcDistribution"
                  class="numeric-field-grid"
                  currency=""
                  :minus="false"
                  :precision="3"
                  separator="."
                  v-model="contrato.volumeDistribuido"
                />
              </div>
            </div>

            <div
              v-if="DISTRIBUICAO_POR_UNIDADES.length > 1"
              class="box-grid-total"
            >
              <div style="width: 80%"></div>
              <div style="width: 20%" class="mr-2">
                <b
                  >Total:
                  <span class="ml-2">{{ totalDistributed | volume }}</span></b
                >
              </div>
            </div>
          </div>
          <div class="box-buttons">
            <button
              @click="distribuir"
              :disabled="!checkOptions"
              class="btn-action-primary"
              style="max-width: 300px; padding: 8px"
            >
              AVANÇAR
            </button>
          </div>
        </div>
      </div>
    </template>

    <visualizacao-proposta-curto-prazo
      v-if="
        (showPropostaPage &&
          !erroProposta &&
          proposal.status != 'EXPIRADA' &&
          proposal.status != 'RENOVADO') ||
        STATUS == 'APROVADA'
      "
      :status="STATUS"
      :proposta="proposta"
    />

    <ModalBase
      :maxWidth="'700px'"
      @closeModal="closeModalUser"
      v-if="showModalFormUser"
    >
      <template #header> APROVAÇÃO PROPOSTA DE CURTO PRAZO </template>
      <template #body>
        <div style="position: relative; padding: 30px">
          <OverlayLoader v-if="loading" />
          <div style="text-align: center">
            Para seguir com a aprovação da Proposta de Curto Prazo, por favor
            insira seu nome e seu e-mail abaixo:
          </div>

          <form-user
            ref="formUserRef"
            @next="concluirAprovacao"
            @getInfosUser="getInfosUser"
          />
        </div>
      </template>
      <template #footer>
        <div class="wrapper-button-user">
          <button
            @click="aprovar"
            class="btn-action-primary"
            style="max-width: 300px; padding: 8px"
            :disabled="loading"
          >
            Aprovar
          </button>
        </div>
      </template>
    </ModalBase>
  </div>
</template>
<script>
import ModalBase from "@/components/modals/ModalBase.vue";
import OverlayLoader from "@/components/OverlayLoader.vue";
import { CONFIRMATION } from "@/constants/strings";
import FormUser from "@/pages/hot-site/components/FormUser.vue";
import VisualizacaoPropostaCurtoPrazo from "@/pages/hot-site/curto-prazo/VisualizacaoPropostaCurtoPrazo.vue";
import PropostaCurtoPrazoService from "@/services/PropostaCurtoPrazoService";
import { DateTime } from "luxon";
import VueNumeric from "vue-numeric";

export default {
  data() {
    return {
      curtoPrazoId: 6,
      proposta: {
        curtoPrazo: {
          especificacaoVolume: {
            volumeExcedido: 0,
            fomulaPreco: "",
            validadeProposta: "",
          },
          volumeMinimo: 0,
          volumeMaximo: 0,
          distribuicaoPorUnidades: [],
        },
        acoes: null,
        status: "EM_PREENCHIMENTO",
      },
      showDistribuicaoPage: true,
      showPropostaPage: false,
      loading: null,
      showModalFormUser: false,
      userInfos: {
        nome: "",
        email: "",
      },

      erroProposta: undefined,
      ////////////////////////////////////
      totalDistributed: 0,
      FormUserShow: false,
      //limitsMin
      //limitsMaximo   para saber se o volume considerado é valido
      modalGetDataUserShow: false,
      modalProposalApprovalShow: false,
      dateValid: "",
      supplyPeriod: "",
      buyersCnpj: "",
      spinner: false,
      loading: undefined,
      showPropostaPage: false,
      processId: undefined,
      volumeConsiderado: 0,
      missingDistribute: 0,
      setVolume: 0,
      proposal: {
        aprovador: null,
        comprador: "",
        condicaoPagamento: "",
        dataCriacao: "",
        dataProposta: "",
        id: 0,
        multa: "",
        processId: "",
        quantidadeContratada: 0,
        shortTermRedistribution: [],
        spread: 0,
        status: "",
        submercado: "",
        tipoEnergia: "",
        tusd: "",
        volumeRequisitado: 0,
        wbcContract: 0,
      },
      limitsVolume: {
        min: 0,
        max: 0,
      },
      checkOptions: undefined,
      inputDisabled: true,
      logApproval: {
        email: "",
        name: "",
      },
      volumeConsideredIsValid: true,
      proposalErro: undefined,
      offToRange: false,
      codProposta: undefined,
      canRenewProposal: false,
    };
  },
  mounted() {
    this.getProposta();
  },
  computed: {
    STATUS() {
      return this.proposta ? this.proposta.status : "";
    },
    PODE_RENOVAR() {
      return this.proposta ? this.proposta.canRenovarProposta : false;
    },
    PRECO: function () {
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.especificacaoVolume
        ? this.proposta.curtoPrazo.especificacaoVolume.fomulaPreco
        : "";
    },
    VALIDADE: function () {
      const hour =
        DateTime.fromISO(
          this.proposta.curtoPrazo.especificacaoVolume.validadeProposta
        ).toFormat("HH") - 3;
      const minutes = DateTime.fromISO(
        this.proposta.curtoPrazo.especificacaoVolume.validadeProposta
      ).toFormat("mm");
      const date = DateTime.fromISO(
        this.proposta.curtoPrazo.especificacaoVolume.validadeProposta
      ).toFormat("dd/MM/yyyy");
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.especificacaoVolume
        ? `${date} ${hour}:${minutes}`
        : "";
    },
    VOLUME_EXCEDIDO() {
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.especificacaoVolume
        ? this.proposta.curtoPrazo.especificacaoVolume.volumeExcedido
        : 0;
    },
    VOLUME_TOTAL: function () {
      return this.proposta && this.proposta.volumeTotal
        ? this.proposta.volumeTotal
        : 0;
    },
    VOLUME_MAX: function () {
      return this.proposta && this.proposta.curtoPrazo
        ? this.proposta.curtoPrazo.volumeMaximo
        : 0;
    },
    VOLUME_MIN: function () {
      return this.proposta && this.proposta.curtoPrazo
        ? this.proposta.curtoPrazo.volumeMinimo
        : 0;
    },
    DISTRIBUICAO_POR_UNIDADES: function () {
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.distribuicaoPorUnidades
        ? this.proposta.curtoPrazo.distribuicaoPorUnidades.map((g) => {
            if (!g.volumeDistribuido) g.volumeDistribuido = 0;
            return g;
          })
        : [];
    },
    FATOR_ATENDIMENTO_INTEGRAL: function () {
      return this.proposta && this.proposta.curtoPrazo
        ? this.proposta.fatorDeAtendimentoIntegral
        : false;
    },
  },
  methods: {
    setVolumeInicial() {
      if (!this.FATOR_ATENDIMENTO_INTEGRAL) {
        this.setVolume = "";
        this.inputDisabled = false;
      } else {
        if (this.VOLUME_EXCEDIDO > this.VOLUME_MAX) {
          this.setVolume = this.VOLUME_MAX;
        }
      }
    },
    getInfosUser(value) {
      this.userInfos = { nome: value.nome, email: value.email };
    },
    getProposta() {
      //^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$
      const { curtoPrazoId } = JSON.parse(window.atob(this.$route.query.cp));
      this.loading = true;
      this.curtoPrazoId = curtoPrazoId;
      PropostaCurtoPrazoService.getPropostaCurtoPrazoHotsite({
        curtoPrazoid: curtoPrazoId,
      })
        .then((response) => {
          if (response.data.status === "FORA_DO_RANGE") {
            this.loading = false;
            return (this.erroProposta =
              "Devido às condições de mercado, essa proposta poderá ser tratada por seu executivo de vendas.");
          }
          if (!this.FATOR_ATENDIMENTO_INTEGRAL) {
            this.checkOptions = "total";
            this.setVolume = "";
          } else {
            if (this.VOLUME_EXCEDIDO > response.max) {
              this.checkOptions = "total";
            } else {
              this.checkOptions = undefined;
            }
          }
          this.setVolumeInicial();
          this.loading = false;
          this.proposta = response.data;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data) {
            this.erroProposta = error.response.data.message;
          } else {
            this.erroProposta = " ";
          }
        });
    },
    distribuir() {
      if (this.volumeConsiderado === 0 || this.volumeConsiderado < 0) {
        this.mxToggleWarningModal({
          message: CONFIRMATION.CURTO_PRAZO.VOLUME_GREATER_THAN_ZERO,
          btnTrue: "OK",
          btnFalse: "Fechar",
          title: "Atenção",
          showBtnFalse: false,
        });
      } else {
        if (
          parseFloat(this.missingDistribute) != 0 &&
          this.DISTRIBUICAO_POR_UNIDADES.length > 1
        ) {
          this.mxToggleWarningModal({
            message: CONFIRMATION.CURTO_PRAZO.VOLUME_DISTRIBUTED_DIFFERENT,
            btnTrue: "OK",
            btnFalse: "Fechar",
            title: "Atenção",
            showBtnFalse: false,
          });
        } else {
          if (!this.volumeConsideredIsValid) {
            this.mxToggleWarningModal({
              message: CONFIRMATION.CURTO_PRAZO.RELATIONSHIP_EXECUTE_PROPOSAL,
              btnTrue: "SIM",
              btnFalse: "NÃO",
              title: "Atenção",
              showBtnFalse: true,
            })
              .then(() => {
                this.enviarDistribuir();
              })
              .catch(() => {});
          } else {
            this.enviarDistribuir();
          }
        }
      }
    },

    enviarDistribuir() {
      let payload = [];
      this.DISTRIBUICAO_POR_UNIDADES.forEach((contrato) => {
        payload.push({
          contratoId: contrato.contratoId,
          volumeDistribuido: contrato.volumeDistribuido.toFixed(3),
        });
      });
      PropostaCurtoPrazoService.distribuirCurtoPrazo({
        curtoPrazoId: this.curtoPrazoId,
        payload,
      })
        .then((response) => {
          this.showDistribuicaoPage = false;
          this.showPropostaPage = true;
          this.getProposta();
        })
        .catch((message) => {});
    },

    voltar() {
      this.showDistribuicaoPage = true;
      this.showPropostaPage = false;
      this.getProposta();
    },
    closeModalUser() {
      this.showModalFormUser = false;
    },
    iniciarAprovacao() {
      this.showModalFormUser = true;
    },
    aprovar() {
      this.$refs.formUserRef.send();
    },
    concluirAprovacao() {
      this.loading = true;
      PropostaCurtoPrazoService.aprovarPropostaCurtoPrazo({
        curtoPrazoId: this.curtoPrazoId,
        payload: this.userInfos,
      })
        .then(() => {
          this.loading = false;
          document.location.reload();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    calcDistribution() {
      this.totalDistributed = 0;
      this.DISTRIBUICAO_POR_UNIDADES.forEach((contract) => {
        this.totalDistributed =
          this.totalDistributed + contract.volumeDistribuido;
      });
      if (this.checkOptions) {
        this.missingDistribute = parseFloat(
          parseFloat(this.volumeConsiderado.toFixed(3)) -
            parseFloat(this.totalDistributed.toFixed(3))
        );
        if (this.DISTRIBUICAO_POR_UNIDADES.length == 1) {
          this.proposta.curtoPrazo.distribuicaoPorUnidades[0].volumeDistribuido =
            this.volumeConsiderado;
          this.totalDistributed = this.volumeConsiderado;
          this.$forceUpdate();
        }
      }
    },
    closeGetDataUser() {
      this.modalGetDataUserShow = false;
    },
    formatSetVolume() {
      let value = this.setVolume;
      if (value < 0) {
        value = value * -1;
      }
      this.setVolume = parseFloat(value).toFixed(3);
    },
    setVolumeInicial() {
      if (!this.FATOR_ATENDIMENTO_INTEGRAL) {
        this.setVolume = "";
        this.inputDisabled = false;
      } else {
        if (this.VOLUME_EXCEDIDO > this.VOLUME_MAX) {
          this.setVolume = this.VOLUME_MAX;
        }
      }
    },
    changeSetVolume() {
      this.volumeConsiderado = this.setVolume;
      this.calcDistribution();
    },

    renovarProposta() {
      this.loading = true;
      PropostaCurtoPrazoService.renovarPropostaCurtoPrazo({
        curtoPrazoId: this.curtoPrazoId,
      })
        .then(() => {
          this.loading = false;
          document.location.reload();
        })
        .catch(() => {
          this.loading = false;
        });
    },

    //////////////////////////////

    getShortTerm() {
      this.spinner = true;
      ShortTermService.getShortTerm({
        codProposta: this.codProposta,
      })
        .then((response) => {
          this.processId = response.curtoPrazo.processId;
          if (!this.FATOR_ATENDIMENTO_INTEGRAL) {
            this.checkOptions = "total";
            this.setVolume = "";
          } else {
            if (this.VOLUME_EXCEDIDO > response.max) {
              this.checkOptions = "total";
            } else {
              this.checkOptions = undefined;
            }
          }
          this.spinner = false;
          this.logApproval =
            response.logAprovacao.length > 0 ? response.logAprovacao[0] : null;
          if (this.VOLUME_EXCEDIDO < 0) {
            this.VOLUME_EXCEDIDO = this.VOLUME_EXCEDIDO * -1;
          }
          this.proposal = response.curtoPrazo;
          this.canRenewProposal = response.canRenewProposal;
          if (
            this.proposal.status == "EM_PREENCHIMENTO" &&
            !this.showPropostaPage
          ) {
            this.showDistribuicaoPage = true;
            this.showPropostaPage = false;
          }
          if (
            this.showPropostaPage ||
            this.proposal.status != "EM_PREENCHIMENTO"
          ) {
            this.showDistribuicaoPage = false;
            this.showPropostaPage = true;
          }
          if (response.curtoPrazo.status == "EXPIRADA") {
          }
          //this.proposal.status  = 'EXPIRADA'    //MOCK
          this.calcSupplyPeriod();
          this.setVolumeInicial();
        })
        .catch((e) => {
          this, (this.proposalErro = e);
          this.spinner = false;
        });
    },
    renewProposal() {
      this.spinner = true;
      ShortTermService.renewProposal({ codProposta: this.codProposta })
        .then((response) => {
          this.spinner = false;
          this.getShortTerm();
        })
        .catch((e) => {
          this.spinner = false;
          this.mxShowModalAlert({
            title: "Atenção",
            message: e.mensagem,
            type: "confirm",
            btnOk: "Atualizar",
          }).then(() => {
            setTimeout(() => {
              document.location.reload();
            }, 200);
          });
        });
    },
    getUserResultOffToRange() {
      this.modalGetDataUserShow = false;
      let requestDistrubution = [];
      this.DISTRIBUICAO_POR_UNIDADES.forEach((contract) => {
        requestDistrubution.push({
          wbcContract: contract.wbcContract,
          volume: contract.volumeDistribuido,
        });
      });
      ShortTermService.redistributeShortTerm(
        this.processId,
        requestDistrubution
      )
        .then((response) => {
          this.spinner = false;
          this.showDistribuicaoPage = false;
          this.showPropostaPage = true;
          this.getShortTerm();
        })
        .catch((message) => {
          this.spinner = false;
          this.mxShowModalAlert({
            title: "Atenção !",
            message: message,
            type: "confirm",
            btnOk: "Atualizar",
          });
        });
    },
    getUserResult(value) {
      this.spinner = true;
      let requestAproval = {
        email: value.infos.email,
        id: 0,
        name: value.infos.name,
        processId: this.processId,
        type: "CP",
      };
      HotsiteService.logAproval(requestAproval)
        .then((response) => {
          this.modalGetDataUserShow = false;
          this.approveProposal();
        })
        .catch((e) => {
          this.spinner = false;
          this.modalGetDataUserShow = false;
          this.mxShowModalAlert({
            title: "Atenção",
            message: e.mensagem,
            type: "confirm",
            btnOk: "Atualizar",
          }).then(() => {
            this.getShortTerm();
          });
        });
    },
    approveProposal() {
      this.spinner = true;
      ShortTermService.updateShortTerm(this.codProposta, "APROVADO")
        .then((response) => {
          this.spinner = false;
          this.modalProposalApprovalShow = true;
        })
        .catch((message) => {
          this.mxShowModalAlert({
            title: "Atenção",
            message: message.mensagem,
            type: "confirm",
            btnOk: "Atualizar",
          }).then(() => {
            //this.getConfiguration();
            setTimeout(() => {
              document.location.reload();
            }, 200);
          });
          this.spinner = false;
        });
    },
    closeProposalApproval() {
      this.getShortTerm();
      this.modalProposalApprovalShow = false;
    },
  },
  watch: {
    /*
    setVolume: function () {
      this.volumeConsiderado = this.setVolume;
      this.calcDistribution();
    },
    */
    checkOptions: function () {
      if (this.FATOR_ATENDIMENTO_INTEGRAL) {
        if (this.checkOptions == "defined") {
          this.inputDisabled = true;
          if (this.VOLUME_EXCEDIDO < this.VOLUME_MAX) {
            this.setVolume = this.VOLUME_EXCEDIDO;
          }
          this.volumeConsiderado = this.VOLUME_EXCEDIDO;
          this.calcDistribution();
        }
        if (this.checkOptions == "total") {
          this.inputDisabled = false;
          if (this.VOLUME_EXCEDIDO < this.VOLUME_MAX) {
            this.setVolume = this.VOLUME_EXCEDIDO;
          }
          this.calcDistribution();
        }
      }
    },

    volumeConsiderado: function () {
      if (
        this.volumeConsiderado < this.VOLUME_MIN ||
        this.volumeConsiderado > this.VOLUME_MAX
      ) {
        this.volumeConsideredIsValid = false;
      } else {
        this.volumeConsideredIsValid = true;
      }
    },
  },
  components: {
    VisualizacaoPropostaCurtoPrazo,
    VueNumeric,
    FormUser,
    ModalBase,
    OverlayLoader,
  },
};
</script>

<style lang="scss" scoped>
.box-erros {
  text-align: center;
}
.wrapper-button-user {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.templates {
  padding-top: 50px;
}
.page {
  background: #e5e5e5;
  min-height: 117.6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.__header {
  position: fixed;
  height: 50px;
  width: 100%;
  display: flex;
  background: #505050;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #ffffff;
  img {
    width: 40px;
  }
}
.header__box-logo {
  padding: 0 15px 0 15px;
}
.box-specification,
.box-distribution,
.box-proposal {
  background: #fff;
  width: 80%;
  margin-top: 30px;
  border-radius: 5px;
}
.box-specification,
.box-proposal {
  margin-top: 80px;
}
.box-proposal {
  background: none;
}
.box-distribution {
  padding: 20px;
}
.specification-header,
.proposal-header {
  display: flex;
  align-items: center;
  padding: 6px 0px 6px 25px;
  background: #929292;
  border-radius: 4px 4px 0px 0px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #ffffff;
  //@include mobile {
  // font-size: 14px;
  //}
}
.specification-content {
  padding: 20px;
}
.box-specification-infos,
.box-distribution-infos {
  background: #f8f8f8;
  border-radius: 5px;
  width: 100%;
  padding: 11px 30px 11px 30px;
  display: flex;
  justify-content: space-between;
  //@include mobile {
  // flex-direction: column;
  //  text-align: center;
  // }
}
.infos-item {
  margin-bottom: 10px;
}
.option-item {
  display: flex;
  padding: 10px;
  text-align: left;
}
.box-input-radio {
  padding: 0px 10px 0px 10px;
}
.box-distribution {
  padding-top: 20px;
}
.specification-label {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #000000;
}
.box-specificstion-option {
  display: flex;
  justify-content: space-between;
  //@include mobile {
  //  flex-direction: column;
  //  text-align: center;
  //}
}
.option-left {
  width: 40%;
  //@include mobile {
  width: 100%;
}
.option-right {
  width: 60%;
  //@include mobile {
  //  width: 100%;
  //}
}
.distribution-header {
  margin: 30px 0 25px 0;
  display: flex;
  justify-content: space-between;
  //include mobile {
  //  flex-direction: column;
  //  text-align: center;
  //}
}
.grid-scroll {
  overflow: auto;
}
.grid-distribution {
  min-width: 700px;
}
.grid-header,
.grid-body {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  padding: 8px 0 8px 0;
}
.grid-header {
  font-weight: 600;
  color: #000000;
  border-bottom: 1px solid #929292;
}
.grid-body {
  color: #505050;
  border-bottom: 1px solid #e5e5e5;
}
.box-grid-total {
  background: #e8e8e8;
  display: flex;
  justify-content: flex-end;
  padding: 8px 0 8px 0;
}
.info-value {
  font-weight: bold;
}
.numeric-field {
}
.numeric-field-grid {
  background: #f1f1f1;
  border-radius: 3px;
  border: none;
  width: 100px;
  text-align: center;
  &:disabled {
    background: #c4c4c4;
    color: #c4c4c4;
  }
}
.color-red {
  color: red;
}
.box-buttons {
  margin-top: 31px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.btn-orange {
  width: 174px;
  height: 42px;
  background: #f86511;
  border-radius: 4px;
  color: #fff;
  &:hover {
    background: rgba($color: #f86511, $alpha: 0.8);
    color: #fff;
  }
  &:disabled {
    background: rgba($color: #ccc, $alpha: 0.8);
    cursor: not-allowed;
  }
}
.btn-border {
  width: 174px;
  height: 42px;
  border: 1px solid #505050;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
}
.proposal-content {
  padding: 0 20px 0 20px;
}
.proposal-content-item {
  display: flex;
  padding: 8px 0 8px 0;
  border-bottom: 1px solid #e5e5e5;
  //@include tablet {
  // flex-direction: column;
  //}
}
.content-item-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #000000;
  min-width: 250px;
}
.content-item-value {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #505050;
}
.box-message-approve {
  padding: 10px 0 10px 0px;
  background: #ffffff;
  border: 1px solid #2ed47a;
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.box-message-icon {
  color: #2ed47a;
  font-size: 20px;
  min-width: 60px;
  text-align: center;
}
.alert-msg {
  width: 100%;
  background: #f8f8f8;
  border-radius: 5px;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #505050;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.alert-icon {
  padding: 0 10px 0 10px;
  img {
    width: 20px;
    margin-top: -2px;
  }
}
.box-content {
  margin-top: 100px;
}
.content-icon {
  padding: 36px 20px 20px 20px;
  //@include mobile {
  //  flex-direction: column;
  //  padding: 20px;
  // }
}
</style>
