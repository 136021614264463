<template>
  <div class="page-body" :style="mxIsChromeBrowser() && 'height: 117.6vh'">
    <div class="loaderPage" v-if="loadingPage">
      <overlay-loader />
    </div>

    <div id="detalhamento" v-if="!paginaRecompra">
      <!-- tela de loading " -->
      <tela-loading v-if="loadingDetalhamento" />

      <!-- tela para quando contrato não for carregado v-if="!contratoCarregado -->

      <contrato-nao-carregado v-if="!contratoCarregado" :error="loadingError" />

      <!-- Mensagem de Contrato expirado  -->
      <contrato-expirado
        :demonstrativo="DEMONSTRATIVO"
        :contrato="CONTRATO_PRINCIPAL"
        v-if="
          contratoCarregado && (DEMONSTRATIVO.expirado || !DEMONSTRATIVO.ativo)
        "
      />

      <!--DEMONSTRATIVO -->
      <div
        class="page"
        v-if="
          contratoCarregado &&
          DEMONSTRATIVO.ativo &&
          !DEMONSTRATIVO.expirado &&
          !DEMONSTRATIVO.recompraDemonstrativo
        "
        id="demonstrativo"
      >
        <div class="__header">
          <div class="header__box-logo">
            <img src="@/assets/logo/orange.svg" />
          </div>
          <div class="text__header">Portal Matrix</div>
        </div>
        <div v-if="CONTRATO_PRINCIPAL" class="box">
          <div
            v-if="CONTRATO_PRINCIPAL.descricaoPortifolio === 'MATRIX_VAREJO'"
            class="logo-matrix"
          >
            <img src="@/assets/logo/orange.svg" width="100" />
          </div>
          <div class="title-demosntrativo">
            Demonstrativo de Faturamento -
            {{ DATA_REFERENCIA }}
          </div>
          <div class="name-company mt-4">
            <b>{{ CONTRATO_PRINCIPAL.empresa.razaoSocial }}</b>
          </div>

          <!--MENSAGENS APROVADO | CONTESTADO | PENDENTE -->

          <div
            class="mt-3 alert _alert-aprovado"
            v-if="DEMONSTRATIVO.situacaoDemonstrativo.situacao === 'APROVADO'"
          >
            O Demonstrativo de Faturamento foi
            <b>APROVADO</b> por
            <b>{{ DEMONSTRATIVO.situacaoDemonstrativo.nome }}</b>
            (e-mail: <b>{{ DEMONSTRATIVO.situacaoDemonstrativo.email }}</b
            >).
          </div>

          <div
            class="mt-3 alert _alert-contestado"
            v-if="DEMONSTRATIVO.situacaoDemonstrativo.situacao === 'REPROVADO'"
          >
            O Demonstrativo de Faturamento foi
            <b>CONTESTADO</b> por
            <b>{{ DEMONSTRATIVO.situacaoDemonstrativo.nome }}</b>
            (e-mail: <b>{{ DEMONSTRATIVO.situacaoDemonstrativo.email }}</b
            >).
          </div>

          <div
            class="mt-3 alert _alert-pendente"
            v-if="
              DEMONSTRATIVO.situacaoDemonstrativo.situacao !== 'APROVADO' &&
              DEMONSTRATIVO.situacaoDemonstrativo.situacao !== 'REPROVADO'
            "
          >
            DOCUMENTO PRELIMINAR PARA VALIDAÇÃO
          </div>
          <!-- MENSAGENS E MODAIS CURTO PRAZO -->
          <blocos-msg-curto-prazo
            :contratoPrincipal="CONTRATO_PRINCIPAL"
            :demonstrativo="DEMONSTRATIVO"
            @solicitarPropostaCurtoPrazo="solicitarPropostaCurtoPrazo"
            :curtoPrazo="CURTO_PRAZO"
          />
          <Modal-solicitar-curto-prazo
            :showModalSolicitarCurtoPrazo="showModalSolicitarCurtoPrazo"
            @salvarSemCurtoPrazo="salvarSemCurtoPrazo"
            @salvarComCurtoPrazo="salvarComCurtoPrazo"
          />

          <!--MENSAGEM RECOMPRA -->
          <div
            v-if="
              CONTRATO_PRINCIPAL.flexibilidade &&
              CONTRATO_PRINCIPAL.flexibilidade.take === 'Abaixo' &&
              CONTRATO_PRINCIPAL.parametrizacao.recebeReducaoDeMontantes &&
              DEMONSTRATIVO.situacaoDemonstrativo.situacao !== 'APROVADO' &&
              DEMONSTRATIVO.situacaoDemonstrativo.situacao !== 'REPROVADO'
            "
            class="box-alert-short-term-proposal"
          >
            <div :class="{ 'box-alert-logo': data.statusPrice != 'APROVADO' }">
              <img :src="mountImg('/images/icon-alert-triangulo.svg')" />
            </div>
            <div class="box-alert-text" v-if="SPREAD_EXPIRADO">
              No momento não há preços ofertados para redução de montantes, caso
              tenha interesse em receber uma oferta por favor tente novamente em
              breve.
            </div>
            <div v-else class="box-alert-text">
              Apresentamos abaixo o Demonstrativo de Faturamento referente ao
              suprimento de <b>{{ DATA_DEMONSTRATIVO }}</b
              >, contrato <b>{{ CONTRATO_PRINCIPAL.codigo }}</b> do(a)
              <b>{{ CONTRATO_PRINCIPAL.empresa.razaoSocial }}</b
              >, cujo consumo apurado este mês
              <u><b>não atingiu o take mínimo contratado</b></u
              >. <br /><br />
              <b>IMPORTANTE: </b> Você pode visualizar uma
              <b>Proposta de Redução de Montantes</b> referente ao
              <b>volume não utilizado</b> neste mês.
              <div class="box-button-msg">
                <button @click="iniciarPropostaRecompra" class="btn">
                  VISUALIZAR PROPOSTA DE REDUÇÃO DE MONTANTES
                </button>
              </div>
            </div>
          </div>

          <!--  Cards Matrix -->
          <cards-matrix
            :contrato="CONTRATO_PRINCIPAL"
            v-if="
              CONTRATO_PRINCIPAL &&
              CONTRATO_PRINCIPAL.descricaoPortifolio === 'MATRIX_VAREJO'
            "
          />

          <!--  Cards Normal -->
          <cards
            v-if="
              CONTRATO_PRINCIPAL &&
              CONTRATO_PRINCIPAL.descricaoPortifolio !== 'MATRIX_VAREJO'
            "
            :contrato="CONTRATO_PRINCIPAL"
            :portifolioContrato="portifolioContrato"
            :data="data"
          />

          <!-- APROVAÇÃO DO DEMONSTRATIVO -->

          <ModalBase
            :maxWidth="'900px'"
            :footerHide="true"
            v-if="showModalAlterarSituacao"
            @closeModal="closeModals"
          >
            <template #header>
              <span v-if="situacao == 'APROVADO'">
                APROVAR DEMONSTRATIVO DE FATURAMENTO
              </span>
              <span v-if="situacao == 'REPROVADO'">
                CONTESTAR DEMONSTRATIVO DE FATURAMENTO
              </span>
            </template>
            <template #body>
              <div style="position: relative">
                <overlay-loader v-if="loadingModalSituacao" />
                <AprovarContestar
                  @situacaoAtualizada="situacaoAtualizada"
                  @finalizarDemonstrativo="finalizarDemonstrativo"
                  :contrato="CONTRATO_PRINCIPAL"
                  :situacao="situacao"
                />
              </div>
            </template>
          </ModalBase>
          <div v-if="SITUACAO_DEMONSTRATIVO" class="rounded-container">
            <div class="select-option">Selecione a opção desejada:</div>
            <div class="box-buttons-approval">
              <button
                @click="alterarSituacao('APROVADO')"
                class="_btn _btn--green"
              >
                CONFIRMAR
              </button>
              <button
                @click="alterarSituacao('REPROVADO')"
                class="_btn _btn--red ml-3"
              >
                CONTESTAR
              </button>
            </div>
          </div>

          <div class="infotAdtional">Informações Adicionais</div>

          <!-- REDISTRIUIÇÃO CONTRATOS FILHOS -->

          <div>
            <mini-description-table-card
              width="100%"
              title="Observações"
              :config="OBSERVACOES_FOOTER"
            />
          </div>

          <distribution
            @update="reflash"
            :demonstrativo="DEMONSTRATIVO"
            :mainContrato="CONTRATO_PRINCIPAL"
          />
          <!--GRÁFICO-->
          <consumption-history
            v-if="HISTORICO_CONSUMO"
            :historicoConsumo="HISTORICO_CONSUMO"
            :data="data"
          />
          <!-- TERMOS -->
          <terms
            v-if="CONTRATO_PRINCIPAL.descricaoPortifolio !== 'MATRIX_VAREJO'"
            :contrato="CONTRATO_PRINCIPAL"
          />
          <terms-matrix
            v-if="CONTRATO_PRINCIPAL.descricaoPortifolio === 'MATRIX_VAREJO'"
          />
          <!--PDF-->
          <div class="box-pdf">
            <div @click="downloadPdf" class="btn-pdf">
              <span class="icon-pdf">
                <img :src="mountImg('/images/pdf.svg')" />
              </span>
              Visualizar Demonstrativo de faturamento em PDF
            </div>
          </div>
          <div class="instruction">
            Caso tenha alguma dúvida, entre em contato com a Matrix Energia
            através do número (11)3327-2900
          </div>
        </div>
      </div>
    </div>
    <escolher-tipo-recompra
      @tipoSelecionado="tipoRecompraSelecionado"
      v-if="paginaRecompra"
    />
    <div
      v-if="contratoCarregado && DEMONSTRATIVO.recompraDemonstrativo"
      class="wrapper-recompra"
    >
      <recompra
        :contratoPrincipal="CONTRATO_PRINCIPAL"
        :demonstrativo="DEMONSTRATIVO"
      />
    </div>
  </div>
</template>

<script>
import ModalBase from "@/components/modals/ModalBase";
import Cards from "@/pages/hot-site/CardsDemonstrativo";
import CardsMatrix from "@/pages/hot-site/CardsDemonstrativosMatrix.vue";
import AprovarContestar from "@/pages/hot-site/components/AprovarContestar";
import ContratoExpirado from "@/pages/hot-site/components/ContratoExpirado.vue";
import ContratoNaoCarregado from "@/pages/hot-site/components/ContratoNaocarregado.vue";
import MiniDescriptionTableCard from "@/pages/hot-site/components/MiniDescriptionTableCard.vue";
import TelaLoading from "@/pages/hot-site/components/TelaLoading.vue";
import ConsumptionHistory from "@/pages/hot-site/ConsumptionHistory";
import BlocosMsgCurtoPrazo from "@/pages/hot-site/curto-prazo/BlocosMsgCurtoPrazo.vue";
import ModalSolicitarCurtoPrazo from "@/pages/hot-site/curto-prazo/ModalSolicitarCurtoPrazo.vue";
import Distribution from "@/pages/hot-site/DistributionPerUnit";
import ModalAskShortTerm from "@/pages/hot-site/ModalAskShortTerm.vue";
import ModalGetDataUser from "@/pages/hot-site/ModalGetDataUser.vue";
import ModalShortTermSent from "@/pages/hot-site/ModalShortTermSent.vue";
import ProposalAmountReductionDifferentPrice from "@/pages/hot-site/proposal-repurchase/ProposalAmountReductionDifferentPrice.vue";
import ProposalAmountReductionSinglePrice from "@/pages/hot-site/proposal-repurchase/ProposalAmountReductionSinglePrice.vue";
import ProposalRepurchaseAproved from "@/pages/hot-site/proposal-repurchase/ProposalRepurchaseAproved.vue";
import ReductionAmountsContested from "@/pages/hot-site/proposal-repurchase/ReductionAmountsContested.vue";
import EscolherTipoRecompra from "@/pages/hot-site/recompra/EscolherTipoRecompra";
import Recompra from "@/pages/hot-site/recompra/Recompra";
import Terms from "@/pages/hot-site/Terms";
import TermsMatrix from "@/pages/hot-site/TermsMatrix.vue";
import DemonstrativoService from "@/services/DemonstrativoService";
import HotsiteService from "@/services/hotsiteService";
import PropostaCurtoPrazoService from "@/services/PropostaCurtoPrazoService";
import { formatData } from "@/utils/functionsUtils";
import { DateTime } from "luxon";

export default {
  components: {
    EscolherTipoRecompra,
    Cards,
    Distribution,
    ConsumptionHistory,
    Terms,
    TermsMatrix,
    CardsMatrix,
    ModalBase,
    AprovarContestar,
    Recompra,
    BlocosMsgCurtoPrazo,
    ContratoExpirado,
    TelaLoading,
    ContratoNaoCarregado,
    ModalSolicitarCurtoPrazo,
    ModalGetDataUser,
    ModalAskShortTerm,
    ModalShortTermSent,
    ProposalAmountReductionDifferentPrice,
    ProposalAmountReductionSinglePrice,
    ProposalRepurchaseAproved,
    ReductionAmountsContested,
    MiniDescriptionTableCard,
  },
  data() {
    return {
      loadingError: null,
      contratoCarregado: false,
      loadingDetalhamento: false,
      showModalAlterarSituacao: false,
      situacao: null,
      showModalSolicitarCurtoPrazo: false,
      payloadSituacao: null,
      loadingPage: false,
      loadingModalSituacao: false,
      //detalhamento: {},
      paginaRecompra: false,
      tipoContrato: null,
      portifolioContrato: null,
      messageValorShortTherm: true,
      spinner: false,
      modalGetDataUserShow: false,
      modalAskShortTermShow: false,
      modalShortTermSentShow: false,
      approveDemoType: undefined,
      requestListShortTerm: [],
      newAmoutLiquido: undefined,
      amountLiquido: undefined,
      showData: false,
      approvalStatus: undefined,
      modalLoad: false,
      loaded: false,
      error: false,
      step: 2,
      option: undefined,
      repurchase: false,
      loading: undefined,
      processId: undefined,
      valid: undefined,
      approval: true,
      messages: [],
      taskId: undefined,
      processInstanceId: undefined,
      //justify: "",
      msgJusErro: false,
      requestAproval: {
        email: "",
        id: 0,
        name: "",
        processId: 0,
        type: "",
      },
      requestConfirm: {
        id: 0,
        idProc: 0,
        justify: "",
        status: "",
      },
      type: undefined,
      data: {
        takeInformation: {
          aboveTake: false,
          belowTake: false,
          difference: 0,
          repurchaseProposal: false,
          shortTermProposal: false,
        },
        mainContract: {},
      },
      proposalShortTerm: {
        emailsEnviados: [],
        curtoPrazo: {
          status: undefined,
        },
      },
      isRepurchaseProposal: false,
      isAmountProposal: null,
      shortTermCode: "",
    };
  },
  computed: {
    DATA_DEMONSTRATIVO: function () {
      const dataFormatada = DateTime.fromISO(
        this.detalhamento.contratoPrincipal.dataReferencia
      ).toFormat("MM/yyyy");
      return dataFormatada;
    },
    CONTRATO_PRINCIPAL: function () {
      return this.detalhamento.contratoPrincipal;
    },
    FLEXIBILIDADE: function () {
      return this.CONTRATO_PRINCIPAL.flexibilidade;
    },
    DEMONSTRATIVO: function () {
      return this.detalhamento.demonstrativo;
    },
    CURTO_PRAZO: function () {
      return this.DEMONSTRATIVO && this.DEMONSTRATIVO.curtoPrazoDemonstrativo
        ? this.DEMONSTRATIVO.curtoPrazoDemonstrativo
        : null;
    },
    HISTORICO_CONSUMO: function () {
      return this.detalhamento.historicoConsumo;
    },
    SITUACAO_DEMONSTRATIVO: function () {
      if (
        this.DEMONSTRATIVO &&
        this.DEMONSTRATIVO.situacaoDemonstrativo.situacao === "EM_PREENCHIMENTO"
      ) {
        return true;
      }
      return false;
    },
    SPREAD_EXPIRADO: function () {
      return this.DEMONSTRATIVO ? this.DEMONSTRATIVO.spreadExpirado : false;
    },
    DATA_REFERENCIA() {
      if (this.CONTRATO_PRINCIPAL.dataReferencia) {
        return formatData(this.CONTRATO_PRINCIPAL.dataReferencia, "MM/yyyy");
      }
    },
    OBSERVACOES_FOOTER() {
      return [
        {
          desc: "Considerando a Resolução Normativa nº 1.080/23, a qual alterou a aplicação das cotas de Proinfa a partir de janeiro de 2024, e o CO 1.012/23 da Câmara de Comercialização de Energia Elétrica, para o faturamento do suprimento a partir do mês de fevereiro de 2024, será considerada a cota de Proinfa divulgada pela CCEE referente ao mês anterior.",
        },
      ];
    },
  },
  mounted() {
    const { contratoId } = JSON.parse(window.atob(this.$route.query.code));
    this.loadingDetalhamento = true;
    DemonstrativoService.getDemonstrativo(contratoId)
      .then((response) => {
        this.contratoCarregado = true;
        this.detalhamento = response;
      })
      .catch((e) => {
        this.loadingError = e.response;
      })
      .finally(() => (this.loadingDetalhamento = false));
  },
  /*
  HotsiteService.getBilling({processId: this.processInstanceId})
      .then((response) => {
        console.log("response", response)
        if (response.tipoContrato) {
          this.tipoContrato = response.tipoContrato
        }
        if (response.portifolioContrato) {
          this.portifolioContrato = response.portifolioContrato
        }
        this.showData = response.showData;
        this.approvalStatus = response.approvalStatus
        this.shortTermCode = response.shortTermCode
        var newData = response;
        if (newData.billingHistoryDTO.length > 0) {
          let dto = newData.billingHistoryDTO.sort(function (a, b) {
            return a.ano < b.ano || (a.ano == b.ano && a.mes < b.mes) ? -1 : a.ano > b.ano ? 1 : 0;
          });
        }
        if (newData.billingHistoryDTO.length == 0) {
          newData.billingHistoryDTO.unshift({
            mes: moment().subtract(1, "M").locale("pt-br").format("MMM/YY"),
          });
          newData.billingHistoryDTO.unshift({
            mes: moment().subtract(2, "M").locale("pt-br").format("MMM/YY"),
          });
          newData.billingHistoryDTO.unshift({
            mes: moment().subtract(3, "M").locale("pt-br").format("MMM/YY"),
          });
        }
        if (newData.billingHistoryDTO.length == 1) {
          newData.billingHistoryDTO.unshift({
            mes: moment().subtract(2, "M").locale("pt-br").format("MMM/YY"),
          });
          newData.billingHistoryDTO.unshift({
            mes: moment().subtract(3, "M").locale("pt-br").format("MMM/YY"),
          });
        }
        if (newData.billingHistoryDTO.length == 2) {
          newData.billingHistoryDTO.unshift({
            mes: moment().subtract(3, "M").locale("pt-br").format("MMM/YY"),
          });
        }
        var mainContract = {};
        response.billings.length > 1
            ? (mainContract = response.billings.filter(
                (g) => g.contractParent == 1
            ))
            : (mainContract = response.billings[0]);
        response.billings.length > 1
            ? (newData.mainContract = mainContract[0])
            : (newData.mainContract = mainContract);
        response.billings.forEach((contract) => {
          this.requestListShortTerm.push({
            cnpj: contract.cnpj,
            companyName: contract.nameCompany,
            wbcContract: contract.wbcContract,
          });
        });
        if (response.repurchaseStatus) {
          if (
              response.takeInformation.repurchaseProposal &&
              response.takeInformation.belowTake &&
              (response.repurchaseStatus.tipo == "RECOMPRA_MANUAL" ||
                  response.repurchaseStatus.tipo == "PRECO_UNICO" ||
                  response.repurchaseStatus.tipo == "PRECOS_DIFERENTES" ||
                  response.repurchaseStatus.tipo == "APROVADO")
          ) {
            this.isRepurchaseProposal = true;
          }
        }
        if (this.isRepurchaseProposal == true) {
          response.repurchaseStatus.tipo == "PRECO_UNICO"
              ? (this.isAmountProposal = "PRECO_UNICO")
              : null;
          response.repurchaseStatus.tipo == "PRECOS_DIFERENTES"
              ? (this.isAmountProposal = "PRECOS_DIFERENTES")
              : null;
          response.repurchaseStatus.tipo == "APROVADO"
              ? (this.isAmountProposal = "APROVADO")
              : null;
          response.repurchaseStatus.tipo == "RECOMPRA_MANUAL"
              ? (this.isAmountProposal = "RECOMPRA_MANUAL")
              : null;
        }
        if (Array.isArray(mainContract)) {
          this.amountLiquido = mainContract[0].amountLiquido;
        } else {
          this.amountLiquido = mainContract.amountLiquido;
        }
        this.data = newData;
        this.verifyFactorAtt();
        if (this.data.takeInformation.statusProposal == "CREATED") {
          this.getProposalShortTerm();
        }
        this.loaded = true;
      })
      .catch(() => {
        this.loaded = true;
        this.error = false;
      });
  */
  methods: {
    solicitarPropostaCurtoPrazo() {
      this.salvarComCurtoPrazo("APROVADO");
    },
    finalizarDemonstrativo(payload) {
      this.payloadSituacao = payload;
      if (
        payload.situacao === "APROVADO" &&
        this.CONTRATO_PRINCIPAL.parametrizacao.recebeCurtoPrazo &&
        this.CONTRATO_PRINCIPAL.flexibilidade &&
        this.CONTRATO_PRINCIPAL.flexibilidade.take === "Acima"
      ) {
        this.showModalAlterarSituacao = false;
        this.showModalSolicitarCurtoPrazo = true;
      } else {
        this.salvarSitucaoDemonstrativo();
      }
    },
    salvarSemCurtoPrazo() {
      this.loadingPage = true;
      this.salvarSitucaoDemonstrativo();
    },
    salvarComCurtoPrazo(status) {
      this.loadingPage = true;
      PropostaCurtoPrazoService.criarPropostaCurtoPrazo({
        contratoId: this.CONTRATO_PRINCIPAL.id,
      })
        .then(() => {
          if (status === "APROVADO") {
            document.location.reload(true);
            this.loadingPage = false;
          } else {
            this.salvarSitucaoDemonstrativo();
          }
        })
        .catch((error) => {
          this.loadingPage = false;
          this.mxToggleWarningModal({
            message: error.response.data.message,
            btnTrue: "OK",
            btnFalse: "Fechar",
            title: "Atenção",
            showBtnFalse: false,
          })
            .then(() => {
              if (status !== "APROVADO") {
                this.loadingPage = true;
                this.salvarSitucaoDemonstrativo();
              }
            })
            .catch(() => {});
        });
    },
    salvarSitucaoDemonstrativo() {
      this.loadingModalSituacao = true;
      DemonstrativoService.patchSituacao(
        this.CONTRATO_PRINCIPAL.id,
        this.payloadSituacao
      )
        .then(() => {
          this.loadingPage = false;
          this.loadingModalSituacao = false;
          this.showModalAlterarSituacao = false;
          this.situacaoAtualizada(this.situacao);
        })
        .catch((error) => {
          this.loadingPage = false;
          this.loadingModalSituacao = false;
          this.showModalAlterarSituacao = false;
          this.mxToggleWarningModal({
            message: error.response.data.message,
            btnTrue: "OK",
            btnFalse: "Fechar",
            title: "Atenção",
            showBtnFalse: false,
          })
            .then(() => {
              document.location.reload(true);
            })
            .catch(() => {});
        });
    },
    alterarSituacao(value) {
      (this.situacao = value), (this.showModalAlterarSituacao = true);
    },
    closeModals() {
      this.showModalAlterarSituacao = false;
    },
    situacaoAtualizada(situacao) {
      const labels = {
        APROVADO: {
          title: "CONFIRMAÇÃO ENVIADA!",
          message: "Suas Notas Fiscais serão processadas e enviadas em breve.",
        },
        REPROVADO: {
          title: "CONTESTAÇÃO ENVIADA!",
          message: "Estamos avaliando sua solicitação e retornaremos em breve.",
        },
      };
      const title = labels[situacao].title;
      const message = labels[situacao].message;
      this.closeModals();
      this.mxToggleWarningModal({
        message,
        btnTrue: "Concluir",
        btnFalse: "Fechar",
        title,
        showBtnFalse: false,
      })
        .then(() => document.location.reload(true))
        .catch(() => {
          document.location.reload(true);
        });
    },
    downloadPdf() {
      let url =
        process.env.VUE_APP_ROOT_BILLING +
        `/demonstrativo/${this.CONTRATO_PRINCIPAL.id}/download`;
      window.open(url, "_system");
    },
    //RECOMPRA

    tipoRecompraSelecionado(value) {
      this.setStatusRecompra(value);
    },

    iniciarPropostaRecompra() {
      if (this.CONTRATO_PRINCIPAL.tipoClasse === "NAO_RATEIO") {
        this.setStatusRecompra("PRECO_UNICO");
      }
      this.paginaRecompra = true;
    },

    setStatusRecompra(status) {
      this.loadingPage = true;
      DemonstrativoService.setStatusRecompra(this.CONTRATO_PRINCIPAL.id, {
        tipo: status,
      })
        .then(() => {
          this.loadingPage = false;
          document.location.reload();
        })
        .catch(() => {
          this.loadingPage = false;
        });
    },

    optionProposedReduction(option) {
      this.isAmountProposal = option;
      RepurchaseStatusService.updateStatus({
        idProcess: parseInt(this.processInstanceId),
        status: option,
      }).then((response) => {});
    },

    order() {},
    verifyFactorAtt() {
      let temp = this.data.billings.filter(
        (g) => g.contractParent === 0 && g.factorAtt < 100
      );
      if (temp.length > 0) {
        this.messageValorShortTherm = false;
      }
    },
    saveLogRecompra(value) {
      this.approveNext(value.infos, value.typeRecompra);
    },
    reflash() {
      document.location.reload();
    },
    closeGetDataUser() {
      this.modalGetDataUserShow = false;
    },
    closeModalAskShortTerm() {
      this.modalAskShortTermShow = false;
      document.location.reload();
    },
    approveDemo() {
      this.modalGetDataUserShow = true;
    },
    getProposalShortTerm() {
      ShortTermService.getShortTerm({
        //processId: this.processInstanceId,
        codProposta: this.shortTermCode,
        checkDueDate: false,
      })
        .then((response) => {
          this.proposalShortTerm = response;
        })
        .catch((message) => {
          this.mxShowModalAlert({
            title: "Atenção",
            message: message,
            type: "confirm",
          });
        });
    },
    formatUniqueUser(value) {
      var uniqueArray = value.filter((item, index) => {
        const _item = JSON.stringify(item);
        return (
          index ===
          value.findIndex((obj) => {
            return JSON.stringify(obj) === _item;
          })
        );
      });
      return uniqueArray;
    },
    getUserResult(value) {
      if (value.type === "APPROVAL") {
        this.approveNext(value.infos);
      }
    },
    approveNext(infosUser, typeRecompra) {
      this.spinner = true;
      HotsiteService.getBilling({
        processId: this.processInstanceId,
      })
        .then((response) => {
          if (response.valid == false) {
            this.valid = response.valid;
            this.data.valid = response.valid;
            this.data.approvalStatus = response.approvalStatus;
            this.data.messages = response.messages;
            var mensagem = "";
            mensagem = response.messages[0];
            this.spinner = false;
            this.modalGetDataUserShow = false;
            this.mxShowModalAlert({
              title: "Atenção",
              message: mensagem,
              type: "confirm",
            }).then(() => {
              this.$forceUpdate();
              document.location.reload();
            });
          } else {
            this.requestAproval.name = infosUser.name;
            this.requestAproval.email = infosUser.email;
            if (typeRecompra) {
              this.requestAproval.type = "RC";
            } else {
              this.requestAproval.type = "DE";
            }
            HotsiteService.logAproval(this.requestAproval)
              .then((response) => {
                this.spinner = false;
                if (!typeRecompra) {
                  this.finalizeApproval();
                } else {
                  if (typeRecompra == "PRECO_UNICO") {
                    this.$refs.unico.post();
                  }
                  if (typeRecompra == "PRECOS_DIFERENTES") {
                    this.$refs.diferentes.post();
                  }
                }
              })
              .catch((e) => {
                this.spinner = false;
              });
          }
          this.modalLoad = false;
        })
        .catch((e) => {
          this.spinner = false;
        });
    },
    finalizeApproval() {
      this.modalGetDataUserShow = false;
      this.requestConfirm.status = "APROVADO";
      HotsiteService.updateStatus(this.requestConfirm).then((response) => {
        if (
          this.data.takeInformation.aboveTake == true &&
          this.data.takeInformation.shortTermProposal == true &&
          !this.data.takeInformation.canCreateProposal
        ) {
          this.completeTask("SHORT-PROPOSAL");
          this.valid = false;
        } else {
          this.valid = false;
          this.completeTask("SIMPLES");
        }
      });
    },
    finishAskShortterm(value) {
      this.modalAskShortTermShow = false;
      if (value == "SHORT-PROPOSAL") {
        this.startProposalShotTerm();
      } else {
        document.location.reload();
      }
    },
    finalizarRecompra(value) {
      this.spinner = true;
      this.requestConfirm.status = "APROVADO";
      HotsiteService.updateStatus(this.requestConfirm)
        .then((response) => {
          this.spinner = false;
          this.completeTask("RECOMPRA", value);
        })
        .catch((E) => {
          this.spinner = false;
        });
    },
    completeTask(value, typeRecompra) {
      this.spinner = true;
      const requestComplete = {
        processInstanceId: this.processInstanceId,
        taskId: this.taskId,
        variables: {
          _controle: "RETORNO",
        },
      };
      HotsiteService.completeTask(requestComplete)
        .then((response) => {
          this.valid = false;
          this.approval = false;
          if (value == "SHORT-PROPOSAL") {
            this.spinner = false;
            this.modalAskShortTermShow = true;
          } else if (value == "SIMPLES") {
            this.spinner = false;
            this.mxShowModalAlert({
              title: "Atenção",
              message: `<b>Confirmação Enviada</b> <br><br> Sua Nota Fiscal será processada e enviada em breve. `,
              type: "confirm",
            }).then(() => {
              setTimeout(() => {
                document.location.reload();
              }, 200);
            });
          } else if (value == "RECOMPRA") {
            this.spinner = false;
            if (typeRecompra == "PRECO_UNICO") {
              this.$refs.unico.modalaproved();
            }
            if (typeRecompra == "PRECOS_DIFERENTES") {
              this.$refs.diferentes.modalaproved();
            }
          } else {
            this.spinner = false;
            document.location.reload();
          }
        })
        .catch((e) => {
          this.spinner = false;
        });
    },
    startProposalShotTerm() {
      this.spinner = true;
      ShortTermService.postShortTerm(
        this.processInstanceId,
        this.requestListShortTerm
      )
        .then((response) => {
          this.spinner = false;
          this.modalShortTermSentShow = true;
          document.location.reload();
        })
        .catch((e) => {
          this.spinner = false;
          this.mxShowModalAlert({
            title: "Atenção",
            message:
              "A proposta de Curto Prazo não pode ser gerada, favor entrar em contato com a Matrix!",
            type: "confirm",
          }).then(() => {
            setTimeout(() => {
              document.location.reload();
            }, 200);
          });
        });
    },
    closeShortTermSent() {
      document.location.reload();
    },
    ///////////////////////////////////////////////////////////
    isInvalid() {
      this.messages = this.data.messages;
    },
    reflash() {
      document.location.reload();
    },
    startApproval(type) {
      this.type = type;
      this.option = "log";
      this.requestConfirm.status = type;
      $("#modal").modal("show");
    },
    avancar() {
      this.modalLoad = true;
      HotsiteService.getBilling({ processId: this.processInstanceId }).then(
        (response) => {
          if (response.valid == false) {
            $("#modal").modal("hide");
            this.valid = response.valid;
            this.data.valid = response.valid;
            this.data.approvalStatus = response.approvalStatus;
            this.data.messages = response.messages;
            this.actSaveDataPdf(this.data);
            var mensagem = "";
            mensagem = response.messages[0];
            this.mxShowModalAlert({
              title: "Atenção",
              message: mensagem,
              type: "confirm",
            }).then(() => {
              this.$forceUpdate();
            });
          } else {
            this.$v.$touch();
            if (!this.$v.$invalid) {
              this.requestAproval.type = "DE";
              HotsiteService.logAproval(this.requestAproval).then(
                (response) => {
                  if (this.type == "APROVADO") {
                    $("#modal").modal("hide");
                    this.confirm();
                  } else {
                    this.option = "justificativa";
                  }
                }
              );
            }
          }
          this.modalLoad = false;
        }
      );
    },
    avancarJustificativa() {
      if (this.requestConfirm.justify == "") {
        this.msgJusErro = true;
      } else {
        $("#modal").modal("hide");
        this.confirm();
      }
    },
    confirm() {
      this.loading = HotsiteService.updateStatus(this.requestConfirm).then(
        (response) => {
          if (this.type == "APROVADO") {
            this.mxShowModalAlert({
              title: "CONFIRMAÇÃO ENVIADA!",
              message: "Sua Nota Fiscal será processada e enviada em breve.",
              type: "confirm",
            }).then(() => {
              this.completeTask();
              this.valid = false;
            });
          } else {
            this.mxShowModalAlert({
              title: "CONTESTAÇÃO ENVIADA!",
              message:
                "Estamos avaliando sua solicitação e retornaremos em breve.",
              type: "confirm",
            }).then(() => {
              this.completeTask();
              this.valid = false;
            });
          }
        }
      );
    },
    lowerCase(e) {
      this.requestAproval.email != ""
        ? (this.requestAproval.email = this.requestAproval.email.toLowerCase())
        : null;
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.messages = this.data.messages;
        !this.data.valid ? this.isInvalid() : (this.valid = true);
        this.taskId = this.data.taskId;
      },
    },
    requestConfirm: {
      deep: true,
      handler() {
        if (this.requestConfirm.justify != "") {
          this.msgJusErro = false;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-approvar {
  padding: 50px;
}
.__header {
  height: 60px;
  width: 100%;
  display: flex;
  background: #2a2a2a;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #ffffff;
  img {
    width: 45px;
  }
}
.header__box-logo {
  padding: 0 15px 0 15px;
}
.input-modal {
  background: #e8e8e8;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  padding-left: 10px;
  margin-top: 5px;
  //border: none;
}
.label-modal {
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #707683;
  margin-top: 20px;
}
.text-modal {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #707683;
  //@include mobile {
  //  font-size: 14px;
  //}
}
.modal-header {
  border: none;
  h5 {
    text-align: center !important;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #505050;
  }
}
.btn-standard {
  //display: flex;
  justify-content: center;
  align-items: center;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px;
  min-width: 100px;
  margin: 0 5px 0 5px;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 5px;
  min-width: 180px;
}
.btn-gray {
  background: #929292;
}
.btn-orange {
  background: #f86511;
}
.modal-content {
  width: 95vw;
}
.modal-dialog {
  width: 100vw !important;
  margin: none !important;
}
#modal {
  text-align: center !important;
}
.modal-body {
  font-style: normal;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #707683;
  padding: 20px 35px 0px 35px;
  text-align: center;
  min-height: 240px;
  //@include mobile {
  // margin: auto;
  //}
}
.footer {
  padding: 15px;
  background: #ebebeb;
  border-radius: 0px 0px 5px 5px;
  text-align: center !important;
  width: 100%;
  background: #ebebeb !important;
}
.container__time-line {
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -20px;
}
.container-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px 0px 0px;
}
.description {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  margin: 50px 0 15px 0;
  letter-spacing: 0.05em;
  color: #707683;
  //@include mobile {
  // font-size: 14px;
  // text-align: center;
  //}
}
.box {
  margin: auto;
  width: 95vw;
  //background: #eeeeee;
  max-width: 1120px;
  border: none;
}
.box-buttons-approval {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 140px;
  margin: 0px 0 30px 0;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}
._btn {
  color: White;
  //font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  width: 250px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  //@include mobile {
  //  width: 130px;
  //}
}
._btn--green {
  background: #12b75d;
  margin-right: 15px;
  &:hover {
    background: rgba($color: #12b75d, $alpha: 0.8);
  }
}
._btn--red {
  background: #f24242;
  margin-left: 15px;
  &:hover {
    background: rgba($color: #f24242, $alpha: 0.8);
  }
}
.instruction {
  margin-top: 100px;
  margin-bottom: 30px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #707683;
  text-align: center;
}
.msgs {
  margin-top: 10px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #707683;
}
.form-group--error {
  border: 1px solid red;
}
.textarea {
  background: #f5f6f8;
  border-radius: 5px;
  border: none;
  width: 100%;
  min-height: 150px;
}
.alert {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-top: 50px;
  text-align: center;
  letter-spacing: 0.1em;
  padding: 12px 0 12px 0;
  //@include mobile {
  //  font-size: 14px;
  //}
}
.msg-justificativa {
  color: red;
  font-size: 12px;
  height: 10px;
  margin-bottom: 10px;
}
.loading {
  width: 100%;
  height: 117.6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: white;
}
.text-loading {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #f86511;
  margin-bottom: 20px;
}
.screen {
  min-width: 100vw;
  max-width: 100vw;
}
.box-pdf {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-pdf {
  background: #f86511;
  color: #fff;
  border-radius: 4px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px 70px 5px 70px;
  margin-bottom: 60px;
  cursor: pointer;
}
.icon-pdf {
  margin: -5px 15px 0 0px;
}
.rounded-container {
  border-radius: 5px;
}
.select-option {
  padding: 10px;
  font-size: 16px;
  color: #fff;
  margin-top: 60px;
  font-weight: bold;
  background: #2a2a2a;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}
.page {
  background: #e5e5e5;
}
.page-body {
  height: 100vh;
}
.modal-load {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
}
._alert-contestado {
  background: #fce7e7;
  border: 2px solid #ff7575;
  color: #ff7575;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}
._alert-aprovado {
  background: #fff;
  border: 1px solid #2ed47a;
  color: #2ed47a;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}
._alert-pendente {
  background: #f1f1f1;
  border: 2px solid #929292;
  color: #525252;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}
.box-load {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.box-alert-logo {
  display: flex;
  align-items: center;
}
.title-demosntrativo {
  font-weight: 500;
  font-size: 26px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #505050;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background: #fff;
  padding: 2px;
  //@include hd {
  //  font-size: 27px;
  //}
  //@include mobile {
  //  font-size: 20px;
  // }
}
.name-company {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #505050;
  //@include hd {
  font-size: 18px;
  //}
  //@include mobile {
  // font-size: 16px;
  //  text-align: center;
  //}
}
.infotAdtional {
  margin: 60px 0 30px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  width: 100%;
  text-align: center;
  letter-spacing: 0.05em;
  background: #ffffff;
  border-radius: 4px;
  color: #505050;
  padding: 13px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}
.box-alert-short-term-proposal {
  display: flex;
  padding: 20px;
  background: #fff;
  margin-top: 5px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #192a3e;
  border-radius: 5px;
}
.box-alert-text {
  padding-left: 15px;
}
.box-button-msg {
  text-align: center;
  margin-left: -30px;
  padding: 5px 0 15px 0;
}
.btn {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  left: calc(50% - 174px / 2);
  top: 573px;
  background: #f86511;
  border-radius: 4px;
  color: #fff;
  padding: 8px 20px 8px 30px;
  &:hover {
    background: rgba($color: #f86511, $alpha: 0.8);
    color: #fff;
  }
}
.logo-matrix {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  height: 80px;
}
.loaderPage {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99999999;
}
</style>
