import Services from "./Services";
class Usuario extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  /**
   * @summary Gets an Usuario entity
   * @param {Object} request
   * @endpoint /api/usuarios
   */
  getUser(request) {
    this._url = `/${request.id}`;
    return this.get(request);
  }

  /**
   * @summary Gets the Usuario info
   * @param {Object} request
   * @endpoint /api/usuarios/info
   */
  getUserInfo(request) {
    this._url = "/info";
    return this.get(request, true);
  }
}

export default new Usuario("/usuarios");
