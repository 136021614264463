import { VOLUME } from "@/filters/formatters";

export class TableHorasFaltantesModel {
  static getTableFields() {
    return new Array(
      {
        name: "contrato",
        title: "Contrato",
      },
      {
        name: "apelido",
        title: "Apelido",
      },
      {
        name: "pontoMedicao",
        title: "Pto. de Medição",
      },
      {
        name: "origem",
        title: "Origem",
      },
      {
        name: "horasFaltantes",
        title: "H. Faltantes",
      },
      {
        name: "totalScde",
        title: "Total SCDE (MWh)",
        formatter(value) {
          return VOLUME(value);
        },
      },
      {
        name: "totalProjetadoMwh",
        title: "Total Proj. (MWh)",
      },
      {
        name: "diferenca",
        title: "Diferença (MWh)",
      }
    );
  }
}
