import AguardandoAprovacaoRecompraManual from "../pages/status-pages-template/aguardando-aprovacao-recompra-manual/AguardandoAprovacaoRecompraManual.vue";
import AguardandoAprovacaoSolicitadoLiquido from "../pages/status-pages-template/aguardando-aprovacao-solicitado-liquido/AguardandoAprovacaoSolicitadoLiquido.vue";
import AguardandoAprovacaoSpreadRecompra from "../pages/status-pages-template/aguardando-aprovacao-spread-recompra/AguardandoAprovacaoSpreadRecompra";
import AnaliseClienteHotsite from "../pages/status-pages-template/analise-cliente-hotsite/AnaliseClienteHotsite.vue";
import AnaliseClienteManual from "../pages/status-pages-template/analise-cliente-manual/AnaliseClienteManual.vue";
import AnalisePosVenda from "../pages/status-pages-template/analise-pos-venda/AnalisePosVenda.vue";
import AprovadoClienteHotSite from "../pages/status-pages-template/aprovado-cliente-hot-site/AprovadoClienteHotSite.vue";
import AprovadoPosVenda from "../pages/status-pages-template/aprovado-pos-venda/AprovadoPosVenda.vue";
import CadastroPendente from "../pages/status-pages-template/cadastro-pendente/CadastroPendente.vue";
import ContestadoClienteHotSite from "../pages/status-pages-template/contestado-cliente-hot-site/ContestadoClienteHotSite.vue";
import NaoImportado from "../pages/status-pages-template/nao-importado/NaoImportado.vue";

const routes = [
    {
        path: "/faturamento/nao-importado/:id?",
        name: "Não Importado",
        component: NaoImportado,
    },
    {
        path: "/faturamento/analise-cliente-hotsite/:id?",
        name: "Em Análise pelo Cliente - Hotsite",
        component: AnaliseClienteHotsite,
    },
    {
        path: "/faturamento/analise-cliente-manual/:id?",
        name: "Em Análise pelo Cliente - Manual",
        component: AnaliseClienteManual,
    },
    {
        path: "/faturamento/cadastro-pendente/:id?",
        name: "Cadastro pendente",
        component: CadastroPendente,
    },
    {
        path: "/faturamento/costestado-cliente-hot-site/:id?",
        name: "Contestado Cliente Hot Site",
        component: ContestadoClienteHotSite,
    },
    {
        path: "/faturamento/aprovado-cliente-hot-site/:id?",
        name: "Aprovado Cliente Hot Site",
        component: AprovadoClienteHotSite,
    },
    {
        path: "/faturamento/analise-pos-venda/:id?",
        name: "Analise Pos Venda",
        component: AnalisePosVenda,
    },
    {
        path: "/faturamento/aprovado-pos-venda/:id?",
        name: "Aprovado Pos Venda",
        component: AprovadoPosVenda,
    },
    {
        path: "/faturamento/aguardando-aprovacao-recompra-manual/:id?",
        name: "Aguardando Aprovacao Recompra Manual",
        component: AguardandoAprovacaoRecompraManual,
    },
    {
        path: "/faturamento/aguardando-aprovacao-solicitado-liquido/:id?",
        name: "Aguardando Aprovacao do solicitado líquido",
        component: AguardandoAprovacaoSolicitadoLiquido,
    },
    {
        path: "/faturamento/aguardando-aprovacao-spread-recompra/:id?",
        name: "Aguardando Aprovacao Spread recompra",
        component: AguardandoAprovacaoSpreadRecompra,
    },
];

export default routes;
