export default class PropostaCurtoPrazoModel {
  constructor(
    {
      id,
      dataCriacao,
      valorMaximo,
      valorMinimo,
      horasAdicionais,
      dataLimite,
      condicaoPagamento,
      multaJuros,
    } = new Object()
  ) {
    this._id = id;
    this._dataCriacao = dataCriacao;
    this._valorMaximo = valorMaximo;
    this._valorMinimo = valorMinimo;
    this._horasAdicionais = horasAdicionais;
    this._dataLimite = dataLimite;
    this._condicaoPagamento = condicaoPagamento;
    this._multaJuros = multaJuros;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id = null) {
    this._id = id;
  }

  get dataCriacao() {
    return this._dataCriacao ?? null;
  }

  set dataCriacao(dataCriacao = null) {
    this._dataCriacao = dataCriacao;
  }

  get valorMaximo() {
    return this._valorMaximo ?? 0;
  }

  set valorMaximo(valorMaximo = null) {
    this._valorMaximo = valorMaximo;
  }

  get valorMinimo() {
    return this._valorMinimo ?? 0;
  }

  set valorMinimo(valorMinimo = null) {
    this._valorMinimo = valorMinimo;
  }

  get horasAdicionais() {
    return this._horasAdicionais ?? null;
  }

  set horasAdicionais(horasAdicionais = null) {
    this._horasAdicionais = horasAdicionais;
  }

  get dataLimite() {
    return this._dataLimite ?? null;
  }

  set dataLimite(dataLimite) {
    this._dataLimite = dataLimite;
  }

  get condicaoPagamento() {
    return this._condicaoPagamento ?? null;
  }

  set condicaoPagamento(condicaoPagamento) {
    this._condicaoPagamento = condicaoPagamento;
  }

  get multaJuros() {
    return this._multaJuros ?? null;
  }

  set multaJuros(multaJuros) {
    this._multaJuros = multaJuros;
  }

  getData() {
    const {
      id,
      dataCriacao,
      valorMaximo,
      valorMinimo,
      horasAdicionais,
      dataLimite,
      condicaoPagamento,
      multaJuros,
    } = this;
    return {
      id,
      dataCriacao,
      valorMaximo,
      valorMinimo,
      horasAdicionais,
      dataLimite,
      condicaoPagamento,
      multaJuros,
    };
  }
}
