<template>
  <div class="sl-coriginal-ontainer">
    <div class="sl-title">Solicitado Líquido Original (MWh)</div>
    <p>{{ value | volume }}</p>
  </div>
</template>
<script>
export default {
  props: {
    value: Number,
  },
};
</script>

<style scoped>
.sl-original-container {
  width: 271px;
}

.sl-title {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 16px;
}

p {
  font-size: 16px;
  font-weight: 400;
}
</style>
