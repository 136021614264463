import PontosMedicaoModel from "@/models/PontosMedicaoModel";
import Services from "./Services";

class PontosMedicaoService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  getPontosMedicao(request) {
    this._url = `/pontos`;
    return new Promise(async (resolve, reject) => {
      try {
        const pontosResponse = await this.get(request, true);
        const pagination = pontosResponse.data;
        if (pontosResponse.data.content.length == 0) {
          return resolve({ data: new Array(), pagination, empty: true });
        }
        const data = pontosResponse.data.content.map((contrato) => {
          return new PontosMedicaoModel({
            ...contrato,
            razaoSocial: contrato.empresa.razaoSocial,
            apelido: contrato.empresa.apelido,
            cnpj: contrato.empresa.cnpj,
            contrato: contrato,
            referencia: contrato.codigoContratoReferencia,
            pontos: contrato.empresa.pontos,
            percentualFatorAtendimento:
              contrato.percentualFatorAtendimento ?? 0,
            percentualPerda: contrato.percentualPerda ?? 0,
            status: contrato.statusAtual,
          });
        });
        resolve({ data, pagination, empty: false });
      } catch (error) {
        reject(error);
      }
    });
  }

  salvarPontoMedicao(request) {
    const { idPonto, body } = request;
    this._url = `/${idPonto}/pontos`;
    return this.patch(body);
  }
}

export default new PontosMedicaoService("/contratos");
