import Services from "./Services";

class UploadService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  uploadManual(request) {
    const { idContrato, arquivos } = request;

    this._url = `/upload-manual/${idContrato}`;

    return this.post(arquivos, false, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  loadManual(request) {
    this._url = `/upload-manual/load`;
    return this.post(request, false);
  }

  getPontosValidos(request) {
    this._url = `/upload-lote/validar-arquivos`;
    return this.post(request, false, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  uploadEmLote(request) {
    this._url = `/upload-lote`;
    return this.post(request, false, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  downloadArquivo(request) {
    this._url = `/upload-manual/${request.idHistoricoAquivo}`;

    return this.get(request);
  }

  arquivosImportados(request) {
    this._url = `/upload-lote/resultado`;
    return this.get(request);
  }

  getContratosNaoImportados(request) {
    this._url = `/upload-lote`;
    return this.get(request, true);
  }

  deletarArquivo(request) {
    this._url = `/upload-manual/${request.idHistoricoAquivo}`;

    return this.delete(request);
  }

  validarArquivosUploadManual(request) {
    this._url = `/upload-manual/${request.contratoId}/validar-arquivos`;
    return this.post(request.files, false, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}

export default new UploadService("/arquivos");
