import { EMPTY } from "@/filters/formatters";
import FaturamentoContratosResumoModel from "@/models/faturamento/FaturamentoContratoResumoModel";

export default class TableFaturamentoContratosResumoModel extends FaturamentoContratosResumoModel {
  getTableFields() {
    return [
      {
        name: "empresa",
        title: "Apelido",
        width: "300px",
        formatter(value) {
          if (value) {
            return value.apelido;
          }
          return "--";
        },
      },
      {
        name: "codigo",
        title: "Contrato",
        width: "100px",
        formatter(value) {
          return EMPTY(value);
        },
      },

      {
        name: "rateio",
        title: "Rateio",
        width: "100px",
        formatter(value) {
          return value ? "Sim" : "Não";
        },
      },
      {
        name: "responsavel",
        title: "Responsável",
        formatter(value) {
          if (value) {
            return value;
          }
          return "--";
        },
      },
      {
        name: "take",
        title: "Takes",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "parametrizacao",
        title: "Envio",
        formatter(value) {
          if (value) {
            return value.envioAutomatico ? "Automático" : "Manual";
          }
          return "--";
        },
      },
      {
        name: "categoria",
        title: "Categoria",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "statusAtual",
        title: "Status",
        formatter(value) {
          if (value?.descricao) {
            return value.descricao;
          }
          return "--";
        },
      },
      {
        name: "ultimoDetalhe",
        title: "Detalhes",
        width: "200px",
        formatter(value) {
          if (value) {
            return value.descricao;
          }
          return "--";
        },
      },
    ];
  }
}
