export default class RotinaImportacaoModel {
  constructor(
    { id, ativo, horaFim, horaInicio, frequenciaEmHoras } = new Object()
  ) {
    this._id = id;
    this._ativo = ativo;
    this._horaFim = horaFim;
    this._horaInicio = horaInicio;
    this._frequenciaEmHoras = frequenciaEmHoras;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id = null) {
    this._id = id;
  }

  get ativo() {
    return this._ativo ?? null;
  }

  set ativo(ativo = null) {
    this._ativo = ativo;
  }

  get horaFim() {
    return this._horaFim ?? null;
  }

  set horaFim(horaFim = null) {
    this._horaFim = horaFim;
  }

  get horaInicio() {
    return this._horaInicio ?? null;
  }

  set horaInicio(horaInicio = null) {
    this._horaInicio = horaInicio;
  }

  get frequenciaEmHoras() {
    return this._frequenciaEmHoras ?? null;
  }

  set frequenciaEmHoras(frequenciaEmHoras = null) {
    this._frequenciaEmHoras = frequenciaEmHoras;
  }

  getData() {
    const { id, ativo, horaFim, horaInicio, frequenciaEmHoras } = this;
    return {
      id,
      ativo,
      horaFim,
      horaInicio,
      frequenciaEmHoras,
    };
  }
}
