<template>
  <div class="loading">
    <div class="__header">
      <div class="header__box-logo">
        <img src="@/assets/logo/orange.svg" />
      </div>
      <div class="text__header">Portal Matrix</div>
    </div>
    <div class="text-loading">{{ MESSAGE }}</div>
    <div />
  </div>
</template>
<script>
export default {
  props: { error: Object },
  computed: {
    MESSAGE: function () {
      return this.error && this.error.status == 422 && this.error.data
        ? this.error.data.message
        : "Sem informações ";
    },
  },
};
</script>

<style lang="scss">
.loading {
  width: 100%;
  height: 117.6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: white;
}

.text-loading {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #f86511;
  margin-bottom: 20px;
}
.__header {
  height: 60px;
  width: 100%;
  display: flex;
  background: #505050;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #ffffff;

  img {
    width: 45px;
  }
}

.header__box-logo {
  padding: 0 15px 0 15px;
}
</style>
