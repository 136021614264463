import Services from "./Services";

class RecompraService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  getBuscarAnaliseRecompraPrecoUnico(request) {
    this._url = `${request.idContrato}/recompras/buscar/analise`;

    return this.get(request);
  }

  getBuscarAnalisePrecosDiferentes(request) {
    this._url = `${request.idContrato}/recompras/buscar/precos-diferentes/analise`;
    return this.get(request);
  }

  getAnaliseRecompraPrecosDiferentes(request) {
    this._url = `${request.idContrato}/recompras/precos-diferentes/analise`;
    return this.get(request);
  }

  getRecompraPrecoUnicoRateio(request) {
    this._url = `${request.idContrato}/recompras/buscar/rateio`;

    return this.get(request);
  }

  getAnaliseRecompraPrecoUnico(request) {
    this._url = `${request.idContrato}/recompras/preco-unico/analise`;
    return this.get(request);
  }

  getRecompraCondicaoPreco(request) {
    this._url = `${request.idContrato}/recompras/preco-unico/condicao-de-preco`;
    return this.get(request);
  }

  efetuarRecompraPrecoUnico(idContrato, request) {
    this._url = `${idContrato}/recompras/preco-unico/efetuar`;
    return this.post(request);
  }

  efetuarRecompraPrecosDiferentes(idContrato, request) {
    this._url = `${idContrato}/recompras/precos-diferentes/efetuar`;
    return this.post(request);
  }

  getAprovacaoRecompra(request) {
    this._url = `${request.idContrato}/recompras/aprovacao`;

    return this.get(request);
  }

  aprovarRecompra(request) {
    const { idContrato, body } = request;

    this._url = `${idContrato}/recompras/aprovacao`;

    return this.post(body);
  }

  reprovarRecompra(request) {
    this._url = `${request.idContrato}/recompras/aprovacao`;

    return this.delete(request);
  }
}

export default new RecompraService("/contratos");
