export default [
  "ACESSAR_BILLING",
  "ASSOCIAR_PONTO_AO_CONTRATO_DESABILITADO",
  "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR",
  "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR_EDITAR",
  "CONFIGURAR_PROINFA_DESABILITADO",
  "CONFIGURAR_PROINFA_VISUALIZAR",
  "CONFIGURAR_PROINFA_VISUALIZAR_EDITAR",
  "VISUALIZAR_FATURAMENTO",
  "REALIZAR_FATURAMENTO",
  "IMPORTACAO_LOTE_HABILITADO",
  "IMPORTACAO_LOTE_DESABILITADO",
  "CONTRATOS_NAO_IMPORTADOS_HABILITADO",
  "CONTRATOS_NAO_IMPORTADOS_DESABILITADO",
  "FECHAR_FATURAMENTO_HABILITADO",
  "FECHAR_FATURAMENTO_DESABILITADO",
  "ALCADA_APROVACAO_LIQUIDO_DESABILITADO",
  "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N1",
  "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N2",
  "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N3",
  "ALCADA_APROVACAO_RECOMPRA_DESABILITADO",
  "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N1",
  "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N2",
  "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N3",
  "EXPORTADOR_MONTANTES_DESABILITADO",
  "EXPORTADOR_MONTANTES_VISUALIZAR",
  "EXPORTADOR_MONTANTES_VISUALIZAR_EXPORTAR",
  "ACOMPANHAR_CURTO_PRAZO_DESABILITADO",
  "ACOMPANHAR_CURTO_PRAZO_VISUALIZAR",
  "ACOMPANHAR_CURTO_PRAZO_VISUALIZAR_SINALIZAR",
  "ASSOCIAR_POS_VENDA_DESABILITADO",
  "ASSOCIAR_POS_VENDA_VISUALIZAR",
  "ASSOCIAR_POS_VENDA_VISUALIZAR_ASSOCIAR",
  "AJUSTES_PLD_SPREAD_DESABILITADO",
  "AJUSTES_PLD_SPREAD_VISUALIZAR",
  "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR",
  "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR",
  "ALCADAS_APROVACAO_DESABILITADO",
  "ALCADAS_APROVACAO_VISUALIZAR",
  "ALCADAS_APROVACAO_VISUALIZAR_EDITAR",
  "CURTO_PRAZO_DESABILITADO",
  "CURTO_PRAZO_VISUALIZAR",
  "CURTO_PRAZO_VISUALIZAR_EDITAR",
  "ROTINA_IMPORTACAO_DESABILITADO",
  "ROTINA_IMPORTACAO_VISUALIZAR",
  "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR",
  "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR_EDITAR",
  "BUSCAR_APURACOES_CCEE",
  "DASHBOARD_HABILITADO",
];
