<template>
  <div class="page-nao-importado">
    <page-painel>
      <template #header>
        Faturamento de : <b>Apelido da Empresa - Razão social da empresa</b>
      </template>
      <template #body>
        <div class="page-nao-importado__body">
          <label>Competência: 01/2022</label>

          <horas-faltantes />

          <contrato
            v-for="contract in 5"
            :key="contract"
            class="contract"
            :type="contract == 1 ? 'apportionment' : 'single'"
          >
            <template #components>
              <div class="wrapper-componentes">
                <div>
                  <upload-manual />
                </div>
                <div>
                  <efetuar-recompra :rateio="contract === 1" />
                </div>
              </div>
            </template>
          </contrato>
          <!-- <button
            @click="sendConcluirCalculo()"
            class="btn-primary-full-filled"
          >
            Concluir
          </button> -->
          <historico-acoes />
        </div>
      </template>
    </page-painel>
  </div>
</template>
<script>
import Contrato from "@/components/contratos/Contrato.vue";
import ContratoUploadManual from "@/components/contratos/ContratoUploadManual.vue";
import HistoricoAcoes from "@/components/historico-acoes/HistoricoAcoes.vue";
import HorasFaltantes from "@/components/horas-faltantes/HorasFaltantes.vue";
import PagePainel from "@/components/panels/PagePanel.vue";
import EfetuarRecompra from "@/components/recompra/EfetuarRecompra.vue";
import AcaoBotaoContratoService from "@/services/AcaoBotaoContratoService";

export default {
  data() {
    return {
      idContrato: null,
    };
  },
  methods: {
    sendConcluirCalculo() {
      this.idContrato = this.$router.history.current.params.id;
      AcaoBotaoContratoService.concluirCalculo(this.idContrato)
        .then(({ data }) => {})
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
  },
  components: {
    Contrato,
    PagePainel,
    HorasFaltantes,
    HistoricoAcoes,
    EfetuarRecompra,
    ContratoUploadManual,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
.page-nao-importado {
  padding-top: 24px;
  &__body {
    padding: 2px 40px;
  }

  .contract {
    margin: 20px 0 20px 0;
  }
  .wrapper-componentes {
    display: flex;
    justify-content: space-between;
  }
}
</style>
