<template>
  <div>
    <label :for="reference">
      {{ label }}
      <span class="required-markup">*</span>
    </label>
    <select
      v-model="content"
      :id="reference"
      :ref="reference"
      class="form-select"
      :disabled="disabled"
    >
      <option v-for="(hour, i) in hoursList" :value="hour" :key="i">
        {{ hour }}
      </option>
    </select>
    <!-- <field-hour-selector
      :valueProp="content"
      @input="(value) => (content = value)"
      :id="reference"
      :from="1"
      :to="22"
      :disabled="disabled"
      :customStyle="{ width: '235px' }"
    /> -->
  </div>
</template>

<script>
import FieldHourSelector from "@/pages/parametrizacao/components/fields/FieldHourSelector.vue";

export default {
  data() {
    return {
      content: "",
    };
  },
  props: {
    modelProp: {
      type: Object,
    },
    reference: {
      type: String,
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modelProp: {
      immediate: true,
      deep: true,
      handler() {
        this.content = this.modelProp[this.reference];
      },
    },
    content() {
      this.modelProp[this.reference] = this.content;
    },
  },
  computed: {
    hoursList() {
      const hours = [];
      for (let i = 1; i <= 22; i++) {
        hours.push(`${String(i).padStart(2, "0")}:00`);
      }
      return hours;
    },
  },
  components: {
    FieldHourSelector,
  },
};
</script>

<style lang="scss" scoped>
select {
  outline: 0;
  background-size: 15px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: 95% center;
  border-radius: 10px;
  border: 1px solid #b4b7ba;
  padding: 6px 12px;
  width: 235px;
}
</style>
