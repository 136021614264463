<template>
  <div>
    <table class="table table-faturamento-contrato-wbc">
      <thead>
        <span class="labelTitle">Volume Distribuído</span>
        <tr>
          <td class="col col-4"><b>CNPJ</b></td>
          <td class="col col-4"><b>Razão Social</b></td>
          <td class="col col-4"><b>Vol. Distribuído (MWh)</b></td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(
            empresa, empresaIndex
          ) in contratoSelecionado.volumesDistribuidos"
          :key="`empresa_${empresaIndex}`"
        >
          <td>
            {{ formatterCNPJ(empresa.cnpj) | EMPTY }}
          </td>
          <td>
            {{ empresa.razaoSocial | EMPTY }}
          </td>
          <td>
            {{ empresa.volumeDistribuido | volume }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { EMPTY, CNPJ } from "@/filters/formatters";

export default {
  props: {
    contratoSelecionado: Object,
  },
  methods: {
    formatterCNPJ(value) {
      return CNPJ(value);
    },
  },
  computed: {
    EMPRESA_RAZAO_SOCIAL() {
      return this.empresa?.razaoSocial;
    },
  },
  filters: {
    EMPTY,
    CNPJ,
  },
};
</script>
<style lang="scss" scoped>
.table-faturamento-contrato-wbc {
  width: 90%;
  margin: 44px 0px 49px 36px;
}

tr {
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee !important;
}

.labelTitle {
  margin-left: 1%;
  font-size: 16px;
  font-weight: 600;
}
</style>