<template>
  <div class="tooltip_container">
    <slot name="header"></slot>
    <div
      :style="[
        {
          width,
          transform: `translateX(${alignHorizontal})`,
          top: `${alignVertical}`,
        },
      ]"
      :class="[
        { 'tooltiptext arrow-up': arrowAlign === 'up' },
        { 'tooltiptext arrow-down': arrowAlign === 'down' },
        { 'tooltiptext arrow-left': arrowAlign === 'left' },
        { 'tooltiptext arrow-right': arrowAlign === 'right' },
      ]"
    >
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alignHorizontal: {
      type: String,
      required: false,
      default: "0px",
    },
    alignVertical: {
      type: String,
      required: false,
      default: "",
    },
    arrowAlign: {
      type: String,
      required: false,
      default: "up",
    },
    width: {
      type: String,
      required: false,
      default: "fit-content",
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip_container {
  position: relative;
}

.tooltip_container .tooltiptext {
  
  z-index: 999 !important;
  
  padding: 10px;
  display: flex;
  font-size: 14px;
  font-weight: 300;
  color: #2a2a2a;
  border-radius: 6px;
  position: absolute;
  visibility: hidden;
  text-align: center;
  align-items: center;
  background: #bdbdbd;
  justify-content: center;
}

.tooltip_container:hover .tooltiptext {
  visibility: visible;
}

// .tooltiptext::after {
//   content: " ";
//   position: absolute;

//   border-width: 5px;
//   border-style: solid;

//   margin-left: -5px;
// }

.arrow-up::after {
  top: -10px;
  left: 50%;

  border-color: transparent transparent #bdbdbd transparent;
}

.arrow-down::after {
  top: 100%;
  left: 50%;

  border-color: #bdbdbd transparent transparent transparent;
}

.arrow-left::after {
  top: 50%;
  right: 100%;

  margin-top: -5px;

  border-color: transparent #bdbdbd transparent transparent;
}

.arrow-right::after {
  top: 50%;
  left: 205px;

  margin-top: -5px;

  border-color: transparent transparent transparent #bdbdbd;
}
</style>
