<template>
  <div class="container-field">
    <label>Preço de Venda (PLD + Spread):</label>
    <vue-numeric
      :min="0"
      :max="9999999"
      :precision="2"
      :value="content"
      :disabled="true"
      :currency="'R$'"
      placeholder="R$ 00,00"
      :decimal-separator="','"
      :thousand-separator="'.'"
      class="form-control ipt-preco-venda"
      data-cy="input-preco_venda_pld_spread_field-pld-spread-components-fields"
    />
  </div>
</template>
<script>
import VueNumeric from "vue-numeric";
export default {
  props: {
    modelProp: Number,
  },
  data() {
    return {
      content: 0,
    };
  },
  watch: {
    modelProp: {
      immediate: true,
      handler() {
        this.content = this.modelProp;
      },
    },
  },
  components: {
    VueNumeric,
  },
};
</script>
<style lang="scss" scoped>
label {
  font-weight: 300;
}

.container-field {
  display: flex;
  flex-direction: column;
  margin: 40px 0px 30px 20px;
}

.ipt-preco-venda {
  margin-top: 8.36px;
  border-radius: 12px;
  color: $color--primary-font;
  padding: 15px 8px 15px 16px;
}

.ipt-preco-venda:disabled {
  background: #f2edeb;
  border: 1px solid #b4b7ba;
}
</style>
