import axios from "axios";
class AcaoBotaoContratoService {
  constructor() {}

  postAcaoFaturamento(link, method, payloadConcluir) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios[method.toLowerCase()](
          link,
          payloadConcluir,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("b-token")}`
            }
          }
        );
        const response = { data };

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  concluirCalculo(link, method, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios[method.toLowerCase()](link, payload, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("b-token")}`
          }
        });
        const response = { data };
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  calcularDescontoGarantido(link, method, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios[method.toLowerCase()](link, payload, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("b-token")}`
          }
        });
        const response = { data };
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new AcaoBotaoContratoService();
