<template>
  <div class="page-analise-pos-venda">
    <page-painel>
      <template #header>
        Faturamento de : <b>Apelido da Empresa - Razão social da empresa</b>
      </template>
      <template #body>
        <div class="page-analise-pos-venda__body">
          <label>Competência: 01/2022</label>

          <contrato
            v-for="contract in 5"
            :key="contract"
            class="contract"
            :type="contract == 1 ? 'apportionment' : 'single'"
          >
            <template #components>
              <upload-manual />
            </template>
          </contrato>

          <historico-acoes />
        </div>
      </template>
    </page-painel>
  </div>
</template>
<script>
import Contrato from "@/components/contratos/Contrato.vue";
import ContratoUploadManual from "@/components/contratos/ContratoUploadManual.vue";
import HistoricoAcoes from "@/components/historico-acoes/HistoricoAcoes.vue";
import PagePainel from "@/components/panels/PagePanel.vue";

export default {
  components: {
    PagePainel,
    Contrato,
    HistoricoAcoes,
    ContratoUploadManual,
  },
};
</script>
<style lang="scss" scoped>
.page-analise-pos-venda {
  padding-top: 24px;
  &__body {
    padding: 2px 40px;
  }

  .contract {
    margin: 20px 0 20px 0;
  }
}
</style>
