<template>
  <div class="container-field">
    <label>Spread de Compra:</label>
    <vue-numeric
      :value="0"
      :minus="true"
      :max="9999999"
      :precision="2"
      :currency="'R$'"
      v-model="content"
      placeholder="R$ 00,00"
      :decimal-separator="','"
      :thousand-separator="'.'"
      class="form-control ipt-spread-compra"
      data-cy="input-spread_compra_field-pld-spread-components-fields"
    />
  </div>
</template>
<script>
import VueNumeric from "vue-numeric";
export default {
  name: "FieldSpreadCompra",
  props: {
    modelProp: Number,
  },
  data() {
    return {
      content: 0,
    };
  },
  watch: {
    modelProp: {
      immediate: true,
      handler() {
        this.content = this.modelProp;
      },
    },
    content: {
      handler() {
        this.$emit("input", this.content);
      },
    },
  },
  components: {
    VueNumeric,
  },
};
</script>
<style lang="scss" scoped>
label {
  font-size: 16px;
  font-weight: 300;
  color: $color--primary-font;
}

.container-field {
  margin: 20px;
}

.ipt-spread-compra {
  margin-top: 8.36px;
  border-radius: 12px;
  color: $color--primary-font;
  padding: 15px 8px 15px 16px;
}
</style>
