<template>
  <div>
    <h2>PAGE NOT FOUND</h2>
  </div>
</template>
<script>
export default {
  name: "NotFound",
};
</script>
