<template>
  <div v-elementAsyncLoader="loading">
    <modal-fechar-faturamento-atual
      v-if="showModalFecharFaturamentoAtual"
      @close="closeModalFecharFaturamentoAtual"
      @openAtencaoFecharFaturamento="openModalAtencao"
    />
    <modal-atencao-fechar-faturamento
      v-if="showModalAtencaoFecharFaturamento"
      @close="closeModalAtencaoFecharFaturamento"
      @proceedAtionCloseFaturamento="closeFaturamento"
    />
    <modal-faturamento-fechado
      v-if="showModalFaturamentoFinalizado"
      @close="closeModalFaturamentoFinalizado"
    />
    <button
      @click="openModalFecharFaturamentoAtual"
      class="btn btn-red-full-filled"
      data-cy="button-fechar_faturamento_atual-parametrizacao-components"
    >
      Fechar Faturamento Atual
    </button>
    <div class="container-historico-fechamento" v-if="data">
      <h5>Histórico de Fechamento</h5>
      <data-table :fields="fields.getTableFields()" :data="data" />
    </div>
    <div class="container-sem-historico-fechamento" v-else>
      <h5>Histórico de Fechamento</h5>
      <p>Não há nenhum faturamento fechado</p>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/tables/DataTable.vue";
import FaturamentosFechados from "@/services/FaturamentosFechadosService";
import TableHistoricoFaturamentosFechados from "@/models/faturamento/TableHistoricoFaturamentosFechados";
import ModalFaturamentoFechado from "@/pages/parametrizacao/components/modals/ModalFaturamentoFechado.vue";
import ModalFecharFaturamentoAtual from "@/pages/parametrizacao/components/modals/ModalFecharFaturamentoAtual.vue";
import ModalAtencaoFecharFaturamento from "@/pages/parametrizacao/components/modals/ModalAtencaoFecharFaturamento.vue";
export default {
  data() {
    return {
      data: new Array(),
      loading: undefined,
      showModalFaturamentoFinalizado: false,
      showModalFecharFaturamentoAtual: false,
      showModalAtencaoFecharFaturamento: false,
      fields: new TableHistoricoFaturamentosFechados(),
    };
  },
  mounted() {
    this.getHistorico();
  },
  methods: {
    getHistorico() {
      this.loading = FaturamentosFechados.getHistoricoFaturamentosFechados()
        .then(({ data }) => (this.data = data))
        .catch((error) => this.mxHandleRequestError(error));
    },
    openModalFecharFaturamentoAtual() {
      this.showModalFecharFaturamentoAtual = true;
    },
    closeModalFecharFaturamentoAtual() {
      this.showModalFecharFaturamentoAtual = false;
    },
    closeModalAtencaoFecharFaturamento() {
      this.showModalAtencaoFecharFaturamento = false;
    },
    openModalAtencao() {
      this.showModalFecharFaturamentoAtual = false;
      this.showModalAtencaoFecharFaturamento = true;
    },
    closeFaturamento() {
      this.loading = FaturamentosFechados.fecharFaturamentoAtual()
        .then(() => {
          this.getHistorico();
          this.showModalAtencaoFecharFaturamento = false;
          this.showModalFaturamentoFinalizado = true;
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    closeModalFaturamentoFinalizado() {
      this.showModalFaturamentoFinalizado = false;
    },
  },
  components: {
    DataTable,
    ModalFaturamentoFechado,
    ModalFecharFaturamentoAtual,
    ModalAtencaoFecharFaturamento,
  },
};
</script>

<style lang="scss" scoped>
.container-historico-fechamento {
  margin-top: 50px;
  margin-left: 10px;

  h5 {
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 25.2px;
  }
}

.container-sem-historico-fechamento {
  margin-top: 50px;

  h5 {
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 25.2px;
  }

  p {
    text-align: center;
  }
}
</style>