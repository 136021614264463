import { render, staticRenderFns } from "./ParametrizacaoHome.vue?vue&type=template&id=78ac1cb9&scoped=true&"
import script from "./ParametrizacaoHome.vue?vue&type=script&lang=js&"
export * from "./ParametrizacaoHome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78ac1cb9",
  null
  
)

export default component.exports