import Services from "./Services";

class JobService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  /**
   * @summary Get the status of Rotina Importação
   * @param {Object} request
   * @endpoint /api/job/dg/status
   */
  getStatusRotinaImportacao(request) {
    this._url = "/dg/status";
    return this.get(request);
  }

  /**
   * @summary Get the status details of Rotina Importação
   * @param {Object} request
   * @endpoint /api/job/dg/status
   */
  getStatusDetalheRotinaImportacao(request) {
    this._url = "/dg/status/detalhe";
    return this.get(request);
  }

  /**
   * @summary Gets Job Config info for DG
   * @param {Object} request
   * @endpoint /api/job/configuracao-dg
   */
  getRotinaImportacao(request) {
    this._url = "/config-dg";
    return this.get(request);
  }

  /**
   * @summary Updates Job Config info for DG
   * @param {Object} request
   * @endpoint /api/job/configuracao-dg
   */
  postRotinaImportacao(request) {
    this._url = "";
    request.rotina = "CALCULO_DG";
    return this.post(request);
  }

  /**
   * @summary Starts the Rotina de Importação DG
   * @param {Object} request
   * @endpoint /api/job/iniciar-calculo-dg
   */
  startJobImportacao(request) {
    this._url = "/iniciar-calculo-dg";
    return this.post(request);
  }
}

export default new JobService("/job");
