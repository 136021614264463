import Vue from 'vue';
import vuescroll from 'vuescroll';
Vue.use(vuescroll, {
  ops: {},
  name: 'scroll' 
});
Vue.prototype.$vuescrollConfig = {
  rail:{
    opacity: 1,
    background: '#F8F8F8',
},
 bar: {
    opacity: 1,
    background: '#C4C4C4',
    keepShow: true,
    showDelay: 600,
 },
 scrollPanel: {
  //scrollingX:false,
 }
};

/*
 exempleConfigs: {
        vuescroll: {
          mode: "native",
          sizeStrategy: "percent",
          detectResize: true,
          locking: true,
        },
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingX:true,
          scrollingY: false,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: "right",
        },
        rail: {
          background: "#01a99a",
          opacity: 0,
          size: "6px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "2px",
          keepShow: false,
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: "#c1c1c1",
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0,
          size: "6px",
          disable: false,
        },
      },
  */