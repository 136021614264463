export default class HistoricoFaturamentoFechadoModel {
  constructor(
    { id, competencia, dataFechamento, responsavel, situacao } = new Object()
  ) {
    this._id = id;
    this._competencia = competencia;
    this._dataFechamento = dataFechamento;
    this._responsavel = responsavel;
    this._situacao = situacao;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id) {
    return (this._id = id);
  }

  get competencia() {
    return this._competencia ?? "--";
  }

  set competencia(competencia) {
    return (this._competencia = competencia);
  }

  get dataFechamento() {
    return this._dataFechamento ?? "--";
  }

  set dataFechamento(dataFechamento) {
    return (this._dataFechamento = dataFechamento);
  }

  get responsavel() {
    return this._responsavel ?? "--";
  }

  set responsavel(responsavel) {
    return (this._responsavel = responsavel);
  }

  get situacao() {
    return this._situacao ?? null;
  }

  set situacao(situacao) {
    return (this._situacao = situacao);
  }

  getData() {
    const { id, competencia, dataFechamento, responsavel, situacao } = this;
    return {
      id,
      competencia,
      dataFechamento,
      responsavel,
      situacao,
    };
  }
}
