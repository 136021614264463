import { render, staticRenderFns } from "./CardPLDSubmercado.vue?vue&type=template&id=5cd405fc&scoped=true&"
import script from "./CardPLDSubmercado.vue?vue&type=script&lang=js&"
export * from "./CardPLDSubmercado.vue?vue&type=script&lang=js&"
import style0 from "./CardPLDSubmercado.vue?vue&type=style&index=0&id=5cd405fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cd405fc",
  null
  
)

export default component.exports