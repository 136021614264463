import Services from "./Services";

class RotinaImportacaoService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  /**
   * @summary Get the status of Rotina Importação
   * @param {Object} request
   * @endpoint /api/rotina
   */
  getStatusRotinaImportacao(request) {
    this._url = "/status";
    return this.get(request);
  }

  /**
   * @summary Get the Rotima Importação
   * @param {Object} request
   * @endpoint /api/rotina
   */
  getRotinaImportacao(request) {
    this._url = "/atual";
    return this.get(request);
  }

  /**
   * @summary Post the Rotima Importação
   * @param {Object} request
   * @endpoint /api/rotina
   */
  postRotinaImportacao(request) {
    this._url = "";
    return this.post(request);
  }
}

export default new RotinaImportacaoService("/rotina");
