var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"elementAsyncLoader",rawName:"v-elementAsyncLoader",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('card-base',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Solicitado Líquido")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"wrapper-table"},[_c('data-table',{attrs:{"data":_vm.alcadasSolicitadoLiquido,"fields":_vm.tableModel.getTableFields()},scopedSlots:_vm._u([{key:"valorFaixaMin",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('field-alcada-aprovacao-min',{ref:("field_alcada_solicitado_liquido_min_" + (_vm.generateUUID())),staticClass:"w-75",staticStyle:{"width":"80% !important"},attrs:{"disabled":"","modelProp":Object.assign({}, row,
                    {valorFaixaMin: '> ' + row.valorFaixaMin}),"placeHolder":"> 50","fieldName":"valorFaixaMin"}})],1)}},{key:"valorFaixaMax",fn:function(ref){
                  var row = ref.row;
return (row.descricaoPerfilAprovador != 'APROVADOR_NIVEL_3')?_c('div',{},[_c('field-alcada-aprovacao-max',{ref:("field_alcada_solicitado_liquido_max_" + (_vm.generateUUID())),staticClass:"w-75",staticStyle:{"width":"80% !important"},attrs:{"placeHolder":"30","modelProp":row,"fieldName":"valorFaixaMax","disabled":!_vm.$can('ALCADAS_APROVACAO_VISUALIZAR_EDITAR')},on:{"maxValueChanged":_vm.changeMinValue}})],1):_vm._e()}}],null,true)})],1)]},proxy:true}])})],1),_c('div',{staticClass:"col-lg-6"},[_c('card-base',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Solicitado Spread de Recompra Manual")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"wrapper-table"},[_c('data-table',{attrs:{"data":_vm.alcadasSolicitadoSpreadRecompra,"fields":_vm.tableModel.getTableFields()},scopedSlots:_vm._u([{key:"valorFaixaMin",fn:function(ref){
                  var row = ref.row;
return _c('div',{},[_c('field-alcada-aprovacao-min',{ref:("field_alcada_solicitado_spread_recompra_min_" + (_vm.generateUUID())),staticClass:"w-75",staticStyle:{"width":"80% !important"},attrs:{"disabled":"","modelProp":Object.assign({}, row,
                    {valorFaixaMin: '> ' + row.valorFaixaMin}),"placeHolder":"> 50","fieldName":"valorFaixaMin"}})],1)}},{key:"valorFaixaMax",fn:function(ref){
                  var row = ref.row;
return (row.descricaoPerfilAprovador != 'APROVADOR_NIVEL_3')?_c('div',{},[_c('field-alcada-aprovacao-max',{ref:("field_alcada_solicitado_spread_recompra_max_" + (_vm.generateUUID())),staticClass:"w-75",staticStyle:{"width":"80% !important"},attrs:{"placeHolder":"30","modelProp":row,"fieldName":"valorFaixaMax","disabled":!_vm.$can('ALCADAS_APROVACAO_VISUALIZAR_EDITAR')},on:{"maxValueChanged":_vm.changeMinValue}})],1):_vm._e()}}],null,true)})],1)]},proxy:true}])})],1),_c('div',{staticClass:"col d-flex justify-content-end mt-3"},[_c('div',{staticClass:"button-bar-parametrizacao"},[_c('button',{staticClass:"btn btn-secondary-full-filled",attrs:{"disabled":!_vm.hasChanged || !_vm.$can('ALCADAS_APROVACAO_VISUALIZAR_EDITAR'),"data-cy":"button-cancelar_alteracoes_alcada_aprovacao-parametrizacao-components"},on:{"click":function($event){return _vm.getBackToParametrizacaoHome()}}},[_vm._v(" Cancelar Alterações ")]),_c('button',{staticClass:"btn btn-primary-full-filled ms-3",attrs:{"disabled":!_vm.hasChanged || !_vm.$can('ALCADAS_APROVACAO_VISUALIZAR_EDITAR'),"data-cy":"button-salvar_alteracoes_alcada_aprovacao-parametrizacao-components"},on:{"click":_vm.save}},[_vm._v(" Salvar Alterações ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }