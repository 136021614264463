import TableExportadorFlexModel from "@/models/tablesModels/TableExportadorFlexModel";

export default class ExportadorModel {
  constructor(
    {
      id,
      ano,
      mes,
      take,
      tipo,
      rateio,
      apelido,
      contrato,
      selected,
      recompra,
      aprovacao,
      exportado,
      tipoClasse,
      responsavel,
      isBoldStyle,
      montantesWbc,
      pontoMedicao,
      novoNotional,
      precoRecompra,
      volumeRecompra,
      valorContratado,
      novoFaturamento,
      montanteBilling,
      contratosFilhos,
      statusIntegracao,
      notionalRecompra,
      novoPrecoBilling,
      valorContratadoWbc,
      dataUltimaPublicacao,
      statusOrdemFinanceira,
    } = new Object()
  ) {
    this._id = id;
    this._ano = ano;
    this._mes = mes;
    this._tipo = tipo;
    this._take = take;
    this._rateio = rateio;
    this._apelido = apelido;
    this._selected = selected;
    this._recompra = recompra;
    this._contrato = contrato;
    this._exportado = exportado;
    this._aprovacao = aprovacao;
    this._tipoClasse = tipoClasse;
    this._isBoldStyle = isBoldStyle;
    this._responsavel = responsavel;
    this._montantesWbc = montantesWbc;
    this._pontoMedicao = pontoMedicao;
    this._novoNotional = novoNotional;
    this._precoRecompra = precoRecompra;
    this._volumeRecompra = volumeRecompra;
    this._valorContratado = valorContratado;
    this._novoFaturamento = novoFaturamento;
    this._contratosFilhos = contratosFilhos;
    this._montanteBilling = montanteBilling;
    this._novoPrecoBilling = novoPrecoBilling;
    this._notionalRecompra = notionalRecompra;
    this._statusIntegracao = statusIntegracao;
    this._valorContratadoWbc = valorContratadoWbc;
    this._dataUltimaPublicacao = dataUltimaPublicacao;
    this._statusOrdemFinanceira = statusOrdemFinanceira;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id = null) {
    this._id = id;
  }

  get ano() {
    return this._ano;
  }

  set ano(ano) {
    this._ano = ano;
  }

  get mes() {
    return this._mes;
  }

  set mes(mes) {
    this._mes = mes;
  }

  get pontoMedicao() {
    return this._pontoMedicao;
  }

  set pontoMedicao(pontoMedicao) {
    this._pontoMedicao = pontoMedicao;
  }

  set rateio(rateio) {
    this._rateio = rateio;
  }

  get rateio() {
    return this._rateio;
  }

  get exportado() {
    return this._exportado;
  }

  set exportado(exportado) {
    this._exportado = exportado;
  }

  get statusOrdemFinanceira() {
    return this._statusOrdemFinanceira;
  }

  set statusOrdemFinanceira(statusOrdemFinanceira) {
    this._statusOrdemFinanceira = statusOrdemFinanceira;
  }

  get volumeRecompra() {
    return this._volumeRecompra;
  }

  set volumeRecompra(volumeRecompra) {
    this._volumeRecompra = volumeRecompra;
  }

  get precoRecompra() {
    return this._precoRecompra;
  }

  set precoRecompra(preçoRecompra) {
    this.precoRecompra = preçoRecompra;
  }

  get notionalRecompra() {
    return this._notionalRecompra;
  }

  set notionalRecompra(notionalRecompra) {
    this.notionalRecompra = notionalRecompra;
  }

  get novoFaturamento() {
    return this._novoFaturamento;
  }

  set novoFaturamento(novoFaturamento) {
    this.novoFaturamento = novoFaturamento;
  }

  get novoPrecoBilling() {
    return this._novoPrecoBilling;
  }

  set novoPrecoBilling(novoPrecoBilling) {
    this.novoPrecoBilling = novoPrecoBilling;
  }

  get novoNotional() {
    return this._novoNotional;
  }

  set novoNotional(novoNotional) {
    this.novoNotional = novoNotional;
  }

  get recompra() {
    return this._recompra ?? null;
  }

  set recompra(recompra) {
    this.recompra = recompra;
  }

  get tipoClasse() {
    return this._tipoClasse;
  }

  set tipoClasse(tipoClasse) {
    this._tipoClasse = tipoClasse;
  }

  get selected() {
    return this._selected ?? false;
  }

  set selected(selected) {
    this._selected = selected;
  }

  get integracao() {
    return this._integracao;
  }

  set integracao(integracao) {
    this._integracao = integracao;
  }

  get dataUltimaPublicacao() {
    return this._dataUltimaPublicacao;
  }

  set dataUltimaPublicacao(dataUltimaPublicacao) {
    this._dataUltimaPublicacao = dataUltimaPublicacao;
  }

  get apelido() {
    return this._apelido ?? null;
  }

  set apelido(apelido = null) {
    this._apelido = apelido;
  }

  get contrato() {
    return this._contrato ?? null;
  }

  set contrato(contrato = null) {
    this._contrato = contrato;
  }

  get responsavel() {
    if (this._responsavel) {
      return this._responsavel;
    }
    return null;
  }

  set responsavel(responsavel = null) {
    this._responsavel = responsavel;
  }

  get take() {
    return this._take ?? null;
  }

  set take(take = null) {
    this._take = take;
  }

  get montanteBilling() {
    return this._montanteBilling ?? null;
  }

  set montanteBilling(montanteBilling = null) {
    this._montanteBilling = montanteBilling;
  }

  get montantesWbc() {
    return this._montantesWbc ?? null;
  }

  set montantesWbc(montantesWbc = null) {
    this._montantesWbc = montantesWbc;
  }

  get aprovacao() {
    if (this._aprovacao) {
      return this._aprovacao.descricao;
    }
    return null;
  }

  set aprovacao(aprovacao) {
    return (this._aprovacao = aprovacao);
  }

  get contratosFilhos() {
    return this._contratosFilhos;
  }

  set contratosFilhos(contratosFilhos) {
    this._contratosFilhos = contratosFilhos;
  }

  get isBoldStyle() {
    return this._isBoldStyle;
  }

  set isBoldStyle(isBoldStyle) {
    this._isBoldStyle = isBoldStyle;
  }

  get tipo() {
    return this._tipo;
  }

  set tipo(tipo = "flex") {
    this._tipo = tipo;
  }

  set valorContratado(valorContratado = 0) {
    this._valorContratado = valorContratado;
  }

  get valorContratado() {
    return this._valorContratado;
  }

  set valorContratadoWbc(valorContratadoWbc = 0) {
    this._valorContratadoWbc = valorContratadoWbc;
  }

  get valorContratadoWbc() {
    return this._valorContratadoWbc;
  }

  get inconsistencies() {
    if (this.tipo === "recompra") {
      // A existência do "hasRecompra" se deve ao fato de que um contrato de tipo "PAI" não possui recompra de forma alguma, mesmo sendo um contrato de tipo "recompra"
      const hasRecompra = this.tipoClasse !== "PAI";
      return {
        quantidade:
          hasRecompra &&
          this.montantesWbc !== this.montanteBilling.recompra.volumeFinal,
        preco:
          hasRecompra &&
          this.valorContratadoWbc !== this.montanteBilling.recompra.precoFinal,
      };
    }
    return {
      quantidade: this.montantesWbc !== this.montanteBilling.volumeAFaturar,
      preco: this.valorContratadoWbc !== this.valorContratado,
    };
  }

  getContratosFilhos(model = TableExportadorFlexModel) {
    if (!this._contratosFilhos || !Array.isArray(this._contratosFilhos)) {
      return [];
    }
    return this._contratosFilhos.map((contratoFilho) => {
      const recompra = contratoFilho.calculoFaturamento;
      return new model({
        ...contratoFilho,
        responsavel: this._responsavel,
        pontoMedicao:
          contratoFilho.pontoMedicao && contratoFilho.pontoMedicao.codigo,
        contrato: contratoFilho.codigo,
        aprovacao: contratoFilho.empresa,
        apelido: contratoFilho.empresa.apelido,
        montanteBilling: contratoFilho.calculoFaturamento,
        ...(recompra && {
          volumeRecompra: recompra.volume,
          precoRecompra: recompra.preco,
          notionalRecompra: recompra.notional,
          novoFaturamento: recompra.volumeFinal,
          novoPrecoBilling: recompra.precoFinal,
          novoNotional: recompra.notionalFinal,
        }),
      });
    });
  }

  getData() {
    const {
      id,
      ano,
      mes,
      take,
      tipo,
      rateio,
      apelido,
      contrato,
      selected,
      recompra,
      aprovacao,
      exportado,
      integracao,
      tipoClasse,
      responsavel,
      isBoldStyle,
      montantesWbc,
      pontoMedicao,
      novoNotional,
      precoRecompra,
      volumeRecompra,
      valorContratado,
      novoFaturamento,
      montanteBilling,
      contratosFilhos,
      statusIntegracao,
      notionalRecompra,
      novoPrecoBilling,
      valorContratadoWbc,
      dataUltimaPublicacao,
      statusOrdemFinanceira,
    } = this;
    return {
      id,
      ano,
      mes,
      take,
      tipo,
      rateio,
      apelido,
      contrato,
      selected,
      recompra,
      aprovacao,
      exportado,
      integracao,
      tipoClasse,
      responsavel,
      isBoldStyle,
      montantesWbc,
      pontoMedicao,
      novoNotional,
      precoRecompra,
      volumeRecompra,
      valorContratado,
      novoFaturamento,
      montanteBilling,
      contratosFilhos,
      statusIntegracao,
      notionalRecompra,
      novoPrecoBilling,
      valorContratadoWbc,
      dataUltimaPublicacao,
      statusOrdemFinanceira,
    };
  }
}
