<template>
  <div class="navigation-header">
    <div
      @click="toggle"
      class="navigation-header__fixed"
      data-cy="div-alternar_header-navigation"
    >
      <div class="navigation-header__menu">
        <img :src="mountImg('/images/svg/menu.svg')" />
      </div>
      <div class="navigation-header__logo">
        <img src="@/assets/logo/orange.svg" />
      </div>
    </div>
    <transition name="slide">
      <div v-if="getNavigationShow" class="navigation-header__slider">
        Billing Matrix
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getNavigationShow"]),
  },

  methods: {
    toggle() {
      this.$store.dispatch("toggleNavigation");
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation-header {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  background: $color--primary-white;
  &__fixed {
    padding-left: 20px;
    height: 100%;
    z-index: 999;
    display: flex;
    gap: 4px;
    min-width: 113px;
    position: relative;
    align-items: center;
    transition: min-width 0.5s;
    background: $color--primary-white;
    @include ml-hd {
      min-width: responsive(113px);
      padding-left: responsive(20px);
    }
  }
  &__menu {
    cursor: pointer;
    margin-right: responsive(10px);
    img {
      width: responsive(19px);
    }
  }
  &__logo {
    img {
      width: 32px;
    }
  }
  &__slider {
    width: 100%;
    z-index: 998;
    padding-left: 2px;
    color: #2a2a2a;
    font-size: 1rem;
    font-weight: 700;
    line-height: 150%;
    white-space: nowrap;
    letter-spacing: 1px;
    font-family: "Montserrat";
    background: $color--primary-white;
    @include ml-hd {
      font-size: responsive(1rem);
    }
  }
}

.slide-enter-active {
  transition: all 0.3s;
}
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter, .slide-leave-to
/* .slide-fade-leave-active em versões anteriores a 2.1.8 */ {
  transform: translateX(-215px);
}
</style>
