const layout = {
  state: {
    navigationShow: false,
    currentFaturamento: {
      competencia: "",
      dataReferencia: "",
      responsavel: "",
      situacao: "",
    },
  },
  getters: {
    getNavigationShow: (state) => state.navigationShow,
    getCurrentFaturamento: (state) => state.currentFaturamento,
  },
  mutations: {
    setToggleNavigation(state) {
      state.navigationShow = !state.navigationShow;
    },
    setHomeTemplateComponent(state, value) {
      state.homeTemplateComponent = value;
    },
    setCurrentFaturamento(state, value) {
      state.currentFaturamento = value;
    },
  },
  actions: {
    toggleNavigation(context) {
      context.commit("setToggleNavigation");
    },
    loadHomeTemplateComponent(context, value) {
      context.commit("setHomeTemplateComponent", value);
    },
    setCurrentFaturamento(context, value) {
      context.commit("setCurrentFaturamento", value);
    },
  },
};

export default layout;
