import Services from "./Services";

class CceeService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  /**
   * @summary Starts the Rotina de Importação
   * @param {Object} request
   * @endpoint /api/ccee/importacao-montantes
   */
  startJobImportacao(request) {
    this._url = "/importacao-montantes";
    return this.post(request);
  }
}

export default new CceeService("/ccee");
