export default class TipoEnergia {
  constructor(
    {
      id,
      codigo,
      descricao,
      sigla,
      status,
      ultimoSpread,
      selected,
    } = new Object()
  ) {
    this._id = id;
    this._codigo = codigo;
    this._descricao = descricao;
    this._sigla = sigla;
    this._status = status;
    this._ultimoSpread = ultimoSpread;
    this._selected = selected;
  }

  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get codigo() {
    return this._codigo;
  }

  set codigo(codigo) {
    this._codigo = codigo;
  }

  get descricao() {
    return this._descricao;
  }

  set descricao(descricao) {
    this._descricao = descricao;
  }

  get sigla() {
    return this._sigla;
  }

  set sigla(sigla) {
    this._sigla = sigla;
  }

  get status() {
    return this._status;
  }

  set status(status) {
    this._status = status;
  }

  get ultimoSpread() {
    return this._ultimoSpread;
  }

  set ultimoSpread(ultimoSpread) {
    this._ultimoSpread = ultimoSpread;
  }

  get selected() {
    return this._selected;
  }

  set selected(selected) {
    if (this._status === "Pendente") {
      this._selected = selected;
    }
  }

  getData() {
    const { id, codigo, descricao, sigla, status, ultimoSpread, selected } =
      this;
    return {
      id,
      codigo,
      descricao,
      sigla,
      status,
      ultimoSpread,
      selected,
    };
  }
}
