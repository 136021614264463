var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-table"},[_c('div',{class:{ 'card-table': _vm.card }},[_c('scroll',{staticStyle:{"padding-bottom":"18px"},attrs:{"ops":_vm.scrollConfig}},[_c('div',{staticClass:"data-table__wrapper",style:(("max-height:" + _vm.maxHeight))},[_c('table',{staticClass:"_table"},[_c('thead',{class:{ 'header-sticky': _vm.headerStick }},[_c('tr',[_c('td'),_vm._l((_vm.fields),function(fieldHeader,fieldHeaderIndex){return _c('td',{key:(fieldHeaderIndex + "fieldHeaderIndex"),class:{ center: fieldHeader.align == 'center' },staticStyle:{"font-size":"16px"},style:([
                  fieldHeader.width
                    ? { width: ("" + (fieldHeader.width)) }
                    : { width: ((100 / fieldHeader.length) + "%") } ])},[(_vm.isFieldSlot('header-' + fieldHeader.name))?_c('div',[_vm._t('header-' + fieldHeader.name)],2):_c('div',[_vm._v(" "+_vm._s(fieldHeader.title)+" ")])])})],2)]),_vm._l((_vm.dataToggle),function(row,indexRow){return _c('tbody',{key:(indexRow + "indexRow"),class:[_vm.stripes ? 'stripes' : '_border-bottom']},[_c('tr',{class:[
                _vm.stripes ? 'stripes' : '_border-bottom',
                _vm.lineClickable ? 'pointer' : null,
                _vm.lineClass ? (_vm.lineClass + "-" + indexRow) : null ],attrs:{"data-cy":"tr-clique_na_linha_selecionada-tables"},on:{"click":function($event){$event.stopPropagation();return _vm.lineClicked(row, indexRow)}}},[_c('td',{style:(("padding: " + _vm.rowPadding + " 15px " + _vm.rowPadding + " 10px; background:" + (row.detail ? '#AEC0D1' : '')))},[_c('span',{staticClass:"row-toggle",attrs:{"data-cy":"span-data_table_recompra-tables"},on:{"click":function($event){return _vm.toggle(indexRow)}}},[(!row.detail)?_c('img',{attrs:{"src":"/images/svg/icon-table-open.svg"}}):_c('img',{attrs:{"src":"/images/svg/icon-table-close.svg"}})])]),_vm._l((_vm.fields),function(fieldBody,indexFieldBody){return _c('td',{key:(indexFieldBody + "indexFieldBody"),class:{ center: fieldBody.align == 'center' },style:(("padding: " + _vm.rowPadding + " 15px " + _vm.rowPadding + " 10px; background:" + (row.detail ? '#AEC0D1' : '')))},[(_vm.isFieldSlot(fieldBody.name))?_c('div',{class:{ center: fieldBody.align == 'center' }},[_vm._t(fieldBody.name,null,{"index":indexRow,"row":row})],2):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.renderValue(_vm.data[indexRow], fieldBody))}})])])})],2),_c('tr',{staticClass:"row-detail"},[_c('td',{directives:[{name:"show",rawName:"v-show",value:(row.detail),expression:"row.detail"}],attrs:{"colspan":_vm.fields.length + 1}},[_vm._t('detail',null,{"index":indexRow,"row":row})],2)])])}),_c('tr',[(_vm.data.length === 0)?_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.fields.length}},[_vm._v(" Nenhum resultado encontrado ")]):_vm._e()]),(_vm.footer)?_c('tr',{staticClass:"footer-sticky"},[_c('td'),_vm._l((_vm.fields),function(fieldHeader,fieldHeaderIndex){return _c('td',{key:(fieldHeaderIndex + "fieldHeaderIndex"),class:{ center: fieldHeader.align == 'center' },staticStyle:{"font-size":"16px"},style:([
                fieldHeader.width
                  ? { width: ("" + (fieldHeader.width)) }
                  : { width: ((100 / fieldHeader.length) + "%") } ])},[(_vm.isFieldSlot('footer-' + fieldHeader.name))?_c('div',{staticStyle:{"font-weight":"600","text-align":"center"}},[_vm._t('footer-' + fieldHeader.name)],2):_c('div')])})],2):_vm._e()],2)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }