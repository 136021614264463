<template>
  <div>
    <modal-base width="590px" @closeModal="close">
      <template #header> Fechar Faturamento Atual </template>
      <template #body>
        <div class="body-modal">
          <p class="text-red">
            Deseja prosseguir com o Fechamento do Faturamento atual? <br />
            <span class="text-black"
              >Esta ação abrirá o próximo faturamento.</span
            >
          </p>
        </div>
      </template>
      <template #footer>
        <div class="container-buttons">
          <button class="btn btn-primary-outlined cancel-btn" @click="close">
            Cancelar
          </button>
          <button
            class="btn btn-primary-full-filled proceed-btn"
            @click="proceed"
          >
            Sim, prosseguir com o Fechamento
          </button>
        </div>
      </template>
    </modal-base>
  </div>
</template>

<script>
import ModalBase from "@/components/modals/ModalBase.vue";
export default {
  methods: {
    close() {
      this.$emit("close");
    },
    proceed() {
      this.$emit("openAtencaoFecharFaturamento");
    },
  },
  components: {
    ModalBase,
  },
};
</script>

<style lang="scss" scoped>
.body-modal {
  text-align: center;
  margin: 20px 16px;
}

.text-red {
  color: #d40d00;
  font-weight: 700;
}

.text-black {
  color: #2a2a2a;
  font-weight: 500;
}

.container-buttons {
  width: 100%;
  display: flex;
  margin-right: 22px;
  align-items: center;
  justify-content: flex-end;
}

.cancel-btn {
  margin-right: 22px;
  border-radius: 10px !important;
}

.proceed-btn {
  background: #d40d00 !important;
  border: 1px solid #d40d00 !important;

  &:hover {
    color: #d40d00 !important;
    background: #fff !important;
  }
}
</style>