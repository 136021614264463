import store from "@/store";
import { Toast } from "bootstrap";

export default {
  methods: {
    async mxToggleToast({ title = "Sucesso", message, type = "success" }) {
      if (store.getters.getToastInstance) {
        store.getters.getToastInstance.hide();
      }
      if (!message) return;
      store.dispatch("ActionToggleToast", { title, message, type });
      const toastElement = document.getElementById("toast-component");
      if (toastElement) {
        const toast = new Toast(toastElement);
        store.dispatch("ActionHoldToastInstance", toast);
        return toast.show();
      }
    },
  },
};
