export default [
  {
    id: "CCEE_INDISPONIVEL",
    descricao: "ccee indiponível",
    visible: true,
    color: "#000000",
  },
  {
    id: "PONTO_SEM_ACESSO",
    descricao: "ponto sem acesso",
    visible: true,
    color: "#ffffff",
  },
  {
    id: "CONTRATO_SEM_PONTO",
    descricao: "contrato s/ ponto",
    visible: true,
    color: "#ffffff",
  },
  {
    id: "PONTO_SEM_PROINFA",
    descricao: "ponto s/ proinfa",
    visible: true,
    color: "#ffffff",
  },
  {
    id: "SEM_PONTO_E_PROINFA",
    descricao: "s/ ponto e proinfa",
    visible: true,
    color: "#ffffff",
  },
  {
    id: "ENVIO_VIA_HOTSITE",
    descricao: "envio via hotsite",
    visible: true,
    color: "#ffffff",
  },
  {
    id: "ENVIO_MANUAL",
    descricao: "envio manual",
    visible: true,
    color: "#ffffff",
  },
  {
    id: "RECOMPRA_APROVADA",
    descricao: "recompra aprov.",
    visible: true,
    color: "#000000",
  },
  {
    id: "TAKE_MINIMO_APROVADO",
    descricao: "take mín. aprov.",
    visible: true,
    color: "#000000",
  },
  {
    id: "SEM_RECOMPRA",
    descricao: "sem recompra",
    visible: true,
    color: "#000000",
  },
  {
    id: "COM_RECOMPRA",
    descricao: "com recompra",
    visible: true,
    color: "#000000",
  },
  {
    id: "PONTO_DESABILITADO",
    descricao: "ponto desabilitado",
    visible: true,
    color: "#ffffff",
  },
  {
    id: "RESULTADO_ALCADA",
    descricao: "resultado da alçada",
    visible: true,
    color: "#000000",
  },
  {
    id: "VOLUME_NEGATIVO",
    descricao: "volume negativo",
    visible: true,
    color: "#ffffff",
  },
  {
    id: "DG_S_APROV_AUTOMATICA",
    descricao: "DG s/ aprov. automática",
    visible: false,
    color: "#ffffff",
  },
  {
    id: "DG_C_APROV_AUTOMATICA",
    descricao: "DG c/ aprov. automática",
    visible: false,
    color: "#ffffff",
  },
  {
    id: "DESCONTO_GARANTIDO",
    descricao: "Desconto Garantido",
    descricaoFilter: "desconto garantido",
    visible: true,
    color: "#ffffff",
  },
];
