<template>
  <div class="hint-tooltip" ref="dynamicTooltip" :style="tooltipStyle">
    <div class="triangle" v-if="direction === 'top'" />
    <div class="ballon">
      {{ content }}
    </div>
    <div
      class="triangle"
      v-if="direction === 'bottom'"
      style="transform: rotate(180deg)"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      direction: null,
    };
  },
  props: {
    on: {
      type: Boolean,
      required: true,
    },
    maxWidth: {
      type: Number,
      default: 200,
    },
    content: {
      type: String,
      default: "",
    },
  },
  watch: {
    on() {
      const parent = this.$refs.dynamicTooltip?.parentElement;
      if (!parent || !this.on) return;
      const screenY = window.innerHeight;

      if (parent.getBoundingClientRect().y > screenY / 2) {
        this.direction = "bottom";
      } else {
        this.direction = "top";
      }
    },
  },
  computed: {
    tooltipStyle() {
      return {
        [this.direction]: "100%",
        display: this.on ? "flex" : "none",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.hint-tooltip {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .triangle {
    border-bottom: 10px #fffaf8 solid;
    border-left: 10px transparent solid;
    border-right: 10px transparent solid;
  }
  .ballon {
    background-color: #fffaf8;
    color: #000;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    width: 200px;
    box-shadow: 3px 3px 5px rgba($color: #000000, $alpha: 0.3);
  }
}
</style>
