import {
  CNPJ,
  realSymbolFormatter,
  roundFormatter,
} from "@/filters/formatters";

export default class ContratoRateioModel {
  static getTableFields() {
    return new Array(
      {
        name: "codigo",
        title: "Contrato",
      },
      {
        name: "apelido",
        title: "Apelido",
      },
      {
        name: "cnpj",
        title: "CNPJ",
        formatter: (value) => {
          if (value) {
            return CNPJ(value);
          }
          return "--";
        },
      },
      {
        name: "volumeBruto",
        title: "S. Bruto (MWm)",
        formatter: (value) => {
          return roundFormatter(value, 3);
        },
      },
      {
        name: "volumeFinal",
        title: "Volume (MWh)",
      },
      {
        name: "precoFinal",
        title: "Preço",
        formatter: (value) => {
          return realSymbolFormatter(roundFormatter(value, 2));
        },
      },
      {
        name: "novoNotional",
        title: "Notional",
        formatter: (value) => {
          return realSymbolFormatter(roundFormatter(value, 2));
        },
      }
    );
  }
}
