export default class ContratoNaoImportadoModel {
  constructor(
    {
      id,
      take,
      rateio,
      detalhe,
      empresa,
      posVenda,
      categoria,
      statusAtual,
      ultimoDetalhe,
      parametrizacao,
      codigo_contrato,
      codigo_contrato_referencia,
    } = new Object()
  ) {
    this._id = id;
    this._take = take;
    this._rateio = rateio;
    this._detalhe = detalhe;
    this._empresa = empresa;
    this._posVenda = posVenda;
    this._categoria = categoria;
    this._statusAtual = statusAtual;
    this._ultimoDetalhe = ultimoDetalhe;
    this._parametrizacao = parametrizacao;
    this._codigo_contrato = codigo_contrato;
    this._codigo_contrato_referencia = codigo_contrato_referencia;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id = null) {
    this._id = id;
  }

  get codigo_contrato() {
    return this._codigo_contrato ?? null;
  }

  set codigo_contrato(codigo_contrato = null) {
    this._codigo_contrato = codigo_contrato;
  }

  get codigo_contrato_referencia() {
    return this._codigo_contrato_referencia ?? null;
  }

  set codigo_contrato_referencia(codigo_contrato_referencia = null) {
    this._codigo_contrato_referencia = codigo_contrato_referencia;
  }

  get rateio() {
    return this._rateio ?? null;
  }

  set rateio(rateio = null) {
    this._rateio = rateio;
  }

  get take() {
    return this._take ?? null;
  }

  set take(take = null) {
    this._take = take;
  }

  get detalhe() {
    return this._detalhes ?? null;
  }

  set detalhe(detalhes = null) {
    this._detalhes = detalhes;
  }

  get categoria() {
    return this._categoria ?? null;
  }

  set categoria(categoria = null) {
    this._categoria = categoria;
  }

  get parametrizacao() {
    return (
      this._parametrizacao ?? {
        envioAutomatico: null,
      }
    );
  }

  set parametrizacao(parametrizacao = null) {
    this._parametrizacao = parametrizacao;
  }

  get posVenda() {
    return this._posVenda ?? null;
  }

  set posVenda(posVenda = null) {
    this._posVenda = posVenda;
  }

  get empresa() {
    return (
      this._empresa ?? {
        id: null,
        cnpj: null,
        apelido: null,
        razaoSocial: null,
      }
    );
  }

  set empresa(empresa = null) {
    this._empresa = empresa;
  }

  get statusAtual() {
    return (
      this._statusAtual ?? {
        id: null,
        descricao: null,
      }
    );
  }

  set statusAtual(statusAtual = null) {
    this._statusAtual = statusAtual;
  }

  get ultimoDetalhe() {
    return (
      this._ultimoDetalhe ?? {
        id: null,
        descricao: null,
        tipo: null,
      }
    );
  }

  set ultimoDetalhe(ultimoDetalhe = null) {
    this._ultimoDetalhe = ultimoDetalhe;
  }

  getData() {
    const {
      id,
      take,
      rateio,
      detalhe,
      empresa,
      posVenda,
      categoria,
      statusAtual,
      ultimoDetalhe,
      parametrizacao,
      codigo_contrato,
      codigo_contrato_referencia,
    } = this;
    return {
      id,
      take,
      rateio,
      detalhe,
      empresa,
      posVenda,
      categoria,
      statusAtual,
      ultimoDetalhe,
      parametrizacao,
      codigo_contrato,
      codigo_contrato_referencia,
    };
  }
}
