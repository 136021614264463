<template>
  <div>
    <modal-base
      width="688px"
      @closeModal="closeModal()"
      data-cy="modal-fechar_modal_pendente_sem_proinfa-faturamento-components-modais"
    >
      <template #header> Cadastro Pendente - Ponto sem PROINFA </template>
      <template #body>
        <div class="body-container">
          <p>
            O contrato <b>{{ getContrato }}</b> contém ponto(s) de medição sem
            PROINFA configurado para o mês de <b>{{ competencia }}</b
            >. Deseja Configurar?
          </p>
        </div>
      </template>
      <template #footer>
        <div class="container-footer">
          <button
            @click="closeModal()"
            class="mx-2 btn btn-primary-outlined btn-margin"
            data-cy="button-cancelar_pendente_sem_proinfa-faturamento-components-modais"
          >
            Cancelar
          </button>
          <button
            @click="configurar()"
            class="mx-3 btn btn-primary-full-filled btn-common"
            data-cy="button-configurar_proinfa_pendente_sem_proinfra-faturamento-components-modais"
          >
            Configurar PROINFA
          </button>
        </div>
      </template>
    </modal-base>
  </div>
</template>
<script>
import ModalBase from "@/components/modals/ModalBase.vue";
import FaturamentosFechadosService from "@/services/FaturamentosFechadosService";
export default {
  data() {
    return {
      competencia: "",
    };
  },
  mounted() {
    this.getCurrentFaturamento();
  },
  props: {
    modelProp: Object,
  },
  computed: {
    getContrato() {
      return this.modelProp.contrato;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    configurar() {
      this.$emit("configurarProinfa");
    },
    async getCurrentFaturamento() {
      const { data } = await FaturamentosFechadosService.getCurrentFaturamento();
      const { competencia } = data;
      this.competencia = competencia;
    },
  },
  components: {
    ModalBase,
  },
};
</script>
<style scoped>
.body-container {
  text-align: center;
  margin: 27px 37px;
}

.body-container p {
  width: 100%;
  height: 48px;
  color: #2a2a2a;
  font-size: 16px;
}

.container-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin: 16px 30px;
}

.btn-common {
  width: 218px;
  height: 34px;
}

.btn-margin {
  width: 194px;
  height: 34px;
  margin-right: 20px;
}

.btn {
  border-radius: 9px;
  padding: 4px 16px;
  font-weight: 700;
}
</style>
