import bigDecimal, { RoundingModes } from "js-big-decimal";
import { DateTime } from "luxon";

export const CNPJ = function (value) {
  if (!value) return "";
  return value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
};

export const CURRENCY = function (value, defaultValue = null) {
  if (!value && value !== 0) return "0";
  if (!value && typeof value !== "number") return defaultValue || "0,00";

  if (value < 0) {
    return (
      "R$ " +
      Number(value).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }
  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const CURRENCY_VALUE_ONLY = function (value, defaultValue = null) {
  if (!value && typeof value !== "number") return defaultValue || "0,00";

  if (value < 0) {
    return Number(value).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return Number(value)
    .toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace("R$", "")
    .trim();
};

export const LIMITADORES = function (value) {
  if (!value || Number(value) === 0) return "N/A";
  if (value.toString().indexOf("%") > -1) return value;
  return CURRENCY(value) + "%";
};

export const PRECO_ICMS = function (value) {
  if (!value) value = 0;
  return Number(value).toLocaleString("pt-BR", {
    type: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const VOLUME = function (value) {
  if (!value) value = 0;
  return Number(value).toLocaleString("pt-BR", {
    type: "currency",
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });
};

export const VOLUME_MWH = function (value) {
  if (!value) return "--";
  return Number(value).toLocaleString("pt-BR", {
    type: "currency",
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });
};

export const VOLUME_PERCENTUAL = function (value) {
  if (!value) return "";
  return PERCENTUAL(VOLUME(value), false);
};

export const PERCENTUAL = function (value, currency = true) {
  if (!value) return "--";
  if (value.toString().indexOf("%") > -1) return value;
  if (currency) return (CURRENCY(value) + "%").replace(".", ",");
  else return value + "%";
};

export const ALIQUOTAS_PERCENTUAL = function (value, currency = true) {
  if (!value) return "--";
  if (value.toString().indexOf("%") > -1) return value;
  if (currency)
    return (CURRENCY(value) + "%").replace(".", ",").replace("R$", "");
  else return value + "%";
};

export const PERCENTUAL_PERDA = function (value) {
  if (!value) return "--";
  return `${Number(value).toFixed(2).replace(".", ",")} %`;
};

export const CAPITALIZE = function (value) {
  if (!value) return "";
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const REMOVE_DOTS = function (value) {
  if (!value) return "";
  return value.toString().replace(/([^\d])+/gim, "");
};

export const PHONE = (value) => {
  if (!value) return "--";
  return value.replace(/(\d{2})(\d{4})(\d{4})/g, "($1) $2-$3");
};

export const CELPHONE = (value) => {
  if (!value) return "--";
  return value.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3");
};

export const CPF = (value) => {
  if (!value) return "--";
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
};

export const INSCRICAO_ESTADUAL = (value) => {
  if (!value) return "--";
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/g, "$1.$2.$3.$4");
};

export const CEP = (value) => {
  if (!value) return "--";
  return value.replace(/(\d{5})(\d{3})/g, "$1-$2");
};

export const DATE_ptBR = (value) => {
  if (!value) return "--";
  try {
    return DateTime.fromMillis(value).toLocaleString({ locale: "pt-BR" });
  } catch (error) {
    return "";
  }
};

export const DATE_TIME_ptBR = (value) => {
  if (!value) return "--";
  try {
    return DateTime.fromMillis(value).toLocaleString(DateTime.DATETIME_SHORT);
  } catch (error) {
    return "";
  }
};

export const EMPTY = (value) => {
  if (!value) return "--";
  return value;
};

const numberTransform = (value) => {
  if ((!value && value !== 0) || value === Infinity) return "--";
  if (isNaN(value)) value = Number(value);
  if (isNaN(value)) return "--";
  return value;
};
const roundCalc = (value, precision) => {
  return bigDecimal.round(value, precision, RoundingModes.HALF_EVEN);
};
const truncateCalc = (value, precision) => {
  const fator = Math.pow(10, precision);
  return bigDecimal.divide(Math.trunc(value * fator), fator);
};

export const volumeFormatter = (value, asNumber = false) => {
  return truncateRoundFormatter(value, 3, 3, asNumber);
};

export const moedaFormatter = (value, asNumber = false) => {
  return truncateRoundFormatter(value, 2, 2, asNumber);
};

export const roundFormatter = (value, roundTo = 3, asNumber = false) => {
  value = numberTransform(value);
  if (value === "--") return value;
  const fixedValue = roundCalc(value, roundTo);
  if (asNumber) return Number(fixedValue);
  const replace = fixedValue.replace(".", ",");
  return replace.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const truncateRoundFormatter = (
  value,
  truncateTo = 4,
  roundTo = 3,
  asNumber = false
) => {
  value = numberTransform(value);
  if (value === "--") return value;
  const trunc = truncateCalc(value, truncateTo);
  const fixedValue = roundCalc(Number(trunc), roundTo);
  if (asNumber) return Number(fixedValue);
  const replace = fixedValue.replace(".", ",");
  return replace.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const replaceZeroWith = (value, preferredChar = "--") => {
  if (value === Infinity || value === null || value === undefined)
    return preferredChar;
  if (typeof value === "string") {
    if (Number(value.replace(".", "").replace(",", "."))) {
      return value;
    }
    return preferredChar;
  }
  return value || preferredChar;
};

export const realSymbolFormatter = (value) => {
  return ["--", "-"].includes(value) ? value : `R$ ${value}`;
};
