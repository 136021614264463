<template>
  <div class="wrapper-buttons">
    <div class="wrapper-left">
      <div
        v-if="
          showButton('realizar_upload_manual', buttons) ||
          showButton('retornar_ao_calculo', buttons)
        "
        class="wrapper-left__left"
      >
        <contrato-upload-manual
          class="mb-2"
          :contrato="contrato"
          v-if="showButton('realizar_upload_manual', buttons)"
          :data="buttonData('realizar_upload_manual', buttons)"
        />
        <acao-faturamento-button
          class="mb-2"
          type="secondary"
          :contrato="contrato"
          v-if="showButton('retornar_ao_calculo', buttons)"
          :data="buttonData('retornar_ao_calculo', buttons)"
        />
      </div>
      <div
        v-if="
          showButton('reiniciar_faturamento', buttons) ||
          showButton('calcular_medicoes', buttons)
        "
        class="wrapper-left__right"
      >
        <acao-faturamento-button
          class="mb-2"
          type="secondary"
          :contrato="contrato"
          v-if="showButton('reiniciar_faturamento', buttons)"
          :data="buttonData('reiniciar_faturamento', buttons)"
        />
        <acao-faturamento-button
          class="mb-2"
          type="secondary"
          :contrato="contrato"
          v-if="showButton('calcular_medicoes', buttons)"
          :data="buttonData('calcular_medicoes', buttons)"
        />
      </div>
    </div>
    <div class="wrapper-middle">
      <acao-faturamento-button
        class="mb-2"
        :contrato="contrato"
        type="secondary-outlined"
        v-if="showButton('validar_acesso_pontos', buttons)"
        :data="buttonData('validar_acesso_pontos', buttons)"
      />
    </div>
    <div class="wrapper-right">
      <div class="wrapper-right__left">
        <efetuar-recompra
          class="mb-2"
          :contrato="contrato"
          :rateio="contrato.rateio"
          v-if="showButton('efetuar_recompra', buttons)"
          :data="buttonData('efetuar_recompra', buttons)"
        />
        <acao-desconto-garantido
          class="mb-2"
          type="secondary-fs"
          :contrato="contrato"
          v-if="showButton('calcular_desconto_garantido', buttons)"
          :data="buttonData('calcular_desconto_garantido', buttons)"
        />
        <enviar-email
          class="mb-2"
          v-if="
            showButton('enviar_email_recompra_manual', buttons) ||
            showButton('enviar_email_calculo_finalizado', buttons)
          "
          :contrato="contrato"
          :dataEmail="dataEmail"
          :typeEmail="TYPE_EMAIL"
        />
      </div>
      <div class="wrapper-right__right">
        <acao-faturamento-button
          class="mb-2"
          :contrato="contrato"
          v-if="showButton('assumir_faturamento', buttons)"
          :data="buttonData('assumir_faturamento', buttons)"
        />
        <acao-faturamento-button
          class="mb-2"
          :contrato="contrato"
          v-if="showButton('finalizar_faturamento', buttons)"
          :data="buttonData('finalizar_faturamento', buttons)"
        />
        <acao-faturamento-button
          class="mb-2"
          :contrato="contrato"
          v-if="showButton('concluir_calculo', buttons)"
          :data="buttonData('concluir_calculo', buttons)"
          :contratosFilhosRedis="contratosFilhosRedis"
        />
      </div>
    </div>
  </div>
</template>
<script>
import OverlayLoader from "@/components/OverlayLoader.vue";
import AcaoDescontoGarantido from "@/components/contratos/AcaoDescontoGarantido.vue";
import AcaoFaturamentoButton from "@/components/contratos/AcaoFaturamentoButton.vue";
import ContratoUploadManual from "@/components/contratos/ContratoUploadManual.vue";
import EnviarEmail from "@/components/contratos/EnviarEmail.vue";
import EnvioEmailRecompra from "@/components/contratos/EnvioEmailRecompra.vue";
import ExtratoResumidoCalculoFinalizado from "@/components/contratos/ExtratoResumidoCalculoFinalizado.vue";
import EfetuarRecompra from "@/components/recompra/EfetuarRecompra.vue";

export default {
  props: {
    buttons: Array,
    contrato: Object,
    dataEmail: Object,
    contratosFilhosRedis: Object,
    titleModalEmail: String,
    recompraAnalisePrecoUnico: Object,
    recompraAnaliseUnicoRateio: Array,
    recompraAnalisePrecoDiferente: Array,
    tipoRecompra: String,
  },
  data() {
    return {
      payloadConcluirCalculo: [],
      concluirIsValid: true,
    };
  },
  methods: {
    showButton(acao, buttons) {
      return buttons.filter((b) => b.acao == acao).length > 0;
    },
    buttonData(acao, buttons) {
      return buttons.filter((b) => b.acao == acao)[0];
    },
  },
  computed: {
    TYPE_EMAIL() {
      if (this.showButton("enviar_email_calculo_finalizado", this.buttons)) {
        return "calculo_finalizado";
      }
      if (this.showButton("enviar_email_recompra_manual", this.buttons)) {
        return "recompra_manual";
      }
    },
  },
  components: {
    EnviarEmail,
    OverlayLoader,
    EfetuarRecompra,
    EnvioEmailRecompra,
    ContratoUploadManual,
    AcaoFaturamentoButton,
    AcaoDescontoGarantido,
    ExtratoResumidoCalculoFinalizado,
  },
};
</script>

<style lang="scss" scoped>
.wrapper-buttons {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px 0;
  min-height: 60px;

  @include mq-value(890px) {
    flex-direction: column;
    width: 100%;
  }
}

.wrapper-left {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;

  @include mq-value(1350px) {
    flex-direction: column;
    width: 300px;
  }
  @include mq-value(890px) {
    flex-direction: column;
    width: 100%;
  }

  &__left {
    width: 100%;
    max-width: 300px;
    padding: 0 20px;

    @include mq-value(1350px) {
      padding: 0;
      width: 100%;
      min-width: 300px;
    }

    @include mq-value(890px) {
      min-width: 10px;
      max-width: 100%;
      width: 100%;
    }
  }

  &__right {
    max-width: 300px;
    width: 100%;
    @include mq-value(890px) {
      width: 100%;
      max-width: 100%;
      min-width: 10px;
    }
  }
}

.wrapper-middle {
  width: 30%;
  display: flex;
  margin-left: 20px;
  box-sizing: border-box;
  justify-content: center;

  @include mq-value(1350px) {
    width: 300px;
    margin: 0 20px;
    flex-direction: column;
  }

  @include mq-value(890px) {
    width: 100%;
    margin-left: 0;
    max-width: 100%;
    flex-direction: column;
  }
}

.wrapper-right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;

  @include mq-value(1350px) {
    flex-direction: column;
    width: 300px;
  }

  @include mq-value(890px) {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }

  &__left {
    width: 100%;
    max-width: 300px;
    padding: 0 20px;

    @include mq-value(1350px) {
      padding: 0;
      min-width: 300px;
    }

    @include mq-value(890px) {
      min-width: 10px;
      width: 100%;
      max-width: 100%;
    }
  }

  &__right {
    width: 100%;
    max-width: 300px;

    @include mq-value(890px) {
      width: 100%;
      min-width: 10px;
      max-width: 100%;
    }
  }
}
</style>
