import { EMPTY } from "@/filters/formatters";
import ContratoNaoImportadoModel from "@/models/faturamento/ContratoNaoImportadoModel";

export default class TableContratoNaoImportadoModel extends ContratoNaoImportadoModel {
  getTableFields() {
    return new Array(
      {
        name: "empresa",
        title: "Apelido",
        width: "300px",
        formatter(value) {
          if (!value) return "--";
          if (value) {
            return EMPTY(value.apelido);
          }
        },
      },
      {
        name: "codigo_contrato",
        title: "Contrato",
        width: "100px",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "codigo_contrato_referencia",
        title: "Contrato Referência",
        width: "100px",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "rateio",
        title: "Rateio",
        width: "100px",
        formatter(value) {
          return value ? "Sim" : "Não";
        },
      }
    );
  }
}
