<template>
  <div class="navigation-item">
    <router-link
      tag="div"
      :to="url"
      class="navigation-item__fixed menu-icons"
      :class="{ 'show-navigation': !getNavigationShow }"
    >
      <slot name="fixed" />
    </router-link>
    <div data-cy="div-alternar_item-navigation" @click="toggle">
      <transition name="slide">
        <router-link
          tag="div"
          :to="url"
          v-if="getNavigationShow"
          class="navigation-item__slider"
        >
          <slot name="slider" />
        </router-link>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getNavigationShow"]),
  },
  methods: {
    toggle() {
      this.$store.dispatch("toggleNavigation");
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation-item {
  display: flex;
  min-height: 42px;
  align-items: center;
  position: relative;
  @include ml-hd {
    min-height: responsive(42px);
  }
  .menu-icons {
    color: #2a2a2a;
  }
  &:hover &__fixed {
    background: $color--primary;
    color: $color--white;
    cursor: pointer;
  }
  &:hover &__slider {
    background: $color--primary;
    color: $color--white;
    cursor: pointer;
  }

  &__fixed {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 70px;
    min-width: 70px;
    min-height: 42px;
    background: $color--white;
    position: relative;
    z-index: 999;
    transition: border-radius 0.3s;
    @include ml-hd {
      max-width: responsive(70px);
      min-width: responsive(70px);
      min-height: responsive(42px);
    }
  }
  &__slider {
    width: 195px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-left: -10px;
    padding-left: 10px;
    height: 100%;
    min-height: 42px;
    border-radius: 0 12px 12px 0;
    position: relative;
    z-index: 998;
    white-space: nowrap;
    background: $color--white;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 1px;
    @include ml-hd {
      width: responsive(195px);
      margin-right: responsive(15px);
      min-height: responsive(42px);
      border-radius: 0 8px 8px 0;
      font-size: responsive(16px);
    }
  }

  .router-link-active {
    background: $color--primary;
    color: $color--white;
  }
}
.show-navigation {
  width: 50px;
  margin-right: 10px;
  border-radius: 0 12px 12px 0;
  @include ml-hd {
    border-radius: 0 9px 9px 0;
  }
}

.slide-enter-active {
  transition: all 0.5s;
}
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter, .slide-leave-to
/* .slide-fade-leave-active em versões anteriores a 2.1.8 */ {
  transform: translateX(-215px);
}
</style>
