<template>
  <div>
    <label for="frequenciaEmHorasField">
      Intervalo de Execução
      <span class="required-markup">*</span>
    </label>
    <select
      v-model="model.content"
      id="frequenciaEmHorasField"
      class="form-select border-intervalo"
      :disabled="disabled"
    >
      <option
        v-for="(intervalo, i) in intervalosList"
        :value="intervalo"
        :key="i"
      >
        {{ intervalo }}h
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    modelProp: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: {
        name: "frequenciaEmHoras",
        content: "",
      },
    };
  },
  computed: {
    intervalosList() {
      const intervalos = [];
      for (var i = 1; i <= 24; i++) {
        intervalos.push(i);
      }
      return intervalos;
    },
  },
  watch: {
    modelProp: {
      immediate: true,
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-select {
  width: 235px;
  border-radius: 10px;
  border: 1px solid #b4b7ba;
}
</style>
