import { render, staticRenderFns } from "./Faturamento.vue?vue&type=template&id=438007cb&scoped=true&"
import script from "./Faturamento.vue?vue&type=script&lang=js&"
export * from "./Faturamento.vue?vue&type=script&lang=js&"
import style0 from "./Faturamento.vue?vue&type=style&index=0&id=438007cb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "438007cb",
  null
  
)

export default component.exports