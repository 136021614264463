<template>
  <div>
    <div class="card-container">
      <div class="card-icon">
        <img :src="`${icon}`" class="my-icon" />
      </div>
      <div class="card-message">
        <p>{{ title }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    icon: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";

.card-container {
  height: 150px;
  max-width: 150px;
  width: 150px;
  border-radius: 27px;
  background: $color--blue-dark;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.card-container:hover {
  opacity: 0.9;
  margin-top: -3px;
  transition: 0.2s ease-in-out;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}

.card-message {
  color: $color--primary-white;
  font-weight: 700;
  line-break: strict;
  text-align: center;
}

.card-icon {
  padding: 20px;
}

.my-icon {
  height: 50px;
}
</style>