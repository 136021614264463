<template>
  <div class="page-exportador">
    <field-search
      ref="fieldSearch"
      @getSearchValue="getSearchValue"
      style="margin-bottom: 30px; width: 100%"
      :placeholder="'Busque por Apelido ou Contrato'"
      data-cy="field-search_procurar_exportador-pages-components-exportador"
    />
    <exportador-table
      :model="TABLE_MODEL"
      ref="exportadorTable"
      :activeTab="activeTab"
      @changeTab="(value) => (activeTab = value)"
    />
  </div>
</template>

<script>
import ExportadorTable from "@/pages/exportador/components/ExportadorTable.vue";
// Apenas nosso Senhor e Salvador sabe o motivo, mas aquele import tem que ficar em cima de todos
import FieldSearch from "@/components/fields/FieldSearch.vue";
import TableExportadorDescontoGarantidoModel from "@/models/tablesModels/TableExportadorDescontoGarantidoModel";
import TableExportadorFlatModel from "@/models/tablesModels/TableExportadorFlatModel";
import TableExportadorFlexModel from "@/models/tablesModels/TableExportadorFlexModel";
import TableExportadorRecompraModel from "@/models/tablesModels/TableExportadorRecompraModel";

export default {
  components: {
    FieldSearch,
    ExportadorTable,
  },
  data() {
    return {
      activeTab: "FLEX",
    };
  },
  computed: {
    TABLE_MODEL() {
      return {
        FLEX: TableExportadorFlexModel,
        FLAT: TableExportadorFlatModel,
        RECOMPRA: TableExportadorRecompraModel,
        DESCONTO_GARANTIDO: TableExportadorDescontoGarantidoModel,
      }[this.activeTab];
    },
  },
  methods: {
    async getSearchValue(value) {
      await this.$refs.exportadorTable.getData(value);
    },
  },
};
</script>
