<template>
  <div class="page-divergencias-billing-wbc">
    <page-panel>
      <template #header>
        <div class="page-header">
          <span>Divergências Billing x WBC</span>
          <div>
            <field-search
              @getSearchValue="setFiltro"
              placeholder="Busque por Contrato ou Apelido"
              :customStyle="{ 'margin-top': 0 }"
            />
          </div>
        </div>
      </template>
      <template #body>
        <div
          class="page-divergencias-billing-wbc__body"
          v-if="contratos.length === 0"
        >
          <span>
            Não existe nenhuma divergência de cadastrados e contratos no WBC
          </span>
        </div>
        <div v-else>
          <overlay-loader v-if="loading" />
          <template v-else>
            <contrato-divergente-billing-WBC
              :contrato="contrato"
              :itemIndex="contratoIndex"
              :key="`contrato_divergente_${contratoIndex}`"
              v-for="(contrato, contratoIndex) in contratos"
              @handleIcon="contrato.opened = !contrato.opened"
              @sincronizar="sincronizarContrato"
            />
          </template>
          <div class="mt-3 mb-4">
            <pagination
              @page="setPage"
              :info="pagination"
              @perPage="setPerPage"
              v-if="pagination && contratos.length > 0"
            />
          </div>
        </div>
      </template>
    </page-panel>
  </div>
</template>

<script>
import { CONFIRMATION } from "@/constants/strings";
import PagePanel from "@/components/panels/PagePanel.vue";
import OverlayLoader from "@/components/OverlayLoader.vue";
import AlteracoesService from "@/services/AlteracoesService";
import FieldSearch from "@/components/fields/FieldSearch.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import ContratoDivergenteBillingWBC from "./components/ContratoDivergenteBillingWBC.vue";
export default {
  data() {
    return {
      loading: false,
      contratos: new Array(),
      pagination: null,
      filters: {
        page: 0,
        size: 10,
        filtro: "",
      },
    };
  },
  mounted() {
    this.getContratos();
  },
  methods: {
    setPage(page) {
      this.filters.page = page;
      this.getContratos();
    },
    setPerPage(size) {
      this.filters.size = size;
      this.getContratos();
    },
    setFiltro(filtro) {
      this.filters.filtro = filtro;
      this.filters.page = 0;
      this.getContratos();
    },
    getContratos(needsLoadingActivation = true) {
      if (needsLoadingActivation) this.loading = true;
      const { page, size, filtro } = this.filters;
      AlteracoesService.list({
        page,
        size,
        ...(filtro ? { filtro } : {}),
      })
        .then(({ data }) => {
          this.contratos = data.content;
          this.pagination = data;
        })
        .catch((error) => this.mxHandleRequestError(error))
        .finally(() => (this.loading = false));
    },
    sincronizarContrato(value) {
      this.loading = AlteracoesService.atualizarWbc(value)
        .then(() => {
          this.mxToggleToast({ message: CONFIRMATION.ALTERACOES_WBC.CREATED });
          this.getContratos(false);
        })
        .catch((error) => {
          if (
            error.response.status === 422 &&
            error.response.data.status === 422
          ) {
            this.mxToggleErrorMessageAlert(error.response.data.message);
          } else {
            this.mxHandleRequestError(error);
          }
          this.loading = false;
        });
    },
  },
  components: {
    PagePanel,
    Pagination,
    FieldSearch,
    OverlayLoader,
    ContratoDivergenteBillingWBC,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_colors.scss";

.page-divergencias-billing-wbc {
  padding-top: 24px;
  &__body {
    padding: 30px;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
