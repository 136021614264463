import Services from "./Services";
import DemonstrativoModel from "@/pages/hot-site/models/DemonstrativoModel";

class DemonstrativoService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  getDemonstrativo(id) {
    this._url = `/${id}`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get();
        if (!response.data) {
          resolve(response);
        }

        const data = new DemonstrativoModel(response.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  patchSituacao(contratoId, payload) {
    this._url = `/${contratoId}`;
    return this.patch(payload);
  }

   patchSituacaoDG(contratoId, payload) {
    this._url = `/desconto-garantido/${contratoId}`;
    return this.patch(payload);
  }

  redistribuirDemonstrativo(contratoId, payload) {
    this._url = `/${contratoId}/redistribuicao?contratoId=${contratoId}`;
    return this.put(payload);
  }

  getHotsiteRecompraPrecoUnicoAnalise(payload) {
    this._url = `/${payload.contratoId}/recompra/preco-unico/analise`;
    return this.get(payload);
  }

  getHotsiteRecompraPrecosDiferentesAnalise(payload) {
    this._url = `/${payload.contratoId}/recompra/precos-diferentes/analise`;
    return this.get(payload);
  }

  getHotsiteRecompraPrecoUnicoCondicao(payload) {
    this._url = `/${payload.contratoId}/recompra/preco-unico/condicao-de-preco`;
    return this.get(payload);
  }

  getHotsiteRecompraBuscarRateio(payload) {
    this._url = `/${payload.contratoId}/recompra/buscar/rateio`;
    return this.get(payload);
  }

  postHotsiteEfetuarRecompraPrecoUnico(id, payload) {
    this._url = `/${id}/recompra/preco-unico/efetuar`;
    return this.post(payload);
  }

  postHotsiteEfetuarRecompraPrecosDiferentes(id, payload) {
    this._url = `/${id}/recompra/precos-diferentes/efetuar`;
    return this.post(payload);
  }

  setStatusRecompra(id, payload) {
    this._url = `/${id}/recompra/status`;
    return this.patch(payload);
  }

  deleteStatusTipoRecompra(id) {
    this._url = `/${id}/recompra/status`;
    return this.delete({});
  }
}

export default new DemonstrativoService("/demonstrativo");
