<template>
  <div class="data-table">
    <div :class="{ 'card-table': card }">
      <scroll :ops="scrollConfig" style="padding-bottom: 18px">
        <div
          class="data-table__wrapper"
          :style="{
            'max-height': customWidth
              ? maxItemsToScroll
                ? GET_MAX_HEIGHT
                : maxHeight
              : maxHeight,
          }"
        >
          <table class="_table">
            <thead :class="{ 'header-sticky': headerStick }">
              <tr>
                <td
                  :style="[
                    {
                      width: fieldHeader.width
                        ? fieldHeader.width
                        : `${100 / fieldHeader.length}%`,
                    },
                  ]"
                  v-for="(fieldHeader, fieldHeaderIndex) in fields"
                  :key="`${fieldHeaderIndex}fieldHeaderIndex`"
                  :class="{ center: fieldHeader.align == 'center' }"
                  style="font-size: 16px"
                >
                  <div v-if="isFieldSlot('header-' + fieldHeader.name)">
                    <slot :name="'header-' + fieldHeader.name" />
                  </div>
                  <div v-else>
                    {{ fieldHeader.title }}
                  </div>
                </td>
              </tr>
            </thead>
            <transition-group name="list" mode="in-out" tag="tbody">
              <tr
                v-for="(row, indexRow) in data"
                :key="`${indexRow}indexRow`"
                :class="[
                  stripes ? 'stripes' : '_border-bottom',
                  lineClickable && 'pointer',
                  lineClass && `${lineClass}-${indexRow}`,
                  lineClassIndex &&
                    lineClassIndex.index === indexRow &&
                    lineClassIndex.class,
                  GET_BOLD_LINE(row),
                ]"
                @click.stop="lineClicked(row, indexRow)"
                data-cy="tbody-tr-clicked_row-table-components"
              >
                <td
                  v-for="(fieldBody, indexFieldBody) in fields"
                  :key="`${indexFieldBody}indexFieldBody`"
                  :style="`padding: ${rowPadding} 15px ${rowPadding} 10px;`"
                  :class="{ center: fieldBody.align == 'center' }"
                >
                  <div
                    v-if="isFieldSlot(fieldBody.name)"
                    :class="{ center: fieldBody.align == 'center' }"
                  >
                    <slot :name="fieldBody.name" :row="row" />
                  </div>
                  <div v-else>
                    <span v-html="renderValue(data[indexRow], fieldBody)" />
                  </div>
                </td>
              </tr>
              <tr key="tr-children-two-key">
                <td
                  v-if="data.length === 0"
                  style="text-align: center"
                  :colspan="fields.length"
                >
                  Nenhum resultado encontrado
                </td>
              </tr>
              <tr
                key="tr-children-three-key"
                v-if="footer"
                class="footer-sticky"
              >
                <td
                  :style="{
                    width: fieldHeader.width || `${100 / fieldHeader.length}%`,
                  }"
                  v-for="(fieldHeader, i) in fields"
                  :key="i"
                  :class="{ center: fieldHeader.align == 'center' }"
                  style="font-size: 16px"
                >
                  <div
                    style="font-weight: 600"
                    v-if="isFieldSlot(`footer-${fieldHeader.name}`)"
                  >
                    <slot :name="`footer-${fieldHeader.name}`" />
                  </div>
                  <div v-else />
                </td>
              </tr>
            </transition-group>
          </table>
        </div>
      </scroll>
    </div>
    <div v-if="pagination && !!paginationData" class="box-pagination mt-3 mb-4">
      <pagination
        @perPage="setPerPage"
        @page="setPage"
        :info="paginationData"
      />
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pagination/Pagination.vue";

export default {
  name: "DataTable",
  components: {
    pagination,
  },
  props: {
    paginationData: {
      type: Object,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    data: {
      //type:Array,
      require: true,
    },
    maxItemsToScroll: {
      type: [Number, String],
      required: false,
    },
    customWidth: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    fields: {
      type: Array,
      require: true,
    },
    stripes: {
      typeof: Boolean,
      require: false,
      default: false,
    },
    lineClickable: {
      typeof: Boolean,
      require: false,
      default: false,
    },
    rowPadding: {
      typeof: String,
      require: false,
      default: false,
    },
    card: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: "100%",
    },
    headerStick: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    lineClass: {
      type: String,
      required: false,
      default: "",
    },
    lineClassIndex: {
      type: Object,
      required: false,
    },
  },
  computed: {
    GET_MAX_HEIGHT() {
      /* 36 stands for "16px font-size + 150% line-height + 12px padding of <td> inside <tr>" */
      return 36 * (this.maxItemsToScroll + 1) + "px";
    },
  },
  data() {
    return {
      scrollConfig: {
        rail: { opacity: 0.5 },
        bar: { keepShow: true },
      },
    };
  },
  methods: {
    GET_BOLD_LINE(row) {
      if (row.isBoldStyle) return "fw-bolder-700";
    },
    setPage(page) {
      this.$emit("setPage", page);
    },
    setPerPage(value) {
      this.$emit("setPerPage", value);
    },
    renderValue(row, fieldBody) {
      return this.hasGetter(fieldBody)
        ? this.callGetter(fieldBody, row)
        : this.hasFormatter(fieldBody)
        ? this.callFormatter(fieldBody, row)
        : row[fieldBody.name];
    },
    isFieldSlot(fieldName) {
      return typeof this.$scopedSlots[fieldName] !== "undefined";
    },
    hasGetter(item) {
      return typeof item.getValue === "function";
    },
    hasFormatter(item) {
      return typeof item.formatter === "function";
    },
    callGetter(fieldBody, row) {
      return fieldBody.getValue(row);
    },
    callFormatter(fieldBody, row) {
      if (!this.hasFormatter(fieldBody)) return;
      if (typeof fieldBody.formatter === "function") {
        return fieldBody.formatter(this.getObjectValue(row, fieldBody.name));
      }
    },
    getObjectValue(object, path, defaultValue) {
      let obj = object;
      if (path.trim() != "") {
        let keys = path.split(".");
        keys.forEach((key) => {
          if (
            obj !== undefined &&
            obj !== null &&
            typeof obj[key] !== "undefined" &&
            obj[key] !== null
          ) {
            obj = obj[key];
          } else {
            obj = defaultValue;
            return;
          }
        });
      }
      return obj;
    },
    lineClicked(data, index) {
      if (this.lineClickable) {
        this.$emit("lineClicked", data, index);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.data-table {
  margin: 0;
  padding: 0;
  &__wrapper {
    padding-bottom: 25px;
  }
  ._table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    thead {
      background: $color--primary-white;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 150%;
      letter-spacing: 1px;
      color: #2a2a2a;

      @include ml-hd {
        font-size: responsive(1rem);
      }
      td {
        padding: 15px 15px 3px 10px;
        margin: 5px;
        border-bottom: #2a2a2a 1px solid;
      }
    }
    tbody {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 1px;
      color: #2a2a2a;
      @include ml-hd {
        font-size: responsive(1rem);
      }
      td {
        padding: 6px 10px;
      }
    }
  }
  .stripes {
    &:nth-child(even) {
      background-color: #f2edeb;
    }
  }
  ._border-bottom {
    border-bottom: solid 1px #eee;
  }
}
.pointer {
  cursor: pointer;
}
.box-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px !important;
}
.card-table {
  padding: 15px;
  border-radius: 24px;
  border: 1px solid #b4b7ba;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}

.header-sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 2;
}
.footer-sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  z-index: 2;

  background: $color--primary-white;
  margin-top: -50px;
  td {
    border-top: #2a2a2a 1px solid;
    padding-top: 20px !important ;
    padding-bottom: 20px !important ;
  }
}

.fw-bolder-700 {
  font-weight: 700 !important;
}

.list-enter-active,
.list-leave-active {
  transition: 0.6s opacity;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
