<template>
  <div>
    <modal-base width="65vw" @closeModal="closeModal()">
      <template #header>
        Alteração de Solicitado Líquido - Arquivos Adicionados
      </template>
      <template #body>
        <div class="section-arquivo">
          <div class="mb-4">
            O solicitado Líquido foi alterado e foram enviados os arquivos
            abaixo como justificativa.
          </div>
          <div class="mt-2 d-flex flex-column">
            <span class="labelTitle">Arquivos Enviados</span>
            <span class="header-table mt-2">Nome dos Arquivos</span>
          </div>
          <data-table
            :stripes="true"
            :data="dataUpload"
            :headerStick="true"
            :maxItemsToScroll="10"
            :fields="visualizarUpload"
          >
            <div slot-scope="{ row }" slot="arquivo">
              <i class="icon icon-file"></i>
              {{ row.arquivo.nomeOriginal }}
            </div>
            <div
              :title="row"
              slot-scope="{ row }"
              slot="downloadSolicitadoLiquido"
            >
              <i class="icon-save_alt cursop" @click="downloadFile(row)"></i>
            </div>
          </data-table>
        </div>
      </template>
      <template #footer></template>
    </modal-base>
  </div>
</template>
<script>
import Services from "@/services/Services";
import DataTable from "@/components/tables/DataTable.vue";
import ModalBase from "@/components/modals/ModalBase.vue";
import { downloadFileFromResponseObject } from "@/utils/functionsUtils";

export default {
  props: {
    rowSelected: Object,
  },
  data() {
    return {
      arquivos: new Array(),
      dataUpload: new Array(),
      loadingModal: undefined,
      visualizarUpload: new Array(
        {
          name: "arquivo",
          width: "95%",
        },
        {
          name: "downloadSolicitadoLiquido",
          title: "",
          width: "5%",
        }
      ),
    };
  },
  mounted() {
    this.getUrlArquivos();
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    getUrlArquivos() {
      const axiosInstance = Services.getInstanceAxios({
        baseURL: this.rowSelected.url,
      });

      this.loadingModal = axiosInstance
        .get()
        .then(({ data }) => {
          this.dataUpload = data;
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    downloadFile(row) {
      const axiosInstance = Services.getInstanceAxios({
        baseURL: row.link.url,
      });

      const nomeArquivo =
        row.arquivo.nomeOriginal.split(".", 2).at(0) || "arquivo";
      const extensao = row.arquivo.nomeOriginal.split(".", 2).at(1) || "csv";

      this.loadingModal = axiosInstance
        .post("", null, { responseType: "blob" })
        .then((response) => {
          downloadFileFromResponseObject(response, nomeArquivo, extensao);
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
  },
  components: {
    ModalBase,
    DataTable,
  },
};
</script>
>
<style scoped>
.section-arquivo {
  width: 100%;
  padding: 30px;
}

.container-footer {
  width: 100%;
}

.btn-common,
.btn-margin {
  width: 190px;
  height: 35px;
}

.btn-margin {
  margin-right: 20px;
}

.btn {
  font-weight: 700;
  padding: 4px 16px;
  border-radius: 9px;
}

.labelTitle {
  font-size: 16px;
  font-weight: 400;
}

.file-description {
  word-break: break-all;
  white-space: pre-wrap;
}

.icon-file,
.icon-save_alt {
  color: #ec6623;
  font-weight: 600;
}

.icon-file {
  font-size: 1.2em;
}

.icon-save_alt {
  font-size: 1.4em;
}

.header-table {
  font-weight: 500;
}
</style>
