<template>
  <div class="page-parametrizacao">
    <page-painel class="panel-parametrizacao">
      <template #header>
        <span>{{ currentNamePage }}</span>
      </template>
      <template #body>
        <div class="page-parametrizacao__body">
          <router-view />
        </div>
      </template>
    </page-painel>
  </div>
</template>
<script>
import tusdIcon from "@/assets/icons/svg/tusd.svg";
import rocket from "@/assets/icons/svg/rocket.svg";
import receipt from "@/assets/icons/svg/receipt.svg";
import download from "@/assets/icons/svg/download.svg";
import clipboardCheck from "@/assets/icons/svg/clipboard-check.svg";

import PagePainel from "@/components/panels/PagePanel.vue";
import CardBaseParametros from "@/components/cards/cardBaseParametros.vue";

export default {
  data() {
    return {
      rocketIcon: rocket,
      clipboardCheckIcon: clipboardCheck,
      downloadIcon: download,
      receipt: receipt,
      tusd: tusdIcon,
    };
  },
  components: {
    PagePainel,
    CardBaseParametros,
  },
  computed: {
    currentNamePage() {
      return this.$route.name;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-parametrizacao {
  padding-top: 24px;
  &__body {
    padding: 30px;
  }
  .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 150px));
    grid-column-gap: 40px;
    grid-row-gap: 10px;
  }

  .panel-parametrizacao {
    min-height: 85vh;
  }
}

.button-bar-parametrizacao {
  margin-top: 30px !important;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 10px 0 10px;
  }
}
</style>
