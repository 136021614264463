<template>
  <card-base>
    <template #header>
      <span class="no-bold" v-html="SUBMERCADO_SIGLA" />
    </template>
    <template #body>
      <div class="container-submercado-card">
        <overlay-loader v-if="loading" />
        <div class="row mb-2 align-items-center">
          <div class="col col-auto">
            <b class="pld-value" :class="HAS_PLD_EDITADO ? 'hide-pld' : null">
              PLD:
              <span class="pld-span">{{ submercado.getPrecoPLD() }}</span>
            </b>
          </div>
          <div
            class="col col-auto pld-editado"
            :class="HAS_PLD_EDITADO ? 'pld-editado-on' : 'pld-editado-off'"
          >
            PLD Editado:
            <span class="pld-span">{{ submercado.getPLDEditado() }} </span>
          </div>
          <div class="col col-auto">
            <button
              id="editar-pld-button"
              v-if="
                $can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR') ||
                $can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR')
              "
              class="btn btn-primary-text fw-bold"
              @click="$emit('editarPld', submercado)"
              data-cy="button-editar_pld-pld-spread-components"
            >
              (Editar PLD)
            </button>
          </div>
        </div>
        <div class="row">
          <data-table
            :data="tableData"
            :lineClickable="
              $can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR') ||
              $can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR')
            "
            :lineClass="`spread-item`"
            @lineClicked="lineClicked"
            :fields="SubmercadoModel.getTableFields()"
          >
            <div class="table-pld" slot="descricao" slot-scope="{ row }">
              {{ row.descricao }}
            </div>
            <div class="table-pld" slot="spreadCompra" slot-scope="{ row }">
              {{ getPropertyValue(row.ultimoSpread, "compra") }}
            </div>
            <div class="table-pld" slot="spreadVenda" slot-scope="{ row }">
              {{ getPropertyValue(row.ultimoSpread, "venda") }}
            </div>
            <div class="table-pld" slot="precoCompra" slot-scope="{ row }">
              {{ getPropertyValue(row.ultimoSpread, "precoCompra") }}
            </div>
            <div class="table-pld" slot="precoVenda" slot-scope="{ row }">
              {{ getPropertyValue(row.ultimoSpread, "precoVenda") }}
            </div>
            <div class="status-slot" slot="status" slot-scope="{ row }">
              <div :class="['STATUS-DOT', STATUS_DOT_CLASS(row)]"></div>
              <span class="STATUS-DOT-TEXT">{{ row.status }}</span>
            </div>
            <div slot="detalhes" class="d-flex ms-2" slot-scope="{ row }">
              <input
                :disabled="
                  !$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR') ||
                  row.status !== 'Pendente'
                "
                type="checkbox"
                :value="row.selected"
                v-model="row.selected"
                @click.stop="selectSpreadRow(row)"
                data-cy="input-pld_submercado-pld-spread-components"
              />
            </div>
            <div class="all-checked" slot="header-detalhes">
              <input
                type="checkbox"
                @click="toggleAllSubmercado"
                v-model="allSubmercadoChecked"
                :disabled="
                  !SHOW_SELECT_ALL_SPREADS ||
                  !$can('AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR')
                "
                data-cy="input-pld_todos_submercado-pld-spread-components"
              />
            </div>
            <div
              v-if="HAS_PERMISSAO_EDITAR_APROVAR"
              class="all-checked"
              slot="header-tooltip"
            >
              <div>
                <tooltip
                  width="200px"
                  arrowAlign="up"
                  alignVertical="30px"
                  alignHorizontal="-180px"
                >
                  <img
                    slot="header"
                    alt="Informação"
                    class="icon-duvida"
                    src="@/assets/icons/svg/duvida.svg"
                  />
                  <p slot="body" class="span-pendentes">
                    Selecione os itens <span>Pendentes</span> que deseja
                    <span>aprovar.</span>
                  </p>
                </tooltip>
              </div>
            </div>
            <div v-else class="all-checked" slot="header-tooltip">
              <div>
                <tooltip
                  width="200px"
                  arrowAlign="up"
                  alignVertical="30px"
                  alignHorizontal="-180px"
                >
                  <img
                    slot="header"
                    alt="Informação"
                    class="icon-duvida"
                    src="@/assets/icons/svg/duvida.svg"
                  />
                  <p slot="body" class="span-pendentes">
                    Apenas usuários com perfil <span>Aprovador</span> podem
                    aprovar.
                  </p>
                </tooltip>
              </div>
            </div>
          </data-table>
        </div>
      </div>
    </template>
  </card-base>
</template>

<script>
import { mapGetters } from "vuex";
import { CURRENCY } from "@/filters/formatters";
import SPREAD_STATUS from "@/enums/SpreadStatusEnum";
import Tooltip from "@/components/Tooltip/Tooltip.vue";
import OverlayLoader from "@/components/OverlayLoader";
import cardBase from "@/components/cards/cardBase.vue";
import SubmercadoModel from "@/models/SubmercadoModel";
import DataTable from "@/components/tables/DataTable.vue";

export default {
  name: "CardPLDSubmercado",
  props: {
    submercado: Object,
    loading: Boolean,
  },
  data() {
    return {
      tableData: new Array(),
      allSubmercadoChecked: false,
    };
  },
  filters: {
    CURRENCY,
  },
  computed: {
    ...mapGetters(["getPermissoes"]),
    SubmercadoModel: () => SubmercadoModel,
    SHOW_SELECT_ALL_SPREADS() {
      return this.submercado.tiposEnergia.some(
        (tipoEnergia) => tipoEnergia.status === SPREAD_STATUS.PENDENTE
      );
    },
    SUBMERCADO_SIGLA() {
      const { sigla, descricao } = this.submercado;
      return `Submercado: <b>${sigla}</b> (${descricao})`;
    },
    HAS_PLD_EDITADO() {
      const { ultimoPld } = this.submercado;
      if (!ultimoPld) return false;
      const { precoEditado } = ultimoPld;
      return precoEditado && precoEditado > 0;
    },
    HAS_PERMISSAO_EDITAR_APROVAR() {
      if (!this.getPermissoes) return false;
      return this.getPermissoes.includes(
        "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR"
      );
    },
  },
  watch: {
    submercado: {
      immediate: true,
      deep: true,
      handler() {
        this.tableData = this.submercado.tiposEnergia;
      },
    },
  },
  methods: {
    selectSpreadRow(row) {
      row.selected = !row.selected;
      const CHECK_ALL_SPREAD_ROW = !this.submercado.tiposEnergia.some(
        (tipoEnergia) =>
          !tipoEnergia.selected && tipoEnergia.status === SPREAD_STATUS.PENDENTE
      );
      this.allSubmercadoChecked = CHECK_ALL_SPREAD_ROW;
    },
    getPropertyValue(property, value = null) {
      let _value;
      if (!value) {
        _value = !!property ? property : "";
      } else {
        _value = !!property ? property[value] : "";
      }
      return CURRENCY(_value, "--");
    },
    lineClicked(tipoEnergia) {
      this.$emit("lineClicked", this.submercado, tipoEnergia);
    },
    STATUS_DOT_CLASS(row) {
      return `STATUS-DOT-${row.status.toUpperCase()}`;
    },
    toggleAllSubmercado() {
      this.allSubmercadoChecked = !this.allSubmercadoChecked;
      this.tableData = this.tableData.map((tipoEnergia) => {
        tipoEnergia.selected = this.allSubmercadoChecked;
        return tipoEnergia;
      });
    },
  },
  components: {
    Tooltip,
    cardBase,
    DataTable,
    OverlayLoader,
  },
};
</script>

<style lang="scss" scoped>
.container-submercado-card {
  border: none;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  padding: 0px 10px 0px 10px;
}

.no-bold {
  font-weight: normal;
}

.table-pld {
  font-size: 16px !important;
}

.btn-primary-text {
  display: flex;
  font-size: 12px;
  padding-top: 0px;
  padding-left: 0px;
}

.all-checked {
  margin-left: 0.4rem;
}

.hide-pld,
.hide-pld span {
  display: flex;
  font-size: 12px;
  color: $color--black-overlay;
}

.span-pendentes span {
  font-weight: 600;
}

.hide-pld span {
  text-decoration: Line-through;
}

.pld-value {
  display: flex;
  color: black;
  font-size: 12px;
}

.pld-editado {
  width: 170px;
  font-size: 12px;
  font-weight: bold;
}

.pld-editado-on {
  color: $color--primary-font;
}

.pld-editado-off {
  color: $color--black-overlay;
}

.pld-span {
  margin-left: 0.3rem;
}

.status-slot {
  display: flex;
  align-items: center;
}

.STATUS-DOT {
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: #c4c4c4;
}

.STATUS-DOT-APROVADO {
  background-color: #2ed47a;
}

.STATUS-DOT-EXPIRADO {
  background-color: #ec2f23;
}

.STATUS-DOT-PENDENTE {
  background-color: #ffb946;
}
</style>
