export const CONFIRMATION = {
  FATURAMENTO: {
    CANT_FOLLOW:
      "Você não pode seguir com essa ação pois o faturamento foi assumido por outra pessoa",
  },
  PROINFA: {
    UPDATE: "Atualizado com sucesso!",
  },
  CALCULO_MEDICOES: {
    CREATED: "Medições calculadas com sucesso!",
    RECALCULATE_FATURAMENTO: (ultimoStatus) =>
      `Esse faturamento se encontra no status - <b>${ultimoStatus}</b> - e será recalculado, deseja continuar ?`,
  },
  ACAO_FATURAMENTO_BUTTON: {
    CONTINUE_BILLING:
      "Ao confirmar, você assumirá o faturamento deste contrato. Deseja continuar ?",
    FINALIZED_BILLING:
      "Ao confirmar, o faturamento deste contrato será <b>finalizado e disponibilizado para Exportação.</b>",
    STATUS_AFTER_SALES:
      "Ao confimar, este faturamento terá seu status alterado para '<b>Em Análise pelo Pós-Venda</b>'. Deseja continuar?",
  },
  HORAS_FALTANTES: {
    UPDATE: "Atualizado com sucesso!",
  },
  IMPORTACAO: {
    CREATED:
      "Processo de importação dos arquivos de medição e cálculo da CCEE foi iniciado.",
    ERROR: {
      409: "O processo de importação dos arquivos de medição e cálculo da CCEE já está em andamento.",
      422: "Não existe um parâmetro de Rotina de Importação definido.",
    },
  },
  RECOMPRA: {
    EXECUTE: "Recompra executada com sucesso",
  },
  EMAIL: {
    SENT: "Email enviado com sucesso!",
    NO_CONTENT: "Corpo do e-mail vazio!",
    NO_ADD: "Nenhum e-mail adicionado!",
  },
  EXPORTADOR_TABLE: {
    BOLETAS_SUCCESS: "Boletas integradas com sucesso",
    MONTANTE_SUCCESS: "Montantes exportados com sucesso",
  },
  SINCRONIZACAO_EMPRESA: {
    EMPRESAS_SYNC: "Processo de sincronização finalizado!",
    SELECTED_EMPRESA: "Selecione pelo menos uma empresa!",
  },
  PRAZO_FINAL_VIGENCIA_SPREAD: {
    UPDATE: "Prazo final da vigência atualizado com sucesso",
  },
  APROVAR_SPREAD: {
    CREATED: "Spread(s) aprovado(s) com sucesso",
    UPDATE: "Spread(s) atualizado(s) com sucesso",
  },
  PLD_SPREAD: {
    UPDATE: "PLD atualizado com sucesso",
  },
  PONTOS_MEDICAO: {
    UPDATE: "Atualizado com sucesso!",
    CREATED: "Medições calculadas com sucesso!",
  },
  POS_VENDA: {
    CREATED: "Pós-venda vinculado com sucesso.",
  },
  SOLICITADO_LIQUIDO: {
    CREATED: "Solicitado Líquido editado com sucesso!",
    UPDATE: "Solicitado Líquido aprovado com sucesso!",
    DELETE: "Solicitado reprovado!",
  },
  CURTO_PRAZO: {
    UPDATE: "Proposta de curto prazo alterado com sucesso!",
    VOLUME_GREATER_THAN_ZERO:
      "O Volume Considerado da Proposta deve ser maior que zero.",
    VOLUME_DISTRIBUTED_DIFFERENT:
      "O volume total distribuido é diferente do volume considerado.",
    RELATIONSHIP_EXECUTE_PROPOSAL: `Devido às condições de mercado esta proposta deverá ser tratada diretamente 
    com seu Executivo de Relacionamento. 
    <b>Deseja solicitar esta proposta?</b>`,
  },
  MODAL_FATURADO_MANUALMENTE_WBC: {
    UPDATE: "Contrato Faturado Manualmente com sucesso!",
  },
  UPLOAD_MANUAL: {
    DOWNLOAD: "Arquivo baixado com sucesso!",
    DELETE: "Arquivo deletado com sucesso!",
  },
  TUSD: {
    UPDATE: "TUSD alterado com sucesso!",
  },
  UPLOAD_LOTE: {
    UPDATE: "Calcular Importação em lote feita com sucesso!",
    UPDATE_INICIADO: "Importação em lote iniciada com sucesso!",
  },
  ROTINA_IMPORTACAO: {
    UPDATE: "Rotina Importação criada com sucesso!",
  },
  ALTERACOES_WBC: {
    CREATED: "Contrato sincronizado com sucesso.",
  },
};

export const MAX_FILE_SIZE = 52428800;
export const ERROR = {
  NO_FILES_SELECTED: "Selecione pelo menos um arquivo!",
  VALUES_INVALID: "Verifique os valores inseridos e tente novamente.",
  VIGENCIA_DATE_INVALID:
    "Valor inválido para o período da vigência, a data precisa ser posterior a data atual",
  ASSOCIATE_POS_VENDA: "Pos-Venda já associado!",
  MAX_FILE_SIZE_MB: `Arquivo inválido, tamanho excede o limite de ${parseInt(
    MAX_FILE_SIZE / 1000 / 1000
  )} mb`,
  MAX_FILE_SIZE_NAME: "Nome do arquivo ultrapassa o permitido: 100 caracteres",
  WRONG_FILE_EXTENSION: "Arquivo inválido, extensão não permitida",
};

export const HTTP_RESPONSE = {
  GENERIC: {
    400: "Ocorreu um erro ao processar sua solicitação!",
    403: "Sua sessão expirou, faça novamente o login para continuar.",
    404: "Ocorreu um erro ao processar sua solicitação: recurso não encontrado",
    409: "Ocorreu um erro ao processar sua solicitação: entidade já cadastrada",
    500: "Ocorreu um erro ao processar sua solicitação: serviço indisponível",
    503: "Ocorreu um erro ao processar sua solicitação: O serviço solicitado não está disponível no momento",
  },
  WBC_CARD: {
    ERROR: {
      503: "Ocorreu um erro ao processar sua solicitação: Não foi possível listar os contratos alterados somente no WBC",
    },
  },
  LOGIN: {
    ERROR: {
      401: "Login inválido! Usuário não encontrado, tente novamente.",
      403: "Login inválido! Você não possui permissões para acessar o Billing Matrix.",
    },
  },
  FATURAMENTO_RESUMO: {
    ERROR: {
      400: "Os parâmetros para listagem dos recursos estão incorretos!",
      401: "Sua sessão expirou, faça novamente o login para continuar",
    },
  },
  NAO_IMPORTADOS: {
    ERROR: {
      400: "Os parâmetros para listagem dos recursos estão incorretos!",
      401: "Sua sessão expirou, faça novamente o login para continuar",
    },
  },
  STATUS_FATURAMENTO: {
    ERROR: {
      204: "Nenhum status encontrado!",
    },
  },
  STATUS_GRUPO: {
    ERROR: {
      204: "Nenhum grupo de status encontrado!",
    },
  },
  PLD_SPREAD: {
    SUCCESS: {
      UPDATE: "PLD preço editado!",
    },
    ERROR: {
      204: "Não existem submercados cadastrados!",
      400: "Novo PLD deve ser maior que R$0,00",
    },
  },
  PLD_SPREAD_VIGENCIA: {
    SUCCESS: {
      CREATE: "Vigencia criada!",
    },
    ERROR: {
      404: "Não existe vigências ativas!",
      422: "Vigência deve ser maior que 10 minutos da data atual.",
    },
  },
  PLD_SPREAD_APROVACAO: {
    SUCCESS: {
      CREATE: "Spread aprovado!",
    },
    ERROR: {
      204: "Todos os ids informados não existem!",
    },
  },
  PONTOS_MEDICAO: {
    SUCCESS: {
      UPDATE: "Contrato e ponto associado!",
    },
    ERROR: {
      204: "Nenhum contrato foi encontrado!",
      400: "Os parâmetros para associação dos recursos estão incorretos!",
    },
  },
  PROINFA: {
    SUCCESS: {
      UPDATE: "Ponto de medição com proinfa atualizado!",
    },
    ERROR: {
      204: "Nenhum ponto de medição encontrado!",
      400: "Os parâmetros da requisição estão incorretos!",
      404: "Ponto de medição não encontrado!",
    },
  },
  EMPRESA: {
    SUCCESS: {
      CREATE: "Um novo recurso de empresa foi criado!",
    },
    ERROR: {
      204: "Recurso de empresas não encontrado!",
      400: "Os parâmetros para criação do recurso estão incorretos!",
      404: "Recurso de empresa não encontrado!",
    },
  },
  ENVIAR_EMAIL: {
    SUCCESS: {
      CREATE: "Email de Recompra Enviado com sucesso!",
    },
    ERROR: {
      400: "Erro ao enviar email! Tente novamente",
    },
  },
  CALCULO_FATURAMENTO: {
    SUCCESS: {
      CREATE: "Operação realizada com sucesso!",
    },
    ERROR: {
      400: "Os parâmetros para finalização do recurso estão incorretos!",
      404: "Alcadas não foram encontradas!",
      406: "Permissão negada para executar este serviço!",
    },
  },
  ARQUIVO: {
    SUCCESS: {
      CREATE: "Arquivo enviado com sucesso!",
    },
  },
  ALCADA_APROVACAO: {
    UPDATE: "Parâmetros de Alçada de Aprovação atualizados com sucesso.",
    SUCCESS: {
      CREATE: "Um novo recurso de alçada foi criado!",
      UPDATE: "A alçada foi atualizada com sucesso!",
    },
    ERROR: {
      204: "Não foi encontrado nenhum recurso de alçada parametrizado!",
      404: "Recurso de alçada não encontrado!",
    },
  },
  HORAS_FALTANTES: {
    SUCCESS: {
      UPDATE: "Adicionado horas complementares com sucesso!",
    },
    ERROR: {
      404: "Nenhum recurso de horas faltantes foi encontrado!",
      400: "Nenhum valor de horas faltates encontrado!",
    },
  },
  UPLOAD_MANUAL: {
    SUCCESS: {
      CREATED: "Upload feito com sucesso!",
    },
    ERROR: {
      204: "Nenhum recurso de contrato encontrado!",
      400: "Não existem pontos de medição válidos para realizar o fluxo de upload",
      422: "Houve um erro no carregamento dos arquivos",
    },
  },
  EMPRESA_POS_VENDA: {
    ERROR: {
      404: "Recurso de empresa pós-venda não encontrado",
    },
  },
  FECHAMENTO: {
    SUCCESS: {
      CREATED: "Inicia rotina de fechamento faturamento de maneira assíncrona",
    },
  },
  HISTORICO_ACOES: {
    ERROR: {
      204: "Nenhuma ação foi encontrada nesse histórico",
    },
  },
  CONTATOS_EMPRESA: {
    ERROR: {
      204: "Nenhum contato de empresa encontrado!",
    },
  },
  CURTO_PRAZO: {
    ERROR: {
      404: "Não foi encontrado nenhum valor para Curto Prazo !",
    },
  },
  TUSD: {
    ERROR: {},
  },
  UPLOAD_LOTE: {
    ERROR: {
      422: "Já existe uma Importação em Lote em andamento!",
    },
  },
  USUARIO: {
    SUCCESS: {
      CREATED: "Usuario criado com sucesso!",
    },
    ERROR: {
      400: "Parametros de criação incorretos!",
    },
  },
  ROTINA_IMPORTACAO: {
    ERROR: {
      422: "A hora de início de fornecimento deve ser menor que a hora fim!",
    },
  },
  SOLICITADO_LIQUIDO: {
    ERROR: {
      422: "Contrato não possui um status válido!",
      404: "Calculo Faturamento não encontrado(a)",
    },
  },
  ALTERACOES_WBC: {
    ERROR: "Não foi possível sincronizar as informações do contrato.",
  },
  MODAL_FATURADO_MANUALMENTE_WBC: {
    ERROR: {},
  },
};
