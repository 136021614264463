export default class RotinaImportacaoEmLoteModel {
  constructor(
    {
      id,
      codigoContratoReferencia,
      codigoContrato,
      razaoSocial,
      nomeArquivo,
      codigoPonto,
      tipoClasse,
      filhos,
    } = new Object()
  ) {
    this._id = id;
    this._codigoContratoReferencia = codigoContratoReferencia;
    this._codigoContrato = codigoContrato;
    this._razaoSocial = razaoSocial;
    this._nomeArquivo = nomeArquivo;
    this._codigoPonto = codigoPonto;
    this._tipoClasse = tipoClasse;
    this._filhos = filhos;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id = null) {
    this._id = id;
  }

  get codigoContratoReferencia() {
    return this._codigoContratoReferencia ?? null;
  }

  set codigoContratoReferencia(codigoContratoReferencia = null) {
    this._codigoContratoReferencia = codigoContratoReferencia;
  }

  get codigoContrato() {
    return this._codigoContrato ?? null;
  }

  set codigoContrato(codigoContrato = null) {
    this._codigoContrato = codigoContrato;
  }

  get razaoSocial() {
    return this._razaoSocial ?? null;
  }

  set razaoSocial(razaoSocial = null) {
    this._razaoSocial = razaoSocial;
  }

  get nomeArquivo() {
    return this._nomeArquivo ?? null;
  }

  set nomeArquivo(nomeArquivo = null) {
    this._nomeArquivo = nomeArquivo;
  }

  get codigoPonto() {
    return this._codigoPonto ?? null;
  }

  set codigoPonto(codigoPonto = null) {
    this._codigoPonto = codigoPonto;
  }

  get tipoClasse() {
    return this._tipoClasse ?? null;
  }

  set tipoClasse(tipoClasse = null) {
    this._tipoClasse = tipoClasse;
  }

  get filhos() {
    return this._filhos ?? new Array();
  }

  set filhos(filhos = null) {
    this._filhos = filhos;
  }

  getData() {
    const {
      id,
      codigoContratoReferencia,
      codigoContrato,
      razaoSocial,
      nomeArquivo,
      codigoPonto,
      tipoClasse,
      filhos,
    } = this;
    return {
      id,
      codigoContratoReferencia,
      codigoContrato,
      razaoSocial,
      nomeArquivo,
      codigoPonto,
      tipoClasse,
      filhos,
    };
  }
}
