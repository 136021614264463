<template>
  <div class="page-aguardando-aprovacao-recompra-manual">
    <page-painel>
      <template #header>
        Faturamento de : <b>Apelido da Empresa - Razão social da empresa</b>
      </template>
      <template #body>
        <div class="page-aguardando-aprovacao-recompra-manual__body">
          <label>Competência: 01/2022</label>
          <contrato
            :key="contract"
            class="contract"
            v-for="contract in 5"
            :type="contract == 1 ? 'apportionment' : 'single'"
          >
            <template #components>
              <div class="container-buttons-analise">
                <button
                  class="btn-primary-full-filled"
                  data-cy="button-reiniciar_faturamento-status-pages-template-aguardando-aprovacao"
                >
                  Recalcular Faturamento
                </button>
                <div class="box-buttons">
                  <enviar-email />
                  <button
                    class="btn-primary-full-filled"
                    data-cy="button-finalizar_faturamento-status-pages-template-aguardando-aprovacao"
                  >
                    Finalizar Faturamento
                  </button>
                </div>
              </div>
            </template>
          </contrato>
          <historico-acoes />
        </div>
      </template>
    </page-painel>
  </div>
</template>
<script>
import Contrato from "@/components/contratos/Contrato.vue";
import ContratoUploadManual from "@/components/contratos/ContratoUploadManual.vue";
import EnviarEmail from "@/components/contratos/EnviarEmail.vue";
import HistoricoAcoes from "@/components/historico-acoes/HistoricoAcoes.vue";
import PagePainel from "@/components/panels/PagePanel.vue";

export default {
  components: {
    Contrato,
    PagePainel,
    EnviarEmail,
    HistoricoAcoes,
    ContratoUploadManual,
  },
};
</script>
<style lang="scss" scoped>
.page-aguardando-aprovacao-recompra-manual {
  padding-top: 24px;
  &__body {
    padding: 2px 40px;
  }
  .contract {
    margin: 20px 0 20px 0;
  }
  .container-buttons-analise {
    display: flex;
    width: 100%;
    height: 10%;
    button {
      width: 250px;
      padding: 8px;
      border-radius: 12px;
      font-weight: bold;
    }
  }
  .box-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    width: 80%;
  }
}
</style>
