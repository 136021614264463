<template>
  <div @click="send" class="send">
    <overlay-loader v-if="loading" />
    <div class="line-send">
      <svg
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="icon-send"
      >
        <path
          d="M10.2184 11.6667L15.4483 19.0001L22.1725 1.00002L2 7.00005L10.2184 11.6667ZM10.2184 11.6667L16.1955 6.33338"
          stroke="#2A2A2A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div class="text-send">Enviar xls por e-mail</div>
    </div>
  </div>
</template>
<script>
import FaturamentoService from "@/services/FaturamentoService";

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    send() {
      this.loading = true;
      this.$emit("enviarXls");

      FaturamentoService.extrator()
        .then(() => {
          this.loading = false;
          this.mxToggleToast({
            message: "Xls enviado com sucesso.",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (response.data.message) {
            this.mxToggleErrorMessageAlert(response.data.message);
          } else {
            this.mxHandleRequestError(error);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.send {
  cursor: pointer !important;
  text-decoration: underline;
  font-weight: bold;
  position: relative;
}
.line-send {
  display: flex;
}
.text-send {
  margin-left: 10px;
}
.icon-send {
  margin-top: 1px;
}
</style>

this.mxHandleRequestError(error)
