<template>
  <label class="switch">
    <input
      type="checkbox"
      :checked="value"
      @change="$emit('input', $event.target.checked)"
      :disabled="disabled"
    />
    <span class="slider round" />
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  width: 50px;
  height: 25px;
  position: relative;
  display: inline-block;
}
.switch input {
  width: 0;
  height: 0;
  opacity: 0;
}
.slider {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  position: absolute;
  -webkit-transition: 0.4s;
  background-color: #ccc;
}
.slider::before {
  left: 3px;
  content: "";
  width: 18px;
  bottom: 4px;
  height: 18px;
  transition: 0.4s;
  position: absolute;
  -webkit-transition: 0.4s;
  background-color: white;
}
.switch > input:checked + .slider {
  background-color: $color--primary-alt;
}
.switch > input:focus + .slider {
  box-shadow: 0 0 1px $color--primary-alt;
}
.switch > input:checked + .slider:before {
  transform: translateX(26px);
  -ms-transform: translateX(26px);
  -webkit-transform: translateX(26px);
}
.switch > input:disabled + .slider {
  opacity: 0.7;
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
</style>
