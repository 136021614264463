import Services from "./Services";

class HotsiteService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  getBilling(request) {
    request.link  = ``; 
      
    return new Promise(async (resolve, reject) => {
      try {
        const getBilling = {
            "taskId":"2327869",
            "valid":true,
            "showData":true,
            "dateBilling":"06/2022",
            "nameCompany":"CALLIDUS INDUSTRIA, COMERCIO E SERVICOS DE PLACAS E COMPONENTES DE INFORMATICA LTDA",
            "approvalStatus":"PENDENTE",
            "messages":["Documento preliminar para validação"],
            "nickNameCompany":"CALLIDUS",
            "billings":[
                    {   "id":459,
                        "meansurementFileId":782,
                        "percentLoss":0.5,
                        "factorAtt":1.0,
                        "proinfa":0.0,
                        "amountScde":519.275232,
                        "amountBruto":778.913,
                        "amountLiquido":637.56,
                        "limitMin":15.0,
                        "limitMax":15.0,
                        "qtdHired":554.4,
                        "qtdHiredMin":471.24,
                        "qtdHiredMax":637.56,
                        "meansurementPoint":"AMCDOIENTR101",
                        "wbcContract":74220,
                        "nameCompany":"CALLIDUS INDUSTRIA, COMERCIO E SERVICOS DE PLACAS E COMPONENTES DE INFORMATICA LTDA",
                        "nickNameCompany":"CALLIDUS",
                        "amountLiquidoAdjusted":null,
                        "price":237.81,
                        "wbcSubmercado":4,
                        "wbcSubmercadoName":"N",
                        "energyType":"Incentivada 50%",
                        "contractParent":null,
                        "cnpj":"08258870000177"}],
                        "billingHistoryDTO":[{"mes":6,"ano":2022,"consumoApurado":778.913,"montanteScde":519.275232}],
                        "aboveTake":null,
                        "belowTake":null,
                        "difference":null,
                        "repurchaseStatus":null,
                        "takeInformation":
                            {"aboveTake":true,
                            "belowTake":false,
                            "difference":141.35300000000007,
                            "shortTermProposal":true,
                            "repurchaseProposal":true,
                            "statusProposal":"NOT_CREATED",
                            "canCreateProposal":false},
                            "isFactorAttIntegral":true,
                            "statusPrice":"EXPIRADO",
                            "shortTermCode":null,
                            "tipoContrato":"Matrix",
                            "portifolioContrato":null
                        }
       
        
        resolve(getBilling);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new HotsiteService("/hotsite");