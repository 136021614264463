<template>
  <div class="sl-novo">
    <label for="novoSolicitadoLiquido" class="sl-title"
      >Novo Solicitado Líquido</label
    >
    <vue-numeric
      :min="0"
      :precision="3"
      ref="refInput"
      v-model="valueField"
      :decimal-separator="','"
      :thousand-separator="'.'"
      id="novoSolicitadoLiquido"
      class="form-control input-sl-novo"
      data-cy="input-novo_solicitado_liquido-modais-fields"
    />
  </div>
</template>

<script>
import VueNumeric from "vue-numeric";

export default {
  data() {
    return {
      valueField: 0,
    };
  },
  components: {
    VueNumeric,
  },
};
</script>

<style scoped>
.sl-title {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 16px;
}
.input-sl-novo {
  border-radius: 12px solid;
}
</style>
