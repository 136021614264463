<template>
  <div>
    <overlay-loader v-if="loading" />
    <div class="uploadbar">
      <div class="uploadbar__header" @click="uploading = !uploading">
        <img
          :src="icon.DropdownArrowIcon"
          :style="uploading ? 'transform: rotate(180deg)' : ''"
        />
        <span>
          {{ label }}
        </span>
      </div>
      <template v-if="uploading">
        <div class="uploadbar__top">
          <div
            v-if="descType"
            v-html="descTypes[descType] && descTypes[descType].top"
            class="uploadbar__top__desc"
          />
        </div>
        <div class="uploadbar__body">
          <upload-csv-input
            :modelProp.sync="modelProp"
            :loading="loading"
            :multiple="multiple"
          />
          <div class="uploadbar__body__list">
            <span>Arquivo enviado</span>
            <div class="m-1">
              <span>Nome do Arquivo</span>
              <div class="uploadbar__body__list__line mt-1" />
              <div class="uploadbar__body__list__table">
                <template v-if="modelProp.length > 0">
                  <div
                    v-for="(arquivo, arquivoIndex) in modelProp"
                    class="uploadbar__body__list__table__row"
                    :key="arquivoIndex"
                  >
                    <div>
                      <img :src="icon.FileIcon" />
                      <span>{{ arquivo.name }}</span>
                    </div>
                    <div>
                      <img
                        @click="trashFile(arquivoIndex)"
                        :src="icon.TrasherIcon"
                      />
                    </div>
                  </div>
                </template>
                <div v-else class="uploadbar__body__list__table__row">
                  <span>Nenhum arquivo selecionado</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uploadbar__bottom">
          <div
            v-if="descType"
            v-html="descTypes[descType] && descTypes[descType].bottom"
            class="uploadbar__bottom__desc"
          />
          <div v-else />
          <div class="uploadbar__bottom__button" @click="iniciarImportacao()">
            <span>Iniciar Importação em Lote</span>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="modalPontosPendentes"
      class="pontos-pendentes"
      @click="fecharModal($event)"
      ref="pontosPendentesBackground"
    >
      <div class="pontos-pendentes-modal">
        <div>
          <strong>Pontos Importados</strong>
          <div @click="fecharModal()">×</div>
        </div>
        <div>
          <span>
            <img :src="icon.WarnIcon" />
            ATENÇÃO! Os contratos ainda estão sendo reiniciados em segundo plano
          </span>
          <div>
            <div class="header">
              <div class="insert strong">Nome do arquivo</div>
              <div class="insert strong">Pontos de medição</div>
              <div class="insert strong" />
            </div>
            <div class="line" />
            <div
              class="table"
              ref="table"
              :style="{
                width: tamanhoModalPontosPendentes,
              }"
            >
              <template
                v-for="(pontosX, i) in [
                  { tipo: 'pontosValidos', icon: 'CheckIcon' },
                  { tipo: 'pontosAlertados', icon: 'WarnIcon' },
                  { tipo: 'pontosInvalidos', icon: 'InvalidIcon' },
                ]"
              >
                <div
                  class="table__row"
                  v-for="(ponto, f) in pontos[pontosX.tipo]"
                  :key="`${i}${f}`"
                >
                  <div class="insert">{{ ponto.arquivo }}</div>
                  <div class="insert">{{ ponto.ponto }}</div>
                  <div class="insert">
                    <img :src="icon[pontosX.icon]" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div />
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from "@/assets/icons/svg/check-icon.svg";
import DownloadIcon from "@/assets/icons/svg/download_icon.png";
import DropdownArrowIcon from "@/assets/icons/svg/dropdown-arrow.svg";
import FileIcon from "@/assets/icons/svg/file.svg";
import InvalidIcon from "@/assets/icons/svg/invalid_icon.svg";
import TrasherIcon from "@/assets/icons/svg/trasher.png";
import WarnIcon from "@/assets/icons/svg/warn-icon.svg";
import OverlayLoader from "@/components/OverlayLoader.vue";
import UploadCsvInput from "@/components/upload/UploadCsvInput.vue";
import { HTTP_RESPONSE } from "@/constants/strings";
import UploadInputMessagesENUM from "@/enums/UploadInputMessagesENUM";
import { defer, removeByteOrderMarkFromFiles } from "@/utils/functionsUtils";

export default {
  name: "UploadCsvCard",
  components: {
    UploadCsvInput,
    OverlayLoader,
  },
  data() {
    return {
      icon: {
        FileIcon,
        DropdownArrowIcon,
        TrasherIcon,
        DownloadIcon,
        CheckIcon,
        WarnIcon,
        InvalidIcon,
      },
      loading: false,
      uploading: false,
      modelProp: "",
      modalPontosPendentes: false,
      tamanhoModalPontosPendentes: "100%",
      pontos: {
        pontosValidos: [],
        pontosAlertados: [],
        pontosInvalidos: [],
      },
      descTypes: UploadInputMessagesENUM,
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    descType: {
      type: String,
      required: false,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    sendFormDataResult: {
      type: Promise,
    },
  },
  methods: {
    trashFile(i) {
      if (this.multiple) {
        const newModelProp = new DataTransfer();
        for (let j = 0; j < this.modelProp.length; j++) {
          if (j !== i) newModelProp.items.add(this.modelProp[j]);
        }
        this.modelProp = newModelProp.files;
      } else {
        this.modelProp = [];
      }
    },
    async abrirModal() {
      this.modalPontosPendentes = true;
      await defer();
      this.tamanhoModalPontosPendentes =
        this.$refs?.table?.scrollHeight > this.$refs.table.offsetHeight
          ? "calc(100% + 17px)"
          : "100%";
    },
    fecharModal(e) {
      if (!e || e.target === this.$refs.pontosPendentesBackground)
        window.location.reload();
    },
    async iniciarImportacao() {
      if (this.loading) return;
      if (this.modelProp && this.modelProp !== "") {
        this.loading = true;
        let files = await removeByteOrderMarkFromFiles(
          new Array(...this.modelProp)
        );
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
          try {
            const newFile = await this.checarTipagem(files[i]);
            formData.append("arquivos", newFile, newFile.name);
          } catch {
            this.loading = false;
            this.mxToggleErrorMessageAlert(
              "Arquivo não suportado: " +
                (this.multiple
                  ? "todos os arquivos devem ser CSV"
                  : "o arquivo deve ser CSV")
            );
            return;
          }
        }

        this.$emit("sendFormData", formData);
      } else this.mxToggleErrorMessageAlert("Selecione um arquivo");
    },
    checarTipagem(file) {
      return new Promise((resolve, reject) => {
        if (file.type == "text/csv") resolve(file);
        else if (file.type == "application/vnd.ms-excel") {
          // Esse é um tipo MIME de text/csv, arquivos .csv enviados pelo firefox
          // caem neste tipo e precisam de conversão
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);

          reader.onload = () =>
            resolve(
              new File([reader.result], file.name, {
                type: "text/csv",
                lastModified: file.lastModified,
              })
            );
        } else reject();
      });
    },
  },
  watch: {
    sendFormDataResult(dataResult) {
      dataResult
        .then(({ data }) => {
          this.pontos = data;
          this.abrirModal();
        })
        .catch(({ response: { status, data } }) => {
          this.mxToggleErrorMessageAlert(
            status === 422 || status === 500
              ? data.message
              : HTTP_RESPONSE.GENERIC[data.status]
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>

.pontos-pendentes
  position: fixed
  top: 0
  left: 0
  z-index: 999
  width: 100vw
  height: 100vh
  background-color: rgba(0, 0, 0, 0.3)
  display: flex
  justify-content: center
  align-items: center
  &-modal
    width: 850px
    height: 375px
    background-color: white
    border-radius: 20px
    > div
      width: 100%
      &:nth-child(1)
        height: 13%
        position: relative
        display: flex
        justify-content: center
        align-items: center
        > strong
          font-weight: 700
        > div
          top: 5px
          right: 20px
          color: #666
          cursor: pointer
          font-size: 30px
          position: absolute
      &:nth-child(2)
        height: 74%
        border-top: 1px solid #B4B7BA
        border-bottom: 1px solid #B4B7BA
        gap: 8px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        > span
          font-size: 14px
          font-weight: 500
          color: #2A2A2A
          display: flex
          align-items: center
          justify-content: center
          gap: 4px
        > div
          height: 80%
          width: 80%
          div .insert
            border-bottom: 1px solid #B6B6B6
            padding: 10px
            font-weight: 400
            color: #2A2A2A
            &.strong
              font-weight: 500
            img
              width: 21px
              padding: 0
            &:nth-child(1)
              width: 50%
            &:nth-child(2)
              width: 40%
            &:nth-child(3)
              width: 10%
          > div
            &.header
              width: 100%
              height: 20%
              display: flex
            &.line
              width: 100%
              height: 1px
              background-color: gray
            &.table
              height: calc(80% - 1px)
              overflow-y: auto
              > div.table__row
                display: flex
      &:nth-child(3)
        height: 13%
.uploadbar
    width: 100%
    background-color: #F2EDEB
    margin-top: 15px
    border-radius: 24px
    transition: 250ms
    display: flex
    flex-direction: column
    &__header
        width: 100%
        height: 52px
        cursor: pointer
        & img
            margin: 15px
    &__top
      display: flex
      padding: 0 20px 4px 20px
      &__desc
          font-size: 12px
          font-weight: 500
          color: #2A2A2A
    &__body
      display: flex
      padding: 4px 20px
      gap: 20px
      &__list
        height: 175px
        width: 50%
        > span
          font-size: 14px
          font-weight: 500
        .m-1 > span
          font-size: 14px
        &__line
          width: 100%
          height: 1px
          background-color: #2A2A2A
        &__table
          max-height: 118px
          overflow-y: auto
          &__row
            display: flex
            border-bottom: 2px solid #E8E8E8
            padding: 5px
            > span // Nenhum arquivo selecionado
              font-weight: 200
              color: gray
            > div // Arquivo selecionado
              display: flex
              align-items: center
              &:first-child
                width: 100%
                > span
                  padding: 0 6px
              > img
                width: 25px
                height: 27px
                padding: 5px
              &:last-child img
                cursor: pointer
    &__bottom
      width: 100%
      display: flex
      justify-content: space-between
      padding: 4px 20px 20px 20px
      &__desc
          max-width: calc( 50% - 20px )
          font-size: 12px
          font-weight: 500
          color: #2A2A2A
      &__button
        height: 50px
        width: 30%
        border-radius: 12px
        cursor: pointer
        background-color: #0A1631
        transition: background-color 300ms
        display: flex
        justify-content: center
        align-items: center
        &:hover
          background-color: #3b496a
        & span
          color: #FFFAF8
          font-weight: 700
</style>
