const modals = {
  state: {
    filesErrorModal: { on: false, props: {} },
  },
  getters: {
    getFilesErrorModal: (state) => state.filesErrorModal,
  },
  mutations: {
    toggleFilesError(state, { on, props }) {
      state.filesErrorModal = { on, props };
    },
  },
  actions: {
    ActionToggleFilesError(context, value) {
      context.commit("toggleFilesError", value);
    },
  },
};

export default modals;
