import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import auth from "@/store/modules/auth";
import layout from "@/store/modules/layout";
import modals from "@/store/modules/modals";
import pages from "@/store/modules/pages";
import alcadaSpread from "@/store/modules/pages/alcadaSpread";
import contrato from "@/store/modules/pages/contrato";
import toast from "@/store/modules/toast";

export default new Vuex.Store({
  modules: {
    auth,
    layout,
    modals,
    pages,
    alcadaSpread,
    contrato,
    toast,
  },
});
