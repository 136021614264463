<template>
  <div class="modalSpinner">
    <div class="spinner" :style="customStyle">
      <img :src="imageSpinner" />
    </div>
  </div>
</template>

<script>
import imageSpinner from "@/assets/icons/svg/spinner.svg";

export default {
  computed: {
    imageSpinner: () => imageSpinner,
  },
  props: {
    customStyle: {
      type: Object,
      default() {
        return { marginLeft: "55px" };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.modalSpinner {
  pointer-events: none;
}
</style>
