var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tooltip_container"},[_vm._t("header"),_c('div',{class:[
      { 'tooltiptext arrow-up': _vm.arrowAlign === 'up' },
      { 'tooltiptext arrow-down': _vm.arrowAlign === 'down' },
      { 'tooltiptext arrow-left': _vm.arrowAlign === 'left' },
      { 'tooltiptext arrow-right': _vm.arrowAlign === 'right' } ],style:([
      {
        width: _vm.width,
        transform: ("translateX(" + _vm.alignHorizontal + ")"),
        top: ("" + _vm.alignVertical),
      } ])},[_vm._t("body")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }