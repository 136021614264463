<template>
  <div class="fields-box">
    <div class="fields-section">
      <field-aprovacao
        titleField="Alçada Competente"
        :valueInfo="dataInfo.descricao"
        colorTitleField="#544943"
        colorValueField="#2A2A2A"
        :widthField="50"
      />
    </div>
  </div>
</template>

<script>
import FieldAprovacao from "../modais/fields/FieldAprovacao.vue";

export default {
  props: {
    dataInfo: {
      default() {
        return {
          descricao: "",
        };
      },
    },
  },
  components: {
    FieldAprovacao,
  },
};
</script>

<style lang="scss" scoped>
.fields-box {
  display: flex;
  flex-direction: column;

  width: 40%;
}

.fields-section {
  display: flex;

  height: 50%;
}
</style>
