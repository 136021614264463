const contrato = {
  state: {
    contratoPaiId: null,
    isPontoSemAcesso: null,
  },
  getters: {
    getContratoPaiId(state) {
      return state.contratoPaiId;
    },
    getIsPontoSemAcesso(state) {
      return state.isPontoSemAcesso;
    },
  },
  mutations: {
    setContratoPaiId(state, data) {
      state.contratoPaiId = data;
    },
    setIsPontoSemAcesso(state, data) {
      state.isPontoSemAcesso = data;
    },
  },
  actions: {
    ActionSetContratoPaiId(context, value) {
      context.commit("setContratoPaiId", value);
    },
    ActionSetIsPontoSemAcesso(context, value) {
      context.commit("setIsPontoSemAcesso", value);
    },
  },
};

export default contrato;
