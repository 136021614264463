<template>
  <div class="w-100">
    <input
      type="number"
      class="w-100"
      :disabled="disabled"
      v-model.trim="$v.model.content.$model"
      data-cy="input-alcada_aprovacao_maxima-parametrizacao-components-fields"
      :class="{
        'is-disabled': disabled,
        'is-invalid': $v.model.content.$error,
      }"
    />
    <div class="is-invalid-label w-100" v-if="$v.model.content.$error">
      O valor máximo deve ser maior que {{ modelProp.valorFaixaMin }}.
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    modelProp: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data() {
    return {
      model: {
        name: "valorFaixaMax",
        content: "",
      },
    };
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return true;
      }
      return false;
    },
  },
  watch: {
    modelProp: {
      immediate: true,
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue < 0) {
          this.modelProp[this.model.name] = 0;
          this.model.content = 0;
        } else {
          this.modelProp[this.model.name] = this.model.content;
          if (this.modelProp.descricaoPerfilAprovador != "APROVADOR_NIVEL_3") {
            this.$emit(
              "maxValueChanged",
              this.modelProp.id,
              this.modelProp.tipo,
              this.modelProp.valorFaixaMax
            );
          }
        }
      },
    },
  },
  computed: {},
  validations: {
    model: {
      content: {
        required,
        maxIsGreaterThanMin() {
          if (this.disabled) return true;
          if (
            this.model.name === "valorFaixaMax" &&
            Number(this.modelProp.valorFaixaMin) >=
              Number(this.modelProp.valorFaixaMax)
          ) {
            return false;
          }
          return true;
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  padding: 5px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #b4b7ba;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.is-disabled {
  color: #9c9ea1 !important;
  background: #f2edeb !important;
}

.is-invalid {
  border: 1px solid red;
}

.is-invalid-label {
  color: red;
  font-size: 10px;
  width: auto !important;
  display: inline-block;
}
</style>
