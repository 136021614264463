import Services from "./Services";
import ContratoDivergenteModel from "@/models/ContratoDivergenteModel";

class AlteracoesService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  list(request) {
    this._url = "/contratos/wbc";
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request, true);
        if (response.data) {
          response.data.content = response.data.content.map(
            (contrato) => new ContratoDivergenteModel(contrato)
          );
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  atualizarWbc(request) {
    this._url = `/contratos/${request}/wbc`;
    return this.post(request);
  }
}

export default new AlteracoesService("/alteracoes");
