<template>
  <div v-if="config" :style="`width: ${width}`" class="card-container">
    <div class="card-title" :style="`background-color: ${titleColor}`">
      {{ title }}
    </div>
    <div class="card-content">
      <table class="card-table">
        <tbody v-if="!!config">
          <tr
            :key="`key_${i}`"
            v-for="(info, i) in config"
            :style="info.color ? `border: 2px solid ${info.color};` : ''"
          >
            <td
              :style="{
                color: info.color || '',
                'font-weight': (info.color && 'bolder') || '',
                'border-bottom-left-radius': isLastRow(i),
              }"
              class="left"
            >
              {{ info.desc }}
            </td>
            <td
              class="right"
              :style="{
                'border-bottom-right-radius': isLastRow(i),
              }"
            >
              <b>{{ info.value }}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: String,
    config: Array,
    titleColor: String,
    title: {
      type: String,
      default: "Default Title",
    },
  },
  data() {
    return {};
  },
  methods: {
    isLastRow(index) {
      return index >= this.config.length - 1 ? "7px" : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  font-size: 0.8rem;
  border-radius: 7px;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0 #2a2a2a22;
}

.card-title {
  padding: 15px 4%;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem;
  background-color: #363636;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.card-content {
  background-color: #ffffff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.card-table {
  width: 100%;
  margin-top: -10px;
  border-radius: 7px;
  background-color: #ffffff;

  tr {
    width: 100%;
    &:nth-child(odd) {
      background-color: #f2edeb;
    }

    td {
      padding: 2% 0;
      &:first-child {
        padding-left: 4%;
      }
      &:last-child {
        padding-right: 4%;
      }
    }
  }
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}
</style>
