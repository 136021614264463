export default class ContratoDivergenteModel {
  constructor(
    {
      id,
      novo,
      codigo,
      campos,
      opened,
      empresa,
      codigoContratoReferencia,
    } = new Object()
  ) {
    this._id = id;
    this._novo = novo;
    this._codigo = codigo;
    this._campos = campos;
    this._opened = opened;
    this._empresa = empresa;
    this._codigoContratoReferencia = codigoContratoReferencia;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id = null) {
    this._id = id;
  }

  get codigo() {
    return this._codigo;
  }

  set codigo(codigo) {
    this._codigo = codigo;
  }

  get codigoContratoReferencia() {
    return this._codigoContratoReferencia;
  }

  set codigoContratoReferencia(codigoContratoReferencia) {
    this._codigoContratoReferencia = codigoContratoReferencia;
  }

  get novo() {
    return this._novo;
  }

  set novo(novo) {
    this._novo = novo;
  }

  get empresa() {
    return this._empresa;
  }

  set empresa(empresa) {
    this._empresa = empresa;
  }

  get opened() {
    return this._opened || false;
  }

  set opened(opened) {
    this._opened = opened;
  }

  get campos() {
    return this._campos;
  }

  set campos(campos) {
    this._campos = campos;
  }

  getData() {
    const { id, novo, codigo, campos, empresa, codigoContratoReferencia } =
      this;
    return {
      id,
      novo,
      codigo,
      campos,
      empresa,
      codigoContratoReferencia,
    };
  }
}
