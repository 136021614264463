<template>
  <div v-elementAsyncLoader="loading" v-if="canAccessBilling">
    <app-container>
      <template #sidebar>
        <navigation />
      </template>
      <template #body>
        <page-template-default>
          <template #topbar>
            <topbar />
          </template>
          <template #content>
            <router-view />
          </template>
        </page-template-default>
      </template>
    </app-container>
  </div>
</template>

<script>
import AppContainer from "@/components/AppContainer.vue";
import Navigation from "@/components/Navigation/Navigation.vue";
import Topbar from "@/components/Topbar/Topbar.vue";
import PageTemplateDefault from "@/components/templates/PageTemplateDefault.vue";
import PermissoesService from "@/services/PermissoesService";
import UsuarioService from "@/services/UsuarioService";
import { defineAbilityFor } from "@/utils/permissions";
import { Toast } from "bootstrap";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: undefined,
      canAccessBilling: false,
    };
  },
  name: "Home",
  computed: {
    ...mapGetters(["getPermissoes"]),
  },
  methods: {
    ...mapActions([
      "ActionSetUsuario",
      "ActionSetPermissoes",
      "ActionSetPermissoesPowerBI",
    ]),
    mountPermissoes() {
      return new Promise((resolve, reject) => {
        this.loading = PermissoesService.getPermissoes()
          .then(({ data }) => {
            if (!data.permissoes?.includes("ACESSAR_BILLING")) {
              this.$router.push({ path: "/access-denied" });
            }
            this.canAccessBilling = true;
            if (data.permissoesPowerBI && data.permissoesPowerBI.length > 0) {
              data.permissoes.push("DASHBOARD_HABILITADO");
            }
            this.ActionSetPermissoes(data.permissoes);
            this.ActionSetPermissoesPowerBI(data.permissoesPowerBI);
            this.$ability.update(defineAbilityFor(data.permissoes));

            resolve();
          })
          .catch((error) => {
            const bToken = window.sessionStorage.getItem(
              process.env.VUE_APP_TOKEN_KEY
            );
            if (bToken) {
              window.sessionStorage.setItem("denied", btoa(true));
              this.$router.push({ path: "/access-denied" });
            }
            this.mxHandleRequestError(error);
            reject();
          });
      });
    },
    mountUsuario() {
      return new Promise((resolve, reject) => {
        this.loading = UsuarioService.getUserInfo()
          .then(({ data }) => {
            window.sessionStorage.setItem("u-keys", data);
            this.ActionSetUsuario(data);
            resolve();
          })
          .catch((error) => {
            this.mxHandleRequestError(error);
            reject();
          });
      });
    },
  },
  async beforeMount() {
    await this.mountUsuario();
    await this.mountPermissoes();
  },
  mounted() {
    const toast = new Toast(document.getElementById("toast-component"));
    toast.show();
    toast.hide();
  },
  components: {
    Topbar,
    Navigation,
    AppContainer,
    PageTemplateDefault,
  },
};
</script>
