import Services from "./Services";

class CurtoPrazoService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  /**
   * @summary Get the Curto Prazo
   * @param {Object} request
   * @endpoint /api/curto-prazo
   */
  getCurtoPrazo(request) {
    this._url = "/parametros";
    return this.get(request);
  }

  /**
   * @summary Post the Curto Prazo
   * @param {Object} request
   * @endpoint /api/curto-prazo
   */
  postCurtoPrazo(request) {
    this._url = "/parametros";
    return this.post(request);
  }

  /**
   * @summary Get the Tusd
   * @param {Object} request
   * @endpoint /api/curto-prazo/tusd
   */
  getTusd(request) {
    this._url = "/tusd";
    return this.get(request);
  }

  /**
   * @summary Post the Tusd
   * @param {Object} request
   * @endpoint /api/tusd
   */
  postTusd(request) {
    this._url = "/tusd";
    return this.post(request);
  }
}

export default new CurtoPrazoService("/curto-prazo");
