<template>
  <div class="data-table">
    <div :class="{ 'card-table': card }">
      <scroll :ops="scrollConfig" style="padding-bottom: 18px">
        <div class="data-table__wrapper" :style="`max-height:${maxHeight}`">
          <table class="_table">
            <thead :class="{ 'header-sticky': headerStick }">
              <tr>
                <td></td>
                <td
                  :style="[
                    fieldHeader.width
                      ? { width: `${fieldHeader.width}` }
                      : { width: `${100 / fieldHeader.length}%` },
                  ]"
                  v-for="(fieldHeader, fieldHeaderIndex) in fields"
                  :key="`${fieldHeaderIndex}fieldHeaderIndex`"
                  :class="{ center: fieldHeader.align == 'center' }"
                  style="font-size: 16px"
                >
                  <div v-if="isFieldSlot('header-' + fieldHeader.name)">
                    <slot :name="'header-' + fieldHeader.name"></slot>
                  </div>
                  <div v-else>
                    {{ fieldHeader.title }}
                  </div>
                </td>
              </tr>
            </thead>
            <tbody
              :class="[stripes ? 'stripes' : '_border-bottom']"
              v-for="(row, indexRow) in dataToggle"
              :key="`${indexRow}indexRow`"
            >
              <tr
                :class="[
                  stripes ? 'stripes' : '_border-bottom',
                  lineClickable ? 'pointer' : null,
                  lineClass ? `${lineClass}-${indexRow}` : null,
                ]"
                @click.stop="lineClicked(row, indexRow)"
                data-cy="tr-clique_na_linha_selecionada-tables"
              >
                <td
                  :style="`padding: ${rowPadding} 15px ${rowPadding} 10px; background:${
                    row.detail ? '#AEC0D1' : ''
                  }`"
                >
                  <span
                    class="row-toggle"
                    @click="toggle(indexRow)"
                    data-cy="span-data_table_recompra-tables"
                  >
                    <img
                      v-if="!row.detail"
                      src="/images/svg/icon-table-open.svg"
                    />
                    <img v-else src="/images/svg/icon-table-close.svg" />
                  </span>
                </td>
                <td
                  v-for="(fieldBody, indexFieldBody) in fields"
                  :key="`${indexFieldBody}indexFieldBody`"
                  :style="`padding: ${rowPadding} 15px ${rowPadding} 10px; background:${
                    row.detail ? '#AEC0D1' : ''
                  }`"
                  :class="{ center: fieldBody.align == 'center' }"
                >
                  <div
                    v-if="isFieldSlot(fieldBody.name)"
                    :class="{ center: fieldBody.align == 'center' }"
                  >
                    <slot
                      :name="fieldBody.name"
                      :index="indexRow"
                      :row="row"
                    ></slot>
                  </div>
                  <div v-else>
                    <span
                      v-html="renderValue(data[indexRow], fieldBody)"
                    ></span>
                  </div>
                </td>
              </tr>

              <tr class="row-detail">
                <td v-show="row.detail" :colspan="fields.length + 1">
                  <slot :name="'detail'" :index="indexRow" :row="row"></slot>
                </td>
              </tr>
            </tbody>
            <tr>
              <td
                v-if="data.length === 0"
                style="text-align: center"
                :colspan="fields.length"
              >
                Nenhum resultado encontrado
              </td>
            </tr>
            <tr v-if="footer" class="footer-sticky">
              <td></td>
              <td
                :style="[
                  fieldHeader.width
                    ? { width: `${fieldHeader.width}` }
                    : { width: `${100 / fieldHeader.length}%` },
                ]"
                v-for="(fieldHeader, fieldHeaderIndex) in fields"
                :key="`${fieldHeaderIndex}fieldHeaderIndex`"
                :class="{ center: fieldHeader.align == 'center' }"
                style="font-size: 16px"
              >
                <div
                  style="font-weight: 600; text-align: center"
                  v-if="isFieldSlot('footer-' + fieldHeader.name)"
                >
                  <slot :name="'footer-' + fieldHeader.name"></slot>
                </div>
                <div v-else></div>
              </td>
            </tr>
          </table>
        </div>
      </scroll>
    </div>
  </div>
</template>
<script>
import arrowUp from "@/assets/icons/svg/arrow-up.svg";
import arrowDownLight from "@/assets/icons/svg/arrow-down-light.svg";
export default {
  props: {
    paginationData: {
      type: Object,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    data: {
      //type:Array,
      require: true,
    },
    fields: {
      type: Array,
      require: true,
    },
    stripes: {
      typeof: Boolean,
      require: false,
      default: false,
    },
    lineClickable: {
      typeof: Boolean,
      require: false,
      default: false,
    },
    rowPadding: {
      typeof: String,
      require: false,
      default: false,
    },
    card: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: "100%",
    },
    headerStick: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    lineClass: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      scrollConfig: {
        rail: { opacity: 0.5 },
        bar: { keepShow: true },
        arrowUp: arrowUp,
        arrowDown: arrowDownLight,
      },
    };
  },
  computed: {
    dataToggle: function () {
      return this.data.map((d) => {
        d.detail = false;
        return d;
      });
    },
  },
  methods: {
    toggle(index) {
      this.dataToggle[index].detail = !this.dataToggle[index].detail;
      this.$forceUpdate();
    },
    setPage(page) {
      this.$emit("setPage", page);
    },
    setPerPage(value) {
      this.$emit("setPerPage", value);
    },
    renderValue(row, fieldBody) {
      return this.hasFormatter(fieldBody)
        ? this.callFormatter(fieldBody, row)
        : row[fieldBody.name];
    },
    isFieldSlot(fieldName) {
      return typeof this.$scopedSlots[fieldName] !== "undefined";
    },
    hasFormatter(item) {
      return typeof item.formatter === "function";
    },
    callFormatter(fieldBody, row) {
      if (!this.hasFormatter(fieldBody)) return;
      if (typeof fieldBody.formatter === "function") {
        return fieldBody.formatter(
          this.getObjectValue(row, fieldBody.name),
          "teste"
        );
      }
    },
    getObjectValue(object, path, defaultValue) {
      let obj = object;
      if (path.trim() != "") {
        let keys = path.split(".");
        keys.forEach((key) => {
          if (
            obj !== null &&
            typeof obj[key] !== "undefined" &&
            obj[key] !== null
          ) {
            obj = obj[key];
          } else {
            obj = defaultValue;
            return;
          }
        });
      }
      return obj;
    },
    lineClicked(data, index) {
      if (this.lineClickable) {
        this.$emit("lineClicked", data, index);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.data-table {
  margin: 0;
  padding: 0;
  &__wrapper {
    padding-bottom: 25px;
  }
  ._table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    thead {
      background: $color--primary-white;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 150%;
      letter-spacing: 1px;
      color: #2a2a2a;
      @include ml-hd {
        font-size: responsive(1rem);
      }
      td {
        padding: 15px 15px 3px 10px;
        margin: 5px;
        border-bottom: #2a2a2a 1px solid;
      }
    }
    tbody {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 1px;
      color: #2a2a2a;
      @include ml-hd {
        font-size: responsive(1rem);
      }
      td {
        padding: 6px 10px;
      }
    }
  }
  .stripes {
    &:nth-child(even) {
      background-color: #f2edeb !important;
    }
  }
  ._border-bottom {
    border-bottom: solid 1px #eee;
  }
}
.pointer {
  cursor: pointer;
}
.box-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px !important;
}
.card-table {
  border: 1px solid #b4b7ba;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  padding: 15px;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}
.header-sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 2;
}
.footer-sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  bottom: 0;
  z-index: 2;
  background: $color--primary-white;
  margin-top: -50px;
  td {
    border-top: #2a2a2a 1px solid;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}
.row-toggle {
  cursor: pointer;
}
.row-detail {
  background: #dfe4e9;
  border-bottom: 1px solid #666;
}
</style>