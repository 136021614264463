import { AbilityBuilder, Ability } from "@casl/ability";
import PermissoesBillingENUM from "@/enums/PermissoesBillingENUM";

export const defineAbilityFor = (userPermissions) => {
  const { can, build, rules } = new AbilityBuilder(Ability);
  if (userPermissions) {
    userPermissions.map((USUARIO_PERMISSAO, index) => {
      for (const ACTION in PermissoesBillingENUM) {
        if (PermissoesBillingENUM[ACTION] === USUARIO_PERMISSAO) {
          can(USUARIO_PERMISSAO);
        }
      }
    });
    return rules;
  }
  return build();
};

export const userHasPermissions = (userPermissions, permissionsToCheck) => {
  if (!userPermissions) return false;
  return permissionsToCheck.some((permission) => {
    return userPermissions.some((userPermission) => {
      return userPermission === permission;
    });
  });
};
