export const StatusBarraENUM = {
  FixoImportacao: {
    error: "importacaoErro",
    finished: "importacaoFinalizada",
    percentage: "importacaoPorcentagem",
    isDg: false,
  },
  FixoCalculo: {
    error: "calculoErro",
    finished: "calculoFinalizado",
    percentage: "calculoPorcentagem",
    isDg: false,
  },
  DgCalculo: {
    error: "finalizadoComErro",
    finished: "finalizado",
    percentage: "progressoPorcentagemSucesso",
    isDg: true,
  },
};

export const getBarStatus = (data, attrName, dg) => {
  const { error, finished, percentage } = StatusBarraENUM[attrName];
  if (dg) {
    if (!data[finished]) return "running";
    if (data[error]) return "error";
    return "finished";
  }
  if (data[error]) return "error";
  if (data[percentage] === 100 || data[finished]) return "finished";
  if (data[percentage] === 0) return "idle";
  return "running";
};
