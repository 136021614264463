<template>
  <div>
    <modal-base
      width="70%"
      @closeModal="$emit('closeModal')"
      data-cy="modal-base-fechar_modal_aprovar_spread-pld-spread-components-modais"
    >
      <template #header>Aprovar Spreads Selecionados</template>
      <template #body>
        <div
          class="container-card-curto-prazo"
          v-element-async-loader="loading"
        >
          <card-base
            v-for="(submercado, submercadoIndex) in submercados"
            :key="`submercadoCard_${submercadoIndex}`"
          >
            <template #header>
              <div class="title-card-base">
                Submercado: <b>{{ submercado.sigla || "--" }}</b> ({{
                  submercado.descricao || "--"
                }})
              </div>
            </template>
            <template #body>
              <div
                class="
                  d-flex
                  align-items-center
                  container-subtitle
                  justify-content-start
                "
              >
                <span class="price-pld">
                  PLD: <b>{{ submercado.getPrecoPLD() || "--" }}</b>
                </span>
                <span class="pld-editado margin-text">
                  PLD Editado: {{ submercado.getPLDEditado() || "--" }}
                </span>
              </div>
              <div>
                <table-submercado
                  :tiposEnergia="
                    TIPOS_ENERGIA_SELECTED(submercado.tiposEnergia)
                  "
                />
              </div>
            </template>
          </card-base>
        </div>
      </template>
      <template #footer>
        <div class="button-bar-modal">
          <button
            @click="$emit('closeModal')"
            class="btn btn-secondary-outlined buttons"
            data-cy="button-cancelar_modal_aprovar_spread-pld-spread-components-modais"
          >
            Cancelar
          </button>
          <button
            @click="approveSpreadSelected"
            class="btn btn-primary-full-filled buttons"
            data-cy="button-salvar_modal_aprovar_spread-pld-spread-components-modais"
          >
            Salvar
          </button>
        </div>
      </template>
    </modal-base>
  </div>
</template>
<script>
import STATUS_SPREAD from "@/enums/SpreadStatusEnum";
import CardBase from "@/components/cards/cardBase.vue";
import DataTable from "@/components/tables/DataTable.vue";
import ModalBase from "@/components/modals/ModalBase.vue";
import PLDSpreadService from "@/services/PLDSpreadService";
import TableSubmercado from "../tables/TableSubmercado.vue";
import { CONFIRMATION } from "@/constants/strings";
export default {
  props: {
    submercados: Array,
  },
  data() {
    return {
      loading: undefined,
    };
  },
  methods: {
    TIPOS_ENERGIA_SELECTED(tiposEnergia) {
      return tiposEnergia.filter(
        (tipoEnergia) =>
          tipoEnergia.selected && tipoEnergia.status === STATUS_SPREAD.PENDENTE
      );
    },
    getSpreadIds() {
      const arr = new Array();
      this.submercados.map((submercado) => {
        submercado.tiposEnergia.map((tipoEnergia) => {
          if (
            tipoEnergia.selected &&
            tipoEnergia.status === STATUS_SPREAD.PENDENTE
          )
            arr.push(tipoEnergia.ultimoSpread.id);
        });
      });
      return arr;
    },
    approveSpreadSelected() {
      const spreadIds = this.getSpreadIds();
      this.loading = PLDSpreadService.approveSpreadSelected({ spreadIds })
        .then(() => {
          this.mxToggleToast({
            message: CONFIRMATION.APROVAR_SPREAD.CREATED,
          });
          this.$emit("reloadSubmercados");
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
  },
  components: {
    CardBase,
    CardBase,
    DataTable,
    ModalBase,
    TableSubmercado,
  },
};
</script>
<style lang="scss" scoped>
.button-bar-modal {
  width: 100%;
  display: flex;
  margin-right: 30px;
  justify-content: flex-end;
  button {
    margin: 10px;
    width: 140px;
    height: 34px;
    line-height: 100%;
    border-radius: 9px;
  }
}

.container-card-curto-prazo {
  padding: 30px;
}

.title-card-base {
  color: $color--white;
}

.pld-editado {
  opacity: 0.5;
  font-size: 12px;
  font-weight: 700;
  color: $color--primary-font;
}

.price-pld {
  font-size: 12px;
  color: $color--primary-font;
}

.margin-card {
  margin-top: 36px;
}

.margin-text {
  margin-left: 32px;
}

.container-subtitle {
  margin-top: 0px;
}

.btn-edit {
  font-size: 12px;
}

.text-style {
  text-decoration: line-through;
}

.font-weight-bold {
  font-weight: 700;
}
</style>
