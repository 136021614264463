<template>
  <div style="position: relative">
    <overlay-loader v-if="loading" />
    <div class="wrapper">
      <div class="units-header">
        <div class="_box_title">Distribuição por unidades</div>
        <div v-if="DIFERENCA && DIFERENCA != 0" style="display: flex">
          <span v-if="DIFERENCA > 0" class="label-remaining">
            Falta distribuir:
          </span>
          <span v-if="DIFERENCA < 0" class="label-remaining">
            Há um volume excedente de:
          </span>
          <span class="value-remaining"
            >{{ forcePositive(DIFERENCA) | volume }} MWh</span
          >
        </div>
        -
      </div>
      <div class="box-table-unit">
        <table class="table-unit">
          <thead class="mtx-sticky">
            <tr>
              <th style="padding-left: 20px">Controle</th>
              <th class="cnpj">CNPJ</th>
              <th>P. Medição</th>
              <th>Submercado</th>
              <th>Montante SCDE (MWh)</th>
              <th>Perdas (%)</th>
              <th>Proinfa (MWh)</th>
              <th>F. Atend. (%)</th>
              <th>Cons. Apurado (MWh)</th>
              <th>Vol. Faturar (MWh)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(contrato, indexContract) in contrstosFilhoRedistribuido"
              :key="`indexContract${indexContract}`"
              class="rows-table"
            >
              <td style="padding-left: 20px">{{ contrato.codigo }}</td>
              <td class="cnpj">
                {{ contrato.empresa.cnpj | cnpj }}
              </td>
              <td class="">
                {{
                  contrato.pontoMedicao ? contrato.pontoMedicao.codigo : "--"
                }}
              </td>
              <td class="">
                {{ contrato.submercado ? contrato.submercado.descricao : "--" }}
              </td>
              <td class="">
                {{
                  contrato.calculoFaturamento &&
                  contrato.calculoFaturamento.apuracao
                    ? contrato.calculoFaturamento.apuracao
                        .quantidadeMontanteScde
                    : 0 | volume
                }}
              </td>
              <td class="">
                {{ contrato.percentualPerda | moeda }}%</td>
              <td class="">
                {{
                   contrato.pontoMedicao &&
                  contrato.pontoMedicao.proinfa ?
                  contrato.pontoMedicao.proinfa.valor : '--' | volume
                }}
              </td>
              <td class="">
                <span v-if="contrato.percentualFatorAtendimento == 100">{{  contrato.percentualFatorAtendimento}}</span>
                <span v-else>{{ contrato.percentualFatorAtendimento | volume }}</span>
                %
                </td>
              <td style="font-weight: 600">
                {{
                  contrato.calculoFaturamento
                    ? contrato.calculoFaturamento.volumeBruto
                    : 0 | volume
                }}
              </td>
              <td style="font-weight: 600; padding: 0 10px">
                <vue-numeric
                  class="inputNumeric"
                  :minus="true"
                  :precision="3"
                  placeholder="0,000"
                  :decimal-separator="','"
                  :thousand-separator="'.'"
                  v-model="contrato.calculoFaturamento.volumeAFaturar"
                />
              </td>
            </tr>
            <tr class="rows-table rows-total">
              <td>TOTAL</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>{{ TOTAL_VOLUME_SCDE | volume }}</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>{{ TOTAL_CONSUMO_APURADO | volume }}</td>
              <td>
                <b>{{ TOTAL_VULEME_FATURAR | volume }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-buttons">
        <button
          @click="cancel"
          class="btn-action-primary"
          style="
            max-width: 300px;
            margin: 0 10px;
            min-width: 200px;
            padding: 5px;
          "
        >
          CANCELAR
        </button>
        <button
          @click="salvar"
          :disabled="DIFERENCA != 0"
          class="btn-action-secondary"
          style="
            max-width: 300px;
            margin: 0 10px;
            min-width: 200px;
            padding: 5px;
          "
        >
          SALVAR REDISTRIBUIÇÃO
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import VueNumeric from "vue-numeric";
import { volumeTrunc } from "@/utils/functionsUtils";
import OverlayLoader from "@/components/OverlayLoader";
import DemonstrativoService from "@/services/DemonstrativoService";

export default {
  props: {
    mainContrato: Object,
    contratosFilhos: Array,
  },
  data() {
    return {
      loading: false,
      contrstosFilhoRedistribuido: [],
    };
  },
  mounted() {
    this.contrstosFilhoRedistribuido = JSON.parse(
      JSON.stringify(
        this.contratosFilhos.map((contrato) => {
          if (contrato && !contrato.calculoFaturamento) {
            contrato.calculoFaturamento = {
              apuracao: {},
              dataReferencia: "",
              id: null,
              volumeAFaturar: 0,
              volumeBruto: 0,
              volumeCurtoPrazo: 0,
              volumeLiquido: 0,
            };
          }
          return contrato;
        })
      )
    );
  },
  computed: {
    CONTRATOS_FILHOS_MODEL() {
      const result = this.contratosFilhos.map((g) => {
        if (!g.calculoFaturamento) {
          g.calculoFaturamento = {
            apuracao: {},
            dataReferencia: "",
            id: null,
            volumeAFaturar: 0,
            volumeBruto: 0,
            volumeCurtoPrazo: 0,
            volumeLiquido: 0,
          };
        }
      });

      return result;
    },
    VOLUMEFATURAR_PAI() {
      return this.mainContrato.calculoSumarizado.volumeAFaturar;
    },
    TOTAL_VOLUME_SCDE() {
      if (this.contrstosFilhoRedistribuido.length > 0) {
        let scdes = [];
        this.contrstosFilhoRedistribuido.forEach((contrato) => {
          if (
            contrato.calculoFaturamento &&
            contrato.calculoFaturamento.apuracao
          )
            scdes.push(
              contrato.calculoFaturamento.apuracao.quantidadeMontanteScde * 1000
            );
        });
        return scdes.reduce((total, numero) => total + numero) / 1000;
      }
      return 0;
    },

    TOTAL_CONSUMO_APURADO() {
      if (this.contrstosFilhoRedistribuido.length > 0) {
        let apurados = [];
        this.contrstosFilhoRedistribuido.forEach((contrato) => {
          if (contrato.calculoFaturamento)
            apurados.push(contrato.calculoFaturamento.volumeBruto * 1000);
        });
        return apurados.reduce((total, numero) => total + numero) / 1000;
      }
      return 0;
    },

    DIFERENCA() {
      return (
        (volumeTrunc(this.VOLUMEFATURAR_PAI * 1000) -
          volumeTrunc(this.TOTAL_VULEME_FATURAR * 1000)) /
        1000
      );
    },

    TOTAL_VULEME_FATURAR() {
      if (this.contrstosFilhoRedistribuido.length > 0) {
        let faturar = [];
        this.contrstosFilhoRedistribuido.forEach((contrato) => {
          if (contrato.calculoFaturamento)
            faturar.push(contrato.calculoFaturamento.volumeAFaturar * 1000);
        });
        return faturar.reduce((total, numero) => total + numero) / 1000;
      }
      return 0;
    },
  },
  methods: {
    forcePositive(value) {
      if (Math.sign(value) === -1) return value * -1;
      return value;
    },
    salvar() {
      this.loading = true;
      let payload = [];
      this.contrstosFilhoRedistribuido.forEach((contrato) => {
        payload.push({
          idCalculo: contrato.calculoFaturamento.id,
          volumeLiquido: contrato.calculoFaturamento.volumeAFaturar.toFixed(3),
        });
      });
      DemonstrativoService.redistribuirDemonstrativo(
        this.mainContrato.id,
        payload
      )
        .then(() => {
          this.loading = false;
          document.location.reload(true);
        })
        .catch(() => {
          this.loading = false;
          return this.mxToggleErrorMessageAlert(
            "Não foi possível redistribuir "
          );
        });
    },
    cancel() {
      this.$emit("closeModal");
    },
  },
  components: {
    VueNumeric,
    OverlayLoader,
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  height: 65vh;
}
.units-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 16px 20px;
}
._box_title {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #000;
}

.box-table-unit {
  flex: 2;
}

.table-unit {
  min-width: 1100px;
  width: 100%;
  border-spacing: 0;
  background: #fff;
  thead {
    background: #fff;
    border-bottom: 1px solid #000;
    tr {
      border-bottom: 1px solid #000;
    }
  }
  th {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000;
    padding: 5px 0 5px 0;
    background: #fff;
    &:hover {
      box-shadow: none;
    }
  }
  tr {
    &:hover {
      box-shadow: none;
    }
  }
  td {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #505050;
    text-align: center;
    border-bottom: 1px solid #bbb;
  }
}
.rows-table {
  min-height: 40px !important;
  td {
    height: 40px !important;
  }
}
.cnpj {
  min-width: 140px !important;
}
.rows-total {
  border: none !important;
  background: #e8e8e8;
  td {
    border: none !important;
    font-weight: 600;
  }
}
.box-buttons {
  display: flex;
  text-align: center;
  padding: 30px;
  min-height: 100px;
  min-height: 100px;
}
.mtx-field {
  border: none !important;
  text-align: center;
  width: 100px;
  background: #f1f1f1 !important;
  border-radius: 3px;
}
.label-remaining {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #505050;
}
.value-remaining {
  color: red;
  font-weight: bold;
  margin-left: 5px;
}
.mtx-btn--gray-border {
  width: 180px !important;
  border-radius: 4px;
  margin-right: 10px;
}
.mtx-btn--orange {
  width: 250px !important;
  border-radius: 4px;
  margin-left: 10px;
}
.inputNumeric {
  padding: 5px;
  border-radius: 4px;
}
</style>
