<template>
  <div :class="[itemSelected ? 'active' : null, 'card-processing']">
    <div :style="``" class="card-processing__header card-font-family">
      <span>Divergências de Cadastros</span>
    </div>
    <div class="card-processing__body">
      <div class="item-card">
        <span>WBC</span>
        <div v-if="WBCLoading" class="content-item">
          <dots-loader />
        </div>
        <div v-else class="content-item">
          <p
            @click="goToDivergences()"
            :class="[
              { 'text-danger': quantidadeWBC > 0 },
              { 'responsive-font': quantidadeWBC > 999 },
            ]"
          >
            {{ quantidadeWBC }}
          </p>
          <img src="../../../../assets/icons/svg/new-window.png" />
        </div>
      </div>
      <div class="separator"></div>
      <div class="item-card">
        <span>Central de Cadastros</span>
        <div
          v-if="divergenciasLoading"
          class="content-item"
          style="justify-content: center"
        >
          <dots-loader />
        </div>
        <div v-else class="content-item">
          <p
            @click="goToSync()"
            :class="[
              { 'text-danger': quantidadeDivergencias > 0 },
              { 'responsive-font': quantidadeDivergencias > 99999 },
            ]"
          >
            {{ quantidadeDivergencias }}
          </p>
          <img src="../../../../assets/icons/svg/new-window.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DotsLoader from "@/components/Loaders/DotsLoader.vue";

export default {
  props: {
    itemSelected: {
      type: Boolean,
      required: false,
    },
    WBCLoading: {
      type: Boolean,
      default: false,
    },
    divergenciasLoading: {
      type: Boolean,
      default: false,
    },
    quantidadeWBC: {
      default: 0,
    },
    quantidadeDivergencias: {
      default: 0,
    },
  },
  methods: {
    goToDivergences() {
      this.$router.push({ path: "/divergencias" });
    },
    goToSync() {
      this.$router.push({ path: "/sincronizacao" });
    },
  },
  components: {
    DotsLoader,
  },
};
</script>
<style lang="scss" scoped>
.responsive-font {
  font-size: 0.7em;
}

.card-processing {
  width: 100%;
  min-width: 15px;

  min-height: 150px;

  overflow: hidden;

  text-align: center;

  border-radius: 24px;
  background: $color--primary-white;

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: 50px;

    position: relative;
    padding: 0 5px;

    font-size: 12px;
    font-weight: 700;

    line-height: 150%;
    letter-spacing: 1px;

    color: $color--primary-white;
    background: #0f214a;
  }

  &__body {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    height: 100px;
    width: 100%;

    font-weight: 900;
    font-size: 38px;

    color: $color--primary-font;
  }

  .card-font-family {
    font-family: "Montserrat", "Helvetica" !important;
  }
}

@keyframes bg_card_slide_down {
  from {
    background-color: white;
  }

  to {
    background-color: #0f214a;
  }
}

.active {
  animation-duration: 0.3s;
  transition: 0.2s ease-in-out;
  animation-fill-mode: forwards;
  animation-name: bg_card_slide_down;
}

.color-white {
  color: white !important;
}

.item-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100px;
  width: 150px;
}

.item-card span {
  font-size: 10px;
}

.content-item {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 70px;

  img {
    width: 10px;

    margin-left: 5px;
  }
}

.separator {
  height: 50px;

  border-left: 2px solid #2a2a2a;
}

.text-danger {
  color: #d01010;
}
</style>
