import Services from "./Services";
import STATUS_SPREAD from "@/enums/SpreadStatusEnum";
import SubmercadoModel from "@/models/SubmercadoModel";
import TipoEnergiaModel from "@/models/TipoEnergiaModel";

class PLDSpreadService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  listSubmercados(request) {
    this._url = "/spread";
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request);
        response.data =
          response.data.length &&
          response.data.map(
            (submercado) =>
              new SubmercadoModel({
                ...submercado,
                tiposEnergia: submercado.tiposEnergia.map((tipoEnergia, i) => {
                  return new TipoEnergiaModel({
                    ...tipoEnergia,
                    selected: tipoEnergia.status === STATUS_SPREAD.APROVADO,
                  });
                }),
              })
          );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  getSpreadValidity(request) {
    this._url = "/spread/vigencia";
    return this.get();
  }

  updateSpreadValidity(request) {
    this._url = "/spread/vigencia";
    return this.post(request);
  }

  updatePLD(request, pldId) {
    this._url = `/spread/pld/${pldId}`;
    return this.patch(request);
  }

  updateSpread(request) {
    this._url = "/spread";
    return this.post(request);
  }

  approveSpreadSelected(request) {
    this._url = "/spread/aprovacao";
    return this.post(request);
  }

  getStatusPLDSpread(request) {
    this._url = `/spread/buscar-status-pld-spread/${request.id}`;
    return this.get(request);
  }
}

export default new PLDSpreadService("/submercado");
