<template>
  <div
    class="page-panel"
    :style="{ 'border-top-left-radius': hasTabs ? '0px' : '40px' }"
  >
    <div
      v-if="!!this.$slots['header'] || !!this.$scopedSlots['header']"
      class="page-panel__header"
    >
      <slot name="header"></slot>
    </div>
    <div class="page-panel__body" :style="{ minHeight: customHeight }">
      <slot name="body"></slot>
    </div>
    <div
      v-if="!!this.$slots['footer'] || !!this.$scopedSlots['footer']"
      class="page-panel__footer"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    hasTabs: Boolean,
    customHeight: {
      type: String,
      default: "80vh",
    },
  },
};
</script>
<style lang="scss" scoped>
.page-panel {
  width: 100%;
  overflow: hidden;
  border-radius: 40px;
  &__header {
    font-size: 21px;
    font-weight: 700;
    line-height: 120%;
    font-style: normal;
    letter-spacing: 1px;
    padding: 30px 40px 0 40px;
    color: $color--primary-font;
  }
  background: $color--primary-white;
  &__body {
    border-radius: 40px;
  }
  &__footer {
    padding: 0 40px 30px 40px;
  }
}
</style>
