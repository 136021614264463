<template>
  <div class="container-spread-field">
    <input
      type="text"
      maxlength="6"
      placeholder="0"
      :disabled="disabled"
      id="horasAdicionaisField"
      ref="horasAdicionaisField"
      v-model.trim="model.content"
      class="w-100 input-pattern-border"
      :class="{'is-disabled': disabled}"
      data-cy="input-prazo_em_minutos_curto-prazo"
    />
  </div>
</template>
<script>
import VueNumeric from "vue-numeric";
import AwesomeMask from "awesome-mask";
export default {
  props: {
    disabled: { type: Boolean },
    modelProp: { type: Object },
  },
  data() {
    return {
      model: {
        name: "horasAdicionais",
        content: "",
      },
    };
  },
  watch: {
    modelProp: {
      deep: true,
      immediate: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
      },
    },
  },
  components: {
    VueNumeric,
  },
  directives: {
    mask: AwesomeMask,
  },
};
</script>
<style lang="scss" scoped>
.container-spread-field {
  width: 50%;
}

.is-disabled {
  color: #9c9ea1 !important;
  background: #f2edeb !important;
}
</style>