<template>
  <div
    id="drop"
    @dragover.prevent
    multiple="multiple"
    @drop.prevent="drop"
    @dragenter="dragenter"
    class="drop"
  >
    <overlay-loader v-if="loadingUpload" />
    <label id="uploadField" for="inputUploadField">
      <input
        :multiple="multiple"
        type="file"
        @change="changeFile"
        id="inputUploadField"
        name="inputUploadField"
        class="form-control"
      />
      <div class="input-upload" v-show="!loadingUpload">
        <i class="icon-exportador font-value" />
        <div>
          Arraste e solte {{ multiple ? "arquivos" : "um arquivo" }} aqui ou
          clique para enviar
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import OverlayLoader from "@/components/OverlayLoader.vue";
import { required } from "vuelidate/lib/validators";
export default {
  props: ["modelProp", "loading", "multiple"],
  data() {
    return {
      loadingUpload: undefined,
    };
  },
  methods: {
    changeFile({ target }) {
      this.emitFilesToFormat(target.files);
    },
    drop(event) {
      if (this.loading) return;
      document.getElementById("uploadField").classList.remove("drop-hover");
      this.emitFilesToFormat(event.dataTransfer.files);
    },
    dragenter() {
      if (this.loading) return;
      document.getElementById("uploadField").classList.add("drop-hover");
    },
    isInvalid() {
      if (this.loading) return;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Arquivo inválido");
        return true;
      }
      return false;
    },
    emitFilesToFormat(files) {
      if (this.loading) return;

      if (this.multiple) {
        const newModelProp = new DataTransfer();
        let fileNames = new Set();
        [...this.modelProp, ...files].forEach((file) => {
          if (!fileNames.has(file.name)) {
            newModelProp.items.add(file);
            fileNames.add(file.name);
          }
        });

        this.$emit("update:modelProp", newModelProp.files);
        return;
      }
      this.$emit("update:modelProp", [files[0]]);
    },
    excluirArquivos(index) {
      if (this.loading) return;
      this.arquivos.splice(index, 1);
    },
  },
  validations: {
    model: {
      content: {
        required,
      },
    },
  },
  components: {
    OverlayLoader,
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/style/style.scss"
@import "@/assets/style/colors.scss"

.drop
    width: calc(50% - 20px)
    > div
      font-size: 12.5px
      font-weight: 600
      margin-bottom: 4px
    #uploadField
        position: relative
        border-radius: 5px
        border: 1px dashed #2a2a2a
        cursor: pointer
        width: 100%
        height: 175px
        &.drop-hover
            border: 2px solid #ffb946 !important
        &:hover
            opacity: 0.5
            transition: 0.2s ease-in-out
        & input
            opacity: 0
        .input-upload
            gap: 20px
            display: flex
            text-align: center
            flex-direction: column
            padding: 0px 35px 35px 35px
            .font-value
                font-size: 3em
</style>
