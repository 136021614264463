<template>
  <div class="container-callback" v-elementAsyncLoader="loading"></div>
</template>

<script>
import PermissoesService from "@/services/PermissoesService";
import UsuarioService from "@/services/UsuarioService";
import axios from "axios";
import { mapActions } from "vuex";
import { mountUrl } from "../utils/functionsUtils";

export default {
  data() {
    return {
      loading: undefined,
    };
  },
  mounted() {
    this.loginUrlCallBack();
  },
  methods: {
    ...mapActions(["ActionSetToken"]),
    loginUrlCallBack() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const state = urlParams.get("state");
      this.authNew(code, state);
    },
    authNew(code, state) {
      const sessionState = sessionStorage.getItem("state");
      const sessionVerifier = sessionStorage.getItem("verifier");

      if (!code || !state || !sessionState || state !== sessionState) {
        throw new Error("Could not authenticate the given user information");
      }
      const url = new URL("token", process.env.VUE_APP_MICROSOFT_OAUTH2_URI);
      this.loading = axios
        .post(
          url.href,
          {
            grant_type: "authorization_code",
            code: code,
            redirect_uri: mountUrl(process.env.VUE_APP_REDIRECT_URI),
            client_id: process.env.VUE_APP_CLIENT_ID,
            code_verifier: sessionVerifier,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          this.getUsuario();

          sessionStorage.setItem(
            process.env.VUE_APP_TOKEN_KEY,
            response.data.access_token
          );
          sessionStorage.setItem(
            process.env.VUE_APP_REFRESH_TOKEN_KEY,
            response.data.refresh_token
          );
        })
        .catch((error) => {
          console.error("CAUSING ERROR", error);
          this.mxHandleRequestError(error);
        });
    },
    getPermissoes() {
      PermissoesService.getPermissoes()
        .then(() => {
          const sessionState = sessionStorage.getItem("state");
          const urlAnterior = new URL(atob(sessionState));

          if (urlAnterior.pathname.includes("/login")) {
            this.ActionSetToken(
              sessionStorage.getItem(process.env.VUE_APP_TOKEN_KEY)
            );
            this.$router.push({ path: "/faturamento" });
          } else {
            window.location.href = atob(sessionState);
          }

          sessionStorage.removeItem("state");
          sessionStorage.removeItem("verifier");
        })
        .catch((error) => {
          this.$router.push({ path: "/access-denied" });
        });
    },
    getUsuario() {
      UsuarioService.getUserInfo()
        .then((response) => {
          if (response && response.data) {
            this.getPermissoes();
          }
        })
        .catch((error) => {
          const status = error.response.status;
          if (status === 403 || status === 401) {
            this.$router.push("/access-denied");
          }
        });
    },
  },
};
</script>

<style>
.container-callback {
  width: 100vw;
  height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
</style>
