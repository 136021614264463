<template>
  <div class="table-card subtitled-only" v-if="config">
    <div class="table-subtitle">
      C. Ajustes relacionados à simulação do mês anterior
    </div>
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>Dado</th>
          <th>Unidade</th>
          <th>Medição Considerada</th>
          <th>Medição Apurada</th>
          <th>Ajuste</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Fatura no Mercado CATIVO</td>
          <td>R$</td>
          <td>
            {{ config.mercadoCativoSm | truncateRound(4, 3) | R$ }}
          </td>
          <td>
            {{ config.tarMercadoCativoSm | truncateRound(4, 3) | R$ }}
          </td>
          <td class="table-bold">
            {{ config.fatMercadoCativoSm | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Custos com o uso do sistema da Distribuidora</td>
          <td>R$</td>
          <td>
            {{ config.custosDistribuidoraSm | truncateRound(4, 3) | R$ }}
          </td>
          <td>
            {{ config.tarCustosDistribuidoraSm | truncateRound(4, 3) | R$ }}
          </td>
          <td class="table-bold">
            {{ config.fatCustosDistribuidoraSm | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Custos Relacionados à CCEE</td>
          <td>R$</td>
          <td>
            {{ config.custosCceeSm | truncateRound(4, 3) | R$ }}
          </td>
          <td>
            {{ config.tarCustosCceeSm | truncateRound(4, 3) | R$ }}
          </td>
          <td class="table-bold">
            {{ config.fatCustosCceeSm | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr>
          <td>Custos Relacionados à Matrix</td>
          <td>R$</td>
          <td>-</td>
          <td>-</td>
          <td class="table-bold">
            {{ config.totCustoMatrixSm | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["config"],
};
</script>
<style lang="scss" scoped>
.table-card {
  margin-top: 25px;
  border-radius: 0 0 0.3em 0.3em;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  box-shadow: 2px 2px 5px 0 #2a2a2a22;
}
.table-subtitle {
  padding: 0.4em;
  background-color: #cecece;
  font-size: 0.9rem;
  font-weight: 700;
  border-top: solid 1px #f86511;
}
table {
  border-collapse: collapse;
  margin: 0;
  font-size: 0.9rem;
  border-radius: 0 0 0.3em 0.3em;

  thead {
    background-color: #e8e8e8;
  }

  tbody {
    tr {
      width: 100%;
      &:nth-child(even) {
        background-color: #f2edeb;
      }
      &:last-child {
        border-radius: 0 0 0.3em 0.3em;
        :first-child {
          border-radius: 0 0 0 0.3em;
        }
        :last-child {
          border-radius: 0 0 0.3em 0;
        }
      }
    }
  }

  .white-row {
    background-color: #ffffff !important;
  }

  .table-bold {
    font-weight: 700;
  }

  .table-total {
    background-color: #fff1ec;
    border-top: solid 1px #f86511;
    border-radius: 0 0 0.3em 0;
  }
}
</style>
