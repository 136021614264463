var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showModalContract)?_c('modal-base',{attrs:{"maxWidth":'80%',"data-cy":"modal-fechar_modal_contratos_pro_infa-pos-venda-modais"},on:{"closeModal":function () {
        _vm.showModalContract = false;
        _vm.pontosTable = [];
      }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Contratos Associados ao Ponto "+_vm._s(_vm.pontosTable.codigo)+" ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"body_contratos_associados"},[_c('data-table',{attrs:{"fields":_vm.CONTRATO_TABLE_FIELDS,"data":_vm.pontosTable.contratos},scopedSlots:_vm._u([{key:"cpnj",fn:function(ref){
      var row = ref.row;
return _c('div',{},[_vm._v(_vm._s(row.empresa.cnpj))])}},{key:"apelido",fn:function(ref){
      var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.empresa.apelido)+" ")])}},{key:"razaoSocial",fn:function(ref){
      var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.empresa.razaoSocial)+" ")])}}],null,false,252712487)})],1)]},proxy:true}],null,false,2894229088)}):_vm._e(),_c('collapsible-card',{ref:"cardCollapsibleRef",staticStyle:{"margin-bottom":"20px"},attrs:{"loading":_vm.loading,"colorHeader":"#0F214A","itemIndex":_vm.itemIndex},on:{"handleIcon":function($event){_vm.pontoMedicao.opened = !_vm.pontoMedicao.opened}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"w-100 row fs07 py-1 d-flex align-items-center"},[(!_vm.pontoMedicao.proinfaConfigurado)?_c('div',{staticClass:"col col-3 d-flex align-items-center"},[(!_vm.pontoMedicao.hasProinfas)?_c('b',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.getNoProinfaMessage))]),_c('img',{staticStyle:{"margin-left":"10px"},attrs:{"src":_vm.warnIcon}})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"col col-6"},[_c('span',[_vm._v(" Ponto: "),_c('b',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.pontoMedicao.codigo))])]),_c('span',[_vm._v(" - Retaguarda: "),_c('b',[_vm._v(" "+_vm._s(_vm.pontoMedicao.retaguarda || "-------------")+" ")])]),(_vm.pontoMedicao.contratos.length === 1)?_c('span',[_vm._v(" - Contrato: "),_c('b',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.pontoMedicao.contratos[0].codigo)+" ")])]):_vm._e()]),(
            _vm.pontoMedicao.contratos.length > 0 &&
            _vm.pontoMedicao.contratos.length != 1
          )?_c('div',{staticClass:"col col-3 d-flex justify-content-end"},[_c('button',{staticClass:"btn-ver-contratos fs07",staticStyle:{"padding":"8px","border-radius":"9px","width":"250px"},attrs:{"data-cy":"button-ver_contratos_associardos-proinfa-components"},on:{"click":function($event){$event.stopPropagation();_vm.showModalContract = true;
              _vm.pontosTable = _vm.pontoMedicao;}}},[_c('b',[_vm._v(" Ver Contratos Associados ")])])]):_vm._e()])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"row py-3"},[_c('b',[_vm._v("PROINFA (MWh)")])]),_c('table',{staticClass:"table"},[_c('thead',[_c('th',{staticClass:"text-left px-2",staticStyle:{"width":"7%"}},[_vm._v("Ano")]),_vm._l((_vm.MONTHS),function(month,i){return _c('th',{key:i,staticClass:"text-left px-2",staticStyle:{"width":"7%"}},[_vm._v(" "+_vm._s(month.title)+" ")])})],2),_c('tbody',[_c('div',{staticClass:"top-line"}),_vm._l((_vm.pontoMedicao.proinfas),function(proinfa,i){return _c('tr',{key:i},[_c('td',[_vm._v(" "+_vm._s(proinfa.ano)+" ")]),_vm._l((_vm.MONTHS),function(month,j){return _c('td',{key:(i + "-" + j)},[_c('vue-numeric',{staticClass:"form-control",staticStyle:{"font-size":"0.8em !important"},attrs:{"min":0,"precision":3,"placeholder":"0,000","decimal-separator":",","thousand-separator":".","disabled":_vm.IS_DISABLED_FIELD(month, proinfa.ano)},model:{value:(proinfa[month.name].valor),callback:function ($$v) {_vm.$set(proinfa[month.name], "valor", $$v)},expression:"proinfa[month.name].valor"}})],1)})],2)})],2)]),_c('div',{staticClass:"row d-flex align-items-center justify-content-end"},[_c('button',{staticClass:"col col-2 btn btn-primary-full-filled me-4",attrs:{"disabled":!_vm.$can('CONFIGURAR_PROINFA_VISUALIZAR_EDITAR'),"data-cy":"button-salvar_contratos_proinfa-proinfa-components"},on:{"click":function($event){return _vm.savePontoMedicao(_vm.pontoMedicao)}}},[_vm._v(" Salvar ")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }