<template>
  <div class="page-faturamento">
    <modal-cadastro-pendente-sem-ponto
      :modelProp="modelLine"
      @closeModal="closeModal()"
      @associarPonto="associarPonto()"
      v-if="showModalCadastroPendenteSemPonto"
      data-cy="modal-modal_cadastro_pendente_sem_ponto-faturamento-components"
    />
    <modal-cadastro-pendente-ponto-desabilitado
      :modelProp="modelLine"
      @closeModal="closeModal()"
      @associarPonto="associarPonto()"
      v-if="showModalCadastroPendentePontoDesabilitado"
      data-cy="modal-modal_cadastro_pendente_sem_ponto-faturamento-components"
    />
    <modal-cadastro-pendente-sem-proinfa
      :modelProp="modelLine"
      @closeModal="closeModal()"
      @configurarProinfa="configurarProinfa()"
      v-if="showModalCadastroPendenteSemProinfa"
      data-cy="modal-modal_cadastro_pendente_sem_proinfa-faturamento-components"
    />
    <progress-bars-faturamento />
    <field-search
      style="margin-top: 20px"
      @getSearchValue="getSearchValue"
      :placeholder="'Busque por Apelido ou Contrato'"
    />
    <processing-count
      :key="'count'"
      :loading="loading"
      ref="processingCount"
      :WBCLoading="WBCLoading"
      style="margin-top: 14px"
      :quantidades="quantidades"
      :quantidadeWBC="quantidadeWBC"
      :divergenciasLoading="divergenciasLoading"
      :quantidadeDivergencias="quantidadeDivergencias"
      @filterByContratoStatus="filterByContratoStatus"
      @clearFilterByContratoStatus="clearFilterByContratoStatus"
    />
    <div class="contratos_table-topbar">
      <div class="extrator">
        <Extrator />
      </div>
      <filter-bar
        :parentIsLoading="loading"
        @filterClear="clearFilters"
        @applyFilter="getFilterData"
        :filtersBundle="filtersBundle"
      />
    </div>
    <div>
      <page-painel
        key="table-painel"
        style="min-height: 400px"
        class="page-faturamento__panel"
        v-elementAsyncLoader="tableLoading"
        customHeight="auto"
      >
        <template #body>
          <div class="page-faturamento__body">
            <data-table
              :stripes="true"
              :pagination="true"
              @setPage="setPage"
              :lineClickable="true"
              @setPerPage="setPerPage"
              @lineClicked="lineClicked"
              :data="tableDataContratos"
              :paginationData="paginationData"
              :fields="tableFaturamentoContratosResumo.getTableFields()"
            >
              <div slot="ultimoDetalhe" slot-scope="{ row }">
                <table-details :row="row" />
              </div>
            </data-table>
          </div>
        </template>
      </page-painel>
    </div>
  </div>
</template>
<script>
import FieldSearch from "@/components/fields/FieldSearch.vue";
import ModalBase from "@/components/modals/ModalBase.vue";
import PagePainel from "@/components/panels/PagePanel.vue";
import ProgressBarsFaturamento from "@/components/progress-bar/ProgressBarsFaturamento.vue";
import DataTable from "@/components/tables/DataTable.vue";
import TableFaturamentoContratosResumoModel from "@/models/faturamento/TableFaturamentoContratosResumoModel";
import ProcessingCount from "@/pages/faturamento/components/contador/ProcessingCount.vue";
import Extrator from "@/pages/faturamento/components/extrator/Extrator.vue";
import FilterBar from "@/pages/faturamento/components/filter/FilterBar.vue";
import ModalCadastroPendentePontoDesabilitado from "@/pages/faturamento/components/modais/ModalCadastroPendentePontoDesabilitado.vue";
import ModalCadastroPendenteSemPonto from "@/pages/faturamento/components/modais/ModalCadastroPendenteSemPonto.vue";
import ModalCadastroPendenteSemProinfa from "@/pages/faturamento/components/modais/ModalCadastroPendenteSemProinfa.vue";
import TableDetails from "@/pages/faturamento/components/tables/TableDetails.vue";
import EmpresaService from "@/services/EmpresaService";
import FaturamentoService from "@/services/FaturamentoService";

export default {
  data() {
    return {
      filters: {},
      searchFilter: {},
      filtersBundle: null,
      paginationFilters: {
        page: 0,
        size: 10,
        desc: true,
      },
      loading: false,
      quantidadeWBC: 0,
      WBCLoading: false,
      cronPreferences: "0",
      tableDataContratos: [],
      tableLoading: undefined,
      modelLine: new Object(),
      idsInterval: new Array(),
      quantidades: new Array(),
      quantidadeDivergencias: 0,
      paginationData: undefined,
      divergenciasLoading: false,
      showModalCadastroPendenteSemPonto: false,
      showModalCadastroPendenteSemProinfa: false,
      showModalCadastroPendentePontoDesabilitado: false,
      tableFaturamentoContratosResumo:
        new TableFaturamentoContratosResumoModel(),
    };
  },
  async mounted() {
    this.applyLocalFilters();
    await this.getQuantidades();

    if (
      JSON.parse(window.sessionStorage.getItem("was_token_recently_refreshed"))
    ) {
      window.sessionStorage.setItem("was_token_recently_refreshed", false);
      this.mxToggleToast({
        message: "Token de acesso atualizado com sucesso",
      });
    }
  },
  methods: {
    applyLocalFilters() {
      if (window.sessionStorage.getItem("f-keys")) {
        const filters = JSON.parse(
          Buffer.from(
            window.sessionStorage.getItem("f-keys"),
            "base64"
          ).toString()
        );
        this.filtersBundle = filters;
        if (this.filtersBundle.grupo) {
          this.$refs.processingCount.itemSelected = this.filtersBundle.grupo;
        }
        this.getFilterData(this.filtersBundle);
      } else {
        this.getContratosResumo();
      }
    },
    async getQuantidades() {
      this.loading = true;
      FaturamentoService.getQuantidade({
        ...this.searchFilter,
        ...this.filters,
      })
        .then(async (response) => {
          const { data } = response;
          if (data)
            this.quantidades = new Array(...data, {
              titulo: "Divergências de Contrato",
              removeIt: false,
            });
          await this.getQuantidadesWBC();
          await this.getQuantidadesCentral();
        })
        .catch((error) => this.mxHandleRequestError(error))
        .finally(() => {
          this.loading = false;
        });
    },
    async getQuantidadesWBC() {
      this.WBCLoading = true;
      FaturamentoService.getQuantidadeWBC({
        ...this.searchFilter,
        ...this.filters,
      })
        .then((response) => {
          const { data } = response;
          if (data) {
            this.quantidadeWBC = data.quantidade;
          }
        })
        .catch((error) => this.mxHandleRequestError(error, "WBC_CARD"))
        .finally(() => (this.WBCLoading = false));
    },
    async getQuantidadesCentral() {
      this.divergenciasLoading = true;

      EmpresaService.getQuantidadeInconsistentes()
        .then(({ data }) => {
          if (data) {
            this.quantidadeDivergencias = data.quantidade;
          }
        })
        .catch((error) => this.mxHandleRequestError(error, "WBC_CARD"))
        .finally(() => (this.divergenciasLoading = false));
    },
    filterByContratoStatus({ grupoId: grupo }) {
      this.filters.grupo = grupo;
      let filters;
      if (window.sessionStorage.getItem("f-keys")) {
        filters = Buffer.from(
          window.sessionStorage.getItem("f-keys"),
          "base64"
        ).toString();
      }
      let newFilters;
      if (filters) {
        newFilters = JSON.stringify({ ...JSON.parse(filters), grupo });
      } else {
        newFilters = JSON.stringify({ grupo });
      }
      window.sessionStorage.setItem(
        "f-keys",
        Buffer.from(newFilters).toString("base64")
      );
      this.getContratosResumo();
    },
    clearFilterByContratoStatus() {
      this.filters.grupo = undefined;
      this.getContratosResumo();
    },
    setPage(page) {
      this.paginationFilters.page = page;
      this.getContratosResumo(true);
    },
    setPerPage(value) {
      this.paginationFilters.size = value;
      this.getContratosResumo();
    },
    getContratosResumo(changingPage = false) {
      if (!changingPage) this.paginationFilters.page = 0;
      this.tableLoading = FaturamentoService.getContratosResumo({
        ...this.searchFilter,
        ...this.filters,
        ...this.paginationFilters,
      })
        .then((data) => {
          this.tableDataContratos = data.content;
          this.paginationData = data;
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "FATURAMENTO_RESUMO");
        });
    },
    getSearchValue(value) {
      value ? (this.searchFilter.filtro = value) : (this.searchFilter = {});
      this.getContratosResumo();
      this.getQuantidades();
    },
    getFilterData(valueFilters) {
      let grupo;
      if (this.filters.hasOwnProperty("grupo")) {
        grupo = this.filters.grupo;
      }
      this.filters = {
        grupo,
      };
      Object.keys(valueFilters).forEach((key) => {
        this.filters[key] = valueFilters[key];
      });
      this.getContratosResumo();
      this.getQuantidades(valueFilters);
    },
    clearFilters() {
      this.filters = {};
      this.$refs.processingCount.itemSelected = null;
      this.getContratosResumo();
      this.getQuantidades();
    },
    checkStatus(status, statusEnum) {
      const keys = Object.keys(statusEnum);
      const array = keys.map((keys) => statusEnum[keys]);
      return array.filter((item) => item.status === status)[0];
    },
    lineClicked(contrato) {
      const {
        ultimoDetalhe: { descricao },
        statusAtual: { descricao: statusDescricao },
        empresa,
        id,
      } = contrato;

      if (
        descricao === "contrato s/ ponto" ||
        (descricao === "s/ ponto e proinfa" &&
          statusDescricao === "Cadastro Pendente")
      ) {
        this.modelLine = contrato;
        this.showModalCadastroPendenteSemPonto = true;
      } else if (descricao === "ponto desabilitado") {
        this.modelLine = contrato;
        this.showModalCadastroPendentePontoDesabilitado = true;
      } else if (
        descricao === "ponto s/ proinfa" &&
        statusDescricao === "Cadastro Pendente"
      ) {
        this.modelLine = contrato;
        this.showModalCadastroPendenteSemProinfa = true;
        return;
      } else {
        let query;
        if (descricao && descricao.toLowerCase() === "ponto sem acesso") {
          query = { pontoSemAcesso: true };
        }
        this.$router.push({
          path: `/faturamento/empresa/${empresa.id}/${id}/`,
          query,
        });
      }
    },
    closeModal() {
      this.showModalCadastroPendenteSemPonto = false;
      this.showModalCadastroPendenteSemProinfa = false;
      this.showModalCadastroPendentePontoDesabilitado = false;
    },
    associarPonto() {
      this.$router.push({ name: "Pontos Medicao", params: this.modelLine });
    },
    configurarProinfa() {
      this.$router.push({
        name: "Proinfa",
        query: { contrato: this.modelLine._codigo },
      });
    },
  },
  components: {
    Extrator,
    ModalBase,
    FilterBar,
    DataTable,
    PagePainel,
    FieldSearch,
    TableDetails,
    ProcessingCount,
    ProgressBarsFaturamento,
    ModalCadastroPendenteSemPonto,
    ModalCadastroPendenteSemProinfa,
    ModalCadastroPendentePontoDesabilitado,
  },
};
</script>
<style lang="scss" scoped>
.page-faturamento {
  margin-top: 20px;
  &__body {
    padding: 24px 32px;
  }
}
.btn-common {
  width: 194px;
  padding: 8px 16px;
  font-weight: 700;
  border-radius: 9px;
}
.btn-margin {
  margin-right: 20px;
}
.btn-faturamento {
  margin-right: 15rem;
}
.contratos_table-topbar {
  display: flex;
  justify-content: flex-end;
  margin: 24px 0;
  .extrator {
    position: absolute;
    margin-right: 150px;
    margin-top: 3px;
    z-index: 10;
  }
}
</style>
