<template>
  <div class="sl-container" :style="[{ width: `${widthField}%` }]">
    <div class="sl-title" :style="[{ color: `${colorTitleField}` }]">
      {{ titleField }}
    </div>
    <p
      :style="[{ color: `${colorValueField}` }]"
      v-if="titleField === 'Novo Solicitado Líquido'"
    >
      {{ valueInfo | volume }} MWh
    </p>
    <p :style="[{ color: `${colorValueField}` }]" v-else>
      {{ valueInfo }}
    </p>
  </div>
</template>

<script>
import { VOLUME } from "@/filters/formatters";
export default {
  props: {
    titleField: {
      type: String,
    },
    valueInfo: {
      type: [Number, String],
    },
    colorTitleField: {
      type: String,
      default: "#2A2A2A",
    },
    colorValueField: {
      type: String,
      default: "#ec6623",
    },
    widthField: {
      type: Number,
      default: 16,
    },
  },
  filters: {
    VOLUME,
  },
};
</script>

<style lang="scss" scoped>
.sl-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
}

p {
  font-size: 14px;
  font-weight: 700;
}
</style>
