var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[(
      (
        _vm.$can('ALCADAS_APROVACAO_VISUALIZAR') ||
        _vm.$can('ALCADAS_APROVACAO_VISUALIZAR_EDITAR')
      )
    )?_c('card-base-parametros',{attrs:{"icon":_vm.clipboardCheckIcon,"title":"Alçadas de Aprovação","data-cy":"card-para_alcadas_aprovacao-parametrizacao-components"},nativeOn:{"click":function($event){return _vm.goToPage('/parametrizacao/alcada-aprovacao')}}}):_vm._e(),(
      (
        _vm.$can('CURTO_PRAZO_VISUALIZAR') ||
        _vm.$can('CURTO_PRAZO_VISUALIZAR_EDITAR')
      )
    )?_c('card-base-parametros',{attrs:{"title":"TUSD","icon":_vm.tusd,"data-cy":"card-para_tusd-parametrizacao-components"},nativeOn:{"click":function($event){return _vm.goToPage('/parametrizacao/tusd')}}}):_vm._e(),(
      (
        _vm.$can('CURTO_PRAZO_VISUALIZAR') ||
        _vm.$can('CURTO_PRAZO_VISUALIZAR_EDITAR')
      )
    )?_c('card-base-parametros',{attrs:{"icon":_vm.rocketIcon,"title":"Proposta de Curto Prazo","data-cy":"card-para_proposta_curto_prazo-parametrizacao-components"},nativeOn:{"click":function($event){return _vm.goToPage('/parametrizacao/curto-prazo')}}}):_vm._e(),(
      (
        _vm.$can('ROTINA_IMPORTACAO_VISUALIZAR') ||
        _vm.$can('ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR') ||
        _vm.$can('ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR_EDITAR')
      )
    )?_c('card-base-parametros',{attrs:{"icon":_vm.downloadIcon,"title":"Rotina de Importação","data-cy":"card-para_rotina_importacao-parametrizacao-components"},nativeOn:{"click":function($event){return _vm.goToPage('/parametrizacao/rotina-importacao')}}}):_vm._e(),(_vm.$can('FECHAR_FATURAMENTO_HABILITADO'))?_c('card-base-parametros',{attrs:{"icon":_vm.receipt,"title":"Fechar Faturamento","data-cy":"card-para_fechar_faturamento-parametrizacao-components"},nativeOn:{"click":function($event){return _vm.goToPage('/parametrizacao/fechar-faturamento')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }