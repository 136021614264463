export default class PosVendaModel {
  constructor(
    { id, apelido, cnpj, razaoSocial, posVenda, nomeFantasia } = new Object()
  ) {
    this._id = id;
    this._cnpj = cnpj;
    this._apelido = apelido;
    this._posVenda = posVenda;
    this._razaoSocial = razaoSocial;
    this._nomeFantasia = nomeFantasia;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id = null) {
    this._id = id;
  }

  get apelido() {
    return this._apelido ?? null;
  }

  set apelido(apelido = null) {
    this._apelido = apelido;
  }

  get cnpj() {
    if (this._cnpj) {
      return this._cnpj.replace(/[^\d]/g, "");
    }
    return null;
  }

  set cnpj(cnpj = null) {
    this._cnpj = cnpj;
  }

  get razaoSocial() {
    return this._razaoSocial ?? null;
  }

  set razaoSocial(razaoSocial = null) {
    this._razaoSocial = razaoSocial;
  }

  get posVenda() {
    return this._posVenda ?? null;
  }

  set posVenda(posVenda = null) {
    this._posVenda = posVenda;
  }

  get nomeFantasia() {
    return this._nomeFantasia ?? null;
  }

  set nomeFantasia(nomeFantasia = null) {
    this._nomeFantasia = nomeFantasia;
  }

  getData() {
    const { id, apelido, cnpj, razaoSocial, posVenda, nomeFantasia } = this;
    return {
      id,
      cnpj,
      apelido,
      posVenda,
      razaoSocial,
      nomeFantasia,
    };
  }
}
