<template>
  <div>
    <modal-base width="600px" :footerHide="true" @closeModal="close">
      <template #header> Fechamento do Faturamento </template>
      <template #body>
        <div class="container-body">
          <p>Faturamento fechado com sucesso!</p>
          <span>Aberto o período de Pré-Faturamento</span>
        </div>
      </template>
    </modal-base>
  </div>
</template>

<script>
import ModalBase from "@/components/modals/ModalBase.vue";
export default {
  methods: {
    close() {
      this.$emit("close");
    },
  },
  components: {
    ModalBase,
  },
};
</script>

<style lang="scss" scoped>
.container-body {
  text-align: center;
  margin: 32px 16px;

  p {
    margin: 0;
    font-weight: 700;
  }

  span {
    font-size: 15px;
  }
}
</style>