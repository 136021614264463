<template>
  <div class="d-flex">
    <overlay-loader v-if="loadingUpload" />
    <div class="upload-arquivo">
      Upload de Arquivos
      <div
        id="drop"
        @dragover.prevent
        multiple="multiple"
        @drop.prevent="drop"
        @dragenter="dragenter"
        class="drop mt-2"
      >
        <label id="uploadField" for="inputUploadField">
          <input
            multiple
            type="file"
            @change="changeFile"
            id="inputUploadField"
            name="inputUploadField"
            class="form-control mt-2"
          />
          <div class="input-upload" v-show="!loadingUpload">
            <i class="icon-exportador font-value"></i>
            <div>Arraste e solte arquivos aqui ou clique para enviar</div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import OverlayLoader from "@/components/OverlayLoader.vue";
export default {
  data() {
    return {
      model: {
        name: "arquivo",
        content: "",
      },
      loadingUpload: undefined,
    };
  },
  methods: {
    changeFile(event) {
      this.model.content = event.target.files;
      this.emitFilesToFormat();
    },
    drop(event) {
      document
        .getElementById("uploadField")
        .classList.remove("drop--drop-over");
      this.model.content = event.dataTransfer.files;
      this.emitFilesToFormat();
    },
    dragenter() {
      document.getElementById("uploadField").classList.add("drop--drop-over");
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Arquivo inválido");
        return true;
      }
      return false;
    },
    emitFilesToFormat() {
      this.$emit("formatFiles", this.model.content);
    },
    excluirArquivos(index) {
      this.arquivos.splice(index, 1);
    },
  },
  validations: {
    model: {
      content: {
        required,
      },
    },
  },
  components: {
    OverlayLoader,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100% !important;
}

.upload-arquivo {
  font-size: 16px;
  font-weight: 300;
}

.drop--drop-over {
  border: 2px solid #ffb946 !important;
}

.input-upload {
  gap: 20px;
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 0px 35px 35px 35px;
}

#uploadField:hover {
  opacity: 0.5;
  transition: 0.2s ease-in-out;
}

#uploadField {
  position: relative;
  border-radius: 5px;
  border: 1px dashed #2a2a2a;
}

#uploadField span {
  top: 10%;
  left: 30%;
  width: 40%;
  font-size: 16px;
  text-align: center;
  position: absolute;
}

#uploadField span i {
  margin-bottom: 20px;
}

#uploadField input {
  opacity: 0;
}

.font-value {
  font-size: 3em;
}
</style>
