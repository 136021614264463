<template>
  <div class="card-container">
    <div class="_card-base">
      <div
        class="_card-base__header"
        :style="[{ backgroundColor: `${colorHeader}` }]"
      >
        <slot name="header"></slot>
      </div>
      <div class="_card-base__body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    subtitle: String,
    colorHeader: String,
  },
};
</script>
<style lang="scss" scoped>
.card-container {
  flex: 1;
  display: flex;
}
._card-base {
  width: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 20px;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;

  &__header {
    font-size: 1rem;
    font-weight: 700;
    padding: 8px 25px;
    line-break: strict;
    background: #0f214a;
    color: $color--primary-white;
  }
  &__body {
    flex: 1;
    padding: 20px;
    padding-top: 2px;
    margin-bottom: 5px;
    line-break: strict;
    border: 1px solid #c4c4c4;
    border-radius: 0 0 20px 20px;
    background: $color--primary-white;
  }
}
</style>