<template>
  <div class="container-field">
    <div class="mb-1">Hora:</div>
    <time-picker
      :value="content"
      :disabled="disabled"
      ref="timePickerField"
      :startScore="1"
      :intervalInMinutes="30"
      :AuxiliaryIndexInMinutes="0"
      @input="(value) => (content = value)"
      data-cy="input-hora_field-pld-spread-components-fields"
    />
  </div>
</template>
<script>
import TimePicker from "@/components/TimePicker/TimePicker.vue";
export default {
  name: "FieldHora",
  props: {
    modelProp: String,
    disabled: Boolean,
  },
  watch: {
    modelProp: {
      handler() {
        this.content = this.modelProp;
      },
    },
  },
  data() {
    return {
      content: null,
    };
  },
  components: {
    TimePicker,
  },
};
</script>

<style lang="scss" scoped>
.container-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 30px;
}

label {
  color: #000;
  font-weight: 500;
}

input {
  width: 90px;
  padding: 5px;
  color: #505050;
  margin-top: 3px;
}
</style>