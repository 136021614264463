const toast = {
  state: {
    toast: {
      title: "",
      message: "",
      type: "success",
      show: false,
    },
    instance: null,
  },
  getters: {
    getToast(state) {
      return state.toast;
    },
    getToastInstance(state) {
      return state.instance;
    },
  },
  mutations: {
    toggleToast(state, { title, message, type }) {
      state.toast = {
        title,
        message,
        type,
        show: true,
      };
    },
    holdToastInstance(state, value) {
      state.instance = value;
    },
    resetToastInstance(state) {
      state.instance = null;
    },
  },
  actions: {
    ActionToggleToast(context, value) {
      context.commit("toggleToast", value);
    },
    ActionHoldToastInstance(context, value) {
      context.commit("holdToastInstance", value);
    },
    ActionResetToastInstance(context) {
      context.commit("resetToastInstance");
    },
  },
};

export default toast;
