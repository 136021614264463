export default {
  ENVIO_OPTIONS: [
    {
      id: "AUTOMATICO",
      descricao: "Automático",
    },
    {
      id: "MANUAL",
      descricao: "Manual",
    },
  ],
};
