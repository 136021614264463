<template>
  <div class="body-email-precos-diferentes">
    <div
      class="col-md-12"
      v-if="isVisibleInfoContract"
      style="margin-top: 50px"
    >
      <card-base :colorHeader="'#0F214A'">
        <template #header>Contrato</template>
        <template #body>
          <div class="card-body">
            <div class="item-card">
              <span class="card-label">Controle Matrix</span>
              <span class="card-value">{{ contrato.codigo }}</span>
            </div>
            <div class="item-card">
              <span class="card-label">Qtd. Contratada (MWh)</span>
              <span class="card-value">{{
                formatterVolume(contrato.flexibilidade.quantidadeContratada)
              }}</span>
            </div>
            <div class="item-card">
              <span class="card-label">Limite Mín. (MWh)</span>
              <span class="card-value">{{
                formatterVolume(contrato.flexibilidade.limiteMinimo)
              }}</span>
            </div>
            <div class="item-card">
              <span class="card-label">Limite Máx. (MWh))</span>
              <span class="card-value">{{
                formatterVolume(contrato.flexibilidade.limiteMaximo)
              }}</span>
            </div>
            <div class="item-card">
              <span class="card-label">Volume Total a Faturar</span>
              <span class="card-value">{{
                formatterVolume(contrato.calculoResultado.volumeAFaturar)
              }}</span>
            </div>
          </div>
        </template>
      </card-base>
    </div>
    <div class="section-title" v-if="isVisibleInfoContract">
      Distribuição por unidades
    </div>
    <div class="section-title" v-else>
      {{
        `Análise de Recompra - Preços Diferentes - ${dataPrecoDiferente.length} contratos filhos`
      }}
    </div>
    <div class="section-table">
      <div class="box-title-table">
        <span
          class="title-table"
          style="
            background: #1f4496;
            border-radius: 10px 0px 0px 0px;
            width: 38%;
          "
        >
          Faturamento Sem Recompra
        </span>
        <span class="title-table" style="background: #0f214a; width: 30%">
          Recompra
        </span>
        <span
          class="title-table"
          style="
            background: #ec6623;
            border-radius: 0px 10px 0px 0px;
            width: 35%;
          "
        >
          Faturamento Com Recompra
        </span>
      </div>
      <div class="box-table">
        <data-table
          :data="dataPrecoDiferente"
          :fields="TableRecompraPrecoDiferente.getTableFields()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { VOLUME } from "@/filters/formatters";
import CardBase from "@/components/cards/cardBase.vue";
import DataTable from "@/components/tables/DataTable.vue";

import { TableRecompraPrecoDiferente } from "@/models/tablesModels/TableRecompraPrecoDiferente";

export default {
  props: {
    isVisibleInfoContract: {
      type: Boolean,
      default: false,
    },
    contrato: {
      type: Object,
    },
    dataPrecoDiferente: {
      type: Array,
    },
  },
  methods: {
    formatterVolume(value) {
      return VOLUME(value);
    },
  },
  computed: {
    TableRecompraPrecoDiferente: () => TableRecompraPrecoDiferente,
  },
  components: {
    CardBase,
    DataTable,
  },
};
</script>

<style lang="scss" scoped>
.body-email-precos-diferentes {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.section-title {
  width: 100%;

  padding: 7px 20px;
  margin: 10px 0px;

  background: #08132c;
  color: white;

  font-weight: 700;
  font-size: 16px;

  border-radius: 20px;
}

.section-table {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;

  margin: 10px 0px;

  border-radius: 10px;
  border: 1px solid #c4c4c4;
}

.box-title-table {
  display: flex;
  align-items: center;

  height: 50px;
  width: 100%;
}

.title-table {
  height: 100%;

  padding: 10px;

  font-weight: 700;
  font-size: 16px;

  color: white;
}

.box-table {
  width: 100%;
  height: 90%;
}

.card-body {
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 100%;
}

.item-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 10px;
  margin: 0 5px;
}

.card-label {
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 24px;
  color: #544943;
}
.card-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2a2a2a;
}
</style>
