<template>
  <div>
    <div class="row" :style="{ height: ROW_HEIGHT }">
      <span class="col col-12 py-3" v-if="pontosMedicao.length > 0">
        Pontos de medição associados ao contrato <b>{{ contrato }}:</b>
      </span>
    </div>
    <card-contrato-proinfa
      :pontoMedicao="pontoMedicao"
      @reload="getContratoReload"
      :itemIndex="pontoMedicaoIndex"
      :key="`contrato-card-${pontoMedicaoIndex}`"
      v-for="(pontoMedicao, pontoMedicaoIndex) in pontosMedicao"
    />
  </div>
</template>

<script>
import CardContratoProinfa from "./CardContratoProinfa.vue";
export default {
  props: {
    contrato: String,
    pontosMedicao: Array,
  },
  computed: {
    ROW_HEIGHT() {
      return this.pontosMedicao.length > 0 ? "auto" : "70px";
    },
  },
  methods: {
    getContratoReload() {
      this.$emit("proinfaReload");
    },
  },
  components: {
    CardContratoProinfa,
  },
};
</script>

<style lang="scss" scoped>
.btn-ver-contratos,
.btn-ver-contratos:active,
.btn-ver-contratos:visited,
.btn-ver-contratos:focus {
  height: 34px;
  font-size: 12px;
  width: 294.52px;
  border-radius: 9px;
  color: $color--blue-btn !important;
  background: $color--primary-white !important;
  border: 1px solid $color--primary-white !important;

  &:hover {
    color: $color--primary-white !important;
    background: $color--blue-btn !important;
    border: 1px solid $color--primary-white !important;
  }
}

.btn-primary-full-filled,
.btn-secondary-full-filled {
  width: 117px;
  height: 34px;
}

.table td {
  border-top: 2px solid rgb(0, 0, 0) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  margin-left: 40px;
}

.body_contratos_associados {
  margin: 10px 30px;
}
</style>
