var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_upload-manual"},[(_vm.showModalUploadManual)?_c('modal-base',{attrs:{"maxWidth":"80%","data-cy":"modal-fechar_modal_upload_manual-modais"},on:{"closeModal":_vm.close},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Realizar Upload Manual")]},proxy:true},{key:"body",fn:function(){return [_c('div',{directives:[{name:"elementAsyncLoader",rawName:"v-elementAsyncLoader",value:(_vm.loading),expression:"loading"}],staticClass:"_upload-manual__body",staticStyle:{"position":"relative"}},[(_vm.loadingLoad)?_c('overlay-loader'):_vm._e(),_c('div',[_vm._v(" Adicionar aqui os arquivos de medição referentes a todos os pontos de medição do contrato "),_c('b',[_vm._v(_vm._s(_vm.contrato.codigo))])]),_c('div',{staticClass:"box-upload"},[_c('div',{staticClass:"item-upload"},[_c('div',{staticClass:"mt-3",staticStyle:{"margin-bottom":"15px"}},[_c('div',[_c('b',[_vm._v("Upload de Arquivos")])]),_c('contrato-upload-manual-field',{directives:[{name:"elementAsyncLoader",rawName:"v-elementAsyncLoader",value:(_vm.filesLoading),expression:"filesLoading"}],on:{"changeFiles":_vm.handleFiles}})],1),_c('div',{staticClass:"mt-3"},[_c('div',[_c('b',[_vm._v("Arquivos Enviados")])]),[_c('data-table',{directives:[{name:"elementAsyncLoader",rawName:"v-elementAsyncLoader",value:(_vm.fieldsFilesLoading),expression:"fieldsFilesLoading"}],attrs:{"fields":_vm.fieldsFiles,"data":_vm.dataFiles,"headerStick":"","maxItemsToScroll":5},scopedSlots:_vm._u([{key:"download",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"alt":"Download icon","src":_vm.icon.download,"data-cy":"img-baixar_arquivo_upload-modais"},on:{"click":function($event){return _vm.downloadArquivo(row.idHistoricoAquivo, row.nome)}}})])}},{key:"delete",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"alt":"Delete icon","src":_vm.icon.trasher,"data-cy":"img-excluir_arquivo_upload-modais"},on:{"click":function($event){return _vm.excluirArquivo(row.idHistoricoAquivo)}}})])}}],null,false,3510270754)})]],2)]),_c('div',{staticClass:"item-upload"},[_c('data-table',{attrs:{"headerStick":"","data":_vm.dataContratos,"maxItemsToScroll":10,"fields":_vm.fieldsContratos},scopedSlots:_vm._u([{key:"icons",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.isValid)?_c('img',{attrs:{"src":_vm.icon.check}}):_c('img',{attrs:{"src":_vm.icon.exclamation}})])}}],null,false,2720951844)})],1)])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"wrapper-buttons"},[_c('div',{staticClass:"btn-calcular"},[_c('button',{staticClass:"btn btn-action-primary",attrs:{"disabled":_vm.IS_DISABLE_FINISH ||
              _vm.overlayLoading ||
              _vm.arquivosEnviados.length === 0 ||
              _vm.loadingLoad,"data-cy":"button-realizar_upload_manual-contratos-"},on:{"click":_vm.loadManual}},[_vm._v(" Calcular Medições ")])])])]},proxy:true}],null,false,3913917322)}):_vm._e(),_c('button',{staticClass:"btn btn-action-secondary-outlined",attrs:{"disabled":_vm.showModalUploadManual,"data-cy":"button-mostar_modal_upload_manual-contratos-"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.showModalUploadManual = true}}},[_vm._v(" Realizar Upload Manual ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }