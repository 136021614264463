<template>
  <div>
    <table class="table table-faturamento-contrato-wbc">
      <tbody>
        <tr>
          <td><b>Flexibilidade</b></td>
          <td>{{ CONTRATO_FATURADO_CONTRATO | EMPTY }}</td>
        </tr>
        <tr>
          <td><b>Preço</b></td>
          <td>{{ CONTRATO_FATURADO_PRECO | EMPTY }}</td>
        </tr>
        <tr>
          <td><b>Reajuste de Preço</b></td>
          <td>Não se Aplica</td>
        </tr>
        <tr>
          <td><b>Tributos</b></td>
          <td>No preço estão inclusos PIS/COFINS, EXCETO ICMS</td>
        </tr>
        <tr>
          <td><b>Condições de Pagamento</b></td>
          <td>
            {{ CURTO_PRAZO_PARAMETRIZACAO_CONDICAO_DE_PAGAMENTO | EMPTY }}
          </td>
        </tr>
        <tr>
          <td><b>Registro CliqCCEE</b></td>
          <td>Registro na CCEE Contra Pagamento</td>
        </tr>
        <tr>
          <td><b>Garantia</b></td>
          <td>Não Possui</td>
        </tr>
        <tr>
          <td><b>Multas e Juros</b></td>
          <td class="td-width">
            {{
              CONTRATO_FATURADO_CURTO_PRAZO_PARAMETRIZACAO_MULTA_JUROS | EMPTY
            }}
          </td>
        </tr>
        <tr>
          <td><b>Reembolso TUSD (TRU)</b></td>
          <td>{{ CONTRATO_FATURADO_CURTO_PRAZO_TUSD_DESCRICAO | EMPTY }}</td>
        </tr>
        <tr>
          <td><b>Validade da Proposta</b></td>
          <td>{{ CONTRATO_FATURADO_DATA_LIMITE | EMPTY }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { DateTime } from "luxon";
import { EMPTY } from "@/filters/formatters";
import { formatData } from "@/utils/functionsUtils";
export default {
  props: {
    contratoSelecionado: Object,
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
  filters: {
    EMPTY,
  },
  computed: {
    CONTRATO_FATURADO_CONTRATO() {
      if (this.contratoSelecionado && this.contratoSelecionado.contrato) {
        return this.contratoSelecionado.contrato.tipo;
      }
    },
    CONTRATO_FATURADO_CURTO_PRAZO_PARAMETRIZACAO_MULTA_JUROS() {
      if (
        this.contratoSelecionado &&
        this.contratoSelecionado.curtoPrazoParametrizacao
      ) {
        return this.contratoSelecionado.curtoPrazoParametrizacao.multaJuros;
      }
    },
    CONTRATO_FATURADO_CURTO_PRAZO_TUSD_DESCRICAO() {
      if (this.contratoSelecionado && this.contratoSelecionado.curtoPrazoTusd) {
        return this.contratoSelecionado.curtoPrazoTusd.descricao;
      }
    },
    CONTRATO_FATURADO_DATA_LIMITE() {
      if (this.contratoSelecionado.dataLimite) {
        return formatData(
          this.contratoSelecionado.dataLimite,
          "dd/MM/yyyy HH:mm:ss"
        );
      }
    },
    CONTRATO_FATURADO_PRECO() {
      if (this.contratoSelecionado) {
        return this.contratoSelecionado.preco;
      }
    },
    CURTO_PRAZO_PARAMETRIZACAO_CONDICAO_DE_PAGAMENTO() {
      if (this.contratoSelecionado && this.contratoSelecionado.curtoPrazoParametrizacao) {
        return this.contratoSelecionado.curtoPrazoParametrizacao
          .condicaoPagamento;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.td-width {
  max-width: 750px;
}

.table-faturamento-contrato-wbc {
  width: 90%;
  margin: 44px 0px 49px 36px;
}

tr {
  border-bottom: solid 1px #eee;
}
</style>