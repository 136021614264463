import { DateTime } from "luxon";
import { EMPTY } from "@/filters/formatters";
import { convertDateToGMT } from "@/utils/functionsUtils";

export default class HistoricoAcoesModel {
  constructor(
    {
      acao,
      dataHora,
      responsavel,
      descricao,
      contrato,
      status,
      link,
    } = new Object()
  ) {
    this._acao = acao;
    this._dataHora = dataHora;
    this._responsavel = responsavel;
    this._descricao = descricao;
    this._contrato = contrato;
    this._status = status;
    this._link = link;
  }

  get acao() {
    return this._acao;
  }

  set acao(acao) {
    this._acao = acao;
  }

  get dataHora() {
    return this._dataHora ?? null;
  }

  set dataHora(dataHora) {
    this._data = dataHora;
  }

  get responsavel() {
    return this._responsavel;
  }

  set responsavel(responsavel) {
    this._responsavel = responsavel;
  }

  get descricao() {
    return this._descricao;
  }

  set descricao(descricao) {
    this.descricao = descricao;
  }

  get status() {
    return this._status;
  }

  set status(status) {
    this._status = status;
  }

  get contrato() {
    return (
      this._contrato ?? {
        id: 0,
        codigo: "string",
        codigoContratoReferencia: "string",
        empresa: {
          id: 0,
          cnpj: "string",
          apelido: "string",
          razaoSocial: "string",
        },
      }
    );
  }

  set contrato(contrato) {
    this._contrato = contrato;
  }

  get link() {
    return this._link ?? null;
  }

  set link(link) {
    this._link = link;
  }
  getData() {
    const { acao, dataHora, responsavel, descricao, contrato, status, link } =
      this;
    return {
      acao,
      dataHora,
      responsavel,
      descricao,
      contrato,
      status,
      link,
    };
  }

  static getTableFields() {
    return new Array(
      {
        name: "contrato",
        title: "Contrato",
        formatter: (value) => {
          return EMPTY(value.codigo);
        },
      },
      {
        name: "acao",
        title: "Ação",
        formatter: (value) => {
          return EMPTY(value);
        },
      },
      {
        name: "dataHora",
        title: "Data",
        formatter: (value) => {
          return convertDateToGMT(value);
        },
      },
      {
        name: "status",
        title: "Status",
        align: "center",
        formatter: (value) => {
          return EMPTY(value);
        },
      },
      {
        name: "responsavel",
        title: "Responsável",
        formatter: (value) => {
          return EMPTY(value);
        },
      },
      {
        name: "descricao",
        title: "Descrição",
        formatter: (value) => {
          return EMPTY(value);
        },
      },
      {
        name: "link",
      }
    );
  }
}
