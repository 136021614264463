<template>
  <div v-elementAsyncLoader="loading">
    <div class="page-exportador__tabs_container">
      <div
        @click="$emit('changeTab', 'FLEX')"
        :class="{ active: is('FLEX') }"
        data-cy="div-mudar_aba_flex-pages-components-exportador"
      >
        Contratos Flex
      </div>
      <div
        @click="$emit('changeTab', 'FLAT')"
        :class="{ active: is('FLAT') }"
        data-cy="div-mudar_aba_flat-pages-components-exportador"
      >
        Contratos Flat
      </div>
      <div
        @click="$emit('changeTab', 'RECOMPRA')"
        :class="{ active: is('RECOMPRA') }"
        data-cy="div-mudar_aba_recompra-pages-components-exportador"
      >
        Contratos com Recompra
      </div>
      <div
        @click="$emit('changeTab', 'DESCONTO_GARANTIDO')"
        :class="{ active: is('DESCONTO_GARANTIDO') }"
        data-cy="div-mudar_aba_desconto_garantido-pages-components-exportador"
      >
        Contratos Desconto Garantido
      </div>
      <span style="display: flex; align-items: center">
        <label
          for="checkboxFiltroDivergencias"
          style="cursor: pointer; margin-right: 10px"
        >
          Aplicar filtro divergências
        </label>
        <input
          type="checkbox"
          v-model="doFilterByInconsistency"
          id="checkboxFiltroDivergencias"
          style="width: 20px; height: 20px; cursor: pointer"
        />
      </span>
    </div>
    <page-panel hasTabs customHeight="60vh">
      <template #body>
        <div class="page-exportador__body">
          <data-table
            stripes
            headerStick
            customWidth
            :data="TABLE_DATA_WITH_FILTER"
            :maxHeight="(device_height >= 1080 ? 60 : 50) + 'vh'"
            lineClass="contrato-exportador"
            :fields="model.getTableFields()"
          >
            <div
              style="cursor: pointer"
              slot="header-selecionar"
              @click="selectAllContratos"
              data-cy="div-selecionar_todos_contratos-pages-components-exportador"
            >
              Selecionar <small><u>(todos)</u></small>
            </div>
            <div slot="valorContratadoWbc" slot-scope="{ row }">
              <span
                :style="{
                  color: row.inconsistencies.preco ? 'red' : 'green',
                }"
              >
                {{ row.valorContratadoWbc | CURRENCY }}
              </span>
            </div>
            <div slot="montantesWbc" slot-scope="{ row }">
              <span
                :style="{
                  color: row.inconsistencies.quantidade ? 'red' : 'green',
                }"
              >
                {{ row.montantesWbc | VOLUME }}
              </span>
            </div>
            <div slot="selecionar" slot-scope="{ row }">
              <div class="wrapper-radio">
                <input
                  type="checkbox"
                  name="row.nome"
                  v-model="row.selected"
                  :disabled="row.tipoClasse !== 'PAI' && row.rateio"
                  @click="row.tipoClasse === 'PAI' && flagContratosFilhos(row)"
                  data-cy="input-check_box_selecionar_todos-pages-components-exportador"
                />
              </div>
            </div>
            <div slot="apelido" slot-scope="{ row }">
              <span v-if="row.tipoClasse === 'FILHO'">> {{ row.apelido }}</span>
              <span v-else>{{ row.apelido }}</span>
            </div>
            <div slot="statusIntegracao" slot-scope="{ row }">
              <span
                v-if="
                  row._statusIntegracao === 'Integrado' ||
                  row._statusIntegracao === 'Aguardando ajuste de Preço'
                "
              >
                {{ row._statusIntegracao }}
              </span>
              <div
                v-else-if="
                  row._statusIntegracao && row._statusIntegracao != 'Sucesso'
                "
              >
                <tooltip
                  width="300px"
                  arrowAlign="up"
                  alignVertical="25px"
                  alignHorizontal="-50px"
                >
                  <div slot="header" class="d-flex gap-2">
                    <img
                      alt="Informação"
                      src="@/assets/icons/svg/exclamation.svg"
                    />
                    <span>Erro</span>
                  </div>
                  <p slot="body">
                    {{ row._statusIntegracao }}
                  </p>
                </tooltip>
              </div>
              <span v-else>--</span>
            </div>
          </data-table>
          <div
            v-if="$can('EXPORTADOR_MONTANTES_VISUALIZAR_EXPORTAR')"
            class="mt-4 integrar-boletas-end"
          >
            <button
              :disabled="!SOME_CONTRATO_SELECTED"
              @click="integrarBoletasSelecionadas"
              class="btn btn-secondary-full-filled"
              data-testid="button-exportador-integrar-boletas-selecionadas"
              data-cy="button-integrar_boletas_selecionadas-pages-components-exportador"
            >
              Integrar Boletas Selecionadas
            </button>
            <button
              @click="exportarModeloWBC"
              :disabled="!SOME_CONTRATO_SELECTED"
              class="btn btn-primary-full-filled"
              data-cy="button-exportar_modelo_wbc-pages-components-exportador"
            >
              Exportar Modelo WBC
            </button>
            <button
              @click="exportarModeloWBC(true)"
              :disabled="!SOME_CONTRATO_SELECTED"
              class="btn btn-secondary-full-filled"
              data-cy="button-exportar_modelo_completo_wbc-pages-components-exportador"
            >
              Exportar Completo
            </button>
          </div>
        </div>
      </template>
      <template #footer />
    </page-panel>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip/Tooltip.vue";
import FieldSearch from "@/components/fields/FieldSearch.vue";
import PagePanel from "@/components/panels/PagePanel.vue";
import DataTable from "@/components/tables/DataTable.vue";
import { CONFIRMATION } from "@/constants/strings";
import FileTypeENUM from "@/enums/FileTypeENUM";
import TypesToastENUM from "@/enums/TypesToastENUM";
import { CURRENCY, VOLUME } from "@/filters/formatters";
import TableExportadorFlexModel from "@/models/tablesModels/TableExportadorFlexModel";
import ExportadorService from "@/services/ExportadorService";
import { downloadFileFromResponseObject } from "@/utils/functionsUtils";

export default {
  name: "ExportadorTable",
  props: {
    activeTab: {
      type: String,
      required: true,
      default: "FLEX",
    },
    model: {
      type: Function,
      required: true,
      default() {
        return new TableExportadorFlexModel();
      },
    },
  },
  data() {
    return {
      loading: undefined,
      tableData: new Array(),
      allContratosChecked: false,
      doFilterByInconsistency: false,
    };
  },
  computed: {
    SOME_CONTRATO_SELECTED() {
      return this.TABLE_DATA_WITH_FILTER.some((contrato) => contrato.selected);
    },
    TABLE_DATA_WITH_FILTER() {
      if (this.doFilterByInconsistency) {
        return this.tableData.filter((contrato) => {
          for (const key in contrato.inconsistencies) {
            if (contrato.inconsistencies[key]) {
              return true;
            }
          }
          return false;
        });
      }
      return this.tableData;
    },
    device_height: () => window.innerHeight,
  },
  watch: {
    activeTab: {
      handler() {
        this.getData();
      },
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    is(tab) {
      return this.activeTab === tab;
    },
    flagContratosFilhos(contrato) {
      const CONTRATOS_FILHOS_IDS = contrato.contratosFilhos.map(({ id }) => id);
      this.TABLE_DATA_WITH_FILTER.map((contrato) => {
        if (CONTRATOS_FILHOS_IDS.includes(contrato.id)) {
          contrato.selected = !contrato.selected;
        }
      });
    },
    selectAllContratos() {
      this.allContratosChecked = !this.allContratosChecked;
      this.TABLE_DATA_WITH_FILTER.map(
        (contrato) => (contrato.selected = this.allContratosChecked)
      );
    },
    getSelectedContratos() {
      return this.TABLE_DATA_WITH_FILTER.map(
        (contrato) => contrato.selected && contrato.id
      ).filter((value) => value);
    },
    exportarModeloWBC(completo = false) {
      if (this.is("DESCONTO_GARANTIDO")) {
        this.loading = ExportadorService.exportarModeloWBCDG(
          this.getSelectedContratos(),
          completo
        )
          .then((response) => {
            downloadFileFromResponseObject(
              response,
              this.model.fileName,
              FileTypeENUM.XLSX
            );
            this.mxToggleToast({
              title: "Sucesso",
              message: CONFIRMATION.EXPORTADOR_TABLE.MONTANTE_SUCCESS,
              type: TypesToastENUM.SUCCESS,
            });
            this.getData();
          })
          .catch((error) => this.mxHandleRequestError(error));
      } else {
        this.loading = ExportadorService.exportarModeloWBC(
          this.getSelectedContratos(),
          completo
        )
          .then((response) => {
            downloadFileFromResponseObject(
              response,
              this.model.fileName,
              FileTypeENUM.XLSX
            );
            this.mxToggleToast({
              title: "Sucesso",
              message: CONFIRMATION.EXPORTADOR_TABLE.MONTANTE_SUCCESS,
              type: TypesToastENUM.SUCCESS,
            });
            this.getData();
          })
          .catch((error) => this.mxHandleRequestError(error));
      }
    },
    integrarBoletasSelecionadas() {
      const integrarMethod = this.is("DESCONTO_GARANTIDO")
        ? "integrarBoletasSelecionadasDg"
        : "integrarBoletasSelecionadas";

      this.loading = ExportadorService[integrarMethod](
        this.getSelectedContratos()
      )
        .then((response) => {
          let boletasIntegradas = [];
          let falhouIntegracao = false;

          if (response.data && response.data.length > 0) {
            boletasIntegradas = response.data.filter((contrato) => {
              if (contrato.sucessoIntegracao === false) {
                falhouIntegracao = true;
              }
              return contrato.sucessoIntegracao;
            });
          }

          if (falhouIntegracao) {
            return this.mxToggleErrorMessageAlert(
              "Houve um erro ao integrar as boletas selecionadas."
            );
          }

          if (boletasIntegradas.length > 0) {
            let mensagemBoletasIntegradas = "Boletas Integradas: <br><br>";

            boletasIntegradas.forEach((boleta) => {
              mensagemBoletasIntegradas += `${boleta.codigo}; `;
            });

            this.mxToggleWarningModal({
              title: "Sucesso",
              btnTrue: "Fechar",
              showBtnFalse: false,
              message: mensagemBoletasIntegradas,
            });
          }
          this.getData();
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    getData(filtro = undefined) {
      this.tableData = new Array();
      const { endpoint } = this.model;
      return new Promise((resolve, reject) => {
        this.loading = ExportadorService.getExportador({ filtro }, endpoint)
          .then(({ data }) => {
            if (!data) return resolve();
            const contratos = new Array();
            data.map((contrato) => {
              let recompra;
              if (contrato.calculoFaturamento) {
                recompra = contrato.calculoFaturamento;
              }
              const contratoModel = new this.model({
                ...contrato,
                contrato: contrato.codigo,
                aprovacao: contrato.aprovacao,
                apelido: contrato.empresa.apelido,
                pontoMedicao:
                  contrato.pontoMedicao && contrato.pontoMedicao.codigo,
                isBoldStyle: contrato.tipoClasse === "PAI",
                montanteBilling: contrato.calculoFaturamento,
                volumeRecompra: recompra && recompra.volume,
                precoRecompra: recompra && recompra.preco,
                notionalRecompra: recompra && recompra.notional,
                novoFaturamento: recompra && recompra.volumeFinal,
                novoPrecoBilling: recompra && recompra.precoFinal,
                novoNotional: recompra && recompra.notionalFinal,
              });
              contratos.push(
                contratoModel,
                ...contratoModel.getContratosFilhos(this.model)
              );
            });
            this.tableData = contratos;
            resolve();
          })
          .catch((error) => {
            this.mxHandleRequestError(error);
            reject(error);
          });
      });
    },
  },
  filters: {
    VOLUME,
    CURRENCY,
  },
  components: {
    Tooltip,
    DataTable,
    PagePanel,
    FieldSearch,
  },
};
</script>

<style lang="scss" scoped>
.page-exportador {
  padding-top: 24px;
  &__body {
    padding-left: 30px;
    padding-right: 30px;
  }
  &__footer {
    display: flex;
    justify-content: end;

    & button {
      font-weight: bold;
      padding: 8px 32px;
      margin-left: 20px;
    }
  }

  &__tabs_container {
    width: 100%;
    display: flex;
    font-weight: bold;

    & div {
      cursor: pointer;
      font-size: 0.9em;
      margin-right: 1px;
      padding: 24px 32px;
      transition: 0.1s ease-in-out;
      background-color: #b4b7ba;
      border-top-left-radius: 8px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      box-shadow: 3px -2px 5px rgba(0, 0, 0, 0.2);
    }

    & div.active {
      box-shadow: none;
      background-color: $color--primary-white;
    }

    & span {
      margin-left: auto;
    }
  }
  .integrar-boletas-end {
    gap: 1rem;
    right: 3%;
    bottom: 5%;
    z-index: 1;
    display: flex;
    position: fixed;
    justify-content: flex-end;
    z-index: 2;
  }
}
</style>
