<template>
  <div>
    <div class="title-folding mb-3">
      <div>Análise de Recompra</div>
      <div class="text-icon">
        <label v-if="volumeExcedente < 0"
          >Take Mínimo Excedente:
          {{ (volumeExcedente * -1) | VOLUME }} MWh</label
        >
        <img
          v-if="volumeExcedente < 0"
          src="@/assets/icons/svg/exclamation.svg"
          alt=""
        />
        <label v-else>
          Take mínimo a Distribuir: {{ volumeExcedente | VOLUME }} MWh
        </label>
      </div>
    </div>
    <div class="table-analise">
      <overlay-loader v-if="loadingTableAnalise" />
      <data-table-recompra
        :data="data"
        :card="true"
        :footer="true"
        :stripes="true"
        :headerStick="true"
        style="width: 100%"
        :maxHeight="'600px'"
        :fields="TableRecompraPrecosDiferentes.getTableFields()"
      >
        <div slot="takeMinimo" slot-scope="{ row, index }">
          <vue-numeric
            :min="0"
            :precision="3"
            v-model="row.takeMinimo"
            placeholder="0,000"
            :decimal-separator="','"
            :thousands-separator="'.'"
            class="input-field-card"
            @input="calcularRecompra(index)"
          />
        </div>

        <div slot="volumeReduzir" slot-scope="{ row, index }">
          <vue-numeric
            :precision="3"
            placeholder="0,000"
            class="input-field-card"
            :decimal-separator="','"
            :thousands-separator="'.'"
            v-model="row.volumeReduzir"
            @input="calcularRecompra(index)"
          />
        </div>

        <div
          slot="novoPreco"
          slot-scope="{ row }"
          style="display: flex; align-items: center"
        >
          R$ {{ row.novoPreco | moeda }}
          <div
            v-if="
              getPerfilAprovadorSpreadRecompra.nome &&
              row.codigo === contratoMaiorSpread.codigo
            "
            class="aprovador-simbolo"
          >
            {{ getPerfilAprovadorSpreadRecompra.simbolo }}
          </div>
        </div>

        <div slot="footer-codigo">
          <b>Totais</b>
        </div>

        <div slot="footer-takeMinimo">
          <b>{{ somTakeMin | VOLUME }}</b>
        </div>

        <div slot="footer-volumeReduzir">
          <b>{{ somVolumeReduzir | VOLUME }}</b>
        </div>

        <div slot="footer-novoVolume">
          <b>{{ somNovoVolume | VOLUME }}</b>
        </div>

        <div slot="footer-novoNotional">
          <b>R$ {{ somNovoNotional | moeda }}</b>
        </div>

        <div slot="detail" slot-scope="{ row, index }">
          <table class="table-detail">
            <thead>
              <tr>
                <td>Preço Original</td>
                <td>Notional Original</td>
                <td>Subm.</td>
                <td>Tipo de Energia</td>
                <td>PLD</td>
                <td>Spread de Compra</td>
                <td>Preço de Compra</td>
                <td
                  :class="
                    row.condicaoDePrecoDto.spreadDeCompra ===
                    row.condicaoDePrecoDto.spreadAjustado
                      ? 'd-flex align-items-center justify-content'
                      : null
                  "
                >
                  Spread Ajustado
                  <div
                    v-if="
                      row.condicaoDePrecoDto.spreadDeCompra ===
                      row.condicaoDePrecoDto.spreadAjustado
                    "
                  >
                    <tooltip
                      width="300px"
                      arrowAlign="up"
                      alignVertical="25px"
                      alignHorizontal="-50px"
                    >
                      <img
                        slot="header"
                        alt="Informação"
                        class="icon-duvida"
                        src="@/assets/icons/svg/duvida.svg"
                      />
                      <p slot="body">
                        O spread Ajustado deve ser diferente do Spread de
                        Compra.
                      </p>
                    </tooltip>
                  </div>
                </td>
                <td>Preço Ajustado</td>
                <td>Notional recompra</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ row.precoOriginal | moeda }}</td>
                <td>{{ row.notionalOriginal | moeda }}</td>
                <td>{{ row.condicaoDePrecoDto.submercado }}</td>
                <td>{{ row.condicaoDePrecoDto.tipoEnergia }}</td>
                <td>{{ row.condicaoDePrecoDto.pld | moeda }}</td>
                <td>{{ row.condicaoDePrecoDto.spreadDeCompra | moeda }}</td>
                <td>{{ row.condicaoDePrecoDto.precoDeCompra | moeda }}</td>
                <td>
                  <vue-numeric
                    :minus="true"
                    :precision="3"
                    placeholder="0,000"
                    :class="
                      row.condicaoDePrecoDto.spreadDeCompra !=
                      row.condicaoDePrecoDto.spreadAjustado
                        ? 'input-field-card'
                        : 'input-field-card-red'
                    "
                    :decimal-separator="','"
                    :thousands-separator="'.'"
                    @input="changeSpread(index)"
                    v-model="row.condicaoDePrecoDto.spreadAjustado"
                  />
                </td>
                <td>
                  {{ row.condicaoDePrecoDto.precoDeCompraAjustado | moeda }}
                </td>
                <td>{{ row.notionalRecompra | moeda }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </data-table-recompra>
    </div>

    <div
      class="mt-5"
      v-if="data.filter((g) => g.condicaoDePrecoDto.spreadAjustado).length > 0"
    >
      <card-base>
        <template #header>Solicitação de Ajuste de Spread</template>
        <template #body>
          <div class="card-body">
            <div class="card-ajuste-spread">
              <div class="wrapper-spread-main">
                <div class="row">
                  <div class="col-md-2">
                    <div>Contrato</div>
                    <div>{{ contratoMaiorSpread.codigo }}</div>
                  </div>
                  <div class="col-md-2">
                    <div>Spread de Compra original</div>
                    <div>
                      <b>{{
                        contratoMaiorSpread.condicaoDePrecoDto.spreadDeCompra
                          | VOLUME
                      }}</b>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div>Spread de Compra Ajustado</div>
                    <div>
                      <b>{{
                        contratoMaiorSpread.condicaoDePrecoDto.spreadAjustado
                          | VOLUME
                      }}</b>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div>Alçada Competente</div>
                    <div
                      v-if="getPerfilAprovadorSpreadRecompra.nome"
                      style="display: flex"
                    >
                      <div>
                        <b>{{ getPerfilAprovadorSpreadRecompra.nome }}</b>
                      </div>
                      <div class="aprovador-simbolo">
                        {{ getPerfilAprovadorSpreadRecompra.simbolo }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="wrapper-spread-selected-justificativa">
                      <div>Justificativa</div>
                      <div>
                        <textarea
                          @input="mountRequest()"
                          v-model="justificativa"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="spread-main-wrapper-button">
                <button
                  style="padding: 10px"
                  @click="showSpreads = !showSpreads"
                  class="btn-action-secondary-outlined btn-spreads"
                >
                  <b v-if="showSpreads">Ocultar Todos os Spreads</b>
                  <b v-else>Exibir Todos os Spreads</b>
                </button>
              </div>
            </div>

            <div v-if="showSpreads" class="wrapper-table-spreads">
              <TableSpreads
                :data="data"
                :contratoMaiorSpread="contratoMaiorSpread"
              />
            </div>
          </div>
        </template>
      </card-base>
    </div>
  </div>
</template>>
<script>
import { mapGetters } from "vuex";
import VueNumeric from "vue-numeric";
import bigDecimal from "js-big-decimal";
import { VOLUME } from "@/filters/formatters";
import TableSpreads from "./TableSpreads.vue";
import Tooltip from "@/components/Tooltip/Tooltip.vue";
import CardBase from "@/components/cards/cardBase.vue";
import RecompraService from "@/services/RecompraService";
import OverlayLoader from "@/components/OverlayLoader.vue";
import DataTableRecompra from "@/components/tables/DataTableRecompra.vue";
import TableRecompraPrecosDiferentes from "@/models/tablesModels/TableRecompraPrecosDiferentesModel";
export default {
  props: {
    contrato: Object,
  },
  data() {
    return {
      data: [],
      contratoMaiorSpread: {
        cogigo: null,
        condicaoDePrecoDto: {},
      },
      justificativa: "",
      isValid: true,
      showSpreads: false,
      somTakeMin: 0,
      somVolumeReduzir: 0,
      somNovoVolume: 0,
      somNovoNotional: 0,
      volumeExcedente: 0,
      loadingTableAnalise: false,
    };
  },
  mounted() {
    this.getAnalise();
    this.bigDecimal = bigDecimal
  },
  computed: {
    ...mapGetters(["getPerfilAprovadorSpreadRecompra"]),
    TableRecompraPrecosDiferentes: () => TableRecompraPrecosDiferentes,
  },
  methods: {
    changeSpread(index) {
      const contrato = this.data[index];
      if (contrato.condicaoDePrecoDto.spreadAjustado) {
        contrato.condicaoDePrecoDto.precoDeCompraAjustado =
          contrato.condicaoDePrecoDto.pld +
          contrato.condicaoDePrecoDto.spreadAjustado;
        this.calcularRecompra(index);
      } else {
        contrato.condicaoDePrecoDto.precoDeCompraAjustado = null;
        this.justificativa =""
        this.calcularRecompra(index);
      }
    },
    calcularRecompra(index) {
      const contrato = this.data[index];
      contrato.condicaoDePrecoDto.precoDeCompraFinal = contrato
        .condicaoDePrecoDto.spreadAjustado
        ? contrato.condicaoDePrecoDto.precoDeCompraAjustado
        : contrato.condicaoDePrecoDto.precoDeCompra;
      contrato.novoVolume = contrato.takeMinimo - contrato.volumeReduzir;
      contrato.notionalOriginal = contrato.takeMinimo * contrato.precoOriginal;
      contrato.notionalRecompra =
        contrato.volumeReduzir * contrato.condicaoDePrecoDto.precoDeCompraFinal;
      contrato.novoNotional =
        contrato.notionalOriginal - contrato.notionalRecompra;
        if(contrato.novoVolume !== 0){
                contrato.novoPreco = this.bigDecimal.divide(contrato.novoNotional,contrato.novoVolume,2);
              }else{
                contrato.novoPreco = 0
              }
      
      this.somTakeMin = this.data
        .map(({ takeMinimo }) => takeMinimo)
        .reduce((total, numero) => bigDecimal.add(total, numero), 0);

      this.somVolumeReduzir = this.data
        .map(({ volumeReduzir }) => volumeReduzir)
        .reduce((total, numero) => bigDecimal.add(total, numero), 0);

      this.somNovoVolume = this.data
        .map(({ novoVolume }) => novoVolume)
        .reduce((total, numero) => bigDecimal.add(total, numero), 0);

      this.somNovoNotional = this.data
        .map(({ novoNotional }) => novoNotional)
        .reduce((total, numero) => bigDecimal.add(total, numero), 0);

      this.volumeExcedente = bigDecimal.subtract(
        this.contrato.calculoResultado.solicitadoLiquido,
        this.somTakeMin
      );
      if (
        this.data.filter((g) => g.condicaoDePrecoDto.spreadAjustado).length > 0
      ) {
        let maiorDifSpread = 0;
        this.data
          .filter((g) => g.condicaoDePrecoDto.spreadAjustado)
          .forEach((contrato, index) => {
            let difSpread =
              contrato.condicaoDePrecoDto.spreadAjustado -
              contrato.condicaoDePrecoDto.spreadDeCompra;
            difSpread = this.positive(difSpread);
            if (difSpread > maiorDifSpread) {
              maiorDifSpread = difSpread;
              contrato.index = index;
              this.contratoMaiorSpread = contrato;
              this.$store.dispatch(
                "ActionCheckAprovadorSpreadRecompra",
                maiorDifSpread
              );
            }
          });
      } else {
        this.contratoMaiorSpread = {
          condicaoDePrecoDto: {},
        };
      }
      this.mountRequest();
    },
    positive(value) {
      if (Math.sign(value) === -1) return value * -1;
      return value;
    },
    getAnalise() {
      this.loadingTableAnalise = true;
      RecompraService.getAnaliseRecompraPrecosDiferentes({
        idContrato: this.contrato.id,
      })
        .then(({ data }) => {
          this.loadingTableAnalise = false;
          this.data = data.map((contrato) => {
            contrato.condicaoDePrecoDto.spreadAjustado = 0;
            contrato.condicaoDePrecoDto.precoDeCompraAjustado = 0;
            contrato.condicaoDePrecoDto.spreadFinal = 0;
            contrato.condicaoDePrecoDto.precoDeCompraFinal = 0;
            contrato.takeMinimo = contrato.condicaoDePrecoDto.volumeLiquido;
            contrato.volumeReduzir = contrato.condicaoDePrecoDto.volumeReduzir;
            contrato.precoOriginal = contrato.condicaoDePrecoDto.precoOriginal;
            contrato.novoVolume = contrato.takeMinimo - contrato.volumeReduzir;
            contrato.notionalOriginal =
              contrato.takeMinimo * contrato.precoOriginal;
            contrato.notionalRecompra =
              contrato.volumeReduzir *
              contrato.condicaoDePrecoDto.precoDeCompra;
            contrato.novoNotional =
              contrato.notionalOriginal - contrato.notionalRecompra;
             if(contrato.novoVolume !== 0){
                contrato.novoPreco = this.bigDecimal.divide(contrato.novoNotional,contrato.novoVolume,2);
              }else{
                contrato.novoPreco = 0
              }
            return contrato;
            
          });
          this.mountRequest();
        })
        .catch(() => {
          this.loadingTableAnalise = false;
        });
    },
    mountRequest() {
      const request = {
        contratosRecompra: new Array(),
        justificativa: this.justificativa,
      };

      this.data.forEach((contrato) => {
        const {
          codigo,
          novoPreco: precoFinal,
          takeMinimo: volume,
          novoVolume: volumeFinal,
          condicaoDePrecoDto,
        } = contrato;

        request.contratosRecompra.push({
          contratoRecompra: {
            preco:condicaoDePrecoDto.precoDeCompraFinal,
            volume,
            codigo,
            precoFinal,
            volumeFinal,
          },
          spread: condicaoDePrecoDto.spreadDeCompra,
          spreadCompraAjustado:
            condicaoDePrecoDto.spreadAjustado === 0
              ? null
              : condicaoDePrecoDto.spreadAjustado,
          pld: condicaoDePrecoDto.pld,
        });
      });

      if (
        this.justificativa == "" &&
        this.data.filter((g) => g.condicaoDePrecoDto.spreadAjustado).length > 0
      ) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
      this.$emit("getResultadoRecompra", request, this.isValid);
    },
  },
  filters: {
    VOLUME,
  },
  components: {
    Tooltip,
    CardBase,
    VueNumeric,
    TableSpreads,
    OverlayLoader,
    DataTableRecompra,
  },
};
</script>

<style lang="scss" scoped>
.title-folding {
  width: 100%;
  display: flex;
  font-weight: 700;
  margin-top: 30px;
  padding: 10px 30px;
  border-radius: 24px;
  color: $color--white;
  justify-content: space-between;
  background: $color--blue-super-dark;
}

.table-analise {
  position: relative;
}

.table-detail {
  color: #0f214a;
  thead {
    font-weight: 600;
    td {
      padding: 0 5px;
      text-align: center;
    }
  }
  tbody {
    font-weight: 300;
    td {
      width: 10%;
      padding: 0 5px;
      text-align: center;
    }
  }
}

.wrapper-spread-main {
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}
.wrapper-spread-main-fields {
  width: 100%;
  display: flex;
}
.wrapper-spread-main-left {
  width: 70%;
}
.spread-main-field {
  width: 25%;
}
.spread-main-wrapper-button {
  margin-top: 30px;
  max-width: 400px;
}
.wrapper-spread-selected-justificativa {
  width: 100%;
  textArea {
    width: 100%;
    border: none;
    padding: 8px;
    min-height: 100px;
    border-radius: 8px;
    border: solid #ccc 1px;
  }
}
.btn-spreads {
  margin-top: -300px;
}
.wrapper-table-spreads {
  width: 100%;
  margin-top: 30px;
}
.aprovador-simbolo {
  width: 22px;
  height: 22px;
  display: flex;
  margin: 0 5px;
  font-size: 9px;
  line-height: 0;
  min-width: 22px;
  font-weight: bold;
  border-radius: 100%;
  align-items: center;
  color: $color--primary;
  justify-content: center;
  border: solid 3px $color--primary;
}

.is-invalid-label {
  color: red;
  font-size: 10px;
  display: inline-block;
  width: auto !important;
}
</style>