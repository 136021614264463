<template>
  <div :class="[itemSelected ? 'active' : null, 'card-processing']">
    <div class="card-processing__header card-font-family">
      <slot name="title"></slot>
    </div>
    <div
      :class="[itemSelected ? 'color-white' : null, 'card-processing__body']"
    >
      <dots-loader v-if="loading" />
      <slot v-else name="value"> </slot>
    </div>
  </div>
</template>
<script>
import DotsLoader from "@/components/Loaders/DotsLoader.vue";

export default {
  props: {
    itemSelected: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DotsLoader,
  },
};
</script>
<style lang="scss" scoped>
.icon {
  right: 10%;
  margin-top: 10%;
  position: absolute;
}

.card-processing {
  width: 100%;
  min-width: 15px;
  overflow: hidden;
  min-height: 150px;
  text-align: center;
  border-radius: 24px;
  background: $color--primary-white;
  &__header {
    display: flex;
    padding: 0 5px;
    font-size: 12px;
    font-weight: 700;
    min-height: 50px;
    line-height: 150%;
    position: relative;
    align-items: center;
    letter-spacing: 1px;
    flex-direction: column;
    justify-content: center;
    background: #0f214a;
    color: $color--primary-white;
  }
  &__body {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color--primary-font;
    font-weight: 900;
    font-size: 38px;
    line-height: 120%;
    letter-spacing: 1px;
  }

  .card-font-family {
    font-family: "Montserrat", "Helvetica" !important;
  }
}

@keyframes bg_card_slide_down {
  from {
    background-color: white;
  }

  to {
    background-color: #0f214a;
  }
}

.active {
  animation-duration: 0.3s;
  transition: 0.2s ease-in-out;
  animation-fill-mode: forwards;
  animation-name: bg_card_slide_down;
}

.color-white {
  color: white !important;
}
</style>
