import PosVendaModel from "../PosVendaModel";

export default class TablePosVendaModel extends PosVendaModel {
  getTableFields() {
    return new Array(
      {
        name: "apelido",
        title: "Apelido",
      },
      {
        name: "cnpj",
        title: "CNPJ",
        formatter(value) {
          return value.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          );
        },
      },
      {
        name: "razaoSocial",
        title: "Razão Social",
      },

      {
        name: "posVenda",
        title: "Pós-venda",
        formatter(value) {
          if (value === undefined) {
            return "--";
          }
          return value.nome;
        },
      }
    );
  }
}
