<template>
  <div class="navigation">
    <div class="navigation__header">
      <navigation-header />
    </div>
    <div class="navigation__body">
      <div class="navigation__bar-fixed-border" />
      <div style="margin-top: 16px">
        <scroll :ops="{ rail: { opacity: 0 }, bar: { keepShow: false } }">
          <div
            v-for="(item, i) in filteredMenuItemsByPermission"
            :key="`item${i}`"
          >
            <navigation-item :url="item.url">
              <template #fixed>
                <span :class="item.icon" class="__icon" />
              </template>
              <template
                #slider
                @click="toggle()"
                v-if="getNavigationShow"
                data-cy="template-descricao_side_bar-navigation"
              >
                {{ item.description }}
              </template>
            </navigation-item>
          </div>
        </scroll>
      </div>
    </div>
  </div>
</template>
<script>
import NavigationHeader from "@/components/Navigation/NavigationHeader.vue";
import NavigationItem from "@/components/Navigation/NavigationItem.vue";
import { mapGetters } from "vuex";

export default {
  name: "Navigation",
  components: {
    NavigationHeader,
    NavigationItem,
  },
  data() {
    return {
      menuItems: [
        {
          description: "Faturamento",
          icon: "icon-faturamento",
          url: "/faturamento",
          permissionsNeeded: [],
        },
        {
          description: "PLD Spread",
          icon: "icon-pld",
          url: "/pld-spread",
          permissionsNeeded: [
            "AJUSTES_PLD_SPREAD_VISUALIZAR",
            "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR",
            "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR",
          ],
        },
        {
          description: "Pontos de Medição",
          icon: "icon-pontos",
          url: "/pontos-medicao",
          permissionsNeeded: [
            "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR",
            "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR_EDITAR",
          ],
        },
        {
          description: "PROINFA",
          icon: "icon-proinfa",
          url: "/proinfa",
          permissionsNeeded: [
            "CONFIGURAR_PROINFA_VISUALIZAR",
            "CONFIGURAR_PROINFA_VISUALIZAR_EDITAR",
          ],
        },
        {
          description: "Associar Pós-Venda",
          icon: "icon-posvenda",
          url: "/pos-venda",
          permissionsNeeded: [
            "ASSOCIAR_POS_VENDA_VISUALIZAR",
            "ASSOCIAR_POS_VENDA_VISUALIZAR_ASSOCIAR",
          ],
        },
        {
          description: "Exportador",
          icon: "icon-exportador",
          url: "/exportador",
          permissionsNeeded: [
            "EXPORTADOR_MONTANTES_VISUALIZAR",
            "EXPORTADOR_MONTANTES_VISUALIZAR_EXPORTAR",
          ],
        },
        {
          description: "Curto Prazo",
          icon: "icon-curto-prazo",
          url: "/curto-prazo",
          permissionsNeeded: [
            "ACOMPANHAR_CURTO_PRAZO_VISUALIZAR",
            "ACOMPANHAR_CURTO_PRAZO_VISUALIZAR_SINALIZAR",
          ],
        },
        {
          description: "Não Importados",
          icon: "icon-thumbs-down",
          url: "/nao-importados",
          permissionsNeeded: ["CONTRATOS_NAO_IMPORTADOS_HABILITADO"],
        },
        {
          description: "Dashboards",
          icon: "icon-dashboard",
          url: "/dashboards",
          permissionsNeeded: ["DASHBOARD_HABILITADO"],
        },
        {
          description: "Importar em Lote",
          icon: "icon-lote",
          url: "/lote",
          permissionsNeeded: ["IMPORTACAO_LOTE_HABILITADO"],
        },
        {
          description: "Parametrização",
          icon: "icon-parameter",
          url: "/parametrizacao",
          permissionsNeeded: [
            "ALCADAS_APROVACAO_VISUALIZAR",
            "ALCADAS_APROVACAO_VISUALIZAR_EDITAR",
            "CURTO_PRAZO_VISUALIZAR",
            "CURTO_PRAZO_VISUALIZAR_EDITAR",
            "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR",
            "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR_EDITAR",
            "FECHAR_FATURAMENTO_HABILITADO",
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getNavigationShow"]),
    filteredMenuItemsByPermission() {
      return this.menuItems.filter(
        (item) =>
          item.permissionsNeeded.length === 0 ||
          item.permissionsNeeded.some((permission) => this.$can(permission))
      );
    },
  },

  methods: {
    toggle() {
      this.$store.dispatch("toggleNavigation");
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation {
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999 !important;
  background: $color--white;
  &__header {
    max-height: 75px;
    min-height: 75px;
    @include ml-hd {
      max-height: responsive(75px);
      min-height: responsive(75px);
    }
    border-bottom: solid 1px $color--primary;
  }
  &__body {
    display: flex;
    flex: 1;
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    position: relative;
    background: $color--white;
  }
  &__footer {
    height: 98px;
    @include ml-hd {
      height: responsive(98px);
    }
    border-top: solid 1px #0a1631;
  }
  &__bar-fixed-border {
    background: $color--white;
    max-width: 20px;
    min-width: 20px;
    border-right: solid 1px $color--primary;
    @include ml-hd {
      max-width: responsive(20px);
      min-width: responsive(20px);
    }
  }
}
.__icon {
  font-size: 20px;
  @include ml-hd {
    font-size: responsive(20px);
  }
}
</style>
