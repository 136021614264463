<template>
  <div class="table-card subtitled-only" v-if="config">
    <div class="table-subtitle">D. Energia Renovável Matrix</div>
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>Dado</th>
          <th>Unidade</th>
          <th>Montante SCDE (MWh)</th>
          <th>Preço (R$/MWh) c/ICMS</th>
          <th>Volume à Faturar</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Energia Firme Matrix ACL</td>
          <td>R$</td>
          <td>
            {{ config.montanteScde | truncateRound(4, 3) }}
          </td>
          <td>
            {{ config.precoCIcms | truncateRound(3, 2) | R$ }}
          </td>
          <td class="table-bold">
            {{ config.consumoApurado | truncateRound(4, 3) }}
          </td>
        </tr>
        <tr class="table-bold white-row">
          <td></td>
          <td></td>
          <td></td>
          <td>Total</td>
          <td class="table-total">
            {{ config.energiaAcl | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
        <tr class="table-bold white-row">
          <td></td>
          <td></td>
          <td></td>
          <td>Total Considerado ACL</td>
          <td class="table-total">
            {{ config.totFatAcl | truncateRound(3, 2) | R$ }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["config"],
};
</script>
<style lang="scss" scoped>
.table-card {
  margin-top: 25px;
  border-radius: 0 0 0.3em 0.3em;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  box-shadow: 2px 2px 5px 0 #2a2a2a22;
}
.table-subtitle {
  padding: 0.4em;
  background-color: #cecece;
  font-size: 0.9rem;
  font-weight: 700;
  border-top: solid 1px #f86511;
}
table {
  border-collapse: collapse;
  margin: 0;
  font-size: 0.9rem;
  border-radius: 0 0 0.3em 0.3em;

  thead {
    background-color: #e8e8e8;
  }

  tbody {
    tr {
      width: 100%;
      &:nth-child(even) {
        background-color: #f2edeb;
      }
      &:last-child {
        border-radius: 0 0 0.3em 0.3em;
        :first-child {
          border-radius: 0 0 0 0.3em;
        }
      }
    }
  }

  .white-row {
    background-color: #ffffff !important;
  }

  .table-bold {
    font-weight: 700;
  }

  .table-total {
    background-color: #fff1ec;
    border-top: solid 1px #f86511;
    border-radius: 0 0 0.3em 0;
  }
}
</style>
