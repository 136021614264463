<template>
  <div
    v-if="config"
    :style="`width: ${width}; font-size: ${fs}`"
    class="card-container"
  >
    <div class="card-title" :style="`background-color: ${titleColor}`">
      {{ title }}
    </div>
    <div class="card-content">
      <table class="card-table">
        <tbody v-if="!!config">
          <tr
            :key="`key_${i}`"
            v-for="(key, i) in config"
            :class="{ stripes: stripes }"
            :style="key.color ? `border: 2px solid ${key.color};` : ''"
          >
            <td>
              <b>{{ key.info }}</b> <span> {{ key.desc }} </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fs: String,
    config: Array,
    width: String,
    stripes: Boolean,
    titleColor: String,
    title: {
      type: String,
      default: "Default Title",
    },
  },
  data() {
    return {};
  },
  methods: {
    isLastRow(index) {
      return index >= this.config.length - 1 ? "0.3em" : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  font-size: 1rem;
  border-radius: 0.3em;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0 #2a2a2a22;
}

.card-title {
  padding: 16px 24px;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.5rem;
  background-color: #363636;
  border-radius: 0.3em 0.3em 0 0;
}

.card-content {
  background-color: #ffffff;
  border-radius: 0 0 0.3em 0.3em;
}

.card-table {
  width: 100%;
  margin-top: -10px;
  border-radius: 0.3em;
  background-color: #ffffff;

  tr {
    width: 100%;

    td {
      padding: 1% 3% 1% 3%;
    }
  }
}

.stripes {
  &:nth-child(odd) {
    background-color: #f2edeb;
  }
  &:last-child {
    :last-child {
      border-radius: 0 0 0.3em 0.3em;
    }
  }
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}
</style>
