<template>
  <div class="container-fields" v-elementAsyncLoader="loading">
    <div class="section-fields">
      <field-aprovacao
        titleField="Novo Solicitado Líquido"
        :valueInfo="aprovacao.novoSolicitadoLiquido"
        :widthField="20"
      />
      <field-aprovacao
        titleField="Alçada Competente"
        :valueInfo="aprovacao.alcadaCorrespondente"
        colorValueField="#2A2A2A"
        :widthField="20"
      />
      <field-justificativa
        :fieldDisabled="true"
        ref="refJustificativaSolicitanteField"
        titleField="Justificativa do Solicitante:"
      />
      <field-justificativa
        ref="refJustificativaOpcionalField"
        titleField="Inserir Justificativa (Opcional):"
      />
    </div>

    <div class="box-buttons">
      <button
        @click="reprovar"
        class="btn reprovar-btn"
        data-cy="button-reprovar_solicitado_liquido-modais"
      >
        Reprovar
      </button>
      <button
        @click="aprovar"
        class="btn aprovar-btn"
        data-cy="button-aprovar_solicitado_liquido-modais"
      >
        Aprovar
      </button>
    </div>
  </div>
</template>

<script>
import { CONFIRMATION } from "@/constants/strings";
import ContratoService from "@/services/ContratoService";
import FieldAprovacao from "./modais/fields/FieldAprovacao.vue";
import FieldJustificativa from "./modais/fields/FieldJustificativa.vue";

export default {
  components: {
    FieldAprovacao,
    FieldJustificativa,
  },
  props: {
    contratoId: Number,
    aprovacao: Object,
  },
  data() {
    return {
      loading: undefined,
    };
  },
  mounted() {
    this.$refs.refJustificativaSolicitanteField.valueField =
      this.aprovacao.justificativaDoSolicitante;
  },
  methods: {
    aprovar() {
      const request = {
        idContrato: this.contratoId,
        justificativa: this.$refs.refJustificativaOpcionalField.valueField,
      };

      this.loading = ContratoService.aprovarSolicitadoLiquido(request)
        .then((res) => {
          this.mxToggleToast({
            message: CONFIRMATION.SOLICITADO_LIQUIDO.UPDATE,
          });
          document.location.reload(true);
        })
        .catch((error) => {
          if (error.response) {
            const { status } = error.response;
            if (status === 406) {
              this.mxToggleWarningModal({
                message: CONFIRMATION.FATURAMENTO.CANT_FOLLOW,
                btnTrue: "Prosseguir",
                showBtnFalse: false,
              }).then(() => document.location.reload(true));
            }
          } else {
            this.mxHandleRequestError(error, "SOLICITADO_LIQUIDO");
          }
        });
    },
    reprovar() {
      const request = {
        idContrato: this.contratoId,
        justificativa: this.$refs.refJustificativaOpcionalField.valueField,
      };

      this.loading = ContratoService.reprovarSolicitadoLiquido(request)
        .then((res) => {
          this.mxToggleToast({
            message: CONFIRMATION.SOLICITADO_LIQUIDO.DELETE,
          });

          document.location.reload(true);
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "SOLICITADO_LIQUIDO");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fields {
  display: flex;
  justify-items: center;
  flex-direction: column;

  width: 100%;
  height: 250px;

  margin: 0px 5px;
  padding: 20px;

  border: 3px dashed #ec6623;
  border-radius: 20px;
}

.section-fields {
  display: flex;
  justify-items: center;

  width: 100%;

  height: 80%;
}

.box-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 25%;

    margin: 5px;
    padding: 8px;

    color: white;

    font-weight: bold;
  }
}

.aprovar-btn {
  background: #78b82a;
}

.reprovar-btn {
  background: #d40d00;
}
</style>
