<template>
  <div class="contract">
    <modal-editar-solicitado-liquido
      v-if="showModalEditarSolicitadoLiquido"
      @closeModal="closeModal()"
      :modelProp="modelLine"
      :solicitadoLiquidoOriginal="contrato.calculoResultado.solicitadoLiquido"
      :contratoId="idContratoRequest"
      :linkSolicitado="URL_EDITAR_SOLICITADO_LIQUIDO"
    />
    <collapsible-card
      class="contract__collapsable"
      :initialOpen="CONTRATO_INITIAL_OPEN"
    >
      <template #title>
        <div class="header-contract">
          <span class="header-contract__title">
            Contrato: <b>{{ contrato.codigo }}</b>
            <label v-if="contrato.rateio">- Com Rateio</label>
          </span>
          <span
            v-if="contrato.calculoResultado.solicitadoLiquido > 0"
            class="header-contract__volume"
          >
            Solicitado Líquido(MWh):
            <b>
              {{
                contrato.calculoResultado.solicitadoLiquido | round(3) | repZero
              }}
            </b>
          </span>
        </div>
      </template>
      <template #body>
        <contrato-tag
          :contrato="contrato"
          v-if="contrato"
          class="contract__tags"
        />
        <template v-if="contrato && contrato.warnings.length">
          <p>Pendências para Aprovação Automática</p>
          <div class="contract__tags _contract-tags">
            <Tag
              v-for="warning in contrato.warnings"
              class="__yellow-tag"
              :hint="warning.translatedDescription"
            >
              {{ warning.shortDescription }}
            </Tag>
          </div>
        </template>
        <horas-faltantes
          v-if="contrato && getContractLink('horas-faltantes', contrato.links)"
          :link="getContractLink('horas-faltantes', contrato.links)"
          :contratoId="contrato.id"
          :hasAction="hasAction"
        />
        <div class="row">
          <div class="col-12">
            <card-base>
              <template #header>Cliente do Contrato</template>
              <template #body>
                <div class="card-body">
                  <div class="_card-contrato-item card-cliente">
                    <div class="card-label">Apelido</div>
                    <div class="card-value">
                      {{ contrato.empresa.apelido || "--" }}
                    </div>
                  </div>
                  <div class="_card-contrato-item card-cliente">
                    <div class="card-label">CNPJ</div>
                    <div class="card-value">
                      {{ contrato.empresa.cnpj | cnpj }}
                    </div>
                  </div>
                  <div class="_card-contrato-item card-cliente">
                    <div class="card-label">Razão Social</div>
                    <div class="card-value">
                      {{ contrato.empresa.razaoSocial || "--" }}
                    </div>
                  </div>
                  <div class="_card-contrato-item card-cliente">
                    <div class="card-label">Responsável</div>
                    <div class="card-value">
                      <div>
                        {{ contrato.responsavel || "--" }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </card-base>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5 col-md-12 mt-4">
            <card-base class="card-base">
              <template #header>Flexibilidade (MWh)</template>
              <template #body>
                <div class="card-body">
                  <div class="_card-contrato-item card-flexibilidade">
                    <div class="card-label">Qtd. Contratada</div>
                    <div class="card-value">
                      {{
                        contrato.flexibilidade.quantidadeContratada
                          | round(3)
                          | repZero
                      }}
                    </div>
                  </div>
                  <div class="_card-contrato-item card-flexibilidade">
                    <div class="card-label">Limite Min.</div>
                    <div class="card-value">
                      {{ contrato.flexibilidade.limiteMinimo | round(3) }}
                    </div>
                  </div>
                  <div class="_card-contrato-item card-flexibilidade">
                    <div class="card-label">Limite Máx.</div>
                    <div class="card-value">
                      {{ contrato.flexibilidade.limiteMaximo | round(3) }}
                    </div>
                  </div>
                </div>
              </template>
            </card-base>
          </div>
          <div class="col-lg-7 col-md-12 mt-4">
            <card-base>
              <template #header>Condições de Cálculo</template>
              <template #body>
                <div class="card-body">
                  <div class="_card-contrato-item card-condicoes">
                    <div class="card-label">Ponto de Medição</div>
                    <div
                      class="card-value padlock"
                      v-if="getIsPontoSemAcesso && !contrato.rateio"
                    >
                      {{ PONTO_MEDICAO }}
                      <img
                        src="@/assets/icons/svg/padlock.svg"
                        title="Ponto sem acesso na CCEE"
                      />
                      <div v-if="getIsPontoSemAcesso" />
                    </div>
                    <div class="card-value" v-else>{{ PONTO_MEDICAO }}</div>
                  </div>
                  <div class="_card-contrato-item card-condicoes">
                    <div class="card-label">PROINFA de {{ COMPETENCIA }}</div>
                    <div class="card-value">
                      {{ GET_CONTRATO_PROINFA }}
                    </div>
                  </div>
                  <div class="_card-contrato-item card-condicoes">
                    <div class="card-label">% de Perda</div>
                    <div class="card-value">
                      {{
                        contrato.rateio
                          ? "--"
                          : contrato.percentualPerda | PERCENTUAL_TO_FIXED2
                      }}
                    </div>
                  </div>
                  <div class="_card-contrato-item card-condicoes">
                    <div class="card-label">Fator de Atend. Carga</div>
                    <div class="card-value">
                      {{
                        contrato.rateio
                          ? "--"
                          : contrato.percentualFatorAtendimento
                            | PERCENTUAL_TO_FIXED3
                      }}
                    </div>
                  </div>
                </div>
              </template>
            </card-base>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-5 col-md-12">
            <card-base class="card-base">
              <template #header>
                Resultado do Cálculo de Medição (MWh)
              </template>
              <template #body>
                <div class="card-body">
                  <div class="_card-contrato-item card-calculo">
                    <div class="card-label">Mont. SCDE</div>
                    <div class="card-value">
                      {{ contrato.calculoResultado.montanteSCDE | round(3) }}
                    </div>
                  </div>
                  <div class="_card-contrato-item card-calculo">
                    <div class="card-label">Solic. Bruto</div>
                    <div class="card-value">
                      {{ contrato.calculoResultado.solicitadoBruto | round(3) }}
                    </div>
                  </div>
                  <div class="_card-contrato-item card-calculo">
                    <div class="card-label d-flex">
                      Solic. Líquido
                      <button
                        @click="editSolicitadoLiquido"
                        data-cy="button-contrato-contratos"
                        v-if="BUTTON_EDITAR_SOLICITADO_VISIBLE"
                        class="ms-2 btn-primary-text icon icon-lapis"
                        style="cursor: pointer; background-color: #ffffff"
                      />
                    </div>
                    <div class="card-value">
                      {{
                        contrato.calculoResultado.solicitadoLiquido
                          | round(3)
                          | repZero
                      }}
                    </div>
                  </div>
                </div>
              </template>
            </card-base>
          </div>
        </div>
        <aprovacao-analise-recompra-preco-unico
          v-if="
            tipoRecompra === 'analise-recompra-preco-unico' ||
            tipoRecompra === 'analise-recompra-preco-unico-rateio'
          "
          :dataPrecoUnico="recompraAnalisePrecoUnico"
        />
        <contrato-preco-unico-com-rateio
          v-if="tipoRecompra === 'analise-recompra-preco-unico-rateio'"
          :dataTable="recompraAnaliseUnicoRateio"
        />
        <contrato-recompra-preco-diferente
          v-if="tipoRecompra === 'analise-recompra-precos-diferentes'"
          :contrato="contrato"
          :dataPrecoDiferente="recompraAnalisePrecoDiferente"
        />
        <div class="mt-5">
          <aprovacao-spread-recompra
            :contrato="contrato"
            v-if="
              URL_APROVAR_RECOMPRA &&
              HAS_ASSUMED_FATURAMENTO &&
              VISIBLE_APROVAR_RECOMPRA
            "
          />
        </div>
        <div class="mt-5">
          <div v-elementAsyncLoader="aprovacaoLoading">
            <aprovacao-solicitado-liquido
              v-if="aprovacao"
              :aprovacao="aprovacao"
              :contratoId="idContratoRequest"
            />
          </div>
        </div>
        <contrato-component-buttons
          class="mb-3"
          :buttons="buttons"
          :contrato="contrato"
          :dataEmail="dataEmail"
          :tipoRecompra="tipoRecompra"
          v-elementAsyncLoader="btnLoading"
          :titleModalEmail="titleModalEmail"
          :contratosFilhosRedis="contratosFilhosRedis"
          :recompraAnalisePrecoUnico="recompraAnalisePrecoUnico"
          :recompraAnaliseUnicoRateio="recompraAnaliseUnicoRateio"
          :recompraAnalisePrecoDiferente="recompraAnalisePrecoDiferente"
        />
        <contratos-filhos
          class="mt-4"
          :contrato="contrato"
          :hasAction="hasAction"
          :key="contrato.codigo"
          v-if="contrato && contrato.rateio && !tipoRecompra"
          @getContratosFilhosRedis="getContratosFilhosRedis"
        />
        <historico-acoes
          class="mt-4"
          key="historico-acoes"
          :link="getContractLink('historico-acoes', contrato.links)"
          v-if="contrato && getContractLink('historico-acoes', contrato.links)"
        />
      </template>
    </collapsible-card>
  </div>
</template>
<script>
import CardBase from "@/components/cards/cardBase.vue";
import collapsibleCard from "@/components/cards/collapsibleCard.vue";
import AprovacaoAnaliseRecompraPrecoUnico from "@/components/contratos/AprovacaoAnaliseRecompraPrecoUnico.vue";
import AprovacaoSolicitadoLiquido from "@/components/contratos/AprovacaoSolicitadoLiquido.vue";
import AprovacaoSpreadRecompra from "@/components/contratos/AprovacaoSpreadRecompra.vue";
import ContratoComponentButtons from "@/components/contratos/ContratoComponentButtons.vue";
import ContratoTag from "@/components/contratos/ContratoTag.vue";
import ContratosFilhos from "@/components/contratos/ContratosFilhos.vue";
import Tag from "@/components/contratos/Tag.vue";
import ContratoPrecoUnicoComRateio from "@/components/contratos/envio-email-recompra/ContratoPrecoUnicoComRateio.vue";
import ContratoRecompraPrecoDiferente from "@/components/contratos/envio-email-recompra/ContratoRecompraPrecoDiferente.vue";
import ModalEditarSolicitadoLiquido from "@/components/contratos/modais/ModalEditarSolicitadoLiquido.vue";
import HistoricoAcoes from "@/components/historico-acoes/HistoricoAcoes.vue";
import HorasFaltantes from "@/components/horas-faltantes/HorasFaltantes.vue";
import { truncateRoundFormatter } from "@/filters/formatters";
import ContratoService from "@/services/ContratoService";
import ContratosService from "@/services/ContratosService";
import FaturamentoService from "@/services/FaturamentoService";
import RecompraService from "@/services/RecompraService";
import { getFaturamentoCompetencia } from "@/utils/functionsUtils";
import { mapGetters } from "vuex";

export default {
  props: {
    contrato: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tipoRecompra: "",
      titleModalEmail: "",
      aprovacao: undefined,
      buttons: new Array(),
      btnLoading: undefined,
      modelLine: new Object(),
      dataEmail: new Object(),
      contratosFilhosRedis: {},
      aprovacaoLoading: undefined,
      idContratoRequest: undefined,
      contratosFilhos: new Array(),
      recompraAnalisePrecoUnico: new Object(),
      recompraAnaliseUnicoRateio: new Array(),
      showModalEditarSolicitadoLiquido: false,
      recompraAnalisePrecoDiferente: new Array(),
    };
  },
  mounted() {
    FaturamentoService.getContratosFilhos(this.contrato.id)
      .then(({ data }) => {
        this.contratosFilhos = data.map((g) => ({
          ...g,
          solicitadoLiquido:
            g.calculoFaturamento?.volumeLiquido || g.solicitadoLiquido,
        }));
      })
      .catch((error) => {
        this.mxHandleRequestError(error);
      });

    this.idContratoRequest =
      this.contratosFilhos.length > 0
        ? this.contratosFilhos[0].id
        : this.contrato.id;

    this.getBotoesAcoes();
    this.getAnaliseEmail();
    this.getTipoRecompra();
  },
  computed: {
    ...mapGetters([
      "getPermissoes",
      "getUsuario",
      "getContratoPaiId",
      "getIsPontoSemAcesso",
    ]),
    HAS_ASSUMED_FATURAMENTO() {
      return this.contrato?.responsavel === this.getUsuario.email;
    },
    CONTRATO_INITIAL_OPEN() {
      return this.contrato.id === this.getContratoPaiId;
    },
    GET_CONTRATO_PROINFA() {
      return this.contrato.rateio
        ? "--"
        : truncateRoundFormatter(this.contrato.proinfa, 3, 3);
    },
    VISIBLE_APROVAR_SOLICITADO() {
      return this.getPermissoes.some(
        (item) =>
          (item === "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N1" &&
            this.contrato.statusAtual.id == 11) ||
          (item === "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N2" &&
            this.contrato.statusAtual.id == 12) ||
          (item === "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N3" &&
            this.contrato.statusAtual.id == 13)
      );
    },
    VISIBLE_APROVAR_RECOMPRA() {
      return this.getPermissoes.some(
        (item) =>
          (item === "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N1" &&
            this.contrato.statusAtual.id == 8) ||
          (item === "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N2" &&
            this.contrato.statusAtual.id == 9) ||
          (item === "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N3" &&
            this.contrato.statusAtual.id == 10)
      );
    },
    PONTO_MEDICAO() {
      if (this.contratosFilhos?.length > 0) return "--";
      return this.contrato.pontoMedicao;
    },
    COMPETENCIA() {
      const { month, year } = getFaturamentoCompetencia();
      return `${month}/${year}`;
    },
    BUTTON_EDITAR_SOLICITADO_VISIBLE() {
      const buttonExists = this.buttons.find((button) => {
        return (
          button.acao === "editar_solicitado_liquido" && button.desabilitado
        );
      });
      return buttonExists?.visivel;
    },
    URL_EDITAR_SOLICITADO_LIQUIDO() {
      const buttonExists = this.buttons.find(
        (button) => button.acao === "editar_solicitado_liquido"
      );
      return buttonExists?.url || "";
    },
    URL_APROVAR_RECOMPRA() {
      const linkExists = this.contrato.links.find(
        (link) => link.rel === "aprovacao-recompra"
      );
      return linkExists;
    },
  },
  methods: {
    getContractLink(rel, links) {
      const link = links.find((link) => link.rel === rel);
      if (link) return link.href;
      return link?.href || null;
    },
    getContratosFilhosRedis(contratos) {
      this.contratosFilhosRedis = contratos;
    },
    editSolicitadoLiquido() {
      this.showModalEditarSolicitadoLiquido = true;
    },
    closeModal() {
      this.showModalEditarSolicitadoLiquido = false;
    },
    getBotoesAcoes() {
      this.btnLoading = FaturamentoService.getBotoesAcoes(
        this.contrato.id
      ).then((response) => {
        this.buttons = response.data;
        this.getAprovacao(this.idContratoRequest);
      });
    },
    getAprovacao(idContrato) {
      const actionAproveExists =
        this.buttons.filter(
          (button) => button.acao === "aprovar_solicitado_liquido"
        ).length > 0;

      if (this.VISIBLE_APROVAR_SOLICITADO && actionAproveExists) {
        this.aprovacaoLoading = ContratoService.buscarAprovacao({
          idContrato,
        }).then(({ data }) => {
          this.aprovacao = data;
        });
      }
    },
    getAnaliseEmail() {
      const { statusAtual } = this.contrato;
      const { id } = statusAtual;
      if ([15, 16].includes(id)) {
        const { id: idContrato } = this.contrato;
        const query =
          id === 15
            ? ContratosService.getEmailCalculoFinalizado({
                idContrato,
              })
            : ContratosService.getEmailRecompraManual({
                idContrato,
              });
        query
          .then(({ data }) => {
            this.dataEmail = data;
          })
          .catch((error) => {
            this.mxHandleRequestError(error);
          });
      }
    },
    buscarAnaliseRecompraPrecoUnico(request) {
      RecompraService.getBuscarAnaliseRecompraPrecoUnico(request)
        .then(({ data }) => {
          this.recompraAnalisePrecoUnico = data;

          this.titleModalEmail =
            "Enviar E-mail - Recompra Manual - Preço Único sem Rateio";
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    buscarAnaliseRecompraPrecoUnicoRateio(request) {
      RecompraService.getRecompraPrecoUnicoRateio(request)
        .then(({ data }) => {
          this.recompraAnaliseUnicoRateio = data;

          this.titleModalEmail =
            "Enviar E-mail - Recompra Manual - Preço Único com Rateio";
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    buscarAnaliseRecompraPrecosDiferentes(request) {
      RecompraService.getBuscarAnalisePrecosDiferentes(request)
        .then(({ data }) => {
          this.recompraAnalisePrecoDiferente = data;

          this.titleModalEmail =
            "Enviar E-mail - Recompra Manual - Preços Diferentes";
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    getTipoRecompra() {
      const requests = {
        "analise-recompra-preco-unico": (request) => {
          this.buscarAnaliseRecompraPrecoUnico(request);
        },
        "analise-recompra-preco-unico-rateio": (request) => {
          this.buscarAnaliseRecompraPrecoUnico(request);

          this.buscarAnaliseRecompraPrecoUnicoRateio(request);
        },
        "analise-recompra-precos-diferentes": (request) => {
          this.buscarAnaliseRecompraPrecosDiferentes(request);
        },
      };

      const links = this.contrato.links.filter((link) =>
        [
          "analise-recompra-preco-unico",
          "analise-recompra-preco-unico-rateio",
          "analise-recompra-precos-diferentes",
        ].includes(link.rel)
      );

      if (links[links.length - 1]) {
        this.tipoRecompra = links[links.length - 1].rel;

        requests[this.tipoRecompra]({ idContrato: this.contrato.id });
      }
    },
    buscarAnaliseRecompraPrecoUnico(request) {
      RecompraService.getBuscarAnaliseRecompraPrecoUnico(request)
        .then(({ data }) => {
          this.recompraAnalisePrecoUnico = data;

          this.titleModalEmail =
            "Enviar E-mail - Recompra Manual - Preço Único sem Rateio";
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    buscarAnaliseRecompraPrecoUnicoRateio(request) {
      RecompraService.getRecompraPrecoUnicoRateio(request)
        .then(({ data }) => {
          this.recompraAnaliseUnicoRateio = data;

          this.titleModalEmail =
            "Enviar E-mail - Recompra Manual - Preço Único com Rateio";
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    buscarAnaliseRecompraPrecosDiferentes(request) {
      RecompraService.getBuscarAnalisePrecosDiferentes(request)
        .then(({ data }) => {
          this.recompraAnalisePrecoDiferente = data;

          this.titleModalEmail =
            "Enviar E-mail - Recompra Manual - Preços Diferentes";
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    getTipoRecompra() {
      const requests = {
        "analise-recompra-preco-unico": (request) => {
          this.buscarAnaliseRecompraPrecoUnico(request);
        },
        "analise-recompra-preco-unico-rateio": (request) => {
          this.buscarAnaliseRecompraPrecoUnico(request);

          this.buscarAnaliseRecompraPrecoUnicoRateio(request);
        },
        "analise-recompra-precos-diferentes": (request) => {
          this.buscarAnaliseRecompraPrecosDiferentes(request);
        },
      };

      const links = this.contrato.links.filter((link) =>
        [
          "analise-recompra-preco-unico",
          "analise-recompra-preco-unico-rateio",
          "analise-recompra-precos-diferentes",
        ].includes(link.rel)
      );

      if (links[links.length - 1]) {
        this.tipoRecompra = links[links.length - 1].rel;

        requests[this.tipoRecompra]({ idContrato: this.contrato.id });
      }
    },
    hasAction(action) {
      return this.buttons.find(({ acao }) => acao === action);
    },
  },
  filters: {
    PERCENTUAL_TO_FIXED2(value) {
      if (isNaN(Number(value))) return "--";
      if (Number(value) === 100) return value + "%";
      return (
        Number(value).toLocaleString("pt-BR", {
          type: "currency",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + "%"
      );
    },
    PERCENTUAL_TO_FIXED3(value) {
      if (isNaN(Number(value))) return "--";
      if (Number(value) === 100) return value + "%";
      return (
        Number(value).toLocaleString("pt-BR", {
          type: "currency",
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        }) + "%"
      );
    },
  },
  components: {
    Tag,
    CardBase,
    ContratoTag,
    HorasFaltantes,
    HistoricoAcoes,
    collapsibleCard,
    ContratosFilhos,
    AprovacaoSpreadRecompra,
    ContratoComponentButtons,
    AprovacaoSolicitadoLiquido,
    ContratoPrecoUnicoComRateio,
    ModalEditarSolicitadoLiquido,
    ContratoRecompraPrecoDiferente,
    AprovacaoAnaliseRecompraPrecoUnico,
  },
};
</script>

<style lang="scss" scoped>
.contract {
  margin: 20px 0 20px 0;
  &__tags {
    margin: 5px 0 20px 0;
  }
}
.row {
  display: flex;
}
.row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}
.header-contract {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &__volume {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
.card-body {
  padding: 0;
  display: flex;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  flex-wrap: wrap;
  flex: 1;
}
._card-contrato-item {
  display: flex;
  padding-top: 15px;
  flex-direction: column;
}
.card-cliente {
  width: 25%;
  @include mq-value(890px) {
    width: 50%;
    margin-bottom: 15px;
  }
  @include mq-value(600px) {
    width: 100%;
  }
}
.card-flexibilidade {
  width: 33%;
  @include mq-value(600px) {
    margin-bottom: 15px;
    width: 100%;
  }
}
.card-condicoes {
  width: 25%;
  @include mq-value(600px) {
    margin-bottom: 15px;
    width: 100%;
  }
}
.card-calculo {
  width: 33%;
  @include mq-value(600px) {
    margin-bottom: 15px;
    width: 100%;
  }
}
.card-label {
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 24px;
  color: #544943;
}
.card-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2a2a2a;
}
.padlock {
  display: flex;
  align-items: center;
  font-size: 14px;
  img {
    cursor: pointer;
    padding-left: 5px;
  }
}
._contract-tags {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  .__yellow-tag {
    color: #242424;
    border: 1px dashed #9d7c05;
    background-color: #fff0cb;
  }
}
</style>
